/**
 * --------------------------------------------------
 *  Actions + Action Types
 * --------------------------------------------------
 */

import { ActionWork } from "store/actions/types/work.action-types";
import {
	ActionNumber,
	ActionNumberArray,
} from "store/actions/types/common.action-types";

import { AHubActions } from "actions/ahub.actions";
import { AppActions } from "actions/app.actions";

/**
 * --------------------------------------------------
 *  Selectors
 * --------------------------------------------------
 */

import { StoreAccess } from "store/store-access";

import { entityPermissions } from "selector/app.selector";
import {
	aHubStatePermanentUserList,
	aHubStatePermanentUserClientIndexs,
	aHubStatePermanentWorkGroups,
	aHubStatePermanentExportTypes,
	aHubStatePermanentExportGenerators,
} from "selector/ahub/ahub-permanent.selector";
import {
	aHubStateTemporaryUserIndexList,
	aHubStateTemporaryUserIndexes,
	aHubStateTemporaryDataSetIndexes,
	aHubStateTemporaryDataSetList,
	aHubStateTemporaryClientIndexs,
	aHubStateTemporaryClientList,
	aHubStateTemporaryClientLibraryVersionIndexs,
	aHubStateTemporaryClientLibraryVersionList,
	aHubStateTemporaryClientLibraryVersionModelUrlsList,
	aHubStateTemporaryDistributionGroupIndexes,
	aHubStateTemporaryDistributionGroupList,
	aHubStateTemporaryWorkGroupIndexes,
	aHubStateTemporaryWorkGroupList,
	aHubStateTemporaryDistributionsList,
	aHubStateTemporaryDistributionGroupDistributionIndexList,
	aHubStateTemporaryDistributionGroupUserIndexList,
	aHubStateTemporaryExportIndexs,
	aHubStateTemporaryExportList,
	aHubStateTemporaryExportDistributionIndexList,
	aHubStateTemporaryExportPreviewImageUrlsList,
	aHubStateTemporaryExportLegacyRefs,
	aHubStateTemporaryWorkGroupUserIndexList,
	aHubStateTemporaryProductPropertyList,
	aHubStateTemporaryProductClassList,
	aHubStateTemporaryProductClassIndexes,
	aHubStateTemporaryProductPropertySectionIndexes,
	aHubStateTemporaryProductPropertySectionList,
	aHubStateTemporaryProductPropertyAllocationIndexes,
	aHubStateTemporaryProductPropertyIndexes,
	aHubStateTemporaryExtractDefinitionIndexes,
	aHubStateTemporaryExtractDefinitionList,
	aHubStateTemporaryExtractIndexes,
	aHubStateTemporaryExtractList,
	aHubStateTemporaryClientLibraryIndexes,
	aHubStateTemporaryClientLibrariesList,
	aHubStateTemporaryClientQuotas,
	aHubStateTemporaryClientQuotasHistory,
	aHubStateTemporaryClientConfigurationList,
	aHubStateTemporaryProductPropertyAllocationChainIndexes,
	aHubStateTemporaryProductPropertyAllocationChains,
	aHubStateTemporaryExtractProducts,
	aHubStateTemporaryExporterIndexes,
	aHubStateTemporaryExporterList,
	aHubStateTemporaryExporterBuildHistorys,
} from "selector/ahub/ahub-temporary.selector";

/**
 * --------------------------------------------------
 *  Store
 * --------------------------------------------------
 */

import { List } from "store/list.vo";
import { ListUtil } from "store/list.util";

/**
 * --------------------------------------------------
 *  Cache
 * --------------------------------------------------
 */

/**
 * Interface for a single promise. A promise within the cache is an
 * array of ids, which the caller is promising to go and fetch the ids promised.
 * This will mean that if another caller asks for the same data very shortly after
 * the original request they can be put on hold as we know the data is on its way
 */
interface Promise {
	ids: number[];
	expireTime: number;
}

export class StoreCache {
	/**
	 * This is an object used as a key pair for our promises.
	 * the promises are stored against their action id for easy reference.
	 */
	private static promises: Object = {};

	/**
	 * Normalise the action based on the cached data.
	 */
	static normaliseAction(action: ActionWork): ActionWork {
		//Switch on the action type
		switch (action.type) {
			case AHubActions.USER_INDEXES_BY_IDS_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryUserIndexList
				);
				break;

			case AHubActions.SESSION_USERS_BY_IDS_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStatePermanentUserList
				);
				break;

			case AHubActions.USER_INDEXES_FULL_FETCH:
				action = StoreCache.normaliseActionFromArray(
					action,
					aHubStateTemporaryUserIndexes
				);
				break;

			case AHubActions.USER_CLIENTS_INDEXS_FETCH:
				action = StoreCache.normaliseActionFromArray(
					<ActionNumberArray>action,
					aHubStatePermanentUserClientIndexs
				);
				break;

			case AHubActions.DISTRIBUTIONS_BY_IDS_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryDistributionsList
				);
				break;

			case AHubActions.DATASET_INDEXS_FETCH:
				action = StoreCache.normaliseActionFromArray(
					action,
					aHubStateTemporaryDataSetIndexes
				);
				break;

			case AHubActions.DATASETS_BY_ID_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryDataSetList
				);
				break;

			case AHubActions.CLIENT_INDEXS_FETCH:
				action = StoreCache.normaliseActionFromArray(
					action,
					aHubStateTemporaryClientIndexs
				);
				break;

			case AHubActions.CLIENTS_BY_ID_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryClientList
				);
				break;

			case AHubActions.CLIENT_LIBRARY_VERSION_INDEXS_FETCH:
				action = StoreCache.normaliseActionFromArray(
					action,
					aHubStateTemporaryClientLibraryVersionIndexs
				);
				break;

			case AHubActions.CLIENTS_LIBRARY_VERSION_BY_ID_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryClientLibraryVersionList
				);
				break;

			case AHubActions.CLIENT_LIBRARY_VESION_MODEL_URL_FETCH:
				action = StoreCache.normaliseActionNumberFromList(
					<ActionNumber>action,
					aHubStateTemporaryClientLibraryVersionModelUrlsList
				);
				break;

			case AHubActions.CLIENTS_LIBRARIES_BY_ID_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryClientLibrariesList
				);
				break;

			case AHubActions.DISTRIBUTION_GROUP_INDEXS_FETCH:
				action = StoreCache.normaliseActionFromArray(
					action,
					aHubStateTemporaryDistributionGroupIndexes
				);
				break;

			case AHubActions.DISTRIBUTION_GROUPS_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryDistributionGroupList
				);
				break;

			case AHubActions.WORK_GROUP_INDEXS_FETCH:
				action = StoreCache.normaliseActionFromArray(
					action,
					aHubStateTemporaryWorkGroupIndexes
				);
				break;

			case AHubActions.WORK_GROUPS_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryWorkGroupList
				);
				break;

			case AHubActions.DISTRIBUTION_GROUP_DISTRIBUTION_INDEXS_FETCH:
				action = StoreCache.normaliseActionNumberFromList(
					<ActionNumber>action,
					aHubStateTemporaryDistributionGroupDistributionIndexList
				);
				break;

			case AHubActions.DISTRIBUTION_GROUP_USER_INDEXS_FETCH:
				action = StoreCache.normaliseActionNumberFromList(
					<ActionNumber>action,
					aHubStateTemporaryDistributionGroupUserIndexList
				);
				break;

			case AHubActions.EXPORT_TYPES_ALL_FETCH:
				action =
					StoreAccess.dataGet(aHubStatePermanentExportTypes).length > 0
						? undefined
						: action;
				break;

			case AHubActions.EXPORT_GENERATORS_ALL_FETCH:
				action =
					StoreAccess.dataGet(aHubStatePermanentExportGenerators).length > 0
						? undefined
						: action;
				break;

			case AHubActions.EXPORT_INDEXS_FETCH:
				action = StoreCache.normaliseActionFromArray(
					<ActionNumber>action,
					aHubStateTemporaryExportIndexs
				);
				break;

			case AHubActions.EXPORTS_BY_ID_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryExportList
				);
				break;

			case AHubActions.EXPORTER_INDEXES_FETCH:
				action = StoreCache.normaliseActionFromArray(
					<ActionNumber>action,
					aHubStateTemporaryExporterIndexes
				);
				break;

			case AHubActions.EXPORTERS_BY_ID_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryExporterList
				);
				break;

			case AHubActions.EXPORT_DISTRIBUTION_INDEXS_FETCH:
				action = StoreCache.normaliseActionNumberFromList(
					<ActionNumber>action,
					aHubStateTemporaryExportDistributionIndexList
				);
				break;

			case AHubActions.EXPORT_LEGACY_REF_FETCH:
				action = StoreCache.normaliseActionNumberFromList(
					<ActionNumber>action,
					aHubStateTemporaryExportLegacyRefs
				);
				break;

			case AHubActions.EXPORTER_BUILD_HISTORYS_BY_IDS_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryExporterBuildHistorys
				);
				break;

			case AHubActions.WORK_GROUP_USER_INDEXS_FETCH:
				action = StoreCache.normaliseActionNumberFromList(
					<ActionNumber>action,
					aHubStateTemporaryWorkGroupUserIndexList
				);
				break;

			case AHubActions.WORK_GROUPS_BY_USER_AND_CLIENT_FETCH:
				action = StoreCache.normaliseActionFromArray(
					action,
					aHubStatePermanentWorkGroups
				);
				break;

			case AppActions.ENTITY_PERMISSIONS_FETCH:
				action = StoreCache.normaliseActionFromArray(action, entityPermissions);
				break;

			case AHubActions.CLIENT_QUOTAS_BY_CLIENT_ID_FETCH:
				action = StoreCache.normaliseActionNumberFromList(
					<ActionNumber>action,
					aHubStateTemporaryClientQuotas
				);
				break;

			case AHubActions.CLIENT_QUOTA_BY_CLIENT_QUOTA_ID_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryClientQuotas
				);
				break;

			case AHubActions.CLIENT_QUOTAS_HISTORY_BY_CLIENT_ID_FETCH:
				action = StoreCache.normaliseActionNumberFromList(
					<ActionNumber>action,
					aHubStateTemporaryClientQuotasHistory
				);
				break;

			case AHubActions.CLIENT_CONFIGURATION_BY_CLIENT_ID_FETCH:
				action = StoreCache.normaliseActionNumberFromList(
					<ActionNumber>action,
					aHubStateTemporaryClientConfigurationList
				);
				break;

			case AHubActions.PRODUCT_PROPERTY_ALLOCATION_INDEXS_FETCH:
				action = StoreCache.normaliseActionFromArray(
					<ActionNumberArray>action,
					aHubStateTemporaryProductPropertyAllocationIndexes
				);
				break;

			case AHubActions.PRODUCT_PROPERTY_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryProductPropertyList
				);
				break;

			case AHubActions.PRODUCT_CLASSES_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryProductClassList
				);
				break;

			case AHubActions.PRODUCT_CLASS_INDEXS_BY_CLIENT_ID_FETCH:
				action = StoreCache.normaliseActionFromArray(
					action,
					aHubStateTemporaryProductClassIndexes
				);
				break;

			case AHubActions.PRODUCT_PROPERTY_SECTION_INDEXS_BY_CLIENT_ID_FETCH:
				action = StoreCache.normaliseActionFromArray(
					action,
					aHubStateTemporaryProductPropertySectionIndexes
				);
				break;

			case AHubActions.PRODUCT_PROPERTY_INDEXS_BY_CLIENT_ID_FETCH:
				action = StoreCache.normaliseActionFromArray(
					action,
					aHubStateTemporaryProductPropertyIndexes
				);
				break;

			case AHubActions.PRODUCT_PROPERTY_SECTIONS_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryProductPropertySectionList
				);
				break;

			case AHubActions.EXTRACT_DEFINITION_INDEXES_FETCH:
				action = StoreCache.normaliseActionFromArray(
					<ActionNumberArray>action,
					aHubStateTemporaryExtractDefinitionIndexes
				);
				break;

			case AHubActions.EXTRACT_DEFINITIONS_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryExtractDefinitionList
				);
				break;

			case AHubActions.EXTRACT_INDEXES_FETCH:
				action = StoreCache.normaliseActionFromArray(
					<ActionNumberArray>action,
					aHubStateTemporaryExtractIndexes
				);
				break;

			case AHubActions.EXTRACTS_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryExtractList
				);
				break;

			// case AHubActions.PRODUCTS_BY_EXTRACT_ID_FETCH:
			//     action = StoreCache.normaliseActionNumberArrayFromList(<ActionNumberArray>action, aHubStateTemporaryExtractProducts);
			//     break;

			case AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_INDEXS_FETCH:
				action = StoreCache.normaliseActionFromArray(
					<ActionWork>action,
					aHubStateTemporaryProductPropertyAllocationChainIndexes
				);
				break;

			case AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAINS_FETCH:
				action = StoreCache.normaliseActionNumberArrayFromList(
					<ActionNumberArray>action,
					aHubStateTemporaryProductPropertyAllocationChains
				);
				break;
		}

		//Return the action
		return action;
	}

	/**
	 * Selector passed is required to return a list type. The data will be obtained. We have been supplied an action with a number.
	 * we will check if an item with that number exists in the list obtained from the selector
	 */
	private static normaliseActionNumberFromList = <T>(
		action: ActionNumber,
		selector: (data) => List<T>
	): ActionNumber => {
		//Get the list from the store using the current selector
		let list: List<T> = StoreAccess.dataGet(selector);

		//If the number in the action is within the list we will return undefined as the data is cached so we don't need to update it.
		//if the data is missing we will return it
		if (ListUtil.listDataItemExists(list, action.number)) return undefined;

		//We will now check the promised items as we may already be in the process of getting this data
		return StoreCache.promisesActionNumber(action);
	};

	/**
	 * Selector passed is required to return a list type. The data will be obtained. We have been supplied an action with an array of numbers
	 * we will then generate a subset of this array so that we have an array which only represents the ids of the items which we don't have in the store.
	 * The subset will be set into the current action. If there are no id's remaining then we will return undefined identifying that we don't need to dispatch the event
	 */
	private static normaliseActionNumberArrayFromList = <T>(
		action: ActionNumberArray,
		selector: (data) => List<T>
	): ActionNumberArray => {
		//Get the list from the store using the current selector
		let list: List<T> = StoreAccess.dataGet(selector);

		//Get a list of the ids which are not in the list from the store
		let ids: number[] = ListUtil.listDataFilterIdOnNotExists(
			list,
			action.numbers
		);

		//If we have no ids left in the list then we will return undefined
		if (ids.length == 0) return undefined;

		//Set the new action id list
		action.numbers = ids;

		//So we have requcted the list from thoes which are already in the store. We will now
		//do the same with thoes items which we have already been promised. Lets try it.
		return StoreCache.promisedActionNumberArray(action);
	};

	/**
	 * A selector is passed in that returns an array. If data exists at the endpoint of the selector then we have a cache and no need to dispatch an update so undefied is return.
	 * If the data is missing then we have no cache and the action is retured so we can dispatch it
	 */
	private static normaliseActionFromArray = <T>(
		action: ActionWork,
		selector: (data) => T[]
	): ActionWork => {
		//Get the array from the store
		let array: T[] = StoreAccess.dataGet(selector);

		//If we have data then we don't need the data then we will not need to re-request
		if (array && array.length > 0) return undefined;

		//We need to get the data so lets check that it hasn't already been promised.
		return StoreCache.promisedActionArray(action);
	};

	/**
	 * Function which check if the action supplied has already been promised.
	 * if the data base been promised then there is no need to send this action.
	 * as we have done soo in the not soo distant past.
	 *
	 * @param action    Action we want to check if its been promised
	 *
	 * @return          The action for work if its required with the ids required, undefined if we don't need the data because its been promised
	 */
	private static promisesActionNumber(action: ActionNumber): ActionNumber {
		//If the action is undefine return
		if (!action) return action;

		//Get the ids we have been promised for this action type
		let promisedIds: number[] = StoreCache.promisedIdsGet(action.type);

		//OK so we will look throug our promised ids to see if our action id exists within
		//the list. If it does, this means that we should have the data soon as the data
		//has been requested recently.
		if (promisedIds.indexOf(action.number) > -1) return undefined;

		//We are going to fetch this data now so we will promise the data

		//Store the un promised items as we are going to get them now!
		StoreCache.promisedIdsAdd(action.type, [action.number]);

		//If not then we are not fetching this data so go and get it
		return action;
	}

	/**
	 * Function which check if the action supplied has already been promised.
	 * if the data base been promised then there is no need to send this action.
	 * as we have done soo in the not soo distant past.
	 *
	 * @param action    Action we want to check if its been promised
	 *
	 * @return          The action for work if its required with the ids required, undefined if we don't need the data because its been promised
	 */
	private static promisedActionNumberArray(
		action: ActionNumberArray
	): ActionNumberArray {
		//If the action is undefine return
		if (!action) return action;

		//Get the ids we have been promised for this action type
		let promisedIds: number[] = StoreCache.promisedIdsGet(action.type);

		//OK we want to get an array of the ids within the action which don't exist on the promised list
		//as these will be the data items we will need to fetch
		let unPromisedIds = action.numbers.filter(
			(actionId) => promisedIds.indexOf(actionId) == -1
		);

		//OK are their any id's left for us to fetch
		if (unPromisedIds.length == 0) return undefined;

		//There are still items left to featch so we will have to update the action with the un promised items
		//We want to make sure that the ids are unique so we will to and from a set that will do the trick
		action.numbers = Array.from(new Set(unPromisedIds));

		//Store the un promised items as we are going to get them now!
		StoreCache.promisedIdsAdd(action.type, unPromisedIds);

		//Return the newly updated action.
		return action;
	}

	/**
	 * Function which check if the action supplied has already been promised.
	 * if the data base been promised then there is no need to send this action.
	 * as we have done soo in the not soo distant past.
	 *
	 * @param action    Action we want to check if its been promised
	 *
	 * @return          The action for work if its required, undefined if we don't need the data because its been promised
	 */
	private static promisedActionArray(action: ActionWork): ActionWork {
		//Get the promised ids under this action type. due to the nature
		//of this function the ids themselfs are not important.
		//we will simply check if our nominal value is in the list ... if it is cracking were good
		let promised: number[] = StoreCache.promisedIdsGet(action.type);

		//If there is a promised item this mean we are fetching the data
		if (promised.length > 0) return undefined;

		//OK there is no promised data so we will add a promise an go and get it

		//OK we don't have a defined id for this action. But we will add in a notial value
		//and based on its existence will tell us if we have promised this before of not
		StoreCache.promisedIdsAdd(action.type, [-1]);

		//Return our new action
		return action;
	}

	/**
	 * Get the ids which it has been promised we are going to get the data for ...
	 * the ids returned have been requested data for recently. This means we should wait a sort time
	 *
	 * @param actionType    The action type we want the promised ids for
	 *
	 * @return              The ids which we have been promised data for the action type supplied
	 */
	private static promisedIdsGet(actionType: string): number[] {
		//Get the cache promie items
		let cachedPromises: Promise[] = StoreCache.promises[actionType];

		//If we have no cached promises then we will return none
		if (!cachedPromises) return [];

		//Create an array of the items which have yet to expire
		let nonExpiredItems = cachedPromises.filter(
			(promise) => promise.expireTime > new Date().getTime()
		);

		//Set the items which have no longer expired
		StoreCache.promises[actionType] = nonExpiredItems;

		//Take our non expired items convert them into an array of id arrays and them merge them into
		//one one array of unique list of items
		return nonExpiredItems
			.map((items) => items.ids)
			.reduce((existingList, newList) => {
				let uniqueList: number[] = existingList ? existingList.slice() : [];
				let newUniqueList: number[] = newList
					? newList.filter((newItem) => uniqueList.indexOf(newItem) == -1)
					: [];

				//Return the new unique list
				return uniqueList.concat(newUniqueList);
			}, []);
	}

	/**
	 * Add the array of ids to the promised list of ids. Ids added to this list
	 * will be stored for a short time, if another action requests this id we will
	 * promise them this data by adding the ids to this list YOU are PROMISING to go and get the data
	 *
	 * @param actionType        The action which we want to add the ids too
	 * @param ids               The ids we want to add to the list
	 */
	private static promisedIdsAdd(actionType: string, ids: number[]) {
		//Create a new cache promise for the ids supplied
		let newCachePromise: Promise = {
			ids: ids.slice(),
			expireTime: new Date().getTime() + 1000,
		};

		//Get the cache promie items
		let cachedPromises: Promise[] = StoreCache.promises[actionType];

		//If we don't have any cached promises then we will start with an empty array
		if (!cachedPromises) cachedPromises = [];

		//Add the new cached items to the promises list
		cachedPromises.push(newCachePromise);

		//Set the items which have no longer expired
		StoreCache.promises[actionType] = cachedPromises;
	}
}
