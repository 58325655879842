/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import { AppState } from "reducers/app.reducer";

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */

import { SessionAppVO } from "valueObjects/app/session.app.vo";
import { UserSessionCredentials } from "@harksolutions/ahub-web-services-types";
import { RequestActionStatusVO } from "valueObjects/app/request-action-status.vo";
import { EntityPermissionAHubVO } from "valueObjects/ahub/accounts/entity-permission.ahub.vo";
import { ClientConfigurationAHubVO } from "valueObjects/ahub/accounts/client-configuration.ahub.vo";
import { NotificationRecordVO } from "valueObjects/app/notification-record.vo";
import { ActionWork } from "actions/types/work.action-types";
import { FileDownloadConfigVO } from "app/valueObjects/app/file-download-config.app.vo";
import { DataSetLibraryViewConfigAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-config.ahub.vo";
import { MapStorage } from "../map-storage.vo";
import { ToolIDEnum } from "app/valueObjects/view/tool-id.view.enum";

/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Root selector to get the App state from the store state
 */
const appState = (state): AppState => state.app;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */

/**
 * Root selector to get the App state from the store state
 */
export const session = (state): SessionAppVO => appState(state).session;

/**
 * Selector for the sessions user id.
 */
export const sessionUserId = (state): number => session(state).userId;

/**
 * Selector for the sessions client id.
 */
export const sessionClientId = (state): number => session(state).clientId;

/**
 * The selector for the current tool id.
 */
export const currentToolId = (state): ToolIDEnum =>
	appState(state).currentToolId;

/**
 * Get the login token from the app state.
 */
export const loginToken = (state): string => appState(state).loginToken;

/**
 * Get the session token from the app state.
 */
export const sessionToken = (state): string => appState(state).sessionToken;

/**
 * Get the external app token from the app state.
 */
export const externalAppToken = (state): string =>
	appState(state).externalAppToken;

/**
 * Gets the return to URL value and returns it.
 */
export const returnToUrl = (state): string => appState(state).returnToUrl;

/**
 * Selector for the sessions client config.
 */
export const sessionClientConfig = (state): ClientConfigurationAHubVO =>
	appState(state).currentClientConfig;

/**
 * Root selector to get the App state from the store state
 */
export const sessionUserSessionCredentials = (state): UserSessionCredentials =>
	session(state).userSessionCredentials;

/**
 * Queued actions
 */
export const actionsQueue = (state): ActionWork[] =>
	appState(state).actionQueue;

/**
 * Get the hard data limit for the amount of items we will get from the aHub
 */
export const appDataItemLimit = (state): number =>
	appState(state).dataItemLimit;

/**
 * Get the hard data limit for the amount of properties which we will display
 */
export const appPropertyCountLimit = (state): number =>
	appState(state).propertyCountLimit;

/**
 * Root selector to get the list of workflow refrence action statuses from the store state.
 */
export const requestActionStatuses = (state): RequestActionStatusVO[] =>
	appState(state).requestActionStatusVOs;

/**
 * Selector to get the entity permissions for the current user/client.
 */
export const entityPermissions = (state): EntityPermissionAHubVO[] =>
	session(state).entityPermissions;

/**
 * Root selector to get the list of notifocation records.
 */
export const notificationRecords = (state): NotificationRecordVO[] =>
	appState(state).notificationRecordVOs;

/**
 * Selector for a file which we want to download
 */
export const fileDownloadConfigs = (state): FileDownloadConfigVO[] =>
	appState(state).fileDownloadConfigs;

/**
 * Copy the text within here into the clipboard
 */
export const clipBoardCopyText = (state): string =>
	appState(state).clipboardCopyText;

/**
 * Selector for a file which we want to download
 */
export const serverTime = (state): number => appState(state).serverTime;

/**
 * Selects library view config by dataset id
 */
export const appLibraryViewConfig = (
	state
): MapStorage<DataSetLibraryViewConfigAHubVO> =>
	appState(state).libraryViewConfigByDatasetId;

/**
 * Selects library view config map storage by client id
 */
export const appLibraryViewConfigMapStorage = (
	state
): MapStorage<MapStorage<DataSetLibraryViewConfigAHubVO>> =>
	appState(state).libraryViewConfigMapStorageByClientId;
