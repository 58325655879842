/**
 * ----------------------------------------------------------
 * Action Types
 * ----------------------------------------------------------
 */

import {
	ActionClientConfigurationAHubVO,
	ActionEntityPermissionAHubVOs,
	ActionUserSessionCredentials,
} from "actions/types/ahub-accounts.action-types";
import {
	ActionRequestActionStatusVO,
	ActionWorklogSegmentAHubVO,
} from "actions/types/ahub-work.action-types";
import {
	ActionFileDownloadConfigVO,
	ActionNotificationRecordVO,
	ActionQueuedActions,
} from "actions/types/app.action-types";
import {
	ActionNumber,
	ActionNumberArray,
	ActionString,
	ActionStringArray,
	ActionToolId,
} from "actions/types/common.action-types";
import { ActionWork, ActionWorkCancel } from "actions/types/work.action-types";
import { FileDownloadConfigVO } from "app/valueObjects/app/file-download-config.app.vo";
import { ClientConfigurationAHubVO } from "valueObjects/ahub/accounts/client-configuration.ahub.vo";
import { EntityPermissionAHubVO } from "valueObjects/ahub/accounts/entity-permission.ahub.vo";
/**
 * ----------------------------------------------------------
 * Value Objects
 * ----------------------------------------------------------
 */
import { UserSessionCredentials } from "@harksolutions/ahub-web-services-types";
import { WorklogSegmentAHubVO } from "valueObjects/ahub/work/worklog-segment.ahub.vo";
import { NotificationRecordVO } from "valueObjects/app/notification-record.vo";
import { RequestActionStatusVO } from "valueObjects/app/request-action-status.vo";
import { DataSetLibraryViewConfigAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-config.ahub.vo";
import {
	ActionLibraryViewConfigByDatasetIdAHubVO,
	ActionLibraryViewConfigAHubVO,
	ActionLibraryViewConfigMapStorageByClientIdAHubVO,
} from "./types/library.action-types";
import { MapStorage } from "../map-storage.vo";
import { ToolIDEnum } from "app/valueObjects/view/tool-id.view.enum";

/**
 * App Actions class
 */
export class AppActions {
	/**
	 * Event Type statics
	 */
	static SESSION_CLEAR = "[APP] SESSION_CLEAR";
	static SESSION_USER_ID_SET = "[APP] SESSION_USER_ID_SET";
	static SESSION_USER_SESSION_CREDENTIALS_SET =
		"[APP] SESSION_USER_SESSION_CREDENTIALS_SET";
	static SESSION_CLIENT_ID_SET = "[APP] SESSION_CLIENT_ID_SET";
	static SESSION_INFO_FETCH = "[APP] SESSION_INFO_FETCH";
	static SESSION_INVALIDATE = "[APP] SESSION_INVALIDATE";
	static SESSION_CLIENT_CONFIG_FETCH = "[APP] SESSION_CLIENT_CONFIG_FETCH";
	static SESSION_CLIENT_CONFIG_SET = "[APP] SESSION_CLIENT_CONFIG_SET";
	static LOGIN_TOKEN_SET = "[APP] LOGIN_TOKEN_SET";
	static SESSION_TOKEN_SET = "[APP] SESSION_TOKEN_SET";
	static EXTERNAL_APP_TOKEN_SET = "[APP] EXTERNAL_APP_TOKEN_SET";
	static RETURN_TO_URL_SET = "[APP] RETURN_TO_URL_SET";

	static CURRENT_TOOL_ID_SET = "[APP] CURRENT_TOOL_ID_SET";

	static ACTIONS_QUEUED_ADD = "[APP] ACTIONS_QUEUED_ADD";
	static ACTIONS_QUEUED_REMOVE = "[APP] ACTIONS_QUEUED_REMOVE";
	static ACTIONS_WORK_CANCEL = "[APP] ACTIONS_WORK_CANCEL";

	static WORKFLOW_REFERENCE_ACTION_STATUS_APPEND =
		"[APP] WORKFLOW_REFERENCE_ACTION_STATUS_APPEND";
	static WORKFLOW_REFERENCE_ACTION_STATUS_UPDATE =
		"[APP] WORKFLOW_REFERENCE_ACTION_STATUS_UPDATE";
	static WORKFLOW_REFERENCE_ACTION_UPLOAD_COMPLETE_SIGNAL_UPDATE =
		"[APP] WORKFLOW_REFERENCE_ACTION_UPLOAD_COMPLETE_SIGNAL_UPDATE";
	static WORKFLOW_REFERENCE_ACTION_UPLOAD_PROGRESS_UPDATE =
		"[APP] WORKFLOW_REFERENCE_ACTION_UPLOAD_PROGRESS_UPDATE";

	static ENTITY_PERMISSIONS_FETCH = "[AHUB] ENTITY_PERMISSIONS_FETCH";
	static ENTITY_PERMISSIONS_SET = "[AHUB] ENTITY_PERMISSIONS_SET";
	static SERVER_TIME_FETCH = "[AHUB] SERVER_TIME_FETCH";
	static SERVER_TIME_SET = "[AHUB] SERVER_TIME_SET";

	static NOTIFICATION_RECORD_APPEND = "[APP] NOTIFICATION_APPEND";

	static DOWNLOAD_URL_SET = "[APP] DOWNLOAD_URL_SET";
	static CLIPBOARD_TEXT_COPY_SET = "[APP] CLIPBOARD_TEXT_COPY_SET";

	static LIBRARY_VIEW_CONFIG_SET = "[APP] LIBRARY_VIEW_CONFIG_SET";
	static LIBRARY_VIEW_CONFIG_BY_DATASET_ID_SET =
		"[APP] LIBRARY_VIEW_CONFIG_BY_DATASET_ID_SET";
	static LIBRARY_VIEW_CONFIG_MAP_STORAGE_BY_CLIENT_ID_SET =
		"[APP] LIBRARY_VIEW_CONFIG_MAP_STORAGE_BY_CLIENT_ID_SET";
	static LIBRARY_VIEW_CONFIG_CLEAR = "[APP] LIBRARY_VIEW_CONFIG_CLEAR";

	/**
	 * Create an action to add queued actions to the queue
	 */
	static actionQueuedAdd(actions: ActionWork[]): ActionQueuedActions {
		return {
			type: AppActions.ACTIONS_QUEUED_ADD,
			actions: actions,
		};
	}

	/**
	 * Create an action to removed queued actions from the store
	 */
	static actionQueuedRemove(actionIds: number[]): ActionNumberArray {
		return {
			type: AppActions.ACTIONS_QUEUED_REMOVE,
			numbers: actionIds,
		};
	}

	/**
	 * Cancel a long running action.. ( will only effect those that have implemented a cancel. !)
	 * @param cancelActionId
	 */
	static actionCancel(cancelActionId: number): ActionWorkCancel {
		return {
			type: AppActions.ACTIONS_WORK_CANCEL,
			cancelActionId: cancelActionId,
		};
	}

	/**
	 * Clear the session data.
	 */
	static sessionClear(): ActionWork {
		return {
			type: AppActions.SESSION_CLEAR,
		};
	}

	/**
	 * Set the user id for this session
	 */
	static sessionUserIdSet(userId: number): ActionNumber {
		//Constuct action with the specified interface
		return {
			type: AppActions.SESSION_USER_ID_SET,
			number: userId,
		};
	}

	/**
	 * Set the client id for this session
	 */
	static sessionClientIdSet(clientId: number): ActionNumber {
		//Constuct action with the specified interface
		return {
			type: AppActions.SESSION_CLIENT_ID_SET,
			number: clientId,
		};
	}

	/**
	 * Fetch the info for this session
	 */
	static sessionInfoFetch(): ActionWork {
		//Constuct action with the specified interface
		return {
			type: AppActions.SESSION_INFO_FETCH,
		};
	}

	/**
	 * Invalidate user session
	 */
	static sessionInvalidate(): ActionWork {
		//Constuct action with the specified interface
		return {
			type: AppActions.SESSION_INVALIDATE,
		};
	}

	/**
	 * Fetch the client config for this session
	 */
	static sessionClientConfigFetch(): ActionWork {
		//Constuct action with the specified interface
		return {
			type: AppActions.SESSION_CLIENT_CONFIG_FETCH,
		};
	}

	/**
	 * Set the client id for this session
	 */
	static sessionClientConfigSet(
		clientConfiguration: ClientConfigurationAHubVO
	): ActionClientConfigurationAHubVO {
		//Constuct action with the specified interface
		return {
			type: AppActions.SESSION_CLIENT_CONFIG_SET,
			clientConfiguration: clientConfiguration,
		};
	}

	/**
	 * Set the user session credentials for this session
	 */
	static sessionUserSessionCredentialsSet(
		userSessionCredentials: UserSessionCredentials
	): ActionUserSessionCredentials {
		//Constuct action with the specified interface
		return {
			type: AppActions.SESSION_USER_SESSION_CREDENTIALS_SET,
			userSessionCredentials: userSessionCredentials,
		};
	}

	/**
	 * Add a new action request status object to the list.
	 */
	static sessionrequestActionStatusAppend(
		requestActionStatus: RequestActionStatusVO
	): ActionRequestActionStatusVO {
		return {
			type: AppActions.WORKFLOW_REFERENCE_ACTION_STATUS_APPEND,
			requestActionStatus: requestActionStatus,
		};
	}

	/**
	 * Update the request action status objects based on a work log segment value object.
	 */
	static sessionrequestActionStatusUpdate(
		worklogSegmentAHubVO: WorklogSegmentAHubVO
	): ActionWorklogSegmentAHubVO {
		return {
			type: AppActions.WORKFLOW_REFERENCE_ACTION_STATUS_UPDATE,
			worklogSegment: worklogSegmentAHubVO,
		};
	}

	/**
	 * Update the request action status objects based on a work log segment value object.
	 */
	static requestActionStatusUploadCompleteSignalUpdate(
		workflowId: string
	): ActionString {
		return {
			type: AppActions.WORKFLOW_REFERENCE_ACTION_UPLOAD_COMPLETE_SIGNAL_UPDATE,
			string: workflowId,
		};
	}

	/**
	 * Update the request action status objects based on a work log segment value object.
	 */
	static requestActionStatusUploadProgressUpdate(
		workflowId: string,
		fileId: string,
		progress: number
	): ActionStringArray {
		return {
			type: AppActions.WORKFLOW_REFERENCE_ACTION_UPLOAD_PROGRESS_UPDATE,
			strings: [workflowId, fileId, progress.toString()],
		};
	}

	/**
	 * Set the login token.
	 */
	static loginTokenSet(token: string): ActionString {
		//Constuct action with the specified interface
		return {
			type: AppActions.LOGIN_TOKEN_SET,
			string: token,
		};
	}

	/**
	 * Create an action to set a session token.
	 */
	static sessionTokenSet(sessionToken: string): ActionString {
		//Constuct action with the specified interface
		return {
			type: AppActions.SESSION_TOKEN_SET,
			string: sessionToken,
		};
	}
	/**
	 * Set the login token.
	 */
	static externalAppTokenSet(token: string): ActionString {
		//Constuct action with the specified interface
		return {
			type: AppActions.EXTERNAL_APP_TOKEN_SET,
			string: token,
		};
	}

	/**
	 * Create a new action to set the return to URL value.
	 *
	 * @param url         The URL to return too.
	 */
	static returnToUrlSet(url: string): ActionString {
		// Create a new action and return it.
		return {
			type: AppActions.RETURN_TO_URL_SET,
			string: url,
		};
	}

	/**
	 * Create a new action to set the current tool id.
	 *
	 * @param toolId      The tool id to set too.
	 */
	static currentToolIdSet(toolId: ToolIDEnum): ActionToolId {
		// Create and return the current tool id.
		return {
			type: AppActions.CURRENT_TOOL_ID_SET,
			toolId: toolId,
		};
	}

	/**
	 * Fetch the index for the work groups.
	 */
	static entityPermissionsFetch(): ActionWork {
		return {
			type: AppActions.ENTITY_PERMISSIONS_FETCH,
		};
	}

	/**
	 * Set the current user/client entity permissions in the store
	 */
	static entityPermissionsSet(
		entityPermissions: EntityPermissionAHubVO[]
	): ActionEntityPermissionAHubVOs {
		return {
			type: AppActions.ENTITY_PERMISSIONS_SET,
			entityPermissions: entityPermissions,
		};
	}

	/**
	 * Fetch the current server time.
	 */
	static serverTimeFetch(): ActionWork {
		return {
			type: AppActions.SERVER_TIME_FETCH,
		};
	}

	/**
	 * Set the current server time in the store
	 */
	static serverTimeSet(serverTime: number): ActionNumber {
		return {
			type: AppActions.SERVER_TIME_SET,
			number: serverTime,
		};
	}

	/**
	 * Append a notification record to the store.
	 */
	static notificationRecordAppend(
		notificationRecord: NotificationRecordVO
	): ActionNotificationRecordVO {
		return {
			type: AppActions.NOTIFICATION_RECORD_APPEND,
			notificationRecord: notificationRecord,
		};
	}

	/**
	 * set the config used to download a file
	 */
	static fileDownloadConfigSet(
		fileDownloadConfigs: FileDownloadConfigVO[]
	): ActionFileDownloadConfigVO {
		return {
			type: AppActions.DOWNLOAD_URL_SET,
			fileDownloadConfigs: fileDownloadConfigs,
		};
	}

	/**
	 * Action will result in the content of the copy text to be copied to the clipboard
	 */
	static clipboardTextCopySet(copyText: string): ActionString {
		return {
			type: AppActions.CLIPBOARD_TEXT_COPY_SET,
			string: copyText,
		};
	}

	/**
	 * Set the library view config
	 */
	static libraryViewConfigSet(
		libraryViewConfig: MapStorage<MapStorage<DataSetLibraryViewConfigAHubVO>>
	): ActionLibraryViewConfigAHubVO {
		//Constuct action with the specified interface
		return {
			type: AppActions.LIBRARY_VIEW_CONFIG_SET,
			libraryViewConfig,
		};
	}

	/**
	 * Set the library view config by dataset id set in the permanent part of the store
	 */
	static libraryViewConfigByDatasetIdSet(
		libraryViewConfig: DataSetLibraryViewConfigAHubVO,
		datasetId: number
	): ActionLibraryViewConfigByDatasetIdAHubVO {
		//Constuct action with the specified interface
		return {
			type: AppActions.LIBRARY_VIEW_CONFIG_BY_DATASET_ID_SET,
			libraryViewConfig,
			datasetId,
		};
	}

	/**
	 * Set the library view config by dataset id set in the permanent part of the store
	 */
	static libraryViewConfigMapStorageByClientIdSet(
		libraryViewConfigMapStorage: MapStorage<DataSetLibraryViewConfigAHubVO>,
		clientId: number
	): ActionLibraryViewConfigMapStorageByClientIdAHubVO {
		//Constuct action with the specified interface
		return {
			type: AppActions.LIBRARY_VIEW_CONFIG_MAP_STORAGE_BY_CLIENT_ID_SET,
			libraryViewConfigMapStorage,
			clientId,
		};
	}

	/**
	 * Create an action to clear the library view config from the store.
	 *
	 */
	static libraryViewConfigClear(): ActionWork {
		// Construct the action.
		return {
			type: AppActions.LIBRARY_VIEW_CONFIG_CLEAR,
		};
	}
}
