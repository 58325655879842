import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { DOCUMENT } from "@angular/common";
import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import { DialogService } from "app/modules/common/dialogs/dialog.service";
import { MoveDialogComponent } from "../move-dialog/move-dialog.component";
import {
	ReorderDialogComponent,
	ReorderingVO,
} from "../reorder-dialog/reorder-dialog.component";
import { TreeStructure } from "../tree-structure.class";

@Component({
	selector: "app-tree-list-item",
	templateUrl: "./tree-list-item.component.html",
	styleUrls: ["./tree-list-item.component.scss"],
})
export class TreeListItemComponent implements OnInit {
	constructor(
		private readonly resolver: ComponentFactoryResolver,
		private readonly dialogService: DialogService,
		@Inject(DOCUMENT) private document: Document
	) {}

	/**
	 * The part of the tree this sub tree represents.
	 */
	@Input() tree: TreeStructure;
	treeNode;
	isNestedDrop = false;
	dropTimer;
	enteredListPosition;
	ancestryIds;
	rootId;

	@Output() dropped = new EventEmitter();

	ngOnInit(): void {
		this.setAncestryIdsArray();
		this.treeNode = JSON.stringify(this.tree.node);
	}

	setAncestryIdsArray() {
		let ids = this.tree.node.ancestry;
		this.ancestryIds = ids
			.split(",")
			.filter((e) => e)
			.map(function (item) {
				return item.trim();
			});
	}

	onDragStart(event) {}
	dropListExited(event) {}

	dropListEntered(event) {
		this.enteredListPosition =
			event.container.element.nativeElement.getBoundingClientRect();
	}

	openOrderDialog() {
		let reorderingVO: ReorderingVO = {
			above: true,
			targetNode: undefined,
		};
		const componentFactory: ComponentFactory<ReorderDialogComponent> =
			this.resolver.resolveComponentFactory(ReorderDialogComponent);
		const dialogResult = this.dialogService.componentDialogOpen(
			"Category Order",
			componentFactory,
			"reorderingVO",
			reorderingVO,
			undefined,
			"Save",
			"Cancel",
			"tree",
			this.tree
		);

		// Watch the dialogue.
		dialogResult.subscribe((result) => {
			if (!result) {
				return;
			}
			if (result.above) {
				this.tree.treeComponent.treeConfig.nodeMoveOrderFunction(
					this.tree.treeComponent.treeConfig.functionScope,
					this.tree.node,
					result.targetNode.node,
					true
				);
			} else {
				this.tree.treeComponent.treeConfig.nodeMoveOrderFunction(
					this.tree.treeComponent.treeConfig.functionScope,
					this.tree.node,
					result.targetNode.node,
					false
				);
			}
		});
	}

	openMoveDialog() {
		let reorderingVO: ReorderingVO = {
			above: true,
			targetNode: undefined,
		};
		const componentFactory: ComponentFactory<MoveDialogComponent> =
			this.resolver.resolveComponentFactory(MoveDialogComponent);
		const dialogResult = this.dialogService.componentDialogOpen(
			"Move Item",
			componentFactory,
			"reorderingVO",
			reorderingVO,
			undefined,
			"Save",
			"Cancel",
			"tree",
			this.tree
		);

		// Watch the dialogue.
		dialogResult.subscribe((result) => {
			if (!result) {
				return;
			}
			this.tree.treeComponent.treeConfig.nodeMoveFunction(
				this.tree.treeComponent.treeConfig.functionScope,
				this.tree.node,
				result.targetNode.node
			);
		});
	}

	drop(event: CdkDragDrop<any>) {
		event.item.element.nativeElement.firstElementChild.classList.remove(
			"list-entered"
		);
		let originalNode = JSON.parse(
			event.item.element.nativeElement.firstElementChild.attributes["nodedata"]
				.value
		);

		this.tree.treeComponent.treeMoveNode(originalNode, this.tree.node);

		this.clearDragInfo(true);
	}

	//// New Drag fix

	dropActionTodo = null;

	dragMoved(event) {
		let e = this.document.elementFromPoint(
			event.pointerPosition.x,
			event.pointerPosition.y
		);

		if (!e) {
			this.clearDragInfo();
			return;
		}
		let container = e.classList.contains("node-item")
			? e
			: e.closest(".node-item");
		if (!container) {
			this.clearDragInfo();
			return;
		}
		this.dropActionTodo = {
			targetId: container.getAttribute("id"),
		};
		const targetRect = container.getBoundingClientRect();
		const oneThird = targetRect.height / 3;

		if (event.pointerPosition.y - targetRect.top < oneThird) {
			// before
			this.dropActionTodo["action"] = "before";
		} else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
			// after
			this.dropActionTodo["action"] = "after";
		} else {
			// inside
			this.dropActionTodo["action"] = "inside";
		}

		this.showDragInfo();
	}

	showDragInfo() {
		this.clearDragInfo();
		if (
			this.dropActionTodo &&
			this.document.getElementById(this.dropActionTodo.targetId)
		) {
			this.document
				.getElementById(this.dropActionTodo.targetId)
				.classList.add("drop-" + this.dropActionTodo.action);
		}
	}
	clearDragInfo(dropped = false) {
		if (dropped) {
			this.dropActionTodo = null;
		}
		this.document
			.querySelectorAll(".drop-before")
			.forEach((element) => element.classList.remove("drop-before"));
		this.document
			.querySelectorAll(".drop-after")
			.forEach((element) => element.classList.remove("drop-after"));
		this.document
			.querySelectorAll(".drop-inside")
			.forEach((element) => element.classList.remove("drop-inside"));
	}
}
