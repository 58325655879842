/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import { viewState } from "selector/view/view.selector";
import { ViewDistributionGroupState } from "reducers/view/view-distribution-groups.reducer";

import {
	aHubStateTemporaryDistributionGroupList,
	aHubStateTemporaryDistributionGroupDistributionIndexList,
	aHubStateTemporaryDistributionGroupUserIndexList,
	aHubStateTemporaryDistributionsList,
	aHubStateTemporaryUserList,
	aHubStateTemporaryUserIndexList,
} from "selector/ahub/ahub-temporary.selector";

import { ListUtil } from "store/list.util";
import { SelectorUtil } from "store/selector.util";

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */

import { RelationshipAHubVO } from "valueObjects/ahub/relationship.ahub.vo";
import { DistributionGroupAHubVO } from "valueObjects/ahub/accounts/distribution-group.ahub.vo";
import { DistributionIndexAHubVO } from "valueObjects/ahub/accounts/distribution-index.ahub.vo";
import { DistributionAHubVO } from "valueObjects/ahub/accounts/distribution.ahub.vo";
import { UserIndexAHubVO } from "valueObjects/ahub/accounts/user-index.ahub.vo";
import { UserAHubVO } from "app/valueObjects/ahub/accounts/user.ahub.vo";

/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Root selector to get the distribution group view state from the store state
 */
const viewDistributionGroupState = (state): ViewDistributionGroupState =>
	viewState(state).distributionGroup;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------


/**
 * Currently selected distribution group id
 */
export const viewSelectedDistributionGroupId = (state): number =>
	viewDistributionGroupState(state).selectedDistributionGroupId;

/**
 * Currently selected distribution id with the distribution group section
 */
export const viewSelectedDistributionGroupDistributionId = (state): number =>
	viewDistributionGroupState(state).selectedDistributionId;

/**
 * A selector which combines an aHub selector and the currently select item to
 */
export const viewSelectedDistributionGroup = (state): DistributionGroupAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryDistributionGroupList(state),
		viewSelectedDistributionGroupId(state)
	);

/**
 * A selector which combines an aHub selector and the currently selected distribution group
 */
export const viewSelectedDistributionGroupDistributionIndexesRelationship = (
	state
): RelationshipAHubVO<DistributionIndexAHubVO> =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryDistributionGroupDistributionIndexList(state),
		viewSelectedDistributionGroupId(state)
	);

/**
 * A selector which gets the distribution indexes from the relationship - null safe
 */
export const viewSelectedDistributionGroupDistributionIndexes = (
	state
): DistributionIndexAHubVO[] =>
	SelectorUtil.relationships(
		state,
		viewSelectedDistributionGroupDistributionIndexesRelationship
	);

/**
 * Get the selected distribution
 */
export const viewSelectedDistributionGroupDistribution = (
	state
): DistributionAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryDistributionsList(state),
		viewSelectedDistributionGroupDistributionId(state)
	);

/**
 * Get the user ralationships for the currently selected distribution group
 */
export const viewSelectedDistributionGroupUsersRelationship = (
	state
): RelationshipAHubVO<UserIndexAHubVO> =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryDistributionGroupUserIndexList(state),
		viewSelectedDistributionGroupId(state)
	);

/**
 * Get the selected distribution group users
 */
export const viewSelectedDistributionGroupUsers = (state): UserIndexAHubVO[] =>
	SelectorUtil.relationships(
		state,
		viewSelectedDistributionGroupUsersRelationship
	);

/**
 * This selector will get the currently selected export type id.
 */
export const viewSelectedExportTypeId = (state): number =>
	viewDistributionGroupState(state).selectedExportTypeId;

/**
 * A selector to get the currently selected user id.
 */
export const viewSelectedUserId = (state): number =>
	viewDistributionGroupState(state).selectedUserId;

/**
 * A selector which combines an aHub selector and the currently selected user id.
 */
export const viewSelectedUser = (state): UserAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryUserList(state),
		viewSelectedUserId(state)
	);

/**
 * A selector that combines the selected user id with the list of aHub user indexes in the temporary store.
 */
export const viewSelectedUserIndex = (state): UserIndexAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryUserIndexList(state),
		viewSelectedUserId(state)
	);
