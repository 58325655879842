import { Pipe, PipeTransform } from "@angular/core";

/**
 * Simple pipe to change a boolean value into a string.
 */
@Pipe({
	name: "booleanToString",
})
export class BooleanToStringPipe implements PipeTransform {
	transform(value: string, args?: any): any {
		// Return value.
		let returnValue: string;

		// What value did we get passed in?
		switch (value) {
			case "1":
				// Change to true.
				returnValue = "True";
				break;

			case "0":
				// Change it to false.
				returnValue = "False";
				break;

			default:
				// Otherwise use the one passed in.
				returnValue = value;
				break;
		}

		// Now return the value.
		return returnValue;
	}
}
