import { takeUntil, debounceTime } from "rxjs/operators";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { StoreAccess } from "store/store-access";
import { viewPropertyIconMap } from "selector/view/view-library-classification-class.selector";

import {
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { Hark, componentDestroyStream } from "modules/common/hark.decorator";
import { PropertyTestAHubVO } from "valueObjects/ahub/library/property-test.ahub.vo";
import { ProductPropertyFilterOptionData } from "modules/common/vo-render/product-property-filter/product-property-filter-option-data.vo";
import { ExtractDefinitionPropertyAllocationObjectVO } from "app/valueObjects/view/extract-definition-property-allocation.view.vo";
import { noWhitespaceValidator } from "app/modules/common/directives/validators/no-whitespace-validator.directive";
import { Utils } from "app/modules/common/utils";

const OPERATORS_BY_TYPE = {
	BOOLEAN: ["EQUALS", "NOT_EQUALS"],
	DECIMAL: ["EQUALS", "NOT_EQUALS", "LESS_OR_EQUAL", "GREATER_OR_EQUAL"],
	DATE_TIME: ["EQUALS", "NOT_EQUALS", "LESS_OR_EQUAL", "GREATER_OR_EQUAL"],
	GENDER: ["EQUALS", "NOT_EQUALS"],
	HTML: ["EQUALS", "NOT_EQUALS", "LIKE"],
	INTEGER: ["EQUALS", "NOT_EQUALS", "LESS_OR_EQUAL", "GREATER_OR_EQUAL"],
	PARAGRAPH: ["EQUALS", "NOT_EQUALS", "LIKE"],
	RGB: ["EQUALS", "NOT_EQUALS"],
	TEXTBLOCK: ["EQUALS", "NOT_EQUALS", "LIKE"],
	TEXTLINE: ["EQUALS", "NOT_EQUALS", "LIKE"],
	TEXTLIST: ["CONTAINS", "NOT_CONTAINS"],
	TOKEN: ["EQUALS", "NOT_EQUALS", "LIKE"],
	TOKENLIST: ["CONTAINS", "NOT_CONTAINS"],
	VOLUME: ["EQUALS", "NOT_EQUALS", "LESS_OR_EQUAL", "GREATER_OR_EQUAL"],
	WEIGHT: ["EQUALS", "NOT_EQUALS", "LESS_OR_EQUAL", "GREATER_OR_EQUAL"],
	WORD: ["EQUALS", "NOT_EQUALS", "LIKE"],
	IMAGE: ["EQUALS", "NOT_EQUALS"],
	MEASUREMENT: ["EQUALS", "NOT_EQUALS", "LESS_OR_EQUAL", "GREATER_OR_EQUAL"],
	AREA_DENSITY: ["EQUALS", "NOT_EQUALS", "LESS_OR_EQUAL", "GREATER_OR_EQUAL"],
	BLOB: ["EQUALS", "NOT_EQUALS"],
};

@Component({
	selector: "app-property-test-dialog",
	templateUrl: "./property-test-dialog.component.html",
	styleUrls: ["./property-test-dialog.component.scss"],
})
@Hark()
export class PropertyTestDialogComponent implements OnInit, OnDestroy {
	/**** REQUIRED FOR USE IN A DIALOGUE  ****/
	/**
	 * The VO that is passed in / out.
	 */
	public dialogVO: PropertyTestDialogVO = null;

	properties: ProductPropertyFilterOptionData[] = [];

	operators: string[] = [];

	values: string[] = [];

	propertyTypeReference: string = undefined;
	/**
	 * Boolean stream should emit values to indicate whether dialog is valid.
	 */
	public dialogIsValid$: Subject<boolean> = new Subject<boolean>();

	/**
	 * Form to control how the list is dispayed on screen
	 */
	propertyTestForm: UntypedFormGroup = this.formBuilder.group({
		productPropertyAllocId: ["", Validators.required],
		operator: ["", Validators.required],
		value: ["", [Validators.required, noWhitespaceValidator()]],
	});

	propertyIconMap = StoreAccess.dataGet(viewPropertyIconMap);

	/**
	 * Used to return the value of each 'select-with-search' option we should use for searching
	 */
	allocPropertyLabelValue = (
		option: ExtractDefinitionPropertyAllocationObjectVO
	) => {
		return option.property.label;
	};

	/**
	 * Used to determine which value of the option object should be applied if an option is selected
	 */
	allocIdValue = (option: ExtractDefinitionPropertyAllocationObjectVO) => {
		return option.id;
	};

	constructor(private formBuilder: UntypedFormBuilder) {}

	ngOnInit() {
		this.properties = this.sortAllocationsAlphabetically(
			this.dialogVO.propertySectionObjects
		);

		// Make sure the properties list at least an empty array.
		if (!this.properties) {
			this.properties = [];
		}

		this.propertyTestForm.controls.productPropertyAllocId.valueChanges
			.pipe(
				debounceTime(200),
				Utils.isNotNullOrUndefined(),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((allocId) => {
				const property = this.properties.find((alloc) => alloc.id === allocId);
				this.values = [];
				this.operators = this.setOperatorsAppropriateToProperty(
					property.property
				);
				this.propertyTypeReference = property.property.typeReference;
				switch (this.propertyTypeReference) {
					case "BOOLEAN":
						this.values = ["1", "0"];
						break;
					case "GENDER":
						this.values = ["M", "W", "U", "B", "G", "Y", "C", "I"];
						break;

					default:
						break;
				}

				if (
					property &&
					property.property &&
					property.property.enumOptions &&
					property.property.enumOptions.length > 0
				) {
					this.values = property.property.enumOptions;
				}
			});

		this.propertyTestForm.valueChanges
			.pipe(debounceTime(200), takeUntil(componentDestroyStream(this)))
			.subscribe((property) => {
				this.dialogIsValid$.next(this.propertyTestForm.valid);

				if (this.propertyTestForm.valid) {
					this.dialogVO.propertyTest = this.propertyTestForm.value;
				}
			});

		if (this.dialogVO.propertyTest) {
			this.propertyTestForm.reset(this.dialogVO.propertyTest);
		}
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	setOperatorsAppropriateToProperty(property) {
		if (property) {
			return OPERATORS_BY_TYPE[property.typeReference];
		}
	}

	sortAllocationsAlphabetically(allocations) {
		if (allocations) {
			return allocations.sort((a, b) => {
				if (a.property.label > b.property.label) {
					return 1;
				} else if (a.property.label < b.property.label) {
					return -1;
				}
				return a.section.label > b.section.label ? 1 : -1;
			});
		}
	}
}

export interface PropertyTestDialogVO {
	propertySectionObjects: ProductPropertyFilterOptionData[];
	propertyTest?: PropertyTestAHubVO;
}
