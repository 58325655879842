// @ts-nocheck
import { Directive, Input } from "@angular/core";
import { MatTooltip, TooltipPosition } from "@angular/material/tooltip";

/**
 * This directive is used when we want to show an objects id with a tool tip.
 */
@Directive({ selector: "[delayedToolTip]" })
export class DelayedToolTipDirective extends MatTooltip {
	/**
	 * By using the inputs name and then passing it to the message property
	 * we actually pass the message string down to the parent tool tip class.
	 */
	@Input("delayedToolTip") message: string;

	/**
	 * The name of the style to use in the tool tip.
	 */
	tooltipClass: string = "delayed-tool-tip";

	/**
	 * The tool tips location.
	 */
	position: TooltipPosition = "above";

	/**
	 * The default delay in ms before showing the tooltip after show is called.
	 */
	showDelay: number = 1500;
}
