import { Component, OnInit, Input, ViewContainerRef } from "@angular/core";
import { TreeConfiguration, TreeNodeVO } from "../tree.component";

@Component({
	selector: "svg:g[app-node-wrapper]",
	templateUrl: "./node-wrapper.component.html",
	styleUrls: ["./node-wrapper.component.css"],
})
export class NodeWrapperComponent implements OnInit {
	/**
	 * The node data for this part of the tree.
	 */
	@Input() node: TreeNodeVO;

	/**
	 * Indicates if this tree node has been identified by the tree as a drop source / target.
	 */
	@Input() isDropTarget: boolean = false;

	/**
	 * Tree configuration setout by the host component
	 */
	@Input() treeConfig: TreeConfiguration = new TreeConfiguration();

	/**
	 * Component
	 */
	private component = undefined;

	constructor(private viewContainerRef: ViewContainerRef) {}

	ngOnInit() {
		//Do we have a tree config and a node factory? Then we can create a component
		if (this.treeConfig && this.treeConfig.nodeFactory) {
			//OK we needed to create a namespace for the document as generating SVG's
			//from the component factory.
			//Used the suggestion from here: https://github.com/angular/angular/issues/10404
			let groupNode = document.createElementNS(
				"http://www.w3.org/2000/svg",
				"g"
			);
			this.component = this.treeConfig.nodeFactory.create(
				this.viewContainerRef.injector,
				[],
				groupNode
			);
			this.viewContainerRef.insert(this.component.hostView);

			//Set the node data set
			this.nodeDataSet();
		}
	}

	ngOnChanges(event) {
		//Set the node data set
		this.nodeDataSet();
	}

	/**
	 * Set the data set within the component
	 */
	private nodeDataSet() {
		//Bail out if we don't have a component
		if (this.component == undefined) return;

		//Set the tree data
		this.node.isDropTarget = this.isDropTarget; // We'll pass the isDropTarget as an updated propert in the treeNodeVO passed to eh render, rather than another seperate property.
		this.component.instance["node"] = this.node;
		this.component.instance["treeConfig"] = this.treeConfig;
	}
}
