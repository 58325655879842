import { Component, AfterViewInit } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams, RowNode } from "ag-grid-community";
import { ProductMatrixDefAHubVO } from "valueObjects/ahub/library/product-matrix-def.ahub.vo";
import { ProductPropertyTypeAHubVO } from "valueObjects/ahub/library/product-property-type.ahub.vo";
import { ProductPropertyAHubVO } from "valueObjects/ahub/library/product-property.ahub.vo";
import { ProductDataMatrixDataAHubVO } from "app/valueObjects/ahub/library/product-data-matrix-data.ahub.vo";
import { Utils as HarkUtils } from "app/modules/common/utils";
import { GridEditorComponentParams } from "../../../editors/grid-editor-component/grid-editor-component.component";
import { BehaviorSubject } from "rxjs";

@Component({
	selector: "app-product-property-matrix-editor",
	templateUrl: "./product-property-matrix-editor.component.html",
	styleUrls: ["./product-property-matrix-editor.component.css"],
})
export class ProductPropertyMatrixEditorComponent
	implements ICellEditorAngularComp, AfterViewInit
{
	/**
	 * Paramteres for this editor
	 */
	params: ICellEditorParams;

	/**
	 * Parameters with the custom
	 */
	paramsCustom: ProductPropertyMatrixEditorParams;

	/**
	 * Is the editor editable
	 */
	editable = false;

	/**
	 * Matrix which we will be displaying
	 */
	matrix: ProductMatrixDefAHubVO = undefined;

	/**
	 * Original raw data supplied to the editor
	 */
	originalRawData: ProductMatrixValue[] = [];

	/**
	 * Raw data for the grid
	 */
	rawData: ProductMatrixValue[] = [];
	rawData$: BehaviorSubject<ProductDataMatrixDataAHubVO[]> =
		new BehaviorSubject([]);

	/**
	 * Are we going to show the matrix string input. Some cases we wont
	 */
	matrixStringInputShow = true;

	productId: number;

	constructor() {
		// This is intentional
	}

	/**
	 * Init of the editor
	 */
	agInit(params: any): void {
		//Set the parameters
		this.params = params;
		this.paramsCustom = params as ProductPropertyMatrixEditorParams;

		this.productId = params.node.data.id;

		//Set the editable flag for this editor
		this.editable = this.paramsCustom.editable;

		//Call to get the matrix which is most appropriate for the class id supplied
		this.matrix = this.paramsCustom.matrixGet(this.params.node);

		this.rawData = (
			Array.isArray(this.params.value) ? HarkUtils.clone(this.params.value) : []
		) as ProductMatrixValue[];

		this.originalRawData = HarkUtils.clone(this.rawData);
	}

	ngAfterViewInit(): void {}

	matrixValueUpdated($event) {}

	/**
	 * Get the current value from the editor
	 */
	getValue() {
		//If
		//1) This is non editable  ORRRR
		//2) There was no data in the grid when we started and none when we finished
		//Then we will return the un-edited data!
		if (!this.editable || (this.rawData.length === 0 && !this.params.value)) {
			return this.params.value;
		}

		//I want to compare the matrix data with the previous state
		//if we either don't have the previous state or the current data bail out
		if (!this.rawData || !this.params.value) {
			return this.rawData;
		}

		//Looking for the first thing we can find that is different from
		//the source matrix supplied when we opend the renderer
		const firstChanged = this.rawData.find((changedMatrix) => {
			//Find the matching option for this matrix option
			const matchingOption = this.originalRawData.find(
				(original) =>
					JSON.stringify(original.id) === JSON.stringify(changedMatrix.id)
			);

			//Have we found an existing option
			if (matchingOption) {
				//We have an existing value if the values are differet then we have found our mathcing item
				return matchingOption.value !== changedMatrix.value;
			} else {
				//We didn't find a match ... but we will make sure our value for this item is not empty
				//as otherwise it may just mean the caller started to type a value then bailed out!
				return changedMatrix.value && changedMatrix.value.trim().length > 0;
			}
		});

		//If we found a changed item then we will return the changed data,
		//if there were no changes then we have nothing new to return so send the old data back!
		return firstChanged ? this.rawData : this.params.value;
	}

	/**
	 * Matrix is always a popup editor
	 */
	isPopup?(): boolean {
		return true;
	}

	/**
	 * Is called when editing is about to start. Giving
	 * us an opertunity to stop the editing before it happens
	 */
	isCancelBeforeStart(): boolean {
		//Do we have a supplied cancel function in the parameters
		if (this.paramsCustom.canEditCellFunc) {
			return !this.paramsCustom.canEditCellFunc(this.params);
		}

		//No is cancel function so no cancel behaviour
		return false;
	}

	doneEditing() {
		this.params.stopEditing();
	}
}

/**
 * Parameters for the product property matrix
 */
export interface ProductPropertyMatrixEditorParams
	extends GridEditorComponentParams {
	property: ProductPropertyAHubVO;
	propertyType: ProductPropertyTypeAHubVO;
	dataChanged?: (row, data: ProductDataMatrixDataAHubVO[]) => void;
	matrixGet: (rowNode: RowNode) => ProductMatrixDefAHubVO;
	editable: boolean;
}

/**
 * Interface for the product matrix values
 */
interface ProductMatrixValue {
	id: string[];
	value: string;
}
