/**
 * Action Types
 */
import {
	ActionNumber,
	ActionNumberArray,
	ActionString,
} from "actions/types/common.action-types";
import {
	ActionDataSetCategoryIndexAHubVOs,
	ActionExporterBlueprintMappingNodesAHubVO,
	ActionExporterMappingClassOptionAHubVOs,
} from "actions/types/library.action-types";
/**
 * Actions
 */
import { ViewActions } from "actions/view.actions";
import { ActionPropertyAllocationObjectAViewVOs } from "app/store/actions/types/view.action-types";
import { ActionWork } from "app/store/actions/types/work.action-types";
import { ListUtil } from "app/store/list.util";
import { List } from "app/store/list.vo";
import { ExporterMappingClassOptionAHubVO } from "app/valueObjects/ahub/library/exporter-mapping-class-option.ahub.vo";
import { PropertyAllocationObjectVO } from "app/valueObjects/stream/product-allocation-object-stream.vo";
import { BlueprintMappingNode } from "app/valueObjects/view/blueprint-mapping-node.ahub.vo";
import { tassign } from "modules/common/type-assign.util";
import { createReducer } from "reducers/reducer-util";
import { Reducer } from "redux";
import { DataSetCategoryIndexAHubVO } from "valueObjects/ahub/library/dataset-category-index.ahub.vo";

/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ViewLibraryPublishingState {
	selectedDataSetId: number;
	selectedDataSetCategoryIndexes: DataSetCategoryIndexAHubVO[];
	selectedExporterId: number;
	selectedExporterBuildHistoryId: number;
	selectedResourcePackId: number;
	selectedExporterBlueprintMappingNodes: BlueprintMappingNode[];
	selectedExporterBlueprintTreeNodePath: string;
	exporterMappingClassOptions: List<ExporterMappingClassOptionAHubVO>;
	selectedExporterMappingClassId: number;
	exporterIconMap: {};
	exporterDestinationIconMap: {};
	selectedExporterDestinationId: number;
	selectedExporterAllocs: PropertyAllocationObjectVO[];
	datasetCategoryIdsToBeCopied: number[];
}

const exporterIconMap = {
	STRING: "text_fields",
	LIST: "list",
};

const exporterDestinationIconMap = {
	API: "cloud",
	AHUB: "star_rate",
	FTP: "folder_shared",
	EMAIL: "attachment",
	CSV: "insert_drive_file",
};

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewLibraryPublishingInitialState: ViewLibraryPublishingState = {
	selectedDataSetId: -1,
	selectedDataSetCategoryIndexes: [],
	selectedExporterId: -1,
	selectedExporterBuildHistoryId: -1,
	selectedResourcePackId: -1,
	selectedExporterBlueprintMappingNodes: [],
	selectedExporterBlueprintTreeNodePath: undefined,
	exporterMappingClassOptions: ListUtil.listCreateEmpty("classId"),
	selectedExporterMappingClassId: -1,
	exporterIconMap: exporterIconMap,
	exporterDestinationIconMap: exporterDestinationIconMap,
	selectedExporterDestinationId: -1,
	selectedExporterAllocs: [],
	datasetCategoryIdsToBeCopied: [],
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the selected dataSet id
 */
export const selectedDataSetIdSet = (
	state: ViewLibraryPublishingState,
	action: ActionNumber
) => {
	//Set the selected dataSet id
	return tassign(state, { selectedDataSetId: action.number });
};

/**
 * Set the selected data set category indexes.
 */
export const selectedDataSetCategoryIndexesSet = (
	state: ViewLibraryPublishingState,
	action: ActionDataSetCategoryIndexAHubVOs
) => {
	//Set the selected dataSet id
	return tassign(state, {
		selectedDataSetCategoryIndexes: action.dataSetCategoryIndexes,
	});
};

/**
 * Set the selected exporter id.
 *
 * @param state             The state change.
 * @param action            The action to change with.
 */
export const selectedExporterIdSet = (
	state: ViewLibraryPublishingState,
	action: ActionNumber
) => {
	// Set the selected exporter id.
	return tassign(state, { selectedExporterId: action.number });
};

/**
 * Set the selected exporter id.
 *
 * @param state             The state change.
 * @param action            The action to change with.
 */
export const selectedExporterDestinationIdSet = (
	state: ViewLibraryPublishingState,
	action: ActionNumber
) => {
	// Set the selected exporter id.
	return tassign(state, { selectedExporterDestinationId: action.number });
};

/**
 * Set the selected exporter build history id.
 *
 * @param state             The state change.
 * @param action            The action to change with.
 */
export const selectedExporterBuildHistoryIdSet = (
	state: ViewLibraryPublishingState,
	action: ActionNumber
) => {
	// Set the selected exporter build history id.
	return tassign(state, { selectedExporterBuildHistoryId: action.number });
};

/**
 * Set the selected exporter allocs.
 *
 * @param state             The state change.
 * @param action            The action to change with.
 */
export const selectedExporterAllocsSet = (
	state: ViewLibraryPublishingState,
	action: ActionPropertyAllocationObjectAViewVOs
) => {
	// Set the selected exporter build history id.
	return tassign(state, { selectedExporterAllocs: action.allocs });
};

/**
 * Set the selected resource pack id.
 *
 * @param state             The state change.
 * @param action            The action to change with.
 */
export const selectedResourcePackIdSet = (
	state: ViewLibraryPublishingState,
	action: ActionNumber
) => {
	// Set the selected resource pack id.
	return tassign(state, { selectedResourcePackId: action.number });
};

/**
 * Set the blueprint nodes we have built from the blueprint (used to construct the blueprint tree)
 */
export const selectedExporterBlueprintMappingNodesSet = (
	state: ViewLibraryPublishingState,
	action: ActionExporterBlueprintMappingNodesAHubVO
) => {
	//Set the selected exporter blueprint mapping nodes
	return tassign(state, {
		selectedExporterBlueprintMappingNodes: action.nodes,
	});
};

/**
 * Clear the blueprint nodes we have built from the blueprint (used to construct the blueprint tree)
 */
export const selectedExporterBlueprintMappingNodesClear = (
	state: ViewLibraryPublishingState,
	action: ActionWork
) => {
	//Set the selected exporter blueprint mapping nodes
	return tassign(state, { selectedExporterBlueprintMappingNodes: [] });
};

/**
 * Set the selected exporter blueprint tree node
 */
export const selectedExporterBlueprintTreeNodePathSet = (
	state: ViewLibraryPublishingState,
	action: ActionString
) => {
	//Set the selected exporter blueprint tree node
	return tassign(state, {
		selectedExporterBlueprintTreeNodePath: action.string,
	});
};

/**
 * Clear the selected exporter blueprint tree node
 */
export const selectedExporterBlueprintTreeNodePathClear = (
	state: ViewLibraryPublishingState,
	action: ActionWork
) => {
	//Set the selected exporter blueprint tree node
	return tassign(state, { selectedExporterBlueprintTreeNodePath: undefined });
};

/**
 * Set the selected exporter mapping class option
 */
export const exporterMappingClassOptionsSet = (
	state: ViewLibraryPublishingState,
	action: ActionExporterMappingClassOptionAHubVOs
) => {
	return tassign(state, {
		exporterMappingClassOptions: ListUtil.listCreate(
			action.classOptions,
			"classId"
		),
	});
};

/**
 * Set the selected exporter mapping class option
 */
export const exporterMappingClassOptionsClear = (
	state: ViewLibraryPublishingState,
	action: ActionExporterMappingClassOptionAHubVOs
) => {
	return tassign(state, {
		exporterMappingClassOptions: ListUtil.listCreateEmpty("classId"),
	});
};

/**
 * Set the selected exporter mapping class id
 */
export const selectedExporterMappingClassIdSet = (
	state: ViewLibraryPublishingState,
	action: ActionNumber
) => {
	//Set the selected exporter blueprint tree node
	return tassign(state, { selectedExporterMappingClassId: action.number });
};

/**
 * dataset Category Ids To Be Copied
 */
export const datasetCategoryIdsToBeCopiedSet = (
	state: ViewLibraryPublishingState,
	action: ActionNumberArray
) => {
	//Set the selected exporter blueprint tree node
	return tassign(state, { datasetCategoryIdsToBeCopied: action.numbers });
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer
 */
reducerHandlers[ViewActions.LIBRARY_PUBLISHING_DATASET_SELECTED_ID_SET] =
	selectedDataSetIdSet;
reducerHandlers[
	ViewActions.LIBRARY_PUBLISHING_DATASET_CATEGORY_INDEXES_SELECTED_SET
] = selectedDataSetCategoryIndexesSet;
reducerHandlers[ViewActions.LIBRARY_PUBLISHING_EXPORTER_SELECTED_ID_SET] =
	selectedExporterIdSet;
reducerHandlers[
	ViewActions.LIBRARY_PUBLISHING_EXPORTER_DESTINATION_SELECTED_ID_SET
] = selectedExporterDestinationIdSet;
reducerHandlers[
	ViewActions.LIBRARY_PUBLISHING_EXPORTER_BUILD_HISTORY_SELECTED_ID_SET
] = selectedExporterBuildHistoryIdSet;
reducerHandlers[ViewActions.LIBRARY_PUBLISHING_SELECTED_EXPORTER_ALLOCS_SET] =
	selectedExporterAllocsSet;

reducerHandlers[ViewActions.LIBRARY_PUBLISHING_RESOURCE_PACK_SELECTED_ID_SET] =
	selectedResourcePackIdSet;
reducerHandlers[
	ViewActions.LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_MAPPING_NODES_SET
] = selectedExporterBlueprintMappingNodesSet;
reducerHandlers[
	ViewActions.LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_MAPPING_NODES_CLEAR
] = selectedExporterBlueprintMappingNodesClear;
reducerHandlers[
	ViewActions.LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_TREE_NODE_SELECTED_PATH_SET
] = selectedExporterBlueprintTreeNodePathSet;
reducerHandlers[
	ViewActions.LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_TREE_NODE_SELECTED_PATH_CLEAR
] = selectedExporterBlueprintTreeNodePathClear;
reducerHandlers[
	ViewActions.LIBRARY_PUBLISHING_EXPORTER_MAPPING_CLASS_OPTIONS_SET
] = exporterMappingClassOptionsSet;
reducerHandlers[
	ViewActions.LIBRARY_PUBLISHING_EXPORTER_MAPPING_CLASS_OPTIONS_CLEAR
] = exporterMappingClassOptionsClear;
reducerHandlers[
	ViewActions.LIBRARY_PUBLISHING_EXPORTER_MAPPING_CLASS_ID_SELECTED_SET
] = selectedExporterMappingClassIdSet;
reducerHandlers[ViewActions.DATASET_CATEGORY_IDS_TO_BE_COPIED_SET] =
	datasetCategoryIdsToBeCopiedSet;

/**
 * Create a reducers based on the reducers handlers
 */
export const ViewLibraryPublishingStateReducer: Reducer<ViewLibraryPublishingState> =
	createReducer(ViewLibraryPublishingInitialState, reducerHandlers);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewLibraryPublishingStateHasHandler = (
	actionType: string
): boolean => reducerHandlers.hasOwnProperty(actionType);
