import { RequestOptions } from "@harksolutions/ahub-web-services";
import {
	PresignedUrlAHubVO,
	RequestTicketAHubVO,
} from "@harksolutions/ahub-web-services-types";
import { Utils } from "app/modules/common/utils";
import { AHubServiceCredentialsVO } from "app/services/ahub/ahub-service-credentials.vo";
import { AHubService } from "app/services/ahub/ahub.service";
import { RequestActionStatusUploadVO } from "app/valueObjects/app/request-action-status-upload.vo";
import { RequestActionStatusEnum } from "app/valueObjects/app/request-action-status.app.enum";
import { RequestActionStatusVO } from "app/valueObjects/app/request-action-status.vo";
import { SessionAppVO } from "app/valueObjects/app/session.app.vo";
import { from, Observable } from "rxjs";
import { catchError, last, map, mergeMap, reduce, tap } from "rxjs/operators";
import { session, sessionUserSessionCredentials } from "selector/app.selector";
import { AppActions } from "../actions/app.actions";
import { ActionRequestActionStatusVO } from "../actions/types/ahub-work.action-types";
import { ActionWork } from "../actions/types/work.action-types";
import { StoreAccess } from "../store-access";

/**
 * Base class for the aHub epics
 */
export class AHubBaseEpic {
	constructor(public readonly aHubService: AHubService) {}

	/**
	 * Used to tap log of the action
	 */
	public tapLogAction<T>() {
		return (source$: Observable<T>) =>
			source$.pipe(
				tap((action: T) => {
					console.log(`action -> `, action);
				})
			);
	}

	/**
	 * Gets the client id currently set as default for all requests.
	 */
	public getClientId(): number {
		//Get the session credentials for the currently signed in user
		const sessionVO: SessionAppVO = StoreAccess.dataGet(session);

		//If we have no session then we will return with a -1 client id
		if (!sessionVO) {
			return -1;
		}

		// Check the id is set.
		if (!sessionVO.clientId) {
			return -1;
		}

		//Return the client id
		return sessionVO.clientId;
	}

	/**
	 * Get the default request parameters for signed requests
	 *
	 * @returns
	 */
	public reqOptSigned(): RequestOptions {
		//Start off with the unsigned ones
		const requestOptions = this.reqOptUnsigned();

		//Get the session credentials for the currently signed in user
		const sessionCredentials: AHubServiceCredentialsVO = StoreAccess.dataGet(
			sessionUserSessionCredentials
		);

		if (!sessionCredentials) {
			return requestOptions;
		}

		//Set the session credentials into the request options
		requestOptions.sessionId = sessionCredentials.sessionId;
		requestOptions.sessionKey = sessionCredentials.sessionKey;

		//Return the options with the session data attached
		return requestOptions;
	}

	/**
	 * Get the default request options for unsigned requests
	 *
	 * @returns
	 */
	public reqOptUnsigned(): RequestOptions {
		//Pssssst no special options
		return new RequestOptions();
	}

	/**
	 * Gets presigned urls for the filenames specified
	 * NB: we must batch the requests in order not to breach the url character limit of 2083
	 */
	presignedUrls = (
		prefixes,
		workflowReference
	): Observable<PresignedUrlAHubVO[]> => {
		const batchedPrefixes = [];
		let prefixBatch = [];
		let prefixBatchSize = 0;
		prefixes.forEach((prefix) => {
			prefixBatchSize += prefix.length;

			if (prefixBatchSize > 2000) {
				batchedPrefixes.push(Utils.clone(prefixBatch));
				prefixBatchSize = 0;
				prefixBatch = [];
			}

			prefixBatch.push(prefix);
		});

		// Flush out the remaining filepaths
		batchedPrefixes.push(Utils.clone(prefixBatch));

		return from(batchedPrefixes).pipe(
			mergeMap((batchOfPrefixes) => {
				return this.aHubService.workflowUploadSignedUrlsGet(
					workflowReference,
					batchOfPrefixes
				);
			}),
			reduce((acc: any[], val) => acc.concat(val), [])
		);
	};

	/**
	 * USE: CALLS THAT RETURN DATA TO BE STORED.
	 * Takes the resulting data from a service call and using the provided method for
	 * next action generation , generates the next action. Includes service call error
	 * catching which if triggered generates and dispatches a Error Action instead.
	 */
	public dataToAction<T>(
		fetchResult: Promise<T>,
		action: ActionWork,
		nextAction: (data: T) => ActionWork,
		faultAction?: (error: any) => ActionWork
	): Observable<ActionWork> {
		return from(fetchResult) // Get the result from service call to fetch data.
			.pipe(
				// turn the results into an actin using the action generation method passed in.
				map((results) => nextAction(results)),
				// Any errors generate a new action which logs the action in the store as an error.
				// Normally these fetch actions don't get logged, but we'll log teh ones that go wrong
				// so we can report on them.
				catchError((error: any): Observable<ActionWork> => {
					if (faultAction) {
						return from([faultAction(error)]);
					}

					return this.createErrorResponce(action, error);
				})
			);
	}

	/**
	 * USE: CALLS THAT RETURN DATA TO BE STORED.
	 * Takes the resulting data from a service call and using the provided method for
	 * next action generation , generates the next action. Includes service call error
	 * catching which if triggered generates and dispatches a Error Action instead.
	 */
	public dataArrayToAction<T>(
		fetchResult: Promise<T[]>,
		action: ActionWork,
		nextAction: (data: T[]) => ActionWork
	): Observable<ActionWork> {
		return from(fetchResult) // Get the result from service call to fetch data.
			.pipe(
				reduce((a, v) => a.concat(v), []), // Merge the results.
				last(), // everything in
				// turn the results into an actin using the action generation method passed in.
				map((results) => nextAction(results)),
				// Any errors generate a new action which logs the action in the store as an error.
				// Normally these fetch actions don't get logged, but we'll log teh ones that go wrong
				// so we can report on them.
				catchError(
					(
						error: any,
						caught: Observable<ActionWork>
					): Observable<ActionWork> => this.createErrorResponce(action, error)
				)
			);
	}

	/**
	 * USE: CALLS THAT RETURN REQUEST TICKETS.
	 * Takes a request VO generated from a service call to the aHub
	 * and converts to a Action status VO
	 * wraps it into an action ready for dispatch to have the orginating action
	 * recorded in the store for subsequent status updates by the workflows.
	 */
	public ticketToActionStatusVO(
		request: Promise<RequestTicketAHubVO>,
		action: ActionWork
	): Observable<ActionRequestActionStatusVO> {
		return from(request).pipe(
			// Take the request
			last(), // Once filly returned.
			map(
				(requestTicket: RequestTicketAHubVO): RequestActionStatusVO =>
					this.actionStatusUploadCreate(requestTicket, action, undefined)
			), // Convert to a Action Status VO - OK.
			catchError(
				// Catch any errors, and generate a Action Status with ERROR.
				(error: any, caught: Observable<RequestActionStatusVO>) => {
					return from([this.actionStatusCreateError(action, error)]);
				}
			),
			map((requestActionStatusVO) =>
				AppActions.sessionrequestActionStatusAppend(requestActionStatusVO)
			)
		);
	}

	/**
	 * Create a new ERROR request action status value object.
	 */
	public actionStatusCreateError(
		action: ActionWork,
		error: Object
	): RequestActionStatusVO {
		return {
			actionId: action.actionId,
			sentTime: new Date(),
			workflowReference: undefined,
			status: RequestActionStatusEnum.ERROR,
			fault: true,
			error: error["error"],
			errorCode: error.hasOwnProperty("status") ? error["status"] : 0,
			upload: undefined,
		};
	}

	/**
	 * Create a new request action status value object.
	 */
	public actionStatusCreate(
		requestTicket: RequestTicketAHubVO,
		action: ActionWork
	): RequestActionStatusVO {
		return this.actionStatusUploadCreate(requestTicket, action, undefined);
	}

	/**
	 * Create a new request action status value object.
	 */
	public actionStatusUploadCreate(
		requestTicket: RequestTicketAHubVO,
		action: ActionWork,
		upload: RequestActionStatusUploadVO
	): RequestActionStatusVO {
		return {
			actionId: action.actionId,
			sentTime: new Date(),
			workflowReference: requestTicket.workflowReference,
			status: RequestActionStatusEnum.WAITING,
			fault: false,
			upload: upload,
		};
	}

	/**
	 * Create a new ERROR request action status value object.
	 */
	public actionStatusCreateErrorFromAction(
		action: ActionWork,
		error: Object,
		requestActionStatus: RequestActionStatusVO
	): RequestActionStatusVO {
		//Clone the object
		requestActionStatus = Utils.clone(requestActionStatus);

		//Set the properties
		requestActionStatus.status = RequestActionStatusEnum.ERROR;
		requestActionStatus.fault = true;
		requestActionStatus.error = error["error"];
		requestActionStatus.errorCode = error.hasOwnProperty("status")
			? error["status"]
			: 0;

		//Return the action status
		return requestActionStatus;
	}

	/**
	 * USE: CALLS THAT RETURN DATA TO BE STORED INCREMENTALLY, SUCH AS PAGINATED DATA
	 * Takes the resulting data from a service call and using the provided method for
	 * next action generation , generates the next action. Includes service call error
	 * catching which if triggered generates and dispatches a Error Action instead.
	 */
	public incrementalDataToAction<T>(
		fetchResult: Promise<T>,
		action: ActionWork,
		nextAction: (data: T) => ActionWork
	): Observable<ActionWork> {
		return from(fetchResult).pipe(
			// Get the result from service call to fetch data.
			// turn the results into an actin using the action generation method passed in.
			map((results) => nextAction(results)),
			// Any errors generate a new action which logs the action in the store as an error.
			// Normally these fetch actions don't get logged, but we'll log the ones that go wrong
			// so we can report on them.
			catchError(
				(
					error: any,
					caught: Observable<ActionWork>
				): Observable<ActionWork> => {
					return from([
						AppActions.sessionrequestActionStatusAppend(
							this.actionStatusCreateError(action, error)
						),
					]);
				}
			)
		);
	}

	private createErrorResponce(
		action: ActionWork,
		error: Object
	): Observable<ActionRequestActionStatusVO> {
		console.log("to err is divine ", error);
		return from([
			AppActions.sessionrequestActionStatusAppend(
				this.actionStatusCreateError(action, error)
			),
		]);
	}

	/**
	 * Gets the id of the user currently logged in this session.
	 */
	public getUserId(): number {
		//Get the session credentials for the currently signed in user
		let sessionVO: SessionAppVO = StoreAccess.dataGet(session);

		//If we have no session then we will return with a -1 client id
		if (sessionVO == undefined || sessionVO == null) return -1;

		// Check the userId is set.
		if (sessionVO.userId == null) return -1;

		//Return the client id
		return sessionVO.userId;
	}
}
