import {
	ServiceACatalog,
	ServiceOptions,
} from "@harksolutions/ahub-web-services";
import {
	ACatalogDistributionAHubVO,
	SelectionConfigACatalogVO,
	SelectionIndexACatalogVO,
	UserIndexAHubVO,
} from "@harksolutions/ahub-web-services-types";
import { AHubService } from "app/services/ahub/ahub.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { ACatalogActions } from "../actions/acatalog.actions";
import { AViewActions } from "../actions/aview.actions";
import {
	ActionExportDistributionIndex,
	ActionSelectionACatalogVO,
	ActionSelectionFeedbackAdd,
	ActionSelectionFeedbackCommit,
	ActionSelectionFeedbackDelete,
	ActionSelectionProductReaction,
	ActionSelectionUserIdsACatalog,
	ActionSelectionUsersByEmailACatalog,
	ActionSelectionWithComponentIdACatalogVO,
} from "../actions/types/acatalog.action-types";
import { ActionNumber } from "../actions/types/common.action-types";
import { ActionWork } from "../actions/types/work.action-types";
import { StoreAccess } from "../store-access";
import { AHubBaseEpic } from "./ahub-base.epics";
import { Epic } from "./epic";

/**
 * Class for the View epic functions
 */
export class AHubACatalogEpics extends AHubBaseEpic implements Epic {
	serviceACatalog: ServiceACatalog = undefined;

	constructor(public readonly aHubService: AHubService) {
		super(aHubService);

		const serviceOptions = new ServiceOptions(
			environment.aHubApi.domain,
			environment.aHubApi.basePath
		);

		serviceOptions.logRequest = true;

		//Create our service library with the base parameters for our requests
		this.serviceACatalog = new ServiceACatalog(serviceOptions);
	}

	epicMethods(): any[] {
		return [
			this.selectionIndexesFetch,
			this.selectionByIdFetch,
			this.selectionsByUserIdFetch,
			this.selectionAdd,
			this.selectionCommit,
			this.selectionDelete,
			this.selectionUsersFetch,
			this.selectionUsersAdd,
			this.selectionUsersAddByEmail,
			this.selectionUsersDelete,
			this.selectionProductReactionCommit,
			this.selectionProductReactionDelete,
			this.selectionFeedbackAdd,
			this.selectionFeedbackCommit,
			this.selectionFeedbackDelete,
			this.selectionDefaultConfigsFetch,
			this.isPublicationAViewPro,
			this.aCatalogDistributionFetch,
		];
	}

	selectionIndexesFetch = (action$: Observable<ActionNumber>) =>
		action$.pipe(
			filter(
				({ type }) => type === ACatalogActions.ACATALOG_SELECTION_INDEXES_FETCH
			),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataArrayToAction(
					this.serviceACatalog.selectionIndexes(
						this.reqOptSigned(),
						action.number
					),
					action,
					(data: SelectionIndexACatalogVO[]) =>
						ACatalogActions.publicationSelectionIndexesSet(action.number, data)
				)
			)
		);

	selectionByIdFetch = (action$: Observable<ActionNumber>) =>
		action$.pipe(
			filter(
				({ type }) => type === ACatalogActions.ACATALOG_SELECTION_BY_ID_FETCH
			),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionById(
						this.reqOptSigned(),
						action.number
					),
					action,
					ACatalogActions.publicationSelectionSet
				)
			)
		);

	selectionsByUserIdFetch = (action$: Observable<ActionNumber>) =>
		action$.pipe(
			filter(
				({ type }) =>
					type === ACatalogActions.ACATALOG_SELECTIONS_BY_USER_ID_FETCH
			),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionsByUserId(
						this.reqOptSigned(),
						action.number
					),
					action,
					ACatalogActions.selectionsSet
				)
			)
		);

	selectionAdd = (
		action$: Observable<ActionSelectionWithComponentIdACatalogVO>
	) =>
		action$.pipe(
			filter(({ type }) => type === ACatalogActions.ACATALOG_SELECTION_ADD),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionAdd(
						this.reqOptSigned(),
						action.selection
					),
					action,
					(newSelectionId) => {
						StoreAccess.dispatch(
							ACatalogActions.publicationSelectionIndexesFetch(
								action.selection.publicationId
							)
						);
						return AViewActions.componentSelectionIdSet(
							action.componentId,
							newSelectionId
						);
					}
				)
			)
		);

	selectionCommit = (action$: Observable<ActionSelectionACatalogVO>) =>
		action$.pipe(
			filter(({ type }) => type === ACatalogActions.ACATALOG_SELECTION_COMMIT),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionCommit(
						this.reqOptSigned(),
						action.selection
					),
					action,
					(_) =>
						ACatalogActions.publicationSelectionIndexesFetch(
							action.selection.publicationId
						)
				)
			)
		);

	selectionDelete = (action$: Observable<ActionSelectionACatalogVO>) =>
		action$.pipe(
			filter(({ type }) => type === ACatalogActions.ACATALOG_SELECTION_DELETE),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionDelete(
						this.reqOptSigned(),
						action.selection.id
					),
					action,
					(_) =>
						ACatalogActions.publicationSelectionIndexesFetch(
							action.selection.publicationId
						)
				)
			)
		);

	selectionUsersFetch = (action$: Observable<ActionNumber>) =>
		action$.pipe(
			filter(
				({ type }) => type === ACatalogActions.ACATALOG_SELECTION_USERS_FETCH
			),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionUsers(
						this.reqOptSigned(),
						action.number
					),
					action,
					(data: UserIndexAHubVO[]) =>
						ACatalogActions.selectionUsersSet(action.number, data)
				)
			)
		);

	selectionUsersAdd = (action$: Observable<ActionSelectionUserIdsACatalog>) =>
		action$.pipe(
			filter(
				({ type }) => type === ACatalogActions.ACATALOG_SELECTION_USERS_ADD
			),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionUsersAdd(
						this.reqOptSigned(),
						action.selectionId,
						action.userIds
					),
					action,
					(_) => ACatalogActions.selectionUsersFetch(action.selectionId)
				)
			)
		);

	selectionUsersAddByEmail = (
		action$: Observable<ActionSelectionUsersByEmailACatalog>
	) =>
		action$.pipe(
			filter(
				({ type }) =>
					type === ACatalogActions.ACATALOG_SELECTION_USERS_ADD_BY_EMAIL
			),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionUsersAddByEmail(
						this.reqOptSigned(),
						action.selectionId,
						action.userEmails
					),
					action,
					(_) => ACatalogActions.selectionUsersFetch(action.selectionId)
				)
			)
		);

	selectionUsersDelete = (
		action$: Observable<ActionSelectionUserIdsACatalog>
	) =>
		action$.pipe(
			filter(
				({ type }) => type === ACatalogActions.ACATALOG_SELECTION_USERS_DELETE
			),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionUsersDelete(
						this.reqOptSigned(),
						action.selectionId,
						action.userIds
					),
					action,
					(_) => ACatalogActions.selectionUsersFetch(action.selectionId)
				)
			)
		);

	selectionProductReactionCommit = (
		action$: Observable<ActionSelectionProductReaction>
	) =>
		action$.pipe(
			filter(
				({ type }) =>
					type === ACatalogActions.ACATALOG_SELECTION_PRODUCT_REACTION_COMMIT
			),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionProductUserReactionCommit(
						this.reqOptSigned(),
						action.selectionId,
						action.productId,
						action.reaction
					),
					action,
					(_) => ACatalogActions.selectionByIdFetch(action.selectionId)
				)
			)
		);

	selectionProductReactionDelete = (
		action$: Observable<ActionSelectionProductReaction>
	) =>
		action$.pipe(
			filter(
				({ type }) =>
					type === ACatalogActions.ACATALOG_SELECTION_PRODUCT_REACTION_DELETE
			),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionProductUserReactionDelete(
						this.reqOptSigned(),
						action.selectionId,
						action.productId,
						action.reactionId
					),
					action,
					(_) => ACatalogActions.selectionByIdFetch(action.selectionId)
				)
			)
		);

	selectionFeedbackAdd = (action$: Observable<ActionSelectionFeedbackAdd>) =>
		action$.pipe(
			filter(
				({ type }) => type === ACatalogActions.ACATALOG_SELECTION_FEEDBACK_ADD
			),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionUserFeedbackAdd(
						this.reqOptSigned(),
						action.selectionId,
						action.feedback
					),
					action,
					(_) => ACatalogActions.selectionByIdFetch(action.selectionId)
				)
			)
		);

	selectionFeedbackCommit = (
		action$: Observable<ActionSelectionFeedbackCommit>
	) =>
		action$.pipe(
			filter(
				({ type }) =>
					type === ACatalogActions.ACATALOG_SELECTION_FEEDBACK_COMMIT
			),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionUserFeedbackCommit(
						this.reqOptSigned(),
						action.selectionId,
						action.selectionFeedbackId,
						action.feedbackMessage
					),
					action,
					(_) => ACatalogActions.selectionByIdFetch(action.selectionId)
				)
			)
		);

	selectionFeedbackDelete = (
		action$: Observable<ActionSelectionFeedbackDelete>
	) =>
		action$.pipe(
			filter(
				({ type }) =>
					type === ACatalogActions.ACATALOG_SELECTION_FEEDBACK_DELETE
			),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.selectionUserFeedbackDelete(
						this.reqOptSigned(),
						action.selectionId,
						action.selectionFeedbackId
					),
					action,
					(_) => ACatalogActions.selectionByIdFetch(action.selectionId)
				)
			)
		);

	selectionDefaultConfigsFetch = (action$: Observable<ActionWork>) =>
		action$.pipe(
			filter(
				({ type }) =>
					type === ACatalogActions.ACATALOG_SELECTION_DEFAULT_CONFIGS_FETCH
			),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataArrayToAction(
					this.serviceACatalog.selectionDefaultConfigsGet(this.reqOptSigned()),
					action,
					(data: SelectionConfigACatalogVO[]) =>
						ACatalogActions.selectionDefaultConfigsSet(data)
				)
			)
		);

	isPublicationAViewPro = (action$: Observable<ActionNumber>) =>
		action$.pipe(
			filter(
				({ type }) =>
					type === ACatalogActions.ACATALOG_IS_PUBLICATION_AVIEW_PRO_FETCH
			),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.publicationIsAViewPro(
						this.reqOptSigned(),
						action.number
					),
					action,
					(data: boolean) =>
						ACatalogActions.isPublicationAViewProSet(action.number, data)
				)
			)
		);

	aCatalogDistributionFetch = (
		action$: Observable<ActionExportDistributionIndex>
	) =>
		action$.pipe(
			filter(
				({ type }) => type === ACatalogActions.ACATALOG_DISTRIBUTION_FETCH
			),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceACatalog.aCatalogDistributionByPublicationAndDistributionGroupId(
						this.reqOptSigned(),
						action.exportDistributionIndex.exportId,
						action.exportDistributionIndex.distributionGroupIds[0]
					),
					action,
					(data: ACatalogDistributionAHubVO) =>
						ACatalogActions.aCatalogDistributionSet(
							action.exportDistributionIndex.id,
							data
						)
				)
			)
		);

	// shareSelectionTokenGenerate = (action$: Observable<ActionSelectionUserAddByEmailTokenPayload>) => action$.pipe(
	//   filter(({ type }) => type === ACatalogActions.ACATALOG_SHARE_SELECTION_TOKEN_GENERATE),
	//   // this.tapLogAction(),
	//   mergeMap(action => this.dataToAction(
	//     this.serviceACatalog.generateTokenForSelectionUserAddByEmail(this.reqOptSigned(),action.selectionUserAddByEmailTokenPayload),
	//     action,
	//     (data: SelectionConfigACatalogVO[]) => ACatalogActions.selectionDefaultConfigsSet(data))
	//   )
	// )
}
