import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface FullImageDialogData {
	imageUrl: string;
	imageName: string;
}

@Component({
	selector: "app-full-image-dialog",
	templateUrl: "./full-image-dialog.component.html",
	styleUrls: ["./full-image-dialog.component.scss"],
})
export class FullImageDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<FullImageDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: FullImageDialogData
	) {}

	closeDialog(): void {
		this.dialogRef.close();
	}

	ngOnInit(): void {}
}
