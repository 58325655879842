/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import { ViewRoutesState } from "reducers/view/view-routes.reducer";
import { viewState } from "selector/view/view.selector";

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */

import { RouteViewVO } from "valueObjects/view/route.view.vo";

/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Get the routes section from the current view state.
 */
const viewRoutes = (state): ViewRoutesState => viewState(state).routes;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */

/**
 * Get the routes from the state
 */
export const availableRoutes = (state): RouteViewVO[] =>
	viewRoutes(state).availableRoutes;

/**
 * Get the routes from the state
 */
export const allRoutes = (state): RouteViewVO[] => viewRoutes(state).allRoutes;

/**
 * Get the route view selected from the store
 */
export const selectedRouteView = (state): RouteViewVO =>
	viewRoutes(state).selectedRouteView;
