import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
	componentDestroyStream,
	Hark,
} from "app/modules/common/hark.decorator";

@Component({
	selector: "app-product-asset-view-dialog-flickbook",
	templateUrl: "./product-asset-view-dialog-flickbook.component.html",
	styleUrls: ["./product-asset-view-dialog-flickbook.component.css"],
})
@Hark()
export class ProductAssetViewDialogFlickbookComponent implements OnInit {
	/**
	 * Get all the images
	 */
	@ViewChildren("images") imagesQueryList: QueryList<any>;

	/**
	 * Parameters for the dialogue (must be null or property isn't found!)
	 */
	public dialogParams: ProductAssetViewDialogFlickbookComponentParams = null;

	/**
	 * Index of the current chapter
	 */
	chapterIndex: number = 0;

	/**
	 * Index of the current asset
	 */
	assetIndex: number = 0;

	/**
	 * Url's for the current chapter
	 */
	assetUrlsCurrentChapter: string[] = [];

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		//Do we have more than one asset?
		if (this.dialogParams) {
			//Get the urls for the first known chapter
			this.assetUrlsCurrentChapter =
				this.dialogParams.chapters.length > 0
					? this.dialogParams.chapters[this.chapterIndex].chapterUrls
					: [];

			//Get the rotation time
			let assetRotationTime = this.dialogParams.rotationIntervalMili
				? this.dialogParams.rotationIntervalMili
				: 0;

			//We just want to make sure that the asset isn't running too often
			assetRotationTime = Math.max(1000, assetRotationTime);

			//If so we will need to rotate the currently displayed asset
			timer(assetRotationTime, assetRotationTime)
				.pipe(takeUntil(componentDestroyStream(this)))
				.subscribe((time) => {
					//Up the asset image index
					this.assetIndex++;

					//If the asset index has gone off the end then we will reset the index to zero
					if (this.assetIndex >= this.assetUrlsCurrentChapter.length) {
						this.assetIndex = 0;
					}

					//Re-evalutate the current visible image
					this.evaluateVisibleImage();
				});
		}
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * Go to the next item
	 */
	nextChapter() {
		//Get the new index
		let newIndex = this.chapterIndex + 1;

		//If we went for the chapter after the last item we will skip round
		if (newIndex >= this.dialogParams.chapters.length) newIndex = 0;

		//Set the new index
		this.chapterIndex = newIndex;

		//Set the URL's and reset the index
		this.assetUrlsCurrentChapter =
			this.dialogParams.chapters[this.chapterIndex].chapterUrls;
		this.assetIndex = 0;
	}

	/**
	 * Go to the previous chapter
	 */
	previousChapter() {
		//Get the new index
		let newIndex = this.chapterIndex - 1;

		//If we went for the chapter before we will skip to the last chapter
		if (newIndex < 0) newIndex = this.dialogParams.chapters.length - 1;

		//Set the new index
		this.chapterIndex = newIndex;

		//Set the URL's and reset the index
		this.assetUrlsCurrentChapter =
			this.dialogParams.chapters[this.chapterIndex].chapterUrls;
		this.assetIndex = 0;
	}

	/**
	 * Evaluate the currently visible asset
	 */
	evaluateVisibleImage() {
		//Convert the query list into an array
		let elementsAsArray = this.imagesQueryList.toArray();

		//Make all the asset hidden
		elementsAsArray.forEach((image) => {
			if (image.nativeElement) {
				image.nativeElement.style.display = "none";
			}
		});

		//Set the specific asset to hidden
		if (
			elementsAsArray[this.assetIndex] &&
			elementsAsArray[this.assetIndex].nativeElement
		) {
			elementsAsArray[this.assetIndex].nativeElement.style.display = "block";
		}
	}
}

/**
 * Define parameters for setting up the component
 */
export interface ProductAssetViewDialogFlickbookComponentParams {
	sectionName: string;
	sectionColour: string;
	propertyName: string;
	rotationIntervalMili: number;
	chapters: ProductAssetViewDialogFlickbookComponentParamsChapter[];
}

/**
 * Define a chapter
 */
export interface ProductAssetViewDialogFlickbookComponentParamsChapter {
	chapterId: string;
	chapterLabel: string;
	chapterUrls: string[];
}
