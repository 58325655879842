import { Action, Reducer } from "redux";
import { createReducer } from "reducers/reducer-util";
import { tassign } from "modules/common/type-assign.util";

/**
 * Action Types
 */
import {
	ActionNumber,
	ActionStringArray,
} from "actions/types/common.action-types";

/**
 * Actions
 */
import { ViewActions } from "actions/view.actions";

/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ViewExportState {
	selectedExportId: number;
	selectedExportDistributionId: number;
	selectedExportVersionId: number;
	selectedDistributionId: number;
	exportVersionHideStates: string[];
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewExportsInitialState: ViewExportState = {
	selectedExportId: -1,
	selectedExportDistributionId: -1,
	selectedExportVersionId: -1,
	selectedDistributionId: -1,
	exportVersionHideStates: ["DELETED"],
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the selected export id
 */
export const selectedExportIdSet = (
	state: ViewExportState,
	action: ActionNumber
) => {
	//Set the selected export id
	return tassign(state, { selectedExportId: action.number });
};

/**
 * Set the selected export distribution id
 */
export const selectedExportDistributionIdSet = (
	state: ViewExportState,
	action: ActionNumber
) => {
	//Set the selected export distribution id
	return tassign(state, { selectedExportDistributionId: action.number });
};

/**
 * Set the selected export version id
 */
export const selectedExportVersionIdSet = (
	state: ViewExportState,
	action: ActionNumber
) => {
	//Set the selected export version id
	return tassign(state, { selectedExportVersionId: action.number });
};

/**
 * Set the hide version states
 */
export const exportVersionHideStatesSet = (
	state: ViewExportState,
	action: ActionStringArray
) => {
	//Set the selected export version id
	return tassign(state, { exportVersionHideStates: action.strings });
};

/**
 * Set the selected distribution id
 */
export const selectedDistributionIdSet = (
	state: ViewExportState,
	action: ActionNumber
) => {
	//Set the selected distribution id
	return tassign(state, { selectedDistributionId: action.number });
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer
 */
reducerHandlers[ViewActions.EXPORT_SELECTED_ID_SET] = selectedExportIdSet;
reducerHandlers[ViewActions.EXPORT_VERSION_SELECTED_ID_SET] =
	selectedExportVersionIdSet;
reducerHandlers[ViewActions.EXPORT_VERSIONS_HIDE_STATES] =
	exportVersionHideStatesSet;
reducerHandlers[ViewActions.DISTRIBUTION_SELECTED_ID_SET] =
	selectedDistributionIdSet;
reducerHandlers[ViewActions.EXPORT_DISTRIBUTION_SELECTED_ID_SET] =
	selectedExportDistributionIdSet;

/**
 * Create a reducers based on the reducers handlers
 */
export const ViewExportStateReducer: Reducer<ViewExportState> = createReducer(
	ViewExportsInitialState,
	reducerHandlers
);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewExportStateHasHandler = (actionType: string): boolean =>
	reducerHandlers.hasOwnProperty(actionType);
