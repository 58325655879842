import { Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import {
	AbstractControl,
	NG_VALIDATORS,
	Validator,
	ValidatorFn,
	Validators,
} from "@angular/forms";

/**
 * The string must only contain alpha-numeric characters.
 */
export function alphaNumericValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const forbidden = /[^A-Za-z0-9]+/g.test(control.value);
		return forbidden ? { alphaNumeric: { value: control.value } } : null;
	};
}

@Directive({
	selector: "[alphaNumeric]",
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: AlphaNumericValidatorDirective,
			multi: true,
		},
	],
})
export class AlphaNumericValidatorDirective implements Validator {
	validate(control: AbstractControl): { [key: string]: any } {
		return alphaNumericValidator()(control);
	}
}
