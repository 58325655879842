import { map, combineLatest } from "rxjs/operators";
import { Observable } from "rxjs";
import { List } from "store/list.vo";

/**
 * Store
 */
import { StoreAccess } from "store/store-access";
import { sessionClientId } from "selector/app.selector";
import {
	aHubStateTemporaryClientQuotas,
	aHubStateTemporaryClientQuotasHistory,
} from "selector/ahub/ahub-temporary.selector";
import { viewSelectedClientId } from "selector/view/view-account-management.selector";

/**
 * Value Objects
 */
import { ClientQuotaAHubVO } from "valueObjects/ahub/accounts/client-quota.ahub.vo";

export class ClientQuotasStream {
	/**
	 * Observe the currently selected client id
	 */
	selectedClientId$: Observable<number> =
		StoreAccess.dataGetObvs(viewSelectedClientId);

	clientQuotas$: Observable<List<ClientQuotaAHubVO>> = StoreAccess.dataGetObvs(
		aHubStateTemporaryClientQuotas
	);

	clientQuotasHistory$: Observable<List<ClientQuotaAHubVO>> =
		StoreAccess.dataGetObvs(aHubStateTemporaryClientQuotasHistory);

	getClientQuotasBySelectedClientId(): Observable<ClientQuotaAHubVO[]> {
		return this.clientQuotas$.pipe(
			combineLatest(
				this.selectedClientId$,
				(clientQuotas, selectedClientId) => ({ clientQuotas, selectedClientId })
			),
			map((combined) =>
				combined.clientQuotas.data
					.filter((item, index, items) => {
						return item["clientId"] === combined.selectedClientId;
					})
					.sort((a, b) => {
						return a.quotaType > b.quotaType ? 1 : -1;
					})
			)
		);
	}

	getClientQuotasHistoryByClientId(clientId): Observable<ClientQuotaAHubVO[]> {
		return this.clientQuotasHistory$.pipe(
			map((clientQuotasHistory) =>
				clientQuotasHistory.data.filter((item, index, items) => {
					return item["clientId"] === clientId;
				})
			)
		);
	}
}
