import {
	ExportDistributionIndexAHubVO,
	SelectionConfigACatalogVO,
	ACatalogDistributionAHubVO,
	SelectionIndexACatalogVO,
	SelectionProductUserReactionACatalogVO,
	UserIndexAHubVO,
} from "@harksolutions/ahub-web-services-types";
import { FeedbackView } from "app/modules/common/components/feedback/feedback-message-widget/feedback-message-widget.component";
import { List } from "../list.vo";
import { MapStorageUtil } from "../map-storage.util";
import { MapStorage } from "../map-storage.vo";
import { ACatalogState } from "../reducers/acatalog.reducer";

const aCatalogState = (state): ACatalogState => state.aCatalog;

export const publicationSelectionIndexesMap = (state) =>
	aCatalogState(state).publicationSelectionIndexes;

export const publicationSelectionIndexes =
	(publicationId: number) =>
	(state): SelectionIndexACatalogVO[] => {
		return MapStorageUtil.mapStorageGet(
			publicationSelectionIndexesMap(state),
			publicationId ? publicationId.toString() : ""
		);
	};

export const selectionList = (state) => aCatalogState(state).selectionList;

export const usersMap = (state) => aCatalogState(state).selectionUsers;

export const selectionUsers =
	(selectionId: number) =>
	(state): UserIndexAHubVO[] => {
		return MapStorageUtil.mapStorageGet(
			usersMap(state),
			selectionId ? selectionId.toString() : ""
		);
	};

export const selectionFeedbackViewsMap = (state): MapStorage<FeedbackView[]> =>
	aCatalogState(state).selectionFeedbackViewsMap;

export const selectionFeedbackViews =
	(selectionId: number) =>
	(state): FeedbackView[] => {
		return MapStorageUtil.mapStorageGet(
			aCatalogState(state).selectionFeedbackViewsMap,
			selectionId ? selectionId.toString() : ""
		);
	};

export const selectionProductReactions =
	(selectionId: number, productId: number) =>
	(state): SelectionProductUserReactionACatalogVO[] => {
		return MapStorageUtil.mapStorageGet(
			MapStorageUtil.mapStorageGet(
				aCatalogState(state).selectionProductReactionMap,
				selectionId.toString()
			),
			productId.toString()
		);
	};

export const selectionProductFeedbackViews =
	(selectionId: number, productId: number) =>
	(state): FeedbackView[] => {
		return MapStorageUtil.mapStorageGet(
			MapStorageUtil.mapStorageGet(
				aCatalogState(state).selectionProductFeedbackViewsMap,
				selectionId.toString()
			),
			productId.toString()
		);
	};

export const selectionDefaultConfigs = (state): SelectionConfigACatalogVO[] =>
	aCatalogState(state).selectionDefaultConfigs;

export const publicationIsAViewProMap = (state) =>
	aCatalogState(state).publicationIsAViewProMap;

export const isPublicationAViewPro =
	(publicationId: number) =>
	(state): boolean => {
		let result = MapStorageUtil.mapStorageGet(
			publicationIsAViewProMap(state),
			publicationId ? publicationId.toString() : ""
		);
		return result ? true : false;
	};

export const publicationExportDistributionIndexesMap = (state) =>
	aCatalogState(state).publicationExportDistributionIndexesMap;

export const publicationExportDistributionIndexes =
	(publicationId: number) =>
	(state): ExportDistributionIndexAHubVO[] => {
		return MapStorageUtil.mapStorageGet(
			publicationExportDistributionIndexesMap(state),
			publicationId ? publicationId.toString() : ""
		);
	};

export const aCatalogDistributionList = (
	state
): List<ACatalogDistributionAHubVO> =>
	aCatalogState(state).aCatalogDistributionList;
