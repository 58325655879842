import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ProductMatrixDefAHubVO } from "app/valueObjects/ahub/library/product-matrix-def.ahub.vo";
import { ProductMatrixDefDimensionAHubVO } from "app/valueObjects/ahub/library/product-matrix-def-dimension.ahub.vo";
import { ProductDataMatrixDataAHubVO } from "app/valueObjects/ahub/library/product-data-matrix-data.ahub.vo";
import { GridOptions, ColDef } from "ag-grid-community";
import { ProductPropertyGridColumnWriteUtil } from "../../components/ag-grid/product-grid/utils/product-property-grid-column-write-util";
import { StoreAccess } from "app/store/store-access";
import { AppActions } from "app/store/actions/app.actions";
import { Utils } from "../../utils";
import { ProductPropertyAHubVO } from "app/valueObjects/ahub/library/product-property.ahub.vo";
import { ProductPropertyTypeAHubVO } from "app/valueObjects/ahub/library/product-property-type.ahub.vo";
import { GridRendererBooleanParams } from "../../components/ag-grid/renderer/grid-renderer-boolean/grid-renderer-boolean.component";

@Component({
	selector: "app-matrix-property-editor",
	templateUrl: "./matrix-property-editor.component.html",
	styleUrls: ["./matrix-property-editor.component.css"],
})
export class MatrixPropertyEditorComponent implements OnInit {
	/**
	 * Matrix which we will be displaying
	 */
	@Input() matrix: ProductMatrixDefAHubVO = undefined;

	/**
	 * Raw data for the grid
	 */
	@Input() rawData: ProductDataMatrixDataAHubVO[] = [];

	@Input() property: ProductPropertyAHubVO;

	@Input() propertyType: ProductPropertyTypeAHubVO;

	@Input() dataChangedFunc: any;

	@Input() editable = false;

	@Input() productId? = 0;

	@Input() showDoneButton? = false;

	@Input() showTitle? = true;

	@Input() showRawDataInput? = true;

	@Output() matrixValueUpdated: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	@Output() doneEditing: EventEmitter<boolean> = new EventEmitter<boolean>();

	originalRawData: ProductDataMatrixDataAHubVO[] = [];
	/**
	 * z axis dimension, this will be displayed on the
	 */
	zAxisDimension: ProductMatrixDefDimensionAHubVO = undefined;

	/**
	 * Setup the basic definition for the table
	 */
	gridOptions: GridOptions = {
		rowData: [],
		columnDefs: [],
		stopEditingWhenGridLosesFocus: true,
		onGridReady: () => {
			this.columnsSet();
			this.dataSet();
		},
		getRowNodeId: (data) => data.axis,
		onCellValueChanged: (e) => {
			//How many dimensions do we have
			const dimensionCount = this.matrix.dimensions.length;

			//We will construst an array which will match the id we are looking for
			//start with the first dimension
			const findId = [e.colDef.colId];

			//If we have more then 1 we will add in the 2nd dimension. If more than 2 add in the 3rd
			if (dimensionCount > 1) {
				findId.push(e.data.axis);
			}
			if (dimensionCount > 2) {
				findId.push(this.zAxisDimensionValue);
			}

			//Option which we want to find
			const option = this.rawData.find((opt) => {
				//If the length of the id doesn't match the amount of ids of our id then they don't match
				if (opt.id.length !== findId.length) {
					return false;
				}

				//Loop trough the parts of the id looking for a part that doesn't match, bail when we find one
				for (let i = 0; i < findId.length; i++) {
					if (findId[i] !== opt.id[i]) {
						return false;
					}
				}

				//Looks like our guy return true
				return true;
			});

			//If we have the option set it, if not then we will add it to the list
			if (option) {
				option.value = e.value;
			} else {
				this.rawData.push({ id: findId, value: e.value });
			}

			//Stringify the raw data
			this.matrixString = JSON.stringify(this.rawData);

			this.matrixValueUpdated.emit(true);
		},
		singleClickEdit: true,
		suppressColumnVirtualisation: true,
		defaultColDef: {
			resizable: true,
			filter: false,
		},
	} as GridOptions;

	/**
	 * Column defintions for the matrix
	 */
	columns: ColDef[] = [];

	/**
	 * Data for the grid
	 */
	data: any = [];

	/**
	 * Identifies which dimension we are showing on the y axis
	 */
	zAxisDimensionValue = undefined;

	/**
	 * String value for the data being stored in the matrix
	 */
	matrixString = "";

	/**
	 * Define the grid renders we want to use in this grid
	 */
	frameworkComponents =
		ProductPropertyGridColumnWriteUtil.frameworkComponents();

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		//Check if we have a 3rd dimention
		if (
			this.matrix &&
			this.matrix.dimensions &&
			this.matrix.dimensions.length > 2
		) {
			//Get the z axis dimension from the matrix
			this.zAxisDimension = this.matrix.dimensions[2];

			//Set the first value in the 3rd dimension as the currently selected value
			this.zAxisDimensionValue =
				this.zAxisDimension.options && this.zAxisDimension.options.length > 0
					? this.zAxisDimension.options[0].id
					: undefined;
		}

		this.originalRawData = Utils.clone(this.rawData);
		this.matrixString = JSON.stringify(this.rawData);

		//Build the grid
		this.buildGridHeadings();
		this.buildGridData();
	}

	/**
	 * Set the columns to the current set of columns
	 */
	columnsSet() {
		//Bail out
		if (!this.gridOptions.api) {
			return;
		}

		//Calling the auto size for the matrix
		this.gridOptions.columnApi.autoSizeAllColumns();

		//Set the column definitions from the
		this.gridOptions.api.setColumnDefs(this.columns);

		//Calling the auto size for the matrix
		this.gridOptions.columnApi.autoSizeAllColumns();
	}

	/**
	 * Set the data in the grid
	 */
	dataSet() {
		//Bail out
		if (!this.gridOptions.api) {
			return;
		}

		//Calling the auto size for the matrix
		this.gridOptions.columnApi.autoSizeAllColumns();

		//Set the row data
		this.gridOptions.api.setRowData(this.data);
	}

	/**
	 * Called when the user selects the dimension they wish to view
	 */
	matrixDimensionChange(dimensionValue: string) {
		//Set the y axis dimension
		this.zAxisDimensionValue = dimensionValue;

		//Rebuild the grid data
		this.buildGridData();
	}

	/**
	 * Build the grid data
	 */
	buildGridData() {
		//If we don't have a matrix or dimension then we cannot build the grid
		if (!this.matrix || !this.matrix.dimensions) {
			return;
		}

		//Variable to tell if we have more than one dimension, nothing to do with one direction!
		const dimensions = this.matrix.dimensions.length;

		//Data we want to set in the column
		const newData = [];

		const id = this.productId;

		//We have multiple rows of data
		if (dimensions === 1) {
			//Create a row
			const row = {
				id,
			};

			//Loop through all the row data setting the row appropriatly
			this.rawData.forEach((val) => {
				row[val.id[0]] = val.value;
			});

			//Add the row to the new data
			newData.push(row);
		} else if (dimensions === 2) {
			//OK take the dimension we are using and we will loop through it looking for our data
			this.matrix.dimensions[1].options.forEach((option) => {
				//Create a basis for the row
				const row = { id, axis: option.id };

				//Filter out the row data down to those results which match the 2nd dimention (row)
				const dataForD2ND3Val = this.rawData.filter(
					(item) => item.id[1] === option.id
				);

				//Once we have our filtered list then we want to set each item into the row
				dataForD2ND3Val.forEach((matrixVal) => {
					row[matrixVal.id[0]] = matrixVal.value;
				});

				//Add the new row into the data
				newData.push(row);
			});
		} else if (dimensions === 3) {
			//OK take the dimension we are using and we will loop through it looking for our data
			this.matrix.dimensions[1].options.forEach((option) => {
				//Create a basis for the row
				const row = { id, axis: option.id };

				//Filter out the row data down to those results which match the 2nd dimention (row) and the selected value on the z axis
				const dataForD2ND3Val = this.rawData.filter(
					(item) =>
						item.id[1] === option.id && item.id[2] === this.zAxisDimensionValue
				);

				//Once we have our filtered list then we want to set each item into the row
				dataForD2ND3Val.forEach((matrixVal) => {
					row[matrixVal.id[0]] = matrixVal.value;
				});

				//Add the new row into the data
				newData.push(row);
			});
		}

		//Set the data we will use for the grid
		this.data = newData;

		//Set the data
		this.dataSet();
	}

	/**
	 * Handel the key down events on the grid
	 */
	gridKeyDownHan(event) {
		//Is this key an enter press we want to stop propergration
		//if we don't stop the event the parent grid will close our
		//custom renderer as well.
		if (event.key === "Enter") {
			event.stopPropagation();
		}

		if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
			event.stopPropagation();
		}
	}

	/**
	 * Handel the change of the matrix input value
	 */
	matrixInputValueChange(event) {
		//Try to parse the existing data
		try {
			//Parse the data
			const parsedData = JSON.parse(event);

			//Parse the data into an object array
			this.rawData = (
				Array.isArray(parsedData) ? parsedData : []
			) as ProductDataMatrixDataAHubVO[];

			//Rebuild the grid data
			this.buildGridData();

			//Set the string
			this.matrixString = event;
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * Copy the value to the clipboad
	 */
	copyValueToClipboard() {
		//Get the value string if we have a value if not then we will set null
		const valueStr = this.getValue()
			? JSON.stringify(this.getValue())
			: undefined;

		//Distpatch a copy to the clipboard action
		if (valueStr) {
			StoreAccess.dispatch(AppActions.clipboardTextCopySet(valueStr));
		}
	}

	/**
	 * Get the current value from the editor
	 */
	getValue() {
		if (!this.rawData || this.rawData.length === 0 || !this.editable) {
			return this.rawData;
		}

		//Looking for the first thing we can find that is different from
		//the source matrix supplied when we opend the renderer
		const firstChanged = this.rawData.find((changedMatrix) => {
			//Find the matching option for this matrix option
			const matchingOption = this.originalRawData.find(
				(original) =>
					JSON.stringify(original.id) === JSON.stringify(changedMatrix.id)
			);

			//Have we found an existing option
			if (matchingOption) {
				//We have an existing value if the values are differet then we have found our mathcing item
				return matchingOption.value !== changedMatrix.value;
			} else {
				//We didn't find a match ... but we will make sure our value for this item is not empty
				//as otherwise it may just mean the caller started to type a value then bailed out!
				return changedMatrix.value && changedMatrix.value.trim().length > 0;
			}
		});

		//If we found a changed item then we will return the changed data,
		//if there were no changes then we have nothing new to return so send the old data back!
		return firstChanged ? this.rawData : this.originalRawData;
	}

	/**
	 * Build the grid heading based on the matrix
	 */
	buildGridHeadings() {
		//No matrix / no dimentions no grid!
		if (
			!this.matrix ||
			!this.matrix.dimensions ||
			this.matrix.dimensions.length === 0
		) {
			return;
		}

		//--------------------COLUMN TEMPLATE--------------------
		//

		//We will create a column template which we will setup. All columns will look & react the same
		//rather than set each one up we will setup one and duplicate it!
		const colDefTemplate: ColDef =
			ProductPropertyGridColumnWriteUtil.columnWriteFilterForProperty({
				property: this.property,
				propertyType: this.propertyType,
			});

		//Set the editability of the column
		colDefTemplate.editable = this.editable;

		//If this is a date then we will set a new minimum size
		if (this.property.typeReference === "DATE_TIME") {
			colDefTemplate.minWidth = 135;
			colDefTemplate.width = 135;
		} else if (this.property.typeReference === "BOOLEAN") {
			colDefTemplate.cellRendererParams = {
				startEditFromCheckbox: true,
			} as GridRendererBooleanParams;
		}

		//-------------------- END COLUMN TEMPLATE--------------------

		//Colums for the matrix
		const cols: ColDef[] = [];

		//Take the first dimension and generate a series of column headers for the data
		this.matrix.dimensions[0].options.forEach((option, i) => {
			//Clone the template column
			const newColumn = Utils.clone(colDefTemplate);

			//Setup the column specific data
			newColumn.headerName = option.id;
			newColumn.colId = option.id;

			//Had to use getters and setters as for some reason we where having issues with options with a '.'
			//expect its some property dot thing!
			newColumn.valueGetter = (cell) =>
				cell.data ? cell.data[option.id] : undefined;
			newColumn.valueSetter = (cell) => {
				cell.data[option.id] = cell.newValue;
				return true;
			};

			//Add the new column to the list
			cols.push(newColumn);
		});

		//Variable to tell if we have more than one dimension, nothing to do with one direction!
		const moreThan1D = this.matrix.dimensions.length > 1;

		//If we are displaying more than 1 dimentions
		//then we will need to add a column at the front to be used for
		//the other axis
		if (moreThan1D) {
			cols.unshift({
				headerName: "Dimension 2",
				field: "axis",
				width: 110,
				suppressMovable: true,
				pinned: "left",
				cellStyle: () => {
					return { "background-color": "#F5F7F7" };
				},
			});
		}

		//Set to the current set of columns
		this.columns = cols;

		//Set the columns
		this.columnsSet();
	}

	done() {
		this.doneEditing.emit(true);
	}
}
