import { tassign } from "modules/common/type-assign.util";
import {
	ViewAccountManagementInitialState,
	ViewAccountManagementState,
	ViewAccountManagementStateHasHandler,
	ViewAccountManagementStateReducer,
} from "reducers/view/view-accounts-management.reducer";
import {
	ViewClientLibraryVersionInitialState,
	ViewClientLibraryVersionState,
	ViewClientLibraryVersionStateHasHandler,
	ViewClientLibraryVersionStateReducer,
} from "reducers/view/view-client-library-version.reducer";
import {
	ViewDistributionGroupInitialState,
	ViewDistributionGroupReducer,
	ViewDistributionGroupReducerHasHandler,
	ViewDistributionGroupState,
} from "reducers/view/view-distribution-groups.reducer";
import {
	ViewDragInitialState,
	ViewDragState,
	ViewDragStateHasHandler,
	ViewDragStateReducer,
} from "reducers/view/view-drag.reducer";
import {
	ViewExportsInitialState,
	ViewExportState,
	ViewExportStateHasHandler,
	ViewExportStateReducer,
} from "reducers/view/view-export.reducer";
import {
	ViewLibraryChainingInitialState,
	ViewLibraryChainingState,
	ViewLibraryChainingStateHasHandler,
	ViewLibraryChainingStateReducer,
} from "reducers/view/view-library-chaining.reducer";
import {
	ViewLibraryClassificationInitialState,
	ViewLibraryClassificationState,
	ViewLibraryClassificationStateHasHandler,
	ViewLibraryClassificationStateReducer,
} from "reducers/view/view-library-classification.reducer";
import {
	ViewLibraryExtractsInitialState,
	ViewLibraryExtractsState,
	ViewLibraryExtractsStateHasHandler,
	ViewLibraryExtractsStateReducer,
} from "reducers/view/view-library-extracts.reducer";
import {
	ViewLibraryPublishingInitialState,
	ViewLibraryPublishingState,
	ViewLibraryPublishingStateHasHandler,
	ViewLibraryPublishingStateReducer,
} from "reducers/view/view-library-publishing.reducer";
import {
	ViewLoginInitialState,
	ViewLoginReducer,
	ViewLoginReducerHasHandler,
	ViewLoginState,
} from "reducers/view/view-login.reducer";
import {
	ViewPageInitialState,
	ViewPageState,
	ViewPageStateHasHandler,
	ViewPageStateReducer,
} from "reducers/view/view-page.reducer";
import {
	ViewRoutesInitialState,
	ViewRoutesReducer,
	ViewRoutesReducerHasHandler,
	ViewRoutesState,
} from "reducers/view/view-routes.reducer";
import {
	ViewWelcomeInitialState,
	ViewWelcomeState,
	ViewWelcomeStateReducer,
	ViewWelcomeStateReducerHasHandler,
} from "reducers/view/view-welcome.reducer";
import {
	ViewWorkGroupsInitialState,
	ViewWorkGroupsState,
	ViewWorkGroupsStateHasHandler,
	ViewWorkGroupsStateReducer,
} from "reducers/view/view-work-groups.reducer";
import { Reducer } from "redux";
import {
	ViewContentViewerState,
	ViewContentViewerInitialState,
	ViewContentViewerStateHasHandler,
	ViewContentViewerStateReducer,
} from "./view-content-viewer";
import {
	AViewState,
	AViewInitialState,
	AViewReducerHasHandler,
	AViewReducer,
} from "../aview.reducer";
import {
	ViewIntegrationInitialState,
	ViewIntegrationState,
	ViewIntegrationStateHasHandler,
	ViewIntegrationStateReducer,
} from "./view-integration.reducer";

/**
 * ----------------------------------
 * App State interface
 * ----------------------------------
 */
export interface ViewState {
	login: ViewLoginState;
	welcome: ViewWelcomeState;
	distributionGroup: ViewDistributionGroupState;
	exports: ViewExportState;
	accountManagement: ViewAccountManagementState;
	clientLibraryVersion: ViewClientLibraryVersionState;
	workGroups: ViewWorkGroupsState;
	routes: ViewRoutesState;
	page: ViewPageState;
	libraryClassification: ViewLibraryClassificationState;
	libraryChaining: ViewLibraryChainingState;
	libraryExtracts: ViewLibraryExtractsState;
	libraryPublishing: ViewLibraryPublishingState;
	drag: ViewDragState;
	contentViewer: ViewContentViewerState;
	integration: ViewIntegrationState;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
const initialState: ViewState = {
	login: ViewLoginInitialState,
	welcome: ViewWelcomeInitialState,
	distributionGroup: ViewDistributionGroupInitialState,
	exports: ViewExportsInitialState,
	accountManagement: ViewAccountManagementInitialState,
	clientLibraryVersion: ViewClientLibraryVersionInitialState,
	workGroups: ViewWorkGroupsInitialState,
	routes: ViewRoutesInitialState,
	page: ViewPageInitialState,
	libraryClassification: ViewLibraryClassificationInitialState,
	libraryChaining: ViewLibraryChainingInitialState,
	libraryExtracts: ViewLibraryExtractsInitialState,
	libraryPublishing: ViewLibraryPublishingInitialState,
	drag: ViewDragInitialState,
	contentViewer: ViewContentViewerInitialState,
	integration: ViewIntegrationInitialState,
};

/**
 * ----------------------------------
 * App State Reducer
 * ----------------------------------
 */
export const ViewReducer: Reducer<ViewState> = (
	state: ViewState = initialState,
	action
): ViewState => {
	//We will test each branch of the tree to see if the reducer down that branch can the supplied action

	//Does the login view reducer of the store have a handler for this action type
	if (ViewLoginReducerHasHandler(action.type)) {
		return tassign(state, { login: ViewLoginReducer(state.login, action) });
	}

	//Does the welcome view reducer of the store have a handler for this action type
	if (ViewWelcomeStateReducerHasHandler(action.type)) {
		return tassign(state, {
			welcome: ViewWelcomeStateReducer(state.welcome, action),
		});
	}

	//Does the distribution group view reducer of the store have a handler for this action type
	if (ViewDistributionGroupReducerHasHandler(action.type)) {
		return tassign(state, {
			distributionGroup: ViewDistributionGroupReducer(
				state.distributionGroup,
				action
			),
		});
	}

	if (ViewExportStateHasHandler(action.type)) {
		return tassign(state, {
			exports: ViewExportStateReducer(state.exports, action),
		});
	}

	if (ViewLibraryPublishingStateHasHandler(action.type)) {
		return tassign(state, {
			libraryPublishing: ViewLibraryPublishingStateReducer(
				state.libraryPublishing,
				action
			),
		});
	}

	if (ViewAccountManagementStateHasHandler(action.type)) {
		return tassign(state, {
			accountManagement: ViewAccountManagementStateReducer(
				state.accountManagement,
				action
			),
		});
	}

	if (ViewClientLibraryVersionStateHasHandler(action.type)) {
		return tassign(state, {
			clientLibraryVersion: ViewClientLibraryVersionStateReducer(
				state.clientLibraryVersion,
				action
			),
		});
	}

	if (ViewWorkGroupsStateHasHandler(action.type)) {
		return tassign(state, {
			workGroups: ViewWorkGroupsStateReducer(state.workGroups, action),
		});
	}

	if (ViewPageStateHasHandler(action.type)) {
		return tassign(state, { page: ViewPageStateReducer(state.page, action) });
	}

	if (ViewRoutesReducerHasHandler(action.type)) {
		return tassign(state, { routes: ViewRoutesReducer(state.routes, action) });
	}

	if (ViewLibraryClassificationStateHasHandler(action.type)) {
		return tassign(state, {
			libraryClassification: ViewLibraryClassificationStateReducer(
				state.libraryClassification,
				action
			),
		});
	}

	if (ViewLibraryChainingStateHasHandler(action.type)) {
		return tassign(state, {
			libraryChaining: ViewLibraryChainingStateReducer(
				state.libraryChaining,
				action
			),
		});
	}

	if (ViewLibraryExtractsStateHasHandler(action.type)) {
		return tassign(state, {
			libraryExtracts: ViewLibraryExtractsStateReducer(
				state.libraryExtracts,
				action
			),
		});
	}

	if (ViewDragStateHasHandler(action.type)) {
		return tassign(state, { drag: ViewDragStateReducer(state.drag, action) });
	}

	if (ViewContentViewerStateHasHandler(action.type)) {
		return tassign(state, {
			contentViewer: ViewContentViewerStateReducer(state.contentViewer, action),
		});
	}

	if (ViewIntegrationStateHasHandler(action.type)) {
		return tassign(state, {
			integration: ViewIntegrationStateReducer(state.integration, action),
		});
	}

	//Nothing can handel this action return the action state unchanged
	return state;
};
