import {
	Directive,
	ElementRef,
	EventEmitter,
	Output,
	Input,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { Utils } from "../../utils";
import { Hark } from "../../hark.decorator";

@Directive({ selector: "[makedroppable]" })
@Hark()
export class MakeDroppableDirective {
	/**
	 * This is the function that will be emitted when the user drops
	 * something onto this element/component.
	 */
	@Output() dropped: EventEmitter<any> = new EventEmitter();

	@Input("allowDropToEmitDataEvent") allowDropToEmitDataEvent: boolean = true;

	//**********
	// Styles
	//***********/

	/**
	 * This is the name of the style all make draggable component start with.
	 */
	private static dragStartStyleName: string = "dropZone";

	/**
	 * This is the name of the drag over style.
	 */
	private static dragOverStyleName: string = "dragOver";

	//**********
	// Event names
	//***********/

	/**
	 * The name of the drag enter event.
	 */
	private static dragEnterEventName: string = "dragenter";

	/**
	 * The name of the drag leave event.
	 */
	private static dragLeaveEventName: string = "dragleave";

	/**
	 * The name of the drag over event.
	 */
	private static dragOverEventName: string = "dragover";

	/**
	 * The name of the drop event.
	 */
	private static dropEventName: string = "drop";

	nativeElement;

	//**********
	// Functions
	//***********/

	constructor(private elementRef: ElementRef) {
		this.nativeElement = this.elementRef.nativeElement;
	}

	ngOnInit() {
		this.setDroppableStyles();

		this.setDropEventListener();
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	setDroppableStyles() {
		// Start by changing the style to the start style.
		this.nativeElement.classList.add(MakeDroppableDirective.dragStartStyleName);

		// Listen for the drag enter events so we know when the user drags something
		// over this element.
		this.nativeElement.addEventListener(
			MakeDroppableDirective.dragEnterEventName,
			(event) => {
				// Add a style to indicate that this element is a drop target.
				this.nativeElement.classList.add(
					MakeDroppableDirective.dragOverStyleName
				);
			}
		);

		// Listen for the drage leave events so we know when the user drags
		// away from this element.
		this.nativeElement.addEventListener(
			MakeDroppableDirective.dragLeaveEventName,
			(event) => {
				// Remove the style
				this.nativeElement.classList.remove(
					MakeDroppableDirective.dragOverStyleName
				);
			}
		);

		// Listen for the drag over event so we know when the user drags
		// something onto this element.
		this.nativeElement.addEventListener(
			MakeDroppableDirective.dragOverEventName,
			(event) => {
				// Stop the default if required.
				if (event.preventDefault) event.preventDefault();

				// Set the drop effect.
				event.dataTransfer.dropEffect = "move";
				return false;
			}
		);
	}

	setDropEventListener() {
		// On drop, get the data and convert it back to a JSON object
		// and fire off an event passing the data.
		this.nativeElement.addEventListener(
			MakeDroppableDirective.dropEventName,
			(event) => {
				// Stops some browsers from redirecting.
				event.stopPropagation();
				event.preventDefault();

				// Remove the over class style.
				this.nativeElement.classList.remove(
					MakeDroppableDirective.dragOverStyleName
				);
				this.nativeElement.classList.remove(
					MakeDroppableDirective.dragStartStyleName
				);

				if (this.allowDropToEmitDataEvent) {
					// Get the data in the transfer.
					let data = event.dataTransfer;

					// Now send the data out through the dropped function so the parent
					// component can view the data.
					this.dropped.emit(data);
				}
				return false;
			}
		);
	}
}
