import { ProductSectionPropertyValueAHubVO } from "app/valueObjects/ahub/library/product-section-property-value.ahub.vo";
import { ProductSectionValueAHubVO } from "app/valueObjects/ahub/library/product-section-value.ahub.vo";
import { ProductAHubVO } from "app/valueObjects/ahub/library/product.ahub.vo";
import { PropertyAllocationObjectVO } from "app/valueObjects/stream/product-allocation-object-stream.vo";
import { ProductPropertyTypeAHubVO } from "app/valueObjects/ahub/library/product-property-type.ahub.vo";

/**
 * This function will return the value of the property of a product by alloc.
 *
 * @param product   Product containing property we wish to find
 * @param alloc     alloc describing section/property we want to get
 */
export const getProductPropertyValueByAlloc = (
	product: ProductAHubVO,
	alloc: PropertyAllocationObjectVO
): string => {
	const propertyToBeReturned = getProductPropertyByAlloc(product, alloc);
	return propertyToBeReturned ? propertyToBeReturned.value : undefined;
};

/**
 * This function will return the 'previewUrl' of the property of a product by alloc.
 *
 * @param product   Product containing property we wish to find
 * @param alloc     alloc describing section/property we want to get
 */
export const getProductPropertyPreviewUrlByAlloc = (
	product: ProductAHubVO,
	alloc: PropertyAllocationObjectVO
): string => {
	const propertyToBeReturned = getProductPropertyByAlloc(product, alloc);
	return propertyToBeReturned ? propertyToBeReturned.previewUrl : undefined;
};

/**
 * This function will return the property of a product by alloc.
 *
 * @param product   Product containing property we wish to find
 * @param alloc     alloc describing section/property we want to get
 */
export const getProductPropertyByAlloc = (
	product: ProductAHubVO,
	alloc: PropertyAllocationObjectVO
): ProductSectionPropertyValueAHubVO => {
	let propertyToBeReturned: ProductSectionPropertyValueAHubVO;
	if (
		product &&
		alloc &&
		product.productSectionValues &&
		product.productSectionValues.length > 0
	) {
		const matchingProductSection: ProductSectionValueAHubVO =
			product.productSectionValues.find(
				(section) => section.sectionId === alloc.section.id
			);
		if (
			matchingProductSection &&
			matchingProductSection.productSectionPropertyValues &&
			matchingProductSection.productSectionPropertyValues.length > 0
		) {
			const matchingProperty =
				matchingProductSection.productSectionPropertyValues.find(
					(property) => property.propertyId === alloc.property.id
				);
			if (matchingProperty) {
				propertyToBeReturned = matchingProperty;
			}
		}
	}
	return propertyToBeReturned;
};

export const updateOrAddProductPropertyValueBySectionAndPropertyId = (
	product: ProductAHubVO,
	newValue: any,
	sectionId: number,
	propertyId: number
) => {
	//Get the sections based on the section id supplied
	let section = product.productSectionValues.find(
		(section) => section.sectionId === sectionId
	);

	//We didn't find an existing section for this id, then we will create on and add it out our product
	if (!section) {
		section = { sectionId, productSectionPropertyValues: [] };
		product.productSectionValues.push(section);
	}

	//If we have no product section values then we will set an empty one
	if (!section.productSectionPropertyValues) {
		section.productSectionPropertyValues = [];
	}

	//Get the property from the list of properties
	let prop = section.productSectionPropertyValues.find(
		(prop) => prop.propertyId === propertyId
	);

	//We didn't find the property then we will create a new one
	if (!prop) {
		prop = { propertyId, value: undefined };
		section.productSectionPropertyValues.push(prop);
	}

	//Set the value and then we are done here
	prop.value = newValue;

	return product;
};

export const buildGenderMap = (
	typeReferenceForThisProperty: ProductPropertyTypeAHubVO
): Map<string, string> => {
	const genderMap = new Map();
	const gendersListed = typeReferenceForThisProperty.description.split(":");

	// I happen to know that the later half of this description is a list of genders
	if (gendersListed && gendersListed.length > 1) {
		const genderList = gendersListed[1].split(",");
		genderList.forEach((gender) => {
			const genderMapElement = gender.trim().split("-");
			genderMap.set(genderMapElement[0].trim(), genderMapElement[1].trim());
		});
	}

	return genderMap;
};
