import { Component, AfterViewInit } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { GridEditorParamsInterface } from "../grid-editor-params-interface";
import { ICellEditorParams } from "ag-grid-community";
import { BehaviorSubject } from "rxjs";

@Component({
	selector: "app-grid-editor-text-list",
	templateUrl: "./grid-editor-text-list.component.html",
	styleUrls: ["./grid-editor-text-list.component.css"],
})
export class GridEditorTextListComponent
	implements ICellEditorAngularComp, AfterViewInit
{
	/**
	 * Parameters for the renderer
	 */
	params: ICellEditorParams = undefined;
	paramsCustom: GridEditorTextListParamsInterface = undefined;

	/**
	 * The original value passed to this function
	 */
	originalValue;

	/**
	 * Value which is part of the input
	 */
	value: string;

	/**
	 * Is the data currently in an errored state
	 */
	isError = false;

	/**
	 * Tooltip for the error message
	 */
	errorTooltip = undefined;

	listValues$: BehaviorSubject<string[]> = new BehaviorSubject([]);

	constructor() {
		// This is intentional
	}

	/**
	 * Init of the renderer
	 */
	agInit(params: any): void {
		//Set the parameters
		this.params = params;
		this.paramsCustom = params as GridEditorTextListParamsInterface;

		if (this.params.value) {
			this.listValues$.next(JSON.parse(this.params.value));
		}
	}

	/**
	 * After the view has been initalised we want to gain focus
	 */
	ngAfterViewInit() {}

	/**
	 * Get the currently selected value
	 */
	getValue(): any {
		//OK work through the list values then bail out
		const trimmedListValues = this.listValues$.getValue().filter((value) => {
			//If this value isn't a string or it's undefined then bail out
			if (typeof value !== "string" || !value) {
				return false;
			}

			//If the value is empty then bail out
			return value.trim().length > 0;
		});

		//Check we have values, if not then return undefined
		return trimmedListValues && trimmedListValues.length > 0
			? JSON.stringify(trimmedListValues)
			: undefined;
	}

	/**
	 * This is a popup so thats that
	 */
	isPopup(): boolean {
		return true;
	}

	doneEditingHandler() {
		if (this.params.api) {
			this.params.api.stopEditing();
		}
	}
}

/**
 * Parameters class which shows what we can pass into the editor
 */
export interface GridEditorTextListParamsInterface
	extends GridEditorParamsInterface {
	addItemRegex: string;
	addItemRegexError: string;
}
