/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import { ViewState } from "reducers/view/view.reducer";

/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Root selector to get the View state from the store state
 */
export const viewState = (state): ViewState => state.view;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */
