import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { DistributionUtils } from "app/modules/common/distribution-utils";
import {
	componentDestroyStream,
	componentTrack,
	Hark,
} from "app/modules/common/hark.decorator";
import { RequestActionMonitorService } from "app/services/request-action-monitor/request-action-monitor.service";
import { AHubActions } from "app/store/actions/ahub.actions";
import { MapStorageUtil } from "app/store/map-storage.util";
import { aHubPublicationDistributionsMap } from "app/store/selector/ahub/ahub-temporary.selector";
import { sessionUserId } from "app/store/selector/app.selector";
import { StoreAccess } from "app/store/store-access";
import { DistributionAViewProductFilterAHubVO } from "app/valueObjects/ahub/accounts/distribution-aview-product-filter.ahub.vo";
import { DistributionGroupAHubVO } from "app/valueObjects/ahub/accounts/distribution-group.ahub.vo";
/**
 * Value objects.
 */
import { DistributionAHubVO } from "app/valueObjects/ahub/accounts/distribution.ahub.vo";
import { EntityPermissions } from "app/valueObjects/ahub/accounts/entity-permissions.ahub";
import { ParameterAHubVO } from "app/valueObjects/ahub/accounts/parameter.vo";
/**
 * Utilities.
 */
import { Utils } from "modules/common/utils";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import {
	map,
	takeUntil,
	distinctUntilChanged,
	debounceTime,
} from "rxjs/operators";

@Component({
	selector: "app-distribution-aview",
	templateUrl: "./distribution-aview.component.html",
	styleUrls: ["./distribution-aview.component.css"],
})
@Hark()
export class DistributionAviewComponent implements OnInit {
	/**
	 * This is the distribution we are displaying.
	 */
	@Input() distribution$: Observable<DistributionAHubVO>;

	/**
	 * This is the distribution group we are displaying.
	 */
	@Input() distributionGroup$: Observable<DistributionGroupAHubVO>;

	/**
	 * Is this component being used for (distribution) 'group settings'
	 */
	@Input() isGroupSettings = false;

	/**
	 * Should this compnent allow editing
	 */
	@Input() readOnly = false;

	@Input() userExportReadOnly = false;

	/**
	 * These are the distribution editor permissions required to
	 * add/remove restrictions.
	 */
	distributionEditor = EntityPermissions.DISTRIBUTION_EDITOR;

	/**
	 * Distribution form
	 */
	distributionForm: UntypedFormGroup = this.formBuilder.group({
		distributionNotes: [],
	});

	distributionGroupParamsForm: UntypedFormGroup = this.formBuilder.group({});

	/**
	 * Action request.
	 */
	actionRequestActionStatus$ = undefined;

	productFilter$: BehaviorSubject<DistributionAViewProductFilterAHubVO> =
		new BehaviorSubject(undefined);

	propertyExclusionIds$: BehaviorSubject<number[]> = new BehaviorSubject(
		undefined
	);

	/**
	 * The sku filters in use.
	 */
	skuFilters$: BehaviorSubject<string[]> = new BehaviorSubject(undefined);

	distributionNotes$: BehaviorSubject<string> = new BehaviorSubject("");

	// Used for checking if something has changed for the aRanger params
	aRangerParams$: BehaviorSubject<ParameterAHubVO[]> = new BehaviorSubject([]);

	sourceProductFilter: DistributionAViewProductFilterAHubVO;
	sourcePropertyExclusions: number[];
	sourceProductFiltersInherit: boolean = false;
	sourcePropertyExclusionsInherit: boolean = false;
	sourceSkuFiltersInherit: boolean = false;
	sourceSkuFilters: string[];
	sourceDistributionNotes: string = "";
	sourceARangerParams: ParameterAHubVO[] = [];

	productFiltersInherited$: BehaviorSubject<boolean> = new BehaviorSubject(
		false
	);
	propertyExclusionsInherited$: BehaviorSubject<boolean> = new BehaviorSubject(
		false
	);

	skuFiltersInherited$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	/**
	 * This is the current distribution source.
	 */
	distributionSource: DistributionAHubVO;
	distributionGroupSource: DistributionGroupAHubVO;

	somethingWorthSaving$ = combineLatest([
		this.productFilter$.pipe(),
		this.propertyExclusionIds$.pipe(),
		this.productFiltersInherited$.pipe(),
		this.propertyExclusionsInherited$.pipe(),
		this.skuFiltersInherited$.pipe(),
		this.skuFilters$.pipe(),
		this.distributionNotes$.pipe(),
		this.aRangerParams$.pipe(),
	]).pipe(
		map(
			([
				productFilter,
				propertyExclusionIds,
				productFiltersInherited,
				propertyExclusionsInherited,
				skuFiltersInherited,
				skuFilters,
				distributionNotes,
				aRangerParmas,
			]) => {
				return (
					JSON.stringify(productFilter) !==
						JSON.stringify(this.sourceProductFilter) ||
					JSON.stringify(propertyExclusionIds) !==
						JSON.stringify(this.sourcePropertyExclusions) ||
					JSON.stringify(productFiltersInherited) !==
						JSON.stringify(this.sourceProductFiltersInherit) ||
					JSON.stringify(propertyExclusionsInherited) !==
						JSON.stringify(this.sourcePropertyExclusionsInherit) ||
					JSON.stringify(distributionNotes) !==
						JSON.stringify(this.sourceDistributionNotes) ||
					JSON.stringify(skuFilters) !==
						JSON.stringify(this.sourceSkuFilters) ||
					JSON.stringify(skuFiltersInherited) !==
						JSON.stringify(this.sourceSkuFiltersInherit) ||
					JSON.stringify(aRangerParmas) !==
						JSON.stringify(this.sourceARangerParams)
				);
			}
		),
		takeUntil(componentDestroyStream(this))
	);

	showAViewPreviewButton$: Observable<boolean>;

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly requestActionMonitorService: RequestActionMonitorService,
		private readonly router: Router,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {
		// This is intentional
	}

	private paramValueGet(params, paramName): boolean {
		let paramValue: boolean;

		const paramObject: ParameterAHubVO = params?.find(
			(param) => param.id == paramName
		);
		if (paramObject && paramObject.values) {
			paramValue = JSON.parse(paramObject.values[0]);
		}
		return Utils.clone(paramValue);
	}

	// filter out the aRanger params from the form
	private mapFormValuesToARangerParams(formValue: any): ParameterAHubVO[] {
		const obj: ParameterAHubVO[] = [];
		const aRangerKeys = ["arangerDisabled", "arangerDisabledInherit"];
		for (const key of aRangerKeys) {
			if (formValue[key] !== undefined && formValue[key] !== null) {
				obj.push({ id: key, values: [formValue[key].toString()] });
			}
		}
		return obj;
	}

	ngOnInit() {
		// Invert the "Is group" value to set the inital source inherit values.
		this.sourceProductFiltersInherit = !this.isGroupSettings;
		this.sourcePropertyExclusionsInherit = !this.isGroupSettings;
		this.sourceSkuFiltersInherit = !this.isGroupSettings;

		// Lets go get the properties for this client
		StoreAccess.dispatch(AHubActions.productPropertiesFetch());

		DistributionUtils.aViewExportTypeParametersAddToForm(
			this.distributionGroupParamsForm
		);
		DistributionUtils.aViewExportTypeInheritParametersAddToForm(
			this.distributionGroupParamsForm
		);
		DistributionUtils.aViewExportTypeGroupParametersAddToForm(
			this.distributionGroupParamsForm
		);

		// Simple distributionForm valueChanges pipe in somethingWorthSaving combineLatest
		// didn't capture the initial change of the form and thus didn't allow to save anything
		// unless you change dg notes (this solutions with subscribe and behaviour subject is workaround)
		this.distributionForm.controls.distributionNotes.valueChanges
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((notes) => {
				this.distributionNotes$.next(notes);
			});

		this.distributionGroupParamsForm.valueChanges
			.pipe(
				takeUntil(componentDestroyStream(this)),
				// create an object with just the aRanger params from the form
				map((formValue) => this.mapFormValuesToARangerParams(formValue)),
				distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
			)
			.subscribe((aRangerParams) => {
				this.aRangerParams$.next(aRangerParams);
			});

		this.distributionGroupParamsForm.controls.skuFilters.valueChanges
			.pipe(takeUntil(componentDestroyStream(this)), debounceTime(100))
			.subscribe((skuFilters) => {
				this.skuFilters$.next(skuFilters);
			});

		this.distribution$
			.pipe(Utils.isNotNullOrUndefined())
			.subscribe((distribution) => {
				// Lets keep a 'clean' copy of the distribution coming in
				this.distributionSource = distribution;

				// Get the inherited distribution parameters and pass to the form.
				DistributionUtils.setAViewDistributionParamsInheritToForm(
					distribution.params,
					this.distributionGroupParamsForm
				);

				// assuming that all the params used in the aRanger tab include "aranger" in the id
				this.sourceARangerParams = distribution.params.filter((param) =>
					param.id.includes("aranger")
				);

				this.sourceDistributionNotes = distribution.distributionNotes;
				this.distributionForm.controls.distributionNotes.setValue(
					distribution.distributionNotes
				);

				this.sourceProductFiltersInherit = this.paramValueGet(
					distribution.params,
					"productFiltersInherit"
				);
				this.productFiltersInherited$.next(this.sourceProductFiltersInherit);

				this.sourceProductFiltersInherit = this.paramValueGet(
					distribution.params,
					"propertyExclusionsInherit"
				);
				this.propertyExclusionsInherited$.next(
					this.sourceProductFiltersInherit
				);

				this.sourceSkuFiltersInherit = this.paramValueGet(
					distribution.params,
					"skuFiltersInherit"
				);
				this.skuFiltersInherited$.next(this.sourceSkuFiltersInherit);
			});

		// Listen for changes to the distribution and distribution group we are displaying.
		combineLatest([
			this.distribution$,
			this.distributionGroup$.pipe(Utils.isNotNullOrUndefined()),
			this.productFiltersInherited$
				.pipe
				// startWith(true),
				// distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
				(),
			this.propertyExclusionsInherited$
				.pipe
				// startWith(true),
				// distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
				(),
			this.skuFiltersInherited$,
		])
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe(
				([
					distribution,
					distributionGroup,
					productFiltersInherited,
					propertyExclusionsInherited,
					skuFiltersInherited,
				]) => {
          
					const aViewExportTypeParameters =
						distributionGroup.exportTypeParameters?.find(
							(etp) => etp.exportTypeCode === "AVIEW"
						);

					const params: ParameterAHubVO[] =
						aViewExportTypeParameters && aViewExportTypeParameters.parameters
							? aViewExportTypeParameters.parameters
							: [];

					// Set the group params to the form (these are inherited in the distribution page)
					DistributionUtils.setAViewDistributionParamsGroupToForm(
						aViewExportTypeParameters.parameters,
						this.distributionGroupParamsForm
					);

					// Are we in the (Distribution) Group Setting page?
					if (this.isGroupSettings) {
						this.distributionGroupSource = Utils.clone(distributionGroup);
						this.setProductFilter(params);
						this.setPropertyExclusionIds(params);
						this.setSkuFilter(params);
						this.setArangerParams(params);
						// set all the standard params to the form using the group data
						DistributionUtils.setAViewDistributionParamsToForm(
							params,
							this.distributionGroupParamsForm
						);
					} else if (!this.isGroupSettings && distribution) {
						// If we're inheriting the settings from group setting, lets use distributionGroup params
						if (propertyExclusionsInherited) {
							this.setPropertyExclusionIds(params);
						} else {
							this.setPropertyExclusionIds(distribution.params);
						}

						if (productFiltersInherited) {
							this.setProductFilter(params);
						} else {
							this.setProductFilter(distribution.params);
						}

						if (skuFiltersInherited) {
							this.setSkuFilter(params);
						} else {
							this.setSkuFilter(distribution.params);
						}

						this.setArangerParams(distribution.params, params);
						// set all the standard params to the form using the individual distribution data
						DistributionUtils.setAViewDistributionParamsToForm(
							distribution.params,
							this.distributionGroupParamsForm
						);
					}

					// We are resetting the form data so we will mark the data as prestine.
					this.distributionGroupParamsForm.markAsPristine();
				}
			);

		// We need to manually trigger change detector to redraw because child components are changing this parent's view
		this.somethingWorthSaving$
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe(() => {
				this.changeDetectorRef.detectChanges();
			});

		// Watch for changes of distribution and with user id get all distribution groups with
		// additional information about membership of this distribution group
		combineLatest([
			this.distribution$.pipe(Utils.isNotNullOrUndefined()),
			StoreAccess.dataGetObvs(sessionUserId),
		])
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe(([distribution, user]) => {
				StoreAccess.dispatch(
					AHubActions.publicationDistributionsMapFetch(
						user,
						distribution.exportId
					)
				);
			});

		// We need to find if user is member of this group to show/hide the preview button
		this.showAViewPreviewButton$ = combineLatest([
			StoreAccess.dataGetObvs(aHubPublicationDistributionsMap).pipe(
				Utils.isNotNullOrUndefined()
			),
			this.distribution$,
		]).pipe(
			takeUntil(componentDestroyStream(this)),
			map(([distributionsMap, distributionSource]) => {
				// Check if we have any dgs for this publication (by exportId)
				if (
					distributionSource &&
					MapStorageUtil.mapStorageHasKey(
						distributionsMap,
						distributionSource?.exportId?.toString()
					)
				) {
					const distributions = MapStorageUtil.mapStorageGet(
						distributionsMap,
						distributionSource.exportId.toString()
					);

					const isMemberOfAtLeastOneDistribution = distributions.some(
						(distribution) => distribution.memberOf
					);

					// Find this distribution within all dgs available for this user
					const distributionGroupIndex: number = distributions?.findIndex(
						(publicationDistribution) => {
							return (
								publicationDistribution?.distributionGroupIds[0] ===
								distributionSource?.distributionGroupId
							);
						}
					);

					// If the index is > -1 we've found the dg and button should be shown
					return (
						distributionGroupIndex > -1 && isMemberOfAtLeastOneDistribution
					);
				} else {
					return false;
				}
			})
		);

		//NOTE: We want to track this component as there will be changes which we want users to confirm before they move away from
		componentTrack(
			this,
			this.somethingWorthSaving$.pipe(map((edited) => !edited)),
			true
		);
	}

	setPropertyExclusionIds(params) {
		// Lets grab the property exclusions for this distribution(group)
		const paramsPropertyExclusions: ParameterAHubVO = params
			? params.find((param) => param.id === "propertyExclusions")
			: undefined;

		const propertyExclusions =
			paramsPropertyExclusions && paramsPropertyExclusions.values
				? paramsPropertyExclusions.values.map((i) => Number(i))
				: [];

		this.sourcePropertyExclusions = Utils.clone(propertyExclusions);

		this.propertyExclusionIds$.next(propertyExclusions);
	}

	setProductFilter(params) {
		// Lets grab the property exclusions for this distribution(group)
		const paramsProductFilters: ParameterAHubVO = params
			? params.find((param) => param.id === "productFilters")
			: undefined;

		const productFilter =
			DistributionUtils.buildProductFilterFromParams(paramsProductFilters);

		this.sourceProductFilter = Utils.clone(productFilter);

		this.productFilter$.next(productFilter);
	}

	setSkuFilter(params) {
		// Lets grab the property exclusions for this distribution(group)
		const paramsSkuFilters: ParameterAHubVO = params
			? params.find((param) => param.id === "skuFilters")
			: undefined;

		const skuFilters: string[] =
			paramsSkuFilters && paramsSkuFilters.values
				? paramsSkuFilters.values
				: undefined;

		this.sourceSkuFilters = Utils.clone(skuFilters);
		this.distributionGroupParamsForm.patchValue({ skuFilters });
	}

	/**
	 * Set the aranger params to the form
	 * @param distributionParams
	 * @param groupParams
	 * This method will set the aranger params to the aranger params source and observable.
	 */
	setArangerParams(
		distributionParams: ParameterAHubVO[],
		groupParams?: ParameterAHubVO[]
	) {
		const distributionParamsAranger = distributionParams.filter((p) =>
			p.id.includes("aranger")
		);
		// use distribution params as default if group params are not passed
		let paramsToBeApplied = distributionParamsAranger;
		// If the group params are passed, check if the group params are to be inherited or not.
		if (groupParams) {
			const groupParamsAranger = groupParams.filter((p) =>
				p.id.includes("aranger")
			);
			const shouldInherit = (paramName: string) =>
				distributionParamsAranger.find((p) => p.id === paramName + "Inherit")
					?.values?.[0] === "true" ?? false;

			// Final list of params to be applied
			paramsToBeApplied = groupParamsAranger.map((p) =>
				shouldInherit(p.id)
					? p
					: distributionParamsAranger.find((p2) => p2.id === p.id) ?? p
			);
		}
		this.sourceARangerParams = Utils.clone(paramsToBeApplied);
		this.aRangerParams$.next(paramsToBeApplied);
	}

	redirectToAView() {
		this.router
			.navigate([])
			.then(() =>
				window.open(
					`/aview/publication/${this.distributionSource.exportId}/edition/latest/distributionGroup/${this.distributionSource.distributionGroupId}`,
					"_blank"
				)
			);
	}

	/**
	 * This function will append a parameter value to the list. If it's already there it will change it, otherwise add it.
	 *
	 * @param params 				The parameters to change.
	 * @param paramName 			The name of the parameter to change.
	 * @param paramValue 			The value to set it too.
	 */
	private parameterAppend(
		params: ParameterAHubVO[],
		paramName: string,
		paramValue: boolean
	) {
		// Get all of the matching parameters.
		const parameters: ParameterAHubVO[] = params.filter(
			(param) => param.id === paramName
		);

		// Do we have any?
		if (parameters && parameters.length > 0) {
			// If so we want to change each of the values.
			parameters.forEach((parameter) => {
				// Set the values.
				parameter.values = [paramValue.toString()];
			});
		} else {
			// Other wise we want to add the new parameter and value.
			params.push({
				id: paramName,
				values: [paramValue.toString()],
			});
		}
	}

	/**
	 * Handle the save action on a distribution.
	 */
	saveHandler() {
		let saveActionId: number;

		const productFilterToBeSaved = this.productFilter$.getValue();
		const propertyExclusionsToBeSaved = this.propertyExclusionIds$.getValue();
		const skuFiltersTobeSaved = this.skuFilters$.getValue();

		if (this.isGroupSettings) {
			saveActionId = this.saveDistributionGroup(
				productFilterToBeSaved,
				propertyExclusionsToBeSaved,
				skuFiltersTobeSaved
			);
		} else {
			saveActionId = this.saveDistribution(
				productFilterToBeSaved,
				propertyExclusionsToBeSaved,
				skuFiltersTobeSaved,
				this.productFiltersInherited$.getValue(),
				this.propertyExclusionsInherited$.getValue(),
				this.skuFiltersInherited$.getValue()
			);
		}

		// Create an observable which will tell us when the action is complete.
		this.actionRequestActionStatus$ =
			this.requestActionMonitorService.requestActionStatusObservableByActionIdBusy(
				saveActionId
			);
	}

	saveDistribution(
		productFilterToBeSaved: DistributionAViewProductFilterAHubVO,
		propertyExclusionsToBeSaved: number[],
		skuFiltersToBeSaved: string[],
		productFiltersInheritToBeSaved: boolean,
		propertyExclusionsInheritToBeSaved: boolean,
		skuFiltersInheritToBeSaved: boolean
	): number {
		// Lets update the distribution with any changes to productFilter
		if (productFilterToBeSaved && !productFiltersInheritToBeSaved) {
			const productFiltersParam: ParameterAHubVO =
				this.distributionSource.params?.find(
					(param) => param.id === "productFilters"
				);

			// Lets build a values array of product filter rules containing filter tests
			const productFilterParamValues: string[] = [];

			productFilterToBeSaved.propertyRules.forEach((rule) => {
				productFilterParamValues.push(JSON.stringify(rule.propertyTests));
			});

			if (productFiltersParam) {
				productFiltersParam.values = productFilterParamValues;
			} else {
				this.distributionSource.params.push({
					id: "productFilters",
					values: productFilterParamValues,
				});
			}
		}

		// Lets update the distribution with any changes to productFilter
		if (propertyExclusionsToBeSaved && !propertyExclusionsInheritToBeSaved) {
			const propertyExclusionsParam: ParameterAHubVO =
				this.distributionSource.params?.find(
					(param) => param.id === "propertyExclusions"
				);

			if (propertyExclusionsParam) {
				propertyExclusionsParam.values = propertyExclusionsToBeSaved.map((p) =>
					p.toString()
				);
			} else {
				this.distributionSource.params.push({
					id: "propertyExclusions",
					values: propertyExclusionsToBeSaved.map((p) => p.toString()),
				});
			}
		}

		// Lets update the distribution with any changes to skuFilter
		if (skuFiltersToBeSaved && !skuFiltersInheritToBeSaved) {
			const skuFiltersParam: ParameterAHubVO =
				this.distributionSource.params?.find(
					(param) => param.id === "skuFilters"
				);

			// Lets build a values array of product filter rules containing filter tests
			const skuFilterParamValues: string[] = [];

			skuFiltersToBeSaved.forEach((rule) => {
				skuFilterParamValues.push(rule);
			});

			if (skuFiltersParam) {
				skuFiltersParam.values = skuFilterParamValues;
			} else {
				this.distributionSource.params.push({
					id: "skuFilters",
					values: skuFilterParamValues,
				});
			}
		}

		// Lets update the distribution with any changes to aRanger params
		this.aRangerParams$.getValue().forEach((param) => {
			const existingParam = this.distributionSource.params?.find(
				(p) => p.id === param.id
			);
			if (existingParam) {
				existingParam.values = param.values;
			} else {
				this.distributionSource.params.push(param);
			}
		});

		// Now set the parameter values to the ones we have set elsewhere.
		this.parameterAppend(
			this.distributionSource.params,
			"propertyExclusionsInherit",
			propertyExclusionsInheritToBeSaved
		);
		this.parameterAppend(
			this.distributionSource.params,
			"productFiltersInherit",
			productFiltersInheritToBeSaved
		);
		this.parameterAppend(
			this.distributionSource.params,
			"skuFiltersInherit",
			skuFiltersInheritToBeSaved
		);

		// Lets update notes
		this.distributionSource.distributionNotes =
			this.distributionForm.controls.distributionNotes.value;

		// Call the save distribution action.
		return StoreAccess.dispatch(
			AHubActions.distributionSave(this.distributionSource)
		);
	}

	saveDistributionGroup(
		productFilterToBeSaved: DistributionAViewProductFilterAHubVO,
		propertyExclusionsToBeSaved: number[],
		skuFiltersToBeSaved: string[]
	): number {
		let aViewExportTypeParameters =
			this.distributionGroupSource.exportTypeParameters.find(
				(etp) => etp.exportTypeCode === "AVIEW"
			);

		// If we dont have aViewExportTypeParameters, we can invent them here
		if (!aViewExportTypeParameters) {
			this.distributionGroupSource.exportTypeParameters.push({
				exportTypeCode: "AVIEW",
				parameters: [],
			});

			aViewExportTypeParameters =
				this.distributionGroupSource.exportTypeParameters.find(
					(etp) => etp.exportTypeCode === "AVIEW"
				);
		}

		let distributionGroupParams = aViewExportTypeParameters.parameters
			? aViewExportTypeParameters.parameters
			: [];

		// Lets update the distribution with any changes to productFilter
		if (productFilterToBeSaved) {
			const productFiltersParam: ParameterAHubVO = distributionGroupParams.find(
				(param) => param.id === "productFilters"
			);

			// Lets build a values array of product filter rules containing filter tests
			const productFilterParamValues: string[] = [];

			productFilterToBeSaved.propertyRules.forEach((rule) => {
				productFilterParamValues.push(JSON.stringify(rule.propertyTests));
			});

			if (productFiltersParam) {
				productFiltersParam.values = productFilterParamValues;
			} else {
				distributionGroupParams.push({
					id: "productFilters",
					values: productFilterParamValues,
				});
			}
		}

		// Lets update the distribution with any changes to skuFilters
		if (skuFiltersToBeSaved) {
			const skuFiltersParam: ParameterAHubVO = distributionGroupParams.find(
				(param) => param.id === "skuFilters"
			);

			// Lets build a values array of product filter rules containing filter tests
			const skuFilterParamValues: string[] = [];

			skuFiltersToBeSaved.forEach((rule) => {
				skuFilterParamValues.push(rule);
			});

			if (skuFiltersParam) {
				skuFiltersParam.values = skuFilterParamValues;
			} else {
				distributionGroupParams.push({
					id: "skuFilters",
					values: skuFilterParamValues,
				});
			}
		}

		// Lets update the distribution with any changes to productFilter
		if (propertyExclusionsToBeSaved) {
			const propertyExclusionsParam: ParameterAHubVO =
				distributionGroupParams.find(
					(param) => param.id === "propertyExclusions"
				);

			if (propertyExclusionsParam) {
				propertyExclusionsParam.values = propertyExclusionsToBeSaved.map((p) =>
					p.toString()
				);
			} else {
				distributionGroupParams.push({
					id: "propertyExclusions",
					values: propertyExclusionsToBeSaved.map((p) => p.toString()),
				});
			}
		}

		// Lets update the distribution with any changes to aRanger params
		this.aRangerParams$.getValue().forEach((param) => {
			const existingParam = distributionGroupParams.find(
				(p) => p.id === param.id
			);
			if (existingParam) {
				existingParam.values = param.values;
			} else {
				distributionGroupParams.push(param);
			}
		});

		// Call the save distribution action.
		return StoreAccess.dispatch(
			AHubActions.distributionGroupSave(this.distributionGroupSource)
		);
	}

	/**
	 * Empty On destroy to ensure @Hark decorator works for an AOT build.
	 */
	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}
}
