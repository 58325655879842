import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "numberLessThanZeroToNew",
})
export class NumberLessThanZeroToTextNewPipe implements PipeTransform {
	transform(value: number, args?: any): any {
		if (value && value < 1) {
			return "New";
		}
		return value;
	}
}
