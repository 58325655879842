import { Reducer } from "redux";
import { createReducer } from "reducers/reducer-util";

/**
 * Action Types
 */

/**
 * Actions
 */

/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ViewWelcomeState {}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewWelcomeInitialState: ViewWelcomeState = {};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions.
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer.
 */

/**
 * Create a reducers based on the reducers handlers.
 */
export const ViewWelcomeStateReducer: Reducer<ViewWelcomeState> = createReducer(
	ViewWelcomeInitialState,
	reducerHandlers
);

/**
 * Check if this reducers can handel the function specified.
 */
export const ViewWelcomeStateReducerHasHandler = (
	actionType: string
): boolean => reducerHandlers.hasOwnProperty(actionType);
