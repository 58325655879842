import { takeUntil, debounceTime, map, startWith } from "rxjs/operators";
import { Component, OnInit, OnDestroy } from "@angular/core";

import { MatDialogRef } from "@angular/material/dialog";

import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";

import { combineLatest, Observable } from "rxjs";

import { Hark, componentDestroyStream } from "modules/common/hark.decorator";

/**
 * Utils
 */
import { SearchUtils } from "modules/common/search-utils";
import { sortAlphanumericAtoZ } from "modules/common/sort.util";
import { Utils } from "../../utils";

@Component({
	selector: "app-list-dialog",
	templateUrl: "./select-single-list-dialog.component.html",
	styleUrls: ["./select-single-list-dialog.component.css"],
})
@Hark()
export class SelectSingleListDialogComponent implements OnInit, OnDestroy {
	/**
	 * Form to control how the list is dispayed on screen.
	 */
	listControlForm: UntypedFormGroup = this.formBuilder.group({
		showSearchFormControl: [true],
		searchFormControl: [""],
	});

	/**
	 * This is the title and content to display in this confirmation dialog.
	 */
	public title: string;

	/**
	 * This is the title of the list selection.
	 */
	public listTitle: string;

	/**
	 * This is the list data to display.
	 */
	public listData$: Observable<any[]>;

	/**
	 * This is the count of the list data before filter.
	 */
	public listDataCount$: Observable<number>;

	/**
	 * The list data to display thats been filtered.
	 */
	public listDataFiltered$: Observable<any[]>;

	/**
	 * This is the count of the list data after filters.
	 */
	public listDataFilteredCount$: Observable<number>;

	/**
	 * Create a stream which represents the stream of form value changes
	 */
	listControlFormValues$: Observable<any> =
		this.listControlForm.valueChanges.pipe(debounceTime(200));

	/**
	 * This is the currently selected value.
	 */
	public selectedValue: number;

	/**
	 * These are the labels to be used on the confirm and cancel buttons.
	 */
	public confirmButtonLabel: string;
	public cancelButtonLabel: string;

	/**
	 * This is the property to record in the selected value. Defaults to id.
	 */
	public itemSelectorProperty: string = "id";

	/**
	 * Create an instance of the list dialog.
	 */
	constructor(
		private formBuilder: UntypedFormBuilder,
		public dialogRef: MatDialogRef<SelectSingleListDialogComponent>
	) {}

	/**
	 * Empty On init to ensure @Hark decorator works for an AOT build
	 */
	ngOnInit() {
		// This is intentional
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * This function will set the list data in the dialog.
	 */
	listDataSet(listData$: Observable<any[]>) {
		// Record the list data.
		this.listData$ = listData$;

		// Set up the non filtered count.
		this.listDataCount$ = this.listData$.pipe(
			takeUntil(componentDestroyStream(this)),
			map((items) => (items ? items.length : 0))
		);

		// Set up the list data with a combine latest so the user can search the list.
		this.listDataFiltered$ = combineLatest([
			this.listData$.pipe(Utils.isNotNullOrUndefined()),
			this.listControlFormValues$.pipe(startWith({})),
		]).pipe(
			map(([listData, listControlForm]) => {
				// Do we have any data? If not, stop here.
				if (listData == undefined) return listData;

				//Filter the list based on the search input
				listData = listData
					.filter((item) => {
						// Are we showing the search bar? If not, return true as we want to show all.
						if (!listControlForm.showSearchFormControl) return true;

						// If we get here then call the search utilies to do the work.
						return SearchUtils.stringSearch(listControlForm.searchFormControl, [
							item.name,
						]);
					})
					.sort((object1, object2) =>
						sortAlphanumericAtoZ(object1.name, object2.name)
					);

				//Return the item from the list
				return listData;
			})
		);

		// Set up the filtered count.
		this.listDataFilteredCount$ = this.listDataFiltered$.pipe(
			takeUntil(componentDestroyStream(this)),
			map((items) => (items ? items.length : 0))
		);
	}
}
