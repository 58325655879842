import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
	RequestOptions,
	ServiceACatalog,
	ServiceOptions,
} from "@harksolutions/ahub-web-services";
import {
	componentDestroyStream,
	Hark,
} from "app/modules/common/hark.decorator";
import { Utils } from "app/modules/common/utils";
import { AHubServiceCredentialsVO } from "app/services/ahub/ahub-service-credentials.vo";
import { sessionUserSessionCredentials } from "app/store/selector/app.selector";
import { StoreAccess } from "app/store/store-access";
import { environment } from "environments/environment";
import jwtDecode from "jwt-decode";
import { BehaviorSubject, combineLatest, from, Observable, of } from "rxjs";
import { catchError, filter, map, switchMap, takeUntil } from "rxjs/operators";

@Component({
	selector: "app-process-token",
	templateUrl: "./process-token.component.html",
	styleUrls: ["./process-token.component.css"],
})
@Hark()
export class ProcessTokenComponent implements OnInit {
	serviceACatalog: ServiceACatalog = undefined;

	constructor(
		public readonly activatedRoute: ActivatedRoute,
		private readonly router: Router
	) {
		const serviceOptions = new ServiceOptions(
			environment.aHubApi.domain,
			environment.aHubApi.basePath
		);

		serviceOptions.logRequest = true;

		//Create our service library with the base parameters for our requests
		this.serviceACatalog = new ServiceACatalog(serviceOptions);
	}

	queryParamToken$: Observable<string>;
	aHubResponse$: Observable<any>;
	decodedToken$: Observable<any>;
	statusMessage$: BehaviorSubject<string> = new BehaviorSubject(
		"Waiting for token.."
	);
	isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

	ngOnInit(): void {
		this.queryParamToken$ = this.activatedRoute.queryParams.pipe(
			Utils.isNotNullOrUndefined(),
			filter((params) => Object.keys(params).includes("token")),
			map(({ token }) => token as string)
		);

		this.decodedToken$ = this.queryParamToken$.pipe(
			map((token) => jwtDecode(token)),
			this.catchError(),
			takeUntil(componentDestroyStream(this))
		);

		this.aHubResponse$ = this.queryParamToken$.pipe(
			switchMap((token) => {
				return from(
					this.serviceACatalog.processToken(this.reqOptSigned(), token)
				);
			}),
			map(() => ({ statusCode: 200, message: "Success!" })),
			this.catchError(),
			takeUntil(componentDestroyStream(this))
		);

		combineLatest([
			this.decodedToken$.pipe(Utils.isNotNullOrUndefined()),
			this.aHubResponse$.pipe(),
		])
			.pipe(
				map(([decodedToken, response]) => {
					if (response.statusCode === 200) {
						return decodedToken?.redirectUrl;
					}

					return undefined;
				}),
				Utils.isNotNullOrUndefined(),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((redirectUrl) => this.router.navigate([redirectUrl]));
	}

	/**
	 * Get the default request parameters for signed requests
	 *
	 * @returns
	 */
	public reqOptSigned(): RequestOptions {
		//Start off with the unsigned ones
		const requestOptions = this.reqOptUnsigned();

		//Get the session credentials for the currently signed in user
		const sessionCredentials: AHubServiceCredentialsVO = StoreAccess.dataGet(
			sessionUserSessionCredentials
		);

		if (!sessionCredentials) {
			return requestOptions;
		}

		//Set the session credentials into the request options
		requestOptions.sessionId = sessionCredentials.sessionId;
		requestOptions.sessionKey = sessionCredentials.sessionKey;

		//Return the options with the session data attached
		return requestOptions;
	}

	/**
	 * Get the default request options for unsigned requests
	 *
	 * @returns
	 */
	public reqOptUnsigned(): RequestOptions {
		//Pssssst no special options
		return new RequestOptions();
	}

	private catchError<T>() {
		return (source$: Observable<T>) =>
			source$.pipe(
				catchError((err) => {
					// Sometimes there is an object inside error message in ahub response
					const message =
						typeof err?.message === "object"
							? err.message.message
							: err.message;

					this.statusMessage$.next(message);
					this.isLoading$.next(false);
					return of(err);
				})
			);
	}

	ngOnDestroy() {}
}
