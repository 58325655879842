import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AHubServiceCredentialsVO } from "services/ahub/ahub-service-credentials.vo";
import { AhubAuthInterceptorUtilsService } from "services/http/interceptors/ahub-auth-interceptor-utils.service";
let API_PATH_REGEX = /.+?\:\/\/.+?(\/.+?)(?:#|\?|$)/;

@Injectable()
export class AhubAuthInterceptorService implements HttpInterceptor {
	constructor(private interceptorUtils: AhubAuthInterceptorUtilsService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let queryString = "";

		//Get the session credentials for the currently signed in user
		let sessionCredentials: AHubServiceCredentialsVO =
			this.interceptorUtils.getSignedCredentials();

		if (req.urlWithParams.indexOf("?") > -1) {
			queryString = req.urlWithParams.substring(
				req.urlWithParams.indexOf("?") + 1
			);
			queryString = this.parseParameterString(queryString);
		}

		//Execute the regular expression for the path
		let apiPathRegex = API_PATH_REGEX.exec(req.url);

		let authReq = req;

		// Lets add some hark auth headers if we have credentials and this isnt an upload
		if (
			sessionCredentials &&
			queryString.indexOf("X-Amz-Algorithm") == -1 &&
			apiPathRegex &&
			apiPathRegex.length > 1
		) {
			authReq = this.interceptorUtils.addAuthHeaders(
				req,
				apiPathRegex[1],
				sessionCredentials,
				queryString
			);
		}
		return next.handle(authReq);
	}

	/**
	 * Parse the parameters supplied ready for authentication
	 */
	private parseParameterString(paramStr: string): string {
		//OK we want to process the parameter string this involes splitting it up
		//into each parameter. Sorting them into alphabetical order then rebuilding the string
		return paramStr
			.split("&")
			.filter((param) => param.length > 0)
			.map((param) => {
				let paramPart = param.split("=");
				return { key: paramPart[0], value: paramPart[1] };
			})
			.sort((n1, n2) => {
				// Get the keys and values as strings. This is to ensure
				// that all comparisons are done with string values.
				// This means 150 will actually equate to less and thefore
				// be before 95.
				let n1Key: string = n1["key"] === undefined ? "" : n1["key"].toString();
				let n2Key: string = n2["key"] === undefined ? "" : n2["key"].toString();
				let n1Value: string =
					n1["value"] === undefined ? "" : n1["value"].toString();
				let n2Value: string =
					n2["value"] === undefined ? "" : n2["value"].toString();

				n1Key = n1Key.toLowerCase();
				n2Value = n2Value.toLowerCase();

				n1Value = n1Value.toLowerCase();
				n2Key = n2Key.toLowerCase();

				if (n1Key > n2Key) {
					return 1;
				}
				if (n1Key < n2Key) {
					return -1;
				}
				if (n1Value > n2Value) {
					return 1;
				}
				if (n1Value < n2Value) {
					return -1;
				}
				return 0;
			})
			.map((obj) => obj.key + "=" + obj.value)
			.join("&");
	}
}
