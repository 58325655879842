import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "integerToPostfixMagPipe",
})
export class IntegerToPostfixMagnitudePipe implements PipeTransform {
	transform(value: number, args?: any): any {
		if (isNaN(value)) value = 0;

		if (value == null) value = 0;

		// Values less than a thousand we show as a count.
		if (value < 1000) return Math.round(value).toString();

		// Between 1 and 10 thousand show 2 decimal place.
		if (value < 9999) return Math.round(value / 10) / 100 + "K";

		// Between 10 and 100 thousand show 1 decimal place
		if (value < 99999) return Math.round(value / 100) / 10 + "K";

		// Over 100 thousand we only show whole values with a K.
		if (value < 999999) return Math.round(value / 1000) + "K";

		// Over 1 Million we only show whole values with a M.
		if (value < 999999999) return Math.round(value / 1000000) + "M";

		// The only thing we are likely to be counting over 1000 Million is proabably
		// File sizes, so will use Byte units. Giga , Terra , rather than Billion etc.

		// Over 100 Million we post fix a G for giga bytes with 1 DP
		if (value < 99999999999) return Math.round(value / 100000000) / 10 + "G";

		// Over 1000 Million we post fix a G for giga bytes
		if (value < 999999999999) return Math.round(value / 1000000000) + "G";

		// Terra Bytes..
		return Math.round(value / 1000000000) + "T";
	}
}
