import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { EntityPermissions } from "app/valueObjects/ahub/accounts/entity-permissions.ahub";
import { BehaviorSubject } from "rxjs";

@Component({
	selector: "app-distribution-aview-content-filters",
	templateUrl: "./distribution-aview-content-filters.component.html",
	styleUrls: ["./distribution-aview-content-filters.component.css"],
})
export class DistributionAviewContentFiltersComponent implements OnInit {
	/**
	 * These are the distribution editor permissions required to
	 * add/remove restrictions.
	 */
	distributionEditor = EntityPermissions.DISTRIBUTION_EDITOR;

	@Input() skuFiltersInherited$: BehaviorSubject<boolean>;

	@Input() isGroupSettings = false;

	@Input() readOnly = false;

	@Input() userExportReadOnly = false;

	@Input() distributionForm: UntypedFormGroup;

	constructor() {
		// This is intentional
	}

	ngOnInit() {}

	inheritOnChange($event) {
		this.skuFiltersInherited$.next($event.checked);
	}
}
