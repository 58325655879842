import { Component, Input, OnInit } from "@angular/core";
import {
	SelectionUserFeedbackACatalogVO,
	UserIndexAHubVO,
} from "@harksolutions/ahub-web-services-types";
import { ACatalogActions } from "app/store/actions/acatalog.actions";
import { aHubStatePermanentUsers } from "app/store/selector/ahub/ahub-permanent.selector";
import { sessionUserId } from "app/store/selector/app.selector";
import { StoreAccess } from "app/store/store-access";
import { UserAHubVO } from "app/valueObjects/ahub/accounts/user.ahub.vo";
import { combineLatest, Observable, of } from "rxjs";
import { delay, filter, map } from "rxjs/operators";
import { FeedbackView } from "../feedback-message-widget.component";

@Component({
	selector: "app-feedback-message",
	templateUrl: "./feedback-message.component.html",
	styleUrls: ["./feedback-message.component.scss"],
})
export class FeedbackMessageComponent implements OnInit {
	constructor() {
		// This is intentional
	}

	@Input() feedback: FeedbackView;
	// @Input() feedbackUser: UserIndexAHubVO
	// user$: Observable<UserIndexAHubVO>;
	@Input() isCurrentUser: boolean = false;

	ngOnInit() {
		// This is intentional
	}

	deleteFeedbackClicked(feedbackId) {
		StoreAccess.dispatch(
			ACatalogActions.selectionFeedbackDelete(
				this.feedback.selectionId,
				feedbackId
			)
		);
	}
}
