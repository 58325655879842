import { AHubService } from "app/services/ahub/ahub.service";
import { Observable } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { ComponentActions } from "../actions/component.actions";
import { ActionComponentDataSetProductSearchIdsFetch } from "../actions/types/component.action-types";
import { Epic } from "./epic";
import { EpicsBase } from "./epics-base";

/**
 * Class for the View epic functions
 */
export class ComponentEpics extends EpicsBase implements Epic {
	constructor(private readonly aHubService: AHubService) {
		super();
	}

	/**
	 * Returns a list of the epic function avalible from this class
	 * If a new epic is added to the class it must be added to this list
	 */
	epicMethods(): any[] {
		return [this.componentDataSetProductsSearch];
	}

	/**
	 * -----------------------------------------------------
	 * Epics
	 * -----------------------------------------------------
	 */

	componentDataSetProductsSearch = (
		action$: Observable<ActionComponentDataSetProductSearchIdsFetch>
	) => {
		return action$.pipe(
			filter(
				({ type }) =>
					type === ComponentActions.COMPONENT_DATA_SET_PRODUCTS_SEARCH_FETCH
			),
			mergeMap((action) =>
				this.requestSingleDataToAction(
					this.aHubService.dataSetCategoryProductIdsSortOrder(
						action.dataSetCategoryId,
						action.searchTerm
					),
					action,
					(result) =>
						ComponentActions.componentDataSetProductSearchSet(
							action.componentId,
							action.dataSetCategoryId,
							result,
							action.searchTerm
						)
				)
			)
		);
	};
}
