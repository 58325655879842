/**
 * Index list, a list of navigatable items with an index.
 * Optional component factory parameter to allow generation of components
 * for list inclusion.
 *
 *  Supply:  Obvservable items, and a property name to indicate  item property containing its index.
 *  AND: A label function to extract the text to use for the label from the items.
 *  Optional: A component factory and identified Property to pass the item into the generated components which will render the items in the list.
 *  You still need to supply the label function.. as this will be used for search / filtering.
 *
 */

import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
// Core imports.
import {
	Component,
	ComponentFactory,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewChildren,
	ElementRef,
	QueryList,
} from "@angular/core";
// Form imports for search.
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
} from "@angular/forms";
import { ArrayUtils } from "modules/common/array-utils";
// Hark component management
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
//Utils
import { SearchUtils } from "modules/common/search-utils";
import {
	sortAlphanumericAtoZ,
	sortAlphanumericZtoA,
	sortDateNewest,
	sortDateOldest,
	sortNumeric0to9,
	sortNumeric9to0,
} from "modules/common/sort.util";
// Methods for reporting unsaved changes before navigation.
import { UnsavedChangesComponentCheck } from "modules/common/unsaved-changes-component-check";
import { Utils } from "modules/common/utils";
import { combineLatest, Observable, Subject, timer } from "rxjs";
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	publishReplay,
	refCount,
	share,
	startWith,
	take,
	takeUntil,
	tap,
} from "rxjs/operators";
// valueObjects
import { EntityPermissionAHubVO } from "valueObjects/ahub/accounts/entity-permission.ahub.vo";
import { FilterOptionVO } from "valueObjects/view/filter-option.view.vo";
import { SortOptionVO } from "valueObjects/view/sort-option.view.vo";
import { EntityPermissions } from "app/valueObjects/ahub/accounts/entity-permissions.ahub";

@Component({
	selector: "app-index-list",
	templateUrl: "./index-list.component.html",
	styleUrls: ["./index-list.component.css"],
})
@Hark()
export class IndexListComponent implements OnInit, OnDestroy {
	// Visible sub component containig the scrolling section.
	@ViewChild("infiniteList", { static: true })
	infiniteListComponent: HTMLDivElement;
	@ViewChild("infiniteListContainer", { static: true })
	infiniteListContainerComponent: HTMLDivElement;
	@ViewChildren("indexItems", { read: ElementRef })
	indexItems: QueryList<ElementRef>;

	/**
	 * Title to be displayed at the top of the list component
	 */
	@Input() listTitle: string;

	/**
	 * Font size of title (optional)
	 */
	@Input() titleFontSize = "17px";

	/**
	 * Enable Responsive List
	 */
	@Input() responsiveList = true;

	/**
	 * Display item count?
	 */
	@Input() displayItemCount = true;

	/**
	 * Display search header
	 */
	@Input() enableSearchHeader = true;

	/**
	 * Observable array of index objects which will be rendered in the list view
	 */
	@Input() indexes: Observable<any[]>;

	/**
	 * Add button busy observable stream
	 */
	@Input() addButtonBusy: Observable<boolean>;

	/**
	 * Delete button busy observable stream
	 */
	@Input() deleteButtonBusy: Observable<boolean>;

	/**
	 * Disable button busy observable stream
	 */
	@Input() disableButtonBusy: Observable<boolean>;

	/**
	 * Enable button busy observable stream
	 */
	@Input() enableButtonBusy: Observable<boolean>;

	/**
	 * Generic menu button busy observable stream
	 */
	@Input() menuBusy: Observable<boolean>;

	/**
	 * Label function which when passed an index gets a string from the items supplied ( indexes )
	 */
	@Input() labelFunction: (index) => string;

	/**
	 * This simple sort function is ignored if use sort options. If the function is not available
	 * then we default to the label function above.
	 */
	@Input() simpleSortFuction: (index) => string;

	/**
	 * This function if provided creates a searchable string to be used by the search.
	 * If none is provided , the index label is used in stead.
	 */
	@Input() searchableStringFunction: (index) => string;

	/**
	 * Set this flag to false if you would not like the list to auto sort.
	 */
	@Input() doSort = true;

	/**
	 * This is the style to use on the list items.
	 */
	@Input() listItemStyleName = "list-item-48";

	/**
	 * This is the text that appears when there is not content
	 */
	@Input() noContentText = "No Results To Display";

	/**
	 * If set to true, we will put each item in a chip !
	 */
	@Input() useChips = false;

	/**
	 * Are we allowing drag and drop (if drag enabled) or drag sort?
	 */
	@Input() dragSortEnabled = false;

	/**
	 * This emmitted is called when there has been some drag sort success.
	 */
	@Output() dropSuccess: EventEmitter<any[]> = new EventEmitter();

	/**
	 * Id of the list to allow dropping in
	 */
	@Input() dropListId: string;

	/**
	 * Array of list ids which elements of this list should be draggable to (cdkDrag).
	 */
	@Input() connectedToDropList: string[] = [];

	// Component factory that generates component for displaying objects in our list - use instead of LabelFunction.
	@Input() componentFactory: ComponentFactory<any>;

	// Parameter name on the generated component to which to pass the item in - use instead of Label Function.
	@Input() componentVOParamName: string;

	/**
	 * Currently selected index id.
	 */
	@Input() selectedIndexId: any;

	/**
	 * Property to use as a index id from the items ( indexes ) supplied.
	 */
	@Input() indexIDProperty = "id";

	/**
	 * Entity permission required to display add button
	 */
	@Input() addButtonVisibleAs: EntityPermissionAHubVO =
		EntityPermissions.ACCOUNTS_USER;

	// Decides whether to show the 'add' fab button
	@Input() showAdd = true;

	// Decides whether to show the 'delete' fab button
	@Input() showDelete: boolean;

	// Decides whether to show the 'disable' fab button
	@Input() showDisable: boolean;

	// Decides whether to show the 'edit' fab button
	@Input() showEdit: boolean;

	/**
	 * Knowledge base
	 */
	@Input() showKowledgeBaseIcon: boolean;
	@Input() knowledgeBasePagePath: string;

	/**
	 * The sort options to use.
	 */
	@Input() sortOptions: SortOptionVO[] = [];

	/**
	 * Are we displaying the all filters option?
	 */
	@Input() displayAllFilters = true;

	/**
	 * The filter options to use.
	 */
	@Input() filterOptions: FilterOptionVO[] = [];

	/**
	 * Flag to determine whether the list should 'auto select' the first item when created (displayed)
	 */
	@Input() initialIndexSelect = true;

	/**
	 * Flag to show if we are using the navigation gurard
	 */
	@Input() navigationGuardEnabled = true;

	/**
	 * Flag to determine whether the callout box can be used for adding new items
	 */
	@Input() addInputCallout = false;

	/**
	 * Input prompt for callout box
	 */
	@Input() addInputPrompt: string;

	/**
	 * Regexp pattern used to validate added items
	 */
	@Input() pattern: string;

	/**
	 * Regexp pattern used to validate added items
	 */
	@Input() patternFailMessage: string;

	/**
	 * The maximum number of items to load before partial loading kicks in, used to ensure performance is maintained when dealing with large lists.
	 */
	@Input() partialLoad = 250;

	@Input() paginationQuickLoadTrackBy = false;

	/**
	 * Add button click emitter to indicate when the add button has been clicked
	 */
	@Output() addButtonClick: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Delete button click emitter to indicate when the delete button has been clicked
	 */
	@Output() deleteButtonClick: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Disable button click emitter to indicate when the disable button has been clicked
	 */
	@Output() disableButtonClick: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Disable button click emitter to indicate when the edit button has been clicked
	 */
	@Output() editButtonClick: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Selected index click emitter to indicate an index has been selected
	 */
	@Output() selectedIndexClick: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Selected index click emitter to indicate an index has been selected
	 */
	@Output() selectedIndexDoubleClick: EventEmitter<any> =
		new EventEmitter<any>();

	searchFormControl: UntypedFormControl = new UntypedFormControl("");

	/**
	 * Used top toggle the search on smaller screens
	 */
	showSearch = false;
	/**
	 * Form to control how the list is dispayed on screen
	 */
	listControlForm: UntypedFormGroup = this.formBuilder.group({
		searchFormControl: this.searchFormControl,
		sortPropertyName: [""],
		sortInverted: [false],
	});

	/**
	 * The form controling the filter options.
	 */
	listFilterForm: UntypedFormGroup = this.formBuilder.group({});

	/**
	 * Create a stream which represents the stream of form value changes
	 */
	listControlFormValues$: Observable<any> =
		this.listControlForm.valueChanges.pipe(debounceTime(200));

	/**
	 * Create a stream which represents the filter form value changes.
	 */
	listFilterFormValues$: Observable<any> =
		this.listFilterForm.valueChanges.pipe(debounceTime(200));

	/**
	 * This is the current count of indexes.
	 */
	indexesCount$: Observable<number>;

	/**
	 * A stream of the indexs which will be filtered
	 *
	 * We use a startWith on the form values steam so that our combined latest doesn't have to wait for a change in
	 * form state before it displays an unfiltered list
	 */
	indexesFiltered$: Observable<any[]> = undefined;
	indexesFiltered: any[] = undefined; // snapo shot of last index list constructed.

	/**
	 * This is the count of filtered indexes.
	 */
	indexesFilteredCount$: Observable<number>;

	/**
	 * Using partial displaying on scrolling, this is loaded area.
	 */
	viewStart = 0;
	viewEnd = 1;
	indexCount = 0; // This is a relection of a counting observable, used for display purposes - had issues with share.
	autoScroll = false; // Set to true when we are taking control of the scrolling, to prevent us scrolling trigging the scrolling functions!

	/**
	 * Viewable margin to preload when scrolling.
	 */
	loadMargin = 1000;

	/**
	 * Main Methods
	 */
	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly unsavedChangesComponentCheck: UnsavedChangesComponentCheck
	) {}

	ngOnInit() {
		//If the indexes are undefined then we will bail out
		if (this.indexes === undefined || this.indexes === null) {
			return;
		}

		// Set up the indexes count observable.
		this.indexesCount$ = this.indexes.pipe(
			takeUntil(componentDestroyStream(this)),
			map((items) => (items ? items.length : 0))
		);

		// Count the index, once we have a good value to set the initial siaply of teh scrolling index.
		this.indexesCount$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				filter((itemCount) => itemCount > 0), // Once we get a positive value through ( > 0 ) we will use it to initilisae the scoll view
				take(1)
			) // Only do this once.
			.subscribe((itemCount) => this.viewSliceUpdate(itemCount)); // For partial loading on the scrolling, we'll start with a resonable number.

		// We'll observe the indexCount to set a standard variable ( had trouble subscribing to index with share )
		// TODO: Revisit this...
		this.indexesCount$.subscribe((count) => {
			this.indexCount = count;
			this.viewSliceUpdate(this.indexCount);
			this.updateScrollView();
		});

		// If the form is changed, we need to re-display from the start.
		this.listControlFormValues$
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((formValues) => {
				this.viewStart = 0;
			});

		//Construct a filtered observable stream which will be displayed. This needs to react on Index list change and list form control changes
		//I have included a start with on the form as both items need to have spat items into the combined stream and forms don't until they have changed
		this.indexesFiltered$ = combineLatest(
			this.indexes.pipe(debounceTime(25)), // Prevents Expression changed after initialisation error, also prevents mass clicking causing unnecessay traffic.
			this.listFilterFormValues$.pipe(startWith({})),
			this.listControlFormValues$.pipe(startWith({})),
			(indexList, listFilterForm, listControlForm) => {
				//If we dont have an index list so return an empty array
				if (indexList === undefined || indexList === null) {
					return [];
				}

				// Get the selected index object in the list before its filtered.
				const selectedIndexObject = indexList.find(
					(indexItem) =>
						indexItem[this.indexIDProperty] === this.selectedIndexId
				);

				// Filter the list based on the search input - We'll get these stright from the form rather than the observable,
				// which we'll simply use as the trigger.
				indexList = indexList.filter((item) =>
					this.indexFilter(item, listControlForm, listFilterForm)
				);

				// N.B We would rather get this value from listControlForm.sortPropertyName but this does
				// not seem to update as quick as we would like.
				// Get the sort property name.
				const sortPropertyName = listControlForm.sortPropertyName;

				// Get the sort inverted value.
				const sortInverted = listControlForm.sortInverted;

				// Do we have a sort property?
				// Sort the items in the form.
				if (sortPropertyName && sortPropertyName !== "") {
					// We have a sort property, yes so do the sorting.
					indexList.sort((a, b) =>
						this.sortFunction(
							a[sortPropertyName],
							b[sortPropertyName],
							sortInverted
						)
					);
				} else if (!this.doSort) {
					// Do nothing...this list has been told not to auto sort.
				} else {
					indexList.sort((a, b) =>
						this.sortFunction(
							this.simpleSortFuctionInternal(a),
							this.simpleSortFuctionInternal(b),
							false
						)
					);
				}

				// Should we auto select the first item in the list
				if (this.initialIndexSelect) {
					// Does the index object we have selected not exist, or the selected index id is either not set or -1.
					// If any of the above factors are true then we want to pick the next selected id.
					if (
						!selectedIndexObject ||
						!this.selectedIndexId ||
						this.selectedIndexId === -1
					) {
						// This is either the first item after sorting, or undefied if the list is empty.
						const indexObject = indexList.length > 0 ? indexList[0] : undefined;

						// Now we set the selected index object.
						this.selectIndex(indexObject);
					}
				}

				return indexList;
			}
		).pipe(
			takeUntil(componentDestroyStream(this)),
			publishReplay(1),
			refCount()
		);

		this.indexesFiltered$
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((indexes) => (this.indexesFiltered = indexes));

		// Set up the indexes count observable.
		this.indexesFilteredCount$ = this.indexesFiltered$.pipe(
			takeUntil(componentDestroyStream(this)),
			map((items) => (items ? items.length : 0)),
			distinctUntilChanged(),
			share()
		);

		//  Update the scroll view ( this acts as a pre-display as we start with only one item shown. )
		this.updateScrollViewRepeat();
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	ngOnChanges() {
		this.selectionUpdate();
	}

	selectionUpdate() {
		// Have we got a selected index id?
		if (
			this.selectedIndexId === undefined ||
			this.selectedIndexId < 0 ||
			this.indexesFiltered === undefined
		) {
			return; // No, do nothing.
		}

		// Get the selected index object in the list before its filtered.
		const selectedIndexObject = this.indexesFiltered.find(
			(indexItem) => indexItem[this.indexIDProperty] === this.selectedIndexId
		);
		const selectedIndexPosition: number =
			this.indexesFiltered.indexOf(selectedIndexObject);

		if (selectedIndexPosition === -1) {
			return; // No such item ?!?1?!
		}

		if (
			selectedIndexPosition >= this.viewStart &&
			selectedIndexPosition < this.viewEnd
		) {
			// Item is already in list display.. just need to move it one screen.
			this.scrollToSelected();
			return;
		}

		// Item is not currently rendered, we will render the whole list
		// Select and move to it
		// Then re-tidy the display to optimise performance.

		// We need to jump to newly selected Id.. hmm.. Tried a few methods wihtout success, going to try some thing basic.
		// Not that proud of this, but it works until someone thinks os something better.
		this.viewStart = 0;

		this.viewEnd = this.indexCount;
		this.autoScroll = true;

		// Search for the item in the list , then scroll to it.
		// We'll try a few times just incase stuff is loading.. but should find it in 1.
		const scrollSearchStop: Subject<void> = new Subject<void>();
		timer(100, 100)
			.pipe(
				takeUntil(componentDestroyStream(this)),
				takeUntil(scrollSearchStop),
				take(10)
			) // Limit in case we somehow don't have teh item.. prevents inf loop.
			.subscribe((subTimer) => {
				if (this.scrollToSelected()) {
					scrollSearchStop.next();
					this.updateScrollViewRepeat();
				}
			});
	}

	scrollToSelected(): boolean {
		if (!this.indexItems) {
			return;
		}

		//Calculated height of all the items until the item which we want to display
		let calculatedHeight = 0;

		//Loop through each of the children indexes, this will allow us to update
		this.indexItems.forEach((indexItem) => {
			//Get the child from the children list
			const child = indexItem.nativeElement;

			//Get the id of the child item
			const childId = child.id;

			//Did we find our selected item? Sweet
			if (childId && childId == this.selectedIndexId) {
				//Calculate the top and the bottom of the sroller
				const scrollTop =
					this.infiniteListContainerComponent["nativeElement"].scrollTop;
				const scrollBottom =
					scrollTop +
					this.infiniteListContainerComponent["nativeElement"].offsetHeight;

				//Is the currently selected offscreen? Set the scroll top of the element
				if (
					calculatedHeight < scrollTop ||
					calculatedHeight > scrollBottom - child.offsetHeight
				) {
					this.infiniteListContainerComponent["nativeElement"].scrollTop =
						Math.max(calculatedHeight, 0); // Move selected item to within view
				}

				return true; // Found It.
			} else {
				// To get also the margin or padding into the equation
				const computedStyle = window.getComputedStyle(child);
				let margin = 0;
				let padding = 0;

				try {
					// Slice removes 'px' at the end
					margin =
						parseInt(computedStyle.marginTop.slice(0, -2)) +
						parseInt(computedStyle.marginBottom.slice(0, -2));
					padding =
						parseInt(computedStyle.paddingTop.slice(0, -2)) +
						parseInt(computedStyle.paddingBottom.slice(0, -2));
				} catch (error) {
					console.error("Index list calculated height error", error);
				}
				//OK so this wasn't the item so add the heigh of the element to the calculated height
				//this will move it down the page.
				calculatedHeight += child.offsetHeight + margin + padding;
			}
		});

		return false; // Hav'nt found what I'm looking for.
	}

	/**
	 * Performs a tidy up using the update scroll view function,
	 * but uses a obsrvable timer repeat to keep going until the job is done.
	 */
	updateScrollViewRepeat() {
		const scrollTidyStop: Subject<void> = new Subject<void>();
		this.autoScroll = true;
		timer(25, 25)
			.pipe(
				takeUntil(scrollTidyStop),
				takeUntil(componentDestroyStream(this)),
				filter((timer) => {
					const nativeInfiniteListContainer =
						this.infiniteListContainerComponent["nativeElement"];
					return nativeInfiniteListContainer.clientHeight !== 0;
				}), // Make sure we have the container.
				tap((count) => {
					if (count >= 499) {
						this.autoScroll = false;
					}
				}), // Switch back on normal scrolling if we don;t fininsh auto scroll.
				take(500)
			) // Limit in case we somehow don't finish tidying ?
			.subscribe((subTimer) => {
				if (!this.updateScrollView()) {
					// If teh update view did'nt do anything, then its complete,  stop.
					scrollTidyStop.next();
					this.autoScroll = false;
				}
			});
	}

	// Updates the number of items that are drawn on the component.. this improves performance of the app when dealing with 1000s of items.
	// We pre load items at the beginning and end of the list, and remove those which are to far beyond the visible portion.
	// Returns true if the view has been updated, consider repeating calls whilst true is returned, until false.
	updateScrollView(fromEvent?: boolean): boolean {
		// Was this triggered by an event.
		if (fromEvent === undefined) {
			fromEvent = false;
		}

		// If we are controlling the scroll , we can ignore on screen scroll events.
		if (fromEvent && this.autoScroll) {
			return; // Ignore event.
		}

		// We wont bother with partial loading of scroll items if we are dealing with less than X items.
		if (this.indexCount < this.partialLoad) {
			this.viewStart = 0;
			this.viewEnd = this.indexCount;
			return false;
		}

		// Obtain list component.
		const nativeInfiniteList = this.infiniteListComponent["nativeElement"];
		const nativeInfiniteListContainer =
			this.infiniteListContainerComponent["nativeElement"];

		// Are we showing all the items in the list.
		if (this.viewStart - this.viewEnd >= this.indexCount) {
			return false; // showing everything, nothing to do.
		}

		const orgViewStart = this.viewStart;
		const orgViewEnd = this.viewEnd;

		const loadableAreaBegin: number = this.loadMargin;
		const unloadableAreaBegin: number = this.loadMargin * 2; // Start loading when less than 1000 , start unloading when more than 2000.

		const loadableAreaEnd: number =
			nativeInfiniteListContainer.scrollTop +
			nativeInfiniteListContainer.clientHeight +
			this.loadMargin;
		const unloadAreaEnd: number =
			nativeInfiniteListContainer.scrollTop +
			nativeInfiniteListContainer.clientHeight +
			this.loadMargin * 2;

		// We are not showing everything... Does the content finish (too) close to the viewable area ?
		if (loadableAreaEnd > nativeInfiniteList.scrollHeight) {
			// We should show more.
			this.viewEnd = Math.min(this.indexCount, this.viewEnd + 1);
		}

		// Does the content scroll to far of the top ?
		if (nativeInfiniteListContainer.scrollTop > unloadableAreaBegin) {
			// We should show less, take some off the top.
			this.viewStart = this.viewStart + 1;
		}

		// We are not showing everything... Does the content finish (too) close to the viewable area ?
		if (unloadAreaEnd < nativeInfiniteList.scrollHeight) {
			// We should show more.
			this.viewEnd = Math.min(this.indexCount, this.viewEnd - 1);
		}

		// Does the content scroll to far of the top ?
		if (nativeInfiniteListContainer.scrollTop < loadableAreaBegin) {
			// We should show less, take some off the top.
			this.viewStart = Math.max(this.viewStart - 1, 0);
		}

		// Return an indication if the scroll area has changed.
		// True if it has changed.
		return orgViewStart !== this.viewStart || orgViewEnd !== this.viewEnd;
	}

	/**
	 * This function is used to make sure that the view start and end are within the correct boundaries.
	 */
	private viewSliceUpdate(indexCount: number) {
		// Is the item count less than the view end?
		if (this.viewEnd > indexCount) {
			// Yes, so we need to move the view area to just below the limit.
			this.viewEnd = indexCount;

			// The view start then needs to be 50 less, or 0 if the view end is less than 50.
			this.viewStart = Math.max(0, this.viewEnd - 50);

			// Turning auto scroll of as changed view area.
			this.autoScroll = false;
		}

		// Is the index count greater than the view end?
		if (indexCount > this.viewEnd) {
			// Yes, so if the gap between the view start and end is less than 50 we want
			// set the view end to the start + 50, or the item count, whatever is lower.
			if (this.viewEnd - this.viewStart < 50) {
				this.viewEnd = Math.min(indexCount, this.viewStart + 50);
			}

			// Turning auto scroll of as changed view area.
			this.autoScroll = false;
		}
	}

	/**
	 * This function will do all of the sorting required in this component.
	 *
	 * @param variableA         The first variable to compare.
	 * @param variableB         The second variable to compare.
	 * @param inverted          Are we inverting the list?
	 */
	private sortFunction(variableA, variableB, inverted = false): number {
		// Are we sorting numbers (eg 0, 1, 8, 11)
		if (typeof variableA === "number" && typeof variableB === "number") {
			return inverted
				? sortNumeric9to0(variableA, variableB)
				: sortNumeric0to9(variableA, variableB);
		}

		// Check the variables objects are the same.
		if (
			Object.prototype.toString.call(variableA) !==
			Object.prototype.toString.call(variableB)
		) {
			// If not, tell the developer.

			// Then convert the values into strings.
			variableA = String(variableA);
			variableB = String(variableB);

			// Then sort using the alpha numberic sort functions.
			return inverted
				? sortAlphanumericZtoA(variableA, variableB)
				: sortAlphanumericAtoZ(variableA, variableB);
		}

		// Do we have date variables? If so, use the date sorting.
		if (variableA instanceof Date) {
			return inverted
				? sortDateNewest(variableA, variableB)
				: sortDateOldest(variableA, variableB);
		}

		// If we get here then sort using the alpha numberic sort functions.
		return inverted
			? sortAlphanumericZtoA(variableA.toString(), variableB.toString())
			: sortAlphanumericAtoZ(variableA.toString(), variableB.toString());
	}

	/**
	 * Select the current index
	 */
	selectIndex(index: any) {
		//Do we have a currently selected index?
		//Yes good well we may want to check if we can navigate first.
		if (index) {
			//Are using using the navigation guard?
			if (this.navigationGuardEnabled) {
				// Check if we have unsaved changes
				this.unsavedChangesComponentCheck
					.unsavedChangesGuard()
					.pipe(takeUntil(componentDestroyStream(this)))
					.subscribe((canContinue) => {
						//If we are able to continue we will emit the selected evetn
						if (canContinue) {
							this.selectedIndexClick.emit(index);
						}
					});
			} else {
				this.selectedIndexClick.emit(index);
			}
		} else {
			//OK no selected index we will report the undefined index change and move on
			this.selectedIndexClick.emit();
		}
	}

	/**
	 * This handler is called when there has been success with the drag sorting.
	 */
	onDropSuccess(event: CdkDragDrop<any[]>) {
		moveItemInArray(
			this.indexesFiltered,
			event.previousIndex,
			event.currentIndex
		);

		// Emit the sorted values.
		this.dropSuccess.emit(this.indexesFiltered);
	}

	/**
	 * Click handler for the add button
	 */
	addButtonClickHandler($event) {
		//Are using using the navigation guard?
		if (this.navigationGuardEnabled) {
			// Check if we have unsaved changes
			this.unsavedChangesComponentCheck
				.unsavedChangesGuard()
				.pipe(takeUntil(componentDestroyStream(this)))
				.subscribe((canContinue) => {
					if (canContinue) this.addButtonClick.emit($event);
				});
		} else {
			//Call the add button click emitter to emmit an event
			this.addButtonClick.emit($event);
		}
	}

	/**
	 * Click handler for the delete button
	 */
	deleteButtonClickHandler() {
		//Are using using the navigation guard?
		if (this.navigationGuardEnabled) {
			// Check if we have unsaved changes
			this.unsavedChangesComponentCheck
				.unsavedChangesGuard()
				.pipe(takeUntil(componentDestroyStream(this)))
				.subscribe((canContinue) => {
					if (canContinue) this.deleteButtonClick.emit();
				});
		} else {
			//Call the add button click emitter to emmit an event
			this.deleteButtonClick.emit();
		}
	}

	/**
	 * Click handler for the delete button
	 */
	disableButtonClickHandler() {
		//Are using using the navigation guard?
		if (this.navigationGuardEnabled) {
			// Check if we have unsaved changes
			this.unsavedChangesComponentCheck
				.unsavedChangesGuard()
				.pipe(takeUntil(componentDestroyStream(this)))
				.subscribe((canContinue) => {
					if (canContinue) this.disableButtonClick.emit();
				});
		} else {
			//Call the add button click emitter to emmit an event
			this.disableButtonClick.emit();
		}
	}

	/**
	 * Click handler for the edit button
	 */
	editButtonClickHandler() {
		//Are using using the navigation guard?
		if (this.navigationGuardEnabled) {
			// Check if we have unsaved changes
			this.unsavedChangesComponentCheck
				.unsavedChangesGuard()
				.pipe(takeUntil(componentDestroyStream(this)))
				.subscribe((canContinue) => {
					if (canContinue) {
						this.editButtonClick.emit();
					}
				});
		} else {
			//Call the add button click emitter to emmit an event
			this.editButtonClick.emit();
		}
	}

	/**
	 * Is the item currently selected
	 */
	isSelected(index) {
		//If we have no index then this item isn't selected
		if (!index) {
			return false;
		}
		//Return if this item is currently selected in the store
		return index[this.indexIDProperty] === this.selectedIndexId;
	}

	/**
	 * This is the simple sort function called when we don't have complex sort options.
	 *
	 * @param vo      The value object who's sort value we want.
	 */
	private simpleSortFuctionInternal(vo) {
		// Do we have a simple sort function? If not, use the label function.
		if (!this.simpleSortFuction) {
			return this.labelFunctionInternal(vo);
		}

		// Otherwise call the simple sort function.
		return this.simpleSortFuction(vo);
	}

	/**
	 * Internal label function to format the string, this will also check that the label function is defined
	 */
	private labelFunctionInternal(vo): string {
		//Is the label function undefined then return undefied straigt away
		if (this.labelFunction === undefined) {
			return undefined;
		}

		//Return the result of the label function
		return this.labelFunction(vo);
	}

	/**
	 * Filter function for the index items
	 */
	indexFilter(indexItem: any, formValue, filterFormValue) {
		// Handle undefined filterOptions
		if (this.filterOptions === undefined) {
			return false;
		}

		// Let's get a list of the filter options we need to actually use.
		const currentFilters: FilterOptionVO[] = this.filterOptions.filter(
			(filterOption) =>
				this.listFilterForm.controls[filterOption.label].value !==
				filterOption.filterInvert
		);

		// If we have no filters on but we do have some filters then hide this item index.
		if (currentFilters.length === 0 && this.filterOptions.length > 0) {
			return false;
		}

		// Do we have any current filters?
		if (currentFilters.length > 0) {
			// We need to group the result per filter property.
			// So create a new list to fill.
			const groupedFilters: FilterOptionVO[] = [];

			// Now group them up.
			currentFilters.forEach((filterOption) => {
				// Look for the grouped filter option in the new list.
				let groupedFilterOption = groupedFilters.find(
					(existingFilterGroup) =>
						existingFilterGroup.filterProperty === filterOption.filterProperty
				);

				// If we couldn't find the filter option then we need to add it to the grouped list.
				if (!groupedFilterOption) {
					// But clone it first.
					groupedFilterOption = Utils.clone(filterOption);

					// Add it to the list.
					groupedFilters.push(groupedFilterOption);

					// Then move on.
					return;
				}

				// Now we need to merge the filter values and not values.
				groupedFilterOption.filterValues = ArrayUtils.mergeNoDuplicates([
					groupedFilterOption.filterValues,
					filterOption.filterValues,
				]);
			});

			// Now let's get the list of filter options that have values matching a value in
			// the item index.
			const matchingFilters = groupedFilters.filter(
				(filterOption) =>
					filterOption.filterValues.find(
						(filterValue) =>
							filterValue == indexItem[filterOption.filterProperty]
					) != undefined
			);

			// Is the list empty? If so, return false.
			if (matchingFilters.length !== groupedFilters.length) {
				return false;
			}
		}

		// Are we showing the search bar? If not, return true as we want to show all.
		if (this.labelFunction === undefined) {
			return true;
		}

		// Search the searchable string created from the index from the supplied function.
		// If no function was supplied we will search the label.
		let searchString: string;
		if (this.searchableStringFunction === undefined) {
			searchString = this.labelFunctionInternal(indexItem);
		} else {
			searchString = this.searchableStringFunction(indexItem);
		}

		// If we get here then call the search utilies to do the work.
		return SearchUtils.stringSearch(formValue.searchFormControl, [
			searchString,
		]);
	}

	trackById(index, indexItem) {
		if (this.paginationQuickLoadTrackBy) {
			return indexItem.id;
		}
		return indexItem;
	}

	toggle() {
		if (this.showSearch) {
			this.showSearch = false;
			// Clears search term to reset the search list on expander close on small screens.
			this.searchFormControl.setValue("");
		} else {
			this.showSearch = true;
		}
	}

	listItemDoubleClickHandler(itemData) {
		this.selectedIndexDoubleClick.emit(itemData);
	}
}
