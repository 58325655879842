import { Action, Reducer } from "redux";
import { createReducer } from "reducers/reducer-util";
import { tassign } from "modules/common/type-assign.util";

/**
 * Action Types
 */
import { ActionString } from "actions/types/common.action-types";

/**
 * Actions
 */
import { ViewActions } from "actions/view.actions";
import { ActionWork } from "actions/types/work.action-types";

/**
 * ----------------------------------
 * App State interface
 * ----------------------------------
 */
export interface ViewLoginState {
	loginURL: string;
	tokenValidated: boolean;
	tokenErrorMessage: string;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewLoginInitialState: ViewLoginState = {
	loginURL: undefined,
	tokenValidated: false,
	tokenErrorMessage: undefined,
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Clear the login view state.
 */
export const loginClear = (state: ViewLoginState, action: ActionWork) => {
	// Reset the session state.
	return tassign(state, ViewLoginInitialState);
};

/**
 * Set the login identifier provider URL.
 */
export const loginURLSet = (state: ViewLoginState, action: ActionString) => {
	//Set the login identifier provider URL.
	return tassign(state, { loginURL: action.string });
};

/**
 * Set the auth token token.
 */
export const loginTokenTokenValidatedSet = (state: ViewLoginState) => {
	// Set the auth token.
	return tassign(state, { tokenValidated: true });
};

/**
 * Set the login error message.
 *
 * @param state 			The state to apply the change too.
 * @param action 			The action that started the change.
 *
 * @returns 				The new state to use.
 */
export const loginTokenErrorSet = (
	state: ViewLoginState,
	action: ActionString
) => {
	//Set the login error message.
	return tassign(state, { tokenErrorMessage: action.string });
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to
 */
reducerHandlers[ViewActions.LOGIN_CLEAR] = loginClear;
reducerHandlers[ViewActions.LOGIN_URL_SET] = loginURLSet;
reducerHandlers[ViewActions.LOGIN_TOKEN_VALIDATED_SET] =
	loginTokenTokenValidatedSet;
reducerHandlers[ViewActions.LOGIN_TOKEN_ERROR_SET] = loginTokenErrorSet;

/**
 * Create a reducers based on the reducers handlers
 */
export const ViewLoginReducer: Reducer<ViewLoginState> = createReducer(
	ViewLoginInitialState,
	reducerHandlers
);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewLoginReducerHasHandler = (actionType: string): boolean =>
	reducerHandlers.hasOwnProperty(actionType);
