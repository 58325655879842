import { BulkTransferRecordAppVO } from "valueObjects/app/bulk-transfer-record.app.vo";
import { List } from "store/list.vo";
import { ComponentDataSetProductsState } from "app/store/reducers/component/component-data-set-products.reducer";
import { componentState } from "selector/component/component.selector";
import { MapStorage } from "app/store/map-storage.vo";
import { DataSetProductsComponentsVO } from "app/valueObjects/component/data-set-products.component.vo";

/**
 * Root selector to get the data set product state
 */
const componentDataSetProductState = (state): ComponentDataSetProductsState =>
	componentState(state).dataSetProducts;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */

/**
 * A selector to access the components
 */
export const componentDataSetProductStateMap = (
	state
): MapStorage<DataSetProductsComponentsVO> =>
	componentDataSetProductState(state).componentMap;
