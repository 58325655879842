import { Component, OnInit, ViewChild } from "@angular/core";

@Component({
	selector: "app-file-save-dialog",
	templateUrl: "./file-save-dialog.component.html",
	styleUrls: ["./file-save-dialog.component.css"],
})
export class FileSaveDialogComponent implements OnInit {
	//Link to the add item text input
	@ViewChild("fileNameInput", { static: true }) fileNameInput;

	fileName: string = "";

	/**
	 * The defauly file extension.
	 */
	private static readonly DEFAULT_FILE_EXTENSION: string = "csv";

	/**
	 * This is the files extension.
	 */
	fileExtension: string = FileSaveDialogComponent.DEFAULT_FILE_EXTENSION;

	/**** REQUIRED FOR USE IN A DIALOGUE  ****/
	/**
	 * The VO that is passed in / out.
	 */
	public dialogVO: any = null;

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		setTimeout(() => {
			this.fileNameInput.nativeElement.focus();
		}, 1);
		this.fileName = this.dialogVO.fileName;
		this.fileExtension = this.dialogVO.fileExtension;

		// Set the file extension to the default if null or empty.
		if (!this.fileExtension || this.fileExtension.trim().length === 0) {
			this.fileExtension = FileSaveDialogComponent.DEFAULT_FILE_EXTENSION;
		}
	}

	updateDialogVO() {
		this.dialogVO.fileName = this.fileName;
	}
}
