import { takeUntil } from "rxjs/operators";
import {
	Component,
	OnInit,
	Input,
	ComponentFactory,
	ComponentFactoryResolver,
	OnDestroy,
} from "@angular/core";
import { Observable } from "rxjs";
import { Utils } from "modules/common/utils";
import { Hark, componentDestroyStream } from "modules/common/hark.decorator";

/**
 * Components.
 */
import { AddEditProductClassRuleDialogComponent } from "./add-edit-product-class-rule-dialog/add-edit-product-class-rule-dialog.component";

/**
 * Value objects.
 */
import { ProductClassFilterAHubVO } from "valueObjects/ahub/library/product-class-filter.ahub.vo";
import { ProductClassFilterOptionData } from "modules/common/vo-render/product-class-filter/product-class-filter-option-data.vo";

/**
 * Services.
 */
import { DialogService } from "modules/common/dialogs/dialog.service";
import { ProductClassRuleAHubVO } from "valueObjects/ahub/library/product-class-rule.ahub.vo";

@Component({
	selector: "app-product-class-filter",
	templateUrl: "./product-class-filter.component.html",
	styleUrls: ["./product-class-filter.component.css"],
})
@Hark()
export class ProductClassFilterComponent implements OnInit, OnDestroy {
	/**
	 * The list of product class filter objects we can display.
	 */
	@Input()
	productClassFilterOptions$: Observable<ProductClassFilterOptionData>[] = [];

	/**
	 * The product filter we need to work with.
	 */
	@Input()
	productClassFilterToDisplay: ProductClassFilterAHubVO = {
		productClassRules: [],
	};

	/**
	 * Are we allowing editing?
	 */
	@Input()
	allowEditing: boolean;

	/**
	 * This is the function that is called when a product class rule has been added.
	 */
	@Input()
	productClassRuleAddedFunction: Function;

	/**
	 * This is the function that is called when a product class rule has been updated.
	 */
	@Input()
	productClassRuleUpdatedFunction: Function;

	/**
	 * This is the function to call when the product class has been deleted.
	 */
	@Input()
	productClassRuleDeletedFunction: Function;

	constructor(
		private dialogueService: DialogService,
		private resolver: ComponentFactoryResolver
	) {}

	/**
	 * Empty On Init to ensure @Hark decorator works for an AOT build
	 */
	ngOnInit() {
		// This is intentional
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * This function is called when the user clicks on the add product class rule button.
	 * This is the first level of interaction, you then add tests to the rule.
	 */
	addProductClassRule() {
		// Create a new product class rule that we will set up.
		let newProductClassRule: ProductClassRuleAHubVO = {
			excludeClassIds: [],
			includeClassId: -1,
		};

		// Get the copmponent Factory we will use to create the component dialog
		// we will use to create a new product class rule.
		let componentFactory: ComponentFactory<AddEditProductClassRuleDialogComponent> =
			this.resolver.resolveComponentFactory(
				AddEditProductClassRuleDialogComponent
			);

		// Create a value object to pass to the dialogue.
		let dialogVO: any = {
			classOptionObjects$: this.productClassFilterOptions$,
			productClassRule: newProductClassRule,
		};

		// Open the custom component dialogue , using the component factory to create the component content, passing in a blank VO.
		let newDialogue = this.dialogueService.componentDialogOpen(
			"Add class rule",
			componentFactory,
			"dialogVO",
			dialogVO,
			"dialogIsValid$",
			"Add",
			"Cancel"
		);

		// Subscribe to the new dialogue, if we recive an product class rule then we will add it
		newDialogue.subscribe((dialogVO) => {
			// If the new VO is undefined then we will bail out, the user cancelled.
			if (!dialogVO || dialogVO.productClassRule == undefined) return;

			// Make a copy of the filter that comes in and make amendments to that prior to sending to aHub
			// This way we only display what really comes back from aHub and not what adjustments (adds and deletes)
			// might have been made locally
			let cloneOfProductClassFilter: ProductClassFilterAHubVO =
				this.productClassFilterCloneGet();

			if (!cloneOfProductClassFilter) {
				cloneOfProductClassFilter = {
					productClassRules: [],
				};
			}

			// Add the new rule.
			cloneOfProductClassFilter.productClassRules.push(
				dialogVO.productClassRule
			);

			// Call the product class rule test added function.
			if (this.productClassRuleAddedFunction)
				this.productClassRuleAddedFunction(cloneOfProductClassFilter);
		});
	}

	/**
	 * This function is called when we want to edit a product class rule.
	 *
	 * @param productClassRuleIndex     The index of the product class that needs to be edited.
	 */
	editProductClassRule(productClassRuleIndex) {
		// Get the product class to edit.
		let editProductClassRule: ProductClassRuleAHubVO =
			productClassRuleIndex >
			this.productClassFilterToDisplay.productClassRules.length
				? null
				: this.productClassFilterToDisplay.productClassRules[
						productClassRuleIndex
				  ];

		// Make sure we have a product class to edit.
		if (!editProductClassRule) {
			editProductClassRule = {
				excludeClassIds: [],
				includeClassId: -1,
			};
		} // Otherwise we make sure the product class rule has been cloned.
		else editProductClassRule = Utils.clone(editProductClassRule);

		// Get the copmponent Factory we will use to create the component dialog
		// we will use to update a product class rule.
		let componentFactory: ComponentFactory<AddEditProductClassRuleDialogComponent> =
			this.resolver.resolveComponentFactory(
				AddEditProductClassRuleDialogComponent
			);

		// Create a value object to pass to the dialogue
		let dialogVO: any = {
			classOptionObjects$: this.productClassFilterOptions$,
			productClassRule: editProductClassRule,
		};

		// Open the custom component dialogue , using the component factory to create the component content, passing in a blank VO.
		let updateDialogue = this.dialogueService.componentDialogOpen(
			"Edit class rule",
			componentFactory,
			"dialogVO",
			dialogVO,
			"dialogIsValid$",
			"Save",
			"Cancel"
		);

		// Subscribe to the update dialogue, if we recive an product class rule then we will save it
		updateDialogue.subscribe((dialogVO) => {
			// If the update VO is undefined then we will bail out, the user cancelled.
			if (!dialogVO || dialogVO.productClassRule == undefined) return;

			// Make a copy of the filter that comes in and make amendments to that prior to sending to aHub
			// This way we only display what really comes back from aHub and not what adjustments (saves and deletes)
			// might have been made locally
			let productClassRuleToEdit: ProductClassFilterAHubVO =
				this.productClassFilterCloneGet();

			// Add the updated rule.
			productClassRuleToEdit.productClassRules[productClassRuleIndex] =
				dialogVO.productClassRule;

			// Call the product class rule test added function.
			if (this.productClassRuleUpdatedFunction)
				this.productClassRuleUpdatedFunction(productClassRuleToEdit);
		});
	}

	/**
	 * This handler is called when the user wants to delete a product class rule.
	 *
	 * @param productClassRuleIndex         The index of the product class that needs to be deleted.
	 */
	deleteProductClassRule(productClassRuleIndex) {
		// We need to see if the product class is empty. So get it.
		let productClassRuleToDelete: ProductClassRuleAHubVO =
			this.productClassFilterToDisplay.productClassRules[productClassRuleIndex];

		// Is the product class rule empty?
		if (
			(!productClassRuleToDelete.excludeClassIds ||
				productClassRuleToDelete.excludeClassIds.length == 0) &&
			productClassRuleToDelete.includeClassId < 0
		) {
			// Yes, so remove it from the display list.
			this.productClassFilterToDisplay.productClassRules.splice(
				productClassRuleIndex,
				1
			);

			// Then stop here.
			return;
		}

		// Make a copy of the filter that comes in and make amendments to that prior to sending to aHub
		// This way we only display what really comes back from aHub and not what adjustments (adds and deletes)
		// might have been made locally
		let productClassRuleToEdit: ProductClassFilterAHubVO =
			this.productClassFilterCloneGet();
		productClassRuleToEdit.productClassRules.splice(productClassRuleIndex, 1);

		// Open a dialogue to check they want to delete the product class rule.
		let dialogRef: Observable<boolean> = this.dialogueService.confirmDialogOpen(
			"Delete class rule?",
			"Are you sure you want to delete this product class?"
		);

		// Subscribe to the result of the dialogue box.
		dialogRef
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((result) => {
				// Was the delete confirmed?
				if (result === true) {
					// Call the product class rule test deleted function.
					if (this.productClassRuleDeletedFunction)
						this.productClassRuleDeletedFunction(productClassRuleToEdit);
				}
			});
	}

	/**
	 * Get a clone of the product filters.
	 */
	private productClassFilterCloneGet(): ProductClassFilterAHubVO {
		return Utils.clone(this.productClassFilterToDisplay);
	}
}
