import {
	SelectionACatalogVO,
	SelectionIndexACatalogVO,
	SelectionProductUserReactionACatalogVO,
	SelectionUserFeedbackACatalogVO,
	UserIndexAHubVO,
} from "@harksolutions/ahub-web-services-types";
import { ACatalogDistributionAHubVO } from "@harksolutions/ahub-web-services-types";
import { SelectionConfigACatalogVO } from "@harksolutions/ahub-web-services-types";
import { ExportDistributionIndexAHubVO } from "@harksolutions/ahub-web-services-types";
import { FeedbackView } from "app/modules/common/components/feedback/feedback-message-widget/feedback-message-widget.component";
import { tassign } from "modules/common/type-assign.util";
import { createReducer } from "reducers/reducer-util";
import { Reducer } from "redux";
import { ACatalogActions } from "../actions/acatalog.actions";
import {
	ActionACatalogDistribution,
	ActionPublicationExportDistributionIndexes,
	ActionPublicationSelectionACatalog,
	ActionPublicationSelectionIndexesACatalog,
	ActionSelectionConfigsACatalog,
	ActionSelectionFeedbackViewsACatalog,
	ActionSelectionProductFeedbackViewsACatalog,
	ActionSelectionProductReactionsACatalog,
	ActionSelectionsACatalog,
	ActionSelectionUsersACatalog,
} from "../actions/types/acatalog.action-types";
import { ActionNumberBoolean } from "../actions/types/common.action-types";
import { ActionWork } from "../actions/types/work.action-types";
import { IdListUtil } from "../id-list.util";
import { ListUtil } from "../list.util";
import { List } from "../list.vo";
import { MapStorageUtil } from "../map-storage.util";
import { MapStorage } from "../map-storage.vo";

/**
 * ----------------------------------
 * Catalog State interface
 * ----------------------------------
 */

export interface ACatalogState {
	publicationSelectionIndexes: MapStorage<SelectionIndexACatalogVO[]>;
	selectionList: List<SelectionACatalogVO>;
	selectionUsers: MapStorage<UserIndexAHubVO[]>;
	selectionProductReactionMap: MapStorage<
		MapStorage<SelectionProductUserReactionACatalogVO[]>
	>;
	selectionFeedbackViewsMap: MapStorage<FeedbackView[]>;
	selectionProductFeedbackViewsMap: MapStorage<MapStorage<FeedbackView[]>>;
	selectionProductAllocFeedbackMap: MapStorage<
		MapStorage<MapStorage<SelectionUserFeedbackACatalogVO[]>>
	>;
	selectionDefaultConfigs: SelectionConfigACatalogVO[];
	publicationIsAViewProMap: MapStorage<boolean>;
	publicationExportDistributionIndexesMap: MapStorage<
		ExportDistributionIndexAHubVO[]
	>;
	aCatalogDistributionList: List<ACatalogDistributionAHubVO>;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */

export const ACatalogInitialState: ACatalogState = {
	publicationSelectionIndexes: MapStorageUtil.mapStorageCreate(),
	selectionList: ListUtil.listCreateEmpty(),
	selectionUsers: MapStorageUtil.mapStorageCreate(),
	selectionProductReactionMap: MapStorageUtil.mapStorageCreate(),
	selectionFeedbackViewsMap: MapStorageUtil.mapStorageCreate(),
	selectionProductFeedbackViewsMap: MapStorageUtil.mapStorageCreate(),
	selectionProductAllocFeedbackMap: MapStorageUtil.mapStorageCreate(),
	selectionDefaultConfigs: null,
	publicationIsAViewProMap: MapStorageUtil.mapStorageCreate(),
	publicationExportDistributionIndexesMap: MapStorageUtil.mapStorageCreate(),
	aCatalogDistributionList: IdListUtil.listCreateEmpty(),
};

const aCatalogPublicationSelectionIndexesSet = (
	state: ACatalogState,
	action: ActionPublicationSelectionIndexesACatalog
) => {
	return tassign(state, {
		publicationSelectionIndexes: MapStorageUtil.mapStorageSet(
			state.publicationSelectionIndexes,
			action.publicationId.toString(),
			action.selectionIndexes
		),
	});
};

const aCatalogPublicationSelectionSet = (
	state: ACatalogState,
	action: ActionPublicationSelectionACatalog
) => {
	return tassign(state, {
		selectionList: IdListUtil.listUpdateIfNew(
			state.selectionList,
			action.selection
		),
	});
};

const aCatalogSelectionsSet = (
	state: ACatalogState,
	action: ActionSelectionsACatalog
) => {
	return tassign(state, {
		selectionList: IdListUtil.listAppend(
			state.selectionList,
			action.selections
		),
	});
};

const aCatalogSelectionsClear = (state: ACatalogState, action: ActionWork) => {
	return tassign(state, {
		selectionList: ListUtil.listCreateEmpty(),
	});
};

const aCatalogSelectionUsersSet = (
	state: ACatalogState,
	action: ActionSelectionUsersACatalog
) => {
	return tassign(state, {
		selectionUsers: MapStorageUtil.mapStorageSet(
			state.selectionUsers,
			action.selectionId.toString(),
			action.userIndexes
		),
	});
};

const aCatalogSelectionProductReactionMapSet = (
	state: ACatalogState,
	action: ActionSelectionProductReactionsACatalog
) => {
	return tassign(state, {
		selectionProductReactionMap: MapStorageUtil.mapStorageSet(
			state.selectionProductReactionMap,
			action.selectionId.toString(),
			action.productUserReactionsMap
		),
	});
};

const aCatalogSelectionProductReactionMapClear = (
	state: ACatalogState,
	action: ActionWork
) => {
	return tassign(state, {
		selectionProductReactionMap: MapStorageUtil.mapStorageCreate(),
	});
};

const aCatalogSelectionFeedbackViewsMapSet = (
	state: ACatalogState,
	action: ActionSelectionFeedbackViewsACatalog
) => {
	return tassign(state, {
		selectionFeedbackViewsMap: MapStorageUtil.mapStorageSet(
			state.selectionFeedbackViewsMap,
			action.selectionId.toString(),
			action.feedbackViews
		),
	});
};

const aCatalogSelectionProductFeedbackMapSet = (
	state: ACatalogState,
	action: ActionSelectionProductFeedbackViewsACatalog
) => {
	return tassign(state, {
		selectionProductFeedbackViewsMap: MapStorageUtil.mapStorageSet(
			state.selectionProductFeedbackViewsMap,
			action.selectionId.toString(),
			action.productUserFeedbackViewsMap
		),
	});
};

const aCatalogSelectionProductFeedbackViewsMapClear = (
	state: ACatalogState,
	action: ActionWork
) => {
	return tassign(state, {
		selectionProductFeedbackViewsMap: MapStorageUtil.mapStorageCreate(),
	});
};

const selectionDefaultConfigsSet = (
	state: ACatalogState,
	action: ActionSelectionConfigsACatalog
) => {
	return tassign(state, {
		selectionDefaultConfigs: action.selectionConfigs,
	});
};

const isPublicationAViewProSet = (
	state: ACatalogState,
	action: ActionNumberBoolean
) => {
	return tassign(state, {
		publicationIsAViewProMap: MapStorageUtil.mapStorageSet(
			state.publicationIsAViewProMap,
			action.number.toString(),
			action.boolean
		),
	});
};

const publicationExportDistributionIndexesSet = (
	state: ACatalogState,
	action: ActionPublicationExportDistributionIndexes
) => {
	return tassign(state, {
		publicationExportDistributionIndexesMap: MapStorageUtil.mapStorageSet(
			state.publicationExportDistributionIndexesMap,
			action.publicationId.toString(),
			action.exportDistributionIndexes
		),
	});
};

const aCatalogDistributionSet = (
	state: ACatalogState,
	action: ActionACatalogDistribution
) => {
	return tassign(state, {
		aCatalogDistributionList: IdListUtil.listAppend(
			state.aCatalogDistributionList,
			[action.aCatalogDistribution]
		),
	});
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
const reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to
 */
reducerHandlers[ACatalogActions.ACATALOG_SELECTION_INDEXES_SET] =
	aCatalogPublicationSelectionIndexesSet;
reducerHandlers[ACatalogActions.ACATALOG_SELECTION_SET] =
	aCatalogPublicationSelectionSet;
reducerHandlers[ACatalogActions.ACATALOG_SELECTIONS_SET] =
	aCatalogSelectionsSet;
reducerHandlers[ACatalogActions.ACATALOG_SELECTIONS_CLEAR] =
	aCatalogSelectionsClear;
reducerHandlers[ACatalogActions.ACATALOG_SELECTION_USERS_SET] =
	aCatalogSelectionUsersSet;
reducerHandlers[ACatalogActions.ACATALOG_SELECTION_PRODUCT_REACTION_MAP_SET] =
	aCatalogSelectionProductReactionMapSet;
reducerHandlers[ACatalogActions.ACATALOG_SELECTION_PRODUCT_REACTION_MAP_CLEAR] =
	aCatalogSelectionProductReactionMapClear;

reducerHandlers[ACatalogActions.ACATALOG_SELECTION_FEEDBACK_VIEWS_MAP_SET] =
	aCatalogSelectionFeedbackViewsMapSet;
reducerHandlers[
	ACatalogActions.ACATALOG_SELECTION_PRODUCT_FEEDBACK_VIEWS_MAP_SET
] = aCatalogSelectionProductFeedbackMapSet;
reducerHandlers[
	ACatalogActions.ACATALOG_SELECTION_PRODUCT_FEEDBACK_VIEWS_MAP_CLEAR
] = aCatalogSelectionProductFeedbackViewsMapClear;

reducerHandlers[ACatalogActions.ACATALOG_SELECTION_DEFAULT_CONFIGS_SET] =
	selectionDefaultConfigsSet;
reducerHandlers[ACatalogActions.ACATALOG_IS_PUBLICATION_AVIEW_PRO_SET] =
	isPublicationAViewProSet;

reducerHandlers[
	ACatalogActions.ACATALOG_PUBLICATION_EXPORT_DISTRIBUTION_INDEXES_SET
] = publicationExportDistributionIndexesSet;
reducerHandlers[ACatalogActions.ACATALOG_DISTRIBUTION_SET] =
	aCatalogDistributionSet;
/**
 * Create a reducers based on the reducers handlers
 */
export const ACatalogReducer: Reducer<ACatalogState> = createReducer(
	ACatalogInitialState,
	reducerHandlers
);

/**
 * Check if this reducers can handel the function specified
 */
export const ACatalogReducerHasHandler = (actionType: string): boolean =>
	reducerHandlers.hasOwnProperty(actionType);
