/**
 *----------------------------------------
 * Core + Util Imports
 *----------------------------------------
 */

import { AppActions } from "actions/app.actions";
import {
	ActionClientConfigurationAHubVO,
	ActionEntityPermissionAHubVOs,
	ActionUserSessionCredentials,
} from "actions/types/ahub-accounts.action-types";
import {
	ActionRequestActionStatusVO,
	ActionWorklogSegmentAHubVO,
} from "actions/types/ahub-work.action-types";
import {
	ActionFileDownloadConfigVO,
	ActionNotificationRecordVO,
	ActionQueuedActions,
} from "actions/types/app.action-types";
import {
	ActionNumber,
	ActionNumberArray,
	ActionString,
	ActionStringArray,
	ActionToolId,
} from "actions/types/common.action-types";
/**
 *----------------------------------------
 * Action Types Imports
 *----------------------------------------
 */
import { ActionWork } from "actions/types/work.action-types";
import { ClientConfigurationAHubVO } from "app/valueObjects/ahub/accounts/client-configuration.ahub.vo";
import { DataSetLibraryViewConfigAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-config.ahub.vo";
import { FileDownloadConfigVO } from "app/valueObjects/app/file-download-config.app.vo";
import { ToolIDEnum } from "app/valueObjects/view/tool-id.view.enum";
import { tassign } from "modules/common/type-assign.util";
import { Utils } from "modules/common/utils";
import { arrayAppend, createReducer } from "reducers/reducer-util";
import { Reducer } from "redux";
import { WorklogAHubVO } from "valueObjects/ahub/work/worklog.ahub.vo";
import { NotificationRecordVO } from "valueObjects/app/notification-record.vo";
import { RequestActionStatusEnum } from "valueObjects/app/request-action-status.app.enum";
import { RequestActionStatusVO } from "valueObjects/app/request-action-status.vo";
/**
 *----------------------------------------
 * Value Objects Imports
 *----------------------------------------
 */
import { SessionAppVO } from "valueObjects/app/session.app.vo";
import {
	ActionLibraryViewConfigAHubVO,
	ActionLibraryViewConfigByDatasetIdAHubVO,
	ActionLibraryViewConfigMapStorageByClientIdAHubVO,
} from "../actions/types/library.action-types";
import { MapStorageUtil } from "../map-storage.util";
import { MapStorage } from "../map-storage.vo";

/**
 * ----------------------------------
 * App State interface
 * ----------------------------------
 */

export interface AppState {
	// The currently selected tool ID.
	currentToolId: ToolIDEnum;

	// Used to authenticate log in attempts
	loginToken: string;

	// Used to allow sharing of authenticated sessions across sites (e.g. the support wordpress site)
	sessionToken: string;

	// Populated by external apps and 'validated' once login is successfully completed
	externalAppToken: string;

	// This is the configuration for the currently selected client
	currentClientConfig: ClientConfigurationAHubVO;

	/**
	 * The URL to return too after a login.
	 */
	returnToUrl: string;

	// Session information used for authenticating requests with the ahub server.
	session: SessionAppVO;

	/**
	 * Actions which are in the queue to be executed
	 */
	actionQueue: ActionWork[];

	/**
	 * Number which represents if we are capping data retrived from the aHub this is the current limit
	 */
	dataItemLimit: number;

	/**
	 * Total amount of properties which can be held on the grid at any one time
	 *
	 * NOTE: Not convinced it should go here but its ok for now ... if we get more we should
	 * find somewhere better
	 */
	propertyCountLimit: number;

	// An array of VO representing actions that have generated server requests and thier current state.
	// At time of writing , request actions that simply ask for data and that are successful, are discarded
	// and are not added and the results can be seen by the data that they change. Requests that fail,
	// or requests that create potentially longer running workflows that need monitoring via worklogs
	// are stored here.
	requestActionStatusVOs: RequestActionStatusVO[];

	// Records of notifications that have been geenrated for the user. These may have come from
	// the worklog-monitor, toast or any other part of the system. And acts as a history which the
	// user can refere back to. We may or may not decide to limit the history ?
	notificationRecordVOs: NotificationRecordVO[];

	// Link to the last file we wanted automatically downloaded
	fileDownloadConfigs: FileDownloadConfigVO[];

	// Copy to clipboard
	clipboardCopyText: string;

	serverTime: number;

	libraryViewConfigByDatasetId: MapStorage<DataSetLibraryViewConfigAHubVO>;

	libraryViewConfigMapStorageByClientId: MapStorage<
		MapStorage<DataSetLibraryViewConfigAHubVO>
	>;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */

/**
 * This is the initial state of the session section of the app state.
 */
const initialSessionState: SessionAppVO = {
	clientId: undefined,
	loginToken: undefined,
	sessionToken: undefined,
	userId: undefined,
	userSessionCredentials: undefined,
	entityPermissions: undefined,
};

/**
 * Set up the initial state of the app store.
 */
const initialState: AppState = {
	currentToolId: ToolIDEnum.AHUB,
	actionQueue: [],
	loginToken: undefined,
	sessionToken: undefined,
	externalAppToken: undefined,
	returnToUrl: undefined,
	session: initialSessionState,
	requestActionStatusVOs: [],
	notificationRecordVOs: [],
	dataItemLimit: 20000,
	propertyCountLimit: 200,
	fileDownloadConfigs: undefined,
	clipboardCopyText: undefined,
	serverTime: undefined,
	currentClientConfig: undefined,
	libraryViewConfigByDatasetId: MapStorageUtil.mapStorageCreate(),
	libraryViewConfigMapStorageByClientId: MapStorageUtil.mapStorageCreate(),
};

/**
 * ----------------------------------
 * Reducers Handlers
 * ----------------------------------
 */

/**
 * Add the queued actions to the queued actions list
 */
const actionQueueAddActions = (
	state: AppState,
	action: ActionQueuedActions
) => {
	//Update the actions list
	return tassign(state, {
		actionQueue: arrayAppend(
			state.actionQueue,
			action.actions,
			(a, b) => a.actionId == b.actionId
		),
	});
};

/**
 * Delete the queued action to the queued actions list
 */
const actionQueueDeleteActions = (
	state: AppState,
	action: ActionNumberArray
) => {
	//Filter out the action id's that are on our delete actions id list
	return tassign(state, {
		actionQueue: state.actionQueue.filter(
			(qAction) => action.numbers.indexOf(qAction.actionId) == -1
		),
	});
};

/**
 * set entity permission session information.
 */
const entityPermissionsSet = (
	state: AppState,
	action: ActionEntityPermissionAHubVOs
) => {
	// Reset the session state.
	return tassign(state, {
		session: tassign(state.session, {
			entityPermissions: action.entityPermissions,
		}),
	});
};

/**
 * Clear the session information.
 */
const sessionClear = (state: AppState, action: ActionWork) => {
	// Reset the session state.
	return tassign(state, {
		session: tassign(state.session, initialSessionState),
	});
};

/**
 * Set the client id within the session information
 */
const sessionClientIdSet = (state: AppState, action: ActionNumber) => {
	//Set the client id within the session
	return tassign(state, {
		session: tassign(state.session, {
			clientId: (<ActionNumber>action).number,
		}),
	});
};

/**
 * Set the login token.
 */
export const loginTokenSet = (state: AppState, action: ActionString) => {
	// Set the login token.
	return tassign(state, { loginToken: action.string });
};

/**
 * Set the session token.
 */
export const sessionTokenSet = (state: AppState, action: ActionString) => {
	// Set the session token.
	return tassign(state, { sessionToken: action.string });
};

/**
 * Set the external app token.
 */
export const externalAppTokenSet = (state: AppState, action: ActionString) => {
	// Set the external app token.
	return tassign(state, { externalAppToken: action.string });
};

/**
 * The function to set the return to URL value.
 *
 * @param state             The state to change.
 * @param action            The action that includes the new value.
 */
export const returnToUrlSet = (state: AppState, action: ActionString) => {
	// Set the return to URL value.
	return tassign(state, { returnToUrl: action.string });
};

/**
 * The function to set the current tool id.
 *
 * @param state             The state to change.
 * @param action            The action that includes the new value.
 */
export const currentToolIdSet = (state: AppState, action: ActionToolId) => {
	// Set the current tool id.
	return tassign(state, { currentToolId: action.toolId });
};

/**
 * Set the client config within the session information
 */
const sessionClientConfigSet = (
	state: AppState,
	action: ActionClientConfigurationAHubVO
) => {
	//Set the client id within the session
	return tassign(state, { currentClientConfig: action.clientConfiguration });
};

/**
 * Set the user id within the session information
 */
const sessionUserIdSet = (state: AppState, action: ActionNumber) => {
	//Set the user id within the session
	return tassign(state, {
		session: tassign(state.session, { userId: (<ActionNumber>action).number }),
	});
};

/**
 * Set the user session credentials within the session information
 */
const sessionUserSessionCredentialsSet = (
	state: AppState,
	action: ActionUserSessionCredentials
) => {
	//Set the user session credentials within the session
	return tassign(state, {
		session: tassign(state.session, {
			userSessionCredentials: action.userSessionCredentials,
		}),
	});
};

/**
 * Append a request action status to the store
 */
const requestActionStatusAppend = (
	state: AppState,
	action: ActionRequestActionStatusVO
) => {
	// Now add the new request tickets to the current list of request tickets.
	return tassign(state, {
		requestActionStatusVOs: arrayAppend(
			state.requestActionStatusVOs,
			[action.requestActionStatus],
			(a, b) => a.workflowReference === b.workflowReference
		),
	});
};

/**
 * Update the request action status in the store from the worklog segment returned.
 */
const requestActionStatusUpdate = (
	state: AppState,
	action: ActionWorklogSegmentAHubVO
) => {
	// Get the worklogs  from the action.
	const worklogs: WorklogAHubVO[] = (<ActionWorklogSegmentAHubVO>action)
		.worklogSegment.workLogs;

	return tassign(state, {
		requestActionStatusVOs: state.requestActionStatusVOs
			.filter((requestActionStatus) =>
				requestActionStatus.entryLogged === undefined
					? true
					: !requestActionStatus.entryLogged
			) // take out actions already logged.
			.map((requestActionStatus: RequestActionStatusVO) => {
				// Get all of the worklogs that have the workflow reference we want.
				const reqActLog = worklogs.filter(
					(workLog: WorklogAHubVO) =>
						workLog.workflowExecutionId ===
						requestActionStatus.workflowReference
				);

				// Now look for the first work log that has failed.
				const failedWorklog = reqActLog.find((worklog) => worklog.fault);

				// Do we have one?
				if (failedWorklog) {
					// Yes, so set up the request action as failed.
					requestActionStatus.status = RequestActionStatusEnum.ERROR;
					requestActionStatus.fault = true;
					requestActionStatus.error = failedWorklog.exception;
				} else if (reqActLog.length > 0) {
					// If we get here then there are no failures. But are there any worklogs that are incomplete?
					const incompleteWorklogs = reqActLog.find(
						(worklog) => !worklog.complete
					);

					// Set the status to running if we have at least 1 incomplete worklog, otherwise completed.
					requestActionStatus.status = incompleteWorklogs
						? RequestActionStatusEnum.RUNNING
						: RequestActionStatusEnum.COMPLETED;

					// We got here, so no failed worklogs.
					requestActionStatus.fault = false;
					requestActionStatus.error = null;
				}

				// Once a action is complete and has been updated as such ( errored or otherwise ), we tag it as logged,
				// so that it can be removed the next time round. By this point any observers of teh store will have seen it.
				requestActionStatus.entryLogged =
					requestActionStatus.status !== RequestActionStatusEnum.RUNNING;

				return requestActionStatus;
			}),
	});
};

/**
 * Set the complete signal on the data
 */
const requestActionStatusUploadCompleteSignalSet = (
	state: AppState,
	action: ActionString
) => {
	//Get the request based on the workflow id
	let request: RequestActionStatusVO = state.requestActionStatusVOs.find(
		(actionState) => actionState.workflowReference == action.string
	);

	//We have no request then bail out
	if (!request || !request.upload) return state;

	//Add our object into the list
	request = Utils.clone(request);
	request.upload.uploadCompleteSignaled = true;

	//Replace the items in the list
	return tassign(state, {
		requestActionStatusVOs: arrayAppend(
			state.requestActionStatusVOs,
			[request],
			(a) => a.workflowReference == action.string
		),
	});
};

/**
 * Update the progress state for the data object specified specified
 */
const requestActionStatusUploadProgressUpdate = (
	state: AppState,
	action: ActionStringArray
) => {
	//Get the various parts of the action
	let wfid = action.strings[0];
	let objectId = action.strings[1];
	let progress = Number(action.strings[2]);

	//Get the request based on the workflow id
	let request: RequestActionStatusVO = state.requestActionStatusVOs.find(
		(actionState) => actionState.workflowReference == wfid
	);

	//We have no request then bail out
	if (!request || !request.upload || !request.upload.uploadData) return state;

	//Get the upload object
	let uploadObject = request.upload.uploadData.find(
		(upload) => upload.uploadId == objectId
	);

	//We have no request then bail out
	if (!uploadObject) return state;

	//Update the progress
	uploadObject = Utils.clone(uploadObject);
	uploadObject.progress = progress;

	//Add our object into the list
	request.upload = tassign(request.upload, {
		uploadData: arrayAppend(
			request.upload.uploadData,
			[uploadObject],
			(item) => item.uploadId == objectId
		),
	});

	//Replace the items in the list
	return tassign(state, {
		requestActionStatusVOs: arrayAppend(
			state.requestActionStatusVOs,
			[request],
			(a) => a.workflowReference == wfid
		),
	});
};

/**
 * Append a notification record to the store
 */
const notificationRecordAppend = (
	state: AppState,
	action: ActionNotificationRecordVO
) => {
	const mins15Ago: number = new Date().valueOf() - 1000 * 60 * 15;

	// Now add the new notification record.
	return tassign(state, {
		notificationRecordVOs: arrayAppend(
			state.notificationRecordVOs.filter(
				(notificationRecordVO: NotificationRecordVO) =>
					notificationRecordVO.notificationTime.valueOf() > mins15Ago
			),
			[action.notificationRecord],
			(a, b) => a.notificationId === b.notificationId
		),
	});
};

/**
 * Set the download URL
 */
const fileDownloadConfigSet = (
	state: AppState,
	action: ActionFileDownloadConfigVO
) => {
	return tassign(state, { fileDownloadConfigs: action.fileDownloadConfigs });
};

/**
 * Set the clipboard copy
 */
const clipBoardCopyTextSet = (state: AppState, action: ActionString) => {
	return tassign(state, { clipboardCopyText: action.string });
};

/**
 * Set the current server time
 */
const serverTimeSet = (state: AppState, action: ActionNumber) => {
	// Now add the new notification record.
	return tassign(state, { serverTime: action.number });
};

/**
 * Set the whole libraery view config.
 */
export const libraryViewConfigSet = (
	state: AppState,
	action: ActionLibraryViewConfigAHubVO
) => {
	return tassign(state, {
		libraryViewConfigMapStorageByClientId: action.libraryViewConfig,
	});
};

/**
 * Set the library view config by dataset id.
 */
export const libraryViewConfigByDatasetIdSet = (
	state: AppState,
	action: ActionLibraryViewConfigByDatasetIdAHubVO
) => {
	return tassign(state, {
		libraryViewConfigByDatasetId: MapStorageUtil.mapStorageSet(
			state.libraryViewConfigByDatasetId,
			action.datasetId.toString(),
			action.libraryViewConfig
		),
	});
};

/**
 * Set the library view config by dataset id.
 */
export const libraryViewConfigMapStorageByClientIdSet = (
	state: AppState,
	action: ActionLibraryViewConfigMapStorageByClientIdAHubVO
) => {
	return tassign(state, {
		libraryViewConfigMapStorageByClientId: MapStorageUtil.mapStorageSet(
			state.libraryViewConfigMapStorageByClientId,
			action.clientId.toString(),
			action.libraryViewConfigMapStorage
		),
	});
};

/**
 * Set the extract products change set list.
 */
export const libraryViewConfigClear = (state: AppState, action: ActionWork) => {
	return tassign(state, {
		libraryViewConfigByDatasetId: MapStorageUtil.mapStorageCreate(),
	});
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
const reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to
 */
reducerHandlers[AppActions.ACTIONS_QUEUED_ADD] = actionQueueAddActions;
reducerHandlers[AppActions.ACTIONS_QUEUED_REMOVE] = actionQueueDeleteActions;
reducerHandlers[AppActions.ENTITY_PERMISSIONS_SET] = entityPermissionsSet;
reducerHandlers[AppActions.SESSION_CLEAR] = sessionClear;
reducerHandlers[AppActions.SESSION_CLIENT_ID_SET] = sessionClientIdSet;
reducerHandlers[AppActions.SESSION_CLIENT_CONFIG_SET] = sessionClientConfigSet;
reducerHandlers[AppActions.SESSION_USER_ID_SET] = sessionUserIdSet;
reducerHandlers[AppActions.SESSION_USER_SESSION_CREDENTIALS_SET] =
	sessionUserSessionCredentialsSet;
reducerHandlers[AppActions.LOGIN_TOKEN_SET] = loginTokenSet;
reducerHandlers[AppActions.SESSION_TOKEN_SET] = sessionTokenSet;
reducerHandlers[AppActions.EXTERNAL_APP_TOKEN_SET] = externalAppTokenSet;
reducerHandlers[AppActions.RETURN_TO_URL_SET] = returnToUrlSet;
reducerHandlers[AppActions.CURRENT_TOOL_ID_SET] = currentToolIdSet;
reducerHandlers[AppActions.WORKFLOW_REFERENCE_ACTION_STATUS_APPEND] =
	requestActionStatusAppend;
reducerHandlers[AppActions.WORKFLOW_REFERENCE_ACTION_STATUS_UPDATE] =
	requestActionStatusUpdate;
reducerHandlers[
	AppActions.WORKFLOW_REFERENCE_ACTION_UPLOAD_COMPLETE_SIGNAL_UPDATE
] = requestActionStatusUploadCompleteSignalSet;
reducerHandlers[AppActions.WORKFLOW_REFERENCE_ACTION_UPLOAD_PROGRESS_UPDATE] =
	requestActionStatusUploadProgressUpdate;
reducerHandlers[AppActions.NOTIFICATION_RECORD_APPEND] =
	notificationRecordAppend;
reducerHandlers[AppActions.DOWNLOAD_URL_SET] = fileDownloadConfigSet;
reducerHandlers[AppActions.CLIPBOARD_TEXT_COPY_SET] = clipBoardCopyTextSet;
reducerHandlers[AppActions.SERVER_TIME_SET] = serverTimeSet;
reducerHandlers[AppActions.LIBRARY_VIEW_CONFIG_BY_DATASET_ID_SET] =
	libraryViewConfigByDatasetIdSet;
reducerHandlers[AppActions.LIBRARY_VIEW_CONFIG_MAP_STORAGE_BY_CLIENT_ID_SET] =
	libraryViewConfigMapStorageByClientIdSet;
reducerHandlers[AppActions.LIBRARY_VIEW_CONFIG_SET] = libraryViewConfigSet;
reducerHandlers[AppActions.LIBRARY_VIEW_CONFIG_CLEAR] = libraryViewConfigClear;

//Create a reducers based on the reducers handlers
export const AppReducer: Reducer<AppState> = createReducer(
	initialState,
	reducerHandlers
);
