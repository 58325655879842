/**
 * Static readonly implementations of the states of an export within the aHub
 */
export class ExportVersionStateAHubEnum {
	static readonly CREATED: string = "CREATED";
	static readonly UPDATING: string = "UPDATING";
	static readonly INTERNAL: string = "INTERNAL";
	static readonly PREVIEW: string = "PREVIEW";
	static readonly LIMITED_RELEASE: string = "LIMITED_RELEASE";
	static readonly RELEASED: string = "RELEASED";
	static readonly HIDDEN: string = "HIDDEN";
	static readonly ERROR: string = "ERROR";
	static readonly DELETING: string = "DELETING";
	static readonly DELETED: string = "DELETED";

	/**
	 * Is the enum in a busy state
	 */
	static inBusyState(state: string): boolean {
		return (
			state == ExportVersionStateAHubEnum.UPDATING ||
			state == ExportVersionStateAHubEnum.DELETING
		);
	}

	static RELEASE_STATES: ExportVersionStateAHubEnum[] = [
		ExportVersionStateAHubEnum.INTERNAL,
		ExportVersionStateAHubEnum.PREVIEW,
		ExportVersionStateAHubEnum.LIMITED_RELEASE,
		ExportVersionStateAHubEnum.RELEASED,
	];
}
