import { Component, OnInit, Input } from "@angular/core";
import { GridOptions, ColDef } from "ag-grid-community";

@Component({
	selector: "property-table-renderer",
	templateUrl: "./property-table-renderer.component.html",
	styleUrls: ["./property-table-renderer.component.css"],
})
export class PropertyTableRendererComponent implements OnInit {
	constructor() {
		// This is intentional
	}

	@Input() rawData: TableData;

	gridOptions: GridOptions = {
		rowData: [],
		columnDefs: [],
		onGridReady: () => {
			this.columnsSet();
		},
	} as GridOptions;

	columnsSet() {
		if (!this.gridOptions.api) return;
		if (!this.rawData.headerRow) return;
		// set headings
		const newHeaderRows: ColDef[] = this.rawData.headerRow.map((v, k) => {
			return { headerName: `${v}`, field: `${k}` };
		});

		// Set Rows
		const newRows = this.rawData.rows.map((v, k) => {
			return newHeaderRows.map((iv, ik) => {
				return v[ik];
			});
		});

		this.gridOptions.api.setColumnDefs(newHeaderRows);
		this.gridOptions.api.setRowData(newRows);
	}

	ngOnInit() {
		// This is intentional
	}
}

export interface TableData {
	headerRow: string[];
	rows: string[][];
}
