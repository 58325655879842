import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { BehaviorSubject, throwError } from "rxjs";
import { Utils } from "../../utils";
import { TreeNodeVO } from "../tree/tree.component";

@Component({
	selector: "app-compact-tree",
	templateUrl: "./compact-tree.component.html",
	styleUrls: ["./compact-tree.component.css"],
})
export class CompactTreeComponent implements OnInit {
	@Input() compactTreeConfig: CompactTreeConfig;

	flatTreeNodes$: BehaviorSubject<TreeNodeVO[]> = new BehaviorSubject([]);
	tree: CompactTreeNode[];

	// ids for connected drop lists
	dropTargetIds = [];
	nodeLookup: Map<string, CompactTreeNode> = new Map();
	dropActionTodo: DropInfo = null;

	treeReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(@Inject(DOCUMENT) private document: Document) {}

	ngOnInit(): void {
		this.compactTreeConfig.flatTreeNodes$
			.pipe()
			.subscribe((flatNodes) => this.flatTreeNodes$.next(flatNodes));

		this.flatTreeNodes$
			.pipe(Utils.isNotNullOrUndefinedOrEmpty())
			.subscribe((flatTreeNodes) => {
				console.log(
					"TCL: CompactTreeComponent -> constructor -> flatTreeNodes",
					flatTreeNodes
				);

				//Sort the nodes into ancestry order, this way we will be able to construct our tree in a sensible way
				flatTreeNodes.sort((a, b) =>
					a.ancestry === b.ancestry ? 0 : a.ancestry > b.ancestry ? 1 : -1
				);

				//Do we have some sorting to do for the siblings within
				flatTreeNodes = this.sortSiblingsByPriority(flatTreeNodes);

				//Find the root category
				const rootItem: TreeNodeVO = flatTreeNodes.find((listItem) =>
					this.isRootFunc(listItem)
				);

				const treeIncludingRoot = this.buildTreeFromAncestry(
					flatTreeNodes,
					rootItem
				);

				this.tree = [treeIncludingRoot];

				// Lets clear any previous this.dropTargetIds before building them again
				this.dropTargetIds = [];
				this.prepareDragDrop(this.tree);

				// The tree is now ready.
				this.treeReady$.next(true);
			});
	}

	// Lets have a function which can sort our tree siblings by priority.
	// We assume that tree siblings will have a common 'ancestry'
	sortSiblingsByPriority = (flatNodes: TreeNodeVO[]): TreeNodeVO[] => {
		// Firstly, lets group our nodes by ancestry
		const ancestryGrouped: Map<string, TreeNodeVO[]> = new Map();

		flatNodes.forEach((flatNode) => {
			if (ancestryGrouped.has(flatNode.ancestry)) {
				const ancestryGroupDatasets = ancestryGrouped.get(flatNode.ancestry);
				ancestryGroupDatasets.push(flatNode);
			} else {
				ancestryGrouped.set(flatNode.ancestry, [flatNode]);
			}
		});

		// Now we can sort each group of 'siblings' by priority
		ancestryGrouped.forEach((nodes, key) => {
			nodes.sort((a, b) =>
				a.priority === b.priority ? 0 : a.priority > b.priority ? 1 : -1
			);
		});

		return [].concat.apply([], Array.from(ancestryGrouped.values()));
	};

	//Function which will identify if a category is the root or not
	isRootFunc = (listItem: any) =>
		!listItem.ancestry || listItem.ancestry.length === 0;

	/**
	 * Create the tree structure from the source list we have been passed in.
	 *
	 * @param flatList      The source list to create the structure from.
	 * @param rootItem        The root item in the tree.
	 */
	buildTreeFromAncestry(
		flatList: TreeNodeVO[],
		rootItem: TreeNodeVO
	): CompactTreeNode {
		const rootCompactTreeNode: CompactTreeNode = {
			id: `item ${rootItem.id}`,
			listItem: rootItem,
			children: [],
		};

		//Loop through each of the nodes, filtering out the root
		flatList
			.filter((listItem) => !this.isRootFunc(listItem))
			.forEach((listItem) => {
				//sourceList items must have an ancestry and label property...lets check and error if we dont
				if (!listItem.ancestry || !listItem.label) {
					throwError(
						"Objects used to build generic list tree must have ancestry and label properties"
					);
				}

				//Split this into multiple parts
				const ancestryParts = listItem.ancestry
					.split(",")
					.filter((part) => part.length > 0);

				// Get the last ancestry part. This is the parents id.
				const parentId = `item ${parseInt(
					ancestryParts[ancestryParts.length - 1]
				)}`;

				//This is the parent node which the data will be held in
				const parentNode: CompactTreeNode = this.findParentItem(
					rootCompactTreeNode,
					parentId
				);

				// Create a new node
				const newNode: CompactTreeNode = {
					id: `item ${listItem.id}`,
					listItem,
					children: [],
				};

				//Add the node to the parents children
				parentNode.children.push(newNode);
			});

		return rootCompactTreeNode;
	}

	/**
	 * This function will find a parent category from a category tree.
	 *
	 * @param parentNode        The parent category to look through.
	 * @param parentId          The id of the parent to look for.
	 */
	private findParentItem(
		parentNode: CompactTreeNode,
		parentId: string
	): CompactTreeNode {
		// Return the parent node passed in if it's null, or the id matches.
		if (!parentNode || parentNode.id === parentId) {
			return parentNode;
		}

		//OK it's not in our list then we will look in our children
		for (const childNode of parentNode.children) {
			//Get the child node from the parent and ask recursivly for the specific parent id which we are asking about
			const foundChildNode = this.findParentItem(childNode, parentId);

			//We found the node we were looking for? splendid return it!
			if (foundChildNode) {
				return foundChildNode;
			}
		}
	}

	prepareDragDrop(nodes: CompactTreeNode[]) {
		nodes.forEach((node) => {
			this.dropTargetIds.push(node.id);
			this.nodeLookup[node.id] = node;
			this.prepareDragDrop(node.children);
		});
	}

	dragMoved(event) {
		let e = this.document.elementFromPoint(
			event.pointerPosition.x,
			event.pointerPosition.y
		);

		if (!e) {
			this.clearDragInfo();
			return;
		}
		let container = e.classList.contains("node-item")
			? e
			: e.closest(".node-item");
		if (!container) {
			this.clearDragInfo();
			return;
		}
		this.dropActionTodo = {
			targetId: container.getAttribute("data-id"),
		};
		const targetRect = container.getBoundingClientRect();
		const oneThird = targetRect.height / 3;

		if (event.pointerPosition.y - targetRect.top < oneThird) {
			// before
			this.dropActionTodo["action"] = "before";
		} else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
			// after
			this.dropActionTodo["action"] = "after";
		} else {
			// inside
			this.dropActionTodo["action"] = "inside";
		}
		this.showDragInfo();
	}

	// @debounce(100)
	drop(event) {
		console.log("TCL: CompactTreeComponent -> drop -> event", event);
		console.log("TCL: CompactTreeComponent -> drop -> this.tree", this.tree);
		if (!this.dropActionTodo) return;

		const draggedItemId = event.item.data;
		console.log(
			"TCL: CompactTreeComponent -> drop -> draggedItemId",
			draggedItemId
		);
		const previousParentNodeId = event.previousContainer.id;
		const targetParentNodeId = this.getParentNodeId(
			this.dropActionTodo.targetId,
			this.tree,
			"main"
		);
		// let targetListId: string
		//   const targetNode = this.getParentNode(draggedItemId, this.tree, this.tree[0]);
		//   targetListId = targetNode.id
		console.log(
			"TCL: CompactTreeComponent -> drop -> targetListId",
			targetParentNodeId
		);

		const draggedItem = this.nodeLookup[draggedItemId];

		const oldItemContainer =
			previousParentNodeId != "main"
				? this.nodeLookup[previousParentNodeId].children
				: this.tree;
		const newContainer =
			targetParentNodeId != "main"
				? this.nodeLookup[targetParentNodeId].children
				: this.tree;

		let i = oldItemContainer.findIndex((c) => c.id === draggedItemId);
		oldItemContainer.splice(i, 1);

		switch (this.dropActionTodo.action) {
			case "before":

			case "after":
				const targetIndex = newContainer.findIndex(
					(c) => c.id === this.dropActionTodo.targetId
				);
				if (this.dropActionTodo.action == "before") {
					newContainer.splice(targetIndex, 0, draggedItem);
				} else {
					newContainer.splice(targetIndex + 1, 0, draggedItem);
				}
				break;

			case "inside":
				this.nodeLookup[this.dropActionTodo.targetId].children.push(
					draggedItem
				);
				this.nodeLookup[this.dropActionTodo.targetId].isExpanded = true;
				break;
		}

		this.clearDragInfo(true);

		// Set priority for previous parent children
		const previousParentNode: CompactTreeNode =
			this.nodeLookup[previousParentNodeId];

		previousParentNode.children.forEach((node, index) => {
			node.listItem.priority = index + 1;
		});

		console.log(
			"TCL: CompactTreeComponent -> drop -> this.nodeLookup",
			this.nodeLookup
		);
		// Set priority for target parent children
		const targetParentNode: CompactTreeNode =
			this.nodeLookup[targetParentNodeId];

		targetParentNode.children.forEach((node, index) => {
			node.listItem.priority = index + 1;
		});
		// Set ancestry for dragged node and children (e.g. swap old parent id for new parent id)
		const updatedFlatNodes: CompactTreeNode[] = Array.from(
			this.nodeLookup.values()
		);
		console.log(
			"TCL: CompactTreeComponent -> drop -> updatedFlatNodes",
			updatedFlatNodes
		);

		const draggedFlatCompactTreeNode: CompactTreeNode = updatedFlatNodes.find(
			(flatTreeNode) => flatTreeNode.id === draggedItemId
		);

		this.updateMovedNodeAncestry(
			draggedFlatCompactTreeNode,
			previousParentNodeId,
			targetParentNodeId
		);

		console.log(
			"TCL: CompactTreeComponent -> drop -> draggedFlatCompactTreeNode",
			draggedFlatCompactTreeNode
		);

		// console.log(
		//   '\nmoving\n[' + draggedItemId + '] from list [' + parentItemId + ']',
		//   '\n[' + this.dropActionTodo.action + ']\n[' + this.dropActionTodo.targetId + '] from list [' + targetListId + ']');
	}

	updateMovedNodeAncestry(
		nodeToBeUpdated: CompactTreeNode,
		previousParentNodeId: any,
		targetParentNodeId: string
	) {
		const listItem = nodeToBeUpdated.listItem;
		const ancestryIds: string[] = listItem.ancestry.split(",");
		let updatedAncestry: string = ",";
		ancestryIds.forEach((ancestryId) => {
			if (ancestryId === previousParentNodeId) {
				updatedAncestry += targetParentNodeId + ",";
			} else {
				updatedAncestry += ancestryId + ",";
			}
		});

		listItem.ancestry = updatedAncestry;
		console.log(
			"TCL: CompactTreeComponent -> updateMovedNodeAncestry -> listItem.ancestry",
			listItem.ancestry
		);

		nodeToBeUpdated.children.forEach((childNode) =>
			this.updateMovedNodeAncestry(
				childNode,
				previousParentNodeId,
				targetParentNodeId
			)
		);
	}

	getParentNodeId(
		id: string,
		nodesToSearch: CompactTreeNode[],
		parentId: string
	): string {
		for (let node of nodesToSearch) {
			if (node.id == id) return parentId;
			let ret = this.getParentNodeId(id, node.children, node.id);
			if (ret) return ret;
		}
		return null;
	}

	getParentNode(
		idToFind: string,
		treeToSearch: CompactTreeNode[],
		parentNode: CompactTreeNode
	): CompactTreeNode {
		// if(treeToSearch.id === id) {
		//   return treeToSearch
		// }

		for (let node of treeToSearch) {
			if (node.id === idToFind) {
				return parentNode;
			}

			let ret = this.getParentNode(idToFind, node.children, node);
			if (ret) {
				return ret;
			}
		}

		return null;

		// for (let node of treeToSearch.children) {
		//   if (node.id == idToFind) {
		//     return treeToSearch;
		//   }

		//   for (let childNode of node.children) {

		//     let ret = this.getParentNode(idToFind, childNode);
		//     if (ret) {
		//       return ret;
		//     }
		//   }
		// }
		// return null;
	}

	showDragInfo() {
		this.clearDragInfo();
		if (this.dropActionTodo) {
			this.document
				.getElementById("node-" + this.dropActionTodo.targetId)
				.classList.add("drop-" + this.dropActionTodo.action);
		}
	}

	clearDragInfo(dropped = false) {
		if (dropped) {
			this.dropActionTodo = null;
		}
		this.document
			.querySelectorAll(".drop-before")
			.forEach((element) => element.classList.remove("drop-before"));
		this.document
			.querySelectorAll(".drop-after")
			.forEach((element) => element.classList.remove("drop-after"));
		this.document
			.querySelectorAll(".drop-inside")
			.forEach((element) => element.classList.remove("drop-inside"));
	}
}

export interface CompactTreeConfig {
	flatTreeNodes$: BehaviorSubject<TreeNodeVO[]>;
}

// export interface ListFlatNode {
//   id: number;
//   ancestry: string;
//   data: any; // original object
//   priority: number;
// }

export interface CompactTreeNode {
	id: string;
	listItem: TreeNodeVO;
	children: CompactTreeNode[];
	isExpanded?: boolean;
}

export interface DropInfo {
	targetId: string;
	action?: string;
}
