import {
	ProductAssetUploadManifestAHubVO,
	ProductAssetUploadManifestProductAHubVO,
	RequestTicketAHubVO,
} from "@harksolutions/ahub-web-services-types";
import {
	ServiceLibrary,
	ServiceOptions,
} from "@harksolutions/ahub-web-services";
import { AHubService } from "app/services/ahub/ahub.service";
import { UploadService } from "app/services/upload/upload.service";
import { RequestActionStatusUploadVO } from "app/valueObjects/app/request-action-status-upload.vo";
import { UploadDataAppVO } from "app/valueObjects/app/upload-data.app.vo";
import { environment } from "environments/environment";
import { from, Observable, of, Subject } from "rxjs";
import {
	catchError,
	concatMap,
	filter,
	last,
	map,
	merge,
	mergeMap,
} from "rxjs/operators";
import { ObjectStoreService } from "services/object-store/object-store.service";
import { AHubActions } from "../actions/ahub.actions";
import { AppActions } from "../actions/app.actions";
import {
	ActionNumber,
	ActionNumberArray,
	ActionNumberNumber,
	ActionNumberStringArray,
	ActionNumberStringString,
	ActionNumberStringStringArray,
	ActionString,
	ActionStringNumber,
} from "../actions/types/common.action-types";
import {
	ActionExtractProductAHubVOs,
	ActionExtractProductAndAssetsUploadAHubVOs,
	ActionResourcePackAHubVO,
} from "../actions/types/library.action-types";
import { ActionWork } from "../actions/types/work.action-types";
import { requestActionStatuses } from "../selector/app.selector";
import { StoreAccess } from "../store-access";
import { AHubBaseEpic } from "./ahub-base.epics";
import { Epic } from "./epic";

/**
 * Class for the View epic functions
 */
export class AHubLibraryEpics extends AHubBaseEpic implements Epic {
	serviceLibrary: ServiceLibrary = undefined;

	constructor(
		public readonly aHubService: AHubService,
		private readonly uploadService: UploadService,
		private readonly objectStoreService: ObjectStoreService
	) {
		super(aHubService);

		const serviceOptions = new ServiceOptions(
			environment.aHubApi.domain,
			environment.aHubApi.basePath
		);

		serviceOptions.logRequest = true;

		//Create our service library with the base parameters for our requests
		this.serviceLibrary = new ServiceLibrary(serviceOptions);
	}

	epicMethods(): any[] {
		return [
			this.resourcePackIndexesFetch,
			this.resourcePacksByIdsFetch,
			this.resourcePackAdd,
			this.resourcePackCommit,
			this.resourcePackDelete,
			this.resourcePackFilesUpload,
			this.resourcePackFilesDelete,
			this.resourcePackFolderAdd,
			this.resourcePackFolderRename,
			this.resourcePackFilesZipDownload,
			this.resourcePackFileDownload,
			//this.productClassIndexsByClientIdFetch,
			//this.productPropertiesFetch,
			//this.productPropertyAllocationIndexesFetch,
			//this.productPropertySectionIndexsFetch,
			//this.extractIndexesFetch,
			//this.extractProductsCommitObjectsAndAssets,
			//this.extractProductsCommitObjectsOnly
		];
	}

	resourcePackIndexesFetch = (action$: Observable<ActionWork>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.RESOURCE_PACK_INDEXES_FETCH),
			//this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceLibrary.resourcePackIndexes(
						this.reqOptSigned(),
						this.getClientId()
					),
					action,
					AHubActions.resourcePackIndexesSet
				)
			)
		);

	resourcePacksByIdsFetch = (action$: Observable<ActionNumberArray>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.RESOURCE_PACKS_FETCH),
			//this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceLibrary.resourcePacksByIds(
						this.reqOptSigned(),
						this.getClientId(),
						action.numbers
					),
					action,
					AHubActions.resourcePacksSet
				)
			)
		);

	resourcePackAdd = (action$: Observable<ActionResourcePackAHubVO>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.RESOURCE_PACK_ADD),
			//this.tapLogAction(),
			mergeMap((action) =>
				this.ticketToActionStatusVO(
					this.serviceLibrary.resourcePackAdd(
						this.reqOptSigned(),
						this.getClientId(),
						action.resourcePack
					),
					action
				)
			)
		);

	resourcePackCommit = (action$: Observable<ActionResourcePackAHubVO>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.RESOURCE_PACK_COMMIT),
			mergeMap((action) =>
				this.ticketToActionStatusVO(
					this.serviceLibrary.resourcePackCommit(
						this.reqOptSigned(),
						this.getClientId(),
						action.resourcePack
					),
					action
				)
			)
		);

	resourcePackDelete = (action$: Observable<ActionNumber>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.RESOURCE_PACK_DELETE),
			mergeMap((action) =>
				this.ticketToActionStatusVO(
					this.serviceLibrary.resourcePackDelete(
						this.reqOptSigned(),
						this.getClientId(),
						action.number
					),
					action
				)
			)
		);

	resourcePackFilesUpload = (
		action$: Observable<ActionNumberStringStringArray>
	) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.RESOURCE_PACK_FILES_UPLOAD),
			//this.tapLogAction(),
			mergeMap((action) => {
				//Get the path prefix
				const prefix = action.string;

				//Map the object id's into the upload items
				const uploadDataItems: UploadDataAppVO[] = action.strings.map(
					(objectStoreId) => {
						//Get the object from the store
						const object = this.objectStoreService.get(objectStoreId);

						//Start off with just the object name if nothing else we will use this
						let pathURL: string = object.name;

						//Do we have a relative path? If so it suggests we uploaded via a folder selection
						//if so we want to use the path but not include the root folder as part of the path
						if (
							object.webkitRelativePath &&
							object.webkitRelativePath.length > 0
						) {
							//Set the URL
							pathURL = object.webkitRelativePath;

							//Get the index of the first slash
							const pathSlashIndex = pathURL.indexOf("/");

							//Remove everything before the slash
							if (pathSlashIndex > -1) {
								pathURL = pathURL.substring(pathSlashIndex + 1, pathURL.length);
							}
						}

						//Add the prefix to the begining of the path
						if (prefix && prefix.trim().length > 0) {
							pathURL =
								prefix +
								(prefix.charAt(prefix.length - 1) === "/" ? "" : "/") +
								pathURL;
						}

						//Add the items to the upload list
						return {
							uploadId: objectStoreId,
							objectStoreId: objectStoreId,
							uploadPath: pathURL,
						};
					}
				);

				//Create a upload data object which we can use to upload the data
				const uploadData = {
					uploadData: uploadDataItems,
				};

				//Call the worklog files upload. With our action the service call to start the request and the data to upload
				return this.worklogFilesUpload(
					action,
					this.serviceLibrary.resourcePackFilesUpload(
						this.reqOptSigned(),
						this.getClientId(),
						action.number
					),
					uploadData
				);
			})
		);

	resourcePackFilesDelete = (action$: Observable<ActionNumberStringArray>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.RESOURCE_PACK_FILES_DELETE),
			//this.tapLogAction(),
			mergeMap((action) =>
				this.ticketToActionStatusVO(
					this.serviceLibrary.resourcePackFilesDelete(
						this.reqOptSigned(),
						this.getClientId(),
						action.number,
						action.strings
					),
					action
				)
			)
		);

	/**
	 * Adds the specified folder (path) to the resource pack
	 */
	resourcePackFolderAdd = (action$: Observable<ActionStringNumber>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.RESOURCE_PACK_FOLDER_ADD),
			//this.tapLogAction(),
			mergeMap((action) =>
				this.ticketToActionStatusVO(
					this.serviceLibrary.resourcePackFolderAdd(
						this.reqOptSigned(),
						this.getClientId(),
						action.number,
						action.string
					),
					action
				)
			)
		);

	/**
	 * Rename the specified folder (path) in the resource pack
	 */
	resourcePackFolderRename = (action$: Observable<ActionNumberStringString>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.RESOURCE_PACK_FOLDER_RENAME),
			mergeMap((action) =>
				this.ticketToActionStatusVO(
					this.serviceLibrary.resourcePackFolderRename(
						this.reqOptSigned(),
						this.getClientId(),
						action.number,
						action.string1,
						action.string2
					),
					action
				)
			)
		);

	/**
	 * Generates a zip files for a selection of files in the resource packs
	 */
	resourcePackFilesZipDownload = (action$: Observable<ActionStringNumber>) => {
		return action$.pipe(
			filter(
				({ type }) => type === AHubActions.RESOURCE_PACK_FILES_ZIP_DOWNLOAD
			),
			mergeMap((action) =>
				this.ticketToActionStatusVO(
					this.serviceLibrary.resourcePackFilesZipDownload(
						this.reqOptSigned(),
						this.getClientId(),
						action.number,
						action.string
					),
					action
				)
			)
		);
	};

	/**
	 * Download (commences on worklog complete receipt) file from the resource packs
	 */
	resourcePackFileDownload = (action$: Observable<ActionStringNumber>) => {
		return action$.pipe(
			filter(({ type }) => type === AHubActions.RESOURCE_PACK_FILE_DOWNLOAD),
			mergeMap((action) =>
				this.ticketToActionStatusVO(
					this.serviceLibrary.resourcePackFileDownload(
						this.reqOptSigned(),
						this.getClientId(),
						action.number,
						action.string
					),
					action
				)
			)
		);
	};

	productClassIndexsByClientIdFetch = (action$: Observable<ActionNumber>) => {
		return action$.pipe(
			filter(
				({ type }) =>
					type === AHubActions.PRODUCT_CLASS_INDEXS_BY_CLIENT_ID_FETCH
			),
			//this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceLibrary.productClassIndexsByClientId(
						this.reqOptSigned(),
						this.getClientId()
					),
					action,
					AHubActions.productClassIndexsSet
				)
			)
		);
	};

	productPropertiesFetch = (action$: Observable<ActionNumberArray>) => {
		return action$.pipe(
			filter(
				({ type }) => type === AHubActions.PRODUCT_PROPERTIES_BY_CLIENT_ID_FETCH
			),
			//this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceLibrary.productProperty(
						this.reqOptSigned(),
						this.getClientId(),
						action.numbers
					),
					action,
					AHubActions.productPropertySet
				)
			)
		);
	};

	productPropertyAllocationIndexesFetch = (action$: Observable<ActionWork>) => {
		return action$.pipe(
			filter(
				({ type }) =>
					type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_INDEXS_FETCH
			),
			//this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceLibrary.productPropertyAllocationIndexs(
						this.reqOptSigned(),
						this.getClientId()
					),
					action,
					AHubActions.productPropertyAllocationsSet
				)
			)
		);
	};

	productPropertySectionIndexsFetch = (action$: Observable<ActionWork>) => {
		return action$.pipe(
			filter(
				({ type }) =>
					type ===
					AHubActions.PRODUCT_PROPERTY_SECTION_INDEXS_BY_CLIENT_ID_FETCH
			),
			//this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceLibrary.productPropertySectionIndexs(
						this.reqOptSigned(),
						this.getClientId()
					),
					action,
					AHubActions.productPropertySectionIndexsSet
				)
			)
		);
	};

	extractIndexesFetch = (action$: Observable<ActionNumberNumber>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.EXTRACT_INDEXES_FETCH),
			//this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceLibrary.extractIndexes(
						this.reqOptSigned(),
						action.number1,
						action.number2
					),
					action,
					AHubActions.extractIndexesSet
				)
			)
		);

	extractProductsCommitObjectsAndAssets = (
		action$: Observable<ActionExtractProductAndAssetsUploadAHubVOs>
	) => {
		return action$.pipe(
			filter(
				({ type }) =>
					type === AHubActions.EXTRACT_PRODUCTS_COMMIT_OBJECTS_AND_ASSETS
			),
			//this.tapLogAction(),
			mergeMap((action) => {
				//Upload request object ready for use
				const uploadRequest: RequestActionStatusUploadVO = {
					uploadData: [],
				};

				//Manifest items
				const productAssetManifest: ProductAssetUploadManifestAHubVO[] = [];

				//For each of the upload items we need to do some setup
				action.extractAssetUpload.forEach((extractAssetUpload) => {
					//Add all the files into the upload data
					uploadRequest.uploadData.push(
						...extractAssetUpload.productAssetFiles
					);

					//Add a record into the manifest for this asset, along with all it's associations
					productAssetManifest.push({
						assetId: extractAssetUpload.assetId,
						assetProductAllocations: extractAssetUpload.productAllocations.map(
							(data) =>
								({
									productId: data.productId,
									allocationIds: data.allocationIds,
								} as ProductAssetUploadManifestProductAHubVO)
						),
					});
				});

				//We will then add the manifiest to the object store and get the reference back!
				const manifestObjectStoreId = this.objectStoreService.store(
					JSON.stringify(productAssetManifest)
				);

				//Add the manfiest to the end of the uploads, this should then upload the manifest last!
				uploadRequest.uploadData.push({
					uploadPath: "asset-manifest.json",
					objectStoreId: manifestObjectStoreId,
				});

				// Actual Service Calls ----------------------------------------

				//Call the service for executing the product commit workflow
				const productCommitWorkflowStart =
					this.serviceLibrary.extractProductsCommitObjectsAndAssets(
						this.reqOptSigned(),
						this.getClientId(),
						action.extractId,
						action.extractProducts,
						action.imageAssetTrimThreshold
					);

				//Call the file upload with our load of assets to upload
				return this.worklogFilesUpload(
					action,
					productCommitWorkflowStart,
					uploadRequest
				);
			})
		);
	};

	extractProductsCommitObjectsOnly = (
		action$: Observable<ActionExtractProductAHubVOs>
	) => {
		return action$.pipe(
			filter(
				({ type }) => type === AHubActions.EXTRACT_PRODUCTS_COMMIT_OBJECTS_ONLY
			),
			//this.tapLogAction(),
			mergeMap((action) =>
				this.ticketToActionStatusVO(
					this.serviceLibrary.extractProductsCommitObjectsOnly(
						this.reqOptSigned(),
						this.getClientId(),
						action.extractId,
						action.extractProducts
					),
					action
				)
			)
		);
	};

	/**
	 * Called to upload a series of files to a workflow
	 */
	worklogFilesUpload = (
		action: ActionWork,
		requestTicketPromise: Promise<RequestTicketAHubVO>,
		upload: RequestActionStatusUploadVO
	) => {
		//Here is the list of id's we are using for a file, we cannot have multiple of the same id so we will sanities it.
		//Worst this will do is unlink the upload from the view but thats the callers fault we want to prevent the errors

		//Loop through all our items making sure they have an id and a priority. We will prioritize
		//into the negitive numbers so that these items will always appear at the end of lists
		upload.uploadData.forEach((item, index) => {
			//Whilst we don't have an id, or its already on the list we will generate a new one
			while (item.uploadId === undefined) {
				item.uploadId =
					Math.random().toString(36).substring(2, 15) +
					Math.random().toString(36).substring(2, 15);
			}

			//Set the priority if we don't have one
			if (item.priority === undefined) {
				item.priority = 0 - index;
			}
		});

		//Take the request ticket stream and append our upload stream to it
		return from(requestTicketPromise).pipe(
			mergeMap((requestTicketData) => {
				//Create a status action event. We will use this to link the action to the request ticket. This will be the first thing to fire
				const newStatusAction = AppActions.sessionrequestActionStatusAppend(
					this.actionStatusUploadCreate(requestTicketData, action, upload)
				);

				//Create a new progress stream. We will use this stream to report the progress of a file
				const progressStream$ = new Subject();

				//Generate a list of the prefixes for the upload, we will loop through getting the requested path for each
				const prefixes = upload.uploadData.map(
					(uploadData) => uploadData.uploadPath
				);

				//This will be our primary stream to carry out the upload. This will take the requested data and upload the data one  at a time.
				//First things first get presigned URL's for the prefiexs which were part of the parameters
				const uploadStream$: Observable<ActionString> = this.presignedUrls(
					prefixes,
					requestTicketData.workflowReference
				).pipe(
					mergeMap((signedUploadUrls) => {
						//We will pair up our presigned URL's with there data object request to make the next stage easier
						//if we can't find it forwhatever reason the object will not be uploaded
						const uploadObjs = signedUploadUrls
							.map((signedURL) => {
								//Find the data object based on the resource path requested by the user
								const dataObject = upload.uploadData.find(
									(uploadData) => signedURL.request === uploadData.uploadPath
								);

								//No data object, can't upload sorry!
								if (!dataObject) {
									return undefined;
								}

								//Create a simple non specific object to go with the URL
								return {
									id: dataObject.uploadId,
									objectStoreId: dataObject.objectStoreId,
									presignedURL: signedURL,
									priority: dataObject.priority,
								};
							})
							.filter((d) => d !== undefined)
							.sort((a, b) =>
								a.priority === b.priority ? 0 : a.priority > b.priority ? -1 : 1
							);

						//Using concatMap we will call our objects one at a time to upload to the server.
						//we must include the reduce on the end so we will not trigger our complete untill all items are complete
						return from(uploadObjs).pipe(
							concatMap((uploadObject) => {
								//Get the data object we want to upload. We will get the item our of the store, we are also flagging the
								//item for removal from the store at this point.
								const dataObject = this.objectStoreService.get(
									uploadObject.objectStoreId
								);

								//Last reported percentage value
								let lastPercentage = 0;

								//Define a progress callback
								const progressCallback = (percentage) => {
									//Add the progress action to the progress stream. Only is the percentage is different to the last one
									if (lastPercentage !== percentage) {
										progressStream$.next(
											AppActions.requestActionStatusUploadProgressUpdate(
												requestTicketData.workflowReference,
												uploadObject.id,
												percentage
											)
										);
									}

									//Set the last percentage value
									lastPercentage = percentage;
								};

								//Call the upload service with all the data we need, including a progress listener
								return this.uploadService.upload(
									uploadObject.presignedURL.signedUrl,
									dataObject,
									progressCallback
								);
							}),
							last()
						);
					}),
					//All the uploads have finished, we want to signal the workflow that we have finished the upload
					mergeMap(() =>
						this.aHubService.workflowUploadComplete(
							requestTicketData.workflowReference
						)
					),
					map(() =>
						AppActions.requestActionStatusUploadCompleteSignalUpdate(
							requestTicketData.workflowReference
						)
					)
				);

				//OK so finally lets take our stream and merge them together, this will dispatch our variety of actions
				//out of our epic to the store sending all progress, action statuses and others as required
				return progressStream$.pipe(
					merge(uploadStream$),
					merge(of(newStatusAction))
				);
			}),
			catchError((err) => {
				//Get the action with the matching action id
				const requestActionStatus = StoreAccess.dataGet(
					requestActionStatuses
				).find((status) => status.actionId === action.actionId);

				//Return the observable message based on a previous one, or new one if we don't have one
				if (requestActionStatus) {
					//Observable for the the cancel request, if we do it
					let workflowUploadCancel: Observable<any> = undefined;

					//Action we want to submit to the store
					const actionForStore = AppActions.sessionrequestActionStatusAppend(
						this.actionStatusCreateErrorFromAction(
							action,
							err,
							requestActionStatus
						)
					);

					//If we have a workflow id for our request action status, then we will cancel the request as we will be unable to finish the upload
					//and as a result not complete the task
					if (requestActionStatus.workflowReference !== undefined) {
						workflowUploadCancel = this.aHubService.workflowUploadCancel(
							requestActionStatus.workflowReference
						);
					}

					//If we are canceling the workflow we will return that with a map to our new event. If not just the new error event
					return workflowUploadCancel
						? workflowUploadCancel.pipe(map((result) => actionForStore))
						: of(actionForStore);
				} else {
					//Return an observable of a generic error
					return of(
						AppActions.sessionrequestActionStatusAppend(
							this.actionStatusCreateError(action, err)
						)
					);
				}
			})
		);
	};
}
