import { Injectable } from "@angular/core";
import { AppActions } from "app/store/actions/app.actions";
import { aHubStatePermanentWorklogSegmentLast } from "app/store/selector/ahub/ahub-permanent.selector";
import { StoreAccess } from "app/store/store-access";
import { WorklogAHubVO } from "app/valueObjects/ahub/work/worklog.ahub.vo";
import { NotificationRecordVO } from "app/valueObjects/app/notification-record.vo";
import { RequestActionStatusEnum } from "app/valueObjects/app/request-action-status.app.enum";
import { RequestActionStatusVO } from "app/valueObjects/app/request-action-status.vo";
import { Subject, timer } from "rxjs";
import { distinctUntilChanged, filter, map, take } from "rxjs/operators";
import { RequestActionMonitorService } from "../request-action-monitor/request-action-monitor.service";
import { ObservableRateLimited } from "./rate-limited-observable";

@Injectable({
	providedIn: "root",
})
/**
 * This service is in change of watching requests & worklogs and generating notifications for these items
 * It also allows a user importing
 */
export class NotificationGeneratorService {
	/**
	 * Create the regular expression to remove all underscores.
	 */
	private readonly underScoreRegularExp: RegExp = /_/g;

	/**
	 * Stream for general notifications dispatch by external callers
	 */
	private readonly generalNotification$: Subject<NotificationRecordVO> =
		new Subject<NotificationRecordVO>();

	constructor(
		private readonly requestActionMonitorService: RequestActionMonitorService
	) {
		//Start our various observers which will be posting
		//notifications to our notification system
		this.actionNotificationObserverStart();
		this.generalNotifiactionObserverStart();
		this.worklogNotificationObserverStart();
		this.worklogInterruptionObserverStart();

		//Dispatch the first seen message
		this.startingMonitoringNotificationDispatch();
	}

	/**
	 * Dispatch a notification
	 *
	 * @param notification
	 */
	public notificationDispatch(notification: NotificationRecordVO) {
		this.generalNotification$.next(notification);
	}

	/**
	 * Starts the observation of action status for error reporting.
	 */
	private actionNotificationObserverStart() {
		//Create a stream of observable failed request actions. We will also filter out the same request being fired over and over.
		const requestActionFaultsDistinctWindow$ = this.requestActionMonitorService
			.requestActionStatusObservableFaults()
			.pipe(
				distinctUntilChanged((x, y) => {
					return (
						x.errorCode === y.errorCode &&
						x.workflowReference === y.workflowReference &&
						y.sentTime.getTime() - x.sentTime.getTime() < 5000
					);
				})
			);

		//Create a new observable rate limited stream of observable faults
		const actionRateLimitedObv$ =
			new ObservableRateLimited<RequestActionStatusVO>(
				requestActionFaultsDistinctWindow$,
				4600,
				5
			);

		//Create a new stream based on the limiting section
		actionRateLimitedObv$.observable.subscribe(
			(actionStatus: RequestActionStatusVO) =>
				this.actionStatusNotificationDispatch(actionStatus)
		);

		//Create a new stream based on the overflow, if we generate an overflow we will add a notification for it
		actionRateLimitedObv$.overflow
			.pipe(
				distinctUntilChanged(),
				filter((overflow: boolean) => {
					return overflow;
				})
			)
			.subscribe((overflow: boolean) => {
				this.actionStatusNotificationDispatch({
					actionId: 0,
					sentTime: new Date(),
					workflowReference: "",
					status: RequestActionStatusEnum.ERROR,
					fault: true,
					error: "ETO - Error Toast Overflow. Some errors may not be reported.",
					errorCode: 999,
					upload: undefined,
				});
			});
	}

	/**
	 * Starts the observation of general notifications.
	 */
	private generalNotifiactionObserverStart() {
		//Create a stream of observable notifications. We will also filter out the same request being fired over and over.
		const generalNotificationDistinctWindow$ = this.generalNotification$.pipe(
			distinctUntilChanged((x, y) => {
				return (
					x.notificationLabel === y.notificationLabel &&
					y.notificationTime.getTime() - x.notificationTime.getTime() < 6000
				);
			})
		);

		//Create a new observable rate limited stream of observable faults
		const generalNotificationLimitedObv$ =
			new ObservableRateLimited<NotificationRecordVO>(
				generalNotificationDistinctWindow$,
				6600,
				5
			);

		//Create a new stream based on the limiting section
		generalNotificationLimitedObv$.observable.subscribe(
			(generalNotification: NotificationRecordVO) =>
				this.internalNotificationDispatch(generalNotification)
		);

		//Create a new stream based on the overflow
		generalNotificationLimitedObv$.overflow
			.pipe(
				distinctUntilChanged(),
				filter((overflow: boolean) => {
					return overflow;
				})
			)
			.subscribe((overflow: boolean) => {
				this.internalNotificationDispatch({
					notificationId: `GN#${new Date().valueOf()}`,
					notificationType: "General",
					notificationTime: new Date(),
					notificationLabel: "ETO: Ignoring excessive notifications.",
					notificationIcon: "flash_on",
					notificationObject: {
						detail: "ETO: Error Toast Overflow. Too many notifications. ",
					},
				});
			});
	}

	/**
	 *  Starts the observation of the completed worklogs for toast purposes.
	 */
	private worklogNotificationObserverStart() {
		/**
		 * Creates observable of the last worklog segment to come in from the ahub with worklog changes.
		 * we'll filter out any updates that have no changes.
		 */
		StoreAccess.dataGetObvs(aHubStatePermanentWorklogSegmentLast)
			.pipe(
				filter(
					(worklogSegment) =>
						worklogSegment.workLogs !== undefined &&
						worklogSegment.workLogs !== null
				),
				filter((worklogSegment) => worklogSegment.workLogs.length > 0),
				map((worklogs) => {
					//An array of worklogs which have been grouped into workflow id's pairs
					const workLogsGrouped: WorklogAHubVO[][] = [];

					if (!worklogs.workLogs || worklogs.workLogs.length === 0) {
						return workLogsGrouped;
					}

					worklogs.workLogs.forEach((workLog) => {
						//Look through the ones we have already stored can we find one with a matching id?
						const existingList: WorklogAHubVO[] = workLogsGrouped.find(
							(workLogExistingList) =>
								workLogExistingList[0].workflowExecutionId ===
								workLog.workflowExecutionId
						);

						//If the item was already on the list then we will add it to the newly created lists
						//If it's not then we will start a new group on the list
						if (existingList) {
							existingList.push(workLog);
						} else {
							workLogsGrouped.push([workLog]);
						}
					});

					//Return the grouped list
					return workLogsGrouped;
				})
			)
			.subscribe((groupedWorklogs) => {
				groupedWorklogs.forEach((worklogGroup) => {
					this.worklogNotificationUpdate(worklogGroup);
				});
			});
	}

	/**
	 * Simple function for de-duplicating a string array.
	 *
	 * @param stringArray       The string array to de-duplicate.
	 */
	private deDupStringArray(stringArray: string[]): string[] {
		// Use a reduce to remove any duplicate strings in the array.
		return stringArray.reduce((finalArray: string[], value: string) => {
			// Look for each value in the final array. If it's not there then we can add it.
			if (!finalArray.find((currentValue) => currentValue === value)) {
				finalArray.push(value);
			}

			// Now return the final array.
			return finalArray;
		}, []);
	}

	/**
	 * Function aimed at updating at updating a worklog notifcation in the store
	 * This may be the first time it's been seen or updating existing data
	 */
	private worklogNotificationUpdate(worklogs: WorklogAHubVO[]) {
		// Get the toast reference from the current token ( if we have one .. )
		const masterWorklog: WorklogAHubVO = worklogs[0];

		// Have all of the worklogs completed?
		const worklogsCompleted = !worklogs.find(
			(worklog: WorklogAHubVO) => !worklog.complete
		);
		const worklogsAnyFailed =
			worklogs.find((worklog: WorklogAHubVO) => worklog.fault) !== undefined;
		const worklogsAnyStarted =
			worklogs.find(
				(worklog: WorklogAHubVO) => worklog.startTime !== undefined
			) !== undefined;

		const icon = worklogsAnyFailed
			? "error"
			: worklogsCompleted
			? "done"
			: "sync";

		//Get the message from the worklog if we have one set
		let label = worklogs
			.map((worklog) => worklog.message)
			.find((worklog) => worklog !== undefined);

		//If there is no message from the worklog then we will need to generate one based on the data in the worklog
		if (!label) {
			// We need to get the list of actions and types from the list worklogs.
			let actions: string[] = worklogs.map((worklog: WorklogAHubVO) =>
				worklog.workAction.toLowerCase().replace(this.underScoreRegularExp, " ")
			);
			let types: string[] = worklogs.map((worklog: WorklogAHubVO) =>
				this.worklogTypeToLabel(worklog.workType)
			);

			// Make sure both lists are de-duplicated.
			actions = this.deDupStringArray(actions);
			types = this.deDupStringArray(types);

			// Do we have either none or more than 1 action? If so, the action will just be "updating".
			const actionLabel: string =
				actions.length === 0 || actions.length > 1 ? "updating" : actions[0];

			// Now we need to get the types label. This is the types list joined with commas and spaces.
			const typesLabel: string = types.join(", ");
			label = `${
				worklogsCompleted ? "Actioned" : "Actioning"
			} ${actionLabel} of ${typesLabel}`;
		}

		// Make a record of this notification
		this.internalNotificationDispatch({
			notificationId: `workflow#${masterWorklog.workflowExecutionId}`,
			notificationType: "Workflow",
			notificationTime: masterWorklog.loggedTime, // Use the time the work was logged, not when we were notified.
			notificationLabel: label,
			notificationIcon: icon,
			notificationObject: {
				worklogs,
				workflowExecutionId: masterWorklog.workflowExecutionId,
				resultIcon: icon,
				complete: worklogsCompleted,
				failed: worklogsAnyFailed,
				started: worklogsAnyStarted,
				userId: masterWorklog.userId,
			},
		});
	}

	/**
	 * This function will convert a known work log type to a string.
	 *
	 * @param worklogType       The work log to convert.
	 */
	private worklogTypeToLabel(worklogType): string {
		// The new variable to set.
		let workLogLabel = "";

		// What work log type do we have.
		switch (worklogType) {
			// Data set to Content Set.
			case "DATASET":
				workLogLabel = "content set";
				break;

			// Dataset category to Content set Category.
			case "DATASET_CATEGORY":
				workLogLabel = "content set category";
				break;

			// Export to publication.
			case "EXPORT":
				workLogLabel = "publication";
				break;

			// Exporter to Content publisher.
			case "EXPORTER":
				workLogLabel = "content publisher";
				break;

			// Exporter build history to Content publisher
			case "EXPORTER_BUILD_HISTORY":
				workLogLabel = "content publisher history";
				break;

			// Extract definitions to Content Template.
			case "EXTRACT_DEFINITION":
				workLogLabel = "content template";
				break;

			// Extracts to contents.
			case "EXTRACT":
				workLogLabel = "content";
				break;

			// Extract contents also to contents.
			case "EXTRACT_CONTENTS":
				workLogLabel = "contents";
				break;

			// All other work log types.
			default:
				// Then remove and lower case the words.
				workLogLabel = worklogType
					.toLowerCase()
					.replace(this.underScoreRegularExp, " ");
				break;
		}

		// Return the work log label created.
		return workLogLabel;
	}

	/**
	 * Observers the worklogs, if we notice that the portal isn't reciving worklogs then we want to deal with it
	 */
	private worklogInterruptionObserverStart() {
		// We'll subscribe to the requestActionMonitorService for any overdue logs...
		this.requestActionMonitorService
			.worklogInterupted()
			.subscribe((interruptionTime) => {
				const interruptionTimeMins: number = Math.round(interruptionTime);

				this.notificationDispatch({
					notificationId: `GN#${new Date().valueOf()}`,
					notificationType: "General",
					notificationTime: new Date(),
					notificationLabel: `REBOOTING! Disconnected from cloud ${Math.round(
						interruptionTimeMins / 1000 / 60
					)} Mins.`,
					notificationIcon: "cloud_off",
					notificationObject: {
						detail:
							"aHub portal has become disconnected from cloud for too long. Reloading to attempt re-connection.",
					},
				});

				timer(15000)
					.pipe(take(1))
					.subscribe((timerVal) => window.location.reload());
			});
	}

	/**
	 * Dispatch a notification indicating that we are starting watching the worklogs
	 */
	private startingMonitoringNotificationDispatch() {
		// Notifify user that monitoring has started. ( Useful for testing notification ! )
		const generalNotification: NotificationRecordVO = {
			notificationId: `GN#${new Date().valueOf()}`,
			notificationType: "General",
			notificationTime: new Date(),
			notificationLabel: "Starting monitoring of activity.",
			notificationIcon: undefined,
			notificationObject: {
				detail:
					"Begininig monitoring of aHub cloud activity to provide live feedback.",
			},
		};

		// Send notification/ Don;t normall do a dely like this, but this notification occurs on start
		// And there is so much else going on the screen, also the whole notification system has'nt really started yet.
		timer(2000)
			.pipe(take(1))
			.subscribe((timerVal) => {
				this.notificationDispatch(generalNotification);
			});
	}

	/**
	 * Dispatch a notification based on an action status
	 */
	private actionStatusNotificationDispatch(
		actionStatus: RequestActionStatusVO
	) {
		let icon = "";
		let message = "";

		// Generate based icon and label on error code.
		// We'll record the generated status icon and lable for historic purposes.
		switch (actionStatus.errorCode) {
			case 0: // error code 0 usually erturned when teh request could not be made , internet disconnected ?
				icon = "power";
				message = "Unable to make request, check internet connection.";
				break;

			case 400:
				icon = "report";
				message =
					"The request was badly formatted, or contained malformed data.";
				break;

			case 401:
				icon = "account_box";
				message = "Authentication supplied not valid.";
				break;

			case 403:
				icon = "lock";
				message = "Insufficent permissions to make this request.";
				break;

			case 419:
				icon = "fingerprint";
				message =
					"Session has expired, or has been reset. You will need to log in.";
				break;

			case 409:
				icon = "transform";
				message =
					"Request would conflict with existing data or a request in progress.";
				break;

			case 404:
				icon = "location_searching";
				message = "Unable to make request, missing endpoint, item or resource.";
				break;

			case 422:
				icon = "help";
				message = "Request correct format but not understood by server.";
				break;

			case 423:
				icon = "block";
				message =
					"Requests was blocked becase the data is read only or in use.";
				break;

			case 429:
				icon = "hourglass_full";
				message = "Too many requests, please try again later.";
				break;

			case 501:
				icon = "alarm_add";
				message =
					"Server has yet to implement that request, are you from the future?";
				break;

			case 500:
				icon = "bug_report";
				message =
					"aHub error, please try again later. if continues, report to Admin.";
				break;

			case 502:
				icon = "cloud_off";
				message = "Network connected, but unable to reach server?";
				break;

			case 503:
				icon = "hourglass_empty";
				message =
					"Server too busy to action your request, please try again later.";
				break;

			case 600:
				icon = "vpn_key";
				message = "Corrupt token - contact admin.";
				break;

			case 601:
				icon = "broken_image";
				message = "Server was unable to parse data suppied in the request.";
				break;

			case 602:
				icon = "person_outline";
				message = "Unsigned request, who are you?";
				break;

			case 603:
				icon = "access_time";
				message = "Request has invalid timestamp.";
				break;

			default:
				icon = "flash_on";
				message = actionStatus.error;
				break;
		}

		//Dispatch a notifaction using the basis of the action status
		this.internalNotificationDispatch({
			notificationId: `Action#${
				actionStatus.actionId === undefined
					? Date.now().valueOf().toString()
					: actionStatus.actionId.toString()
			}`,
			notificationType: "Action",
			notificationTime: actionStatus.sentTime,
			notificationObject: actionStatus,
			notificationLabel: message,
			notificationIcon: icon,
		});
	}

	/**
	 * Dispatch a notifiaction imediatly
	 */
	private internalNotificationDispatch(notification: NotificationRecordVO) {
		StoreAccess.dispatch(AppActions.notificationRecordAppend(notification));
	}
}
