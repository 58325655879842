import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild,
	ViewContainerRef,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
	selector: "app-three-d-renderer",
	templateUrl: "./three-d-renderer.component.html",
	styleUrls: ["./three-d-renderer.component.css"],
})
export class ThreeDRendererComponent implements OnInit {
	private static readonly EVENT_NAME_DRAG_OVER = "DRAG_OVER_EVENT";
	private static readonly EVENT_NAME_DROPPED = "EVENT_NAME_DROPPED";
	private static readonly EVENT_NAME_DRAG_LEAVE = "DRAG_LEAVE_EVENT";
	private static readonly EVENT_NAME_MOUSE_DOWN = "EVENT_NAME_MOUSE_DOWN";
	private static readonly EVENT_NAME_MOUSE_UP = "EVENT_NAME_MOUSE_UP";

	@ViewChild("frame", { static: true })
	iFrame: ViewContainerRef;

	@Output()
	mouseup: EventEmitter<number[]> = new EventEmitter(undefined);

	@Output()
	mousedown: EventEmitter<number[]> = new EventEmitter(undefined);

	@Output()
	dragOver: EventEmitter<void> = new EventEmitter();

	@Output()
	dragLeave: EventEmitter<void> = new EventEmitter();

	@Output()
	dropped: EventEmitter<void> = new EventEmitter();

	@HostListener("window:message", ["$event"])
	onMessage(event) {
		const eventType = event.data?.split("|");
		const positionArray = eventType?.[1]?.split("x");

		switch (eventType?.[0]) {
			case ThreeDRendererComponent.EVENT_NAME_MOUSE_UP:
				this.mouseup.emit(positionArray);
				break;

			case ThreeDRendererComponent.EVENT_NAME_MOUSE_DOWN:
				this.mousedown.emit(positionArray);
				break;

			case ThreeDRendererComponent.EVENT_NAME_DRAG_OVER:
				this.dragOver.emit();
				break;

			case ThreeDRendererComponent.EVENT_NAME_DROPPED:
				this.dropped.emit();
				break;

			case ThreeDRendererComponent.EVENT_NAME_DRAG_LEAVE:
				this.dragLeave.emit();
				break;

			default:
				break;
		}
	}

	/**
	 * Content which we will use to fill the view
	 */
	iFrameContent: string;

	@Input()
	params: ThreeDRendererParams;

	constructor(private readonly sanitizer: DomSanitizer) {}

	ngOnInit() {
		//Update the frame contents
		this.updateIFrame();
	}

	/**
	 * Update the iFrame with the current parameter set
	 */
	updateIFrame() {
		//Generate the content for the iFrame
		const iFrameContent = this.generate3dIFrameContent(this.params);

		//Update the SRC document so that it have it's new content
		this.iFrameContent = iFrameContent;
	}

	/**
	 * Generate the contents of the iFrame based on the supplied data set
	 *
	 * @param params
	 */
	generate3dIFrameContent(params: ThreeDRendererParams) {
		const modelViewerControls = `camera-controls auto-rotate ${
			this.params.disableZoom ? "disable-zoom" : ""
		}`;

		let iframeHTML = "";

		iframeHTML += `<style>
        html {
          height:100%;
          width:100%
        }
        body {
          height:100%;
          width:100%;
          margin:0px;
        }
        #loader-container {
          position: absolute;
          z-index: 9;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
        }
        #loader {
          margin: auto;
          position: absolute;
          z-index: 999;
          top: 50%;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          transform: translate(-50%, -50%);
        }
        #loader span {
          font-weight: bold;
          font-size: 12px;
          display: flex;
          font-family: Arial;
          padding-top: 10px;
        }
        #loader-bg {
          background-color: rgba(255, 255, 255, 0.8);
          position: absolute;
          z-index: 9;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
        }
        #loader-bg-preview {
          position: absolute;
          top: 0px;
          height: 100%;
          z-index: 8;
          left: 0px;
          width: 100%;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
        model-viewer {
          --progress-bar-height: 0px;
        }
      </style>`;

		iframeHTML += `<script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>`;
		iframeHTML += `<script nomodule src="https://unpkg.com/@google/model-viewer/dist/model-viewer-legacy.js"></script>`;
		iframeHTML += `<script src="https://unpkg.com/focus-visible@5.1.0/dist/focus-visible.js"></script>`;
		iframeHTML += `<model-viewer id="viewer" style="width:100%;height:100%;pointer-events: all !important;"
                  src="${params.objerUrl}" ${modelViewerControls} loading="eager" autoplay>`;
		iframeHTML += `
    <script>
    window.addEventListener("mouseup",function(e){
      e = e || event;
      var eventData = e.clientX+ 'x' + e.clientY;
      e.preventDefault();
      window.parent.postMessage("${ThreeDRendererComponent.EVENT_NAME_MOUSE_UP}|" + eventData, "*");
    },false);

    window.addEventListener("mousedown",function(e){
      e = e || event;
      var eventData = e.clientX+ 'x' + e.clientY;
      e.preventDefault();
      window.parent.postMessage("${ThreeDRendererComponent.EVENT_NAME_MOUSE_DOWN}|" + eventData, "*");
    },false);

    window.addEventListener("dragover",function(e){
      e = e || event;
      e.preventDefault();
      window.parent.postMessage("${ThreeDRendererComponent.EVENT_NAME_DRAG_OVER}", "*");
    },false);
    window.addEventListener("drop",function(e){
      e = e || event;
      e.preventDefault();
      window.parent.postMessage("${ThreeDRendererComponent.EVENT_NAME_DROPPED}", "*");
    },false);

    window.addEventListener("dragleave",function(e){
      window.parent.postMessage("${ThreeDRendererComponent.EVENT_NAME_DRAG_LEAVE}", "*");
    },false);

    </script>
    <div id='loader-container' slot='poster'>
      <div id='loader-bg-preview' style="background-image: url(${params.objectPreviewUrl});"></div>
      <div id='loader-bg'></div>
      <div id='loader'>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; display: block; shape-rendering: auto;" width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#6074e2" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
        </svg>
        <span>Loading</span>
      </div>
    <div>`;
		iframeHTML += `
  </model-viewer>`;

		return iframeHTML;
	}
}

export interface ThreeDRendererParams {
	objerUrl: string;
	objectPreviewUrl: string;
	disableZoom?: boolean;
}
