import {
	map,
	takeUntil,
	filter,
	startWith,
	combineLatest,
	switchMap,
} from "rxjs/operators";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Observable, of } from "rxjs";
import { Hark, componentDestroyStream } from "modules/common/hark.decorator";

/**
 * Store access.
 */
import { StoreAccess } from "store/store-access";

/**
 * Actions.
 */
import { AHubActions } from "actions/ahub.actions";

/**
 * Value objects.
 */
import { UserIndexAHubVO } from "valueObjects/ahub/accounts/user-index.ahub.vo";
import { UserAHubVO } from "valueObjects/ahub/accounts/user.ahub.vo";
import { List } from "store/list.vo";

/**
 * Selectors.
 */
import { aHubStateTemporaryUserIndexList } from "selector/ahub/ahub-temporary.selector";
import { sessionUserId } from "selector/app.selector";

/**
 * Utilites.
 */
import { ListUtil } from "store/list.util";
import { Utils } from "../../utils";

@Component({
	selector: "app-user-spot",
	templateUrl: "./user-spot.component.html",
	styleUrls: ["./user-spot.component.scss"],
})
@Hark()
export class UserSpotComponent implements OnInit, OnDestroy {
	/**
	 * This is the user id we are working with.
	 */
	@Input()
	public userId$: Observable<number>;

	/**
	 * This is the user id we are working with.
	 */
	@Input()
	public userId: number;

	/**
	 * This is the user we are working with. We can use this instead of
	 * the user id.
	 */
	@Input()
	public user$: Observable<UserIndexAHubVO>;

	/**
	 * This is the user we are working with. We can use this instead of
	 * the user id.
	 */
	@Input()
	public user: UserIndexAHubVO;

	/**
	 * This is the size of the component
	 */
	@Input()
	public size = 28;

	/**
	 * Optional tooltip passed in
	 */
	@Input()
	public tooltip: string = undefined;

	/**
	 * This is the list of user indexes.
	 */
	private readonly userIndexes$: Observable<List<UserIndexAHubVO>> =
		StoreAccess.dataGetObvs(aHubStateTemporaryUserIndexList);

	/**
	 * This is the current user.
	 */
	private userIndex$: Observable<UserIndexAHubVO>;

	/**
	 * Are we displaying the current user id?
	 */
	isSessionUser$: Observable<boolean> = of(true);

	/**
	 * This is the users name.
	 */
	userName$: Observable<string>;

	/**
	 * Get the users initials.
	 */
	userInitials$: Observable<string>;

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		// Lets handle the various ways that this component can be used (e.g. by userId or Observable userId or user or Observable user)

		if (this.userId$) {
			// Subscribe to the user id so we can make a request to get the user index.
			this.userId$
				.pipe(
					Utils.isNotNullOrUndefined(),
					takeUntil(componentDestroyStream(this)),
					filter((userId) => userId > 0)
				)
				.subscribe((userId) => {
					StoreAccess.dispatch(AHubActions.userIndexesByIdFetch([userId]));
				});

			this.user$ = this.userId$.pipe(
				Utils.isNotNullOrUndefined(),
				switchMap((userId) =>
					StoreAccess.dataListItemGetObvs(
						aHubStateTemporaryUserIndexList,
						userId
					)
				)
			);
		}

		if (this.userId) {
			this.user$ = StoreAccess.dataListItemGetObvs(
				aHubStateTemporaryUserIndexList,
				this.userId
			);
		}

		if (this.user) {
			this.user$ = of(this.user);
		}

		// Set up the is session user obsvervable.
		this.isSessionUser$ = this.user$.pipe(
			filter((user) => user != undefined),
			takeUntil(componentDestroyStream(this)),
			map((user) => StoreAccess.dataGet(sessionUserId) == user.id)
		);

		// Get the user initials when they change.
		this.userName$ = this.user$.pipe(
			map((user) => {
				// Get the first character of the users first and surname and add them to create the initials.
				return user ? `${user.firstName} ${user.lastName}` : "";
			})
		);

		// Get the user initials when they change.
		this.userInitials$ = this.user$.pipe(
			map((user) => {
				// Get the first character of the users first and surname and add them to create the initials.
				return user ? user.firstName.charAt(0) + user.lastName.charAt(0) : null;
			})
		);
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}
}
