import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	Input,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	componentDestroyStream,
	Hark,
} from "app/modules/common/hark.decorator";
import { AHubActions } from "app/store/actions/ahub.actions";
import { ComponentActions } from "app/store/actions/component.actions";
import { ViewActions } from "app/store/actions/view.actions";
import { ListUtil } from "app/store/list.util";
import { List } from "app/store/list.vo";
import {
	aHubStateTemporaryProductClassIndexes,
	aHubStateTemporaryProductPropertyAllocationIndexes,
} from "app/store/selector/ahub/ahub-temporary.selector";
import { StoreAccess } from "app/store/store-access";
import { SectionPropertyAllocationsStream } from "app/store/stream/section-property-allocations.stream";
import { DataSetLibraryViewClassConfigAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-class-config.ahub.vo";
import {
	DataSetLibraryViewConfigAHubVO,
	EMPTY_DATASET_LIBRARY_VIEW_CONFIG,
} from "app/valueObjects/ahub/library/dataset-library-view-config.ahub.vo";
import { DataSetAHubVO } from "app/valueObjects/ahub/library/dataset.ahub.vo";
import { ProductAssetAHubVO } from "app/valueObjects/ahub/library/product-asset.ahub.vo";
import { ProductAHubVO } from "app/valueObjects/ahub/library/product.ahub.vo";
import { PropertyAllocationObjectVO } from "app/valueObjects/stream/product-allocation-object-stream.vo";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	pairwise,
	publish,
	publishReplay,
	refCount,
	shareReplay,
	startWith,
	takeUntil,
} from "rxjs/operators";
import { InfinityScrollerComponent } from "../../components/infinity-scroller/infinity-scroller.component";
import { LibraryViewUtils } from "../../library-view-utils";
import { Utils } from "../../utils";
import {
	ProductViewComponent,
	ProductViewData,
} from "../product-view/product-view.component";

@Component({
	selector: "app-category-products-view",
	templateUrl: "./category-products-view.component.html",
	styleUrls: ["./category-products-view.component.css"],
})
@Hark()
export class CategoryProductsViewComponent implements OnInit {
	/**
	 * Link to the infinty scroller component
	 */
	@ViewChild("infinityScroller")
	infinityScroller: InfinityScrollerComponent;

	@Input()
	/**
	 * Id's of the products which we want to display
	 */
	productIds$: Observable<number[]>;

	@Input()
	/**
	 * Map of the product data
	 */
	productDataList$: Observable<List<ProductAHubVO>>;

	@Input()
	/**
	 * Map of the product asset
	 */
	productAssetList$: Observable<List<ProductAssetAHubVO>>;

	/**
	 * Data set which we are using as a basis for this rule!
	 */
	@Input() dataSet$: Observable<DataSetAHubVO>;

	// Grid Size
	@Input() largeGrid$: BehaviorSubject<boolean>;

	/**
	 * Dataset product view component unique id
	 * Used to allow product clicks to be reported to the correct parent component (e.g. DatasetProductViewComponent-#####)
	 */
	@Input() datasetProductViewComponentId: string;

	@Input() productsPerRow = 6;

	// We fetch them once in content-view
	allocIndex$ = StoreAccess.dataGetObvs(
		aHubStateTemporaryProductPropertyAllocationIndexes
	).pipe(publishReplay(1), refCount());

	/**
	 * Current data list
	 */
	currentProductDataList: List<ProductAHubVO> = undefined;

	// Lets enrich the library config with inheritance (e.g. config set at the root will be inherited by
	// all child classes unless overiden in the child class)
	libraryConfigWithInheritance$: Observable<DataSetLibraryViewConfigAHubVO>;

	/**
	 * Stream for the view objects
	 */
	productDataViewObject$: Observable<ProductViewData[]> = null;

	// Component factory to be passed to the infinite list to generate components for the index.
	readonly componentFactory: ComponentFactory<ProductViewComponent> =
		this.resolver.resolveComponentFactory(ProductViewComponent);

	/**
	 * List of the product id for the current category
	 */
	productIdList: number[] = [];

	/**
	 * How many products do we want to get at any one time
	 */
	readonly productDataWindow = 25;

	/**
	 * Currently selected data set id!
	 */
	selectedDataSetId = -1;

	/**
	 * A stream which we can post to
	 */
	readonly productIdsFetchRequest$: Subject<number[]> = new Subject<number[]>();

	constructor(private readonly resolver: ComponentFactoryResolver) {}

	ngOnInit() {
		//Watch the current data set so we can get updates to it!
		this.dataSet$
			.pipe(
				filter(
					(dataSetSelectedId) =>
						dataSetSelectedId !== undefined && dataSetSelectedId.id > 0
				),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((dataSetSelectedId) => {
				//Set the currently selected data set id!
				this.selectedDataSetId = dataSetSelectedId.id;

				// Lets initialise our grid items per row
				StoreAccess.dispatch(
					ViewActions.gridViewItemsPerRowSet(this.productsPerRow)
				);
			});

		/**
		 * We want to watch the product ids selected for display and our product data list,
		 * which contains the detailed data we we look up the ids to display the data.
		 *
		 * Whilst we couuld watch them seperately, as long as the  product data data observable is
		 * registered first, but this seams a bit risky, so we'll combine.
		 */
		combineLatest([
			this.productDataList$.pipe(),
			this.productIds$.pipe(),
			this.dataSet$.pipe(Utils.isNotNullOrUndefined()),
		])
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe(([productDataList, productIds, dataset]) => {
				// We'll record the supplied product data and ids as the current properties for this component
				// as they areused by other functions.
				this.currentProductDataList = productDataList;
				this.productIdList = productIds;

				// We want to limit the product information we download in any one go to the size of the product data window, the
				// idea being we want to get the first bit of data on screen as fast as possible.
				let toFetchIdList = productIds.slice(
					0,
					Math.min(this.productDataWindow, productIds.length)
				);

				// check we hav'nt already got the product data for these ids, we only want the ones we are missing data for.
				toFetchIdList = this.productIdsToFetchFromList(
					this.currentProductDataList,
					toFetchIdList,
					true
				);

				//Do we have products to fetch , then fetch them .
				if (toFetchIdList.length > 0) {
					StoreAccess.dispatch(
						AHubActions.dataSetProductsByIdsFetch(dataset.id, toFetchIdList)
					);

					// Lets grab the asset data for these products too. It'll be useful for showing flickbooks and movie previews
					// without drilling into the product-view-full
					StoreAccess.dispatch(
						AHubActions.dataSetProductAssetsByIdsFetch(
							dataset.id,
							toFetchIdList
						)
					);
				}
			});

		this.libraryConfigWithInheritance$ = combineLatest([
			StoreAccess.dataGetObvs(aHubStateTemporaryProductClassIndexes).pipe(
				Utils.isNotNullOrUndefined(),
				distinctUntilChanged()
			),
			this.dataSet$.pipe(Utils.isNotNullOrUndefined()),
		]).pipe(
			map(([classIndex, dataset]) => {
				let libraryViewConfigWithInheritance: DataSetLibraryViewConfigAHubVO;

				if (!dataset.libraryViewConfig) {
					return libraryViewConfigWithInheritance;
				}

				// Lets make a container for our 'constructed' library view config,
				// this will include class config inherited from parent classes
				libraryViewConfigWithInheritance = Utils.clone(
					EMPTY_DATASET_LIBRARY_VIEW_CONFIG
				);

				// Give our empty config the dataset id
				libraryViewConfigWithInheritance.dataSetId = dataset.id;

				classIndex.forEach((classIndex) => {
					const libraryViewClassConfig: DataSetLibraryViewClassConfigAHubVO =
						dataset.libraryViewConfig.libraryViewClassConfigs
							? dataset.libraryViewConfig.libraryViewClassConfigs.find(
									(classConfig) => classConfig.classId === classIndex.id
							  )
							: undefined;

					// Ok, lets merge the library view class configs for the classes in the this products's class's ancestry
					const mergedLibraryViewClassConfig =
						LibraryViewUtils.buildMergedClassLibraryViewConfigWithInheritanceFromClassAncestry(
							libraryViewClassConfig,
							dataset.libraryViewConfig,
							classIndex
						);

					if (mergedLibraryViewClassConfig) {
						mergedLibraryViewClassConfig.classId = classIndex.id;
						libraryViewConfigWithInheritance.libraryViewClassConfigs.push(
							mergedLibraryViewClassConfig
						);
					}
				});

				return libraryViewConfigWithInheritance;
			}),
			shareReplay()
		);

		this.productDataViewObject$ = this.productIds$.pipe(
			debounceTime(10),
			map((productIds) => {
				if (!productIds) {
					return [];
				}

				//Create a stream for the allocation id's
				const selectedDataSetProductPropertyAllocationStreamObjects$: Observable<
					PropertyAllocationObjectVO[]
				> = combineLatest([
					SectionPropertyAllocationsStream.productAllocationObjectStreamDataGet(
						this.allocIndex$
					),
					this.dataSet$,
				]).pipe(
					takeUntil(componentDestroyStream(this)),
					map(([allocs, dataSet]) => {
						//No data then return an empty array
						if (!allocs || !dataSet || !dataSet.sections) {
							return [];
						}

						//Limit the allocations to the sections we care about as part of this data set
						return allocs.filter((alloc) =>
							dataSet.sections.find(
								(sectionId) => alloc.section.id === sectionId
							)
						);
					})
				);

				//We want to map all the product id's into a view object which we can use to render the product grid.
				//these will each represent a single product.
				return productIds.map((id) => {
					//Convert the id to a string for use in the pipes
					const idAsStr = id.toString();

					//Create an object which will allow for the view to be updated as the product data is updated!
					return {
						id,
						product$: this.productDataList$.pipe(
							map((list) => ListUtil.listDataItemGet(list, idAsStr))
						),
						productAssets$: this.productAssetList$.pipe(
							map((list) => ListUtil.listDataItemGet(list, idAsStr))
						),
						allocationStream$:
							selectedDataSetProductPropertyAllocationStreamObjects$,
						libraryConfig$: this.libraryConfigWithInheritance$,
						componentId: this.datasetProductViewComponentId,
						largeGrid$: this.largeGrid$,
						dataSetId: undefined,
						productViewClickedAction: this.productViewClickActionHandler,
					} as ProductViewData;
				});
			}),
			publish(),
			refCount()
		);

		//Listen to the pagination setup
		this.productDataPaginationFetchSetup();
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	productViewClickActionHandler(componentId, productId) {
		StoreAccess.dispatch(
			ComponentActions.componentDataSetProductsDataSetCategorySelectProductIdSet(
				componentId,
				productId
			)
		);
	}

	/**
	 * Sets up the pagination function for the product data
	 */
	productDataPaginationFetchSetup() {
		//Listen to the list of product id's which need to be obtained from the service
		this.productIdsFetchRequest$
			.pipe(
				debounceTime(350),
				takeUntil(componentDestroyStream(this)),
				startWith([]),
				pairwise()
			)
			.subscribe((productIdPairwise) => {
				//Get the two list of id's the last result and our current result
				const previousIds = productIdPairwise[0];
				const newIds = productIdPairwise[1];

				//Check if the index of the first product id is futher along than the previous first id index. Then we are scrolling down
				const isScrollingDown =
					this.productIdList.indexOf(newIds[0]) >=
					this.productIdList.indexOf(previousIds[0]);

				//Calculate half the buffer
				const halfBuffer = this.productDataWindow / 2;

				//Find the index of the product array which is half the buffer before our rendered product and half the buffer after
				const firstIdIndex = Math.max(
					0,
					this.productIdList.indexOf(newIds[0]) - halfBuffer
				);
				const lastIdIndex = Math.min(
					this.productIdList.length - 1,
					this.productIdList.indexOf(newIds[newIds.length - 1]) + halfBuffer
				);

				//We will create a window of id's we want the data/assets for!
				const productIdWindow: number[] = this.productIdList.slice(
					firstIdIndex,
					lastIdIndex + 1
				);

				//Go and get the list of id's to fetch base on the data we aleady have and the direction we are scrolling
				const productDataIdsToFetch = this.currentProductDataList
					? this.productIdsToFetchFromList(
							this.currentProductDataList,
							productIdWindow,
							isScrollingDown
					  )
					: [];

				//Do we have any product data id's to fetch
				if (this.selectedDataSetId > 0 && productDataIdsToFetch.length > 0) {
					StoreAccess.dispatch(
						AHubActions.dataSetProductsByIdsFetch(
							this.selectedDataSetId,
							productDataIdsToFetch
						)
					);

					// Lets grab the asset data for these products too. It'll be useful for showing flickbooks and movie previews
					// without drilling into the product-view-full
					StoreAccess.dispatch(
						AHubActions.dataSetProductAssetsByIdsFetch(
							this.selectedDataSetId,
							productDataIdsToFetch
						)
					);
				}
			});
	}

	/**
	 * Establishes which product id's we want when getting product data or assets.
	 * This will optimise our requests to only get data we need but also get blocks of data rather than just a few missing bits
	 */
	productIdsToFetchFromList(
		productDataList: List<any>,
		productIds: number[],
		isScrollingDown: boolean
	): number[] {
		//Duplicate the id's
		productIds = productIds.slice(0);

		//Yes then we can get a list of the id's which aren't in our list , if we have one.
		if (productDataList) {
			productIds = ListUtil.listDataFilterIdOnNotExists(
				productDataList,
				productIds
			);
		}

		//Are we going to get some data from the server?
		if (productIds.length > 0) {
			productIds = this.productIdsToFetchFromListFillWithMissing(
				productDataList,
				productIds,
				isScrollingDown
			);
		}

		//Return the product id's which we want to fetch based on what's missing
		return productIds;
	}

	/**
	 * Fill with the id's we are missing from the list up to our maximum request window
	 *
	 * @param productDataList   List of the existing product data we have
	 * @param productIds        List of the existing id's which we want to fetch which we will pad out
	 * @param isScrollingDown   Are we scrolling down?
	 */
	productIdsToFetchFromListFillWithMissing(
		productDataList: List<any>,
		productIds: number[],
		isScrollingDown: boolean
	): number[] {
		// Check we have  list to match against, if not, then we are missing everything !
		if (!productDataList) {
			return productIds;
		}

		//Get the loop index we are starting at depending on the scrolling direction
		let loopIndex = this.productIdList.indexOf(
			isScrollingDown ? productIds[productIds.length - 1] : productIds[0]
		);

		//As we are going to the server we should fill up our buffer!
		while (productIds.length < this.productDataWindow) {
			//Change the loop index depending on scrolling
			loopIndex += isScrollingDown ? 1 : -1;

			//If the index has reached then end then we will bail out!
			if (
				(isScrollingDown && loopIndex === this.productIdList.length) ||
				(!isScrollingDown && loopIndex < 0)
			) {
				break;
			}

			//If this id is not on the list then we will add it!
			if (
				!ListUtil.listDataItemExists(
					productDataList,
					this.productIdList[loopIndex]
				)
			) {
				productIds.push(this.productIdList[loopIndex]);
			}

			//If we have filled our buffer then we will break out!
			if (productIds.length >= this.productDataWindow) {
				break;
			}
		}

		//Return the product ids now padded!
		return productIds;
	}

	/**
	 * Change function handler for the infinity scroller
	 *
	 * @param event
	 */
	productInfiniteListBufferChanged(event: ProductViewData[]) {
		//Get a list of the product id's
		const renderedProductIds = event.map((a) => a.id);

		//Put the product id's into the fetch stream
		this.productIdsFetchRequest$.next(renderedProductIds);
	}

	/**
	 *
	 */
	public scrollerReset() {
		//Do we have an infinity scroller, if so call the reset function
		if (this.infinityScroller) {
			this.infinityScroller.scrollerReset();
		}
	}
}
