import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Hark } from "modules/common/hark.decorator";

/**
 * Value objects.
 */
import { ExtractDefinitionPropertyAllocationObjectVO } from "app/valueObjects/view/extract-definition-property-allocation.view.vo";

@Component({
	selector: "app-extract-allocations-item",
	templateUrl: "./extract-allocations-item.component.html",
	styleUrls: ["./extract-allocations-item.component.css"],
})
@Hark()
export class ExtractAllocationsItemComponent implements OnInit, OnDestroy {
	/**
	 * This is the extract allocation we are editing the settings on.
	 */
	@Input()
	public extractAllocationObject: ExtractDefinitionPropertyAllocationObjectVO =
		null;

	constructor() {
		// This is intentional
	}

	/**
	 * Empty On init to ensure @Hark decorator works for an AOT build
	 */
	ngOnInit() {
		// This is intentional
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}
}
