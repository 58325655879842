/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */
import { AHubState } from "reducers/ahub/ahub.reducer";

import { List } from "store/list.vo";
import { ListUtil } from "store/list.util";

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */

/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Root selector to get the aHub state from the store state
 */
export const aHubState = (state): AHubState => state.ahub;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */
