/**
 * A Tree node renderer designed to render product class information.
 */

import { Component, OnInit, Input } from "@angular/core";

import { Hark, componentDestroyStream } from "modules/common/hark.decorator";

/** Value Objects  **/
import {
	TreeConfiguration,
	TreeNodeVO,
} from "modules/common/components/tree/tree.component";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataSetCategoryAHubVO } from "app/valueObjects/ahub/library/dataset-category.ahub.vo";
import { StoreAccess } from "app/store/store-access";
import { viewLibraryPublishingSelectedDataSetCategory } from "app/store/selector/view/view-library-publishing.selector";

@Component({
	selector: "app-data-set-category-node",
	templateUrl: "./data-set-category-node.component.html",
	styleUrls: ["./data-set-category-node.component.css"],
})
@Hark()
export class DataSetCategoryNodeComponent implements OnInit {
	/**
	 * The part of tree to be rendered.
	 */
	@Input() node: TreeNodeVO;

	/**
	 * Tree configuration which discribes how teh tree should be rendered.
	 */
	@Input() treeConfig: TreeConfiguration = new TreeConfiguration();

	/**
	 * This is the actually selected data set category full value object.
	 */
	selectedDataSetCategory$: Observable<DataSetCategoryAHubVO> =
		StoreAccess.dataGetObvs(viewLibraryPublishingSelectedDataSetCategory);

	/**
	 * Can we edit?
	 */
	editable = true;

	/**
	 * Node dimensions for drawing purposes.
	 */
	nodeWidth = 160;
	nodeHeight = 100;

	nodeTopEdge = "translate(-20 -" + this.treeConfig.nodeHeight + ")";
	nodeBottomEdge = "translate(20 " + this.treeConfig.nodeHeight + ")";

	constructor() {
		// This is intentional
	}

	/**
	 * Empty On Init to ensure @Hark decorator works for an AOT build
	 */
	ngOnInit() {
		// Set the editable to whatever value is set in the static property.
		this.editable = this.treeConfig.editable;

		// Watch the editable property in the tree config.
		if (this.treeConfig.editable$)
			this.treeConfig.editable$
				.pipe(takeUntil(componentDestroyStream(this)))
				.subscribe((isEditable) => (this.editable = isEditable));
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * This function is called when the user clicks on the category and selects it.
	 */
	selectCategory() {
		if (this.treeConfig.nodeSelectFunction) {
			this.treeConfig.nodeSelectFunction(this.node);
		}
	}

	moveCategoryPriorityUp() {
		if (this.treeConfig.nodeOrderMoveUpFunction) {
			this.treeConfig.nodeOrderMoveUpFunction(
				this.treeConfig.functionScope,
				this.node
			);
		}
	}

	moveCategoryPriorityDown() {
		if (this.treeConfig.nodeOrderMoveDownFunction) {
			this.treeConfig.nodeOrderMoveDownFunction(
				this.treeConfig.functionScope,
				this.node
			);
		}
	}
}
