import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ExtractDefinitionPropertyAllocationObjectVO } from "app/valueObjects/view/extract-definition-property-allocation.view.vo";

@Component({
	selector: "app-property-chip",
	templateUrl: "./property-chip.component.html",
	styleUrls: ["./property-chip.component.scss"],
})
export class PropertyChipComponent implements OnInit {
	/**
	 * This is the extract allocation we are editing the settings on.
	 */
	@Input()
	public extractAllocationObject: ExtractDefinitionPropertyAllocationObjectVO =
		null;

	@Input()
	public enableToggleArrow = false;

	@Input()
	public enableReadOnlyIcon = true;

	@Input()
	public isToggled = false;

	// Display right hand side icons (lock and matrix)
	@Input()
	public enableIcons = true;

	/**
	 * Emit the value to be actioned
	 */
	@Output() clicked: EventEmitter<any> = new EventEmitter();

	chipExpanderActive = false;

	chipClick() {
		this.clicked.emit();
	}

	constructor() {
		// This is intentional
	}

	ngOnInit() {}
}
