import { ColDef, RowNode, ICellEditorParams } from "ag-grid-community";
import {
	GridFilterSelectParams,
	GridFilterFloatingSelectParams,
	GridFilterSelectOption,
} from "modules/common/components/ag-grid/filters/grid-filter-select-base";
import { GridFilterSelectFloatingComponent } from "modules/common/components/ag-grid/filters/grid-filter-select-floating/grid-filter-select-floating.component";
import {
	ProductPropertyMatrixEditorParams,
	ProductPropertyMatrixEditorComponent,
} from "../component/product-property-matrix-editor/product-property-matrix-editor.component";
import { ComponentFactoryResolver } from "@angular/core";
import {
	GridRendererMaterialIconParams,
	GridRendererMaterialIconComponent,
} from "modules/common/components/ag-grid/renderer/grid-renderer-material-icon/grid-renderer-material-icon.component";
import { GridRendererTextCopyClipboardComponent } from "modules/common/components/ag-grid/renderer/grid-renderer-text-copy-clipboard/grid-renderer-text-copy-clipboard.component";
import { GridFilterSelectComponent } from "modules/common/components/ag-grid/filters/grid-filter-select/grid-filter-select.component";
import { GridEditorComponentComponent } from "modules/common/components/ag-grid/editors/grid-editor-component/grid-editor-component.component";
import { ProductMatrixDefAHubVO } from "valueObjects/ahub/library/product-matrix-def.ahub.vo";
import { ProductDataMatrixDataAHubVO } from "app/valueObjects/ahub/library/product-data-matrix-data.ahub.vo";
import { ProductPropertyAHubVO } from "app/valueObjects/ahub/library/product-property.ahub.vo";
import { ProductPropertyTypeAHubVO } from "app/valueObjects/ahub/library/product-property-type.ahub.vo";

/**
 * Util class for the product property grid
 */
export class ProductPropertyGridColumnMatrixUtil {
	/**
	 * Defines the shape of the common framework components shared between the main grid and child grids
	 */
	public static frameworkComponents() {
		return {
			textClipboard: GridRendererTextCopyClipboardComponent,
			selectFilter: GridFilterSelectComponent,
			componentEditor: GridEditorComponentComponent,
			matIconRenderer: GridRendererMaterialIconComponent,
		};
	}

	/**
	 * Get a basic cell render for a matrix property
	 */
	public static columnPropertyMatrix(
		params:
			| ProductPropertyColumnWriteMatrixParameters
			| ProductPropertyColumnReadMatrixParameters
	): ColDef {
		const property = params.property;

		//Get the property description
		const propertyDesc = property.description ? property.description : "";

		//Setup some default for the column, some will be updated later
		const colDef: ColDef = {
			headerName: property.label,
			editable: true,
			width: 100,
			cellEditor: "componentEditor",
			filterParams: { newRowsAction: "keep" },
			headerTooltip: `#${property.id} ${propertyDesc}`,
			cellRenderer: "textClipboard",
		};

		let editable = false;
		let dataChanged = undefined;
		let canEditCellFunc = undefined;

		//Are these write parameters?
		if (ProductPropertyGridColumnMatrixUtil.isWriteParameters(params)) {
			const asWriteParams =
				params as ProductPropertyColumnWriteMatrixParameters;
			editable = asWriteParams.enableEdit;
			dataChanged = asWriteParams.dataChangeFunc;
			canEditCellFunc = asWriteParams.canEditCellFunc;
		}

		colDef.cellEditorParams = {
			factoryObject: params.resolver.resolveComponentFactory(
				ProductPropertyMatrixEditorComponent
			),
			property,
			propertyType: params.propertyType,
			matrixGet: params.matrixGetFunc,
			editable,
			dataChanged,
			canEditCellFunc,
		} as ProductPropertyMatrixEditorParams;

		//Setup the cell render for the matrix item
		colDef.cellRenderer = "matIconRenderer";
		colDef.cellRendererParams = {
			matIconGetFunc: (data: ProductDataMatrixDataAHubVO[]) =>
				data !== null && data !== undefined ? "grid_on" : undefined,
		} as GridRendererMaterialIconParams;

		//Setup the select filters options
		const filterOptions: GridFilterSelectOption[] = [
			{ label: "All", value: "allNoFilter", activeFilter: false },
			{
				label: "Blank",
				value: "Blank",
				activeFilter: true,
				filterFunction: (row, value) => {
					return !value || value === "";
				},
			},
			{
				label: "Not Blank",
				value: "Not Blank",
				activeFilter: true,
				filterFunction: (row, value) => {
					return value && value !== "";
				},
			},
		];

		//Setup the select filters
		colDef.filter = "selectFilter";
		colDef.filterParams = {
			newRowsAction: "keep",
			defaultSelectedFilterValue: filterOptions[0].value,
			valueOptions: filterOptions,
		} as GridFilterSelectParams;

		//Setup the floating filter componenet
		colDef.floatingFilterComponentFramework = GridFilterSelectFloatingComponent;
		colDef.floatingFilterComponentParams = {
			suppressFilterButton: true,
			defaultSelectedFilterValue: filterOptions[0].value,
			valueOptions: filterOptions,
		} as GridFilterFloatingSelectParams;

		//Return the column definition
		return colDef;
	}

	/**
	 * Are these parameters write params
	 *
	 * @param params    Parameters which we want to test
	 */
	public static isWriteParameters(
		params:
			| ProductPropertyColumnWriteMatrixParameters
			| ProductPropertyColumnReadMatrixParameters
	) {
		//Cast this as the object we want
		const asWriteParams = params as ProductPropertyColumnWriteMatrixParameters;

		//Check if it has the properties which make it write parameters
		return (
			asWriteParams.dataChangeFunc !== undefined &&
			asWriteParams.canEditCellFunc !== undefined
		);
	}
}

export interface ProductPropertyColumnReadMatrixParameters {
	property: ProductPropertyAHubVO;
	propertyType: ProductPropertyTypeAHubVO;
	resolver: ComponentFactoryResolver;
	matrixGetFunc: (rowNode: RowNode) => ProductMatrixDefAHubVO;
}

export interface ProductPropertyColumnWriteMatrixParameters
	extends ProductPropertyColumnReadMatrixParameters {
	enableEdit: boolean;
	canEditCellFunc?: (params: ICellEditorParams) => boolean;
	dataChangeFunc?: (rowData: any, value: ProductDataMatrixDataAHubVO[]) => void;
}
