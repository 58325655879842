import { map } from "rxjs/operators";
import { Component, OnInit, Input } from "@angular/core";
import { Observable, of } from "rxjs";

/**
 * Actions.
 */
import { AHubActions } from "actions/ahub.actions";

/**
 * Value objects.
 */
import { ExtractIndexAHubVO } from "valueObjects/ahub/library/extract-index.ahub.vo";
import { ExtractDefinitionAHubVO } from "valueObjects/ahub/library/extract-definition.ahub.vo";
import { ExtractStateAHubEnum } from "valueObjects/ahub/library/extract-state.ahub.enum";

/**
 * Light weight date utils.
 */
import * as moment from "moment";

/**
 * Utilites.
 */
import { ListUtil } from "store/list.util";

/**
 * Store access.
 */
import { StoreAccess } from "store/store-access";

/**
 * Selectors.
 */
import { aHubStateTemporaryExtractDefinitionList } from "selector/ahub/ahub-temporary.selector";

@Component({
	selector: "app-extract-index-list-item",
	templateUrl: "./extract-index-list-item.component.html",
	styleUrls: ["./extract-index-list-item.component.css"],
})
export class ExtractIndexListItemComponent implements OnInit {
	@Input()
	/**
	 * Index Value object passed into from the list
	 */
	public extractIndexAHubVO: ExtractIndexAHubVO = undefined;

	/**
	 * The extract's user index.
	 */
	public userId$: Observable<number> = of(-1);

	/**
	 * This is the date the extract was last changed.
	 */
	public changedDate: string;

	/**
	 * Extract state read only
	 */
	public extractStateReadOnly = false;

	/**
	 * Is the extract in the initial state?
	 */
	public extractStateInitial = false;

	/**
	 * Is the extract in the work in progress state?
	 */
	public extractStateWIP = false;

	/**
	 * Has the extract been completed?
	 */
	public extractStateComplete = false;

	/**
	 * Is the extract in the archived?
	 */
	public extractStateArchived = false;

	/**
	 * Get the current extract definition.
	 */
	extractDefinition$: Observable<ExtractDefinitionAHubVO> =
		StoreAccess.dataGetObvs(aHubStateTemporaryExtractDefinitionList).pipe(
			map((extractDefinitionList) => {
				// Stop here if we don't have the right data.
				if (
					!extractDefinitionList ||
					this.extractIndexAHubVO.extractDefinitionId === -1
				) {
					return undefined;
				}

				// Now return the extract definition.
				return ListUtil.listDataItemGet(
					extractDefinitionList,
					this.extractIndexAHubVO.extractDefinitionId
				);
			})
		);

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		//Formate the date so we have a view friend version of it
		this.changedDate = moment(this.extractIndexAHubVO.changed).format(
			"D MMM YYYY"
		);

		// Set the user id observable.
		this.userId$ = of(this.extractIndexAHubVO.userId);

		// Make a request for the extract defintion too.
		if (this.extractIndexAHubVO.extractDefinitionId > -1) {
			StoreAccess.dispatch(
				AHubActions.extractDefinitionsByIdFetch([
					this.extractIndexAHubVO.extractDefinitionId,
				])
			);
		}

		// Set up the extract state properties.
		this.extractStateReadOnly =
			this.extractIndexAHubVO.state === ExtractStateAHubEnum.READ_ONLY;
		this.extractStateInitial =
			this.extractIndexAHubVO.state === ExtractStateAHubEnum.INITIAL;
		this.extractStateWIP =
			this.extractIndexAHubVO.state === ExtractStateAHubEnum.WIP;
		this.extractStateComplete =
			this.extractIndexAHubVO.state === ExtractStateAHubEnum.COMPLETE;
		this.extractStateArchived =
			this.extractIndexAHubVO.state === ExtractStateAHubEnum.ARCHIVED;
	}
}
