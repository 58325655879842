/**
 * Actions
 */
import { AppActions } from "actions/app.actions";
import { ActionRequestActionStatusVO } from "actions/types/ahub-work.action-types";
/**
 * Action Types
 */
import { ActionNumber } from "actions/types/common.action-types";
import { Utils } from "modules/common/utils";
import { from, Observable } from "rxjs";
import { catchError, last, map, reduce, tap } from "rxjs/operators";
import { ActionWork } from "store/actions/types/work.action-types";
/**
 * Value objects
 */
import { RequestTicketAHubVO } from "valueObjects/ahub/work/request-ticket.ahub.vo";
import { RequestActionStatusUploadVO } from "valueObjects/app/request-action-status-upload.vo";
import { RequestActionStatusEnum } from "valueObjects/app/request-action-status.app.enum";
import { RequestActionStatusVO } from "valueObjects/app/request-action-status.vo";

export class EpicsBase {
	/*************
	 * PRIVATE
	 ****************/

	/**
	 * USE: CALLS THAT RETURN DATA TO BE STORED.
	 * Takes the resulting data from a service call and using the provided method for
	 * next action generation , generates the next action. Includes service call error
	 * catching which if triggered generates and dispatches a Error Action instead.
	 */
	public requestDataToAction(
		fetchResult: Observable<any>,
		action: ActionWork,
		nextAction: any
	): Observable<ActionWork> {
		return fetchResult // Get the result from service call to fetch data.
			.pipe(
				reduce((a, v) => a.concat(v), []), // Merge the results.
				last(), // everything in
				// turn the results into an actin using the action generation method passed in.
				map((results) => {
					return nextAction(results);
				}),
				// Any errors generate a new action which logs the action in the store as an error.
				// Normally these fetch actions don't get logged, but we'll log teh ones that go wrong
				// so we can report on them.
				catchError(
					(
						error: any,
						caught: Observable<ActionWork>
					): Observable<ActionWork> => {
						console.log("to err is divine ", error);
						return from([
							AppActions.sessionrequestActionStatusAppend(
								this.requestActionStatusCreateError(action, error)
							),
						]);
					}
				)
			);
	}

	// /**
	//  * USE: CALLS THAT RETURN DATA TO BE STORED.
	//  * Takes the resulting data from a service call and using the provided method for
	//  * next action generation , generates the next action. Includes service call error
	//  * catching which if triggered generates and dispatches a Error Action instead.
	//  */
	// public requestDataToAction(fetchResult: Observable<any>, action: ActionWork, nextAction: Function): Observable<ActionWork> {
	//     return fetchResult // Get the result from service call to fetch data.
	//         .reduce((a, v) => a.concat(v), []) // Merge the results.
	//         .last() // everything in
	//         // turn the results into an actin using the action generation method passed in.
	//         .map((results: any): ActionWork => { return nextAction(results); })
	//         // Any errors generate a new action which logs the action in the store as an error.
	//         // Normally these fetch actions don't get logged, but we'll log teh ones that go wrong
	//         // so we can report on them.
	//         .catch((error: any, caught: Observable<ActionWork>): Observable<ActionWork> => {
	//             return from([
	//                 AppActions.sessionrequestActionStatusAppend(this.requestActionStatusCreateError(action, error))
	//             ])
	//         });
	// }

	/**
	 * USE: CALLS THAT RETURN DATA TO BE STORED INCREMENTALLY, SUCH AS PAGINATED DATA
	 * Takes the resulting data from a service call and using the provided method for
	 * next action generation , generates the next action. Includes service call error
	 * catching which if triggered generates and dispatches a Error Action instead.
	 */
	public requestIncrementalDataToAction(
		fetchResult: Observable<any>,
		action: ActionWork,
		nextAction: Function
	): Observable<ActionWork> {
		return fetchResult.pipe(
			// Get the result from service call to fetch data.
			// turn the results into an actin using the action generation method passed in.
			map((results: any): ActionWork => {
				return nextAction(results);
			}),
			// Any errors generate a new action which logs the action in the store as an error.
			// Normally these fetch actions don't get logged, but we'll log the ones that go wrong
			// so we can report on them.
			catchError(
				(
					error: any,
					caught: Observable<ActionWork>
				): Observable<ActionWork> => {
					return from([
						AppActions.sessionrequestActionStatusAppend(
							this.requestActionStatusCreateError(action, error)
						),
					]);
				}
			)
		);
	}

	/**
	 * USE: CALLS THAT RETURN SINGLE DATA TO BE STORED.
	 * Takes the resulting single data from a service call and using the provided method for
	 * next action generation , generates the next action. Includes service call error
	 * catching which if triggered generates and dispatches a Error Action instead.
	 */
	public requestSingleDataToAction(
		fetchResult: Observable<any>,
		action: ActionWork,
		nextAction: Function,
		faultFunction?: Function,
		faultParams?: any[]
	): Observable<ActionWork> {
		return fetchResult.pipe(
			// Get the result from service call to fetch data.
			last(), // everything in
			// turn the results into an actin using the action generation method passed in.
			map((results: any): ActionWork => {
				return nextAction(results);
			}),
			// Any errors generate a new action which logs the action in the store as an error.
			// Normally these fetch actions don't get logged, but we'll log teh ones that go wrong
			// so we can report on them.
			catchError(
				(
					error: any,
					caught: Observable<ActionWork>
				): Observable<ActionWork> => {
					// Do we have a fault function and parameters? If so, call it
					if (faultFunction && faultParams) {
						return from([faultFunction(...faultParams)]);
					} else if (faultFunction) {
						return from([faultFunction()]);
					}

					// If we get here though we need to create an error.
					return from([
						AppActions.sessionrequestActionStatusAppend(
							this.requestActionStatusCreateError(action, error)
						),
					]);
				}
			)
		);
	}

	/**
	 * USE: CALLS THAT RETURN DATA TO STORE AGAINST SINGLE NUMERIC INDEX SUPPLIED IN ACTION.
	 * Takes the resulting data from a number index (Request for detail data by ID) service call
	 * and using the provided method for next action generation ( two params are expected id and data ),
	 * generates the next action. Includes service call error
	 * catching which if triggered generates and dispatches a Error Action instead.
	 */
	public requestIndexDataToAction(
		fetchResult: Observable<any>,
		action: ActionNumber,
		nextAction: Function
	): Observable<ActionWork> {
		return fetchResult.pipe(
			// Get the result from service call to fetch data.
			reduce((a, v) => a.concat(v), []), // Merge the results.
			last(), // everything in
			// We will create an object containing the data combined with the id in the action.
			map((dataItems) => ({ indexId: action.number, dataItems: dataItems })),
			// turn the results into an actin using the action generation method passed in.
			map((results: any): ActionWork => {
				return nextAction(results.indexId, results.dataItems);
			}),
			// Any errors generate a new action which logs the action in the store as an error.
			// Normally these fetch actions don't get logged, but we'll log teh ones that go wrong
			// so we can report on them.
			catchError(
				(
					error: any,
					caught: Observable<ActionWork>
				): Observable<ActionWork> => {
					return from([
						AppActions.sessionrequestActionStatusAppend(
							this.requestActionStatusCreateError(action, error)
						),
					]);
				}
			)
		);
	}

	/**
	 * USE: CALLS THAT RETURN SINGLE ITEM TO STORE AGAINST SINGLE NUMERIC INDEX SUPPLIED IN ACTION.
	 * Takes the resulting data from a number index (Request for detail data by ID) service call
	 * and using the provided method for next action generation ( two params are expected id and data ),
	 * generates the next action. Includes service call error
	 * catching which if triggered generates and dispatches a Error Action instead.
	 */
	public requestIndexSingleDataToAction(
		fetchResult: Observable<any>,
		action: ActionNumber,
		nextAction: Function
	): Observable<ActionWork> {
		return fetchResult.pipe(
			// Get the result from service call to fetch data.
			last(), // everything in
			// We will create an object containing the data combined with the id in the action.
			map((dataItems) => ({ indexId: action.number, dataItems: dataItems })),
			// turn the results into an actin using the action generation method passed in.
			map((results: any): ActionWork => {
				return nextAction(results.indexId, results.dataItems);
			}),
			// Any errors generate a new action which logs the action in the store as an error.
			// Normally these fetch actions don't get logged, but we'll log teh ones that go wrong
			// so we can report on them.
			catchError(
				(
					error: any,
					caught: Observable<ActionWork>
				): Observable<ActionWork> => {
					return from([
						AppActions.sessionrequestActionStatusAppend(
							this.requestActionStatusCreateError(action, error)
						),
					]);
				}
			)
		);
	}

	/**
	 * USE: CALLS THAT RETURN REQUEST TICKETS.
	 * Takes a request VO generated from a service call to the aHub
	 * and converts to a Action status VO
	 * wraps it into an action ready for dispatch to have the orginating action
	 * recorded in the store for subsequent status updates by the workflows.
	 */
	public requestTicketToActionStatusVO(
		request: Observable<RequestTicketAHubVO>,
		action: ActionWork
	): Observable<ActionRequestActionStatusVO> {
		return request.pipe(
			// Take the request
			last(), // Once filly returned.
			map(
				(requestTicket: RequestTicketAHubVO): RequestActionStatusVO =>
					this.requestActionStatusUploadCreate(requestTicket, action, undefined)
			), // Convert to a Action Status VO - OK.
			catchError(
				// Catch any errors, and generate a Action Status with ERROR.
				(error: any, caught: Observable<RequestActionStatusVO>) => {
					return from([this.requestActionStatusCreateError(action, error)]);
				}
			),
			map((requestActionStatusVO) =>
				AppActions.sessionrequestActionStatusAppend(requestActionStatusVO)
			)
		);
	}

	/**
	 * Create a new request action status value object.
	 */
	public requestActionStatusCreate(
		requestTicket: RequestTicketAHubVO,
		action: ActionWork
	): RequestActionStatusVO {
		return this.requestActionStatusUploadCreate(
			requestTicket,
			action,
			undefined
		);
	}

	/**
	 * Create a new request action status value object.
	 */
	public requestActionStatusUploadCreate(
		requestTicket: RequestTicketAHubVO,
		action: ActionWork,
		upload: RequestActionStatusUploadVO
	): RequestActionStatusVO {
		return {
			actionId: action.actionId,
			sentTime: new Date(),
			workflowReference: requestTicket.workflowReference,
			status: RequestActionStatusEnum.WAITING,
			fault: false,
			upload: upload,
		};
	}

	/**
	 * Create a new ERROR request action status value object.
	 */
	public requestActionStatusCreateError(
		action: ActionWork,
		error: Object
	): RequestActionStatusVO {
		return {
			actionId: action.actionId,
			sentTime: new Date(),
			workflowReference: undefined,
			status: RequestActionStatusEnum.ERROR,
			fault: true,
			error: error["error"],
			errorCode: error.hasOwnProperty("status") ? error["status"] : 0,
			upload: undefined,
		};
	}

	/**
	 * Create a new ERROR request action status value object.
	 */
	public requestActionStatusCreateErrorFromRequestAction(
		action: ActionWork,
		error: Object,
		requestActionStatus: RequestActionStatusVO
	): RequestActionStatusVO {
		//Clone the object
		requestActionStatus = Utils.clone(requestActionStatus);

		//Set the properties
		requestActionStatus.status = RequestActionStatusEnum.ERROR;
		requestActionStatus.fault = true;
		requestActionStatus.error = error["error"];
		requestActionStatus.errorCode = error.hasOwnProperty("status")
			? error["status"]
			: 0;

		//Return the action status
		return requestActionStatus;
	}

	/**
	 * Creates a generic ERROR ACTION with action status value object with a error message and code.
	 * Normally you would use requestActionStatusCreateError to generate from the action.
	 * However this can be useful in the odd occasion you just need to report an error from an epic method.
	 * This returns the action to dispatch.
	 *
	 **/
	public actionStatusGenericError(
		errorMsg: string
	): Observable<ActionRequestActionStatusVO> {
		return from([
			AppActions.sessionrequestActionStatusAppend({
				actionId: 0,
				sentTime: new Date(),
				workflowReference: undefined,
				status: RequestActionStatusEnum.ERROR,
				fault: true,
				error: errorMsg,
				errorCode: 999,
				upload: undefined,
			}),
		]);
	}
}
