import { Component, OnInit } from "@angular/core";
import { FormGroup, UntypedFormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-contact-support-dialog",
	templateUrl: "./contact-support-dialog.component.html",
	styleUrls: ["./contact-support-dialog.component.css"],
})
export class ContactSupportDialogComponent implements OnInit {
	/**** REQUIRED FOR USE IN A DIALOGUE  ****/
	/**
	 * The VO that is passed in / out.
	 */
	public dialogVO: any = null;

	mailtoLink = "mailto:support@ahub.cloud?subject=aHub Login Support Request";

	constructor(
		private formBuilder: UntypedFormBuilder,
		public dialogRef: MatDialogRef<ContactSupportDialogComponent>
	) {}

	ngOnInit() {}

	sendSupportRequestMessage() {
		this.dialogRef.close(true);
	}
}
