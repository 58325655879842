import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "app-reaction-icon",
	templateUrl: "./reaction-icon.component.html",
	styleUrls: ["./reaction-icon.component.scss"],
})
export class ReactionIconComponent implements OnInit {
	@Input() colour = "#6074e2";
	@Input() label = "Reaction";
	@Input() title?;
	@Input() reactionCount = 0;
	@Input() icon = "bi bi-hand-thumbs-up-fill";
	@Input() active = false;
	@Input() disabled = false;
	@Input() size = 15;
	@Input() fontSize = "";
	@Input() interactive = false;
	@Input() hollow = false;
	iconSize: number;

	@Output() reactionClickHandler: EventEmitter<string> =
		new EventEmitter<string>();

	constructor() {
		// This is intentional
	}

	ngOnInit(): void {
		this.iconSize = (54 * this.size) / 100;
	}

	reactionClick(label) {
		if (!this.disabled) {
			this.reactionClickHandler.emit(label);
		}
	}
}
