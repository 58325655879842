import { take, takeUntil } from "rxjs/operators";
import {
	Directive,
	Input,
	OnDestroy,
	OnChanges,
	SimpleChanges,
	SimpleChange,
	ElementRef,
	OnInit,
} from "@angular/core";

import { Observable, Subject, Subscription, timer } from "rxjs";

import { Hark, componentDestroyStream } from "modules/common/hark.decorator";

@Directive({ selector: "[scrollToId]" })
@Hark()
export class ScrollToIdDirective implements OnInit, OnDestroy, OnChanges {
	@Input()
	/**
	 * Link to the Id we want to scroll to
	 */
	public scrollToId: any = undefined;

	/**
	 * Trigger to cancel subscription.
	 */
	private endScrollIdcheck: Subject<void> = new Subject<void>();

	constructor(private element: ElementRef) {}

	/**
	 * Empty On init to ensure @Hark decorator works for an AOT build
	 */
	ngOnInit() {
		// This is intentional
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * Handel the ngOnChange events
	 */
	ngOnChanges(changes: SimpleChanges) {
		//Get the scroll id change
		let scrollIdChange: SimpleChange = changes["scrollToId"];

		//If we don't have a change property then we will do nothing
		if (!scrollIdChange) return;

		//  We seem to get loads of change notifications with undefined sometimes.. no need to action these !
		if (scrollIdChange.currentValue == undefined) return;

		//Get a self variable which means we can us this within the timeout
		let self = this;

		//Unsubscribe from previous scroll to attempts
		this.endScrollIdcheck.next();

		//We want to scroll to the item. We may want to try this multiple times
		//as sometimes some rendering is required before we can select the item.

		// Originally this was set to check every 100ms, but with multiple list on the screen
		// and a 0ms initial trigger caused initial slow screen display.
		// Have set a delay start with a random ms variant, with a subsequent less frequent check second checks.
		// thus when multiple lists on screen, initial checks are staggared and with a pause
		// so more likely to find on first attempt.. and scrolling to position slightly after page load is acceptable.

		timer(500 + Math.random() * 250, 250)
			.pipe(
				takeUntil(componentDestroyStream(this)),
				takeUntil(this.endScrollIdcheck.asObservable()),
				take(5)
			)
			.subscribe(() => {
				//Scroll to the element id
				let result = self.scrollToElementId(scrollIdChange.currentValue);

				//Unsubscribe from the subscription
				if (result) this.endScrollIdcheck.next();
			});
	}

	/**
	 * Scroll to element id supplied we will return true if the item was found and scrolled to
	 */
	private scrollToElementId(elementId: string): boolean {
		//Are we missing any part of this element if so we will not be able to scroll
		if (
			!this.element ||
			!this.element.nativeElement ||
			!this.element.nativeElement.children
		)
			return false;

		//Get the list of the children elements
		let children = this.element.nativeElement.children;

		//Calculated height of all the items untill the item which we want to display
		let calculatedHeight: number = 0;

		//Loop through each of the children indexs, this will allow us to upd
		for (let childIndex in children) {
			//Get the child from the children list
			let child = children[childIndex];

			//Get the if of the child item
			let childId = child.id;

			//Did we find our selected item? Sweet
			if (childId && childId == elementId) {
				//Calculate the top and the bottom of the sroller
				let scrollTop = this.element.nativeElement.scrollTop;
				let scrollBottom = scrollTop + this.element.nativeElement.offsetHeight;
				let scrollHeight = scrollBottom - scrollTop;

				//Calculate the top bottom and height of this element
				let elementTop = calculatedHeight;
				let elementBottom = elementTop + child.offsetHeight;
				let elementHeight = elementBottom - elementTop;

				//OK so we will first consider the top of the element, if the top of the element onscreen?
				if (elementTop < scrollTop || elementTop > scrollBottom) {
					//No ok so lets then reset the top position to the top of the element
					this.element.nativeElement.scrollTop = elementTop;
				}
				//Right so it looks like the element is onscreen. Is the bottom of the element offscreen
				else if (elementBottom > scrollBottom) {
					//Have we got enough room to fit the whole component on the page
					if (elementHeight < scrollHeight) {
						this.element.nativeElement.scrollTop = elementBottom;
					} else {
						//Set the element top
						this.element.nativeElement.scrollTop = elementTop;
					}
				}

				//We found our item and either scrolled or not so bail out
				return true;
			} else {
				//OK so this wasn't the item so add the heigh of the element to the calculated height
				//this will move it down the page.
				calculatedHeight += child.offsetHeight;
			}
		}

		//No items were found so return false
		return false;
	}
}
