import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChildren,
} from "@angular/core";
// Form imports for search.
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
} from "@angular/forms";
//Services
import { DialogService } from "modules/common/dialogs/dialog.service";
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
/**
 * Utilies
 */
import { SearchUtils } from "modules/common/search-utils";
import { Observable, of } from "rxjs";
import {
	combineLatest,
	debounceTime,
	map,
	startWith,
	takeUntil,
} from "rxjs/operators";
import { EntityPermissions } from "valueObjects/ahub/accounts/entity-permissions.ahub";

@Component({
	selector: "app-form-list",
	templateUrl: "./form-list.component.html",
	styleUrls: ["./form-list.component.css"],
})
@Hark()
export class FormListComponent implements OnInit, OnDestroy {
	/**
	 * Title to be displayed
	 */
	@Input() titleText: string;

	/**
	 * Title to be displayed
	 */
	@Input() enableAddButtons: boolean;

	/**
	 * Form group which contains the list of items we wish to diaply
	 */
	@Input() listControlForm: AbstractControl;

	/**
	 * Add Prompt
	 */
	@Input() itemAddPrompt: string;

	/**
	 * Regexp pattern used to validate added items
	 */
	@Input() pattern: string;

	/**
	 * Regexp pattern used to validate added items
	 */
	@Input() patternFailMessage: string;

	/**
	 * Are we displaying the extra menu button?
	 */
	@Input() showExtraMenu: boolean = false;

	/**
	 * Used to control whether current user should be able to add or delete from this list. Defaults to ACCOUNTS USER if none set
	 */
	@Input() accessControlLevel = EntityPermissions.ACCOUNTS_USER;

	@Input() appendNewItems: boolean = false;

	// The search box.
	@ViewChildren("searchFormInput") searchFormInput;

	/**
	 * This is the list of items to display. The display will run off this observable list.
	 */
	listItems$: Observable<string[]>;

	/**
	 * This is the list item count.
	 */
	listItemsCount$: Observable<number>;

	/**
	 * This is the list of filtered items.
	 */
	filteredListItems$: Observable<string[]>;

	/**
	 * This is the count of the filtered items list.
	 */
	filteredListItemsCount$: Observable<number>;

	/**
	 * Form to control how the list is dispayed on screen
	 */
	searchControlForm: UntypedFormGroup = this.formBuilder.group({
		showSearchFormControl: [false],
		searchFormControl: [""],
	});

	/**
	 * Create a stream which represents the stream of form value changes
	 */
	searchControlFormValues$: Observable<any> =
		this.searchControlForm.valueChanges.pipe(debounceTime(200));

	constructor(
		private formBuilder: UntypedFormBuilder,
		private dialogService: DialogService
	) {}

	ngOnInit() {
		// Call the update display incase we don't get a value changes event below.
		this.updateDisplay(this.listControlForm.value);

		/**
		 * Create a subscription to the sata which is being set in the stream
		 * this will allow use to affect the view when new data is set
		 */
		this.listControlForm.valueChanges
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((dataList) => {
				this.updateDisplay(dataList);
			});
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * Update the display with the data list passed in.
	 *
	 * @param dataList      The data list to display.
	 */
	private updateDisplay(dataList) {
		// Ignore empty lists.
		if (!dataList) dataList = [];

		//Call the new list data function
		this.newListData(dataList);

		// Create a new observable list of the items.
		this.listItems$ = of(dataList);

		// Set up the pre-filtered count.
		this.listItemsCount$ = this.listItems$.pipe(
			takeUntil(componentDestroyStream(this)),
			map((items) => (items ? items.length : 0))
		);

		// Now set up the filtered list item observable stream so we can filter results.
		this.filteredListItems$ = this.listItems$.pipe(
			combineLatest(
				this.searchControlFormValues$.pipe(startWith({})),
				(itemList, listControlForm) => ({ itemList, listControlForm })
			),
			map(({ itemList, listControlForm }) =>
				itemList.filter((item) => {
					// Are we showing the search box?
					//  let showSearchFormControl = this.searchControlForm.controls['showSearchFormControl'].value;
					let searchFormControl =
						this.searchControlForm.controls["searchFormControl"].value;

					// If we aren't showing the search control then return true as we are not doing any searching.
					// if (!showSearchFormControl)
					//  return true;

					// Now make the request to search the string.
					return SearchUtils.stringSearch(searchFormControl, [item]);
				})
			)
		);

		// Set up the post-filtered count.
		this.filteredListItemsCount$ = this.filteredListItems$.pipe(
			takeUntil(componentDestroyStream(this)),
			map((items) => (items ? items.length : 0))
		);
	}

	/**
	 * The new list data has been set so we will re-initalise our list
	 */
	newListData(dataList) {
		//If the data is missing we will default our form control to an empty array
		if (!dataList) this.listControlForm.setValue([]);
	}

	/**
	 * Add the item to the form group
	 */
	itemAdd($event) {
		//Check that the input has a valid value, if it has then push the value onto the form
		if ($event.length > 0) {
			//Get the current item list
			let inputItemList: any[] = this.itemListFromControl();

			//If the item list is undefined then we will set it to a new array ready for use
			if (inputItemList == undefined) inputItemList = [];

			//Add the item to the lise
			if (this.appendNewItems) {
				inputItemList.push($event);
			} else {
				inputItemList.unshift($event);
			}

			//Mark the form as dirty
			this.listControlForm.markAsDirty();

			//Set the altered list of values in the form control
			this.listControlForm.setValue(inputItemList);
		}
	}

	/**
	 * Remove the item at the specified index from the list
	 */
	itemsRemove(indexes: number[]) {
		//Get the item array
		let itemArray: any[] = this.itemListFromControl();

		//If the index is off either bounds then we cannot remove the item
		if (indexes == undefined || indexes.length <= 0 || itemArray == undefined)
			return;

		//Sort the indexes backwards so we can remove from the list without breaking the original list
		indexes.sort((a, b) => (a > b ? -1 : a == b ? 0 : 1));

		//Loop through each of the indexes
		indexes.forEach((index) => {
			//If the index is out of range if so skip over
			if (index < 0 || index >= itemArray.length) return;

			//Remove the item from the item array
			itemArray.splice(index, 1);
		});

		//Mark the form as dirty
		this.listControlForm.markAsDirty();

		//Set the altered list of values in the form control
		this.listControlForm.setValue(itemArray);
	}

	/**
	 * This handler is called when the user clicks on the delete button.
	 */
	itemsDeleteHandler() {
		//Get the item array
		let itemArray: any[] = this.itemListFromControl();

		//We need to set the index for each item
		let index = 0;

		//The popup expects an object and to accuretly delete the items we need to store an index to the item
		//this will allow us to delete the item from the list later. Er must also have an id property for this
		//object or the popup will not work. So our index will be our id
		let createdObjects = itemArray.map((item) => {
			//Create a new object with the index and the data item
			let newObj = { id: index, data: item };

			//Increase the item
			index++;

			//Return the new object
			return newObj;
		});

		// Open a new list dialog to get the correct distribution groups to assign the new distribution too.
		let dialogRef = this.dialogService
			.selectMultiListDialogOpen(
				"Select " + this.titleText + " to Remove",
				undefined,
				this.titleText,
				of(createdObjects),
				[],
				"data",
				true,
				"Remove"
			)
			.pipe(takeUntil(componentDestroyStream(this)));

		//Subscribe the dialogue for the results of the dialogue
		dialogRef.subscribe((results) => {
			//Of we don't have results then bail out
			if (!results) return;

			//OK so we have selected some things to removed we will map this back to a list
			//of indexes to remove from the list
			let indexs = (results as any[]).map((result) => result.id);

			//Remove the items
			this.itemsRemove(indexs);
		});
	}

	/**
	 * Return the item list from the form control
	 */
	itemListFromControl(): any[] {
		//If the list form control is undefined then we will return an empty list
		if (this.listControlForm == undefined) return [];

		//Return the item list
		return <any[]>this.listControlForm.value;
	}

	/**
	 * Generic toggle button click control handler.
	 *
	 * NOTE: We are currently having to do this as forms do not have a toggle button
	 *        as an input only the slides ... this is not useful in all cases.
	 *        as a result I am implementing a simple one where the data is still reflected in the form
	 *        but when a button is clicked it calls this function with its selector ands set the form data manually
	 */
	toggleButtonClickHandler(controlName: string) {
		//Create the sort control
		let control = <UntypedFormControl>(
			this.searchControlForm.controls[controlName]
		);

		//If the control is undefied then bail out
		if (control == undefined) return;

		//Get the toggle value from the control
		let toggle: boolean = <boolean>control.value;

		//Set the value of the toggle to the opisite of what it was before
		control.setValue(!toggle);
	}

	/**
	 * This is a simple string sorting function.
	 */
	private stringSorter(string1: string, string2: string): number {
		string1 = string1 ? string1 : "";
		string2 = string2 ? string2 : "";

		// Make sure we convert the values into lower case so the case does not affect the sorting.
		if (string1.toLowerCase() > string2.toLowerCase()) return 1;

		if (string1.toLowerCase() < string2.toLowerCase()) return -1;

		return 0;
	}
}
