/**
 * Sort the alhpanumeric strings from 0 - 9 - A - Z
 */
export const sortNumeric0to9 = (numberA: number, numberB: number): number => {
	//Default to 0 if the string is undefined
	numberA = numberA ? numberA : 0;
	numberB = numberB ? numberB : 0;

	// Make sure we convert the names into lower case so the case does not affect the sorting.
	if (numberA > numberB) return 1;
	if (numberA < numberB) return -1;

	return 0;
};

/**
 * Sort the alhpanumeric strings from 0 - 9 - A - Z
 */
export const sortNumeric9to0 = (numberA: number, numberB: number): number => {
	//Default to 0 if the string is undefined
	numberA = numberA ? numberA : 0;
	numberB = numberB ? numberB : 0;

	// Make sure we convert the names into lower case so the case does not affect the sorting.
	if (numberA > numberB) return -1;
	if (numberA < numberB) return 1;

	return 0;
};

/**
 * Sort the alhpanumeric strings from 0 - 9 - A - Z
 */
export const sortAlphanumericAtoZ = (
	stringA: string,
	stringB: string
): number => {
	//Default to empty string if the string is undefined
	stringA = stringA ? stringA : "";
	stringB = stringB ? stringB : "";

	// Make sure we convert the names into lower case so the case does not affect the sorting.
	if (stringA.toLowerCase() > stringB.toLowerCase()) return 1;
	if (stringA.toLowerCase() < stringB.toLowerCase()) return -1;

	return 0;
};

/**
 * Sort the alhpanumeric strings from Z - A - 9 - 0
 */
export const sortAlphanumericZtoA = (
	stringA: string,
	stringB: string
): number => {
	//Default to empty string if the string is undefined
	stringA = stringA ? stringA : "";
	stringB = stringB ? stringB : "";

	// Make sure we convert the names into lower case so the case does not affect the sorting.
	if (stringA.toLowerCase() > stringB.toLowerCase()) return -1;
	if (stringA.toLowerCase() < stringB.toLowerCase()) return 1;

	return 0;
};

/**
 * Sort the Dates Earliest first
 */
export const sortDateNewest = (dateA: Date, dateB: Date): number => {
	//Default to now string if the datae is undfined.
	dateA = dateA ? dateA : new Date();
	dateB = dateB ? dateB : new Date();

	// Make sure we convert the names into lower case so the case does not affect the sorting.
	if (dateA.valueOf() > dateB.valueOf()) return -1;
	if (dateA.valueOf() < dateB.valueOf()) return 1;

	return 0;
};

/**
 * Sort the Dates oldest first
 */
export const sortDateOldest = (dateA: Date, dateB: Date): number => {
	//Default to now string if the datae is undfined.
	dateA = dateA ? dateA : new Date();
	dateB = dateB ? dateB : new Date();

	// Make sure we convert the names into lower case so the case does not affect the sorting.
	if (dateB.valueOf() > dateA.valueOf()) return -1;
	if (dateB.valueOf() < dateA.valueOf()) return 1;

	return 0;
};
