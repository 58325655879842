import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
} from "@angular/core";
import { ExportVersionAHubVO } from "app/valueObjects/ahub/accounts/export-version.ahub.vo";

@Component({
	selector: "app-export-version-state-balls",
	templateUrl: "./export-version-state-balls.component.html",
	styleUrls: ["./export-version-state-balls.component.css"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportVersionStateBallsComponent implements OnInit {
	@Input() exportVersions: ExportVersionAHubVO[];

	@Input() tooltipMessage = "Export Version States";
	@Input() size = 30;

	constructor(private cd: ChangeDetectorRef) {}

	ngOnInit() {}
}
