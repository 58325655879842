import { Component, OnInit } from "@angular/core";
import { ThreeDRendererParams } from "app/modules/common/components/three-d-renderer/three-d-renderer.component";

@Component({
	selector: "app-product-asset-view-dialog-glb",
	templateUrl: "./product-asset-view-dialog-glb.component.html",
	styleUrls: ["./product-asset-view-dialog-glb.component.css"],
})
export class ProductAssetViewDialogGLBComponent implements OnInit {
	/**
	 * Parameters for the dialogue (must be null or property isn't found!)
	 */
	public dialogParams: ProductAssetViewDialogGLBComponentParams = null;

	/**
	 * Rendering parameters
	 */
	renderParams: ThreeDRendererParams;

	ngOnInit() {
		this.renderParams = {
			objerUrl: this.dialogParams.glbURL,
			objectPreviewUrl: this.dialogParams.previewURL,
		};
	}
}

/**
 * Parameters for setting up the GLB preview
 */
export interface ProductAssetViewDialogGLBComponentParams {
	sectionName: string;
	sectionColour: string;
	propertyName: string;
	glbURL: string;
	previewURL: string;
}
