import { map } from "rxjs/operators";
import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { DialogService } from "modules/common/dialogs/dialog.service";
import { EntityPermissions } from "app/valueObjects/ahub/accounts/entity-permissions.ahub";

@Component({
	selector: "app-distribution-group-properties-aview",
	templateUrl: "./distribution-group-properties-aview.component.html",
	styleUrls: ["./distribution-group-properties-aview.component.css"],
})
export class DistributionGroupPropertiesAViewComponent implements OnInit {
	/**
	 * These are the distribution editor permissions required to
	 * add/remove restrictions.
	 */
	distributionEditor = EntityPermissions.DISTRIBUTION_EDITOR;

	@Input() distributionForm: FormGroup;
	@Input() groupSettings = false;

	orderSubmissionTypes: Object[] = [
		{ value: "", name: "None" },
		{ value: "xml", name: "Legacy XML API" },
		{ value: "xmlBody", name: "XML Body" },
		{ value: "jsonBody", name: "JSON Body" },
	];

	/**
	 * Create an observable string of the order submission server type
	 */
	public distributionOrderSubmissionType$: Observable<String> = undefined;

	constructor() {}

	ngOnInit() {
		if (this.distributionForm) {
			this.distributionOrderSubmissionType$ =
				this.distributionForm.valueChanges.pipe(
					map((data) => data.orderSubmissionServerType)
				);
		}
	}
}
