import { map } from "rxjs/operators";
import { NgRedux } from "@angular-redux2/store";
import { Observable } from "rxjs";

/**
 * Store
 */
import { StoreCache } from "store/store-cache";
import { List } from "store/list.vo";

/**
 * Utils
 */
import { ListUtil } from "store/list.util";
import { Utils } from "modules/common/utils";

/**
 * Actions
 */
import { ActionWork } from "store/actions/types/work.action-types";
import { ActionQueuedActions } from "actions/types/app.action-types";
import { AppActions } from "actions/app.actions";
import { HashedMapStorage } from "store/hashed-map-storage.vo";
import { IdHashedMapUtil } from "store/id-hashed-map-storage.util";
import { HashedMapUtil } from "store/hashed-map-storage.util";

/**
 * Class to make it easy to interact with the store and its data
 */
export class StoreAccess {
	/**
	 * A count we will use for action ids ... this will be incremental
	 */
	private static actionCount: number = 0;

	/**
	 * Dispatch the action specified to the store
	 */
	static dispatch(action: ActionWork, ignoreCache?: boolean): number {
		//If we are not ignoring the cache we need to normalise the action
		//this may edit the action to only reflect the data we need. Or return undefied
		//to indicate we have no need to dispatch this action at all
		if (ignoreCache != true) action = StoreCache.normaliseAction(action);

		//If the action is missing then return undefined
		if (!action) return undefined;

		//Do we have an action id, not then create
		if (!action.actionId) {
			//Increase the action count.
			StoreAccess.actionCount++;

			//Give the action a random id.
			action.actionId = StoreAccess.actionCount;
		}

		//Dispatch the action to the store
		NgRedux.store.dispatch(action);

		// Return the action id.
		return action.actionId;
	}

	/**
	 * Adds the action specified to the action queue. This action will be executed when we have sufficent
	 * room in the queue for execution.
	 * If intending to send multiple send them all at once as this will much more efficent in the long run. If its only
	 * one fill your boots
	 *
	 * @param action
	 */
	static dispatchToQueueSingle(action: ActionWork): number {
		//Call with multi version with the action specfied
		let actionIds = StoreAccess.dispatchToQueue([action]);

		//Returns the action id's to the queue
		return actionIds ? actionIds[0] : undefined;
	}

	/**
	 * Adds the actions specified to the action queue. This action will be executed when we have sufficent
	 * room in the queue for execution
	 */
	static dispatchToQueue(actions: ActionWork[]): number[] {
		//If the action is missing then return undefined
		if (!actions || actions.length == 0) return undefined;

		//List of action id's for the actions requested
		let actionIds = [];

		//Loop through each of our actions generating id's for them if we need to
		actions.forEach((action) => {
			//Do we have an action id, not then create
			if (!action.actionId) {
				//Increase the action count.
				StoreAccess.actionCount++;

				//Give the action a random id.
				action.actionId = StoreAccess.actionCount;
			}

			//Add the action id to the list
			actionIds.push(action.actionId);
		});

		//Create a queued action
		let addQueuedAction: ActionQueuedActions =
			AppActions.actionQueuedAdd(actions);

		//Dispatch a add queued action
		StoreAccess.dispatch(addQueuedAction);

		//Return the action id for the originally submitted action
		return actionIds;
	}

	/**
	 * Get the store data as an observable using a selector. This will be a copy of the objects
	 * and not the original. The original store data needs to be kept prestine
	 */
	static dataGetObvs = <T>(selector: (data) => T): Observable<T> => {
		//If the selector is missing then
		if (selector == undefined || selector == null) return undefined;

		//Call the selector with the data from the store
		let observable: Observable<T> = NgRedux.store.select(selector);

		//Before the data is returned we create a deep copy of it
		if (observable)
			observable = observable.pipe(map((data) => Utils.clone(data)));

		//Return the observable
		return observable;
	};

	/**
	 * Get the store data using a selector. This will be a copy of the object
	 * and not the original. The original store data needs to be kept prestine
	 */
	static dataGet = <T>(selector: (data) => T): T => {
		//If the selector is missing then
		if (selector === undefined || selector == null) {
			return undefined;
		}

		//Call the selector with the data from the store. We will call a copy function so we never return the
		//original object
		return Utils.clone(selector(NgRedux.store.getState()));
	};

	/**
	 * -------------------------------------------------------------
	 * Store data list access - makes accessing list data easier.
	 *                          generally uses the above functions at the root.
	 * -------------------------------------------------------------
	 */

	/**
	 * Get the store list data using a selector by a specified id. This will be a copy
	 * of the object and not the original. The original store data needs to be kept prestine
	 */
	static dataListItemGet = <T>(selector: (data) => List<T>, id: any): T => {
		//Get the list of items from the selector
		let items: T[] = StoreAccess.dataListItemsGet(selector, [id]);

		//Get the item out of the items array
		return items && items.length > 0 ? items[0] : undefined;
	};

	/**
	 * Get the store list data using a selector by a specified ids. This will be a copy
	 * of the object and not the original. The original store data needs to be kept prestine
	 */
	static dataListItemsGet = <T>(
		selector: (data) => List<T>,
		ids: any[]
	): T[] => {
		//We will call our base function to create a new selector using the passed selector and ids
		let newListSelector = StoreAccess.dataListItemsGetSelectorCreate(
			selector,
			ids
		);

		//We will then call the regular data get function within this class to get the data in the traditional way
		return StoreAccess.dataGet(newListSelector);
	};

	/**
	 * Get the observable store list data using a selector by a specified ids. This will be a copy
	 * of the object and not the original. The original store data needs to be kept prestine
	 */
	static dataListItemsGetObvs = <T>(
		selector: (data) => List<T>,
		ids: any[]
	): Observable<T[]> => {
		return StoreAccess.dataGetObvs(selector).pipe(
			map((list) => ListUtil.listDataItemsGet(list, ids))
		);
	};

	/**
	 * Get an observable store list data using a selector by a specified id. This will be a copy
	 * of the object and not the original. The original store data needs to be kept prestine
	 */
	static dataListItemGetObvs = <T>(
		selector: (data) => List<T>,
		id: any
	): Observable<T> => {
		//We are creating a wrapping function which has the selector we are using and the ids that we want
		//we will then return the wrapping function.
		let singleItemFromListFunction = (state) => {
			//Get the list base on the selector supplied
			let list: List<T> = selector(state);

			//Call a map on the ids, in this we will get the item for each id in the list.
			//Within the map we get the item out of the list. We will continue to filter out the undefied items
			return ListUtil.listDataItemGet(list, id);
		};

		//Get the list of items from the selector
		return StoreAccess.dataGetObvs(singleItemFromListFunction);
	};

	/**
	 * Base function for creating the list items get function
	 * The function expects a selector which returns a List object with a set of ids. It will then
	 * return a new selector which conforms to the selector interface ( state ):value.
	 */
	private static dataListItemsGetSelectorCreate = <T>(
		selector: (data) => List<T>,
		ids: any[]
	): ((state) => T[]) => {
		//We are creating a wrapping function which has the selector we are using and the ids that we want
		//we will then return the wrapping function.
		return (state) => {
			//Get the list base on the selector supplied
			let list: List<T> = selector(state);

			//Call a map on the ids, in this we will get the item for each id in the list.
			//Within the map we get the item out of the list. We will continue to filter out the undefied items
			return ids
				.map((id) => ListUtil.listDataItemGet(list, id))
				.filter((value) => !undefined);
		};
	};

	/**
	 * Does the id specified exist in the store list data.
	 */
	static dataListItemExists = <T>(
		selector: (data) => List<T>,
		id: any
	): boolean => {
		//Get the list of items from the selector
		let items: boolean[] = StoreAccess.dataListItemsExist(selector, [id]);

		//Get the item out of the items array
		return items && items.length > 0 ? items[0] : undefined;
	};

	/**
	 * Does the ids specified exist in the store list data.
	 */
	static dataListItemsExist = <T>(
		selector: (data) => List<T>,
		ids: any[]
	): boolean[] => {
		//We will call our base function to create a new selector using the passed selector and ids
		let newListSelector = StoreAccess.dataListItemsExistSelectorCreate(
			selector,
			ids
		);

		//We will then call the regular data get function within this class to get the data in the traditional way
		return StoreAccess.dataGet(newListSelector);
	};

	/**
	 * Get an observable stream to show if the id specified exist in the store list data.
	 */
	static dataListItemExistObvs = <T>(
		selector: (data) => List<T>,
		id: any
	): Observable<boolean> => {
		//Get the list of items from the selector
		return StoreAccess.dataListItemsExistObvs(selector, [id]).pipe(
			map((valueArray) => (valueArray.length > 0 ? valueArray[0] : false))
		);
	};

	/**
	 * Get an observable stream to show if the ids specified exist in the store list data.
	 */
	static dataListItemsExistObvs = <T>(
		selector: (data) => List<T>,
		ids: any[]
	): Observable<boolean[]> => {
		//We will call our base function to create a new selector using the passed selector and ids
		let newListSelector = StoreAccess.dataListItemsExistSelectorCreate(
			selector,
			ids
		);

		//We will then call the regular data get function within this class to get the data in the traditional way
		return StoreAccess.dataGetObvs(newListSelector);
	};

	/**
	 * Base function for creating the list items exist function
	 * The function expects a selector which returns a List object with a set of ids. It will then
	 * return a new selector which conforms to the selector interface ( state ):boolean[].
	 */
	private static dataListItemsExistSelectorCreate = <T>(
		selector: (data) => List<T>,
		ids: any[]
	): ((state) => boolean[]) => {
		//We are creating a wrapping function which has the selector we are using and the ids that we want
		//we will then return the wrapping function.
		return (state) => {
			//Get the list base on the selector supplied
			let list: List<T> = selector(state);

			//Call a map on the ids, in this we will get the item for each id in the list.
			//Within the map we get the item out of the list. We will continue to filter out the undefied items
			return ids.map((id) => ListUtil.listDataItemExists(list, id));
		};
	};

	/**
	 * -------------------------------------------------------------
	 * Hashed Map - Utils
	 * -------------------------------------------------------------
	 */

	/**
	 * Get all of the items out of the map supplied when the map changes as an observable.
	 */
	static dataHashedMapItemsAllGetObvs = <T>(
		selector: (data) => HashedMapStorage<T>
	): Observable<T[]> => {
		//Return a data get observable stream to watch the map. Get all the data out of the map
		return StoreAccess.dataGetObvs(selector).pipe(
			map((map) => (map ? HashedMapUtil.hashMapGetItemsAll(map) : undefined))
		);
	};

	/**
	 * Get all of the items out of the map supplied when the map changes.
	 */
	static dataHashedMapItemsAllGet = <T>(
		selector: (data) => HashedMapStorage<T>
	): T[] => {
		const map = StoreAccess.dataGet(selector);
		//Return data
		return map ? HashedMapUtil.hashMapGetItemsAll(map) : undefined;
	};

	/**
	 * Get the store list data using a selector by a specified id. This will be a copy
	 * of the object and not the original. The original store data needs to be kept prestine
	 */
	static dataHashedMapItemGet = <T>(
		selector: (data) => HashedMapStorage<T>,
		id: string
	): T => {
		//Get the map from the selector
		let map = StoreAccess.dataGet(selector);

		//Return the data from the
		return map ? HashedMapUtil.hashMapGetItem(map, id) : undefined;
	};

	/**
	 * Get the store list data using a selector by a specified id. This will be a copy
	 * of the object and not the original. The original store data needs to be kept prestine
	 */
	static dataHashedMapItemGetObvs = <T>(
		selector: (data) => HashedMapStorage<T>,
		id: string
	): Observable<T> => {
		return StoreAccess.dataGetObvs(selector).pipe(
			map((map) => (map ? HashedMapUtil.hashMapGetItem(map, id) : undefined))
		);
	};
}
