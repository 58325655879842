import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "textToOperator",
})
export class TextToOperatorPipe implements PipeTransform {
	transform(value: string, args?: any): any {
		if (value) {
			switch (value) {
				case "EQUALS":
					return "=";
				case "NOT_EQUALS":
					return "!=";
				case "LESS_OR_EQUAL":
					return "<=";
				case "GREATER_OR_EQUAL":
					return ">=";
				case "LIKE":
					return "LIKE";
				case "CONTAINS":
					return "CONTAINS";
				case "NOT_CONTAINS":
					return "NOT CONTAINS";
				case "BLANK":
					return "BLANK";
				case "NOT_BLANK":
					return "NOT BLANK";

				default:
					return "textToOperator PIPE FAILED TO FIND " + value;
			}
		}
	}
}
