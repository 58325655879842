import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from "@angular/router";
import { sessionToken } from "app/store/selector/app.selector";
import { StoreAccess } from "app/store/store-access";
import { filter } from "rxjs/operators";

@Injectable()
export class ExternalRedirectGuard implements CanActivate {
	constructor(private readonly router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		StoreAccess.dataGetObvs(sessionToken)
			.pipe(
				filter(
					(sessionToken) => sessionToken !== undefined && sessionToken !== null
				)
			)
			.subscribe((sessionToken) => {
				window.location.href = route.data["externalUrl"];

				return true;
			});
		return false;
	}
}
