import { Injectable } from "@angular/core";

@Injectable()
export class ObjectStoreService {
	/**
	 * Object which will be our store for the object
	 */
	private objectStore = {};

	constructor() {
		// This is intentional
	}

	/**
	 * Store the object against and id, id specification is optional
	 * if its not set one will be generated. The id is returned
	 */
	store(object: any, id?: string): string {
		//If we have no id then we will generate a random  string for the id
		if (!id || id.trim().length == 0)
			id =
				Math.random().toString(36).substring(2, 15) +
				Math.random().toString(36).substring(2, 15);

		//Store the object with the id specified
		this.objectStore[id] = object;

		//Return the id ready for use
		return id;
	}

	/**
	 * Get the object from the id specified. It can be specified to delete the value from the store
	 * after the get. If we know we are putting it away and getting it once then we can do this to
	 * throw the data out
	 */
	get(id: string, deleteAfterGet?: boolean): any {
		//Get the value from the store
		let value = this.objectStore[id];

		//Delete the value from the store if specified
		if (deleteAfterGet) this.delete(id);

		//Returnt value
		return value;
	}

	/**
	 * Delete the item from the object store
	 */
	delete(id: string) {
		//Set the item at this id to undefined
		this.objectStore[id] = undefined;
	}
}
