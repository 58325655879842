import {
	Component,
	OnInit,
	Input,
	ComponentFactoryResolver,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";

import { combineLatest, Observable, of } from "rxjs";

import { RequestActionMonitorService } from "services/request-action-monitor/request-action-monitor.service";
/**
 * Store
 */
import { StoreAccess } from "store/store-access";
import { AHubActions } from "actions/ahub.actions";
import { Hark, componentDestroyStream } from "modules/common/hark.decorator";

import { EntityPermissions } from "valueObjects/ahub/accounts/entity-permissions.ahub";
import { ClientConfigurationAHubVO } from "valueObjects/ahub/accounts/client-configuration.ahub.vo";
import { Utils } from "modules/common/utils";
import {
	debounceTime,
	map,
	takeUntil,
	publishReplay,
	refCount,
} from "rxjs/operators";
import { DialogService } from "../../dialogs/dialog.service";
import {
	InputDialogVO,
	InputDialogComponent,
} from "../../dialogs/input-dialog/input-dialog.component";
import { ClientAHubVO } from "app/valueObjects/ahub/accounts/client.ahub.vo";
import { ObjectStoreService } from "app/services/object-store/object-store.service";
import { aHubStateTemporaryClientLogos } from "app/store/selector/ahub/ahub-temporary.selector";
import { ListUtil } from "app/store/list.util";

@Component({
	selector: "app-client-configuration-card",
	templateUrl: "./client-configuration-card.component.html",
	styleUrls: ["./client-configuration-card.component.scss"],
})
@Hark()
export class ClientConfigurationCard implements OnInit {
	@Input() clientConfig$: Observable<ClientConfigurationAHubVO>;
	clientConfig: ClientConfigurationAHubVO;

	/**
	 * This is the pre-signed URL for the client logo if we can find one.
	 */
	clientLogo$: Observable<string>;

	@Input() selectedClient: Observable<ClientAHubVO>;
	/**
	 * Define the configuration form
	 */
	configForm: UntypedFormGroup = this.fb.group({
		id: [],
		permittedInclusionListDomains: [],
		trimThreshold: [],
		aViewPro: false,
		aWorkbook: false,
		aRanger: false,
	});

	selectedFile: any = null;

	clientLogo: any = null;

	/**
	 *  Subscribe to the request action status object that has an action id that matches this current id.
	 */
	actionRequestActionStatus$: Observable<boolean> = undefined;

	/**
	 * This is the number of permitted domains.
	 */
	permittedDomainsLength$: Observable<number>;

	clientConfigUntouchedByHumanHands: ClientConfigurationAHubVO;

	systemUser = EntityPermissions.SYSTEM_USER;

	formChanges$ = this.configForm.valueChanges.pipe(debounceTime(200));

	constructor(
		private readonly fb: UntypedFormBuilder,
		private readonly requestActionMonitorService: RequestActionMonitorService,
		private readonly dialogService: DialogService,
		private readonly objectStoreService: ObjectStoreService,
		private readonly resolver: ComponentFactoryResolver
	) {}

	onFileSelect(e: any): void {
		this.selectedFile = e.target.files[0] ?? null;
		if (e.target.files) {
			const reader = new FileReader();
			reader.readAsDataURL(this.selectedFile);
			reader.onload = (event) => {
				this.clientLogo = event.target.result;
			};
		}
		this.uploadFile(this.selectedFile);
	}

	/**
	 * Empty On Init to ensure @Hark decorator works for an AOT build
	 */
	ngOnInit() {
		this.clientConfig$
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((clientConfig) => {
				this.clientConfigUntouchedByHumanHands = Utils.clone(clientConfig);
				this.clientConfig = clientConfig;
				this.formsSet(clientConfig);
				this.configForm.markAsPristine();

				// Make the request to get the clients logo.
				if (clientConfig) {
					StoreAccess.dispatch(AHubActions.clientLogoGet(clientConfig.id));
				}
			});

		// Watch the length of the permitted domains.
		this.permittedDomainsLength$ = this.configForm.valueChanges.pipe(
			takeUntil(componentDestroyStream(this)),
			map((data) =>
				data.permittedInclusionListDomains
					? data.permittedInclusionListDomains.length
					: 0
			)
		);

		this.clientLogo$ = combineLatest([
			this.clientConfig$.pipe(
				Utils.isNotNullOrUndefined(),
				map((clientConfig) => clientConfig.id)
			),
			StoreAccess.dataGetObvs(aHubStateTemporaryClientLogos),
		]).pipe(
			map(([clientId, clientLogos]) => {
				const item = ListUtil.listDataItemGet(clientLogos, clientId);
				return item && item.item ? item.item.signedUrl : null;
			}),
			publishReplay(1),
			refCount()
		);

		this.clientLogo$
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((clientLogoUrl) => (this.clientLogo = clientLogoUrl));
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 *  Reset the form back to the original state
	 */
	resetHandler() {
		// Take a copy of the client so we can reset it to the source object.
		let clientConfig: ClientConfigurationAHubVO = Utils.clone(
			this.clientConfigUntouchedByHumanHands
		);

		// Now reset it.
		this.formsSet(clientConfig);
		this.configForm.markAsPristine();
	}

	/**
	 * Set the client into our forms which are currently using it
	 */
	private formsSet(selectedClient: ClientConfigurationAHubVO) {
		//Set the client as generic form data
		let formData: any = selectedClient;

		//If the data is undefined then we will set the form to empty
		if (!formData) formData = {};

		//We are resetting the form data so we will mark the data as prestine
		// this.configForm.markAsPristine();

		//Update the form data
		this.configForm.reset(formData);
	}

	/**
	 * Call the client congiguration save handler
	 */
	clientConfigSaveHandler() {
		// Populate the original object with the values from the form. This way we dont over write (eg blank out) any
		// properties not mentioned in this form
		Object.keys(this.configForm.controls).forEach((key) => {
			// console.log(this.configForm.get(key).value)
			this.clientConfig[key] = this.configForm.get(key).value;
		});

		// Save the distribution group.
		const saveActionId = StoreAccess.dispatch(
			AHubActions.clientConfigurationCommit(this.clientConfig)
		);

		//Get an Observable which will indicate if this action is busy or not
		this.actionRequestActionStatus$ =
			this.requestActionMonitorService.requestActionStatusObservableByActionIdBusy(
				saveActionId
			);

		//Action request status
		this.actionRequestActionStatus$
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((actionRequestActionStatus) => {
				if (actionRequestActionStatus) {
					this.configForm.markAsPristine();
				}
			});
	}

	/**
	 * Upload logo for this client
	 */
	uploadFile(file: File) {
		//If we have no files then bail!
		if (!file) return;

		//List of the object store id's
		let objectStoreId: string = "";

		// We can't upload files from just the provision of a directory.
		// Ie We can't look up files if we are given a directory. We need files..
		// Directories have no type.
		if (file.type != "") {
			//Call the object store to stick the file in it and make a note of the id it is assigned
			objectStoreId = this.objectStoreService.store(file);
		}

		// Dispatch an upload event
		StoreAccess.dispatchToQueueSingle(
			AHubActions.clientLogoUpload(this.clientConfig.id, "", objectStoreId)
		);
	}

	/**
	 * Open the add domain dialog
	 */
	addPermittedDomainClick($event) {
		let inputDialogVO: InputDialogVO = {
			inputValue: "",
			inputLabel: "Domain",
		};
		const addDomainDialog: Observable<any> =
			this.dialogService.componentDialogOpen(
				"Add permitted domain",
				this.resolver.resolveComponentFactory(InputDialogComponent),
				"inputDialogVO",
				inputDialogVO,
				null,
				"Add"
			);

		addDomainDialog
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((result) => {
				if (!result || !result.inputValue) {
					return;
				}

				if (!this.clientConfig.permittedInclusionListDomains) {
					this.clientConfig.permittedInclusionListDomains = [];
				}
				this.clientConfig.permittedInclusionListDomains.push(result.inputValue);
				this.formsSet(this.clientConfig);
				this.configForm.markAsDirty();
			});
	}

	/**
	 * This handler is called when the user clicks on the delete button.
	 */
	itemsDeleteHandler($event) {
		//Get the item array
		const itemArray: any[] = this.clientConfig.permittedInclusionListDomains;

		//We need to set the index for each item
		let index = 0;

		//The popup expects an object and to accuretly delete the items we need to store an index to the item
		//this will allow us to delete the item from the list later. Er must also have an id property for this
		//object or the popup will not work. So our index will be our id
		const createdObjects = itemArray.map((item) => {
			//Create a new object with the index and the data item
			const newObj = { id: index, data: item };

			//Increase the item
			index++;

			//Return the new object
			return newObj;
		});

		// Open a new list dialog to get the correct distribution groups to assign the new distribution too.
		const dialogRef = this.dialogService
			.selectMultiListDialogOpen(
				"Select Permitted Domains Inclusion List to Remove",
				undefined,
				"Permitted Domains Inclusion List",
				of(createdObjects),
				[],
				"data",
				true,
				"Remove"
			)
			.pipe(takeUntil(componentDestroyStream(this)));

		//Subscribe the dialogue for the results of the dialogue
		dialogRef.subscribe((results) => {
			//Of we don't have results then bail out
			if (!results) {
				return;
			}

			//OK so we have selected some things to removed we will map this back to a list
			//of indexes to remove from the list
			const indexs = results.map((result) => result.id);

			//Remove the items
			this.itemsRemove(indexs);
		});
	}

	/**
	 * Remove the item at the specified index from the list
	 */
	itemsRemove(indexes: number[]) {
		//Get the item array
		const itemArray: any[] = this.clientConfig.permittedInclusionListDomains;

		//If the index is off either bounds then we cannot remove the item
		if (
			indexes === undefined ||
			indexes.length <= 0 ||
			itemArray === undefined
		) {
			return;
		}

		//Sort the indexes backwards so we can remove from the list without breaking the original list
		indexes.sort((a, b) => {
			if (a > b) {
				return -1;
			} else {
				return a === b ? 0 : 1;
			}
		});

		//Loop through each of the indexes
		indexes.forEach((index) => {
			//If the index is out of range if so skip over
			if (index < 0 || index >= itemArray.length) {
				return;
			}

			//Remove the item from the item array
			itemArray.splice(index, 1);
		});

		//Set the altered list of values in the form control
		this.clientConfig.permittedInclusionListDomains = itemArray;
		this.formsSet(this.clientConfig);

		//Mark the form as dirty
		this.configForm.markAsDirty();
	}
}
