import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "nullToEmpty",
})
export class NullToEmptyPipe implements PipeTransform {
	transform(value: string, args?: any): any {
		if (value) {
			return value.replace(/null/g, "<strong> empty </strong>");
		}
	}
}
