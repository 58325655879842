import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { UserIndexAHubVO } from "@harksolutions/ahub-web-services-types";
import {
	RequestOptions,
	ServiceACatalog,
	ServiceOptions,
} from "@harksolutions/ahub-web-services";
import { AHubServiceCredentialsVO } from "app/services/ahub/ahub-service-credentials.vo";
import { ACatalogActions } from "app/store/actions/acatalog.actions";
import { selectionUsers } from "app/store/selector/acatalog.selector";
import { aHubStatePermanentUsers } from "app/store/selector/ahub/ahub-permanent.selector";
import {
	sessionUserId,
	sessionUserSessionCredentials,
} from "app/store/selector/app.selector";
import { StoreAccess } from "app/store/store-access";
import { UserAHubVO } from "app/valueObjects/ahub/accounts/user.ahub.vo";
import { environment } from "environments/environment";
import { combineLatest, Observable } from "rxjs";
import {
	delay,
	distinctUntilChanged,
	filter,
	map,
	takeUntil,
} from "rxjs/operators";
import { componentDestroyStream, Hark } from "../../hark.decorator";

const EMAIL_REGEX =
	/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
	selector: "app-share-selection",
	templateUrl: "./share-selection.component.html",
	styleUrls: ["./share-selection.component.css"],
})
@Hark()
export class ShareSelectionComponent implements OnInit, OnDestroy {
	@Input() shareSelectionWrapper: ShareSelectionWrapper = null;

	userByEmailForm = this.formBuilder.group({
		userEmail: ["", [Validators.required, Validators.pattern(EMAIL_REGEX)]],
	});

	/**
	 * This is the current session user.
	 */
	sessionUser$: Observable<UserAHubVO> = combineLatest([
		StoreAccess.dataGetObvs(sessionUserId).pipe(
			filter((userId) => userId !== undefined)
		),
		StoreAccess.dataGetObvs(aHubStatePermanentUsers),
	]).pipe(
		delay(0),
		map(([userId, users]) => users.find((user) => user.id === userId))
	);

	selectionUserIds$: Observable<number[]>;
	selectionUserIndexes$: Observable<UserIndexAHubVO[]>;

	serviceACatalog: ServiceACatalog = undefined;
	requestOptions: RequestOptions = undefined;

	@ViewChild("selectionUrlCopyButton", { static: false })
	public selectionUrlCopyButton;
	@ViewChild("shareLinkCopyButton", { static: false })
	public shareLinkCopyButton;
	errorMessage: string;
	shareLink: string;
	emailAddressValue: string;
	successMessages: string[] = [];
	selectionUrl: string;

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly clipboard: Clipboard
	) {
		const serviceOptions = new ServiceOptions(
			environment.aHubApi.domain,
			environment.aHubApi.basePath
		);

		serviceOptions.logRequest = true;

		//Create our service library with the base parameters for our requests
		this.serviceACatalog = new ServiceACatalog(serviceOptions);

		//Start off with the unsigned ones
		this.requestOptions = new RequestOptions();

		//Get the session credentials for the currently signed in user
		const sessionCredentials: AHubServiceCredentialsVO = StoreAccess.dataGet(
			sessionUserSessionCredentials
		);

		if (sessionCredentials) {
			//Set the session credentials into the request options
			this.requestOptions.sessionId = sessionCredentials.sessionId;
			this.requestOptions.sessionKey = sessionCredentials.sessionKey;
		}
	}

	ngOnInit(): void {
		this.selectionUrl = this.shareSelectionWrapper.selectionUrl;

		// If the entered email changes lets make sure any previous message are removed
		this.userByEmailForm.controls.userEmail.valueChanges
			.pipe()
			.subscribe((value) => {
				if (this.shareLink) {
					this.shareLink = undefined;
				}

				if (this.errorMessage) {
					this.errorMessage = undefined;
				}
			});

		// Fetch selection users
		StoreAccess.dispatch(
			ACatalogActions.selectionUsersFetch(
				this.shareSelectionWrapper.selectionId
			)
		);

		// Stream of selection user indexes
		this.selectionUserIndexes$ = StoreAccess.dataGetObvs(
			selectionUsers(this.shareSelectionWrapper.selectionId)
		).pipe(
			distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
			takeUntil(componentDestroyStream(this))
		);
	}

	onUserEmailSubmit(): void {
		const userEmail: string = this.userByEmailForm.controls.userEmail.value;

		// StoreAccess.dispatch()
		this.serviceACatalog
			.shareSelection(
				this.requestOptions,
				this.shareSelectionWrapper.selectionId,
				userEmail
			)
			.then((response) => {
				// Clear any previous error message
				this.errorMessage = undefined;
				// User was successfully added!
				if (response === "User added") {
					StoreAccess.dispatch(
						ACatalogActions.selectionUsersFetch(
							this.shareSelectionWrapper.selectionId
						)
					);
					this.successMessages.push(
						`${userEmail} successfully added to ${this.shareSelectionWrapper.selectionName}`
					);
				} else {
					// User can access the publication for this selection but has not registered, lets make them a link that
					// will complete the selection add process once that is done
					this.shareLink = `${window.location.origin}/process-token?token=${response}`;

					// Lets make it possible to send the 'share link' via email
					this.emailAddressValue = userEmail;
				}
			})
			.catch((error) => {
				// Clear any previous share link
				this.shareLink = undefined;

				if (error.statusCode === 409) {
					this.errorMessage = `${userEmail} already exists for discussion: ${this.shareSelectionWrapper.selectionName}`;
				} else if (error.statusCode === 404) {
					this.errorMessage = `${userEmail} does not have access to the publication: ${this.shareSelectionWrapper.publicationName}.
         Please contact publication administrator to resolve this, then try again.`;
				}
			});
	}

	copyShareLink() {
		this.shareLinkCopyButton.nativeElement.innerHTML = "Copied!";
		this.clipboard.copy(this.shareLink);
	}

	copySelectionUrl() {
		this.selectionUrlCopyButton.nativeElement.innerHTML = "Copied!";
		this.clipboard.copy(this.selectionUrl);
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}
}

export interface ShareSelectionWrapper {
	selectionId: number;
	selectionName: string;
	publicationName: string;
	selectionUrl: string;
}
