import { Component, OnInit } from "@angular/core";
import { StoreAccess } from "app/store/store-access";
import { AppActions } from "app/store/actions/app.actions";
import { PropertyAllocationObjectVO } from "app/valueObjects/stream/product-allocation-object-stream.vo";
import { DataSetAHubVO } from "app/valueObjects/ahub/library/dataset.ahub.vo";
import {
	aHubStateTemporaryProductClassIndexes,
	aHubStateTemporaryProductPropertyAllocationIndexes,
} from "app/store/selector/ahub/ahub-temporary.selector";
import { filter, map, takeUntil } from "rxjs/operators";
import { BehaviorSubject, Observable, combineLatest, Subject } from "rxjs";
import { ProductClassIndexAHubVO } from "app/valueObjects/ahub/library/product-class-index.ahub.vo";
import { componentDestroyStream, Hark } from "../../hark.decorator";
import {
	DataSetLibraryViewClassConfigAHubVO,
	EMPTY_DATASET_LIBRARY_VIEW_CLASS_CONFIG,
} from "app/valueObjects/ahub/library/dataset-library-view-class-config.ahub.vo";
import { LibraryViewUtils } from "../../library-view-utils";
import { SectionPropertyAllocationsStream } from "app/store/stream/section-property-allocations.stream";
import { AHubActions } from "app/store/actions/ahub.actions";
import { Utils } from "../../utils";
import { DataSetLibraryViewClassConfigWithInheritanceAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-class-config-with-inheritance.ahub.vo";
import { DataSetLibraryViewConfigAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-config.ahub.vo";
import { MatDialogRef } from "@angular/material/dialog";
import { MapStorage } from "app/store/map-storage.vo";
import {
	appLibraryViewConfigMapStorage,
	sessionClientId,
} from "app/store/selector/app.selector";
import { MapStorageUtil } from "app/store/map-storage.util";
import { Router } from "@angular/router";

@Component({
	selector: "app-configure-library-view-dialog",
	templateUrl: "./configure-library-view-dialog.component.html",
	styleUrls: ["./configure-library-view-dialog.component.scss"],
})
@Hark()
export class ConfigureLibraryViewDialogComponent implements OnInit {
	title: string;
	content: string;
	datasetID: any;

	/**
	 * These are the labels to be used on the confirm and cancel buttons.
	 *
	 *
	 */
	public confirmButtonLabel: string;
	public cancelButtonLabel: string;

	// Value object to pass into the created wrapped component using the componentVOParamName, useful for
	// passing initial values, will also be the object that is returned when the dialog is closed.
	public initVO: any;

	// enable reset button.
	public enableResetButton = false;

	/**** REQUIRED FOR USE IN A DIALOGUE  ****/
	/**
	 * The VO that is passed in / out.
	 */
	public dialogVO: any = null;

	//public dialogConfig: any = undefined;

	originalDataset: DataSetAHubVO;

	// Parameter name on the generated component to which to pass the VO in.
	public paramNameVO: string;

	classIndex$ = StoreAccess.dataGetObvs(
		aHubStateTemporaryProductClassIndexes
	).pipe(filter((classIndexes) => classIndexes !== undefined));

	selectedClassId$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	selectedClass$: Observable<ProductClassIndexAHubVO> = combineLatest([
		this.classIndex$,
		this.selectedClassId$.pipe(
			filter(
				(selectedClassId) =>
					selectedClassId !== undefined && selectedClassId > -1
			)
		),
	]).pipe(
		map(([classIndexes, selectedClassId]) => {
			return classIndexes
				? classIndexes.find((classIndex) => classIndex.id === selectedClassId)
				: undefined;
		}),

		takeUntil(componentDestroyStream(this))
	);

	savePending$: Subject<boolean> = new Subject<boolean>();
	savePending: boolean;
	configUpdated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	selectedClassLibraryViewConfig$: BehaviorSubject<DataSetLibraryViewClassConfigAHubVO> =
		new BehaviorSubject(undefined);

	// Lets get the allocs for this dataset
	selectedDataSetClassAllocs$ = combineLatest([
		StoreAccess.dataGetObvs(
			aHubStateTemporaryProductPropertyAllocationIndexes
		).pipe(filter((productPropertAllocs) => productPropertAllocs != null)),
		this.selectedClass$.pipe(
			filter((selectedClass) => selectedClass !== undefined)
		),
	]).pipe(
		map(([allocs, selectedClass]) => {
			const sections =
				this.dialogVO && this.dialogVO.sections ? this.dialogVO.sections : [];
			const sectionAllocs = allocs.filter((alloc) =>
				sections.find((sectionId) => sectionId === alloc.productSectionId)
			);
			const selectedClassAncestryIds =
				LibraryViewUtils.getAncestryAsNumberArray(selectedClass.ancestry);
			// Add selected class id
			selectedClassAncestryIds.push(selectedClass.id);
			return sectionAllocs.filter((sectionAlloc) =>
				selectedClassAncestryIds.includes(sectionAlloc.productClassId)
			);
		}),
		takeUntil(componentDestroyStream(this))
	);

	/**
	 * These are all of the product option filters available for the currently selected data set.
	 */
	selectedDataSetProductPropertyAllocationStreamObjects$: Observable<
		PropertyAllocationObjectVO[]
	> = SectionPropertyAllocationsStream.productAllocationObjectStreamDataGet(
		this.selectedDataSetClassAllocs$
	);

	rootNodeLabel = "Root Class";

	allocs$: Observable<PropertyAllocationObjectVO[]> =
		this.selectedDataSetProductPropertyAllocationStreamObjects$
			.pipe(filter((allocs) => allocs !== undefined))
			.pipe(
				takeUntil(componentDestroyStream(this)),
				map((allocs) => {
					// If we are currently defining library view config for the library puvblishing dataset (admins only)
					if (this.router.url.includes("library-publishing")) {
						// Lets make it possible to 'UNSET' an alloc by adding an empty one
						const unsetAlloc: PropertyAllocationObjectVO = UNSET_ALLOC;
						allocs.push(unsetAlloc);
					}

					return allocs;
				})
			);

	/**
	 * User library view config for current session client id and passed in dataset id (this.dialogVO)
	 */
	userLibraryViewConfigMapStorageByClientIdAndDataSetId$: Observable<DataSetLibraryViewConfigAHubVO> =
		combineLatest([
			StoreAccess.dataGetObvs(appLibraryViewConfigMapStorage).pipe(
				Utils.isNotNullOrUndefined()
			),
			StoreAccess.dataGetObvs(sessionClientId).pipe(
				Utils.isNotNullOrUndefined()
			),
		]).pipe(
			map(([userLibraryViewConfig, sessionClientId]) => {
				const clientLibraryViewConfig: MapStorage<DataSetLibraryViewConfigAHubVO> =
					MapStorageUtil.mapStorageGet(
						userLibraryViewConfig,
						sessionClientId.toString()
					);

				// Given that the dialogVO is actually a dataset
				return MapStorageUtil.mapStorageGet(
					clientLibraryViewConfig,
					this.dialogVO.id
				);
			})
		);

	constructor(
		public dialogRef: MatDialogRef<ConfigureLibraryViewDialogComponent>,
		private readonly router: Router
	) {}

	ngOnInit() {
		// Make a request to get the product property alloc indexes.
		StoreAccess.dispatch(AHubActions.productPropertyAllocationsFetch());
		StoreAccess.dispatch(AHubActions.productClassIndexsByClientIdFetch());

		this.originalDataset = Utils.clone(this.dialogVO);

		this.savePending$
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((savePending) => (this.savePending = savePending));

		combineLatest([
			this.selectedClass$.pipe(
				filter((selectedClass) => selectedClass !== undefined)
			),
			this.configUpdated$,
		])
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe(([selectedClass, configUpdated]) => {
				let selectedClassLibraryViewConfig: DataSetLibraryViewClassConfigAHubVO;

				if (
					this.dialogVO.libraryViewConfig &&
					this.dialogVO.libraryViewConfig.libraryViewClassConfigs &&
					this.dialogVO.libraryViewConfig.libraryViewClassConfigs.length > 0
				) {
					selectedClassLibraryViewConfig =
						this.dialogVO.libraryViewConfig.libraryViewClassConfigs.find(
							(classConfig) => classConfig.classId === selectedClass.id
						);
				}

				// This must be a new config for this selected class, lets make an 'empty' one
				if (!selectedClassLibraryViewConfig) {
					selectedClassLibraryViewConfig = {
						classId: selectedClass.id,
						productIdentifierPropertyAlloc: null,
						productMainImagePropertyAlloc: null,
						productInfoPropertyAllocs: null,
					};
				}

				// Lets find the classLibraryViewConfig for this class...AND/OR use the config
				// from parent classes in the tree
				const mergedClassLibraryViewConfig: DataSetLibraryViewClassConfigWithInheritanceAHubVO =
					LibraryViewUtils.buildMergedClassLibraryViewConfigWithInheritanceFromClassAncestry(
						Utils.clone(selectedClassLibraryViewConfig),
						Utils.clone(this.dialogVO.libraryViewConfig),
						selectedClass
					);

				this.selectedClassLibraryViewConfig$.next(mergedClassLibraryViewConfig);
			});
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	configChangeHandler(changedConfig: DataSetLibraryViewClassConfigAHubVO) {
		const selectedDataSet = this.dialogVO;

		// Lets check for differences between what came back from the class config editor and what was sent in
		const classConfigBeforeEdit =
			this.selectedClassLibraryViewConfig$.getValue();

		//Defined an empty object which we will use to identify the keys of an object.
		//at least this way it's type safe
		const emptyObject: DataSetLibraryViewClassConfigAHubVO = {
			classId: -1,
			productIdentifierPropertyAlloc: -1,
			productMainImagePropertyAlloc: -1,
			productInfoPropertyAllocs: [],
		};

		const changedPropertyKeys: string[] = Object.keys(emptyObject).filter(
			(changedConfigPropertyKey) => {
				if (!classConfigBeforeEdit) {
					return true;
				}

				const val1 = changedConfig[changedConfigPropertyKey]
					? JSON.stringify(changedConfig[changedConfigPropertyKey])
					: undefined;
				const val2 = classConfigBeforeEdit[changedConfigPropertyKey]
					? JSON.stringify(classConfigBeforeEdit[changedConfigPropertyKey])
					: undefined;

				return val1 !== val2;
			}
		);

		if (changedPropertyKeys.length > 0) {
			this.clearViewOnlyProperties(changedConfig);
			this.updateDatasetWithChangedProperties(
				selectedDataSet,
				changedPropertyKeys,
				changedConfig
			);
			this.tidyUpEmptyClassConfigs(selectedDataSet);
			this.configUpdated$.next(true);
			this.savePending$.next(true);
		}
	}

	/**
	 * Inspects the dataset library config class by class removing entries which have no 'useful' config
	 * @param selectedDataSet
	 */
	tidyUpEmptyClassConfigs(selectedDataSet: DataSetAHubVO) {
		const libraryViewConfig: DataSetLibraryViewConfigAHubVO =
			selectedDataSet.libraryViewConfig;

		// no config?...lets bounce
		if (
			!libraryViewConfig ||
			!libraryViewConfig.libraryViewClassConfigs ||
			libraryViewConfig.libraryViewClassConfigs.length === 0
		) {
			return;
		}

		const classConfigs: DataSetLibraryViewClassConfigAHubVO[] =
			libraryViewConfig.libraryViewClassConfigs;

		const emptyClassConfigsToBeRemovedByClassId: number[] = [];
		classConfigs.forEach((classConfig) => {
			let foundAUsefulValue = false;
			Object.keys(classConfig).forEach((key) => {
				// we expect that the class id at least will be populated
				if (key === "classId") {
					return;
				}

				if (classConfig[key]) {
					foundAUsefulValue = true;
				}
			});

			if (!foundAUsefulValue) {
				emptyClassConfigsToBeRemovedByClassId.push(classConfig.classId);
			}
		});

		libraryViewConfig.libraryViewClassConfigs =
			libraryViewConfig.libraryViewClassConfigs.filter(
				(classConfig) =>
					!emptyClassConfigsToBeRemovedByClassId.includes(classConfig.classId)
			);
	}

	clearViewOnlyProperties(
		changedConfig: DataSetLibraryViewClassConfigWithInheritanceAHubVO
	) {
		delete changedConfig.productIdentifierPropertyAllocInheritedFromClassId;
		delete changedConfig.productMainImagePropertyAllocInheritedFromClassId;
		delete changedConfig.productInfoPropertyAllocsInheritanceMap;
	}

	updateDatasetWithChangedProperties(
		selectedDataSet: DataSetAHubVO,
		changedPropertyKeys: string[],
		changedConfig: DataSetLibraryViewClassConfigAHubVO
	) {
		// Lets make sure we have library view config
		if (
			!selectedDataSet.libraryViewConfig ||
			!selectedDataSet.libraryViewConfig.dataSetId ||
			!selectedDataSet.libraryViewConfig.libraryViewClassConfigs
		) {
			selectedDataSet.libraryViewConfig = {
				dataSetId: selectedDataSet.id,
				libraryViewClassConfigs: [],
			};
		}

		// Lets make sure we've got a library view class config
		let classConfig =
			selectedDataSet.libraryViewConfig.libraryViewClassConfigs.find(
				(classConfiguration) =>
					classConfiguration.classId === changedConfig.classId
			);

		if (!classConfig) {
			const newClassConfig = Utils.clone(
				EMPTY_DATASET_LIBRARY_VIEW_CLASS_CONFIG
			);
			newClassConfig.classId = this.selectedClassId$.getValue();
			classConfig = newClassConfig;
			selectedDataSet.libraryViewConfig.libraryViewClassConfigs.push(
				classConfig
			);
		}

		changedPropertyKeys.forEach((changedPropertyKey) => {
			classConfig[changedPropertyKey] = changedConfig[changedPropertyKey];
			// If the value is empty we can just delete the whole property
			if (!classConfig[changedPropertyKey]) {
				delete classConfig[changedPropertyKey];
			}
		});
	}

	/**
	 * Handel the change of the selected class on the config menu!
	 *
	 * @param clazz
	 */
	classSelectedHandler(clazz) {
		//Do we have a class
		if (clazz) {
			this.selectedClassId$.next(clazz.id);
		}
	}

	onCloseClick(): void {
		this.dialogRef.close();
	}
	onSaveClick(): void {
		// If we have a save pending then close with the value object,
		// otherwise we want to close like a cancel.
		this.savePending
			? this.dialogRef.close(this.dialogVO)
			: this.onCloseClick();
	}

	resetUserLibraryViewConfig() {
		// Lets get the Map Storage of user library view configs
		const userLibraryViewConfig: MapStorage<
			MapStorage<DataSetLibraryViewConfigAHubVO>
		> = StoreAccess.dataGet(appLibraryViewConfigMapStorage);

		// Firstly lets grab the Map Storage of dataset user library view configs by the session client id
		let userLibraryViewConfigMapStorageByClientId: MapStorage<DataSetLibraryViewConfigAHubVO> =
			MapStorageUtil.mapStorageGet(
				userLibraryViewConfig,
				StoreAccess.dataGet(sessionClientId).toString()
			);

		// If we dont have a MapStorage for this client, we'll make one now
		if (!userLibraryViewConfigMapStorageByClientId) {
			// No config set for this client id, so nothing to clear
			return;
		}

		// Lets pop this library view config into the by dataset id map storage
		userLibraryViewConfigMapStorageByClientId = MapStorageUtil.mapStorageSet(
			userLibraryViewConfigMapStorageByClientId,
			this.dialogVO.id,
			undefined
		);

		// We've got something worth saving, les put this config away in the local store (e.g. NOT against the dataset)
		StoreAccess.dispatch(
			AppActions.libraryViewConfigMapStorageByClientIdSet(
				userLibraryViewConfigMapStorageByClientId,
				StoreAccess.dataGet(sessionClientId)
			)
		);
		this.dialogRef.close();
	}
}

export const UNSET_ALLOC: PropertyAllocationObjectVO = {
	id: 0,
	productClass: {
		ancestry: undefined,
		description: undefined,
		id: undefined,
		label: "",
		productMatrixDefinition: undefined,
	},
	property: {
		description: undefined,
		id: undefined,
		label: "NOT SET",
		required: undefined,
		tag: undefined,
		typeReference: undefined,
		isUnique: false,
	},
	section: {
		colour: "lightgrey",
		description: undefined,
		id: undefined,
		label: "",
		tag: undefined,
	},
};
