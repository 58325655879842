import { takeUntil, debounceTime } from "rxjs/operators";
import { Component, OnInit, AfterViewChecked, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable, Subscription, BehaviorSubject } from "rxjs";
import { ComponentDialogInjectedWrapperComponent } from "./component-dialog.injected-wrapper.component";

// Additional core imports for component injection.
import { ViewChild, ComponentFactory } from "@angular/core";

// Hark component management
import { Hark, componentDestroyStream } from "modules/common/hark.decorator";

@Component({
	selector: "app-component-dialog",
	templateUrl: "./component-dialog.component.html",
	styleUrls: ["./component-dialog.component.css"],
})
@Hark()
export class ComponentDialogComponent
	implements OnInit, OnDestroy, AfterViewChecked
{
	/**
	 * Add button busy observable stream
	 */
	public addButtonBusy: Observable<boolean>;

	// Component factory that generates component for displaying objects in our list - use instead of LabelFunction.
	public componentFactory: ComponentFactory<any>;

	// Parameter name on the generated component to which to pass the VO in.
	public paramNameVO: string;

	// Parameter name on the generated component to which to watch to see if the dialog content is valid.
	public paramNameDialogValid: string;

	// Value object to pass into the created wrapped component using the componentVOParamName, useful for
	// passing initial values, will also be the object that is returned when the dialog is closed.
	public initVO: any;

	/**
	 * Parameter which defined where the parameter object is stored in the child component so we can pass the configuration to it
	 */
	public configurationParameter: string;

	/**
	 * The configuration which which we want to pass through to the child component
	 */
	public configurationObject: Object;

	/**
	 * This is the title to display in this confirmation dialog.
	 */
	public title: string;

	/**
	 * Css Class for styling
	 */
	public wrapperCSSClass = "";

	/**
	 * These are the labels to be used on the confirm and cancel buttons.
	 */
	public confirmButtonLabel: string;
	public cancelButtonLabel: string;

	// Indicates if the dialog contents are valid and can be confirmed.
	public dialogValid = false;

	/**
	 * class to be used by the mat-dialog-content
	 */
	public matDialogContentClassName: string = undefined;

	/**
	 * Do we have a validation stream watching for if a form is valid or not
	 */
	private validationStream: BehaviorSubject<any> = undefined;

	/**
	 * Do we have a validation stream subscription
	 */
	private validationStreamSubscription: Subscription = undefined;

	/**
	 * The component wrapping our injected component.
	 */
	@ViewChild(ComponentDialogInjectedWrapperComponent)
	wrapper: ComponentDialogInjectedWrapperComponent;

	constructor(
		public readonly dialogRef: MatDialogRef<ComponentDialogComponent>
	) {}

	ngOnInit() {
		// Enable the OK button if we didnt specify a paramNameDialogValid (e.g. there isnt anything to validate)
		if (!this.paramNameDialogValid) {
			this.dialogValid = true;
		}
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	ngAfterViewChecked() {
		//We need to do some additional work as ng after view checked gets called after each view render

		// After initialisation we will watch the component wrapper for emitted booleans
		// to indicate if teh component it contains is valid for submission.
		if (this.paramNameDialogValid) {
			//Is this the a different observable stream as last time we subscribed
			//if so we may want to create a new subscription
			if (this.validationStream !== this.wrapper.dialogValid$) {
				//If we have a current subscription we will want to unsubscribe from it
				if (this.validationStreamSubscription) {
					this.validationStreamSubscription.unsubscribe();
				}

				//Set this as the validation stream
				this.validationStream = this.wrapper.dialogValid$;

				//Create a subscription to the new stream
				this.validationStreamSubscription = this.validationStream
					.pipe(
						takeUntil(componentDestroyStream(this)),
						debounceTime(50) //Stops spam but is also removing a changed after checked issue
					)
					.subscribe((valid) => (this.dialogValid = valid));
			}
		} else {
			//We have no property to watch so we default to always valid
			this.dialogValid = true;
		}
	}

	closeOK() {
		// Get the value out of the component held in the wrapper..
		// and Close the dialogue
		this.dialogRef.close(this.wrapper.getVO());
	}

	closeCancel() {
		//Close the dialogue
		this.dialogRef.close(undefined);
	}
}
