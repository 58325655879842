import {
	promiseMergeArray,
	ServiceAccounts,
	ServiceOptions,
} from "@harksolutions/ahub-web-services";
import { ExportDistributionIndexAHubVO } from "@harksolutions/ahub-web-services-types";
import { AHubService } from "app/services/ahub/ahub.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { ACatalogActions } from "../actions/acatalog.actions";
import { AHubActions } from "../actions/ahub.actions";
import {
	ActionNumber,
	ActionNumberArray,
} from "../actions/types/common.action-types";
import { AHubBaseEpic } from "./ahub-base.epics";
import { Epic } from "./epic";

/**
 * Class for the View epic functions
 */
export class AHubAccountsEpics extends AHubBaseEpic implements Epic {
	/**
	 * Web service class we will be using to make all our calls
	 */
	serviceAccounts: ServiceAccounts = undefined;

	constructor(public readonly aHubService: AHubService) {
		super(aHubService);

		const serviceOptions = new ServiceOptions(
			environment.aHubApi.domain,
			environment.aHubApi.basePath
		);

		serviceOptions.logRequest = true;

		//Create our service accounts with the base parameters for our requests
		this.serviceAccounts = new ServiceAccounts(serviceOptions);
	}

	/**
	 * Returns a list of the epic function avalible from this class
	 * If a new epic is added to the class it must be added to this list
	 */
	epicMethods(): any[] {
		return [
			//this.usersByIdsFetch,
			this.exportDistributionIndexesByPublicationIdFetch,
		];
	}

	usersByIdsFetch = (action$: Observable<ActionNumberArray>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.USERS_BY_IDS_FETCH),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataArrayToAction(
					promiseMergeArray(
						this.serviceAccounts.usersByIdsGet(
							this.reqOptSigned(),
							action.numbers
						)
					),
					action,
					AHubActions.usersSet
				)
			)
		);

	exportDistributionIndexesByPublicationIdFetch = (
		action$: Observable<ActionNumber>
	) =>
		action$.pipe(
			filter(
				({ type }) =>
					type ===
					AHubActions.EXPORT_DISTRIBUTION_INDEXES_BY_PUBLICATION_ID_FETCH
			),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceAccounts.exportDistributionIndexesByPublicationId(
						this.reqOptSigned(),
						action.number
					),
					action,
					(data: ExportDistributionIndexAHubVO[]) =>
						ACatalogActions.publicationExportDistributionIndexesSet(
							action.number,
							data
						)
				)
			)
		);
}
