import { ColDef } from "ag-grid-community";
import {
	GridRendererMaterialIconParams,
	GridRendererMaterialIconComponent,
} from "modules/common/components/ag-grid/renderer/grid-renderer-material-icon/grid-renderer-material-icon.component";
import { GridRendererTextCopyClipboardComponent } from "modules/common/components/ag-grid/renderer/grid-renderer-text-copy-clipboard/grid-renderer-text-copy-clipboard.component";
import { GridFilterSelectComponent } from "modules/common/components/ag-grid/filters/grid-filter-select/grid-filter-select.component";
import { GridRendererBooleanComponent } from "modules/common/components/ag-grid/renderer/grid-renderer-boolean/grid-renderer-boolean.component";
import { ProductPropertyAHubVO } from "valueObjects/ahub/library/product-property.ahub.vo";
import {
	GridFilterSelectOption,
	GridFilterSelectParams,
	GridFilterFloatingSelectParams,
} from "modules/common/components/ag-grid/filters/grid-filter-select-base";
import { GridFilterSelectFloatingComponent } from "modules/common/components/ag-grid/filters/grid-filter-select-floating/grid-filter-select-floating.component";
import {
	ProductPropertyGridColumnMatrixUtil,
	ProductPropertyColumnReadMatrixParameters,
} from "modules/common/components/ag-grid/product-grid/utils/product-property-gird-column-matrix-util";
import { GridRendererDateComponent } from "../../renderer/grid-renderer-date/grid-renderer-date.component";
import { IFilterOptionDef } from "ag-grid-community/dist/lib/interfaces/iFilter";
import { ProductPropertySectionAHubVO } from "app/valueObjects/ahub/library/product-property-section.ahub.vo";

/**
 * Util class for the product property grid
 */
export class ProductPropertyGridColumnReadUtil {
	/**
	 * Boolean filters for the boolean values
	 */
	private static readonly BOOLEAN_FILTER_OPTIONS: GridFilterSelectOption[] = [
		{ label: "All", value: "allNoFilter", activeFilter: false },
		{
			label: "True",
			value: "true",
			activeFilter: true,
			filterFunction: (row, val) => val === "1" || val === 1,
		},
		{
			label: "False",
			value: "false",
			activeFilter: true,
			filterFunction: (row, val) => val !== "1" && val !== 1,
		},
	];

	/**
	 * Static value which we will use for columns which dont have the property for the column
	 */
	public static readonly FILTER_INVALID_PROPERTY_VALUE = "NOT_VALID_FOR_COLUMN";

	/**
	 * Default blnak value filter
	 */
	public static readonly BLANK_VALUE_FILTER = (filterValue, cellValue) => {
		if (!cellValue) {
			return true;
		}
		return `${cellValue}`.trim().length === 0;
	};

	/**
	 * Default non blnak value filter
	 */
	public static readonly NON_BLANK_VALUE_FILTER = (filterValue, cellValue) => {
		if (cellValue) {
			return `${cellValue}`.trim().length > 0;
		}

		//Otherwise it must have some data
		return false;
	};

	/**
	 * Defines the shape of the common framework components shared between the main grid and child grids
	 */
	public static frameworkComponents() {
		return Object.assign(
			ProductPropertyGridColumnMatrixUtil.frameworkComponents(),
			{
				textClipboard: GridRendererTextCopyClipboardComponent,
				selectFilter: GridFilterSelectComponent,
				booleanRenderer: GridRendererBooleanComponent,
				matIconRenderer: GridRendererMaterialIconComponent,
				dateRenderer: GridRendererDateComponent,
			}
		);
	}

	/**
	 * Get a basic cell render with filters specificed for a property
	 *
	 * @param   Parameters for setting up the column
	 */
	public static columnReadFilterForProperty(
		params: ProductPropertyColumnReadParameters
	): ColDef {
		//Get the property it's out only required property which is used everywhere
		const property = params.property;

		//Get the property description
		const propertyDesc = property.description ? property.description : "";

		//Setup some default for the column, some will be updated later
		let colDef: ColDef = {
			headerName: property.label,
			filter: "agTextColumnFilter",
			width: 170,
			editable: false,
			filterParams: {
				newRowsAction: "keep",
				filterOptions: [
					"contains",
					"notContains",
					"equals",
					"notEqual",
					"startsWith",
					"endsWith",
					ProductPropertyGridColumnReadUtil.blankValueFilter(
						params.blankFilterFunction
					),
					ProductPropertyGridColumnReadUtil.notBlankValueFilter(
						params.notBlankFilterFunction
					),
				],
			},
			headerTooltip: `#${property.id} ${propertyDesc}`,
			cellRenderer: "textClipboard",
		};

		//Switch on each type to setup the property specific data
		switch (property.typeReference) {
			case "BOOLEAN":
				//Call the column defintion for the boolean setting it up for this type
				colDef = ProductPropertyGridColumnReadUtil.columnDefBoolean(colDef);
				break;

			case "DECIMAL":
			case "MEASUREMENT":
			case "INTEGER":
			case "VOLUME":
			case "WEIGHT":
				//Call the column defintion for the number setting it up for this type
				colDef = ProductPropertyGridColumnReadUtil.columnDefNumber(
					colDef,
					params
				);
				break;

			case "TOKENLIST":
			case "TEXTLIST":
				//Call the column defintion for the list setting it up for this type
				colDef = ProductPropertyGridColumnReadUtil.columnDefList(
					colDef,
					params
				);
				break;

			case "IMAGE":
			case "BLOB":
			case "GLB":
			case "VIDEO":
			case "FLICKBOOK":
			case "PDF":
				//Call the column defintion for the asset setting it up for this type
				colDef = ProductPropertyGridColumnReadUtil.columnDefAsset(
					colDef,
					params
				);
				break;

			case "DATE_TIME":
				//Call the column defintion for the date time setting it up for this type
				colDef = ProductPropertyGridColumnReadUtil.columnDefDateTime(
					colDef,
					params
				);

				break;

			default:
				break;
		}

		//If this isn't a matrix property and it has enums we will update the filters for this item
		//so we can update the filters for it
		if (
			!property.matrix &&
			property.enumOptions &&
			property.enumOptions.length > 0
		) {
			colDef = ProductPropertyGridColumnReadUtil.enumFilterSetup(
				colDef,
				params
			);
		}

		//Return the column definition
		return colDef;
	}

	/**
	 * Blank filter for this product property column
	 *
	 * @param filterValue      Value of the filter
	 * @param cellValue        Value of the cell
	 */
	public static productPropertyFilterBlankOrInvalid(
		filterValue,
		cellValue
	): boolean {
		//Check to see if this property it's correct for this product in this column. If it's
		//incorrect we will filter it out immediately
		if (
			cellValue &&
			cellValue.toString().toUpperCase() ===
				ProductPropertyGridColumnReadUtil.FILTER_INVALID_PROPERTY_VALUE
		) {
			return false;
		}

		//Use the default blank value filter to evaluate blankness
		return ProductPropertyGridColumnReadUtil.BLANK_VALUE_FILTER(
			filterValue,
			cellValue
		);
	}

	/**
	 * Non Blank filter for this product property column
	 *
	 * @param filterValue      Value of the filter
	 * @param cellValue        Value of the cell
	 */
	public static productPropertyFilterNotBlankOrInvalid(
		filterValue,
		cellValue
	): boolean {
		//Check to see if this property it's correct for this product in this column. If it's
		//incorrect we will filter it out immediately
		if (
			cellValue &&
			cellValue.toString().toUpperCase() ===
				ProductPropertyGridColumnReadUtil.FILTER_INVALID_PROPERTY_VALUE
		) {
			return false;
		}

		//Use the default non blank value filter to evaluate  its non blankness
		return ProductPropertyGridColumnReadUtil.NON_BLANK_VALUE_FILTER(
			filterValue,
			cellValue
		);
	}

	/**
	 * This function will create the header component for a product section and property.
	 *
	 * @param section                   The section we are displaying.
	 * @param property                  The property we are displaying.
	 */
	public static headerComponentParamsCreate(
		section: ProductPropertySectionAHubVO,
		property: ProductPropertyAHubVO
	) {
		return {
			template:
				`<div class="ag-cell-label-container" role="presentation">` +
				`   <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>` +
				`   <div ref="eLabel" class="ag-header-cell-label" role="presentation">` +
				`       <div class="flex-container column full-width">` +
				`          <span class="ag-header-cell-text text-medium bold notranslate" style="padding-bottom: 4px; overflow:hidden;" role="columnheader">${property.label}</span>` +
				`          <span class="ag-header-cell-label ag-header-cell-text" style="font-size: 0.9em; font-weight: lighter;">` +
				`               <span class="sectionIcon10" style="background-color:${section.colour}"></span>` +
				`               <span class="ag-header-cell-text" role="columnheader">${section.label}</span>` +
				`           </span>` +
				`       </div>` +
				`	    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>` +
				`	    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>` +
				`	    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>` +
				`	    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>` +
				`	    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>` +
				`	</div>` +
				`</div>`,
		};
	}

	/**
	 * Create a column definition for a product property which represents a matrix
	 *
	 * @param params            Parameters for the column renderer
	 *
	 * @return                  A column definition for a matrix
	 */
	public static columnReadFilterForPropertyMatrix(
		params: ProductPropertyColumnReadMatrixParameters
	): ColDef {
		return ProductPropertyGridColumnMatrixUtil.columnPropertyMatrix(params);
	}

	/**
	 * ----------------------------------------------------------
	 * Private fuctions
	 * ----------------------------------------------------------
	 */

	/**
	 * Setup the column definition for the boolean
	 *
	 *  @param colDef           Column to base it on
	 */
	private static columnDefBoolean(colDef: ColDef) {
		//Setup the column for the boolean
		colDef.cellRenderer = "booleanRenderer";
		colDef.filter = "selectFilter";
		colDef.filterParams = {
			valueOptions: ProductPropertyGridColumnReadUtil.BOOLEAN_FILTER_OPTIONS,
			defaultSelectedFilterValue:
				ProductPropertyGridColumnReadUtil.BOOLEAN_FILTER_OPTIONS[0].value,
		} as GridFilterSelectParams;
		colDef.floatingFilterComponentFramework = GridFilterSelectFloatingComponent;
		colDef.floatingFilterComponentParams = {
			suppressFilterButton: true,
			valueOptions: ProductPropertyGridColumnReadUtil.BOOLEAN_FILTER_OPTIONS,
			defaultSelectedFilterValue:
				ProductPropertyGridColumnReadUtil.BOOLEAN_FILTER_OPTIONS[0].value,
		} as GridFilterFloatingSelectParams;

		//Return the column definition
		return colDef;
	}

	/**
	 * Setup the column definition for the number
	 *
	 *  @param colDef           Column to base it on
	 *  @param params           Parameters for the column
	 */
	private static columnDefNumber(
		colDef: ColDef,
		params: ProductPropertyColumnReadParameters
	) {
		colDef.filter = "agNumberColumnFilter";
		colDef.filterParams = {
			newRowsAction: "keep",
			filterOptions: [
				"equals",
				"notEqual",
				"lessThan",
				"lessThanOrEqual",
				"greaterThan",
				"greaterThanOrEqual",
				"inRange",
				ProductPropertyGridColumnReadUtil.blankValueFilter(
					params.blankFilterFunction
				),
				ProductPropertyGridColumnReadUtil.notBlankValueFilter(
					params.notBlankFilterFunction
				),
			],
		};

		//We need to create a filter value getter for the number parameter so that
		//they are treated like numbers and not strings
		colDef.filterValueGetter = (params) => {
			//We will check if it has a value getter which is callable
			if (
				params.colDef.valueGetter &&
				params.colDef.valueGetter instanceof Function
			) {
				//It goes then get the value and we can try and convert it
				const value = params.colDef.valueGetter(params);

				//Try to retrun the value as a number
				const valueAsNumber = value ? Number(value) : undefined;

				//Return the final value
				return valueAsNumber && !isNaN(valueAsNumber)
					? valueAsNumber
					: undefined;
			}

			//If this wasn't the case we will return the presented
			return params;
		};

		//Add a numeric sort function
		colDef.comparator = (a, b) =>
			ProductPropertyGridColumnReadUtil.numericSortFunction(a, b);

		//Return the column definition
		return colDef;
	}

	/**
	 * Setup the column definition for the asset type
	 *
	 *  @param colDef           Column to base it on
	 *  @param params           Parameters for the column
	 */
	private static columnDefAsset(
		colDef: ColDef,
		params: ProductPropertyColumnReadParameters
	) {
		//Asset for the blob and the photo map
		const assetIcon = {
			BLOB: "redeem",
			GLB: "3d_rotation",
			IMAGE: "image",
			VIDEO: "videocam",
			FLICKBOOK: "collections",
			PDF: "picture_as_pdf",
		};

		//Set the values
		colDef.width = 110;
		colDef.cellRenderer = "matIconRenderer";
		colDef.cellRendererParams = {
			matIconGetFunc: (value: string) =>
				Number(value) > 0
					? assetIcon[params.property.typeReference]
					: undefined,
			iconLabelGetFunc: (value: string) => value,
		} as GridRendererMaterialIconParams;

		//Setup the asset filters, we are going to use a text filter for now as we only need to use the simple
		//non-numeric specific filter and this saves us converting our value strings to numbers
		colDef.filter = "agTextColumnFilter";
		colDef.filterParams = {
			newRowsAction: "keep",
			filterOptions: [
				"equals",
				"notEqual",
				ProductPropertyGridColumnReadUtil.blankValueFilter(
					params.blankFilterFunction
				),
				ProductPropertyGridColumnReadUtil.notBlankValueFilter(
					params.notBlankFilterFunction
				),
			],
		};

		//Add a numeric sort as all assets are numeric id's
		colDef.comparator = (a, b) =>
			ProductPropertyGridColumnReadUtil.numericSortFunction(a, b);

		//Return the column definition
		return colDef;
	}

	/**
	 * Setup the column definition for the asset type
	 *
	 *  @param colDef           Column to base it on
	 *  @param params           Parameters for the column
	 */
	private static columnDefDateTime(
		colDef: ColDef,
		params: ProductPropertyColumnReadParameters
	) {
		//Set the date picker as the prefered renderer
		colDef.cellRenderer = "dateRenderer";
		colDef.minWidth = 185;
		colDef.width = 185;
		colDef.filter = "agDateColumnFilter";
		colDef.filterParams = {
			newRowsAction: "keep",
			filterOptions: [
				"equals",
				"notEqual",
				"lessThan",
				"greaterThan",
				"inRange",
				ProductPropertyGridColumnReadUtil.blankValueFilter(
					params.blankFilterFunction
				),
				ProductPropertyGridColumnReadUtil.notBlankValueFilter(
					params.notBlankFilterFunction
				),
			],

			comparator: (filterLocalDateAtMidnight, cellValue) => {
				//No data return 0
				if (!cellValue || !filterLocalDateAtMidnight) {
					return 0;
				}

				//Epoch number
				const dateNumber = Number(cellValue);

				//No date number then we will return zero
				if (!dateNumber || isNaN(dateNumber.valueOf())) {
					return 0;
				}

				//Create a cell date
				const cellDate = new Date();
				cellDate.setTime(dateNumber.valueOf());
				cellDate.setHours(0);
				cellDate.setUTCMinutes(0);
				cellDate.setUTCSeconds(0);
				cellDate.setUTCMilliseconds(0);

				// Now that both parameters are Date objects, we can compare
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				} else if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}

				//Matches return zero
				return 0;
			},
		};

		//Return the column definition
		return colDef;
	}

	/**
	 * Setup the column definition for the text / token list
	 *
	 * @param colDef           Column to base it on
	 *  @param params           Parameters for the column
	 */
	private static columnDefList(
		colDef: ColDef,
		params: ProductPropertyColumnReadParameters
	): ColDef {
		//If we don't than any enums then we will use default sorting
		colDef.filter = "agTextColumnFilter";
		colDef.filterParams = {
			newRowsAction: "keep",
			filterOptions: [
				"contains",
				"notContains",
				ProductPropertyGridColumnReadUtil.blankValueFilter(
					params.blankFilterFunction
				),
				ProductPropertyGridColumnReadUtil.notBlankValueFilter(
					params.notBlankFilterFunction
				),
			],
		};

		return colDef;
	}

	/**
	 * Setup the filters for the enums
	 *
	 * @param colDef            Column to set them up for
	 *  @param params           Parameters for the column
	 */
	private static enumFilterSetup(
		colDef: ColDef,
		params: ProductPropertyColumnReadParameters
	): ColDef {
		//No column then return it straight back
		if (!colDef) {
			return colDef;
		}

		//Create a filter options list for the parameters, start with an emp
		const filterOptions: (string | IFilterOptionDef)[] = ["empty"];

		//Is this a list type property we need to handle them differently to others
		if (params.property.primitiveType === "LIST") {
			//Add in the list type enum filter for each value of the list & the invalid value filter
			params.property.enumOptions.forEach((option) =>
				filterOptions.push(
					ProductPropertyGridColumnReadUtil.enumValueListFilter(option)
				)
			);
			filterOptions.push(
				ProductPropertyGridColumnReadUtil.enumValueListFilterInvalid(
					params.property
				)
			);
		} else {
			//Add in the enum filter for each value of the list & the invalid value filter
			params.property.enumOptions.forEach((option) =>
				filterOptions.push(
					ProductPropertyGridColumnReadUtil.enumValueFilter(option)
				)
			);
			filterOptions.push(
				ProductPropertyGridColumnReadUtil.enumValueFilterInvalid(
					params.property
				)
			);
		}

		//Add in the blank / non blank options
		filterOptions.push(
			ProductPropertyGridColumnReadUtil.blankValueFilter(
				params.blankFilterFunction
			)
		);
		filterOptions.push(
			ProductPropertyGridColumnReadUtil.notBlankValueFilter(
				params.notBlankFilterFunction
			)
		);

		colDef.filterParams = {
			newRowsAction: "keep",
			filterOptions,
		};

		//Return the column definition
		return colDef;
	}

	/**
	 * Create a filter for the enum list value
	 */
	private static enumValueListFilter(value: string): IFilterOptionDef {
		return {
			displayKey: `Contains: ${value}`,
			displayName: `Contains: ${value}`,
			test: (filterValue, cellValue) => {
				if (!cellValue) {
					return false;
				}

				try {
					// Make sure the value we want to search for is lower case.
					const searchTerm = value.toLowerCase();

					// Convert the list of values in the cell into a lower case array.
					const items: string[] = JSON.parse(cellValue.toLowerCase());

					// Return true if we can find the search term in the items list.
					return items.indexOf(searchTerm) > -1;
				} catch (error) {
					//Doing nothing on error
				}

				return false;
			},
			hideFilterInput: true,
		} as IFilterOptionDef;
	}

	/**
	 * Create an invlid filter specifically for the enum list
	 *
	 * @param property
	 */
	private static enumValueListFilterInvalid(
		property: ProductPropertyAHubVO
	): IFilterOptionDef {
		return {
			displayKey: `Invalid Values`,
			displayName: `Invalid Values`,
			test: (filterValue, cellValue) => {
				if (!cellValue) {
					return false;
				}
				try {
					//Convert the value into an array if any of the values don't exist in the enums it's invalid
					const items: string[] = JSON.parse(cellValue.toLowerCase());
					return (
						items.find(
							(item) =>
								property.enumOptions.find(
									(option) => option.toLowerCase() === item
								) === undefined
						) !== undefined
					);
				} catch (error) {
					//Doing nothing on error
				}

				return false;
			},
			hideFilterInput: true,
		} as IFilterOptionDef;
	}

	/**
	 * Create a filter for the enum options of the list
	 */
	private static enumValueFilter(value: string): IFilterOptionDef {
		//NOTE: Using Blank as empty is a reserved filter type in ag-grid
		//      It's used to denote no filter set! This also matches excel

		return {
			displayKey: `Value: ${value}`,
			displayName: `Value: ${value}`,
			test: (filterValue, cellValue) => {
				if (!cellValue) {
					return false;
				}

				return cellValue.toLowerCase() === value.toLowerCase();
			},
			hideFilterInput: true,
		} as IFilterOptionDef;
	}

	/**
	 * Create an invalid value filter for the enums
	 *
	 * @param property      Property which this is for
	 */
	private static enumValueFilterInvalid(
		property: ProductPropertyAHubVO
	): IFilterOptionDef {
		return {
			displayKey: `Invalid Values`,
			displayName: `Invalid Values`,
			test: (filterValue, cellValue) => {
				if (!cellValue) {
					return false;
				}
				return (
					property.enumOptions.find(
						(option) => option.toLowerCase() === cellValue.toLowerCase()
					) === undefined
				);
			},
			hideFilterInput: true,
		} as IFilterOptionDef;
	}

	/**
	 * Create a blank filter value for our dropdowns
	 *
	 * @param blankFilterFunction   Custom test function for the blank filter
	 */
	private static blankValueFilter(
		blankFilterFunction: (filterValue: any, cellValue: any) => boolean
	): IFilterOptionDef {
		//NOTE: Using Blank as empty is a reserved filter type in ag-grid
		//      It's used to denote no filter set! This also matches excel

		return {
			displayKey: "Blank",
			displayName: "Blank",
			test: blankFilterFunction
				? blankFilterFunction
				: ProductPropertyGridColumnReadUtil.BLANK_VALUE_FILTER,
			hideFilterInput: true,
		} as IFilterOptionDef;
	}

	/**
	 * Create a not blank filter value for our dropdowns
	 *
	 * @param nonBlankFilterFunction      Custom test function for the non blank filter
	 */
	private static notBlankValueFilter(
		nonBlankFilterFunction: (filterValue: any, cellValue: any) => boolean
	): IFilterOptionDef {
		//NOTE: Using Blank as empty is a reserved filter type in ag-grid
		//      It's used to denote no filter set! This also matches excel

		return {
			displayKey: "Not Blank",
			displayName: "Not Blank",
			test: nonBlankFilterFunction
				? nonBlankFilterFunction
				: ProductPropertyGridColumnReadUtil.NON_BLANK_VALUE_FILTER,
			hideFilterInput: true,
		} as IFilterOptionDef;
	}

	/**
	 * Numeric sort function wich we can use to sort numeric data
	 *
	 * @param a  First number
	 * @param b  Second number
	 */
	private static numericSortFunction(a, b) {
		const aNum = Number(a);
		const bNum = Number(b);

		//Work out for invalid values
		if ((!aNum || isNaN(aNum.valueOf())) && (!bNum || isNaN(bNum.valueOf()))) {
			return 0;
		} else if (!aNum || isNaN(aNum.valueOf())) {
			return -1;
		} else if (!bNum || isNaN(bNum.valueOf())) {
			return 1;
		}

		//Work out the comparisson
		return aNum.valueOf() - bNum.valueOf();
	}
}

export interface ProductPropertyColumnReadParameters {
	property: ProductPropertyAHubVO;
	blankFilterFunction?: (filterValue: any, cellValue: any) => boolean;
	notBlankFilterFunction?: (filterValue: any, cellValue: any) => boolean;
}
