import { Action, Reducer } from "redux";
import { createReducer } from "reducers/reducer-util";
import { tassign } from "modules/common/type-assign.util";

/**
 * Action Types
 */
import { ActionNumber } from "actions/types/common.action-types";

/**
 * Actions
 */
import { ViewActions } from "actions/view.actions";
import { DistributionGroupIndexAHubVO } from "app/valueObjects/ahub/accounts/distribution-group-index.ahub.vo";
import { ActionDistributionGroupIndexAHubVOs } from "app/store/actions/types/ahub-accounts.action-types";

/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ViewAccountManagementState {
	selectedClientId: number;
	selectedUserId: number;
	selectedUserDistributionGroupIndexes: DistributionGroupIndexAHubVO[];
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewAccountManagementInitialState: ViewAccountManagementState = {
	selectedClientId: -1,
	selectedUserId: -1,
	selectedUserDistributionGroupIndexes: null,
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the selected client id
 */
export const selectedClientIdSet = (
	state: ViewAccountManagementState,
	action: ActionNumber
) => {
	//Set the selected client id
	return tassign(state, { selectedClientId: action.number });
};

/**
 * Set the selected user id.
 */
export const selectedUserIdSet = (
	state: ViewAccountManagementState,
	action: ActionNumber
) => {
	// Set the selected user id.
	return tassign(state, { selectedUserId: action.number });
};

/**
 * Set the distribution group indexes object for the selected user
 */
const selectedUserDistributionGroupIndexesSet = (
	state: ViewAccountManagementState,
	action: ActionDistributionGroupIndexAHubVOs
) => {
	//Set the distribution group indexes
	return tassign(state, {
		selectedUserDistributionGroupIndexes: action.distributionGroupIndexs,
	});
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer
 */
reducerHandlers[ViewActions.ACCOUNT_MANAGEMENT_CLIENT_SELECTED_ID_SET] =
	selectedClientIdSet;
reducerHandlers[ViewActions.ACCOUNT_MANAGEMENT_USER_SELECTED_ID_SET] =
	selectedUserIdSet;
reducerHandlers[ViewActions.SELECTED_USER_DISTRIBUTION_GROUP_INDEXS_SET] =
	selectedUserDistributionGroupIndexesSet;
/**
 * Create a reducers based on the reducers handlers
 */
export const ViewAccountManagementStateReducer: Reducer<ViewAccountManagementState> =
	createReducer(ViewAccountManagementInitialState, reducerHandlers);

/**
 * Check if this reducer can handle the function specified
 */
export const ViewAccountManagementStateHasHandler = (
	actionType: string
): boolean => reducerHandlers.hasOwnProperty(actionType);
