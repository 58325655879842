import { Component, OnInit } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { noWhitespaceValidator } from "app/modules/common/directives/validators/no-whitespace-validator.directive";
import {
	componentDestroyStream,
	Hark,
} from "app/modules/common/hark.decorator";
import { Utils } from "app/modules/common/utils";
import { PropertyAViewVO } from "app/modules/routes/aview/valueObjects/property.aview.vo";
import { viewPropertyIconMap } from "app/store/selector/view/view-library-classification-class.selector";
import { StoreAccess } from "app/store/store-access";
import { ProductFilterTestAHubVO } from "app/valueObjects/ahub/library/product-filter-test.ahub.vo";
import { ProductPropertyAHubVO } from "app/valueObjects/ahub/library/product-property.ahub.vo";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, filter, map, takeUntil } from "rxjs/operators";

const OPERATORS_BY_TYPE = {
	BOOLEAN: ["EQUALS", "NOT_EQUALS", "BLANK", "NOT_BLANK"],
	DECIMAL: [
		"EQUALS",
		"NOT_EQUALS",
		"LESS_OR_EQUAL",
		"GREATER_OR_EQUAL",
		"BLANK",
		"NOT_BLANK",
	],
	DATE_TIME: [
		"EQUALS",
		"NOT_EQUALS",
		"LESS_OR_EQUAL",
		"GREATER_OR_EQUAL",
		"BLANK",
		"NOT_BLANK",
	],
	GENDER: ["EQUALS", "NOT_EQUALS", "BLANK", "NOT_BLANK"],
	HTML: ["EQUALS", "NOT_EQUALS", "LIKE", "BLANK", "NOT_BLANK"],
	INTEGER: [
		"EQUALS",
		"NOT_EQUALS",
		"LESS_OR_EQUAL",
		"GREATER_OR_EQUAL",
		"BLANK",
		"NOT_BLANK",
	],
	PARAGRAPH: ["EQUALS", "NOT_EQUALS", "LIKE", "BLANK", "NOT_BLANK"],
	RGB: ["EQUALS", "NOT_EQUALS", "BLANK", "NOT_BLANK"],
	TEXTBLOCK: ["EQUALS", "NOT_EQUALS", "LIKE", "BLANK", "NOT_BLANK"],
	TEXTLINE: ["EQUALS", "NOT_EQUALS", "LIKE", "BLANK", "NOT_BLANK"],
	TEXTLIST: ["CONTAINS", "NOT_CONTAINS", "BLANK", "NOT_BLANK"],
	TOKEN: ["EQUALS", "NOT_EQUALS", "LIKE", "BLANK", "NOT_BLANK"],
	TOKENLIST: ["CONTAINS", "NOT_CONTAINS", "BLANK", "NOT_BLANK"],
	VOLUME: [
		"EQUALS",
		"NOT_EQUALS",
		"LESS_OR_EQUAL",
		"GREATER_OR_EQUAL",
		"BLANK",
		"NOT_BLANK",
	],
	WEIGHT: [
		"EQUALS",
		"NOT_EQUALS",
		"LESS_OR_EQUAL",
		"GREATER_OR_EQUAL",
		"BLANK",
		"NOT_BLANK",
	],
	WORD: ["EQUALS", "NOT_EQUALS", "LIKE", "BLANK", "NOT_BLANK"],
	IMAGE: ["EQUALS", "NOT_EQUALS", "BLANK", "NOT_BLANK"],
	MEASUREMENT: [
		"EQUALS",
		"NOT_EQUALS",
		"LESS_OR_EQUAL",
		"GREATER_OR_EQUAL",
		"BLANK",
		"NOT_BLANK",
	],
	AREA_DENSITY: [
		"EQUALS",
		"NOT_EQUALS",
		"LESS_OR_EQUAL",
		"GREATER_OR_EQUAL",
		"BLANK",
		"NOT_BLANK",
	],
	BLOB: ["EQUALS", "NOT_EQUALS", "BLANK", "NOT_BLANK"],
};

@Component({
	selector: "app-aview-distribution-property-filter-dialog",
	templateUrl: "./aview-distribution-product-filter-test-dialog.component.html",
	styleUrls: ["./aview-distribution-product-filter-test-dialog.component.css"],
})
@Hark()
export class AviewDistributionProductFilterTestDialogComponent
	implements OnInit
{
	/**** REQUIRED FOR USE IN A DIALOGUE  ****/
	/**
	 * The VO that is passed in / out.
	 */
	public dialogVO: ProductFilterTestDialogVO = null;

	properties: PropertyAViewVO[] = [];

	operators: string[] = [];

	values: string[] = [];

	propertyTypeReference: string = undefined;
	/**
	 * Boolean stream should emit values to indicate whether dialog is valid.
	 */
	public dialogIsValid$: Subject<boolean> = new Subject<boolean>();

	/**
	 * Form to control how the list is dispayed on screen
	 */
	propertyTestForm: UntypedFormGroup = this.formBuilder.group({
		propertyId: ["", Validators.required],
		operator: ["", Validators.required],
		value: ["", [Validators.required, noWhitespaceValidator()]],
	});

	propertyIconMap = StoreAccess.dataGet(viewPropertyIconMap);

	/**
	 * Used to return the value of each 'select-with-search' option we should use for searching
	 */
	propertyLabelValue = (option: ProductPropertyAHubVO) => {
		return option.label;
	};

	/**
	 * Used to determine which value of the option object should be applied if an option is selected
	 */
	propertyIdValue = (option: ProductPropertyAHubVO) => {
		return option.id;
	};
	operatorIsBlankOrNotBlank$: BehaviorSubject<boolean> = new BehaviorSubject(
		true
	);

	constructor(private formBuilder: UntypedFormBuilder) {}

	ngOnInit() {
		this.properties = this.dialogVO.properties
			?.filter((property) => {
				return (
					property.primitiveType !== "TABLE" &&
					property.primitiveType !== "ASSET" &&
					!property.matrix
				);
			})
			.sort((a, b) => (a.label > b.label ? 1 : a.label === b.label ? 0 : -1));

		// Make sure the properties list at least an empty array.
		if (!this.properties) {
			this.properties = [];
		}

		this.propertyTestForm.controls.propertyId.valueChanges
			.pipe(
				debounceTime(200),
				Utils.isNotNullOrUndefined(),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((propertyId) => {
				const property = this.properties.find(
					(property) => property.id === propertyId
				);
				this.values = [];
				this.operators = this.setOperatorsAppropriateToProperty(property);
				this.propertyTypeReference = property.typeReference;
				switch (this.propertyTypeReference) {
					case "BOOLEAN":
						this.values = ["1", "0"];
						break;
					case "GENDER":
						this.values = ["M", "W", "U", "B", "G", "Y", "C", "I"];
						break;

					default:
						break;
				}

				if (
					property &&
					property.enumOptions &&
					property.enumOptions.length > 0
				) {
					this.values = property.enumOptions;
				}
			});

		this.propertyTestForm.valueChanges
			.pipe(debounceTime(200), takeUntil(componentDestroyStream(this)))
			.subscribe((property) => {
				this.dialogIsValid$.next(this.propertyTestForm.valid);

				if (this.propertyTestForm.valid) {
					this.dialogVO.propertyTest = this.propertyTestForm.value;
				}
			});

		// This will allow us to find the 'value' part of the form if the selected operator is BLANK or NOT_BLANK
		this.propertyTestForm.controls.operator.valueChanges
			.pipe()
			.subscribe((operator) => {
				const operatorIsBlankOrNotBlank =
					operator === "BLANK" || operator === "NOT_BLANK";

				// Hiding the vlaue part of the form so we no longer need it to be 'required' (for validation)
				if (operatorIsBlankOrNotBlank) {
					this.propertyTestForm.controls.value.clearValidators();
				} else {
					this.propertyTestForm.controls.value.setValidators(
						Validators.required
					);
				}

				this.propertyTestForm.controls.value.updateValueAndValidity();

				this.operatorIsBlankOrNotBlank$.next(
					operator === "BLANK" || operator === "NOT_BLANK"
				);
			});

		this.operatorIsBlankOrNotBlank$
			.pipe(
				Utils.isNotNullOrUndefined(),
				filter((operatorBlankOrNotBlank) => operatorBlankOrNotBlank === true)
			)
			.subscribe((clearFilterValue) => {
				this.propertyTestForm.controls.value.setValue(undefined);
			});

		if (this.dialogVO.propertyTest) {
			this.propertyTestForm.reset(this.dialogVO.propertyTest);
		}
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	setOperatorsAppropriateToProperty(property) {
		if (property) {
			return OPERATORS_BY_TYPE[property.typeReference];
		}
	}
}

export interface ProductFilterTestDialogVO {
	properties: PropertyAViewVO[];
	propertyTest?: ProductFilterTestAHubVO;
}
