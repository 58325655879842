import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import { selectedRouteView } from "app/store/selector/view/view-routes.selector";
import { StoreAccess } from "app/store/store-access";
import { RouteViewVO } from "app/valueObjects/view/route.view.vo";
import { BehaviorSubject, map, Observable } from "rxjs";

@Component({
	selector: "app-sidenav-route",
	templateUrl: "./sidenav-route.component.html",
	styleUrls: ["./sidenav-route.component.css"],
})
export class SidenavRouteComponent implements OnInit {
	@Input() routes: RouteViewVO[];

	@Input() depth: number;
	@Output() closeNav = new EventEmitter();

	selectedRouteView$: Observable<RouteViewVO> =
		StoreAccess.dataGetObvs(selectedRouteView);

	selectedRouteViewLink$: Observable<string> = this.selectedRouteView$.pipe(
		map((route) => (route && route.routerLink ? route.routerLink : ""))
	);

	constructor(private changeDetector: ChangeDetectorRef) {
		// This is intentional
	}

	ngOnInit() {
		this.changeDetector.detach();
		setInterval(() => {
			this.changeDetector.reattach();
			this.changeDetector.detectChanges();
			this.changeDetector.detach();
		}, 1000);
	}

	subRouteCheck(route: RouteViewVO): boolean {
		return route && route.routes && route.routes.length > 0;
	}

	selectedRouteCheck(selectedRouteLink: string, route: RouteViewVO): boolean {
		return (
			route &&
			route.routerLink &&
			selectedRouteLink &&
			selectedRouteLink.includes(route.routerLink)
		);
	}

	closeNavEmit(event) {
		this.closeNav.emit();
	}

	closeNavClick(event) {
		this.closeNav.emit();
	}
}
