import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { AppActions } from "actions/app.actions";
import { StoreAccess } from "store/store-access";
import { formatDate } from "@angular/common";
import * as moment from "moment";

@Component({
	selector: "app-grid-renderer-date",
	templateUrl: "./grid-renderer-date.component.html",
	styleUrls: ["./grid-renderer-date.component.css"],
})
export class GridRendererDateComponent implements AgRendererComponent {
	/**
	 * Parameters for the render
	 */
	params: GridRendererDateComponentParams;

	/**
	 * Value which we want to display
	 */
	valueToDisplay: any;

	/**
	 * Display the copy button or not
	 */
	displayCopyButton = false;

	constructor() {
		// This is intentional
	}

	/**
	 * Call after the init function
	 */
	agInit(params: GridRendererDateComponentParams): void {
		//Set the parameters for the render
		this.params = params;

		//Set the date format!
		this.params.dateFormat = this.params.dateFormat
			? this.params.dateFormat
			: "dd/MM/yyyy";
		this.params.copyFormatMoment = this.params.copyFormatMoment
			? this.params.copyFormatMoment
			: "DD/MM/YYYY";

		//Update the value to reflect the value of the cell
		this.updateValue();
	}

	/**
	 * Refresh the renderer
	 */
	refresh(params: GridRendererDateComponentParams): boolean {
		//Set the new set of parameters
		this.params = params;

		//Update the value to reflect the value of the cell
		this.updateValue();

		return true;
	}

	/**
	 * Update the value we are going to display
	 */
	updateValue() {
		//Set the value to display
		this.valueToDisplay = this.params.getValue
			? this.params.getValue()
			: this.params.value;
	}

	isValidTimestamp() {
		return this.isNumeric(this.valueToDisplay);
	}

	isNumeric(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	/**
	 * Function to be called if we want to display the copy button
	 */
	displayCopyButtonHandler(display) {
		//No value then we will not display it
		if (!this.valueToDisplay || this.valueToDisplay === "0") {
			display = false;
		}

		//Display the copy button
		this.displayCopyButton = display;
	}

	/**
	 * Copy the value to the clipboad
	 */
	copyValueToClipboard(event) {
		//Format the date ready for copying
		const dateFormatted = moment(Number(this.valueToDisplay).valueOf()).format(
			this.params.copyFormatMoment
		);

		//Distpatch a copy to the clipboard action
		StoreAccess.dispatch(AppActions.clipboardTextCopySet(dateFormatted));

		//Stop the event propergation
		event.stopPropagation();
	}
}

export interface GridRendererDateComponentParams extends ICellRendererParams {
	dateFormat?: string;
	copyFormatMoment?: string;
}
