import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "app-property-filter-rule-index",
	templateUrl: "./property-filter-rule-index.component.html",
	styleUrls: ["./property-filter-rule-index.component.css"],
})
export class PropertyFilterRuleIndexComponent implements OnInit {
	constructor() {
		// This is intentional
	}

	@Input() public propertyFilterRuleVO: any = null;

	ngOnInit() {
		// This is intentional
	}

	assetFileMatcherTypeTitle(type: string) {
		let title = "";
		switch (type) {
			case "ENDS_WITH":
				title = "Ends with";
				break;
			case "ENDS_WITH_WITHOUT_EXTENSION":
				title = "Ends With (Ignoring file extension)";
				break;
			case "CONTAINS":
				title = "Contains";
				break;
			case "STARTS_WITH":
				title = "Starts With";
				break;
			case "PRODUCT_IDENTIFIER_RULE_EXACT":
				title = " Matches Product Identifier";
				break;
			case "REGEX":
				title = "Regular Expression (REGEX)";
				break;
			default:
				break;
		}
		return title;
	}
}
