import { Observable } from "rxjs";
import { filter } from "rxjs/operators";

export class Utils {
	/**
    *   Returns a deep copy of the supplied object. Primative types will be returned untouched. 
        This function may have problems with cyclic objects, but who wouldnt.
    */
	public static clone(obj) {
		let copy;

		// Handle the 3 simple types, and null or undefined
		if (null == obj || "object" !== typeof obj) {
			return obj;
		}
		// Handle Date
		if (obj instanceof Date) {
			copy = new Date();
			copy.setTime(obj.getTime());
			return copy;
		}

		// Handle Array
		if (obj instanceof Array) {
			copy = [];
			for (var i = 0, len = obj.length; i < len; i++) {
				copy[i] = this.clone(obj[i]);
			}
			return copy;
		}

		// Handle Object
		if (obj instanceof Object) {
			copy = {};
			for (var attr in obj) {
				if (obj.hasOwnProperty(attr)) {
					copy[attr] = this.clone(obj[attr]);
				}
			}
			return copy;
		}

		throw new Error("Unable to copy obj! Its type isn't supported.");
	}

	public static inputIsNotNullOrUndefined<T>(
		input: null | undefined | T
	): input is T {
		return input !== null && input !== undefined;
	}

	public static isNotNullOrUndefined<T>() {
		return (source$: Observable<null | undefined | T>) =>
			source$.pipe(filter((source) => this.inputIsNotNullOrUndefined(source)));
	}

	public static isNotNullOrUndefinedOrEmpty<T>() {
		return (source$: Observable<null | undefined | T[]>) =>
			source$.pipe(
				filter((source) => this.inputIsNotNullOrUndefined(source)),
				filter((source) => source.length > 0)
			);
	}

	public static compareUnorderedArrays<T>(
		a: T[],
		b: T[],
		objProperty?: string
	): boolean {
		if (a.length !== b.length) {
			return false;
		}

		if (objProperty) {
			a = a.map((item) => item[objProperty]);
			b = b.map((item) => item[objProperty]);
		}

		a = a.sort((x, y) => (x > y ? 1 : -1));
		b = b.sort((x, y) => (x > y ? 1 : -1));

		return a.every((item, index) => {
			return item === b[index];
		});
	}

	public static compareOrderedArrays<T>(
		a: T[],
		b: T[],
		objProperty?: string
	): boolean {
		if (a.length !== b.length) {
			return false;
		}

		if (objProperty) {
			a = a.map((item) => item[objProperty]);
			b = b.map((item) => item[objProperty]);
		}

		return a.every((item, index) => item === b[index]);
	}
}
