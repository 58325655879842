import { Action, Reducer } from "redux";
import { createReducer } from "reducers/reducer-util";
import { tassign } from "modules/common/type-assign.util";

/**
 * Action Types
 */
import { ActionNumber } from "actions/types/common.action-types";

/**
 * Actions
 */
import { ViewActions } from "actions/view.actions";

/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ViewClientLibraryVersionState {
	selectedClientLibraryVersion: number;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewClientLibraryVersionInitialState: ViewClientLibraryVersionState =
	{
		selectedClientLibraryVersion: -1,
	};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the selected client library version id
 */
export const selectedClientLibraryVersionSet = (
	state: ViewClientLibraryVersionState,
	action: ActionNumber
) => {
	//Set the selected client library version id
	return tassign(state, { selectedClientLibraryVersion: action.number });
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer
 */
reducerHandlers[ViewActions.CLIENT_LIBRARY_VERSION_SELECTED_SET] =
	selectedClientLibraryVersionSet;

/**
 * Create a reducers based on the reducers handlers
 */
export const ViewClientLibraryVersionStateReducer: Reducer<ViewClientLibraryVersionState> =
	createReducer(ViewClientLibraryVersionInitialState, reducerHandlers);

/**
 * Check if this reducer can handle the function specified
 */
export const ViewClientLibraryVersionStateHasHandler = (
	actionType: string
): boolean => reducerHandlers.hasOwnProperty(actionType);
