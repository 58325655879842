import { Component, OnInit } from "@angular/core";
import { ComponentDialogComponent } from "app/modules/common/dialogs/component-dialog/component-dialog.component";
import { StoreAccess } from "app/store/store-access";
import { sessionClientConfig } from "app/store/selector/app.selector";
import { ClientConfigurationAHubVO } from "app/valueObjects/ahub/accounts/client-configuration.ahub.vo";
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { AppActions } from "app/store/actions/app.actions";
import { Utils } from "app/modules/common/utils";

@Component({
	selector: "app-add-permitted-domain-dialog",
	templateUrl: "./add-permitted-domain-dialog.component.html",
	styleUrls: ["./add-permitted-domain-dialog.component.css"],
})
export class AddPermittedDomainDialogComponent implements OnInit {
	dialogVO: any = null;

	currentClientConfig$: Observable<ClientConfigurationAHubVO> =
		StoreAccess.dataGetObvs(sessionClientConfig);

	permittedInclusionListDomainsForm: UntypedFormGroup = this.formBuilder.group({
		permittedInclusionListDomains: [],
	});

	constructor(private readonly formBuilder: UntypedFormBuilder) {}

	ngOnInit() {
		StoreAccess.dispatch(AppActions.sessionClientConfigFetch());

		this.permittedInclusionListDomainsForm.controls.permittedInclusionListDomains.valueChanges
			.pipe(Utils.isNotNullOrUndefined())
			.subscribe((permittedInclusionListDomainSelected) => {
				this.dialogVO = permittedInclusionListDomainSelected;
			});
	}
}
