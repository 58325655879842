import { Action, Reducer } from "redux";
import { createReducer } from "reducers/reducer-util";
import { tassign } from "modules/common/type-assign.util";

/**
 * Action Types
 */
import { ActionObject } from "actions/types/common.action-types";

/**
 * Actions
 */
import { ViewActions } from "actions/view.actions";
import { ActionWork } from "actions/types/work.action-types";

/**
 * ----------------------------------
 * App State interface
 * ----------------------------------
 */
export interface ViewDragState {
	draggedObject: any;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewDragInitialState: ViewDragState = {
	draggedObject: null,
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the dragged object.
 */
export const draggedObjectSet = (
	state: ViewDragState,
	action: ActionObject
) => {
	// Set the currently dragged object.
	return tassign(state, { draggedObject: action.object });
};

/**
 * This will clear the currently dragged object.
 *
 * @param state                 The state to change.
 * @param action                The action doing the work.
 */
export const draggedObjectClear = (
	state: ViewDragState,
	action: ActionWork
) => {
	// Reset the dragged object.
	return tassign(state, { draggedObject: undefined });
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to
 */
reducerHandlers[ViewActions.DRAGGED_OBJECT_SET] = draggedObjectSet;
reducerHandlers[ViewActions.DRAGGED_OBJECT_CLEAR] = draggedObjectClear;

/**
 * Create a reducers based on the reducers handlers
 */
export const ViewDragStateReducer: Reducer<ViewDragState> = createReducer(
	ViewDragInitialState,
	reducerHandlers
);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewDragStateHasHandler = (actionType: string): boolean =>
	reducerHandlers.hasOwnProperty(actionType);
