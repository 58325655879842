import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { SanitzedReturnType } from "app/valueObjects/types/sanitized-return.type";

@Pipe({
	name: "trustedContent",
})
export class TrustedContentPipe implements PipeTransform {
	constructor(protected sanitizer: DomSanitizer) {}

	public transform(value: any, type: string): SanitzedReturnType {
		switch (type) {
			case "html":
				return this.sanitizer.bypassSecurityTrustHtml(value);
			case "style":
				return this.sanitizer.bypassSecurityTrustStyle(value);
			case "script":
				return this.sanitizer.bypassSecurityTrustScript(value);
			case "url":
				return this.sanitizer.bypassSecurityTrustUrl(value);
			case "resourceUrl":
				return this.sanitizer.bypassSecurityTrustResourceUrl(value);
			default:
				throw new Error(`Invalid safe type specified: ${type}`);
		}
	}
}
