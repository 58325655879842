import { delay, filter, map } from "rxjs/operators";
import {
	Component,
	OnInit,
	Input,
	ComponentFactory,
	ComponentFactoryResolver,
	EventEmitter,
	Output,
} from "@angular/core";

import { NotificationDetailDialogComponent } from "../notification-detail-dialog/notification-detail-dialog.component";

/**
 * Value Objects
 */
import {
	NotificationRecordVO,
	NotificationObjectRequestActionStatusVO,
	NotificationObjectWorkflow,
	NotificationObjectGeneral,
} from "valueObjects/app/notification-record.vo";

/* Services */
import { DialogService } from "modules/common/dialogs/dialog.service";
import { combineLatest, Observable, of } from "rxjs";
import { RequestActionMonitorService } from "services/request-action-monitor/request-action-monitor.service";
import { RequestActionStatusUploadVO } from "valueObjects/app/request-action-status-upload.vo";
import { aHubStatePermanentUsers } from "app/store/selector/ahub/ahub-permanent.selector";
import { sessionUserId } from "app/store/selector/app.selector";
import { StoreAccess } from "app/store/store-access";
import { UserAHubVO } from "app/valueObjects/ahub/accounts/user.ahub.vo";
import { AppActions } from "app/store/actions/app.actions";

@Component({
	selector: "app-notification-record",
	templateUrl: "./notification-record.component.html",
	styleUrls: ["./notification-record.component.scss"],
})
export class NotificationRecordComponent implements OnInit {
	// The Value object to be rendered.
	@Input() notificationRecord: NotificationRecordVO;

	// If the notification record type is action , then we will cast its payload here.
	requestActionStatus: NotificationObjectRequestActionStatusVO;

	// If the notification record type is workflow , then we will cast its payload here.
	workflowStatus: NotificationObjectWorkflow;

	// If the notification record type is general , then we will cast its payload here.
	notificationDetail: NotificationObjectGeneral;

	@Output() complete: EventEmitter<void> = new EventEmitter<void>();

	//Main upload stream
	notificationUploadStream$: Observable<RequestActionStatusUploadVO> =
		of(undefined);
	notificationUploadCurrentDataStream$: Observable<{
		objectName: string;
		progress: number;
	}> = of(undefined);
	notificationUploadDataTotal$: Observable<number> = of(0);
	notificationUploadDataComplete$: Observable<number> = of(0);

	/**
	 * This is the current session user.
	 */
	sessionUser$: Observable<UserAHubVO> = combineLatest([
		StoreAccess.dataGetObvs(sessionUserId).pipe(
			filter((userId) => userId !== undefined)
		),
		StoreAccess.dataGetObvs(aHubStatePermanentUsers),
	]).pipe(
		delay(0),
		map(([userId, users]) => users.find((user) => user.id === userId))
	);

	/**
	 * General booleans for UX UI elements
	 */
	activeToggle = false;
	showQueueIcon = false;

	constructor(
		private dialogService: DialogService,
		private resolver: ComponentFactoryResolver,
		private requestActionMonitor: RequestActionMonitorService
	) {}

	ngOnInit() {
		// Lets handle notification labels which contain dot seperated exception paths
		// (e.g. com.fasterxml.jackson.databind.JsonMappingException) so that we text wrap sensibly
		this.notificationRecord.notificationLabel =
			this.notificationRecord.notificationLabel.replace(/\./g, ".<wbr>");

		// Depending on the type of notification, we'll cast the notificationObject payload, to make it easy to get properties.
		switch (this.notificationRecord.notificationType) {
			case "Action": {
				this.requestActionStatus = <NotificationObjectRequestActionStatusVO>(
					this.notificationRecord.notificationObject
				);
				break;
			}

			case "Workflow": {
				this.workflowStatus = this.notificationRecord
					.notificationObject as NotificationObjectWorkflow;

				//Get the upload for this worklog, we may not have one in which case the stream will not fire. Also construct the current upload stream
				this.notificationUploadStream$ = this.requestActionMonitor
					.requestActionStatusUploadObservableByWF(
						this.workflowStatus.workflowExecutionId
					)
					.pipe(filter((upload) => upload != undefined));
				this.notificationUploadCurrentDataStream$ =
					this.notificationUploadStream$.pipe(
						map((upload) => {
							return upload.uploadData
								? upload.uploadData
										.sort((a, b) =>
											a.priority == b.priority
												? 0
												: a.priority > b.priority
												? -1
												: 1
										)
										.find((a) => a.progress < 100 || a.progress == undefined)
								: undefined;
						}),
						map((upload) => {
							return upload
								? {
										objectName: upload.uploadPath,
										progress:
											upload.progress == undefined ? 0 : upload.progress,
								  }
								: undefined;
						})
					);

				// Create a list of how many uploads we have
				this.notificationUploadDataTotal$ = this.notificationUploadStream$.pipe(
					filter((upload) => upload !== undefined),
					map((upload) => {
						const files = upload.uploadData.filter((uploadedFile) => {
							return uploadedFile.uploadPath !== "asset-manifest.json";
						});
						return files ? files.length : 0;
					})
				);

				//How many are complete
				this.notificationUploadDataComplete$ =
					this.notificationUploadStream$.pipe(
						filter((upload) => upload != undefined),
						map((upload) =>
							upload.uploadData
								? upload.uploadData.filter((o) => o.progress == 100).length
								: 0
						)
					);

				// Show queue icon when no start time
				if (this.notificationRecord.notificationObject.worklogs !== undefined) {
					this.showQueueIcon =
						this.notificationRecord.notificationObject.worklogs.find(
							(worklog) => {
								return worklog.startTime === undefined;
							}
						)
							? true
							: false;
				}
				break;
			}

			case "General": {
				this.complete.emit();
				this.notificationDetail = <NotificationObjectGeneral>(
					this.notificationRecord.notificationObject
				);
				break;
			}

			default: {
				console.log(
					`Unknown notification type NOT recorded. ${this.notificationRecord.notificationType}`
				);
			}
		}

		/**
		 * Emit if complete
		 */
		if (
			this.notificationRecord.notificationObject.complete ||
			this.notificationRecord.notificationObject.fault
		) {
			this.complete.emit();
		}
	}

	/**
	 * Shows the raw detail of the notification in a dialog box.
	 */
	showDetail() {
		// Get the copmponent Factory we will use to create the component dialog
		const componentFactory: ComponentFactory<NotificationDetailDialogComponent> =
			this.resolver.resolveComponentFactory(NotificationDetailDialogComponent);

		// Create dialog.
		this.dialogService.componentDialogOpen(
			"Notification Detail",
			componentFactory,
			"notificationRecordVO",
			this.notificationRecord
		);
	}

	/**
	 * Get an observable version of the user.
	 *
	 * @param userId        The user id to covert.
	 */
	userIdObservableGet(userId: number): Observable<number> {
		return of(userId);
	}

	/**
	 * Opens the details section of the Notification
	 */
	toggleRecord() {
		this.activeToggle = !this.activeToggle;
	}

	/**
	 * Pad the start of a number with a certain character to make sure it reaches a minimum length.
	 *
	 * @param originalNumber          The number to padd.
	 * @param minLength               The minimum length to reach.
	 * @param padChar                 The character(s) to padd at the front.
	 */
	padStart(originalNumber: number, minLength: number, padChar: string): string {
		// Convert the number to a string.
		let originalString: string = originalNumber.toString();

		// Make sure the length is correct.
		while (originalString.length < minLength) {
			// Add the padd charatcer.
			originalString = padChar + originalString;
		}

		// Return the string.
		return originalString;
	}

	copyJsonToClipboard(json) {
		//Distpatch a copy to the clipboard action
		StoreAccess.dispatch(AppActions.clipboardTextCopySet(JSON.stringify(json)));
	}
}
