import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
	componentDestroyStream,
	Hark,
} from "app/modules/common/hark.decorator";
import { NotificationGeneratorService } from "app/services/notification-generator/notification-generator.service";
import { ObjectStoreService } from "app/services/object-store/object-store.service";
import { RequestActionMonitorService } from "app/services/request-action-monitor/request-action-monitor.service";
import { DataSetLibraryViewClassConfigAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-class-config.ahub.vo";
import { DataSetLibraryViewConfigAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-config.ahub.vo";
import {
	ProductAssetAHubVO,
	ProductAssetSectionPropertyValues,
	ProductAssetSectionValues,
} from "app/valueObjects/ahub/library/product-asset.ahub.vo";
import { ProductClassIndexAHubVO } from "app/valueObjects/ahub/library/product-class-index.ahub.vo";
import { ProductSectionPropertyValueAHubVO } from "app/valueObjects/ahub/library/product-section-property-value.ahub.vo";
import { ProductSectionValueAHubVO } from "app/valueObjects/ahub/library/product-section-value.ahub.vo";
import { ProductAHubVO } from "app/valueObjects/ahub/library/product.ahub.vo";
import { PropertyAllocationObjectVO } from "app/valueObjects/stream/product-allocation-object-stream.vo";
import { ExtractDefinitionPropertyAllocationObjectVO } from "app/valueObjects/view/extract-definition-property-allocation.view.vo";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { AssetUtils } from "../../asset-utils";
import { ProductAssetViewParamsVO } from "../../components/product-asset-view/product-asset-view.component";
import { getProductPropertyValueByAlloc } from "../../product-utils";
import { Utils } from "../../utils";
import { PropertyView } from "../product-view-full/product-view-full.component";

@Component({
	selector: "app-product-view",
	templateUrl: "./product-view.component.html",
	styleUrls: ["./product-view.component.scss"],
})
@Hark()
export class ProductViewComponent implements OnInit {
	@ViewChild("card", { static: true }) card: ElementRef;

	/**
	 * View data for the object
	 */
	@Input() public productViewData: ProductViewData = null;

	libraryViewClassConfigByProductClass$: Observable<DataSetLibraryViewClassConfigAHubVO>;

	libraryViewConfigMainImageAlloc$: Observable<PropertyAllocationObjectVO>;

	// This will come from the library view config
	productIdentifier = "...";

	productInfoProperties: PropertyView[];

	displayedColumns: string[] = ["propertyName", "propertyValue"];
	mainImageAsset: ProductAssetViewParamsVO;

	readonly WIDTH_X_HEIGHT = "[width]x[height]";

	loading: boolean;
	previewAssetWithData$: Observable<ProductAssetViewParamsVO>;
	flickbookOrVideoAssetWithData$: Observable<ProductAssetViewParamsVO>;
	hovering$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

	/**
	 * String to JSON
	 */
	stringToJson(data) {
		return JSON.parse(data);
	}

	constructor(
		private readonly requestActionMonitor: RequestActionMonitorService,
		private readonly notificationGenerator: NotificationGeneratorService,
		private readonly objectStoreService: ObjectStoreService
	) {}

	ngOnInit() {
		this.libraryViewClassConfigByProductClass$ = combineLatest([
			this.productViewData.libraryConfig$,
			this.productViewData.product$.pipe(Utils.isNotNullOrUndefined()),
		]).pipe(
			map(([libraryViewConfig, product]) => {
				if (!libraryViewConfig) {
					return;
				}

				return libraryViewConfig.libraryViewClassConfigs
					? libraryViewConfig.libraryViewClassConfigs.find(
							(classConfig) => classConfig.classId === product.productClassId
					  )
					: undefined;
			})
		);

		this.libraryViewConfigMainImageAlloc$ = combineLatest([
			this.libraryViewClassConfigByProductClass$.pipe(
				Utils.isNotNullOrUndefined(),
				map(
					(libraryViewClassConfig) =>
						libraryViewClassConfig.productMainImagePropertyAlloc
				)
			),
			this.productViewData.allocationStream$.pipe(
				Utils.isNotNullOrUndefined(),
				filter((allocIndex) => allocIndex.length > 0)
			),
		]).pipe(
			map(([libraryViewConfigMainImageAlloc, allocIndex]) => {
				return allocIndex.find(
					(allocIndex) => allocIndex.id === libraryViewConfigMainImageAlloc
				);
			})
		);

		// Lets pull out the product data to be shown for this product's identifier and info properties
		combineLatest([
			this.libraryViewClassConfigByProductClass$.pipe(
				Utils.isNotNullOrUndefined()
			),
			this.productViewData.allocationStream$.pipe(
				Utils.isNotNullOrUndefined(),
				filter((allocIndex) => allocIndex.length > 0)
			),
			this.productViewData.product$.pipe(filter((a) => a !== undefined)),
		])
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe(([libraryViewClassConfig, allocIndex, product]) => {
				// GET PRODUCT IDENTIFIER VALUE
				this.productIdentifier = "...";
				const productIdentifierAllocId =
					libraryViewClassConfig.productIdentifierPropertyAlloc;

				this.productIdentifier = this.getProductIdentifier(
					product,
					productIdentifierAllocId,
					allocIndex
				);

				// Get the info properties
				const productInfoPropertyAllocIds: number[] =
					libraryViewClassConfig.productInfoPropertyAllocs;

				if (productInfoPropertyAllocIds) {
					this.productInfoProperties = this.buildArrayOfInfoProperties(
						product,
						productInfoPropertyAllocIds,
						allocIndex
					);
				}
			});

		this.previewAssetWithData$ = combineLatest([
			this.libraryViewConfigMainImageAlloc$.pipe(Utils.isNotNullOrUndefined()),
			this.productViewData.productAssets$.pipe(Utils.isNotNullOrUndefined()),
		]).pipe(
			takeUntil(componentDestroyStream(this)),
			map(([libraryViewConfigMainImageAlloc, productAssets]) => {
				return this.buildMainImageAsset(
					productAssets,
					libraryViewConfigMainImageAlloc,
					libraryViewConfigMainImageAlloc.property.typeReference
				);
			})
		);

		// Lets load up the main image asset
		this.flickbookOrVideoAssetWithData$ = combineLatest([
			this.libraryViewConfigMainImageAlloc$.pipe(
				Utils.isNotNullOrUndefined(),
				filter((libraryViewConfigMainImageAlloc) =>
					["FLICKBOOK", "VIDEO"].includes(
						libraryViewConfigMainImageAlloc.property.typeReference
					)
				)
			),
			this.productViewData.productAssets$.pipe(Utils.isNotNullOrUndefined()),
		]).pipe(
			takeUntil(componentDestroyStream(this)),
			map(([libraryViewConfigMainImageAlloc, productAssets]) => {
				return this.buildMainImageAsset(
					productAssets,
					libraryViewConfigMainImageAlloc,
					libraryViewConfigMainImageAlloc.property.typeReference
				);
			})
		);

		combineLatest([
			this.hovering$.pipe(Utils.isNotNullOrUndefined()),
			this.flickbookOrVideoAssetWithData$.pipe(Utils.isNotNullOrUndefined()),
			this.previewAssetWithData$.pipe(Utils.isNotNullOrUndefined()),
		])
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe(([hovering, flickbookVideoAsset, previewAsset]) => {
				if (hovering) {
					this.mainImageAsset = flickbookVideoAsset;
				} else {
					this.mainImageAsset = previewAsset;
				}
			});

		// Lets start by grabbing the url for the product main image as the preview that comes with the product data
		this.previewAssetWithData$
			.pipe(Utils.isNotNullOrUndefined())
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((previewAsset) => {
				this.mainImageAsset = previewAsset;
			});
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	// The asset view child will emit an event if the user is 'hovering' over it
	// We can use that trigger to load up 'frames' of flickbooks and video assets
	hovering($event) {
		this.hovering$.next($event);
	}

	buildMainImageAsset(
		productAssets: ProductAssetAHubVO,
		mainImageAlloc: PropertyAllocationObjectVO,
		assetType
	): ProductAssetViewParamsVO {
		let mainImageAsset: ProductAssetViewParamsVO;
		const productAssetSectionPropertyValues: ProductAssetSectionPropertyValues =
			this.getAssetSectionPropertyValuesByAlloc(productAssets, mainImageAlloc);

		if (productAssetSectionPropertyValues) {
			mainImageAsset = this.getAssetRendererParamsByAssetTypeReference(
				assetType,
				mainImageAlloc,
				productAssetSectionPropertyValues
			);
		}

		return mainImageAsset;
	}

	getAssetSectionPropertyValuesByAlloc(
		productAssets: ProductAssetAHubVO,
		alloc: PropertyAllocationObjectVO
	): ProductAssetSectionPropertyValues {
		let productAssetSectionPropertyValues: ProductAssetSectionPropertyValues;

		if (productAssets && productAssets.productSectionValueAssets) {
			const productSectionValueAsset: ProductAssetSectionValues =
				productAssets.productSectionValueAssets.find(
					(section) => section.productPropertySectionId === alloc.section.id
				);

			if (
				productSectionValueAsset &&
				productSectionValueAsset.productSectionPropertyValues
			) {
				productAssetSectionPropertyValues =
					productSectionValueAsset.productSectionPropertyValues.find(
						(assetPropertyValue) =>
							assetPropertyValue.propertyId === alloc.property.id
					);
			}
		}

		return productAssetSectionPropertyValues;
	}

	getProductIdentifier(
		product: ProductAHubVO,
		productIdentifierAllocId: number,
		allocIndex: PropertyAllocationObjectVO[]
	): string {
		let productIdentifier: string = "...";
		if (productIdentifierAllocId) {
			const productValue = this.getProductValue(
				product,
				productIdentifierAllocId,
				allocIndex
			);
			if (productValue) {
				productIdentifier = productValue;
			}
		}
		return productIdentifier;
	}

	getProductValue(
		product: ProductAHubVO,
		allocId: number,
		allocIndex: PropertyAllocationObjectVO[]
	): string {
		let productValue: string;
		if (allocId) {
			const matchingAllocIndex = allocIndex.find(
				(allocIndex) => allocIndex.id === allocId
			);

			// Ok, cool, we have an alloc index for displaying the product value,
			// We'll need the section and property id in order to get at the right product value
			if (matchingAllocIndex) {
				productValue = getProductPropertyValueByAlloc(
					product,
					matchingAllocIndex
				);
			}
		}
		return productValue;
	}

	/**
	 * For a given product, find the property using the supplied propertyAllocation object and return the previewURL for a property value.
	 * Note this is likely to be null unless the property in question is an asset.
	 *
	 * @param product
	 * @param allocIndex
	 */
	getProductPropertyValueByAllocId(
		product: ProductAHubVO,
		allocIndex: PropertyAllocationObjectVO
	): ProductSectionPropertyValueAHubVO {
		// The result.
		let productSectionPropertyValue: ProductSectionPropertyValueAHubVO;

		// Check for any null params or if the product section array  of properties is empty.
		if (
			product &&
			allocIndex &&
			product.productSectionValues &&
			product.productSectionValues.length > 0
		) {
			// Find the section of properties in the product for the given product allocation index.
			const matchingProductSection: ProductSectionValueAHubVO =
				product.productSectionValues.find(
					(section) => section.sectionId === allocIndex.section.id
				);

			// If we find the section of properties referenced , and its has properties ..
			if (
				matchingProductSection &&
				matchingProductSection.productSectionPropertyValues &&
				matchingProductSection.productSectionPropertyValues.length > 0
			) {
				// Find the property in the property section.
				productSectionPropertyValue =
					matchingProductSection.productSectionPropertyValues.find(
						(property) => property.propertyId === allocIndex.property.id
					);
			}
		}

		// Return the preview URL.
		return productSectionPropertyValue;
	}

	/**
	 * Builds map of property info allocs, used to display property -> values for each product
	 * (as defined by the LibraryViewConfig)
	 * @param product
	 * @param productInfoPropertyAllocIds
	 * @param allocIndex
	 */
	buildArrayOfInfoProperties(
		product: ProductAHubVO,
		productInfoPropertyAllocIds: number[],
		allocIndex: PropertyAllocationObjectVO[]
	): PropertyView[] {
		const infoProperties: PropertyView[] = [];

		productInfoPropertyAllocIds.forEach((infoAllocId) => {
			const propertyView: PropertyView = {
				propertyAlloc: allocIndex.find((alloc) => alloc.id === infoAllocId),
				propertyValue: this.getProductValue(product, infoAllocId, allocIndex),
				isMatrix: undefined,
			};

			infoProperties.push(propertyView);
		});

		return infoProperties;
	}

	productViewClick() {
		this.productViewData.productViewClickedAction(
			this.productViewData.componentId,
			this.productViewData.id
		);

		// StoreAccess.dispatch(ComponentActions.componentDataSetProductsDataSetCategorySelectProductIdSet(this.productViewData.componentId, this.productViewData.id))
	}

	// Main image (asset) setup

	getAssetRendererParamsByAssetTypeReference(
		typeReference: string,
		alloc: PropertyAllocationObjectVO,
		sectionProperty: ProductAssetSectionPropertyValues
	): ProductAssetViewParamsVO {
		switch (typeReference) {
			case "IMAGE":
				return this.imageAssetSetup(alloc, sectionProperty);
			case "FLICKBOOK":
				return this.flickbookAssetSetup(
					alloc,
					sectionProperty,
					"Flickbook Asset"
				);
			case "VIDEO":
				return this.flickbookAssetSetup(alloc, sectionProperty, "Video Asset");
			case "GLB":
				return this.glbAssetSetup(alloc, sectionProperty, "GLB Asset");
			default:
				return this.imageAssetSetup(alloc, sectionProperty);
		}
	}
	/**
	 * Setup the image asset renderer
	 */
	imageAssetSetup(
		allocation: PropertyAllocationObjectVO,
		assetData: ProductAssetSectionPropertyValues
	): ProductAssetViewParamsVO {
		//Get the asset images from the asset data
		const assetImageUrls =
			assetData && assetData.previewUrl
				? [assetData.previewUrl.replace(this.WIDTH_X_HEIGHT, "512x512")]
				: [];

		let clickHandler;

		//Handel the full screen asset click
		clickHandler = (params) => {
			this.productViewClick();
		};

		const extractAlloc: ExtractDefinitionPropertyAllocationObjectVO =
			this.extractAllocCreate(allocation);

		const editable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

		//Setup a renderer for the single asset
		return this.assetRendererSetup(
			extractAlloc,
			assetData,
			"Image Asset",
			assetImageUrls,
			clickHandler,
			editable$
		);
	}

	/**
	 * Setup the flickbook asset renderer
	 */
	flickbookAssetSetup(
		allocation: PropertyAllocationObjectVO,
		assetData: ProductAssetSectionPropertyValues,
		assetType: string
	): ProductAssetViewParamsVO {
		//Get the frame URL's
		let frameURLs = assetData.assetUrls.filter((url) =>
			url.includes("standard")
		);

		// We'll be using the flickbook functionality to show a preview of video stills too
		// However we should filter the preview image url list to just the 'stills'
		if (allocation.property.typeReference === "VIDEO") {
			frameURLs = assetData.assetUrls.filter((assetUrl) =>
				assetUrl.includes("stills")
			);
		}

		//Handler for viewing the full screen asset
		let clickHandler = (params) => {
			this.productViewClick();
		};

		//Setup the asset renderer
		const extractAlloc: ExtractDefinitionPropertyAllocationObjectVO =
			this.extractAllocCreate(allocation);

		const editable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

		//Setup a renderer for the single asset
		return this.assetRendererSetup(
			extractAlloc,
			assetData,
			assetType,
			frameURLs,
			clickHandler,
			editable$
		);
	}

	/**
	 * Setup the GLB asset renderer
	 */
	glbAssetSetup(
		allocation: PropertyAllocationObjectVO,
		assetData: ProductAssetSectionPropertyValues,
		assetType: string
	): ProductAssetViewParamsVO {
		//Get the asset images from the asset data
		const assetImages =
			assetData && assetData.previewUrl
				? [assetData.previewUrl.replace(this.WIDTH_X_HEIGHT, "512x512")]
				: [];

		const glbURL = undefined;

		//Handel the full screen asset click
		const clickHandler = (params) => {
			this.productViewClick();
		};

		const extractAlloc: ExtractDefinitionPropertyAllocationObjectVO =
			this.extractAllocCreate(allocation);

		const editable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

		//Setup a renderer for the single asset
		// return AssetUtils.assetRendererSetup(extractId, undefined, productId, extractAlloc, assetData, 'GLB Asset',
		//   assetImages, uploadFileClickHandler, undefined, this.uploadFolderClickHandlerBound, fullScreenAssetClickHandler,
		//   this.editable$, this.requestActionMonitor, this.notificationGenerator, this.objectStoreService, this.assetsMap, this.assets);
		//Setup a renderer for the single asset
		return this.assetRendererSetup(
			extractAlloc,
			assetData,
			assetType,
			assetImages,
			clickHandler,
			editable$,
			glbURL
		);
	}

	extractAllocCreate(allocation): ExtractDefinitionPropertyAllocationObjectVO {
		return {
			priority: undefined,
			extractDefinitionProductClassId: undefined,
			id: allocation.id,
			productClass: allocation.productClass,
			property: allocation.property,
			readOnly: undefined,
			section: allocation.section,
		};
	}

	assetRendererSetup(
		extractAlloc,
		assetData,
		assetTypeName,
		assetImages,
		clickHandler,
		editable$,
		glbURL?
	): ProductAssetViewParamsVO {
		return AssetUtils.assetRendererSetup(
			undefined,
			this.productViewData.dataSetId,
			"<PRODUCT_ID_NOT_SET>",
			extractAlloc,
			assetData,
			assetTypeName,
			assetImages,
			undefined,
			undefined,
			undefined,
			clickHandler,
			editable$,
			this.requestActionMonitor,
			this.notificationGenerator,
			this.objectStoreService,
			undefined,
			undefined,
			true,
			undefined,
			glbURL
		);
	}
}

export interface ProductViewData {
	id: number;
	product$: Observable<ProductAHubVO>;
	productAssets$: Observable<ProductAssetAHubVO>;
	allocationStream$: Observable<PropertyAllocationObjectVO[]>;
	libraryConfig$: Observable<DataSetLibraryViewConfigAHubVO>;
	componentId: string;
	dataSetId: number;
	largeGrid$: BehaviorSubject<boolean>;
	productViewClickedAction?: (componentId, productId) => void;
	productClassIndexes?: ProductClassIndexAHubVO[];
}

export interface AssetIdAndAllocIndex {
	assetId: string;
	allocIndex: PropertyAllocationObjectVO;
}
