import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-client-quota-info-dialog",
	templateUrl: "./client-quota-info-dialog.component.html",
	styleUrls: ["./client-quota-info-dialog.component.css"],
})
export class ClientQuotaInfoDialogComponent implements OnInit {
	constructor() {
		// This is intentional
	}

	ngOnInit() {}
}
