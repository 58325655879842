import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ProductMatrixDefDimensionOptionAHubVO } from "app/valueObjects/ahub/library/product-matrix-def-dimension-option.ahub.vo";
import { Observable } from "rxjs";

@Component({
	selector: "app-reorder-options",
	templateUrl: "./reorder-options.component.html",
	styleUrls: ["./reorder-options.component.scss"],
})
export class ReorderOptionsComponent implements OnInit {
	constructor(private readonly formBuilder: FormBuilder) {}

	/**
	 * All of the options to display.
	 */
	@Input()
	public allOptions: ProductMatrixDefDimensionOptionAHubVO[] = null;

	/**
	 * VO
	 */
	@Input()
	public reorderingVO: ReorderingVO = null;

	/**
	 * The non-observable version of the index above.
	 */
	currentAlternativeLabelIndex: number;

	/**
	 * The location of the current option.
	 */
	currentPosition: number = 0;

	/**
	 * The re-ordering form that we use in the HTML.
	 */
	reorderingForm: FormGroup = this.buildForm();

	/**
	 * The function to create the re-ordering form that we use in the HTML.
	 */
	buildForm(): FormGroup {
		return this.formBuilder.group({
			targetOption: "",
			above: true,
		});
	}

	ngOnInit(): void {
		// Store the current alternative label index
		this.currentAlternativeLabelIndex =
			this.reorderingVO.currentAlternativeLabelIndex;

		// Set up the position of the currently selected option.
		this.currentPosition = this.allOptions.indexOf(
			this.reorderingVO.originalOption
		);

		// Watch the changing of the target option so we can update the VO.
		this.reorderingForm.controls.targetOption.valueChanges.subscribe(
			(value) => {
				this.reorderingVO.targetOption = value;
			}
		);

		// Watch the changing of the "above" value, so we can update the VO too.
		this.reorderingForm.controls.above.valueChanges.subscribe((value) => {
			this.reorderingVO.above = value;
		});

		// Make sure we set the current target option to the first option that's not the selected one.
		this.reorderingForm.controls.targetOption.setValue(
			this.allOptions.find(
				(option) => option.id !== this.reorderingVO.originalOption.id
			)
		);
	}

	/**
	 * This function will get the label from an option.
	 *
	 * @param option 			The option to get the label from.
	 *
	 * @returns 				The label for this option.
	 */
	optionLabelGet(option: ProductMatrixDefDimensionOptionAHubVO): string {
		// Stop here if the alternative label id is -1.
		if (this.currentAlternativeLabelIndex < 0) return option.id;

		// Return the an empty string if we don't have any labels.
		if (!option.labels) return "";

		// Now return the label at the index specified, or an empty string if we don't have a label.
		return this.currentAlternativeLabelIndex < option.labels.length
			? option.labels[this.currentAlternativeLabelIndex]
			: "";
	}
}

/**
 * This describes what the re-ordering VO should look like.
 */
export interface ReorderingVO {
	above: boolean;
	targetOption: ProductMatrixDefDimensionOptionAHubVO;
	originalOption: ProductMatrixDefDimensionOptionAHubVO;
	currentAlternativeLabelIndex: number;
}
