/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import {
	SelectionReactionACatalogVO,
	UserIndexAHubVO,
} from "@harksolutions/ahub-web-services-types";
import { ACatalogDistributionAHubVO } from "@harksolutions/ahub-web-services-types";
import { AviewProductFullDataLegacy } from "app/modules/routes/aview/aview-legacy/aview-legacy-product/aview-legacy-product.component";
import { AviewProductFullDataMapped } from "app/modules/routes/aview/aview-mapped/aview-mapped-product/aview-mapped-product.component";
import { ActiveGLBAssetPreviews } from "app/modules/routes/aview/valueObjects/active-glb-asset-previews.aview.vo";
import { AViewVO } from "app/modules/routes/aview/valueObjects/aview.aview.vo";
import { ComponentsAViewVO } from "app/modules/routes/aview/valueObjects/components.aview.vo";
import { KeyAViewVO } from "app/modules/routes/aview/valueObjects/key.aview.vo";
import { ProductCardSummaryAViewVO } from "app/modules/routes/aview/valueObjects/product-card-summary.aview.vo";
import { ProductViewConfigAViewVO } from "app/modules/routes/aview/valueObjects/product-view-config.aview.vo";
import { AViewState } from "app/store/reducers/aview.reducer";
import { ExportOutputAssetAHubVO } from "app/valueObjects/ahub/accounts/export-output-asset.ahub.vo";
import { PresignedUrlAHubVO } from "app/valueObjects/ahub/presigned-url.ahub.vo";
import { IdItemVO } from "../id-item.vo";
import { List } from "../list.vo";
import { MapStorageUtil } from "../map-storage.util";
import { MapStorage } from "../map-storage.vo";

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */

/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Get the aview section from the current view state.
 */
const aViewState = (state): AViewState => state.aView;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */

/**
 * Get the selected publication edition path list
 */
export const publicationEditionPathUrlsList = (
	state
): List<IdItemVO<PresignedUrlAHubVO>> => aViewState(state).publicationPathUrls;

/**
 * Get the aviews
 */
export const aViews = (state): AViewVO[] => aViewState(state).aViews;

/**
 * Get the aviews
 */
export const aViewKeys = (state): KeyAViewVO[] =>
	aViewState(state).aViews.map((aView) => {
		const key: KeyAViewVO = {
			publicationId: aView.publicationId,
			edition: aView.edition,
		};
		return key;
	});

/**
 * Get component state map
 */
export const aViewComponentMap = (state): MapStorage<ComponentsAViewVO> =>
	aViewState(state).componentMap;

export const aViewComponentMapKeys = (state) => {
	return aViewState(state).componentMap
		? aViewState(state).componentMap.keys
		: undefined;
};

/**
 * Get the publication ID of the first component (if we have one).
 *
 * @param state 			The state to get the value from.
 *
 * @returns 				Returns the publication ID we've found.
 */
export const aViewFirstComponentPublicationId = (state): number => {
	// Make sure we have at least 1 key. Otherwise return -1.
	if (aViewComponentMapKeys(state).length == 0) {
		return -1;
	}

	// Now get the first component key, get it's component, then return the publication ID.
	return MapStorageUtil.mapStorageGet(
		aViewComponentMap(state),
		aViewComponentMapKeys(state)[0]
	)?.selectedPublicationId;
};

/**
 *
 *
 * @param componentId
 */
export const aViewComponent = (
	componentId: string
): ((state) => ComponentsAViewVO) => {
	return (state): ComponentsAViewVO =>
		MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId);
};

/**
 *
 *
 * @param componentId
 */
export const aViewComponentAssets = (
	componentId: string
): ((state) => ExportOutputAssetAHubVO[]) => {
	return (state): ExportOutputAssetAHubVO[] => {
		const component = MapStorageUtil.mapStorageGet(
			aViewComponentMap(state),
			componentId
		);
		return component ? component.assets : undefined;
	};
};

/**
 *
 *
 * @param componentId
 */
export const aViewComponentSelectedProductId = (
	componentId: string
): ((state) => number) => {
	return (state): number => {
		const component = MapStorageUtil.mapStorageGet(
			aViewComponentMap(state),
			componentId
		);
		return component ? component.selectedProductId : -1;
	};
};

/**
 *
 *
 * @param componentId
 */
export const aViewComponentSelectedCategoryId = (
	componentId: string
): ((state) => number) => {
	return (state): number => {
		const component = MapStorageUtil.mapStorageGet(
			aViewComponentMap(state),
			componentId
		);
		return component ? component.selectedCategoryId : -1;
	};
};

/**
 *
 *
 * @param componentId
 */
export const aViewComponentSelectedProductViewData = (
	componentId: string
): ((state) => AviewProductFullDataLegacy | AviewProductFullDataMapped) => {
	return (state): AviewProductFullDataLegacy | AviewProductFullDataMapped => {
		const component = MapStorageUtil.mapStorageGet(
			aViewComponentMap(state),
			componentId
		);
		return component ? component.selectedProductViewData : undefined;
	};
};

/**
 *
 *
 * @param componentId
 */
export const aViewComponentSelectedProductViewDataLegacy = (
	componentId: string
): ((state) => AviewProductFullDataLegacy) => {
	return (state): AviewProductFullDataLegacy => {
		const component = MapStorageUtil.mapStorageGet(
			aViewComponentMap(state),
			componentId
		);
		return component
			? (component.selectedProductViewData as AviewProductFullDataLegacy)
			: undefined;
	};
};

/**
 *
 *
 * @param componentId
 */
export const aViewComponentSelectedProductViewDataMapped = (
	componentId: string
): ((state) => AviewProductFullDataMapped) => {
	return (state): AviewProductFullDataMapped => {
		const component = MapStorageUtil.mapStorageGet(
			aViewComponentMap(state),
			componentId
		);
		return component
			? (component.selectedProductViewData as AviewProductFullDataMapped)
			: undefined;
	};
};

/**
 *
 *
 * @param componentId
 */
export const aViewComponentFilteredProductIds = (
	componentId: string
): ((state) => number[]) => {
	return (state): number[] => {
		const component = MapStorageUtil.mapStorageGet(
			aViewComponentMap(state),
			componentId
		);
		return component ? component.filteredProductIds : undefined;
	};
};

/**
 * Get the current product count
 *
 * @param componentId
 */
export const aViewComponentProductCount = (
	componentId: string
): ((state) => number) => {
	return (state): number => {
		const component = MapStorageUtil.mapStorageGet(
			aViewComponentMap(state),
			componentId
		);
		return component ? component.productCount : 0;
	};
};

/**
 * Get components selected distribution to apply filters
 *
 * @param componentId
 */
export const aViewComponentSelectedACatalogDistribution =
	(componentId: string) =>
	(state): ACatalogDistributionAHubVO => {
		return MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId)
			?.selectedACatalogDistribution;
	};

/**
 * Get components selected publication id
 *
 * @param componentId
 */
export const aViewComponentSelectedPublicationId =
	(componentId: string) =>
	(state): number => {
		return MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId)
			?.selectedPublicationId;
	};
/**
 * This is a function which will generate a selector meaning we get exactly the item from the list we want
 *
 * @param publicationId
 * @param editionNumber
 */
export const aViewGet = (
	publicationId: number,
	editionNumber: number
): ((state) => AViewVO) => {
	return (state): AViewVO =>
		aViewState(state).aViews.find(
			(aView) =>
				aView.publicationId === publicationId && aView.edition === editionNumber
		);
};

/**
 * Selects library view config by edition id
 */
export const aViewProductViewConfig = (
	state
): MapStorage<ProductViewConfigAViewVO> =>
	aViewState(state).productViewConfigByEdition;

/**
 * Selects library view config map storage by publication id
 */
export const aViewProductViewConfigMapStorage = (
	state
): MapStorage<MapStorage<ProductViewConfigAViewVO>> =>
	aViewState(state).productViewConfigMapStorageByPublicationId;

export const aViewDisplaySetting = (state): string =>
	aViewState(state).displaySetting;

export const globalSidebarStateActive = (state): boolean =>
	aViewState(state).globalSidebarStateActive;

export const aViewComponentFilterCount =
	(componentId: string) =>
	(state): number => {
		return MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId)
			?.filterCount;
	};

export const aViewComponentActiveGlbAssetPreviews =
	(componentId: string) =>
	(state): ActiveGLBAssetPreviews => {
		return MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId)
			?.activeGblAssetPreviews;
	};

export const aViewComponentSelectedSelectionId =
	(componentId: string) =>
	(state): number => {
		return MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId)
			?.selectedSelectionId;
	};

export const aViewComponentProductCardSummaries =
	(componentId: string) =>
	(state): MapStorage<ProductCardSummaryAViewVO> => {
		return MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId)
			?.productCardSummaryMap;
	};

export const aViewComponentProductCardSummary =
	(componentId: string, productId: string) =>
	(state): ProductCardSummaryAViewVO => {
		const componentProductSummaryMap: MapStorage<ProductCardSummaryAViewVO> =
			MapStorageUtil.mapStorageGet(
				aViewComponentMap(state),
				componentId
			)?.productCardSummaryMap;
		return MapStorageUtil.mapStorageGet(componentProductSummaryMap, productId);
	};

export const aViewComponentSelectionUserFilterList =
	(componentId: string) =>
	(state): List<UserIndexAHubVO> => {
		return MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId)
			?.selectionUserFilterList;
	};

export const aViewComponentSelectionReactionFilterList =
	(componentId: string) =>
	(state): List<SelectionReactionACatalogVO> => {
		return MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId)
			?.selectionReactionFilterList;
	};

export const aViewComponentSelectionReactionConsensusFilter =
	(componentId: string) =>
	(state): string => {
		return MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId)
			?.selectionReactionConsensusFilter;
	};

export const aViewClientLogos = (state): List<IdItemVO<PresignedUrlAHubVO>> =>
	aViewState(state).clientLogos;
