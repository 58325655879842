import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoggedInGuard } from "app/router/guards/logged-in-guard";
import { UrlRedirectGuard } from "app/router/guards/url-redirect.guard";
import { ProcessTokenComponent } from "./process-token.component";

const routes: Routes = [
	// ==================================
	// CATCH ALL UNKNOWN - MUST BE LAST
	// ==================================
	{
		path: "**",
		canActivate: [UrlRedirectGuard, LoggedInGuard],
		component: ProcessTokenComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ProcessTokenRoutingModule {}
