import { Component, Input, OnInit } from "@angular/core";
import { ClassAViewVO } from "app/modules/routes/aview/valueObjects/class.aview.vo";

@Component({
	selector: "app-product-view-config-class-tree-item",
	templateUrl: "./product-view-config-class-tree-item.component.html",
	styleUrls: ["./product-view-config-class-tree-item.component.css"],
})
export class ProductViewConfigClassTreeItemComponent implements OnInit {
	@Input() classTreeItemVO: any = null;

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		// This is intentional
	}
}

export interface ProductViewConfigClassTreeItem extends ClassAViewVO {
	hasConfig?: boolean;
}
