export interface PaginationControlAppVO {
	nextIndex: number;
	nextId: number;
	pageSize: number;
	sortDirection: string;
	autoFetchNext: boolean;
	exponential: boolean; // If set to true, pagination increases exponetially 100,200,400,800 etc..
	exponentialLimit: number;
}

/**
 * Pagination default options, 200 at a time , id ascending with automatic fetch of next page.
 * NB: Don;t forget to clone this object for use ! Utils.clone(PAGINATION_DEFAULT);
 */
export const PAGINATION_DEFAULT: PaginationControlAppVO = {
	nextIndex: 0,
	nextId: -1,
	pageSize: 25,
	sortDirection: "asc",
	autoFetchNext: true,
	exponential: true,
	exponentialLimit: 800, // Limiting pagination request size to that of the aHub or lower.
};
