import { IFloatingFilterParams, RowNode } from "ag-grid-community";

/**
 * Base file for the select filter, this will mean that we will share some of the functionality
 * between the basic filter and the floating filters.
 */

/**
 * Base parameters for each set of filters
 */
interface GridFilterSelectBaseParams {
	valueGetter?: (rowNode: RowNode) => any;
	filterChangedCallback?: (additionalEventAttributes?: any) => void;
	defaultSelectedFilterValue: any;
	valueOptions: GridFilterSelectOption[];
}

/**
 * Create an interface for the regular filter which mixes our base and the regular param stack
 */
export interface GridFilterSelectParams extends GridFilterSelectBaseParams {}

/**
 * Create an interface for the floating filter which mixes our base and the regular param stack
 */
export interface GridFilterFloatingSelectParams
	extends IFloatingFilterParams,
		GridFilterSelectBaseParams {}

/**
 * Grid filter select options
 */
export interface GridFilterSelectOption {
	value: any;
	label: string;
	labelStyle?: any;
	activeFilter: boolean;
	filterFunction?: (rowNode: RowNode, value: any) => boolean;
}

/**
 * Interface for the grid filter select model
 */
export interface GridFilterSelectModel {
	selectedValue: any;
}

/**
 * Get the selected object type for the value
 *
 * @param value
 * @param params
 */
export const getSelectedObjectFromValue = (
	value,
	params: GridFilterSelectBaseParams
): GridFilterSelectOption => {
	//Do we have any options
	if (params.valueOptions) {
		//Looking for the object which matches the selected value. We are converting to strings to keep types consistent
		return params.valueOptions.find(
			(option) => `${option.value}` === `${value}`
		);
	}

	return undefined;
};
