import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutingNavigation } from "app/app-routing-navigation";
//Utils
import { Utils } from "modules/common/utils";
import { Observable } from "rxjs";
import { sessionClientId, sessionUserId } from "selector/app.selector";
import { allRoutes } from "selector/view/view-routes.selector";
// Services
import { RequestActionMonitorService } from "services/request-action-monitor/request-action-monitor.service";
import { AHubActions } from "store/actions/ahub.actions";
import { AppActions } from "store/actions/app.actions";
import { ViewActions } from "store/actions/view.actions";
import { StoreAccess } from "store/store-access";
// Streams
import { EntityPermissionsStream } from "store/stream/entity-permissions.stream";
import { PermissionType } from "valueObjects/ahub/accounts/entity-permission-type.ahub.enum";
// VOs
import { EntityPermissionAHubVO } from "valueObjects/ahub/accounts/entity-permission.ahub.vo";
import { RouteViewVO } from "valueObjects/view/route.view.vo";
import { map, filter } from "rxjs/operators";

@Injectable()
export class PermissionsService {
	eps: EntityPermissionsStream = new EntityPermissionsStream();

	userAndClientSet$: Observable<boolean> = this.eps.userAndClientIdAreSet();

	/**
	 * Get an observable of the entity permissions
	 */
	entityPermissions$: Observable<EntityPermissionAHubVO[]> =
		this.eps.entityPermissionsDistinct();

	currentEntityPermissions: EntityPermissionAHubVO[];

	allroutes = StoreAccess.dataGet(allRoutes);

	public PermissionType = PermissionType;

	constructor(
		private router: Router,
		private requestActionMonitorService: RequestActionMonitorService
	) {
		this.userAndClientSet$.subscribe((userIdOrClientIdWereSet) => {
			const clientId = StoreAccess.dataGet(sessionClientId);
			const userId = StoreAccess.dataGet(sessionUserId);

			if (clientId && userId) {
				// client or user changed so we need to go get some new permissions
				StoreAccess.dispatch(AppActions.entityPermissionsFetch(), true);
				StoreAccess.dispatch(AHubActions.workGroupsByUserAndClientFetch());
			}
		});

		this.entityPermissions$.subscribe((entityPermissions) => {
			if (entityPermissions) {
				this.currentEntityPermissions = entityPermissions;
				this.updateSidenav();
				this.navigateToWelcomePageIfNotAllowedToBeWhereWeCurrentlyAre();
			}
		});
	}

	private updateSidenav() {
		// Loop through all of the available routes, clone the ones we want to
		// keep, and set their sub routes, then filter out any nulls.
		const availableRoutes: RouteViewVO[] = this.allroutes
			.map((route) => {
				if (!this.userHasPermissionToView(route.permissions)) {
					return null;
				}

				// If we get here this route is available.
				// Duplicate it so we can use it again.
				let availableRoute: RouteViewVO = Utils.clone(route);

				if (route.routes) {
					// Filter the sub routes into the sub routes of the cloned route.
					availableRoute.routes = route.routes.filter((subRoute) =>
						this.userHasPermissionToView(subRoute.permissions)
					);
				}

				// We want to keep this route, so return it.
				return availableRoute;
			})
			.filter((route) => route != null);

		// Finally update the available routes in the store.
		StoreAccess.dispatch(ViewActions.availableRoutesSet(availableRoutes));
	}

	sessionUserHasPermissionToViewObservable(
		permissionToCheck: EntityPermissionAHubVO
	): Observable<boolean> {
		return this.entityPermissions$.pipe(
			filter((entityPermissions) => entityPermissions !== undefined),
			map((entityPermissions) => {
				let userHasPermission = false;
				entityPermissions.forEach((userPermission) => {
					if (userPermission.entityRef === permissionToCheck.entityRef) {
						if (
							PermissionType[userPermission.permissionType] >=
							PermissionType[permissionToCheck.permissionType]
						) {
							userHasPermission = true;
						}
					}
				});
				return userHasPermission;
			})
		);
	}

	// Compares passed permission object with users entity permissions and returns true if
	// users entity permission is greater than passed permission.
	userHasPermissionToView(
		minimumAcceptablePermissions: EntityPermissionAHubVO[],
		ownerUserId?: number,
		mustMatchUser?: boolean
	): boolean {
		// Make sure the must match user is at least false.
		if (mustMatchUser == undefined) mustMatchUser = false;

		//The permissions passed to this function are undefined, we will default to no
		if (minimumAcceptablePermissions == undefined) return false;

		let userHasPermission = false;

		this.currentEntityPermissions.forEach((userPermission) => {
			// Lets see if we have an acceptable permission for this user permission entityRef
			const acceptablePermissionByEntityRef: EntityPermissionAHubVO =
				minimumAcceptablePermissions.find(
					(permission) => permission.entityRef === userPermission.entityRef
				);
			if (acceptablePermissionByEntityRef) {
				if (
					PermissionType[userPermission.permissionType] >=
					PermissionType[acceptablePermissionByEntityRef.permissionType]
				) {
					userHasPermission = true;
				}
			}
		});

		// Does the user have permission from the permissions? If so, return true now.
		if (userHasPermission && !mustMatchUser) return true;

		// If we need to match the user and we don't have a user id then return false straight away.
		if (
			mustMatchUser &&
			(ownerUserId == undefined || ownerUserId == null || ownerUserId <= 0)
		)
			return false;

		// Is the owner user id greater than 0? If so, does the id match the current session id? If so return true because this is valid.
		if (
			ownerUserId != undefined &&
			ownerUserId > -1 &&
			(ownerUserId == StoreAccess.dataGet(sessionUserId) || ownerUserId == 0)
		)
			return true;

		// If we must match the user id and if we get here then the user id clearly didn't match,
		// so return false.
		if (mustMatchUser) return false;

		// Otherwise return whether we have user permissions based on the entity permissions.
		return userHasPermission;
	}

	canNavigateTo(path: string): boolean {
		let matchingSubRoute: RouteViewVO = undefined;

		const matchingRouteFound = this.allroutes.some((route) => {
			if (route.routes) {
				matchingSubRoute = route.routes.find((subRoute) => {
					return subRoute.routerLink === path;
				});
			}
			return matchingSubRoute !== undefined;
		});

		//If we don't have a matching sub route this means the route is not described in the store.
		//Then we will assume that the user can visit this location we are looking to improve this
		if (!matchingRouteFound) return true;

		return this.userHasPermissionToView(matchingSubRoute.permissions);
	}

	navigateToWelcomePageIfNotAllowedToBeWhereWeCurrentlyAre() {
		if (!this.canNavigateTo(this.router.url.split("/", 2)[1])) {
			AppRoutingNavigation.navigateWelcome(this.router, false);
		}
	}
}
