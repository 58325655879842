import { HashedMapStorage } from "store/hashed-map-storage.vo";

/**
 * This is the util class for accessing the data of the hashed map
 */
export class HashedMapUtil {
	/**
	 * Create an empty hashed map
	 *
	 * @param mapBuckets    Amounts of buckets which the hash map will be split into
	 */
	public static hashedMapCreateEmpty(
		mapBuckets?: number
	): HashedMapStorage<any> {
		return {
			map: {},
			mapBucketCount: mapBuckets ? Math.max(1, mapBuckets) : 128,
			itemCount: 0,
		};
	}

	/**
	 * Create a new hashed map with the data specified
	 *
	 * @param data          The data which we want to start the map off with
	 * @param idFunction    The function which we will use to identify the unique key of the item
	 * @param mapBuckets    Amounts of buckets which the hash map will be split into
	 */
	public static hashedMapCreate<T>(
		data: T[],
		idFunction: (item: T) => string,
		mapBuckets?: number
	): HashedMapStorage<T> {
		//Create a new hashed map
		let hashMap = HashedMapUtil.hashedMapCreateEmpty(mapBuckets);

		//Call the append items function which will fill the map with our data
		HashedMapUtil.hashMapAppendItems(hashMap, data, idFunction);

		//Return the hashed map
		return hashMap;
	}

	/**
	 * Create a shallow clone of the hashed map
	 *
	 * @param hashedMap     The hash map which we want to shallow clone
	 */
	public static hashedMapClone<T>(
		hashedMap: HashedMapStorage<T>
	): HashedMapStorage<T> {
		//Create a new hashed map
		let newHash = HashedMapUtil.hashedMapCreateEmpty(hashedMap.mapBucketCount);

		//Set the item count
		newHash.itemCount = hashedMap.itemCount;

		//Loop through every reference we have in the map setting them into our new map. We will also duplicate the value arrays
		HashedMapUtil.hashMapBucketReferencesGet(hashedMap).forEach((ref) => {
			HashedMapUtil.hashMapBucketValuesSet(
				newHash,
				ref,
				HashedMapUtil.hashMapBucketValuesGet(hashedMap, ref).slice()
			);
		});

		//Return the new map
		return newHash;
	}

	/**
	 * Get the bucket references which this hash map has
	 *
	 * @param hashMap       The hash map which we want the references for
	 */
	private static hashMapBucketReferencesGet<T>(
		hashMap: HashedMapStorage<T>
	): string[] {
		//No data then no keys!
		if (!hashMap || !hashMap.map) return [];

		//Get the keys which are on the object these are the bucket references for the current map
		return Object.keys(hashMap.map);
	}

	/**
	 * Get the item from the hash map but it's id
	 *
	 * @param hashMap           Hash map which we want the value from
	 * @param id                The id of the object we want
	 */
	public static hashMapGetItem<T>(hashMap: HashedMapStorage<T>, id: string): T {
		//No have map, id or no id function, nothing to be done!
		if (!hashMap || !id) return undefined;

		//Generate a bucket references and get the values. We will then look for this item by it's reference
		let idHashItem = HashedMapUtil.hashMapBucketValuesGet(
			hashMap,
			HashedMapUtil.hashMapBucketReferenceGet(id, hashMap.mapBucketCount)
		).find((item) => item.id == id);

		//Did we dind the item
		return idHashItem ? idHashItem.data : undefined;
	}

	/**
	 * Returns all the values from the hash map. No order is mainted the data comes stored as it is.
	 *
	 * @param hashMap   Hash map we want the values from!
	 */
	public static hashMapGetItemsAll<T>(hashMap: HashedMapStorage<T>): T[] {
		//No hash map so return undefined
		if (!hashMap) return undefined;

		//Loop through the keys of the hash map so we can collate all the items from all the sections.
		//We will then extract the values for each section of the bucket, filter out any empty ones.
		//Finally we will reduce them into a single list
		return HashedMapUtil.hashMapBucketReferencesGet(hashMap)
			.map((key) => HashedMapUtil.hashMapBucketValuesGet(hashMap, key))
			.filter((items) => items.length > 0)
			.map((idHashItems) => idHashItems.map((hshItem) => hshItem.data))
			.reduce((previous: T[], newValue: T[]) => previous.concat(newValue), []);
	}

	/**
	 * Append the items into the list
	 *
	 * @param hashMap           Hash map which we want to add the data too
	 * @param item              Item which we want to add
	 * @param idFunction        The function which we will use to identify the unique key of the item
	 */
	public static hashMapAppendItem<T>(
		hashMap: HashedMapStorage<T>,
		item: T,
		idFunction: (item: T) => string
	): void {
		//No have map, item or no id function, nothing to be done!
		if (!hashMap || !item || !idFunction) return;

		//Call the id function to get the id of the item
		let itemId = idFunction(item);

		//If the item has no id then we can't really store it
		if (!itemId) return;

		//Get a bucket reference. This will give us the location of our map
		let bucketReference = HashedMapUtil.hashMapBucketReferenceGet(
			itemId,
			hashMap.mapBucketCount
		);

		//Take our list of bucket values and filter out the item which we are appending
		let currentValues = HashedMapUtil.hashMapBucketValueRemoveItem(
			hashMap,
			bucketReference,
			itemId
		);

		//Create a wrapper of the items
		let dataItem: IdHashItem<T> = {
			id: itemId,
			data: item,
			timestamp: new Date().getTime(),
		};

		//Append our new item to the list
		currentValues.push(dataItem);

		//Update the hash map item count
		hashMap.itemCount++;

		//Set the data back into the list
		HashedMapUtil.hashMapBucketValuesSet(
			hashMap,
			bucketReference,
			currentValues
		);
	}

	/**
	 * Append all the items in the array into the hash map
	 *
	 * @param hashMap       Hash map which we want to add the data too
	 * @param items         The items which we want to add to the map
	 * @param idFunction    The function which we will use to identify the unique key of the items
	 */
	public static hashMapAppendItems<T>(
		hashMap: HashedMapStorage<T>,
		items: T[],
		idFunction: (item: T) => string
	): void {
		//Do we have the data we need? No bail!
		if (!hashMap || !items || !idFunction) return;

		//Call the append for every item in the array
		items.forEach((item) =>
			HashedMapUtil.hashMapAppendItem(hashMap, item, idFunction)
		);
	}

	/**
	 * Remove a specific item from one of the hash map, to do this we must
	 *
	 * @param hashMap               The hash map which we want to remove the item from
	 * @param id                    Id of the item which we want to remove
	 */
	public static hashMapRemoveItem<T>(
		hashMap: HashedMapStorage<T>,
		id: string
	): void {
		//Do we have the data we need? No bail!
		if (!hashMap || !id) return;

		//Call the remove value function after we have worked out where it is.
		HashedMapUtil.hashMapBucketValueRemoveItem(
			hashMap,
			HashedMapUtil.hashMapBucketReferenceGet(id, hashMap.mapBucketCount),
			id
		);
	}

	/**
	 * Remove a specific item from one of the hash map, to do this we must
	 *
	 * @param hashMap               The hash map which we want to remove the item from
	 * @param ids                   Ids of the item which we want to remove
	 */
	public static hashMapRemoveItems<T>(
		hashMap: HashedMapStorage<T>,
		ids: string[]
	): void {
		//Do we have the data we need? No bail!
		if (!hashMap || !ids) return;

		//Go through each of the items removing them one by one
		ids.forEach((id) => HashedMapUtil.hashMapRemoveItem(hashMap, id));
	}

	/**
	 * Remove a specific item from one of the hash map, to do this we must
	 *
	 * @param hashMap               The hash map which we want to remove the item from
	 * @param bucketReference       Bucket reference which we want to remove the item from
	 * @param id                    Id of the item which we want to remove
	 *
	 * @returns                     Returns the contents of the bucket post remove
	 */
	public static hashMapBucketValueRemoveItem<T>(
		hashMap: HashedMapStorage<T>,
		bucketReference: string,
		id: string
	): IdHashItem<T>[] {
		//Get the current values from the bucket values
		let currentValues = HashedMapUtil.hashMapBucketValuesGet(
			hashMap,
			bucketReference
		);

		//Get the index of the item which match the id specified
		let itemIndex = currentValues.findIndex((item) => item.id == id);

		//Item isn't in the list so we will bail out!
		if (itemIndex < 0) return currentValues;

		//Remove the item from the list
		currentValues.splice(itemIndex, 1);

		//Set the values into the bucket
		HashedMapUtil.hashMapBucketValuesSet(
			hashMap,
			bucketReference,
			currentValues
		);

		//Update the hash map item count
		hashMap.itemCount--;

		//Return the current values
		return currentValues;
	}

	/**
	 * Gets the items within the map which are older than a specified time
	 *
	 * @param timestamp     Timestamp which we want to get the items for
	 */
	public static hashMapItemIdsOlderThan<T>(
		hashMap: HashedMapStorage<T>,
		timestamp: number
	): string[] {
		//Get the bucket references
		let bucketReferences = HashedMapUtil.hashMapBucketReferencesGet(hashMap);

		//Loop through each of the bucket references so we can collate a list of id's of old data items
		//which we want to remove from the bucket
		return bucketReferences
			.map((bucketReference) => {
				//Get the items from the bucket by the reference specified
				let items = HashedMapUtil.hashMapBucketValuesGet(
					hashMap,
					bucketReference
				);

				//Filter this list down to the items which we want to remove. Then we will map this to id's
				return items
					.filter((item) => item.timestamp < timestamp)
					.map((item) => item.id);
			})
			.filter((oldItems) => oldItems && oldItems.length > 0)
			.reduce((previous, newValue) => previous.concat(newValue), []);
	}

	/**
	 * Gets the specific bucket values based on a specific id
	 *
	 * @param hashMap               The hash map we want the bucket from
	 * @param bucketReference       Reference which we will use to get the bucket from the map
	 *
	 * @return                      Get the values stored in the bucket
	 */
	private static hashMapBucketValuesGet<T>(
		hashMap: HashedMapStorage<T>,
		bucketReference: string
	): IdHashItem<T>[] {
		return hashMap.map.hasOwnProperty(bucketReference) &&
			hashMap.map[bucketReference]
			? hashMap.map[bucketReference]
			: [];
	}

	/**
	 * Gets the specific bucket based on a specific id
	 *
	 * @param hashMap               The hash map we want the bucket from
	 * @param bucketReference       Reference which we will use to get the bucket from the map
	 * @param data`                 Data which we want to set into the hash map
	 *
	 * @return                      Get the values stored in the bucket
	 */
	private static hashMapBucketValuesSet<T>(
		hashMap: HashedMapStorage<T>,
		bucketReference: string,
		data: IdHashItem<T>[]
	): void {
		//If we have values to set in the store then we will set the data
		if (data && data.length > 0) {
			hashMap.map[bucketReference] = data;
		} else {
			//No values so we might as well remove the values from the hash map
			delete hashMap.map[bucketReference];
		}
	}

	/**
	 * Generate a hash reference for the reference supplied for the key size specified
	 *
	 * @param id                he id which we want to generate a bucket reference for
	 * @param hashMapSize       The size of the hash map
	 */
	private static hashMapBucketReferenceGet(
		id: string,
		hashMapSize: number
	): string {
		//Results uint which the hashed result will be stored in
		let result: number = 0;

		//Get the string as a number
		let stringAsNumber = Number(id);

		//If the string is a number then we will get it
		if (!Number.isNaN(stringAsNumber)) {
			return (stringAsNumber % hashMapSize).toString();
		}

		//If not we will deal with it like a string

		//Check that we have been passed a reference
		if (id) {
			for (let i: number = 0; i < id.length; i++) {
				result ^= id.charCodeAt(i);
			}
		}

		//Retun the result
		return (result % hashMapSize).toString();
	}
}

/**
 * Interface for the id hash item
 */
export interface IdHashItem<T> {
	id: string;
	data: T;
	timestamp;
}
