import {
	Component,
	ContentChild,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormControl,
} from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { componentDestroyStream, Hark } from "../../hark.decorator";
import { Utils } from "../../utils";

@Component({
	selector: "app-select-with-search-grouped",
	templateUrl: "./select-with-search-grouped.component.html",
	styleUrls: ["./select-with-search-grouped.component.css"],
})
@Hark()
export class SelectWithSearchGroupedComponent implements OnInit, OnChanges {
	@ContentChild("selectOption") optionTemplate: TemplateRef<any>;

	// search input field, allows us to give it focus
	@ViewChild("searchInput", { static: true }) searchInput;

	@Input() placeholder: string = "PLACEHOLDER NOT SET!";

	@Input() groupLabel: string = "";

	/**
	 * The form search input control name.
	 */
	selectFormControl: UntypedFormControl;
	@Input("selectFormControl") set _selectFormControl(
		abstractControl: AbstractControl
	) {
		this.selectFormControl = abstractControl as UntypedFormControl;
	}

	/**
	 * function provided by parent which returns the value we should set when an option is selected (e.g. id)
	 */
	@Input() getIdFunction;

	/**
	 * Optional variable to hold selected option id if we dont want to use this conmponent with a form control (see above).
	 */
	_selectNgModel;
	@Input() set selectNgModel(selectNgModel) {
		this._selectNgModel = selectNgModel;
		if (this.selectFormControl) {
			this.selectFormControl.setValue(selectNgModel, { emitEvent: false });
			if (this.disabled) {
				this.selectFormControl.disable();
			}
			this.selectedOption = this.findOptionById(
				this.groupedOptions,
				this.getIdFunction(this.selectFormControl.value)
			);
		}
	}

	@Input() disabled?: boolean = false; //Used to disable this input if we are not using form input (e.g. using selectNgModel)

	/**
	 * function provided by parent which returns the value we should search against for specified option (object)
	 */
	@Input() searchableOptionValueFunction;

	/**
	 * Input which holds all the available grouped options
	 */
	@Input() groupedOptions: GroupedOptions[] = undefined;

	cleanGroupedOptions: GroupedOptions[];

	_disabledOptionIds: number[];
	_disabledOptionsByIdWithReason: Map<number, string> = new Map();
	@Input() set disabledOptionsByIdWithReason(
		disabledOptions: Map<number, string>
	) {
		if (disabledOptions) {
			this._disabledOptionIds = Array.from(disabledOptions.keys());
			this._disabledOptionsByIdWithReason = disabledOptions;
		}
	}

	@Output() selectedValueChanged: EventEmitter<any> = new EventEmitter();

	// Used to help display the selected option in the same way as the options in the list
	selectedOption: any;

	allSelectOptions: any[];

	constructor(private readonly formBuilder: UntypedFormBuilder) {}

	ngOnInit() {
		// If we dont have a form control for this select, we can use an ng model approach
		// Internally we will still use a form control, but when the selection changes we will emit an event with the new id
		if (this._selectNgModel !== undefined) {
			this.selectFormControl = new UntypedFormControl({
				value: this._selectNgModel,
				disabled: this.disabled,
			});
		}

		if (this.selectFormControl.value) {
			// Initialise selected option
			this.selectedOption = this.findOptionById(
				this.groupedOptions,
				this.getIdFunction(this.selectFormControl.value)
			);
			this.selectFormControl.setValue(this.selectedOption);
		}

		this.selectFormControl.valueChanges
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((value) => {
				// Lets grab an 'option' by the id of the selected option, such that the view of a selected option looks like the drop down options.
				// (see mat-select-trigger)

				this.selectedOption = this.findOptionById(
					this.groupedOptions,
					this.getIdFunction(value)
				);

				this.selectedValueChanged.emit(this.selectedOption);
			});
	}

	ngOnDestroy() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes["groupedOptions"]) {
			this.cleanGroupedOptions = Utils.clone(this.groupedOptions);

			// Oh the options have changed, lets find the currently set form value and assign the selectedOption
			if (
				this.selectFormControl &&
				this.selectFormControl.value &&
				this.groupedOptions
			) {
				this.selectedOption = this.findOptionById(
					this.groupedOptions,
					this.getIdFunction(this.selectFormControl.value)
				);

				this.selectedValueChanged.emit(this.selectedOption);
			}
		}
	}

	findOptionById(
		groupedOptionsToSearch: GroupedOptions[],
		idToFind: number
	): any {
		let foundOption: any;
		if (groupedOptionsToSearch) {
			for (const optionGroup of groupedOptionsToSearch) {
				foundOption = optionGroup?.options?.find(
					(option) => this.getIdFunction(option) === idToFind
				);
				if (foundOption) {
					break;
				}
			}
		}
		return foundOption;
	}

	focusSearchInput($opened) {
		// When closing select box we need to restore default state of search box, grouped options and selected option of the form
		if (!$opened) {
			this.searchInput.nativeElement.value = "";
			this.groupedOptions = Utils.clone(this.cleanGroupedOptions);
			this.selectedOption = this.findOptionById(
				this.groupedOptions,
				this.getIdFunction(this.selectFormControl.value)
			);
			this.selectFormControl.setValue(this.selectedOption);
		} else {
			setTimeout(() => {
				this.searchInput.nativeElement.focus();
			}, 100);
		}
	}

	searchTermChanged($event) {
		if ($event.key === "ArrowUp" || $event.key === "ArrowDown") {
			return;
		}

		const searchTerm = $event.target.value;

		this.updateOptionsForSearchTerm(searchTerm);
	}

	updateOptionsForSearchTerm(searchTerm: string) {
		if (searchTerm.length > 0) {
			const inputValueInLowerCase = searchTerm.toLowerCase();

			this.groupedOptions = Utils.clone(this.cleanGroupedOptions).map(
				(optionGroup) => {
					optionGroup.options = optionGroup?.options?.filter((option) => {
						const searchableValue = this.searchableOptionValueFunction(option);
						return searchableValue
							.toString()
							.toLowerCase()
							.includes(inputValueInLowerCase);
					});
					return optionGroup;
				}
			);

			// Lets lose the groups which no longer have any options
			this.groupedOptions = this.groupedOptions.filter(
				(optionGroup) => optionGroup.options.length > 0
			);
		} else {
			// no search term, lets put the whole list back
			this.groupedOptions = Utils.clone(this.cleanGroupedOptions);
		}
	}

	shouldOptionBeDisabled(option) {
		let disable = false;

		if (this._disabledOptionIds) {
			const optionId = this.getIdFunction(option);

			disable = this._disabledOptionIds.includes(optionId);
		}
		return disable;
	}
}

export interface GroupedOptions {
	groupName: string;
	options: any[];
}
