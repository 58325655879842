/**
 * Core
 */
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AHubActions } from "app/store/actions/ahub.actions";
import { StoreAccess } from "app/store/store-access";
import { footerLabel, version } from "assets/jenkins_consts";
import { environment } from "environments/environment";
import { Hark } from "../common/hark.decorator";
import { AppComponent } from "app/app.component";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.css"],
})
@Hark()
export class FooterComponent implements OnInit, OnDestroy {
	supportedBrowser = AppComponent.browserSupported;
	clusterId: string = environment.name;
	version: string = version;
	footerLabel: string = footerLabel;

	constructor(private router: Router) {}

	ngOnInit() {
		if (this.footerLabel == "<FOOTER-LABEL>") {
			this.footerLabel = "LOCAL";
		}
		// Work around for bug where environment variables are lost when building using aot...
		// this is a problem here because gamma is built using this flag and we want the footer to show we are on gamma
		if (
			(this.clusterId == undefined || this.clusterId == "") &&
			window.location.hostname.indexOf("gamma") != -1
		) {
			this.clusterId = "GAMMA";
		}
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	production() {
		return this.version.match(/^\d/);
	}
}
