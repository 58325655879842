import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "app-percentage-circle",
	templateUrl: "./percentage-circle.component.html",
	styleUrls: ["./percentage-circle.component.css"],
})
export class PercentageCircleComponent implements OnInit {
	@Input() total: number = undefined; // Total circle value.
	@Input() value: number = 0; // Primary Value
	@Input() underlayValue: number = 30; // Secondary value draw over the top of the first value. -1 to disable.
	@Input() valueLabel: string = ""; // Label for the value.
	@Input() underlayValueLabel: string = ""; // Label for the unlderlying value.
	@Input() valueColour: string = "#55c1b5"; // Dark Green Colour of the value. #55c1b5 #fe6c60 #ff4081
	@Input() warnColour: string = "#fe6c60"; // Orange Colour to use if exceeding warning colour.
	@Input() alertColour: string = "#ff4081"; // Red
	@Input() alertPerc: number = 100;
	@Input() warnPerc: number = 75;
	@Input() strokeWidthFraction: number = 0.025; // Stroke width as a fraction of the radius 1/10.
	@Input() strokeOpacity: number = 1; // Ability to fade the stroke colour, can be useful on small circles as it heps with anti-aliasing.
	@Input() valueDisplay: string = "fraction"; // Options are none ( or any unmatched value ) , fraction, value , percentage
	@Input() strokeOffset: number = 0.25;
	@Input() usedCircumference: number = 1.0; // How much of the circumference should we use.
	@Input() unitsLabel: string = ""; // Units label to use, disables aggregation ie.. K and M.

	valuePercentage: number = 0; // Calculated percentage.
	underlayValuePercentage: number = 0; // Calculated percentage.
	valuePercentageDisplay: string; // Calculated percentage formatted for display.
	valueColourStroke: string; // Colour to use for the value fist stroke.
	underlayValueColourStroke: string; // Colour to use for the overlay stroke.

	// Visual circle dimensions.
	circleRadius = 28;
	circleCircumference = this.circleRadius * 2 * Math.PI;

	// Colours to use and dash array ( which is used to dash part of the circle to make it partially fill. )
	strokeWidth: number;
	strokeStart: number;
	underlayValueStrokeDashArray = [0, this.circleCircumference];
	valueStrokeDashArray = [0, this.circleCircumference];
	trackStrokeDashArray = [this.circleCircumference, 0];
	fromStrokeDashArray = [0, this.circleCircumference];

	// value tool tips.
	valueTooltip: string;
	underlayValueTooltip: string;

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		// Compute display properties
		this.strokeWidth = 3;
		this.strokeStart = this.circleCircumference * this.strokeOffset;
		this.trackStrokeDashArray = [
			this.circleCircumference * this.usedCircumference,
			this.circleCircumference -
				this.circleCircumference * this.usedCircumference,
		];

		// Set the tooltip values.
		this.valueTooltip = this.valueLabel;
		this.underlayValueTooltip = this.underlayValueLabel;

		// Refresh teh display. not just colours but shape too!
		this.setSpinnerColours();
	}

	ngOnChanges(changes) {
		this.setSpinnerColours();
	}

	setSpinnerColours() {
		this.valuePercentage = Math.max(
			0,
			Math.min(
				1,
				this.total > 0 ? this.value / this.total : this.total <= 0 ? 1 : 0
			)
		);
		this.valuePercentageDisplay = Math.round(this.valuePercentage * 100) + "%";
		this.underlayValuePercentage = Math.max(
			0,
			Math.min(
				1,
				this.total > 0
					? this.underlayValue / this.total
					: this.total <= 0
					? 1
					: 0
			)
		);

		// Determin the colour of the stroke on the percentage values. ( for warning colours ).
		this.underlayValueColourStroke = this.getArcColour(
			this.underlayValuePercentage
		);
		this.valueColourStroke = this.getArcColour(this.valuePercentage);

		// Set the dash pattern to make teh correct line length around the circle.
		let underlayValueFillArc = Math.abs(
			this.underlayValuePercentage *
				this.circleCircumference *
				this.usedCircumference
		);
		let valueFillArc = Math.abs(
			this.valuePercentage * this.circleCircumference * this.usedCircumference
		);
		this.underlayValueStrokeDashArray = [
			underlayValueFillArc,
			this.circleCircumference - underlayValueFillArc,
		];
		this.valueStrokeDashArray = [
			valueFillArc,
			this.circleCircumference - valueFillArc,
		];
	}

	/**
	 * Determines the stoke colour based on the arc amount, and limit values.
	 */
	getArcColour(valuePerc: number) {
		// If we are dealing with an infinite total.. we can leave it at that.
		if (this.total == -1) return this.valueColour;

		// If the percentage exceeds our alert value.. then set alert colour.
		if (valuePerc >= this.alertPerc) return this.alertColour;

		// If the percentage exceeds our warn colour return that.
		if (valuePerc >= this.warnPerc) return this.warnColour;

		// Otherwise , use primary..
		return this.valueColour;
	}
}
