import { TreeStructure } from "./tree-structure.class";

export function removeLeadingAndTrailingAncestryCommas(input) {
	let value = input;

	if (input) {
		value = input.replace(/(^,)|(,$)/g, "");
	}

	return value;
}

/**
 *  Function that takes an id of a tree and returns a function that
 *  can remove subtress from a tree where the id matches.
 *  This can be used on the recursive tree- structure class.
 */
export function removeSubTreesWithIdsFunction(ids: string[]): Function {
	return (tree: TreeStructure) => {
		// Loop through an remove..
		for (
			let subTreeIndex = tree.subTrees.length - 1;
			subTreeIndex >= 0;
			subTreeIndex--
		) {
			if (ids.indexOf(tree.subTrees[subTreeIndex].id) > -1) {
				TreeStructure.subTreeRemove(tree.subTrees[subTreeIndex].id, tree);
			}
		}
		return tree;
	};
}

// Returns node depth of the node passed. Assumes node will have an 'ancestry' field
export function getFlatDataNodeDepth(dataNode): number {
	return includeIdInAncestry(
		removeLeadingAndTrailingAncestryCommas(dataNode.ancestry),
		dataNode.id
	).split(",").length;
}

export function includeIdInAncestry(ancestry, id): string {
	return ancestry ? `${ancestry},${id}` : id.toString();
}

export function getPathOfTreeStructureNodesToRoot(
	tree: TreeStructure
): TreeStructure[] {
	const nodesToRoot = [];
	nodesToRoot.push(tree);
	let parentTree = tree.parentTree;
	while (parentTree) {
		nodesToRoot.push(parentTree);
		parentTree = parentTree.parentTree;
	}
	return nodesToRoot;
}

export function treeIds(subtree: TreeStructure): string[] {
	let ids: string[] = [subtree.id];

	subtree.subTrees.forEach((subTree) => {
		ids = ids.concat(treeIds(subTree));
	});
	return ids;
}

export function getTreeNodeById(
	tree: TreeStructure,
	idToFind: string
): TreeStructure {
	// Set up the tree we might find.
	let foundTreeNode: TreeStructure = undefined;

	// Do we have a tree and id to find?
	if (tree && idToFind) {
		// Is this the tree we want? If so, return it.
		if (tree.id === idToFind) {
			return tree;
		}

		//Loop through the items
		for (let subTree of tree.subTrees) {
			// Try and find it.
			foundTreeNode = getTreeNodeById(subTree, idToFind);

			// If we find one, return true, this stops the "some" function.
			if (foundTreeNode) {
				break;
			}
		}
	}

	// Return any found tree node.
	return foundTreeNode;
}
