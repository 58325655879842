/**
 * Action Types
 */
import { ActionNumber } from "actions/types/common.action-types";
/**
 * Actions
 */
import { ViewActions } from "actions/view.actions";
import { tassign } from "modules/common/type-assign.util";
import { createReducer } from "reducers/reducer-util";
import { Reducer } from "redux";

/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ViewIntegrationState {
	selectedClientFileChangeSetId: number;
	selectedJobId: number;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewIntegrationInitialState: ViewIntegrationState = {
	selectedClientFileChangeSetId: -1,
	selectedJobId: -1,
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the selectedClientFileChangeSetId
 */
export const selectedClientFileChangeSetIdSet = (
	state: ViewIntegrationState,
	action: ActionNumber
) => {
	//Set the selected export id
	return tassign(state, { selectedClientFileChangeSetId: action.number });
};

/**
 * Set the selectedJobId
 */
export const selectedJobIdSet = (
	state: ViewIntegrationState,
	action: ActionNumber
) => {
	//Set the selected job id
	return tassign(state, { selectedJobId: action.number });
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer
 */
reducerHandlers[ViewActions.SELECTED_CLIENT_FILE_CHANGE_SET_ID_SET] =
	selectedClientFileChangeSetIdSet;
reducerHandlers[ViewActions.SELECTED_JOB_ID_SET] = selectedJobIdSet;

/**
 * Create a reducers based on the reducers handlers
 */
export const ViewIntegrationStateReducer: Reducer<ViewIntegrationState> =
	createReducer(ViewIntegrationInitialState, reducerHandlers);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewIntegrationStateHasHandler = (actionType: string): boolean =>
	reducerHandlers.hasOwnProperty(actionType);
