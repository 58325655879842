import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { IHeaderAngularComp } from "ag-grid-angular";
import { IAfterGuiAttachedParams, IHeaderParams } from "ag-grid-community";
import {
	componentDestroyStream,
	Hark,
} from "app/modules/common/hark.decorator";
import { Utils } from "app/modules/common/utils";
import { selectionProductFeedbackViews } from "app/store/selector/acatalog.selector";
import { aViewComponentSelectedSelectionId } from "app/store/selector/aview.selector";
import { StoreAccess } from "app/store/store-access";
import { Observable } from "rxjs";
import {
	map,
	publishReplay,
	refCount,
	switchMap,
	takeUntil,
} from "rxjs/operators";
import { FeedbackView } from "../../../feedback/feedback-message-widget/feedback-message-widget.component";

@Component({
	selector: "app-grid-selection-mode-header",
	templateUrl: "./grid-aview-product-full-header.component.html",
	styleUrls: ["./grid-aview-product-full-header.component.css"],
})
@Hark()
export class GridAviewProductFullHeaderComponent
	implements OnInit, IHeaderAngularComp, OnDestroy
{
	params: any;

	ascSort: string;
	descSort: string;
	noSort: string;
	filter: boolean;

	uniqueComponentId: string;
	productId: number;
	allocactionId: number;

	selectedSelectionId$: Observable<number>;
	allocationFeedbackViews$: Observable<FeedbackView[]>;

	private readonly elementRef: ElementRef;

	constructor(elementRef: ElementRef) {
		this.elementRef = elementRef;
	}

	refresh(params: IHeaderParams): boolean {
		return JSON.stringify(this.params) === JSON.stringify(params);
	}

	afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
		throw new Error("Method not implemented.");
	}

	agInit(params: any): void {
		this.params = params;
		this.uniqueComponentId = params.uniqueComponentId;
		this.productId = params.productId;
		this.allocactionId = params.allocationId;

		this.selectedSelectionId$ = StoreAccess.dataGetObvs(
			aViewComponentSelectedSelectionId(this.uniqueComponentId)
		).pipe(takeUntil(componentDestroyStream(this)));

		this.allocationFeedbackViews$ = this.selectedSelectionId$.pipe(
			Utils.isNotNullOrUndefined(),
			switchMap((selectionId) =>
				StoreAccess.dataGetObvs(
					selectionProductFeedbackViews(selectionId, this.productId)
				)
			),
			map(
				(productFeedbackViews) =>
					productFeedbackViews?.filter(
						(feedbackView) => feedbackView.allocationId === this.allocactionId
					) || []
			),
			takeUntil(componentDestroyStream(this)),
			publishReplay(1),
			refCount()
		);

		this.params.column.addEventListener(
			"sortChanged",
			this.onSortChanged.bind(this)
		);
		this.params.column.addEventListener(
			"filterChanged",
			this.onFilterChanged.bind(this)
		);
		this.onSortChanged();
		this.onFilterChanged();
	}

	ngOnInit(): void {
		// Needs to be here for Hark decorator
	}

	ngOnDestroy() {
		this.params.column.removeEventListener(
			"sortChanged",
			this.onSortChanged.bind(this)
		);
		this.params.column.removeEventListener(
			"filterChanged",
			this.onFilterChanged.bind(this)
		);
	}

	onMenuClick() {
		this.params.showColumnMenu(
			this.querySelector(".ag-header-cell-menu-button")
		);
	}

	onSortRequested(order, event) {
		this.params.setSort(order, event.shiftKey);
	}

	onSortChanged() {
		this.ascSort = this.descSort = this.noSort = "inactive";
		if (this.params.column.isSortAscending()) {
			this.ascSort = "active";
		} else if (this.params.column.isSortDescending()) {
			this.descSort = "active";
		} else {
			this.noSort = "active";
		}
	}

	onFilterChanged() {
		this.filter = this.params.column.isFilterActive();
	}

	private querySelector(selector: string): HTMLElement {
		return this.elementRef.nativeElement.querySelector(selector) as HTMLElement;
	}
}
