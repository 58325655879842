/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

/**
 * Utilities
 */
import { SelectorUtil } from "store/selector.util";
import { ListUtil } from "store/list.util";

/**
 * Redcuer
 */
import { ViewLibraryClassificationState } from "reducers/view/view-library-classification.reducer";

/**
 * Selectors
 */
import { viewState } from "selector/view/view.selector";

import {
	aHubStateTemporaryProductPropertySectionIndexes,
	aHubStateTemporaryProductPropertySectionList,
	aHubStateTemporaryProductPropertyList,
} from "selector/ahub/ahub-temporary.selector";

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */
import { ProductClassAHubVO } from "valueObjects/ahub/library/product-class.ahub.vo";
import { RelationshipAHubVO } from "valueObjects/ahub/relationship.ahub.vo";
import { UserIndexAHubVO } from "valueObjects/ahub/accounts/user-index.ahub.vo";
import { ProductPropertySectionIndexAHubVO } from "valueObjects/ahub/library/product-property-section-index.ahub.vo";
import { ProductPropertySectionAHubVO } from "valueObjects/ahub/library/product-property-section.ahub.vo";
import { ProductPropertyAHubVO } from "valueObjects/ahub/library/product-property.ahub.vo";
import { ProductClassIndexAHubVO } from "valueObjects/ahub/library/product-class-index.ahub.vo";

/**
 * Root selector to get the library classification view state from the store state
 */
const viewLibraryClassificationState = (
	state
): ViewLibraryClassificationState => viewState(state).libraryClassification;

/**
 * --------------------------------------------------
 * Selectors
 * -------------------------------------------------- */

/**
 * A selector to access the product class wrappers
 */
export const viewProductSectionId = (state): number =>
	viewLibraryClassificationState(state).productSectionSelectedId;

/**
 * A selector to access the selected property id
 */
export const viewSelectedPropertyId = (state): number =>
	viewLibraryClassificationState(state).propertySelectedId;

/**
 * A selector  which combines an aHub selector and the currently select item to
 */
export const viewSelectedProperty = (state): ProductPropertyAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryProductPropertyList(state),
		viewSelectedPropertyId(state)
	);

/**
 * A selector to access the currently selected classes.
 */
export const viewSelectedProductClasses = (state): ProductClassIndexAHubVO[] =>
	viewLibraryClassificationState(state).productClassesSelected;

/**
 * Currently selected product section
 */
export const viewProductSectionIndex = (
	state
): ProductPropertySectionIndexAHubVO => {
	//Get the data from the reducers
	let sectionId =
		viewLibraryClassificationState(state).productSectionSelectedId;
	let sections = aHubStateTemporaryProductPropertySectionIndexes(state);

	//Bail out if the data is undefined
	if (sectionId < 0 || sections == undefined) return undefined;

	//Return the section which matches the id supplied
	return sections.find((section) => section.id == sectionId);
};

/**
 * A selector which combines an aHub selector and the currently select section id.
 */
export const viewProductSection = (state): ProductPropertySectionAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryProductPropertySectionList(state),
		viewProductSectionId(state)
	);

/**
 * A selector to property icon map
 */
export const viewPropertyIconMap = (state): {} =>
	viewLibraryClassificationState(state).propertyIconMap;

/**
 * A selector to get the currently selected section property id.
 */
export const viewSelectedSectionPropertyId = (state): number =>
	viewLibraryClassificationState(state).productSelectedSectionPropertyId;
