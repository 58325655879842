import { Component, Input, OnInit } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ComponentDialogComponent } from "app/modules/common/dialogs/component-dialog/component-dialog.component";
import {
	componentDestroyStream,
	Hark,
} from "app/modules/common/hark.decorator";
import { BehaviorSubject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "app-dataset-products-view-search-dialog",
	templateUrl: "./dataset-products-view-search-dialog.component.html",
	styleUrls: ["./dataset-products-view-search-dialog.component.css"],
})
@Hark()
export class DatasetProductsViewSearchDialogComponent implements OnInit {
	/**
	 * Create an instance of the search input control
	 */
	searchInputControl = ["", [Validators.required, Validators.minLength(2)]];

	/**
	 * Form group to control the
	 */
	searchControlForm: UntypedFormGroup = this.formBuilder.group({
		searchInput: this.searchInputControl,
	});

	/**
	 * Stream which indicates if the stream is valid or not!
	 */
	public formValid$: BehaviorSubject<boolean> = new BehaviorSubject(true);

	/**
	 * Search term!
	 */
	@Input()
	public searchTerm: string = null;

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly dialogRef: MatDialogRef<ComponentDialogComponent>
	) {}

	ngOnInit() {
		//Do we have a search term and value
		if (this.searchTerm) {
			//Patch the existing value into the form
			this.searchControlForm.patchValue({ searchInput: this.searchTerm });

			//Update this now we have supplied it with a value
			this.formValid$.next(true);
		}

		//Subscribe to the value changes
		this.searchControlForm.valueChanges
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((value) => {
				//Set the search term!
				if (value && value.searchInput) {
					this.searchTerm = value.searchInput;
				}

				//Indicate if the form is valid or not
				this.formValid$.next(this.searchControlForm.valid);
			});
	}

	closeDialogAndSearchOnKeyupEnter() {
		this.dialogRef.close(this.searchTerm);
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}
}
