import { Component, AfterViewInit } from "@angular/core";

import { IFloatingFilter } from "ag-grid-community";
import { AgFrameworkComponent } from "ag-grid-angular";

import {
	GridFilterFloatingSelectParams,
	GridFilterSelectModel,
	GridFilterSelectOption,
	getSelectedObjectFromValue,
} from "modules/common/components/ag-grid/filters/grid-filter-select-base";

@Component({
	selector: "app-grid-filter-select-floating",
	templateUrl: "./grid-filter-select-floating.component.html",
	styleUrls: ["./grid-filter-select-floating.component.css"],
})
export class GridFilterSelectFloatingComponent
	implements IFloatingFilter, AgFrameworkComponent<any>
{
	/**
	 * Parameters for the filter
	 */
	params: GridFilterFloatingSelectParams;

	/**
	 * Selected object from the dropdown
	 */
	selectedObject: GridFilterSelectOption = undefined;

	constructor() {
		// This is intentional
	}

	/**
	 * Initialistion of the ag grid componenet
	 */
	agInit(params) {
		//Set the parameters
		this.params = params as GridFilterFloatingSelectParams;
	}

	/**
	 * Handel the change of value
	 */
	onChange(value) {
		//Set the filter value
		this.setSelectedObjectFromValue(value);

		//Call for the value to be updated
		this.valueChanged();
	}

	/**
	 * Triggered when the filter value has changed
	 */
	valueChanged() {
		//Get the selected value
		const selectedValue = this.selectedObject
			? this.selectedObject.value
			: undefined;

		//Get the parent instance so we can set the value directly
		this.params.parentFilterInstance((instance) => {
			instance.setModel({
				selectedValue: selectedValue as GridFilterSelectModel,
			});
		});
	}

	/**
	 * Triggered when the parent model changed
	 */
	onParentModelChanged(parentModel: GridFilterSelectModel): void {
		//Do we have a parent model?
		if (parentModel) {
			//Set the filter value
			this.setSelectedObjectFromValue(parentModel.selectedValue);
		} else {
			//Filter value reset to the disabled value
			this.setSelectedObjectFromValue(this.params.defaultSelectedFilterValue);
		}
	}

	/**
	 * Set the currently selected object based on the passed value
	 */
	setSelectedObjectFromValue(value) {
		this.selectedObject = getSelectedObjectFromValue(value, this.params);
	}
}
