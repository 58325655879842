import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AhubAuthInterceptorUtilsService } from "services/http/interceptors/ahub-auth-interceptor-utils.service";
let API_PATH_REGEX = /.+?\:\/\/.+?(\/.+?)(?:#|\?|$)/;

@Injectable()
export class AhubContentTypeInterceptorService implements HttpInterceptor {
	constructor(private interceptorUtils: AhubAuthInterceptorUtilsService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// If this is an upload put request we need an octet-stream as our content-type
		if (req.urlWithParams.indexOf("X-Amz-Algorithm") !== -1) {
			req = req.clone({
				setHeaders: {
					"Content-Type": "application/octet-stream",
				},
			});
		} else {
			req = req.clone({
				setHeaders: {
					"Content-Type": "application/json",
				},
			});
		}

		return next.handle(req);
	}
}
