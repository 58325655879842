import { Component } from "@angular/core";

@Component({
	selector: "app-empty-component",
	template: "",
})
/**
 * It is ocassionally it is useful to have an empty component this is such a component
 */
export class EmptyComponent {
	constructor() {
		// This is intentional
	}
}
