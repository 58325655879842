import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-confirm-dialog",
	templateUrl: "./confirm-dialog.component.html",
	styleUrls: ["./confirm-dialog.component.css"],
})
export class ConfirmDialogComponent implements OnInit {
	/**
	 * This is the title and content to display in this confirmation dialog.
	 */
	public title: string;
	public content: string;

	/**
	 * These are the labels to be used on the confirm and cancel buttons.
	 */
	public confirmButtonLabel: string;
	public cancelButtonLabel: string;

	constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}

	ngOnInit() {}
}
