import { DataSetLibraryViewClassConfigWithInheritanceAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-class-config-with-inheritance.ahub.vo";
import { DataSetLibraryViewClassConfigAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-class-config.ahub.vo";
import { DataSetLibraryViewConfigAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-config.ahub.vo";
import { ProductClassIndexAHubVO } from "app/valueObjects/ahub/library/product-class-index.ahub.vo";
import { mergeWith } from "lodash";
import { Utils } from "./utils";

export class LibraryViewUtils {
	/**
	 * This method will return a 'merged' class library config for the selected class being passed in. The
	 * Merging will take each the parent class's config, filling in any blanks for each child, with a preference for
	 * nearer parent. e.g. selected class has main image specified, but nothing else, immediate parent class has
	 * different main image property specified and short description specified. grandparent class has product
	 * identifier specied. The resultant config for the selected class should show: main image from the selected
	 * class short description from the parent and product identifier from the grandparent
	 *
	 * @param libraryViewClassConfig
	 * @param dataset
	 * @param classIndex
	 */
	public static buildMergedClassLibraryViewConfigWithInheritanceFromClassAncestry(
		libraryViewClassConfig: DataSetLibraryViewClassConfigAHubVO,
		libraryViewConfig: DataSetLibraryViewConfigAHubVO,
		classIndex: ProductClassIndexAHubVO
	): DataSetLibraryViewClassConfigWithInheritanceAHubVO {
		let mergedClassLibraryViewConfig: DataSetLibraryViewClassConfigWithInheritanceAHubVO =
			libraryViewClassConfig;

		if (
			!classIndex ||
			!libraryViewConfig ||
			!libraryViewConfig.libraryViewClassConfigs ||
			libraryViewConfig.libraryViewClassConfigs.length === 0
		) {
			return mergedClassLibraryViewConfig;
		}

		const allClassLibraryViewConfigs =
			libraryViewConfig.libraryViewClassConfigs;

		const parentClassIds: number[] = this.getAncestryAsNumberArray(
			classIndex.ancestry
		);

		// As we would like to prioritise closer parent config,
		// we should reverse the default sort order of the ancestry
		parentClassIds.reverse();

		// Lets make a map container for inhertance of info allocs <allocId, (parent)classId>)
		const infoPropertyInheritanceMap: Map<number, number> = new Map();

		parentClassIds.forEach((parentClassId) => {
			const parentClassConfig = allClassLibraryViewConfigs.find(
				(classConfig) => classConfig.classId === parentClassId
			);
			if (parentClassConfig) {
				const configsKeysFromParent: string[] = [];
				mergedClassLibraryViewConfig = mergeWith(
					mergedClassLibraryViewConfig,
					parentClassConfig,
					(mergedValue, parentValue, key) => {
						switch (key) {
							case "productIdentifierPropertyAlloc":
							case "productMainImagePropertyAlloc":
								// Lets inherit our parents config if we're empty and make a note of where the config came from
								if (!mergedValue) {
									configsKeysFromParent.push(key);
									mergedValue = parentValue;
								}
								break;
							case "productInfoPropertyAllocs":
								// For the info alloc array we should 'uniquely' merge the arrays from
								// this class config and our parents
								if (parentValue) {
									mergedValue = mergedValue ? mergedValue : [];
									mergedValue = Array.from(
										new Set([...mergedValue, ...parentValue])
									);
									parentValue.forEach((val) => {
										infoPropertyInheritanceMap.set(val, parentClassId);
									});
									configsKeysFromParent.push(key);
								}
								break;
						}

						return mergedValue;
					}
				);

				configsKeysFromParent.forEach((configKey) => {
					switch (configKey) {
						case "productIdentifierPropertyAlloc":
						case "productMainImagePropertyAlloc":
							mergedClassLibraryViewConfig[configKey + "InheritedFromClassId"] =
								parentClassId;
							break;
						case "productInfoPropertyAllocs":
							if (
								infoPropertyInheritanceMap &&
								infoPropertyInheritanceMap.size > 0
							) {
								mergedClassLibraryViewConfig[
									"productInfoPropertyAllocsInheritanceMap"
								] = infoPropertyInheritanceMap;
							}
							break;
					}
				});
			}
		});

		return mergedClassLibraryViewConfig;
	}

	public static getAncestryAsNumberArray(ancestry: string): number[] {
		return ancestry
			? ancestry
					.split(",")
					.filter((element) => element)
					.map(Number)
			: [];
	}

	/**
	 *
	 * @param libraryViewConfig
	 * @param classIdOfTheConfigWeWant
	 * @param classIndex
	 *
	 * Returns the library view class config for the specified class, or its nearest parent, or undefined
	 */
	public static getNearestLibraryViewClassConfigByClassId(
		libraryViewConfig: DataSetLibraryViewConfigAHubVO,
		classIdOfTheConfigWeWant: number,
		classIndex: ProductClassIndexAHubVO[]
	): DataSetLibraryViewClassConfigAHubVO {
		let libraryViewClassConfig: DataSetLibraryViewClassConfigAHubVO;

		if (!libraryViewConfig || !libraryViewConfig.libraryViewClassConfigs) {
			return libraryViewClassConfig;
		}

		// Maybe the desired classId has a config, lets try to get that first
		libraryViewClassConfig = libraryViewConfig.libraryViewClassConfigs.find(
			(classConfig) => classConfig.classId === classIdOfTheConfigWeWant
		);

		if (!libraryViewClassConfig) {
			// Ok we didnt get lucky, lets walk the ancestry looking for a config
			const classWeWant: ProductClassIndexAHubVO = classIndex.find(
				(clazz) => clazz.id === classIdOfTheConfigWeWant
			);

			if (classWeWant) {
				const ancestryOfClassIdsOfTheClassWeWantSortedByNearestParentFirst: number[] =
					this.getAncestryAsNumberArray(classWeWant.ancestry).reverse();

				for (const classId of ancestryOfClassIdsOfTheClassWeWantSortedByNearestParentFirst) {
					libraryViewClassConfig =
						libraryViewConfig.libraryViewClassConfigs.find(
							(classConfig) => classConfig.classId === classId
						);
					// Did we find a suitable libreay view class config?
					if (libraryViewClassConfig) {
						// We did, cool lets foxtrot oscar
						break;
					}
				}
			}
		}

		return libraryViewClassConfig;
	}
}
