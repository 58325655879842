export const imageUrlPathSwap = (
	imageurl: string,
	targetType: string
): string => {
	// Make sure the taget is lower case.
	targetType = `.${targetType.toLowerCase()}`;

	// Now replace all of the possible extensions to make sure we get the right one.
	imageurl = imageurl.replace(".jpg", targetType);
	imageurl = imageurl.replace(".png", targetType);
	imageurl = imageurl.replace(".webp", targetType);
	return imageurl;
};

export const assetFileNameAddExtension = (
	assetFileName,
	targetType: string
): string => {
	return (assetFileName += `.${targetType.toLowerCase()}`);
};
