export class ExtractStateAHubEnum {
	static readonly READ_ONLY: string = "READ_ONLY";
	static readonly INITIAL: string = "INITIAL";
	static readonly WIP: string = "WIP";
	static readonly COMPLETE: string = "COMPLETE";
	static readonly ARCHIVED: string = "ARCHIVED";

	/**
	 * The full list of statuses.
	 */
	static readonly ALL_STATUSES: string[] = [
		ExtractStateAHubEnum.READ_ONLY,
		ExtractStateAHubEnum.INITIAL,
		ExtractStateAHubEnum.WIP,
		ExtractStateAHubEnum.COMPLETE,
		ExtractStateAHubEnum.ARCHIVED,
	];
}
