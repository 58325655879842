import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ElementRef,
	Inject,
	Input,
	NgZone,
	OnInit,
	ViewChild,
} from "@angular/core";
import { isEqual } from "lodash";
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
import {
	combineLatest,
	BehaviorSubject,
	fromEvent,
	Observable,
	of,
	Subject,
	timer,
} from "rxjs";
import {
	auditTime,
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	map,
	mergeMap,
	pairwise,
	publishReplay,
	refCount,
	take,
	takeUntil,
	tap,
} from "rxjs/operators";
import { RequestActionMonitorService } from "services/request-action-monitor/request-action-monitor.service";
import { TreeStructure } from "./tree-structure.class";
import * as treeUtils from "./tree-utils";
import { Utils } from "../../utils";
import {
	CdkDragDrop,
	CdkDragMove,
	CdkDragRelease,
	CdkDropList,
	moveItemInArray,
} from "@angular/cdk/drag-drop";
import { DOCUMENT } from "@angular/common";
import { installPatch } from "../../nested-drag-drop-patch";

/*********************************************************************
 * VIEWPORT POINT / AREA INTERNAL CLASSES
 ****************************************************************************/

export class ViewboxArea {
	top: number = -512;
	left: number = -512;
	width: number = 1024;
	height: number = 1024;

	static viewboxAreaFromBaseAndFocus(
		baseArea: ViewboxArea,
		focusOn: ViewboxPoint
	): ViewboxArea {
		return {
			top: focusOn.y + (baseArea.height * focusOn.scale) / -2,
			left: focusOn.x + (baseArea.width * focusOn.scale) / -2,
			width: baseArea.width * focusOn.scale,
			height: baseArea.height * focusOn.scale,
		};
	}

	// Takes to viewbox areas and a point in one, and transofrms it to a point in the other.
	static viewboxPointTransform(
		sourceViewboxArea: ViewboxArea,
		sourceViewboxPoint: ViewboxPoint,
		targetViewboxArea: ViewboxArea
	): ViewboxPoint {
		// Calculate the change in offset..
		let offset: ViewboxPoint = {
			x: targetViewboxArea.left - sourceViewboxArea.left,
			y: targetViewboxArea.top - sourceViewboxArea.top,
			scale: 1,
		};

		// Calculate the change in scale  ( which should be the same to maintain aspect)
		let scale = targetViewboxArea.width / sourceViewboxArea.width;

		// Now apply the transform to the source point...
		let resultViewboxPoint = ViewboxPoint.add(
			ViewboxPoint.multiple(sourceViewboxPoint, scale),
			offset
		);

		// Return
		return resultViewboxPoint;
	}

	/**
	 * Takes a viewboxArea and scales it.
	 *
	 * @param baseArea
	 * @param scale
	 */
	static viewboxAreaScale(baseArea: ViewboxArea, scale: number): ViewboxArea {
		return {
			top: baseArea.top * scale,
			left: baseArea.left * scale,
			width: baseArea.width * scale,
			height: baseArea.height * scale,
		};
	}
}

// Point in the view...
// NB: Scale is treated simply as another parameter.. we are not doing anything clever with the co-ordinates.
export class ViewboxPoint {
	x: number = 0;
	y: number = 0;
	scale: number = 1;

	static difference(source: ViewboxPoint, target: ViewboxPoint) {
		return {
			x: target.x - source.x,
			y: target.y - source.y,
			scale: target.scale - source.scale,
		};
	}
	static add(a: ViewboxPoint, b: ViewboxPoint) {
		return { x: a.x + b.x, y: a.y + b.y, scale: a.scale + b.scale };
	}
	static multiple(a: ViewboxPoint, multiplyer: number) {
		return {
			x: a.x * multiplyer,
			y: a.y * multiplyer,
			scale: a.scale * multiplyer,
		};
	}
}

/****************************************************************************************
 * TREE VO DEFINITION
 ************************************************************************************/

/**
 * A simple VO class to pass round sizes of bits of teh tree, with a width and height.
 */
export class TreeSize {
	width: number;
	height: number;

	static add(a: TreeSize, b: TreeSize) {
		return { width: a.width + b.width, height: a.height + b.height };
	}
	static subtract(a: TreeSize, b: TreeSize) {
		return { width: a.width - b.width, height: a.height - b.height };
	}
	static multiple(treeSize: TreeSize, m: number) {
		return { width: treeSize.width * m, height: treeSize.height * m };
	}
}

export class TreeConfiguration {
	treeLandscape: boolean = false;
	nodeFactory: ComponentFactory<any> = undefined;
	nodeWidth: number = 100;
	nodeHeight: number = 50;
	nodeLevelGap: number = 50;
	nodeSiblingGap: number = 20;
	nodeDeleteFunction: Function = null;
	nodeEditFunction: Function = null;
	nodeAddFunction: Function = null;
	nodeMoveFunction: Function = null;
	nodeMoveOrderFunction?: Function = null;
	nodeSelectFunction: Function = null;
	nodeSortComparisonFunction?: (a: TreeNodeVO, b: TreeNodeVO) => number = null;
	nodeOrderMoveUpFunction?: Function = null;
	nodeOrderMoveDownFunction?: Function = null;
	sourceData$: Observable<TreeNodeVO[]> = null;
	nodeParams$: Observable<any> = null; // This can be used to provide custom parameters for display of data by the nodes.
	functionScope: any = null; // This is used so we can pass the scope of the function.
	editable: boolean = true;
	editable$?: Observable<boolean> = null;
	showNodeId?: string; //Id of the node which we want to ensure is onscreen
	externallySelectNodeById$?: BehaviorSubject<string> = null; // Should we wish to 'select' a node programatically as if it had been clicked
	externallyExpandNodeAndChildrenById$?: BehaviorSubject<string> = null; // Should we wish to 'expand' a node programatically
}

/**
 * Represents an inidividual node in the tree and its associated data.
 * This is an interface , but bug in angular / web pack causes issues if not set as class
 * */
export class TreeNodeVO {
	id: string;
	label: string; // Display label for the Node.
	hasWarning: boolean; // Indicates issue with this node, its at teh tree level rather than detail as we want teh tree to be able to display a warning even if teh detail component is hidden.
	isDropTarget?: boolean; // Indicates the tree node is being used as a srop target ( either the item being dragged or target for drop, use to all node to change its display.)
	message: string; // Message about teh node , typically for use with hasWarninig but could be used for other states.
	ancestry: string; // Comma delimeted ancestry string, not including this nodes id and topped and tailed with delimiter ","
	data: any; // The data teh node represents.
	priority?: number; // Used to order (sort) the categories within a hierarchy layer
	cantMoveUp?: boolean; // Used to determine if the view should show controls for moving this node up/down in sort order
	cantMoveDown?: boolean; // Used to determine if the view should show controls for moving this node up/down in sort order
	/**
	 * Ensures the ancestry definition is correctly delimited with a starting and ending delimiter.
	 * @param treeNode
	 */
	static ancestryStandardise(treeNode: TreeNodeVO) {
		if (treeNode.ancestry == undefined) {
			treeNode.ancestry = "";
		}
		if (treeNode.ancestry == "") {
			return treeNode;
		}
		if (!treeNode.ancestry.startsWith(",")) {
			treeNode.ancestry = "," + treeNode.ancestry;
		}
		if (!treeNode.ancestry.startsWith(",", treeNode.ancestry.length - 1)) {
			treeNode.ancestry = treeNode.ancestry + ",";
		}
		return treeNode;
	}

	/**
	 * Returns true if the TreeNodeVO are considered equvilent in value.
	 * Note: For comparison, some aspects of the node ( used for highlighting, drop status etc ) are ignored.
	 * @param a
	 * @param b
	 */
	static equals(a: TreeNodeVO, b: TreeNodeVO): boolean {
		// These are the values we will override / reset so they are not considered as part of the comparison.
		let resetValues = {
			isDropTarget: false,
			cantMoveUp: false,
			cantMoveDown: false,
		};
		let aClone: TreeNodeVO = Object.assign(new TreeNodeVO(), a, resetValues);
		let bClone: TreeNodeVO = Object.assign(new TreeNodeVO(), b, resetValues);

		return isEqual(aClone, bClone);
	}
}

/****************************************************************************
 * TREE MAIN METHODS
 ****************************************************************************/

@Component({
	selector: "app-tree",
	templateUrl: "./tree.component.html",
	styleUrls: ["./tree.component.css"],
})
@Hark()
export class TreeComponent implements OnInit {
	/**
	 * Tree configuration setout by the host component
	 */
	@Input() treeConfig: TreeConfiguration;

	/**
	 * Organised Tree Data, we'll sort the nodes supplied in the tree
	 * data according to their depth. As we'll always want to
	 * update / draw the parents before the children.
	 */
	organisedTreeData$: Observable<TreeNodeVO[]>;

	/**
	 * These are the tree highlighted nodes ( current selected & ancestors)
	 * Do not confuse this highlighting with any node data driven display
	 * highlighting which can be maintained seperately to the tree, or driven
	 * off it using this observable.
	 */
	treeHighlightedNodesSubject$: BehaviorSubject<TreeNodeVO[]> =
		new BehaviorSubject<TreeNodeVO[]>([]);
	treeHighlightedNodes$: Observable<TreeNodeVO[]> =
		this.treeHighlightedNodesSubject$.asObservable().pipe(debounceTime(50));

	/**
	 * This is the tree selected node.
	 * Do not confuse this selection  with any node data driven display
	 * selection which can be maintained seperately to the tree, or driven
	 * off it using this observable.
	 */
	treeSelectedNodesSubject$: BehaviorSubject<TreeNodeVO> =
		new BehaviorSubject<TreeNodeVO>(undefined);
	treeSelectedNodes$: Observable<TreeNodeVO> = this.treeSelectedNodesSubject$
		.asObservable()
		.pipe(debounceTime(50));

	/**
	 * Boolean indicating if we are dragging the view box around ...
	 */
	viewBoxIsDragging: boolean = false;

	/**
	 * Indicates the view Component point at which dragging commenced.
	 * We will do all changes relative to this point.
	 */
	viewboxDragComponentStartPoint: ViewboxPoint;

	/**
	 * Indicates the view canvas point at which dragging commenced.
	 * We will do all changes relative to this point.
	 */
	viewboxFocusDragStartPoint: ViewboxPoint;

	/**
	 * Indicates if zoomtoFit is enabled. where the zoom tries to fill the sceen with the tree whn changed.
	 */
	zoomToFitCheckBox: boolean = false;

	/**
	 * This flag indicates whether or not to show nodes when they are added to the tree.
	 * During initial construction this is false, ( so we don;t show everything )
	 * Afterwards and new nodes are automatically displayed.
	 */
	nodeShowWhenAdded: boolean = false;

	/**
	 * Link to the part of the tree structure being dragged.
	 */
	draggingSubtree: TreeStructure = null;

	/**
	 * The original parent of teh part of the tree being dragged.
	 */
	draggingSubTreeOriginalParent: TreeStructure = null;

	/**
	 * The last thing we were dragging over..
	 */
	draggingSubtreeLastOver: TreeStructure = null;

	/**
	 * Boolean flag that is used to hide the draggable subtree clone
	 * until a holding down period has passed.
	 */
	draggingVisableDelay: boolean = false;

	/**
	 * Signal to cancel any dragging being prepared (Usr mouse down but released too soon.)
	 */
	draggingAbort: Subject<void> = new Subject<void>();

	/**
	 * Sets the relative tree root canvas point, this should always be 0,0
	 * But having it as a bindable variable allows for easy triggering of subtree updates.
	 */
	treeRootCanvasPoint: ViewboxPoint = this.treeRootCanvasPointGet();

	/**
	 * Triggers a refesh of the tree display.
	 * Useful is the display has become manky with moving.
	 */
	refreshDisplay$: Subject<void> = new Subject<void>();

	/**
	 * Triggers a recalculation of the tree size.
	 * Had to do this in order to debounce the function call.
	 */
	recalculateTreeSize$: Subject<void> = new Subject<void>();

	/**
	 * This hearbeat allows for sub tree animations to co-ordinate updates to their positions.
	 */
	animationHeartbeat$: Observable<number> = timer(0, 10).pipe(
		takeUntil(componentDestroyStream(this))
	);

	/**
	 * The SVG component repreenting the tree being dragged.
	 */
	@ViewChild("draggingTree", { static: true }) draggingTreeElement: ElementRef;

	/**
	 * The transition to move the cloned sub tree display used for dragging.
	 */
	draggingSubtreeTranslatedPosition: String = "translate(0 0)";

	/**
	 * Triggered by product class moves
	 */
	actionStatus$: Observable<boolean> = of(false);

	constructor(
		private resolver: ComponentFactoryResolver,
		private requestActionMonitorService: RequestActionMonitorService,
		private zone: NgZone,
		private elem: ElementRef
	) {}

	ngOnInit() {
		// This allows cdk drag drop to work on nested lists
		installPatch();

		// We'll start off by setting the viewbox to center / 1:1 scaling.
		this.viewboxFocusApply(this.viewboxTarget);

		// We'll get an ordered copy of the tree data. We want parents first.
		this.organisedTreeData$ = this.treeConfig.sourceData$.pipe(
			takeUntil(componentDestroyStream(this)),
			map((nodes) => {
				// Has this tree been passed a function to use for sorting the nodes (e.g. sort by priority)
				// If so, lets use it

				if (this.treeConfig.nodeSortComparisonFunction) {
					return nodes.sort(this.treeConfig.nodeSortComparisonFunction);
				} else {
					return nodes.sort((a, b) => {
						let aAncestryDepth = a.ancestry.split(",").length;
						let bAncestryDepth = b.ancestry.split(",").length;

						if (aAncestryDepth < bAncestryDepth) return -1;
						if (aAncestryDepth > bAncestryDepth) return 1;

						// After depth, sort by label name if we have one.
						if (a.label == null || b.label == null) return 0;

						return a.label <= b.label ? -1 : 1;
					});
				}
			}),
			pairwise(), // Get the current list of nodes and the previous list of nodes.
			map((nodesCompare) => {
				// Note the tree copes ok with add / remove / swap order of nodes.
				// But it is unlikely to cope with a complete change of data.
				// If this is teh case we need to trigger a tree reset ( tree = null !)
				// For another time.

				// Addin and removing of node or updating of data is done further down teh observable
				// chain. However there is an optimisation we can do if the order has changed.
				// We can simply sway visual tree node for the data from another.

				// We are checking here to see if nodes have been swapped ( IE change in order ).
				// What nodes do we have now and previously.
				let previousNodes: TreeNodeVO[] = nodesCompare[0];
				let currentNodes: TreeNodeVO[] = nodesCompare[1];

				// Has a node been added / removed, then no optimised swapsies.
				if (previousNodes.length !== currentNodes.length) return currentNodes; // return new data.

				// Quick check to see if the nodes have actually changed order / position.
				let nodesChangedPostion: boolean = false;
				for (let i = 0; i < previousNodes.length; i++) {
					if (previousNodes[i].id !== currentNodes[i].id) {
						nodesChangedPostion = true;
						break;
					}
				}

				// No change in the node order.. must be a simple data change.. we can stop here.
				if (!nodesChangedPostion) return currentNodes; // return new data.

				// We know we have the same number of nodes, and the same node ids, but the order is different.
				// The order might be different because the node has been moved to a different branch, rather than simply
				// a change of order with its siblings.. if this is the case, we cant simply swap data.

				// Now we'll compare the nodes and check that no nodes ancestry has changed.
				let isAncestrySame: boolean = true;
				for (let i = 0; i < previousNodes.length; i++) {
					if (previousNodes[i].ancestry != currentNodes[i].ancestry) {
						isAncestrySame = false;
						break;
					}
				}

				// If the ancestry has changed.. then not a simple swap of sibling order..
				if (!isAncestrySame) return currentNodes; // return new data.

				// At this point, we have teh same nodes, with teh same ancestry but in a different order ( sibling swaps )

				// Loop through the VO nodes using index
				for (let i = 0; i < currentNodes.length; i++) {
					// Are the current and previous nodes a different id at the same index.
					if (previousNodes[i].id !== currentNodes[i].id) {
						// Yes, then lets look up the visual tree VO and swap the ID of the node VO the visual tree represents.
						// When the tree updates its visualls it wil automatically update the data to the new VO ( including children ! )
						let previousTreeNode: TreeStructure = treeUtils.getTreeNodeById(
							this.tree,
							previousNodes[i].id
						);
						let currentTreeNode: TreeStructure = treeUtils.getTreeNodeById(
							this.tree,
							currentNodes[i].id
						);
						previousTreeNode.id = currentNodes[i].id;
						currentTreeNode.id = previousNodes[i].id;

						// We also need to swap the VO node in data we are checking.. otherwise we will get to the node we have swapped
						// with.. it will be out of order and we will end up swapping again, and undoing the visual change !
						let previousTreeNodeVO: TreeNodeVO = previousNodes[i];
						let previousTreeNodeVOSwapIndex: number = previousNodes.findIndex(
							(node) => node.id === currentNodes[i].id
						);
						let previousTreeNodeVOSwap: TreeNodeVO =
							previousNodes[previousTreeNodeVOSwapIndex];
						let swapTreeNodeVO: TreeNodeVO = previousTreeNodeVO;
						previousNodes[i] = previousTreeNodeVOSwap;
						previousNodes[previousTreeNodeVOSwapIndex] = swapTreeNodeVO;
					}
				}

				// Going forward we output the current list of nodes to display.
				return currentNodes;
			}),
			publishReplay(1),
			refCount()
		);

		// Should we wish to 'select' a node programatically as if it had been clicked, we need only set this
		// by node id
		if (this.treeConfig.externallySelectNodeById$) {
			this.treeConfig.externallySelectNodeById$
				.pipe(
					Utils.isNotNullOrUndefined(),
					takeUntil(componentDestroyStream(this))
				)
				.subscribe((externallySelectNodeById) =>
					this.selectTreeNodeByNodeId(externallySelectNodeById)
				);
		}

		// Should we wish to 'expand' a node (and its children) programatically, we need only set this
		// by node id
		if (this.treeConfig.externallyExpandNodeAndChildrenById$) {
			this.treeConfig.externallyExpandNodeAndChildrenById$
				.pipe(
					Utils.isNotNullOrUndefined(),
					distinctUntilChanged(),
					takeUntil(componentDestroyStream(this))
				)
				.subscribe((externallyExpandNodeAndChildrenById) => {
					const treeStructureToExpand = TreeStructure.treeStructureByNodeId(
						this.tree,
						externallyExpandNodeAndChildrenById
					);
					this.treeSubTreeOpen(treeStructureToExpand);
				});
		}

		// We need to watch for when the tree has been cleared out so we can reset it.
		// This only happens when the tree data is null or empty.
		combineLatest([this.organisedTreeData$, this.refreshDisplay$])
			.pipe(
				map(([data, refresh]) => data),
				takeUntil(componentDestroyStream(this)),
				filter((data) => !data || data.length == 0)
			)
			.subscribe((data) => {
				// Restart the tree.
				this.tree = null;
				this.nodeShowWhenAdded = false;
			});

		// UPDATES THE TREE CONTENT , TO ADD AND MOVE TO MATCH SOURCE DATA
		// Take the source data ( flat array of classes ) and subscribe
		// to a stream of the individual elements.
		// We will use this as a means to update the displayed tree.
		combineLatest([this.organisedTreeData$, this.refreshDisplay$])
			.pipe(
				map(([data, refresh]) => data),
				takeUntil(componentDestroyStream(this)),
				filter((data) => data != undefined && data.length > 0),
				mergeMap((sourceDataArray) => sourceDataArray)
			)
			.subscribe((sourceData) => {
				// Supply the incomming data node to the the tree sync function which will update it.
				this.treeSyncWithData(sourceData);
				this.treeStructureRespositionAll();
			});

		// Handles REMOVES FROM THE TREE , TREE STRUCTURES THAT NO LONGER EXIST IN THE SOURCE DATA.
		// Changes to node ordering (e.g. by priority)
		combineLatest([this.organisedTreeData$, this.refreshDisplay$])
			.pipe(
				map(([data, refresh]) => data),
				takeUntil(componentDestroyStream(this)),
				Utils.isNotNullOrUndefined(),
				filter((data) => data.length > 0)
			)
			.subscribe((sourceDataArray) => {
				// compare the current tree with the incoming data
				// to see if some nodes are missing and should be removed from the tree
				if (this.tree) {
					// List of node ids in the tree.
					let treeIds = treeUtils.treeIds(this.tree);

					// Filter out any tree Ids that have Id in the source data, leave the ids to remove
					treeIds = treeIds.filter(
						(id) =>
							sourceDataArray.find((sourceData) => sourceData.id == id) ==
							undefined
					);

					// Remove them.
					if (treeIds.length > 0) {
						TreeStructure.applyFunctionSubTrees(
							this.tree,
							treeUtils.removeSubTreesWithIdsFunction(treeIds)
						);
						this.debouncedTreeStructureSizeCalc();
						this.treeStructureRespositionAll();
						TreeStructure.applyFunctionSubTrees(
							this.tree,
							(tree) => {
								tree.nodeChanged$.next();
							},
							true
						); // Update all visuals
					}
				}
			});

		this.refreshDisplay$.next();

		// EXECUTES AFTER THE SUPPLY OF THE FIRST VALID SET OF DATA, SETS UP VIEW.
		// We get our first lot of data, we want to expand a little and zoom to fit.
		this.organisedTreeData$
			.pipe(
				filter((data) => data != undefined && data.length > 0),
				takeUntil(componentDestroyStream(this)),
				take(1)
			)
			.subscribe((newData) => {
				let subTreeStructure: TreeStructure = undefined;

				// I think we will show the first level of the tree..
				// I don't want to start with it fully opened as it could be quite big !
				TreeStructure.applyFunctionSubTrees(this.tree, (subtree) => {
					// adding  || (subtree.parentTree.id === this.tree.id) whould show second level.
					if (subtree.parentTree == null) {
						subtree.childrenShow = true;
					}

					if (
						this.treeConfig.showNodeId &&
						this.treeConfig.showNodeId == subtree.id
					) {
						subTreeStructure = subtree;
					}
				});

				//Do we have a sub tree structure which we can open. We will not call
				//a restructure and reposition as we are about to do it!
				if (subTreeStructure) {
					this.showLineage(subTreeStructure);
				}

				// Have the whole tree recalculate the widths required to display correctly.
				this.debouncedTreeStructureSizeCalc();
				this.treeStructureRespositionAll();

				// From now on any changed nodes should be displayed.
				this.nodeShowWhenAdded = true;

				// Whenever the tree does any position changes (eg adding, moving or deleting nodes and expanding/collapsing subtrees)
				// We will consider whether the zoom to fit check box is 'checked', if so we'll zoom to fit
				if (this.tree) {
					this.tree.posCalc$
						.asObservable()
						.pipe(takeUntil(componentDestroyStream(this)))
						.subscribe((tree) => {
							if (this.zoomToFitCheckBox) {
								this.treeZoomTrigger();
							}
						});
				}

				// Then we better zoom to fit...
				this.zoomToFitCheckBox = true;
				this.treeZoomTrigger();
			});

		// Watch for tree drag over and highlight
		this.dropLocation$
			.asObservable()
			.pipe(takeUntil(componentDestroyStream(this)), distinctUntilChanged())
			.subscribe((tree) => {
				this.treeDropLocationHighlight(tree);
			});

		// Make sure that the root level svg component canvas is the same size as its parent
		// With origin at the middle of the screen.
		// We had an issue where when inserted into a dialog component tab, the component size given was 0.
		// A little hacky, but when initialised we will keep checking the component size and only stop
		// trying to match the canvas size to the component, once we have a size greater then 0.
		let stopTryingToMatchComponent$: Subject<void> = new Subject<void>();
		timer(0, 100)
			.pipe(
				takeUntil(componentDestroyStream(this)),
				takeUntil(stopTryingToMatchComponent$.asObservable())
			)
			.subscribe((tick) => {
				// Stop the subscription if we successfully set teh viewbox size.
				if (this.viewboxMatchComponent()) {
					stopTryingToMatchComponent$.next();

					// Then we better zoom to fit...
					this.zoomToFitCheckBox = true;
					this.treeZoomTrigger();
				}
			});

		this.recalculateTreeSize$
			.pipe(takeUntil(componentDestroyStream(this)), auditTime(150))
			.subscribe(() => {
				this.treeStructureSizeCalc();
			});
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/***********************
	 * TREE DRAGGING
	 ***********************/

	dropLocation$: Subject<TreeStructure> = new Subject<TreeStructure>();

	/**
	 * Called by a sub tree to indicate it is being dragged.
	 * Also provides teh mouse event that triggered the copy, useful to get the position
	 * of the interaction.
	 */
	treeDragStart(draggedTree: TreeStructure, mouseEvent: MouseEvent) {
		// We'll need to have the tree start observing the mouse movement.
		// As the mouse down would have been capture in a lower subtree component
		// and not passed up ( to prevent all sub trees actioning ) , we need
		// to manually trigger the watching of the mouse movement.
		this.svgViewObserveMouseMoveStart();

		// Quick check that we are not dragging the root.. pointless.
		if (draggedTree === this.tree) return;

		// Cancel any previous drag , that might have been in progress ( clicking like a d**k ).
		this.draggingAbort.next();

		// Store a pointer to the part of the tree being dragged, used for generating a display copy.
		this.draggingSubtree = draggedTree;

		// But dont display it yet! , wait for timer.
		this.draggingVisableDelay = false;
		let timedObservable: Observable<number> = timer(500, 500);
		timedObservable
			.pipe(
				takeUntil(componentDestroyStream(this)),
				takeUntil(this.draggingAbort),
				take(1)
			)
			.subscribe((ticks) => {
				this.draggingVisableDelay = true; // Ok show it now.
				this.draggingSubtreeLastOver = null;

				// Prune from the tree... if we are still dragging !
				if (this.draggingSubtree) {
					this.draggingSubTreeOriginalParent = this.draggingSubtree.parentTree;
					TreeStructure.subTreeRemove(
						this.draggingSubtree.id,
						this.draggingSubtree.parentTree
					);
					this.debouncedTreeStructureSizeCalc();
					this.treeStructureRespositionAll();
				}
			});

		// Move display clone to mouse location.
		this.treeDragLoctionToMouse(mouseEvent);
	}

	/**
	 *  Called whilst tree dragging is in progress.
	 */
	treeDragging(mouseEvent: MouseEvent) {
		// Move display clone to mouse location.
		this.treeDragLoctionToMouse(mouseEvent);

		// Check who we are hovering over?
		let dropPoint: ViewboxPoint = this.mouseEventToCanvasPoint(mouseEvent);

		// Check in with tree structure to find what we were dropped over:
		let treeStructureDropLocation = this.treeStructureLookupByCanvasPoint(
			dropPoint,
			this.tree
		);

		this.dropLocation$.next(treeStructureDropLocation);
	}

	/**
	 * Hightlight a tree structure.
	 * @param tree
	 */
	treeDropLocationHighlight(tree: TreeStructure) {
		// Are we over a tree?
		if (tree == null) {
			// We are not over a drop location, make sure nothing is highlighted... optimisation here...
			TreeStructure.applyFunctionEverywhere(this.tree, (tree) => {
				tree.isDropTarget = false;
			});
			return;
		}

		// Yes, is it highlighted?
		if (!tree.isDropTarget) {
			// No
			// First make sure nothing else is highlighted.
			TreeStructure.applyFunctionEverywhere(this.tree, (tree) => {
				tree.isDropTarget = false;
			});

			// .. and highlght just the drop lopcation.
			tree.isDropTarget = true;
		}
	}

	/**
	 *  Takes a mouse event, extracts the mouse location on the tree component and returns the canvas svg point.
	 */
	mouseEventToCanvasPoint(mouseEvent: MouseEvent): ViewboxPoint {
		// Start by taking the mouse component co-ordinates...
		let mouseViewboxPoint: ViewboxPoint = {
			x: mouseEvent.x,
			y: mouseEvent.y,
			scale: 1,
		};

		// Transform the mouseClick to a canvas point.
		let canvasPoint: ViewboxPoint = ViewboxArea.viewboxPointTransform(
			this.componentViewbox(),
			mouseViewboxPoint,
			this.viewboxArea
		);

		// But we also need to convert this to a point on the positioned and scaled tree svg group.
		let treePoint: ViewboxPoint = canvasPoint;
		treePoint = ViewboxPoint.multiple(treePoint, 1 / this.viewboxFocus.scale);
		treePoint = ViewboxPoint.add(treePoint, this.viewboxFocus);
		treePoint.scale = this.viewboxFocus.scale; // Not really used, but we'll set to the focus point scale ( the scale of the tree ).

		return treePoint;
	}

	treeDragLoctionToMouse(mouseEvent: MouseEvent) {
		// Move display clone to mouse location.
		let canvasViewboxPoint: ViewboxPoint =
			this.mouseEventToCanvasPoint(mouseEvent);

		// Adjust to make node central under mouse.
		canvasViewboxPoint.y =
			canvasViewboxPoint.y - this.treeConfig.nodeHeight / 2;

		// Translate svg clone of tree, to mouse interation point.
		this.draggingTreeElement.nativeElement.setAttribute(
			"transform",
			"translate(" + canvasViewboxPoint.x + " " + canvasViewboxPoint.y + ")"
		);
		//this.draggingSubtreeTranslatedPosition = "translate(" + canvasViewboxPoint.x + " " + canvasViewboxPoint.y + ")";
	}

	/**
	 *  Called when the display tree clone has been released / dropped.
	 */
	treeDragEnd(mouseEvent: MouseEvent) {
		// Abort any dragging that have be initiatied but not complete ( user did not hold mouse down long enough)
		this.draggingAbort.next();

		// Check we of we actually started dragging something , if released before dragging visible , ignore. !
		if (this.draggingVisableDelay == true) {
			// We want to know which node we are dropping over, use the mouse , and check in with tree.
			// Turn mouse position , into canvas position.
			let dropPoint: ViewboxPoint = this.mouseEventToCanvasPoint(mouseEvent);

			// Check in with tree structure to find what we were dropped over:
			let treeStructureDropLocation = this.treeStructureLookupByCanvasPoint(
				dropPoint,
				this.tree
			);

			// If we are not dropping anywhere , refresh the display.
			if (treeStructureDropLocation == null) {
				TreeStructure.subTreeAdd(
					this.draggingSubtree,
					this.draggingSubTreeOriginalParent
				);
				this.refreshDisplay$.next();
			}

			// Did we find a drop , if so ... tell people.
			if (
				treeStructureDropLocation &&
				this.draggingSubtree.node.id !== treeStructureDropLocation.node.id
			) {
				let dialogResponse: Observable<boolean> = this.treeMoveNode(
					this.draggingSubtree.node,
					treeStructureDropLocation.node
				);

				let draggingSubtree = this.draggingSubtree;
				let draggingSubTreeOriginalParent = this.draggingSubTreeOriginalParent;
				dialogResponse.pipe(take(1)).subscribe((r) => {
					// If the move dialog was 'Cancelled'
					if (r == false) {
						// add the pruned tree back onto parent branch.
						TreeStructure.subTreeAdd(
							draggingSubtree,
							draggingSubTreeOriginalParent
						);
						this.refreshDisplay$.next();
					} else {
						this.treeSubTreeSelect(undefined);
					}
				});
			}
		}

		// End the drag.
		this.draggingSubtree = null;
		this.draggingSubTreeOriginalParent = null;
		this.draggingVisableDelay = false;
		TreeStructure.applyFunctionEverywhere(this.tree, (tree) => {
			tree.isDropTarget = false;
		});
	}

	/**
	 * Looks up a part of teh tree structure based on a cavas point being wihtin the tree's node
	 * @param canvasPoint
	 * @param tree
	 */
	treeStructureLookupByCanvasPoint(
		canvasPoint: ViewboxPoint,
		tree: TreeStructure
	): TreeStructure {
		// Have we calculated the node position of this subtree?
		if (tree.nodeViewboxArea == null) return null; // no area to check, then we cant check.

		// Does the canvas point fall with this tree?
		if (
			tree.nodeViewboxArea.left < canvasPoint.x &&
			tree.nodeViewboxArea.left + tree.nodeViewboxArea.width > canvasPoint.x &&
			tree.nodeViewboxArea.top < canvasPoint.y &&
			tree.nodeViewboxArea.top + tree.nodeViewboxArea.height > canvasPoint.y
		)
			return tree;

		// No, then check the children... if they are displayed ..
		if (tree.childrenShow) {
			for (let subtree of tree.subTrees) {
				let foundTree: TreeStructure = this.treeStructureLookupByCanvasPoint(
					canvasPoint,
					subtree
				);
				if (foundTree) return foundTree;
			}
		}

		// Did'nt find any matches , return null.
		return null;
	}

	// The base point for the tree, keep it 0,0 !
	treeRootCanvasPointGet(): ViewboxPoint {
		return {
			x: 0,
			y: 0,
			scale: 1,
		};
	}

	/**************************************************************
	 * VIEWPORT MANAGEMENT
	 **************************************************************/

	// The SVG component.
	@ViewChild("svgView", { static: true }) svgView: ElementRef;
	@ViewChild("treeRoot", { static: true }) svgTreeRoot: ElementRef;

	// This is location the view is focused on , the center of our view
	viewboxFocus: ViewboxPoint = { x: 0, y: 0, scale: 1 };

	// This is the view box representing teh canvas inside the svg component.
	viewboxArea: ViewboxArea;

	// This is the string definition for the viewbox as used and bound by the svg component.
	viewboxDefinition: String = "-512 -512 1024 1024";

	// This is where we want to be focused.
	viewboxTarget: ViewboxPoint = { x: 0, y: 0, scale: 1 };

	// When carrying out transition between focus locations , this is the starting point
	// ( the original focus point ) when the new target focus location was requested.
	viewboxTransitionOrigin: ViewboxPoint;

	// This indicates if there is a transition in progress.
	viewboxTransitionInProgress: boolean = false;
	viewboxTransitionEnded$: Subject<boolean> = new Subject<boolean>();

	// This is the moment (ms) the view was requested to change its target
	viewboxTransitionRequestTime: number = 0;

	// This is the time it should take to make the transition.
	viewboxTransitionFocusTime: number = 500;

	componentViewbox(): ViewboxArea {
		return {
			top: this.svgView.nativeElement.getBoundingClientRect().top,
			left: this.svgView.nativeElement.getBoundingClientRect().left,
			width: this.svgView.nativeElement.getBoundingClientRect().width,
			height: this.svgView.nativeElement.getBoundingClientRect().height,
		};
	}

	/**
	 * Changes the viewbox of the top level svg view to match that of its
	 * component, with 1:1 scale but offset so that the origin is in the center of the screen.
	 */
	viewboxMatchComponent(): Boolean {
		// Whats the current size of the component the svg is in.
		let componentViewbox = this.componentViewbox();

		// The canvas view box will be the same aspect, offset to make 0,0 the center.
		this.viewboxArea = {
			left: -(componentViewbox.width / 2),
			top: -(componentViewbox.height / 2),
			width: componentViewbox.width,
			height: componentViewbox.height,
		};

		// if the component size is 0 , return false, we can't set the size.
		if (this.viewboxArea.width < 1 || this.viewboxArea.height < 1) return false;

		this.viewboxDefinition =
			this.viewboxArea.left +
			" " +
			this.viewboxArea.top +
			" " +
			this.viewboxArea.width +
			" " +
			this.viewboxArea.height;
		return true;
	}

	// This sets the view focus to a particular point ( immediate )
	// Updates the SVG bound variable with the new viewbox Area.
	// co-orinates of the point passed are in canvas based ( not component )..
	viewboxFocusApply(focus: ViewboxPoint) {
		// Changed the scrolling tree ( moving it around the view ) to moving the whole tree position, rather than changing the viewbox.
		// Whilst changing the viewbox is simplier and makes more sense, it triggers angular change detection, forcing whole screen refesh which makes the
		// movement slugggish when lots on the screen...( Tried using zones, but svg component viewbox changes still cause refresh issue .)

		// Move the tree svg group.
		this.svgTreeRoot.nativeElement.setAttribute(
			"transform",
			"scale(" + focus.scale + ") translate(" + -focus.x + " " + -focus.y + ")"
		);
	}

	// This calculates the new focus position as a point on way to target, based
	// on the time since the reques was made ( so slow computers still get to target same time
	// as fast computers, just with fewer frames.)
	viewboxFocusUpdate() {
		// Whats the vector between where we started from and where we want to end up.
		let deltaVector: ViewboxPoint = ViewboxPoint.difference(
			this.viewboxTransitionOrigin,
			this.viewboxTarget
		);

		// We want to have travelled a proportion of this distance, a fraction , based on the time.
		let nowDate = new Date();
		let nowMs = nowDate.getTime();
		let ellapsedProportion: number =
			(nowMs - this.viewboxTransitionRequestTime) /
			this.viewboxTransitionFocusTime;

		// We can not be beyond the end of the transition. ie 1.
		ellapsedProportion = Math.min(1, ellapsedProportion);

		// Because we are being a smart arse, rather than any old linear movement, we want it to ease in and out.
		// So we'll use a cos wave ( adjusted to go between 0 - 1) to move our linear proportion to a eased one.
		if (ellapsedProportion < 1) {
			let easedProportion: number =
				(Math.cos(ellapsedProportion * 180 * (Math.PI / 180) + Math.PI) + 1) /
				2;

			// Apply the eased proportion to the delta vector and add to the orginal starting point to get
			// our current focused location.
			this.viewboxFocus = ViewboxPoint.add(
				this.viewboxTransitionOrigin,
				ViewboxPoint.multiple(deltaVector, easedProportion)
			);
		} else {
			// If we are 1 or more we have reached our destination.
			this.viewboxFocus = this.viewboxTarget;
			this.viewboxTransitionInProgress = false;
			this.viewboxTransitionEnded$.next(true);
		}

		// apply the new focus.
		this.viewboxFocusApply(this.viewboxFocus);
	}

	// Ends the current focus transition.
	focusEndNow() {
		// Stop the updates.
		this.viewboxTransitionEnded$.next(true);

		// Jump to end point.
		this.viewboxFocus = this.viewboxTarget;

		// Flag no transition is progress.
		this.viewboxTransitionInProgress = false;

		// Immediate single view adjust.
		this.viewboxFocusApply(this.viewboxTarget);
	}

	// This is a request to focus on a point on the canvas
	focusOnPointRequest(newTargetFocusPoint: ViewboxPoint) {
		// Record the endpoint target we are heading for.
		this.viewboxTarget = newTargetFocusPoint;

		// This is where we are starting from.
		this.viewboxTransitionOrigin = this.viewboxFocus;

		// Reset the start time ( which represents the duration the transition will take.)
		let nowDate = new Date();
		this.viewboxTransitionRequestTime = nowDate.getTime();

		// Is there a transition already in progress ?
		// if so , we let it continue with the new target..
		if (this.viewboxTransitionInProgress) return;

		// Flag that there is a transition in progress.
		this.viewboxTransitionInProgress = true;

		// Create observable to update screen.
		this.animationHeartbeat$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				takeUntil(this.viewboxTransitionEnded$.asObservable())
			)
			.subscribe((update) => {
				this.viewboxFocusUpdate();
			});
	}

	/**
	 * Starts the view drag, takes the cursor position on the component as a starting reference.
	 *
	 * @param componentX
	 * @param componentY
	 */
	svgViewDragStart(componentX: number, componentY: number) {
		// We have started dragging.
		this.viewBoxIsDragging = true;

		// Record the start drag point, where the mouse is on the component, and the current focus
		this.viewboxDragComponentStartPoint = {
			x: componentX,
			y: componentY,
			scale: 1,
		};
		this.viewboxFocusDragStartPoint = this.viewboxFocus;
	}

	/**
	 * Starts the view drag, takes the cursor position on the component as the new drag position.
	 *
	 * @param componentX
	 * @param componentY
	 */
	svgViewDragging(componentX: number, componentY: number) {
		// Whats the new cursor location on the screen?
		let componentPoint: ViewboxPoint = {
			x: componentX,
			y: componentY,
			scale: 1,
		};

		// We want to find the difference between the start of the drag point and the current cursor position.
		let componentPointDelta: ViewboxPoint = ViewboxPoint.difference(
			componentPoint,
			this.viewboxDragComponentStartPoint
		);
		componentPointDelta.scale = 1; // Scale of the component should be fixed to 1

		// Now we add the change , note the scaling of the mouse movement to as when zoomed out, moving the mouse 1 px is equivelent to moving canvas many pixels and visa versa.
		let viewboxNewFocus: ViewboxPoint = ViewboxPoint.add(
			this.viewboxFocusDragStartPoint,
			ViewboxPoint.multiple(componentPointDelta, 1 / this.viewboxFocus.scale)
		);
		viewboxNewFocus.scale = this.viewboxFocus.scale;

		// record the change in focus and update the screen.
		this.viewboxFocus = viewboxNewFocus;
		this.viewboxFocusApply(viewboxNewFocus);
	}

	/**
	 * Ends the dragging of the view.
	 */
	svgViewDragEnd() {
		// We have stopped dragging.
		this.viewBoxIsDragging = false;
	}

	// Start observing teh mouse movement and call our generic mouse handler, outside of anulgar zone
	// for maximum performance.
	svgViewObserveMouseMoveStart() {
		this.zone.runOutsideAngular(() => {
			fromEvent(this.svgView.nativeElement, "mousemove")
				.pipe(takeUntil(fromEvent(this.svgView.nativeElement, "mouseup")))
				.subscribe((event) => this.svgViewMouseEvent(<MouseEvent>event));
		});
	}

	// This is a mouse handler for the svg view.
	svgViewMouseEvent(event: MouseEvent) {
		// We'll handle this, no need to pass on.
		event.preventDefault();

		switch (event.type) {
			case "mousedown": {
				this.svgViewDragStart(event.offsetX, event.offsetY);
				this.svgViewObserveMouseMoveStart();
				break;
			}

			case "mousemove": {
				// Any chnages we make to the visuals need to happen inside the angular zone to reflect on screen.
				this.zone.run(() => {
					if (this.viewBoxIsDragging)
						this.svgViewDragging(event.offsetX, event.offsetY);

					if (this.draggingSubtree) this.treeDragging(event);
				});

				break;
			}

			case "mouseup": {
				if (this.viewBoxIsDragging) this.svgViewDragEnd();

				if (this.draggingSubtree) this.treeDragEnd(event);

				break;
			}
		}
	}

	/**********************************************************************
	 * TREE MANAGEMENT
	 ***********************************************************************/

	// This represents the displayed tree.
	tree: TreeStructure = null;

	/**
	 * Takes a node of data and updates the visual tree.
	 **/
	treeSyncWithData(dataNode: TreeNodeVO) {
		// standardise the dataNode tree ancestry.. its important it conforms to teh standard.
		dataNode = TreeNodeVO.ancestryStandardise(dataNode);

		// Is this data node already represented in the tree?
		// Recursively search the tree
		// to find the node at the top of the subtree structure that matches this node.
		let targetTreeStructure: TreeStructure = treeUtils.getTreeNodeById(
			this.tree,
			dataNode.id
		);

		// Also see if we can locate the parent of the incoming data node.
		let parentTreeStructure: TreeStructure = null;

		// Does the node have an ancestry ? it will if its anything but root ! If so we can find the parent tree.
		if (
			dataNode.hasOwnProperty("ancestry") &&
			dataNode.ancestry != undefined &&
			dataNode.ancestry != ""
		) {
			// Get the node ancestry..
			let ancestry: string[] = dataNode.ancestry.split(",");

			// extract immediate parent.
			let parentNodeId: string = "";
			while (parentNodeId === "") parentNodeId = ancestry.pop();

			// Find parent.
			parentTreeStructure = treeUtils.getTreeNodeById(this.tree, parentNodeId);
		}

		// Did we find the tree structure representing this node?
		// and if we did , has it changed parent? If so remove from existing, we;; add it back in later.
		if (
			targetTreeStructure &&
			parentTreeStructure &&
			targetTreeStructure.parentTree.id != parentTreeStructure.id
		) {
			TreeStructure.subTreeRemove(
				targetTreeStructure.id,
				targetTreeStructure.parentTree
			);

			// Trigger any additional visual updates based on node data changes.
			TreeStructure.applyFunctionAncestors(parentTreeStructure, (tree) => {
				tree.nodeChanged$.next();
			});
			targetTreeStructure.nodeChanged$.next();
		}

		// If we did'nt fnd any node we'll need to create a new one.
		if (targetTreeStructure == null) {
			// CREATE NEW NODE
			// Create a new tree structure part and add to tree.
			targetTreeStructure = {
				treeComponent: this,
				id: dataNode.id + "", // Force string.
				childrenShow: false, // Defaulting for now
				node: dataNode, // Data fed to the display component.
				nodeChanged$: new Subject<void>(), // Subject emits when the data has changed.
				sizeDisplayedStart: {
					width: this.treeConfig.nodeWidth + this.treeConfig.nodeSiblingGap * 2,
					height: this.treeConfig.nodeHeight + this.treeConfig.nodeLevelGap * 2,
				},
				sizeDisplayed: {
					width: this.treeConfig.nodeWidth + this.treeConfig.nodeSiblingGap * 2,
					height: this.treeConfig.nodeHeight + this.treeConfig.nodeLevelGap * 2,
				},
				sizeDisplayedTransitionStart: 0,
				size: {
					width: this.treeConfig.nodeWidth + this.treeConfig.nodeSiblingGap * 2,
					height: this.treeConfig.nodeHeight + this.treeConfig.nodeLevelGap * 2,
				}, // Mininum dimension ( will be updated )
				absolutePosition$: new Subject<ViewboxPoint>(),
				nodeViewboxArea: null,
				subTrees: [], // New structure, no children yet,
				parentTree: null, // New structure, no parent yet,
				posCalc$: new Subject<string>(),
				isSelected: false,
				isHighlighted: false,
				isDropTarget: false,
			};
		}

		// If the targetTreeStructure has no parent ( becuase its new or moved from existing )
		// We'll need to graft it on.
		if (
			targetTreeStructure.parentTree == null ||
			(parentTreeStructure != null &&
				targetTreeStructure.parentTree.id != parentTreeStructure.id)
		) {
			if (parentTreeStructure != null) {
				// We have found the nodes parent, graft it on.
				TreeStructure.subTreeAdd(targetTreeStructure, parentTreeStructure);

				// Should we automatically show the added child ( and its parents )
				if (this.nodeShowWhenAdded) {
					TreeStructure.applyFunctionAncestors(parentTreeStructure, (tree) => {
						tree.childrenShow = true;
					});
				}

				// Trigger any additional visual updates based on node data changes.
				TreeStructure.applyFunctionSubTrees(targetTreeStructure, (tree) => {
					tree.nodeChanged$.next();
				});
			} else if (this.tree == null) {
				// We don't have a parent defined in the node, but we don't have a tree either.. so this must be the root node.
				this.tree = targetTreeStructure;
			} else if (this.tree.id == targetTreeStructure.id) {
				// This is the root node, and its already root ! Do nothing.
			} else {
				console.log(
					"DEV NOTE: TREE: Skipped adding node to tree display, parent does not exist. " +
						targetTreeStructure.node.data.label
				);
			}
		}

		// At this point,  we have found existing node, or have created one and added it to tree.
		// We can simply update its values.

		if (!TreeNodeVO.equals(targetTreeStructure.node, dataNode)) {
			targetTreeStructure.node = dataNode;
			TreeStructure.applyFunctionAncestors(targetTreeStructure, (tree) => {
				tree.nodeChanged$.next();
			});
		}

		// Possible optimisation could be done here? Do we always need to sort?
		// Lets re-sort this node with siblings as it might have changed order with the new data -
		if (
			this.treeConfig.nodeSortComparisonFunction &&
			parentTreeStructure != null
		) {
			parentTreeStructure.subTrees.sort((a, b) => {
				return this.treeConfig.nodeSortComparisonFunction(a.node, b.node);
			});

			// We have a parent tree, so we can sort with our siblings, no comparison function so just use default.
		} else if (parentTreeStructure != null) {
			return parentTreeStructure.subTrees.sort((a, b) => {
				let aAncestryDepth = a.node.ancestry.split(",").length;
				let bAncestryDepth = b.node.ancestry.split(",").length;

				if (aAncestryDepth < bAncestryDepth) return -1;
				if (aAncestryDepth > bAncestryDepth) return 1;

				// After depth, sort by label name if we have one.
				if (a.node.label == null || b.node.label == null) return 0;

				return a.node.label < b.node.label ? -1 : 1;
			});
		}

		// Tree changed, it will need to recalculate all its sizes.
		this.debouncedTreeStructureSizeCalc();
	}

	/**
	 * Has the whole tree reposition its layout.
	 * Useful if lots has changed, but not always the smoothest.
	 */
	treeStructureRespositionAll() {
		TreeStructure.applyFunctionEverywhere(this.tree, (tree) => {
			TreeStructure.reposition(tree);
		});
		this.treeZoomTrigger();
	}

	/**
	 * Has the entire tree re-calculate its size based on the current
	 * children display properties.
	 */
	treeStructureSizeCalc() {
		if (this.tree) {
			this.tree.size = this.treeStructureSubtreeSizeCalculate(this.tree);
		}
		// We need to update all the subTrees absolute position information, so trigger by updateing our own
		// which is always 0,0.
		this.zone.runOutsideAngular(() => {
			if (this.tree) {
				this.tree.absolutePosition$.next({
					x: 0,
					y: 0,
					scale: this.viewboxFocus.scale,
				});
			}
		});
	}

	/**
	 * Calls the treeStructureSizeCalc function, but debounced to prevent
	 * multiple calls in quick succession.
	 */
	debouncedTreeStructureSizeCalc() {
		this.recalculateTreeSize$.next();
	}

	/**
	 * Calculates the physical size of this part of teh sub tree, by recursively looking at
	 * the children beneath ( the size of teh sub tree depends on all the child sub trees )
	 *
	 * NOTE: There is a temptation here to simply carry out calculations based on portrait,
	 * then simply swap dimensions, but because the nodes themselves are not necessary equal width
	 * and height the calculations for size an position change if the tree rotates as the nodes remain
	 * in thier original aspect.
	 *
	 *
	 * @param tree
	 */
	private treeStructureSubtreeSizeCalculate(tree: TreeStructure): TreeSize {
		// Lets create a Tree size variable to return the size of this subtree
		// ( Which will be dependent on our child sub tree ! recursively.)
		// We'll initialise our size as just the plain node, wihtout any child subtress.
		let calculatedTreeSize: TreeSize = {
			width:
				this.treeConfig.nodeWidth +
				(this.treeConfig.treeLandscape
					? this.treeConfig.nodeLevelGap * 2
					: this.treeConfig.nodeSiblingGap * 2),
			height:
				this.treeConfig.nodeHeight +
				(this.treeConfig.treeLandscape
					? this.treeConfig.nodeSiblingGap * 2
					: this.treeConfig.nodeLevelGap * 2),
		};

		let subTreeSize: TreeSize = {
			width: 0,
			height: 0,
		};

		// Now lets look at our children..
		for (let subtree of tree.subTrees) {
			// If we are a portrait tree, root at the top spreading downwards, then our width
			// will be the combined width of all our sub trees..
			// Our height will be the height of of tallest child sub tree plus our height.
			// If the tree is landscape root on the left with branches to the right,  , then this is swapped.
			let childSubTreeSize: TreeSize =
				this.treeStructureSubtreeSizeCalculate(subtree);
			if (this.treeConfig.treeLandscape) {
				// Landscape, Our height will be the sum of all our child sub tree heights.
				subTreeSize.height = subTreeSize.height + childSubTreeSize.height;

				// The width will be the widest of our children ( The one with deepest children levels).
				subTreeSize.width = Math.max(subTreeSize.width, childSubTreeSize.width);
			} else {
				// Portrait, Our width will be the sum of all our child sub tree widths.
				subTreeSize.width = subTreeSize.width + childSubTreeSize.width;

				// The height will be the heighest of our children ( The one with deepest children levels).
				subTreeSize.height = Math.max(
					subTreeSize.height,
					childSubTreeSize.height
				);
			}
		}

		// With our child sub tree sizes calculated and checked, we need to work out our size.
		// The dimensions we set to will depend on the orientation of the tree.
		if (this.treeConfig.treeLandscape) {
			// Landscape

			// Add a gap to each size of the height of the child sub trees.
			// Our overall value will be the larger of our root node, or our children.
			subTreeSize.height =
				subTreeSize.height + this.treeConfig.nodeSiblingGap * 2;
			calculatedTreeSize.height = Math.max(
				calculatedTreeSize.height,
				subTreeSize.height
			);

			// Add to our width the width of our subtree.
			calculatedTreeSize.width = calculatedTreeSize.width + subTreeSize.width;
		} else {
			// Portrait

			// Add a gap to each size of the width of the child sub trees.
			// Our overall value will be the larger of our root node, or our children.
			subTreeSize.width =
				subTreeSize.width + this.treeConfig.nodeSiblingGap * 2;
			calculatedTreeSize.width = Math.max(
				calculatedTreeSize.width,
				subTreeSize.width
			);

			// Add to our height the height of our subtree.
			calculatedTreeSize.height =
				calculatedTreeSize.height + subTreeSize.height;
		}

		// Update the tree node data with the new calculated size.
		tree.size = calculatedTreeSize;

		// If our parent has hidden us, then our return size will be zero.
		// We do this check here rather than at the recursive loop as we stil
		// need to calculate our children sizes if they are expanded.
		if (tree.parentTree != null && !tree.parentTree.childrenShow)
			return { width: 0, height: 0 };

		return calculatedTreeSize;
	}

	/*******************************************************************************
	 * TREE USER INTRACTIONS
	 *******************************************************************************/

	// Should we wish to 'select' a node programatically as if it had been clicked
	selectTreeNodeByNodeId(id: string) {
		const treeNodeToSelect: TreeStructure = treeUtils.getTreeNodeById(
			this.tree,
			id
		);
		if (treeNodeToSelect) {
			this.treeSubTreeSelect(treeNodeToSelect);
		}
	}

	/**
	 *  A subTree has been selected by the user.
	 * This is for internal purposes, the control / selection of the contents
	 * is handled by the the node wrapping component, supplied to the tree.
	 * This is different to the add / delete, which have to use methods supplied to the tree
	 */
	treeSubTreeSelect(subTree: TreeStructure) {
		TreeStructure.applyFunctionSubTrees(this.tree, (tree) => {
			tree.isSelected = false;
			tree.isHighlighted = false;
		}); // Clear all previous selections and highlights.

		// Check we are being passed something to select.
		// silent avoids redraw..
		if (subTree == undefined) {
			this.treeHighlightedNodesSubject$.next([]);
			this.treeSelectedNodesSubject$.next(undefined);
			return;
		}

		if (subTree.parentTree != null) {
			TreeStructure.applyFunctionAncestors(
				subTree.parentTree,
				(tree) => (tree.isHighlighted = true)
			);
		} // All node above this subtree should be highlighted
		subTree.isSelected = true; // This subtree has been selected.
		subTree.isHighlighted = true; // It should also be highlighted.

		// Trigger obsevale indicating new highlighted and selected items.
		this.treeHighlightedNodesSubject$.next(
			TreeStructure.nodesHighlighted(this.tree)
		);
		this.treeSelectedNodesSubject$.next(subTree.node);
	}

	treeSubTreeOpenAll() {
		// Flag the whole tree structure to show children.
		TreeStructure.applyFunctionSubTrees(
			this.tree,
			(tree) => (tree.childrenShow = true)
		);

		// Have the whole tree recalculate the widths required to display correctly.
		this.debouncedTreeStructureSizeCalc();

		// Call a resposition on all parts of the tree.
		TreeStructure.applyFunctionSubTrees(this.tree, (tree) =>
			TreeStructure.reposition(tree)
		);

		// Then we better zoom to fit...
		this.treeZoomTrigger();
	}

	treeSubTreeOpen(treeToBeOpened: TreeStructure) {
		treeToBeOpened.childrenShow = true;
		// Have the current tree recalculate the widths required to display correctly.
		this.debouncedTreeStructureSizeCalc();
		// Call a resposition on required parts of the tree.
		TreeStructure.reposition(treeToBeOpened);
		// Then we better zoom to fit...
		this.treeZoomTrigger();
	}

	treeSubTreeClose(treeToBeOpened: TreeStructure) {
		treeToBeOpened.childrenShow = false;
		// Have the current tree recalculate the widths required to display correctly.
		this.debouncedTreeStructureSizeCalc();
		// Call a resposition on required parts of the tree.
		TreeStructure.reposition(treeToBeOpened);
		// Then we better zoom to fit...
		this.treeZoomTrigger();
	}

	treeSubTreeCloseAll() {
		// Flag the whole tree structure to hide children.
		TreeStructure.applyFunctionSubTrees(
			this.tree,
			(tree) => (tree.childrenShow = false)
		);

		// Have the whole tree recalculate the widths required to display correctly.
		this.debouncedTreeStructureSizeCalc();

		// Call a resposition on all parts of the tree.
		TreeStructure.applyFunctionSubTrees(this.tree, (tree) =>
			TreeStructure.reposition(tree)
		);

		// Then we better zoom to fit...
		this.treeZoomTrigger();
	}

	/**
	 * This function is called when the user clicks on the zoom out button.
	 */
	treeZoomOut() {
		// We cannot zoom out if the zoom has already reached its maxium.
		if (this.viewboxTarget.scale < 0.5) return;

		// Zoom in one level more than previous.
		let targetZoom: number = this.viewboxTarget.scale > 1 ? 1 : 0.5;

		// Request the zoom change.
		this.treeZoomChange(targetZoom);
	}

	/**
	 * This function is called when the user clicks on the zoom in button.
	 */
	treeZoomIn() {
		// We cannot zoom in if the zoom has already reached its minimum.
		if (this.viewboxTarget.scale > 1.5) return;

		// Zoom in one level more than previous.
		let targetZoom: number = this.viewboxTarget.scale < 1 ? 1 : 1.5;
		let zoomToFitCheckBox = false;

		// Request the zoom change.
		this.treeZoomChange(targetZoom);
	}

	zoomToFitSelected(e) {
		e.stopPropagation();
		e.preventDefault();
		this.treeZoomTrigger();
	}

	/**
	 * Triggers the auto zoom...
	 * this is ignored if teh auto zoom is switched off.
	 */
	treeZoomTrigger() {
		// No auto zoom enable .. dont zoom!
		if (!this.zoomToFitCheckBox) return;

		this.treeZoomFitAll();
	}

	/**
	 * Scales the zoom to fit teh displayed part of the tree.
	 */
	treeZoomFitAll() {
		//If the tree is null then bail out
		if (this.tree == null) return;

		// Get the size of the current view.
		let viewWidth: number =
			this.svgView.nativeElement.getBoundingClientRect().width;
		let viewHeight: number =
			this.svgView.nativeElement.getBoundingClientRect().height;

		// We need to calculate the scale needed to show all of the trees height and width.
		//let requiredWidthScale: number = ((this.tree.width + this.treeConfig.nodeWidth) / viewWidth);
		//let requiredHeightScale: number = ((this.tree.height + this.treeConfig.nodeHeight) / viewHeight);
		// let requiredWidthScale: number = 1 / ((this.tree.size.width) / viewWidth);
		let requiredWidthScale: number = viewWidth / this.tree.size.width;

		let requiredHeightScale: number = 1 / (this.tree.size.height / viewHeight);

		// Now we need to pick the higher of the 2 numbers. This is to make sure we get all of the contents on the screen.
		let targetScale: number = Math.min(requiredWidthScale, requiredHeightScale);

		// Make the request to change the focus point, the slight offset ( /2.0 would be center ).. positions slighty towards root node.. looks better, I think invisible padding at bottom is cause..
		this.treeConfig.treeLandscape
			? this.treeZoomChange(targetScale, this.tree.size.width / 3, 0)
			: this.treeZoomChange(targetScale, 0, this.tree.size.height / 3);
	}

	/**
	 * Change the current tree zoom(scale).
	 *
	 * @param targetScale     The target scale.
	 * @param targetX         The target x (if -1 then uses current view box focus x.)
	 * @param targetY         The target x (if -1 then uses current view box focus y.)
	 */
	private treeZoomChange(
		targetScale: number,
		targetX: number = -1,
		targetY: number = -1
	) {
		// Limit scaling to sensible values.
		targetScale = Math.max(Math.min(targetScale, 1.5), 0.2);

		// Make sure we use the current x and y if set as -1.
		targetX = targetX == -1 ? this.viewboxFocus.x : targetX;
		targetY = targetY == -1 ? this.viewboxFocus.y : targetY;

		// Now create the new view box point to change too.
		let targetViewBoxPoint: ViewboxPoint = {
			x: targetX,
			y: targetY,
			scale: targetScale,
		};

		// Make the request to change the focus point.
		this.focusOnPointRequest(targetViewBoxPoint);
	}

	/**
	 * Takes a node represented by Tree complainat data and calls the configured
	 * deleteMethod with it.
	 */
	treeDeleteNode(treeComplaintData: TreeNodeVO) {
		this.treeConfig.nodeDeleteFunction(
			this.treeConfig.functionScope,
			treeComplaintData
		);
	}

	/**
	 * Takes a node represented by Tree complainat data and calls the configured
	 * editMethod with it.
	 */
	treeEditNode(treeComplaintData: TreeNodeVO) {
		this.treeConfig.nodeEditFunction(
			this.treeConfig.functionScope,
			treeComplaintData
		);
	}

	/**
	 * Takes a node represented by Tree complainat data and calls the configured
	 * add methods with it.
	 */
	treeAddToNode(treeComplaintData: TreeNodeVO) {
		this.treeConfig.nodeAddFunction(
			this.treeConfig.functionScope,
			treeComplaintData
		);
	}

	/**
	 * Takes a node move and calls the configured
	 * move methods with it.
	 */
	treeMoveNode(nodeToMoveData: TreeNodeVO, newParentNodeData: TreeNodeVO) {
		return this.treeConfig.nodeMoveFunction(
			this.treeConfig.functionScope,
			nodeToMoveData,
			newParentNodeData
		);
	}

	/**
	 * Zoom control via mousewheel..
	 * @param $event
	 */
	mouseWheel($event) {
		this.zoomToFitCheckBox = false;
		// Mouse wheel up
		if ($event.deltaY > 0) {
			this.treeZoomChange(this.viewboxTarget.scale - 0.2);
		} else {
			// Mouse wheel down
			this.treeZoomChange(this.viewboxTarget.scale + 0.2);
		}
	}

	/**
	 * Opens the path down to the node selected. closing that which doesn't need to be open to see it
	 */
	showLineage(treeStructure: TreeStructure, calculateAndPosition?: boolean) {
		// Hide everything.
		TreeStructure.applyFunctionEverywhere(treeStructure, (tree) => {
			tree.childrenShow = false;
		});

		// Show Parents
		TreeStructure.applyFunctionAncestors(treeStructure, (tree) => {
			tree.childrenShow = true;
		});

		// But not my children.. ( switched on by above function.)
		treeStructure.childrenShow = false;

		//Calculate and position the tree by default or if the user said yes
		if (calculateAndPosition == true || calculateAndPosition == undefined) {
			// Recalculate tree sizes / layout.
			// everyone needs to check and move if required.
			this.tree.treeComponent.treeStructureRespositionAll();
		}
	}

	treeList$ = of(this.tree);
}
