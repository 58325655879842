import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";

@Component({
	selector: "app-info-dialog",
	templateUrl: "./info-dialog.component.html",
	styleUrls: ["./info-dialog.component.css"],
})
export class InfoDialogComponent implements OnInit {
	/**
	 * This is the title and content to display in this infoation dialog.
	 */
	public title: string;
	public contentAssetFileLocation: string;
	public content: string;

	getConfiguration = (): Observable<Object> => {
		return this.http.get(this.contentAssetFileLocation, {
			responseType: "text",
		});
	};

	constructor(
		public dialogRef: MatDialogRef<InfoDialogComponent>,
		private http: HttpClient
	) {}

	ngOnInit() {
		this.getConfiguration().subscribe((res) => {
			this.content = res.toString();
		});
	}
}
