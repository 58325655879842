import { FlatTreeControl } from "@angular/cdk/tree";
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
	MatTreeFlatDataSource,
	MatTreeFlattener,
} from "@angular/material/tree";
import { DomSanitizer } from "@angular/platform-browser";
import { UserIndexAHubVO } from "@harksolutions/ahub-web-services-types";
import {
	componentDestroyStream,
	Hark,
} from "app/modules/common/hark.decorator";
import { sortDateOldest } from "app/modules/common/sort.util";
import { Utils } from "app/modules/common/utils";
import { sessionUserId } from "app/store/selector/app.selector";
import { StoreAccess } from "app/store/store-access";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";

@Component({
	selector: "app-feedback-message-widget",
	templateUrl: "./feedback-message-widget.component.html",
	styleUrls: ["./feedback-message-widget.component.scss"],
})
@Hark()
export class FeedbackMessageWidgetComponent implements OnInit {
	@Input() feedback$: Observable<FeedbackView[]>;
	feedbackSortedByLastModifiedDate$: Observable<FeedbackView[]>;

	@Input() isOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	@Input() title = "Comments";
	@Input() subtitle = "";

	@Input() bottom: string;
	@Input() right: string;

	@ViewChild("feedbackContainer", { static: true }) feedbackContainer;
	@ViewChild("userFeedbackInput", { static: true }) userFeedbackInput;

	groupedFeedback$: Observable<GroupedFeedbackView[]>;

	@Output() addFeedback: EventEmitter<string> = new EventEmitter<string>();
	@Output() closeWidget = new EventEmitter();

	private readonly _transformer = (node: FoodNode, level: number) => {
		return {
			expandable: !!node.children && node.children.length > 0,
			name: node.name,
			level: level,
		};
	};

	treeControl = new FlatTreeControl<ExampleFlatNode>(
		(node) => node.level,
		(node) => node.expandable
	);

	treeFlattener = new MatTreeFlattener(
		this._transformer,
		(node) => node.level,
		(node) => node.expandable,
		(node) => node.children
	);

	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

	feedbackMessageInput: string;

	currentUserId: number = StoreAccess.dataGet(sessionUserId);

	showFeedbackGrouped = false;

	/**
	 * Define the form structure
	 */
	groupFeedbackForm: UntypedFormGroup = this.fb.group({
		grouped: [true],
	});

	timeDependentZIndex: number;

	constructor(
		public readonly fb: UntypedFormBuilder,
		private readonly hostElement: ElementRef,
		private readonly sanitizer: DomSanitizer
	) {
		this.dataSource.data = TREE_DATA;
	}

	hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

	ngOnInit() {
		this.userFeedbackInput.nativeElement.focus();

		this.timeDependentZIndex = this.takeLastXDigits(
			this.getDateNowInTenthOfASecond(),
			7
		);

		this.feedbackSortedByLastModifiedDate$ = this.feedback$?.pipe(
			takeUntil(componentDestroyStream(this)),
			map((feedback) => {
				this.updateScroll();
				return (
					feedback?.sort((a, b) =>
						sortDateOldest(a.lastModified, b.lastModified)
					) || []
				);
			})
		);

		this.groupedFeedback$ = combineLatest([
			this.feedbackSortedByLastModifiedDate$?.pipe(
				Utils.isNotNullOrUndefined()
			),
			this.groupFeedbackForm.controls.grouped.valueChanges.pipe(
				startWith(true),
				Utils.isNotNullOrUndefined()
			),
		]).pipe(
			takeUntil(componentDestroyStream(this)),
			map(([feedback, groupFeedbackControl]) => {
				let groupedFeedback: GroupedFeedbackView[] = [];

				if (groupFeedbackControl) {
					// Firstly lets grab all product (or alloc or asset-alloc) feedback
					const allProductRelatedFeedback: FeedbackView[] = feedback.filter(
						(feedback) => feedback.productId > 0
					);
					const nonProductRelatedFeedback: FeedbackView[] = feedback.filter(
						(feedback) => feedback.productId === 0
					);

					// group together non product related feedback
					groupedFeedback = groupedFeedback.concat(
						groupedFeedback,
						this.buildGroupedFeedbackViews(nonProductRelatedFeedback)
					);

					// Now lets build some nested product feedback with alloc and asset-alloc feedback within
					// lets figure out some unique product feedback parents
					const productParentProductIds: number[] = Array.from(
						new Set(
							allProductRelatedFeedback
								.filter(
									(feedback) =>
										feedback.type === "product" ||
										feedback.type === "allocationId"
								)
								?.map((feedback) => feedback.productId)
						)
					);

					let parentProductFeedbackViews: FeedbackView[] =
						productParentProductIds.map((id) => {
							const allParentProductFeedback = allProductRelatedFeedback.filter(
								(feedback) => feedback.productId === id
							);

							return (
								allParentProductFeedback.find(
									(feedback) => feedback.type === "product"
								) || allParentProductFeedback[0]
							);
						});

					// Now we can build some GroupedFeedback with nested GroupFeedback
					parentProductFeedbackViews.forEach((parentFeedback) => {
						const productRelatedFeedback: FeedbackView[] =
							allProductRelatedFeedback.filter(
								(feedback) => feedback.productId === parentFeedback.productId
							);
						const nestedGroupedFeedback: GroupedFeedbackView[] =
							this.buildGroupedFeedbackViews(productRelatedFeedback);

						// In order to have the feedback messages for the parent product appear together also, lets identify them from the
						// 'all nested group'
						const groupedFeedbackForParentProduct: GroupedFeedbackView =
							nestedGroupedFeedback.find(
								(nestedFeedback) => nestedFeedback.type === "product"
							);
						const groupedNestedFeedbackForProduct: GroupedFeedbackView[] =
							nestedGroupedFeedback.filter(
								(nestedFeedback) => nestedFeedback.type !== "product"
							);

						const groupedProductFeedbackWithNestedFeedback: GroupedFeedbackView =
							{
								title: parentFeedback.title,
								type: parentFeedback.type,
								imageUrl: parentFeedback.imageUrl,
								feedbackViews:
									groupedFeedbackForParentProduct?.feedbackViews || [],
								nestedGroups: groupedNestedFeedbackForProduct,
							};
						groupedFeedback.push(groupedProductFeedbackWithNestedFeedback);
					});

					// We should also handle the situation where the feedback coming in is just for a specific property
					// And parent product is no longer relevant
					if (
						!parentProductFeedbackViews ||
						parentProductFeedbackViews.length === 0
					) {
						groupedFeedback = this.buildGroupedFeedbackViews(feedback);
					}
				} else {
					feedback.forEach((feedback) => {
						const feedbackGroup: GroupedFeedbackView = {
							title: feedback.title,
							subtitle: feedback.subtitle,
							imageUrl: feedback.imageUrl,
							type: feedback.type,
							feedbackViews: [feedback],
						};
						groupedFeedback.push(feedbackGroup);
					});
				}

				this.updateScroll();

				return groupedFeedback;
			})
		);
	}

	getDateNowInTenthOfASecond(): number {
		return Date.now() / 100;
	}

	takeLastXDigits(n: number, digitsCount: number): number {
		const modulo = Array(digitsCount)
			.fill(0)
			.reduce((acc) => acc * 10, 1);
		return Math.round(n % modulo);
	}

	buildGroupedFeedbackViews(
		feedbackViews: FeedbackView[]
	): GroupedFeedbackView[] {
		const groupedFeedbackViews: GroupedFeedbackView[] = [];

		const feedbackMapByGroupId: Map<string, FeedbackView[]> = new Map<
			string,
			FeedbackView[]
		>();

		feedbackViews.forEach((feedback) => {
			let feedbackByGroupId: FeedbackView[] =
				feedbackMapByGroupId.get(feedback.groupId)?.length > 0
					? feedbackMapByGroupId.get(feedback.groupId)
					: [];
			feedbackByGroupId.push(feedback);
			feedbackMapByGroupId.set(feedback.groupId, feedbackByGroupId);
		});

		for (let [groupId, feedbackViews] of feedbackMapByGroupId.entries()) {
			const feedbackGroup: GroupedFeedbackView = {
				title: feedbackViews[0]?.title,
				subtitle: feedbackViews[0]?.subtitle,
				imageUrl: feedbackViews[0]?.imageUrl,
				type: feedbackViews[0]?.type,
				feedbackViews: feedbackViews.sort((a, b) =>
					sortDateOldest(a.lastModified, b.lastModified)
				),
			};
			groupedFeedbackViews.push(feedbackGroup);
		}

		return groupedFeedbackViews;
	}

	updateScroll() {
		setTimeout(() => {
			this.feedbackContainer.nativeElement.scrollTop =
				this.feedbackContainer.nativeElement.scrollHeight;
		}, 200);
	}

	closeWindow() {
		this.closeWidget.emit();
	}

	addFeedbackInput() {
		this.addFeedback.emit(this.feedbackMessageInput);
		this.feedbackMessageInput = "";
	}

	ngOnDestroy() {}

	ngAfterViewInit() {
		this.updateScroll();
	}

	/**
	 *	Get origional size (used for the resize function later)
	 */
	elemWidth = 0;
	elemHeight = 0;
	origionalWidth = 0;
	origionalHeight = 0;
	width = this.sanitizer.bypassSecurityTrustStyle("");
	height = this.sanitizer.bypassSecurityTrustStyle("");
	onResizeStart(event) {
		this.origionalWidth =
			event.source.element.nativeElement.parentElement.getBoundingClientRect().width;
		this.origionalHeight =
			event.source.element.nativeElement.parentElement.getBoundingClientRect().height;
	}
	/**
	 *	Resize event
	 */
	onResizeHandleDrag(event) {
		this.elemWidth = this.origionalWidth - event.distance.x;
		this.elemHeight = this.origionalHeight - event.distance.y;
		if (event.event.clientY > 130) {
			this.height = this.elemHeight + "px";
		}
		if (event.event.clientX > 125) {
			this.width = this.elemWidth + "px";
		}
		event.source.reset();
		event.source.element.nativeElement.parentElement.classList.add(
			"cdk-drag-dragging"
		);
	}

	// Recursive tracked by ID to attempt to get a unique tracking ID for nested and grouped items.
	// This prevents the feed refreshing all messages and instead only loads in the new messages.
	trackById(index, indexItem: GroupedFeedbackView) {
		if (indexItem) {
			if (
				indexItem.feedbackViews?.length > 0 &&
				indexItem.feedbackViews[0]?.feedbackMessage
			) {
				return indexItem.feedbackViews[0].lastModified;
			} else {
				if (indexItem.nestedGroups?.length > 0 && this.trackById) {
					for (let i = 0; i < indexItem.nestedGroups?.length; i++) {
						const trackedString = this.trackById(
							index,
							indexItem.nestedGroups[i]
						);
						if (trackedString) {
							return `${trackedString}-${indexItem.title}`;
						}
					}
				}
			}
		}
		return undefined;
	}
}

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface FoodNode {
	name: string;
	children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
	{
		name: "Fruit",
		children: [{ name: "Apple" }, { name: "Banana" }, { name: "Fruit loops" }],
	},
	{
		name: "Vegetables",
		children: [
			{
				name: "Green",
				children: [{ name: "Broccoli" }, { name: "Brussels sprouts" }],
			},
			{
				name: "Orange",
				children: [{ name: "Pumpkins" }, { name: "Carrots" }],
			},
		],
	},
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
	expandable: boolean;
	name: string;
	level: number;
}

export interface GroupedFeedbackView {
	title: string;
	subtitle?: string;
	imageUrl: string;
	type: string;
	feedbackViews: FeedbackView[];
	nestedGroups?: GroupedFeedbackView[];
}

function ngAfterViewInit() {
	throw new Error("Function not implemented.");
}

export interface FeedbackView {
	groupId: string; //e.g. 'productId'+productId+'allocationId'+allocationId+'assetId'+assetId
	selectionId: number;
	title: string;
	imageUrl: string;
	userIndex: UserIndexAHubVO;
	feedbackId?: number;
	subtitle?: string;
	propertyValue?: string;
	propertyType?: string;
	propertyLabel?: string;
	productId?: number;
	allocationId?: number;
	assetId?: number;
	type: string;
	feedbackMessage: string;
	lastModified: Date;
}
