import { Action, Reducer } from "redux";
import { createReducer } from "reducers/reducer-util";
import { tassign } from "modules/common/type-assign.util";

/**
 * Action Types
 */
import { ActionNumber } from "actions/types/common.action-types";

/**
 * Actions
 */
import { ViewActions } from "actions/view.actions";

/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ViewWorkGroupsState {
	selectedWorkGroupId: number;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewWorkGroupsInitialState: ViewWorkGroupsState = {
	selectedWorkGroupId: -1,
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the selected work group id
 */
export const selectedWorkGroupIdSet = (
	state: ViewWorkGroupsState,
	action: ActionNumber
) => {
	//Set the selected work group id
	return tassign(state, { selectedWorkGroupId: action.number });
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer
 */
reducerHandlers[ViewActions.WORK_GROUP_SELECTED_ID_SET] =
	selectedWorkGroupIdSet;

/**
 * Create a reducers based on the reducers handlers
 */
export const ViewWorkGroupsStateReducer: Reducer<ViewWorkGroupsState> =
	createReducer(ViewWorkGroupsInitialState, reducerHandlers);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewWorkGroupsStateHasHandler = (actionType: string): boolean =>
	reducerHandlers.hasOwnProperty(actionType);
