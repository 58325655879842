/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import { viewState } from "selector/view/view.selector";
import { ViewExportState } from "reducers/view/view-export.reducer";
import {
	aHubStateTemporaryExportList,
	aHubStateTemporaryExportDistributionList,
	aHubStateTemporaryExportDistributionIndexList,
	aHubStateTemporaryDistributionsList,
	aHubStateTemporaryExportLegacyRefs,
} from "selector/ahub/ahub-temporary.selector";

import { ListUtil } from "store/list.util";
import { SelectorUtil } from "store/selector.util";

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */

import { RelationshipAHubVO } from "valueObjects/ahub/relationship.ahub.vo";
import { ExportAHubVO } from "valueObjects/ahub/accounts/export.ahub.vo";
import { ExportDistributionAHubVO } from "valueObjects/ahub/accounts/export-distribution.ahub.vo";
import { DistributionAHubVO } from "valueObjects/ahub/accounts/distribution.ahub.vo";
import { DistributionIndexAHubVO } from "valueObjects/ahub/accounts/distribution-index.ahub.vo";
import { IdItemVO } from "store/id-item.vo";

/**
 * Root selector to get the exports view state from the store state
 */
const viewExportState = (state): ViewExportState => viewState(state).exports;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------


/**
 * Currently selected export id
 */
export const viewSelectedExportId = (state): number =>
	viewExportState(state).selectedExportId;

/**
 * Currently selected export distribution id
 */
export const viewSelectedExportDistributionId = (state): number =>
	viewExportState(state).selectedExportDistributionId;

/**
 * Currently selected export version id
 */
export const viewSelectedExportVersionId = (state): number =>
	viewExportState(state).selectedExportVersionId;

/**
 * Currently selected export distribution id
 */
export const viewSelectedDistributionId = (state): number =>
	viewExportState(state).selectedDistributionId;

/**
 * export version hide states array
 */
export const viewExportVersionHideStates = (state): string[] =>
	viewExportState(state).exportVersionHideStates;

/**
 * A selector  which combines an aHub selector and the currently select item to
 */
export const viewSelectedExport = (state): ExportAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryExportList(state),
		viewSelectedExportId(state)
	);

/**
 * A selector  which combines an aHub selector and the currently select item to
 */
export const viewSelectedExportLegacyRefs = (state): IdItemVO<string[]> =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryExportLegacyRefs(state),
		viewSelectedExportId(state)
	);

/**
 * Currently selected export distribution
 */
export const viewSelectedDistribution = (state): DistributionAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryDistributionsList(state),
		viewSelectedDistributionId(state)
	);

/**
 * A selector  which combines an aHub selector and the currently selected distribution group
 */
export const viewSelectedExportDistributionIndexesRelationship = (
	state
): RelationshipAHubVO<DistributionIndexAHubVO> =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryExportDistributionIndexList(state),
		viewSelectedExportId(state)
	);

/**
 * A selector  which gets the distribution indexes from the relationship - null safe
 */
export const viewSelectedExportDistributionIndexes = (
	state
): DistributionIndexAHubVO[] =>
	SelectorUtil.relationships(
		state,
		viewSelectedExportDistributionIndexesRelationship
	);

/**
 * Currently selected export distribution
 */
export const viewSelectedExportDistribution = (
	state
): ExportDistributionAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryExportDistributionList(state),
		viewSelectedExportDistributionId(state)
	);
