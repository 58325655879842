import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	Injector,
	Input,
	OnInit,
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AViewUtils } from "app/modules/routes/aview/utils/aview-utils";
import { ClassAViewVO } from "app/modules/routes/aview/valueObjects/class.aview.vo";
import { ProductViewClassConfigWithInheritanceAHubVO } from "app/modules/routes/aview/valueObjects/product-view-class-config-with-inheritance.aview.vo";
import { ProductViewClassConfigAViewVO } from "app/modules/routes/aview/valueObjects/product-view-class-config.aview.vo";
import { ProductViewConfigAViewVO } from "app/modules/routes/aview/valueObjects/product-view-config.aview.vo";
import { PropertyAllocationObjectAViewVO } from "app/modules/routes/aview/valueObjects/property-allocation-object.aview.vo";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import {
	distinctUntilKeyChanged,
	filter,
	map,
	shareReplay,
	takeUntil,
} from "rxjs/operators";
import { componentDestroyStream, Hark } from "../../hark.decorator";
import { Utils } from "../../utils";
import {
	ProductViewClassConfigAllocationModel,
	ProductViewClassConfigModel,
} from "../product-view-class-config/product-view-class-config.component";
import {
	ProductViewConfigClassTreeItem,
	ProductViewConfigClassTreeItemComponent,
} from "../product-view-config-class-tree-item/product-view-config-class-tree-item.component";

@Component({
	selector: "app-product-view-config",
	templateUrl: "./product-view-config.component.html",
	styleUrls: ["./product-view-config.component.scss"],
})
@Hark()
export class ProductViewConfigComponent implements OnInit {
	@Input() cssClass = "";

	@Input() public productViewConfigParams: ProductViewConfigParams = null;

	productViewConfig: ProductViewConfigAViewVO;

	classIndex$: BehaviorSubject<ClassAViewVO[]> = new BehaviorSubject(undefined);
	rootNodeLabel = "Root Class";

	selectedClassId$: BehaviorSubject<number> = new BehaviorSubject<number>(
		undefined
	);

	selectedClass$: Observable<ClassAViewVO> = combineLatest([
		this.classIndex$.pipe(Utils.isNotNullOrUndefined()),
		this.selectedClassId$.pipe(
			filter(
				(selectedClassId) =>
					selectedClassId !== undefined && selectedClassId > 0
			)
		),
	]).pipe(
		map(([classIndexes, selectedClassId]) => {
			return classIndexes
				? classIndexes.find((classIndex) => classIndex.id === selectedClassId)
				: undefined;
		}),
		distinctUntilKeyChanged("id"),
		takeUntil(componentDestroyStream(this)),
		shareReplay()
	);

	// Lets get the allocs for this aview
	selectedAViewClassAllocs$: Observable<PropertyAllocationObjectAViewVO[]>;

	allocObjects$: Observable<PropertyAllocationObjectAViewVO[]>;

	selectedClassProductViewConfig$: BehaviorSubject<ProductViewClassConfigWithInheritanceAHubVO> =
		new BehaviorSubject(undefined);

	selectedProductViewClassConfigModel: ProductViewClassConfigModel;

	// Lets pop a little asterisk after classIndex label for the classes which have config
	classIndexesWithHasConfigIndicator$: Observable<
		ProductViewConfigClassTreeItem[]
	>;

	/**
	 * Component factory to generate components for the index.
	 */
	public componentFactory: ComponentFactory<ProductViewConfigClassTreeItemComponent> =
		this.resolver.resolveComponentFactory(
			ProductViewConfigClassTreeItemComponent
		);

	private dialogRef = null;
	constructor(
		private injector: Injector,
		private readonly resolver: ComponentFactoryResolver
	) {
		this.dialogRef = this.injector.get(MatDialogRef, null);
	}

	ngOnInit() {
		this.classIndex$.next(this.productViewConfigParams.productClasses);

		// Lets reduce the allocs to only those necessary for the selected class
		this.selectedAViewClassAllocs$ = combineLatest([
			this.selectedClass$.pipe(
				filter((selectedClass) => selectedClass !== undefined)
			),
			this.productViewConfigParams.allocObjects$.pipe(
				Utils.isNotNullOrUndefined()
			),
		]).pipe(
			map(([selectedClass, allocObjects]) => {
				const selectedClassAncestryIds = AViewUtils.getAncestryAsNumberArray(
					selectedClass.ancestry
				);
				// Add selected class id
				selectedClassAncestryIds.push(selectedClass.id);
				return allocObjects.filter((sectionAlloc) =>
					selectedClassAncestryIds.includes(sectionAlloc.productClass.id)
				);
			}),
			takeUntil(componentDestroyStream(this)),
			shareReplay()
		);

		// Get the product view config for the selected class
		combineLatest([
			this.selectedClass$.pipe(Utils.isNotNullOrUndefined()),
			this.productViewConfigParams.productViewConfig$.pipe(),
		])
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe(([selectedClass, productViewConfig]) => {
				this.productViewConfig = productViewConfig;

				// no product view config, no probs, lets make an empty one
				if (!this.productViewConfig) {
					this.productViewConfig = {
						productViewClassConfigs: [],
					};
				}

				let selectedClassProductViewConfig: ProductViewClassConfigAViewVO;

				if (
					this.productViewConfig &&
					this.productViewConfig.productViewClassConfigs &&
					this.productViewConfig.productViewClassConfigs.length > 0
				) {
					selectedClassProductViewConfig =
						this.productViewConfig.productViewClassConfigs.find(
							(classConfig) => classConfig.classId === selectedClass.id
						);
				}

				// Lets find the classProductViewConfig for this class...AND/OR use the config
				// from parent classes in the tree
				this.selectedProductViewClassConfigModel =
					AViewUtils.buildProductViewClassConfigModelWithInheritance(
						Utils.clone(selectedClassProductViewConfig),
						Utils.clone(this.productViewConfig),
						selectedClass
					);

				// Lets sort any info properties such that inherited properties are listed first
				if (
					this.selectedProductViewClassConfigModel.productInfoPropertyAllocs
				) {
					this.selectedProductViewClassConfigModel.productInfoPropertyAllocs =
						AViewUtils.sortModelInfoPropertiesIntoInheritedFirst(
							this.selectedProductViewClassConfigModel.productInfoPropertyAllocs
						);
				}
			});

		/**
		 * If the product view config is updated we should rebuild the class tree to include an indicator for classes with config
		 */
		this.classIndexesWithHasConfigIndicator$ = combineLatest([
			this.classIndex$.pipe(Utils.isNotNullOrUndefined()),
			this.productViewConfigParams.productViewConfig$.pipe(),
		]).pipe(
			map(([classIndexes, viewConfig]) => {
				const viewConfigClassIdsWithConfig: number[] = viewConfig
					? viewConfig.productViewClassConfigs.map(
							(classConfig) => classConfig.classId
					  )
					: [];

				const productViewConfigClassTreeIndexes: ProductViewConfigClassTreeItem[] =
					classIndexes;

				productViewConfigClassTreeIndexes.forEach((classIndex) => {
					classIndex.hasConfig = viewConfigClassIdsWithConfig.includes(
						classIndex.id
					);
				});

				return productViewConfigClassTreeIndexes;
			})
		);
	}

	resetUserProductViewConfig() {
		this.productViewConfigParams.productViewConfig$.next(
			Utils.clone(this.productViewConfigParams.defaultProductViewConfig)
		);
	}

	/**
	 * Handle the change of the selected class on the config menu!
	 *
	 * @param clazz
	 */
	classSelectedHandler(clazz) {
		//Do we have a class
		if (clazz) {
			this.selectedClassId$.next(clazz.id);
		}
	}

	configChangeHandler(changedConfigModel: ProductViewClassConfigModel) {
		// Lets strip out the inherited properties for the changed class config
		const changedConfigModelWithoutInheritedProperties: ProductViewClassConfigModel =
			this.removeInheritedProperties(changedConfigModel);

		const changedClassConfig = AViewUtils.convertToProductViewClassConfig(
			changedConfigModelWithoutInheritedProperties
		);

		const exisitingClassConfig: ProductViewClassConfigAViewVO =
			this.productViewConfig.productViewClassConfigs.find(
				(classConfig) => classConfig.classId === changedConfigModel.classId
			);
		if (exisitingClassConfig) {
			this.productViewConfig.productViewClassConfigs =
				this.productViewConfig.productViewClassConfigs.map((classConfig) => {
					if (classConfig.classId === changedConfigModel.classId) {
						classConfig = changedClassConfig;
					}

					return classConfig;
				});
		} else {
			// New config!
			this.productViewConfig.productViewClassConfigs.push(changedClassConfig);
		}

		this.productViewConfigParams.productViewConfig$.next(
			this.productViewConfig
		);
	}

	/**
	 * Removes properties from config model whcih have been inherited from a parent class config
	 */
	removeInheritedProperties(
		changedConfigModel: ProductViewClassConfigModel
	): ProductViewClassConfigModel {
		// Lets make a minimal config model to hold non inherited properties
		const changedConfigModelWithoutInheritedProperties: ProductViewClassConfigModel =
			{
				classId: changedConfigModel.classId,
			};

		// Cycle through the changed properties (this will include inherited properties)
		Object.keys(changedConfigModel).forEach((key) => {
			const changedConfigModelProperty = changedConfigModel[key];

			// info properties are an array, we'll deal with those differently
			if (Array.isArray(changedConfigModelProperty)) {
				const changedConfigModelPropertyArrayWithoutInheritance: ProductViewClassConfigAllocationModel[] =
					changedConfigModelProperty.filter(
						(modelProperty: ProductViewClassConfigAllocationModel) =>
							!modelProperty.inheritedFrom
					);

				// Do we have anything new?
				if (changedConfigModelPropertyArrayWithoutInheritance.length > 0) {
					// Ok lets keep it
					changedConfigModelWithoutInheritedProperties[key] =
						changedConfigModelPropertyArrayWithoutInheritance;
				}
			} else {
				// Non array property

				// Is this property not inherited
				if (
					changedConfigModelProperty &&
					!changedConfigModelProperty.inheritedFrom
				) {
					changedConfigModelWithoutInheritedProperties[key] =
						changedConfigModelProperty;
				}
			}
		});

		return changedConfigModelWithoutInheritedProperties;
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}
}

export interface ProductViewConfigParams {
	defaultProductViewConfig: ProductViewConfigAViewVO;
	productViewConfig$: BehaviorSubject<ProductViewConfigAViewVO>;
	allocObjects$: Observable<PropertyAllocationObjectAViewVO[]>;
	productClasses: ClassAViewVO[];
	showRestoreDefaultConfigButton?: boolean;
	restoreDefaultConfigButtonMessage?: string;
	// used to allow us the clear user view configs for the current publication/edition (e.g. the restore button above has been clicked)
	publicationId?: number;
	edition?: number;
	disabledAllocIdsAndReasonMap$?: BehaviorSubject<Map<number, string>>;
}
