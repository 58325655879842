import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ReorderingVO } from "../reorder-dialog/reorder-dialog.component";
import { TreeStructure } from "../tree-structure.class";
import { TreeNodeVO } from "../tree.component";

@Component({
	selector: "app-move-dialog",
	templateUrl: "./move-dialog.component.html",
	styleUrls: ["./move-dialog.component.css"],
})
export class MoveDialogComponent implements OnInit {
	constructor(private readonly formBuilder: UntypedFormBuilder) {}

	/**
	 * Tree
	 */
	@Input()
	public tree: TreeStructure = null;

	/**
	 * VO
	 */
	@Input()
	public reorderingVO: ReorderingVO = null;

	siblings: TreeStructure[];
	treeNodeArray: TreeStructure[] = [];

	reorderingForm: UntypedFormGroup = this.buildForm();

	buildForm(): UntypedFormGroup {
		return this.formBuilder.group({
			targetNode: "",
		});
	}

	ngOnInit(): void {
		this.addNodesToArray(this.getTopLevelTree(this.tree), this.treeNodeArray);
		this.reorderingVO = {
			above: true,
			targetNode: this.reorderingForm.controls.targetNode.value,
		};

		this.siblings = this.treeNodeArray.filter(
			(node) => node.id != this.tree.node.id
		);

		this.reorderingForm.controls.targetNode.valueChanges.subscribe((value) => {
			this.reorderingVO.targetNode = value;
		});
	}

	getTopLevelTree(tree: TreeStructure) {
		let topLevelNode = tree;
		if (tree.parentTree) {
			topLevelNode = this.getTopLevelTree(tree.parentTree);
		}
		return topLevelNode;
	}
	addNodesToArray(tree: TreeStructure, treeNodeArray: TreeStructure[]) {
		treeNodeArray.push(tree);
		let subtrees: TreeStructure[] = tree.subTrees;
		subtrees.forEach((v: TreeStructure, k) => {
			this.addNodesToArray(v, treeNodeArray);
		});
	}
}
