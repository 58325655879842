import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
	selector: "app-video-embed-dialog",
	templateUrl: "./video-embed-dialog.component.html",
	styleUrls: ["./video-embed-dialog.component.css"],
})
export class VideoEmbedDialogComponent implements OnInit {
	title: string;
	videoUrl: string;
	cancelButtonLabel: string;
	safeSrc: SafeResourceUrl;

	constructor(
		public readonly dialogRef: MatDialogRef<VideoEmbedDialogComponent>,
		private readonly sanitizer: DomSanitizer
	) {}

	onCloseClick(): void {
		this.dialogRef.close();
	}

	ngOnInit() {
		this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
	}
}
