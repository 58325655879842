import { AfterViewInit, Component } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { GridEditorParamsInterface } from "../grid-editor-params-interface";
import { ICellEditorParams } from "ag-grid-community";

@Component({
	selector: "app-grid-editor-boolean",
	templateUrl: "./grid-editor-boolean.component.html",
	styleUrls: ["./grid-editor-boolean.component.css"],
})
export class GridEditorBooleanComponent
	implements ICellEditorAngularComp, AfterViewInit
{
	params: ICellEditorParams;

	/**
	 * Parameters for the editor
	 */
	paramsCustom: GridEditorBooleanParams;

	/**
	 * Toggle state of the editor
	 */
	toggledState;

	/**
	 * Once the view has initalised we need to know
	 */
	ngAfterViewInit(): void {
		// WHAT HAPPENING AN WHY.
		//Well we are not allowed inline editors with ag-grid. ie a checkbox
		//which changes the state of the current value. We did some play with having the
		//render change the state. However it wasn't feeling right and feel it was going to
		//end up with some confusing states. So we have created a renderer simply when we enter
		//editing mode, swaps the state and closes. So we don't have to go to the hastle of actually
		//then ticking a box. This is the best thing we can think of for now which isn't mega dirty

		//Toggle the state of the value in the renderer
		this.toggledState = this.toggledState === "1" ? "0" : "1";

		//After a timeout we will stop editing and prevent any further rendering taking place
		setTimeout(() => this.params.api.stopEditing(), 10);
	}

	/**
	 * Setup the editor
	 */
	agInit(params: any): void {
		//Setup the inital parameters
		this.params = params;
		this.paramsCustom = params as GridEditorBooleanParams;

		//Set the inital toggle state of the renderer
		this.toggledState = this.params.value;
	}

	/**
	 * Is called when editing is about to start. Giving
	 * us an opertunity to stop the editing before it happens
	 */
	isCancelBeforeStart(): boolean {
		//Do we have a supplied cancel function in the parameters
		if (this.paramsCustom.canEditCellFunc) {
			return !this.paramsCustom.canEditCellFunc(this.params);
		}

		//No is cancel function so no cancel behaviour
		return false;
	}

	/**
	 * Get the current value from the editor
	 */
	getValue(): any {
		return this.toggledState;
	}

	/**
	 * This editor is not a popup
	 */
	isPopup?(): boolean {
		return false;
	}
}

/**
 * Parameters class which shows what we can pass into the editor
 */
export interface GridEditorBooleanParams extends GridEditorParamsInterface {}
