import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-feedback-message-icon",
	templateUrl: "./feedback-message-icon.component.html",
	styleUrls: ["./feedback-message-icon.component.css"],
})
export class FeedbackMessageIconComponent implements OnInit {
	@Input() size: number = 15;
	@Input() messageCount = 0;
	@Input() colour = "#6074e2";

	constructor() {
		// This is intentional
	}

	ngOnInit(): void {
		// This is intentional
	}
}
