import { List } from "store/list.vo";

/**
 * Utility class for managing lists
 */
export class ListUtil {
	/**
	 * Create an empty list item
	 */
	public static listCreateEmpty(dataPropertyToIndex?: string): List<any> {
		//Return an empty list
		return ListUtil.listCreate([], dataPropertyToIndex);
	}

	/**
	 * Create the list based on the data array supplied and the property to index
	 */
	public static listCreate<T>(
		dataItems: T[],
		dataPropertyToIndex?: string
	): List<T> {
		//Create a list object with the defined type
		let list: List<T> = { data: dataItems, indexDirectory: {}, size: 0 };

		//Call for an update on the metaData
		return ListUtil.listMetaDataUpdate(list, dataPropertyToIndex);
	}

	/**
	 * Check the list directory for the items index
	 */
	public static listDirectoryIndexGet(list: List<any>, id: any): number {
		//Check that the list and directory exists
		if (list && list.indexDirectory) {
			//Get the item index
			let index = list.indexDirectory[id];

			//If index is defined then return it, if not return -1
			return index != undefined && index > -1 ? index : -1;
		}

		//We were missing a list return -1
		return -1;
	}

	/**
	 * Get the data with the id specified
	 */
	public static listDataItemGet<T>(list: List<T>, id: any): T {
		//If the list is missing retrun undefined
		if (list == undefined) return undefined;

		//Get the index of the item
		let index: number = ListUtil.listDirectoryIndexGet(list, id);

		//Return the data item and the index we looked up ... if its on the list
		return index != -1 ? list.data[index] : undefined;
	}

	/**
	 * Get the data with the id specified
	 */
	public static listDataItemsGet<T>(list: List<T>, ids: any[]): T[] {
		//If the list is missing retrun undefined
		if (list == undefined) {
			return undefined;
		}

		const dataItems = [];

		for (const id of ids) {
			//Get the index of the item
			let index: number = ListUtil.listDirectoryIndexGet(list, id);

			// If item found with matching id
			if (index !== -1) {
				dataItems.push(list.data[index]);
			}
		}
		//Return the data items that match the supplied ids
		return dataItems;
	}

	/**
	 * Will create a new list with the list of items appended. If an item
	 * with the same id exists in the list then new item will replace the old one
	 */
	public static listAppend<T>(
		list: List<T>,
		appendItems: T[],
		indexProperty: string
	): List<T> {
		//If we are missing the append items we will use an empty list
		if (!appendItems) {
			appendItems = [];
		}

		// De-Dupe New Items  ----------------------------------------------

		//We will create a map object for the new items for a moment. This
		//will allow us to de-dupe the append items in two passes of the array
		let newItemMap = {};

		//Loop through the list setting each item to the map. This will allow us to de-dupe
		//based on the id.
		appendItems.forEach((item) => (newItemMap[item[indexProperty]] = item));

		//Go and get each item from the map and reset the array back into the appended items
		appendItems = Object.keys(newItemMap).map((key) => newItemMap[key]);

		//Map the list of append items to a new array of their id's
		let newItemIds: any[] = appendItems.map((item) => item[indexProperty]);

		// Remove new items from existing list  ----------------------------

		//We will take the current list and remove all the items which match an id in our new items id list
		//this way we can do a blanket add at the end
		let listData: T[] = ListUtil.listDataRemoveIds(list, newItemIds);

		// Append new items  ----------------------------------------------

		//We now have a list without any of the items we are about to add this means we can do a blanket
		//data concat. This should be much quicker than doing data filters
		listData = listData.concat(appendItems);

		//Create a new list from the data items less the items we want to remove
		return ListUtil.listCreate(listData, indexProperty);
	}

	/**
	 * Check to see if the data item with the id supplied exists
	 */
	public static listDataItemExists<T>(list: List<T>, id: any): boolean {
		// Check list is not empty.
		if (list == undefined || list.size < 1) return false;

		//If the list is defined and the item exists in the object index then it exists
		return ListUtil.listDirectoryIndexGet(list, id) != -1;
	}

	/**
	 * Takes a list of ids and filters them returning a new array
	 * of ids where they matched against existing list item ids.
	 */
	public static listDataFilterIdOnExists<T>(list: List<T>, ids: any[]): any[] {
		// Start with an empty matching list.
		let matchingIds: any[] = [];

		//If we have no matching ids then we will return an empty list
		if (!ids) return matchingIds;

		// Check for the ids, and if we find it , then add it to the matching list.
		for (let id of ids)
			if (list.indexDirectory[id] != undefined) matchingIds.push(id);

		return matchingIds;
	}

	/**
	 * Takes a list of ids and filters them returning a new array
	 * of ids where they didn't exist in the current data set
	 */
	public static listDataFilterIdOnNotExists<T>(
		list: List<T>,
		ids: any[]
	): any[] {
		// Start with an empty matching list.
		let matchingIds: any[] = [];

		// Check for the ids, and if we find it , then add it to the matching list.
		for (let id of ids)
			if (list.indexDirectory[id] == undefined) matchingIds.push(id);

		return matchingIds;
	}

	/**
	 * Takes a list and will return a new data array ( based on the lists data array )
	 * minus the items which match the ids specified
	 */
	public static listDataRemoveIds<T>(list: List<T>, ids: any[]): T[] {
		//We have no data in the list so return the list
		if (!list) return undefined;

		// Check for empty ids.
		if (!ids || ids.length < 1) return list.data;

		//Start with an array of the data
		let dataItems: T[] = [];

		//Check we have list data then duplicate and set it into the data items array
		if (list && list.data) dataItems = list.data.concat();

		//We will map each element of the ids array to the literal list of indexs we want to remove
		//the function we are using will retrun -1 if the item isn't in the list so we will also add a filter
		//to remove all the ids specfiefied which are not in the data list.
		//
		//We will also apply a sort to the list we will sort the list from higest id to lowest
		//this will mean we can loop through the list remove the items without affecting the indexs as we do so
		let indexsToRemove: number[] = ids
			.map((id) => ListUtil.listDirectoryIndexGet(list, id))
			.filter((index) => index > -1)
			.sort((indexA, indexB) =>
				indexA > indexB ? -1 : indexA < indexB ? 1 : 0
			);

		//Loop through each of the indexes to remove splicing the items of the list.
		indexsToRemove.forEach((indexToRemove) =>
			dataItems.splice(indexToRemove, 1)
		);

		//Return the data items array
		return dataItems;
	}

	/**
	 * Update the list metaData based on the current list data
	 */
	private static listMetaDataUpdate<T>(
		list: List<T>,
		dataPropertyToIndex: string
	): List<T> {
		//If the list or data within the list is undefined then we will bail out
		if (list == undefined) return undefined;

		//Initial properties for the update
		let indexDirectory: Object = {};
		let listSize: number = 0;

		//If the data is defined then we will update the data
		if (list.data != undefined) {
			//Create our index directory object if we have a property for indexing
			if (dataPropertyToIndex != undefined) {
				list.data.forEach(
					(dataItem: T, index: number) =>
						(indexDirectory[dataItem[dataPropertyToIndex].toString()] = index)
				);
			}

			//Set the list size
			listSize = list.data.length;
		}

		//Update the object index
		list.indexDirectory = indexDirectory;

		//Set the list size
		list.size = listSize;

		//Return the updated list
		return list;
	}
}
