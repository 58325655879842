/**
 * Static readonly implementations of the entity reference types within the aHub.
 */
export class EntityRefAHubEnum {
	static readonly SYSTEM: string = "SYSTEM";
	static readonly ACCOUNTS: string = "ACCOUNTS";
	static readonly USAGE: string = "USAGE";
	static readonly LIBRARY_DATA: string = "LIBRARY_DATA";
	static readonly LIBRARY_ASSETS: string = "LIBRARY_ASSETS";
	static readonly LIBRARY_ATTACHMENTS: string = "LIBRARY_ATTACHMENTS";
	static readonly LIBRARY_STORAGE: string = "LIBRARY_STORAGE";
	static readonly DISTRIBUTION: string = "DISTRIBUTION";
	static readonly PUBLISHING: string = "PUBLISHING";
}
