/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import { DataSetCategoryAHubVO } from "app/valueObjects/ahub/library/dataset-category.ahub.vo";
import { ExporterBuildHistoryIndexAHubVO } from "app/valueObjects/ahub/library/exporter-build-history-index.ahub.vo";
import { ExporterBuildHistoryAHubVO } from "app/valueObjects/ahub/library/exporter-build-history.ahub.vo";
import { ExporterMappingClassOptionAHubVO } from "app/valueObjects/ahub/library/exporter-mapping-class-option.ahub.vo";
import { PropertyAllocationObjectVO } from "app/valueObjects/stream/product-allocation-object-stream.vo";
import { BlueprintMappingNode } from "app/valueObjects/view/blueprint-mapping-node.ahub.vo";
import { ViewLibraryPublishingState } from "reducers/view/view-library-publishing.reducer";
import {
	aHubStateTemporaryDataSetCategoryList,
	aHubStateTemporaryDataSetCategoryProductIdSorted,
	aHubStateTemporaryDataSetCategoryProducts,
	aHubStateTemporaryDataSetList,
	aHubStateTemporaryExporterBuildHistoryIndexMap,
	aHubStateTemporaryExporterBuildHistorys,
	aHubStateTemporaryExporterList,
	aHubStateTemporaryResourcePackList,
} from "selector/ahub/ahub-temporary.selector";
import { viewState } from "selector/view/view.selector";
import { HashedMapStorage } from "store/hashed-map-storage.vo";
import { ListUtil } from "store/list.util";
import { List } from "store/list.vo";
import { MapStorageUtil } from "store/map-storage.util";
import { DataSetCategoryIndexAHubVO } from "valueObjects/ahub/library/dataset-category-index.ahub.vo";
/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */
import { DataSetAHubVO } from "valueObjects/ahub/library/dataset.ahub.vo";
import { ExporterAHubVO } from "valueObjects/ahub/library/exporter.ahub.vo";
import { ProductAHubVO } from "valueObjects/ahub/library/product.ahub.vo";
import { ResourcePackAHubVO } from "@harksolutions/ahub-web-services-types";
/**
 * Root selector to get the dataSets view state from the store state
 */
const viewLibraryPublishingState = (state): ViewLibraryPublishingState =>
	viewState(state).libraryPublishing;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------


/**
 * Currently selected dataSet id
 */
export const viewLibraryPublishingSelectedDataSetId = (state): number =>
	viewLibraryPublishingState(state).selectedDataSetId;

/**
 * A selector  which combines an aHub selector and the currently select item to
 */
export const viewLibraryPublishingSelectedDataSet = (state): DataSetAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryDataSetList(state),
		viewLibraryPublishingSelectedDataSetId(state)
	);

/**
 * A selector to get the currently selected data set category indexes.
 */
export const viewLibraryPublishingSelectedDataSetCategoryIndexes = (
	state
): DataSetCategoryIndexAHubVO[] => {
	//Get the indexes from the state
	let indexs = viewLibraryPublishingState(state).selectedDataSetCategoryIndexes;

	//We will sort the items before we return them
	return indexs
		? indexs.sort((a, b) =>
				(a.ancestry ? a.ancestry : "").length >
				(b.ancestry ? b.ancestry : "").length
					? 1
					: -1
		  )
		: [];
};

/**
 * Get the lowest category currently selected.
 */
export const viewLibraryPublishingSelectedDataSetCategoryIndex = (
	state
): DataSetCategoryIndexAHubVO => {
	//Get the indexes from the list
	let indexes = viewLibraryPublishingSelectedDataSetCategoryIndexes(state);

	//Return the last thing on the list
	return indexes && indexes.length > 0
		? indexes[indexes.length - 1]
		: undefined;
};

/**
 * Get the selected id of the current data set category
 */
export const viewLibraryPublishingSelectedDataSetCategoryId = (
	state
): number => {
	//Get the selected data set category
	let index = viewLibraryPublishingSelectedDataSetCategoryIndex(state);

	//Return the id of the data set category
	return index ? index.id : -1;
};

/**
 * A selector which combines an aHub selector and the currently selected item too
 */
export const viewLibraryPublishingSelectedDataSetCategory = (
	state
): DataSetCategoryAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryDataSetCategoryList(state),
		viewLibraryPublishingSelectedDataSetCategoryId(state)
	);

/**
 * Get the products which relate to the currently selected data set category
 */
export const viewLibraryPublishingSelectedDataSetCategoryProducts = (
	state
): HashedMapStorage<ProductAHubVO> =>
	MapStorageUtil.mapStorageGet(
		aHubStateTemporaryDataSetCategoryProducts(state),
		viewLibraryPublishingSelectedDataSetCategoryId(state).toString()
	);

/**
 * Get the extract products list for the currently selected dataset
 */
export const viewLibraryPublishingSelectedDataSetCategoryProductsCount = (
	state
): number => {
	let mapData = viewLibraryPublishingSelectedDataSetCategoryProducts(state);

	//If we have map data then return
	return mapData ? mapData.itemCount : 0;
};

/**
 * Get the products which relate to the currently selected data set category
 */
export const viewLibraryPublishingSelectedDataSetCategoryProductIdSortOrder = (
	state
): number[] => {
	return MapStorageUtil.mapStorageGet(
		aHubStateTemporaryDataSetCategoryProductIdSorted(state),
		viewLibraryPublishingSelectedDataSetCategoryId(state).toString()
	);
};

/**
 * A selector to get the currently selected exporter id.
 */
export const viewLibraryPublishingSelectedExporterId = (state): number =>
	viewLibraryPublishingState(state).selectedExporterId;

/**
 * A selector to get the currently selected exporter delivery target id.
 */
export const viewLibraryPublishingSelectedExporterDestinationId = (
	state
): number => viewLibraryPublishingState(state).selectedExporterDestinationId;

/**
 * A selector to get the currently selected exporter id.
 */
export const viewLibraryPublishingSelectedExporterBuildHistoryId = (
	state
): number => viewLibraryPublishingState(state).selectedExporterBuildHistoryId;

/**
 * A selector to get the currently selected exporter allocs.
 */
export const viewLibraryPublishingSelectedExporterAllocs = (
	state
): PropertyAllocationObjectVO[] =>
	viewLibraryPublishingState(state).selectedExporterAllocs;

/**
 * A selector  which combines an aHub selector and the currently select item to
 */
export const viewLibraryPublishingSelectedExporterBuildHistoryIndexes = (
	state
): ExporterBuildHistoryIndexAHubVO[] =>
	MapStorageUtil.mapStorageGet(
		aHubStateTemporaryExporterBuildHistoryIndexMap(state),
		viewLibraryPublishingSelectedExporterId(state).toString()
	);

/**
 * A selector which will give us the currently selected item
 */
export const viewLibraryPublishingSelectedExporterBuildHistorySelected = (
	state
): ExporterBuildHistoryAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryExporterBuildHistorys(state),
		viewLibraryPublishingSelectedExporterBuildHistoryId(state)
	);

/**
 * A selector  which combines an aHub selector and the currently select item to
 */
export const viewLibraryPublishingSelectedExporter = (state): ExporterAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryExporterList(state),
		viewLibraryPublishingSelectedExporterId(state)
	);

/**
 * A selector to get the id of the selected resource pack.
 */
export const viewLibraryPublishingSelectedResourcePackId = (state): number =>
	viewLibraryPublishingState(state).selectedResourcePackId;

/**
 * A selector to get the selected resource pack.
 */
export const viewLibraryPublishingSelectedResourcePack = (
	state
): ResourcePackAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryResourcePackList(state),
		viewLibraryPublishingSelectedResourcePackId(state)
	);

/**
 * A selector to get the exporter blueprint mapping nodes.
 */
export const viewLibraryPublishingExporterBlueprintMappingNodes = (
	state
): BlueprintMappingNode[] =>
	viewLibraryPublishingState(state).selectedExporterBlueprintMappingNodes;

/**
 * A selector to get the selected exporter blueprint tree node.
 */
export const viewLibraryPublishingSelectedExporterBlueprintTreeNodePath = (
	state
): string =>
	viewLibraryPublishingState(state).selectedExporterBlueprintTreeNodePath;

/**
 * A selector to get the selected exporter selected class option id.
 */
export const viewLibraryPublishingSelectedExporterMappingClassId = (
	state
): number => viewLibraryPublishingState(state).selectedExporterMappingClassId;

/**
 * A selector to get the selected exporter class options.
 */
export const viewLibraryPublishingExporterMappingClassOptions = (
	state
): List<ExporterMappingClassOptionAHubVO> =>
	viewLibraryPublishingState(state).exporterMappingClassOptions;

/**
 * A selector to get the selected exporter selected class option.
 */
export const viewLibraryPublishingSelectedExporterMappingClassOption = (
	state
): ExporterMappingClassOptionAHubVO =>
	ListUtil.listDataItemGet(
		viewLibraryPublishingExporterMappingClassOptions(state),
		viewLibraryPublishingSelectedExporterMappingClassId(state)
	);

/**
 * A selector to exporter icon map
 */
export const viewExporterIconMap = (state): {} =>
	viewLibraryPublishingState(state).exporterIconMap;

/**
 * A selector to exporter delivery icon map
 */
export const viewExporterDestinationIconMap = (state): {} =>
	viewLibraryPublishingState(state).exporterDestinationIconMap;

/**
 * Dataset Category Ids ToBe Copied
 */
export const datasetCategoryIdsToBeCopied = (state): number[] =>
	viewLibraryPublishingState(state).datasetCategoryIdsToBeCopied;
