import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { PropertyRuleAHubVO } from "valueObjects/ahub/library/property-rule.ahub.vo";
import { Observable } from "rxjs";
import { PropertyTestAHubVO } from "app/valueObjects/ahub/library/property-test.ahub.vo";
import { ProductFilterRuleAHubVO } from "app/valueObjects/ahub/library/product-filter-rule.ahub.vo";
import { ProductFilterTestAHubVO } from "app/valueObjects/ahub/library/product-filter-test.ahub.vo";

@Component({
	selector: "app-product-filter-rule",
	templateUrl: "./product-filter-rule.component.html",
	styleUrls: ["./product-filter-rule.component.css"],
})
export class ProductFilterRuleComponent implements OnInit {
	@Input() productFilterRule: ProductFilterRuleAHubVO;
	@Input() propertyRuleIndex: number;
	@Input() allowPropertyTestsToBeAdded: boolean;
	@Input() busy$: Observable<boolean>;
	@Output() addPropertyTest: EventEmitter<number> = new EventEmitter<number>();
	@Output() editPropertyTest: EventEmitter<PropertyTestByRuleIndexVO> =
		new EventEmitter<PropertyTestByRuleIndexVO>();
	@Output() deletePropertyTest: EventEmitter<PropertyTestByRuleIndexVO> =
		new EventEmitter<PropertyTestByRuleIndexVO>();
	@Output() deletePropertyRule: EventEmitter<any> = new EventEmitter<any>();

	constructor() {
		// This is intentional
	}

	ngOnInit() {}

	addPropertyTestClickHandler() {
		this.addPropertyTest.emit(this.propertyRuleIndex);
	}

	propertyTestDelete(propertyTestIndex: number) {
		const propertyTestByRuleIndex: PropertyTestByRuleIndexVO = {
			propertyRuleIndex: this.propertyRuleIndex,
			propertyTestIndex: propertyTestIndex,
		};

		this.deletePropertyTest.emit(propertyTestByRuleIndex);
	}

	editPropertyTestHandler(
		propertyTestIndex: number,
		propertyTestToBeEdited: ProductFilterTestAHubVO
	) {
		const propertyTestByRuleIndex: PropertyTestByRuleIndexVO = {
			propertyRuleIndex: this.propertyRuleIndex,
			propertyTestIndex: propertyTestIndex,
			propertyTest: propertyTestToBeEdited,
		};

		this.editPropertyTest.emit(propertyTestByRuleIndex);
	}

	deletePropertyRuleClickHandler() {
		// Dispatch the delete all tests event.
		this.deletePropertyRule.emit(this.propertyRuleIndex);
	}
}

export interface PropertyTestByRuleIndexVO {
	propertyRuleIndex: number;
	propertyTestIndex: number;
	propertyTest?: ProductFilterTestAHubVO;
}
