import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ViewActions } from "actions/view.actions";
import { filter } from "rxjs/operators";
import { allRoutes } from "selector/view/view-routes.selector";
/**
 * Store
 */
import { StoreAccess } from "store/store-access";
/**
 * Value Objects
 */
import { RouteViewVO } from "valueObjects/view/route.view.vo";

@Injectable()
/**
 * Handels Route events
 */
export class RouterService {
	flattenedRoutes: RouteViewVO[];

	constructor(private router: Router, private route: ActivatedRoute) {}

	/**
	 * Start watching the router for activity
	 */
	routeWatcherStart() {
		//Subscribe to the router events. This is a top level service singleton
		//which lasts the lenght of the application. So no need to unsubscribe
		this.router.events
			.pipe(filter((event) => event !== undefined))
			.subscribe((event) => {
				//Is this a navigation event event then call the appropriate handlers
				if (event instanceof NavigationEnd) {
					//Call the route update
					this.routeViewUpdate(event);
				}
			});
	}

	/**
	 * Updates the view section of the store with the currently navigated sections
	 */
	private routeViewUpdate(event: NavigationEnd): void {
		const allNavigableRoutes: RouteViewVO[] = StoreAccess.dataGet(allRoutes);

		// Lets only flatten the route views once ;)
		if (!this.flattenedRoutes) {
			this.flattenedRoutes = RouterService.flatten(allNavigableRoutes);
		}

		const selectedRouteViewVO: RouteViewVO =
			this.findMostSimilarRouteViewToNavigatedRoute(
				this.flattenedRoutes,
				event.urlAfterRedirects
			);

		if (selectedRouteViewVO) {
			StoreAccess.dispatch(
				ViewActions.selectedRouteViewSet(selectedRouteViewVO)
			);
		}
	}

	findMostSimilarRouteViewToNavigatedRoute(
		flattenedRoutes: RouteViewVO[],
		navigatedUrl: string
	): RouteViewVO {
		let matchingRoute: RouteViewVO;

		const navigatedUrlAsPathElements = navigatedUrl
			.split("/")
			.filter((element) => element !== undefined && element !== "");

		navigatedUrlAsPathElements.forEach((element) => {
			flattenedRoutes = flattenedRoutes.filter(
				(route) => route.routerLink.indexOf(element) !== -1
			);

			// For now lets just pop the first match into the matchingRoute
			if (flattenedRoutes && flattenedRoutes.length > 0) {
				matchingRoute = flattenedRoutes[0];
			}
		});

		return matchingRoute;
	}

	private static flatten(arrayToFlatten: RouteViewVO[]): RouteViewVO[] {
		let result = [];
		arrayToFlatten.forEach(function (a) {
			result.push(a);
			if (Array.isArray(a.routes)) {
				result = result.concat(RouterService.flatten(a.routes));
			}
		});
		return result;
	}
}
