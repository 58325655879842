import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { Hark, componentDestroyStream } from "modules/common/hark.decorator";
import { Observable } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

/**
 * Services
 */
import { DialogService } from "modules/common/dialogs/dialog.service";

/**
 * Utilities.
 */
import { DistributionUtils } from "modules/common/distribution-utils";

/**
 * Value objects.
 */
import { ExportDistributionAHubVO } from "valueObjects/ahub/accounts/export-distribution.ahub.vo";

@Component({
	selector: "app-user-export-distribution-aw-legacy",
	templateUrl: "./user-export-distribution-aw-legacy.component.html",
	styleUrls: ["./user-export-distribution-aw-legacy.component.css"],
})
@Hark()
export class UserExportDistributionAwLegacyComponent implements OnInit {
	/**
	 * The export distribution that has been selected to view.
	 */
	@Input()
	exportDistribution$: Observable<ExportDistributionAHubVO>;

	/**
	 * Distribution form
	 */
	public distributionForm: UntypedFormGroup = this.formBuilder.group({});

	/**
	 * The all of the order submission types.
	 */
	orderSubmissionTypes: Object[] = [
		{ value: "", name: "None" },
		{ value: "xml", name: "Legacy XML API" },
		{ value: "xmlBody", name: "XML Body" },
		{ value: "jsonBody", name: "JSON Body" },
	];

	constructor(
		private formBuilder: UntypedFormBuilder,
		private dialogService: DialogService
	) {}

	ngOnInit() {
		// Set up the distribution form.
		DistributionUtils.awExportTypeParametersAddToForm(this.distributionForm);

		// Watch the export distribution that's been passed in.
		this.exportDistribution$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				filter((exportDistribution) => exportDistribution != undefined)
			)
			.subscribe((exportDistribution) => {
				// Do we have distribution parameters? If so, update the form.
				if (exportDistribution.distributionParams)
					DistributionUtils.setAWDistributionParamsToForm(
						exportDistribution.distributionParams,
						this.distributionForm
					);

				// Disable the form.
				this.distributionForm.disable();
			});
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * The function called when the user clicks on the info icon.
	 */
	openInfo(event) {
		// Display the info.
		this.dialogService.infoDialogOpen(
			"Caution Unmergable Property",
			"assets/info/distributionUrlInfo.html"
		);
	}
}
