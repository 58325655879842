import { Injectable } from "@angular/core";
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
	ActivatedRoute,
} from "@angular/router";
import { Observable } from "rxjs";
import { AppActions } from "actions/app.actions";
import { AHubActions } from "actions/ahub.actions";

/**
 * Store access
 */
import { StoreAccess } from "store/store-access";
import {
	loginToken,
	externalAppToken,
	sessionUserSessionCredentials,
} from "selector/app.selector";

import { AppRoutingNavigation } from "app/app-routing-navigation";
import { UserSessionCredentials } from "@harksolutions/ahub-web-services-types";

@Injectable()
export class LoginTokenManagerGuard implements CanActivate {
	constructor(
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		// QUERY PARAMS NOT MATRIX PARAMS! This is the type of params sent from external apps (eg aWorkbook)
		const ahtoken = route.queryParams["ahtoken"];
		let identityProviderToken = route.queryParams["id_token"];

		// Microsoft have moved to fragments .. so we need to watch out for these too.
		const fragmentProps = new Object();

		if (
			route.fragment !== undefined &&
			route.fragment !== null &&
			route.fragment !== ""
		) {
			// Split the fragment string up by & as it should be in the format key1=value1&key2=value2
			const fragments = route.fragment.split("&");

			// For each partial fragment we will extract the key value properties.
			fragments.forEach((element) => {
				// Split each mini fragment by the equals, key we have 2 bits Key and Value, then store them.
				const keyValuePair = element.split("=");

				if ((keyValuePair.length = 2)) {
					fragmentProps[keyValuePair[0]] = keyValuePair[1];
				}
			});
		}

		// Has the id_token returned from authentication, been returned in the fragment ?
		// If so use it.
		if (fragmentProps.hasOwnProperty("id_token")) {
			identityProviderToken = fragmentProps["id_token"];
		}

		if (ahtoken !== undefined && ahtoken !== "") {
			// AWORKBOOK LOGIN!
			StoreAccess.dispatch(AppActions.externalAppTokenSet(ahtoken));
		} else if (identityProviderToken) {
			// RETURNING FROM 3RD PARTY LOGIN (MICROSOFT)
			AppRoutingNavigation.navigateAuthToken(
				this.router,
				identityProviderToken
			);
			return false;
		}

		//Get the 3rd party email from the parameters
		const emailFrom3rdPartyParam = route.queryParams["email"];

		// Get the user credentials, client id and user id from the local storage.
		const userCredentials: UserSessionCredentials = StoreAccess.dataGet(
			sessionUserSessionCredentials
		);

		//Are we missing user credentials?
		if (!userCredentials) {
			//No credentials then we will need to go and login!
			AppRoutingNavigation.navigateLoginSelection(
				this.router,
				emailFrom3rdPartyParam
			);
			return false;
		} else if (
			!emailFrom3rdPartyParam ||
			userCredentials.userEmail.toLowerCase() ===
				emailFrom3rdPartyParam.toLowerCase()
		) {
			//We have user credentials but not a 3rd party email address or we have user credentials
			//and the email supplied matches our logged in user then we can attempt a login complete
			AppRoutingNavigation.navigateLoginComplete(this.router);
			return false;
		}

		//Finally if we get here we must log out as we have no other viable option!
		AppRoutingNavigation.navigateLogout(this.router);
		return false;
	}
}
