import { combineLatest, map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";

/**
 * Store
 */
import { StoreAccess } from "store/store-access";
import { sessionClientId } from "selector/app.selector";
import { aHubStatePermanentUserClientIndexs } from "selector/ahub/ahub-permanent.selector";

/**
 * Value Objects
 */
import { ClientIndexAHubVO } from "valueObjects/ahub/accounts/client-index.ahub.vo";

export class ClientIndexStream {
	/**
	 * Create a observable watching the current client id.
	 */
	currentClientId$: Observable<number> =
		StoreAccess.dataGetObvs(sessionClientId);

	/**
	 * Create an observable to watch all of the client indexes.
	 */

	clientIndexs$: Observable<ClientIndexAHubVO[]> = StoreAccess.dataGetObvs(
		aHubStatePermanentUserClientIndexs
	);
	/**
	 * This is the current client object.
	 */
	getClientIndexByCurrentClientId(): Observable<ClientIndexAHubVO> {
		return this.clientIndexs$.pipe(
			combineLatest(
				this.currentClientId$.pipe(startWith(-1)),
				(clientIndexList, currentClientId) => ({
					clientIndexList,
					currentClientId,
				})
			),
			map(({ clientIndexList, currentClientId }) =>
				ClientIndexStream.clientIndexFind(clientIndexList, currentClientId)
			)
		);
	}
	/**
	 * This is the client index for supplied id.
	 */
	getClientIndexById(id): Observable<ClientIndexAHubVO> {
		return this.clientIndexs$.pipe(
			map((clientIndexList) =>
				ClientIndexStream.clientIndexFind(clientIndexList, id)
			)
		);
	}

	/**
	 * Find a client index from a list.
	 */
	static clientIndexFind(
		clientIndexList: ClientIndexAHubVO[],
		clientId: number
	): ClientIndexAHubVO {
		// Basically find the first instance and return it.
		return clientIndexList.find(
			(clientIndex: ClientIndexAHubVO) => clientIndex.id === clientId
		);
	}
}
