import {
	ErrorDialogParameters,
	ErrorDialogComponent,
} from "./dialogs/error-dialog/error-dialog.component";
import { ProductAssetSectionPropertyValues } from "app/valueObjects/ahub/library/product-asset.ahub.vo";
import { ExtractDefinitionPropertyAllocationObjectVO } from "app/valueObjects/view/extract-definition-property-allocation.view.vo";
import {
	ProductAssetViewParamsVO,
	ProductAssetViewParamsMenuButtonsVO,
} from "./components/product-asset-view/product-asset-view.component";
import { BehaviorSubject } from "rxjs";
import { ProductAssetViewVO } from "app/valueObjects/view/product-asset-view.vo";
import { RequestActionMonitorService } from "app/services/request-action-monitor/request-action-monitor.service";
import { StoreAccess } from "app/store/store-access";
import { viewPropertyIconMap } from "app/store/selector/view/view-library-classification-class.selector";
import { AHubActions } from "app/store/actions/ahub.actions";
import { ViewActions } from "app/store/actions/view.actions";
import {
	viewLibraryProductAssetSwapSource,
	viewLibrarySelectedExtractId,
} from "app/store/selector/view/view-library-extracts.selector";
import { AppActions } from "app/store/actions/app.actions";
import { RequestActionStatusUploadDataVO } from "app/valueObjects/app/request-action-status-upload-object.vo";
import { ObjectStoreService } from "app/services/object-store/object-store.service";
import { map, filter } from "rxjs/operators";
import { ProductAHubVO } from "app/valueObjects/ahub/library/product.ahub.vo";
import { ProductAssetViewDialogFlickbookComponentParamsChapter } from "./dialogs/product-asset-view-dialog/product-asset-view-dialog-flickbook/product-asset-view-dialog-flickbook.component";
import { NotificationGeneratorService } from "app/services/notification-generator/notification-generator.service";

export class AssetUtils {
	// Given that a PUT HTTP request using the presigned URL is a 'single'-part upload, the object size is limited to 5GB
	public static readonly FIVE_GIGS_IN_BYTES: number = 5368709120;
	public static readonly TEN_GIGS_IN_BYTES: number = 10737418240;

	/**
	 * Background images for the assets
	 */
	private static readonly ASSET_BACKGROUND: string =
		"/assets/images/transparent-background.png";
	private static readonly ASSET_BACKGROUND_DND: string =
		"/assets/images/transparent-background-dragdropupload.png";

	/**
	 * This function will merge an array of arrays and remove all duplicates.
	 *
	 * @param arrays            The arrays to merge.
	 */
	public static mergeNoDuplicates(arrays: any[][]): any[] {
		// Create a set to add the values too.
		const set = new Set();

		// Now add each part of the each arrays.
		arrays.forEach((array) => array.forEach((item) => set.add(item)));

		// Now return the array.
		return Array.from(set);
	}

	public static fileIsLargerThan(maximumFileSize: number, file: File): File {
		return file.size > maximumFileSize ? file : undefined;
	}

	public static openFileTooLargeUploadErrorDialog(
		file,
		resolver,
		dialogService
	) {
		//Create the error parameters object
		const errObject: ErrorDialogParameters = {
			message: `${file.name} ( ${this.formatBytes(
				file.size
			)} ) is too big ( >= 5 GB)`,
			hiddenMessage: ["Please amend and try again"],
		};

		//Open the dialogue with the parameters object
		dialogService
			.componentDialogOpen(
				"Upload Error",
				resolver.resolveComponentFactory(ErrorDialogComponent),
				"dialogParams",
				errObject,
				undefined,
				"Ok"
			)
			.subscribe((refresh) => {});
	}

	public static formatBytes(bytes, decimals = 2) {
		if (bytes === 0) {
			return "0 Bytes";
		}

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	}

	/**
	 * Single function to generate renderer for simple single file asset types e.g. images / video's / pdf's
	 * This method may be called to generate a renderer for either extract OR dataset product assets (different download call for each)
	 * @param extractId
	 * @param dataSetId
	 * @param productId
	 * @param extractAlloc
	 * @param assetData
	 * @param assetTypeName
	 * @param assetPreviewImgUrls
	 * @param uploadSingleFileClickHandlerFunc
	 * @param uploadFolderClickHandlerFunc
	 * @param fullScreenAssetClickHandlerFunc
	 * @param editable$
	 * @param requestActionMonitor
	 * @param objectStoreService
	 * @param assetsMap
	 * @param assets
	 * @param hideAssetDetails
	 * @param downloadResizedImageAssetClickHandlerFunc
	 */
	public static assetRendererSetup(
		extractId,
		dataSetId,
		productId,
		extractAlloc: ExtractDefinitionPropertyAllocationObjectVO,
		assetData: ProductAssetSectionPropertyValues,
		assetTypeName: string,
		assetPreviewImgUrls: string[],
		uploadSingleFileClickHandlerFunc: (
			params: ProductAssetViewParamsVO
		) => void,
		dragAndDropHandlerFunc: (event, params: ProductAssetViewParamsVO) => void,
		uploadFolderClickHandlerFunc: (params: ProductAssetViewParamsVO) => void,
		fullScreenAssetClickHandlerFunc: (params: ProductAssetViewParamsVO) => void,
		editable$: BehaviorSubject<boolean>,
		requestActionMonitor: RequestActionMonitorService,
		notificationGenerator: NotificationGeneratorService,
		objectStoreService: ObjectStoreService,
		assetsMap: BehaviorSubject<Map<string, ProductAssetViewParamsVO>>,
		assets: BehaviorSubject<ProductAssetViewParamsVO[]>,
		hideAssetDetails = false,
		downloadResizedImageAssetClickHandlerFunc?: (
			params: ProductAssetViewParamsVO
		) => void,
		glbURL?
	): ProductAssetViewParamsVO {
		//Buttons that we will want as options for the renderer
		let buttons: ProductAssetViewParamsMenuButtonsVO[] = [];

		//Function which decides if we can edit the data?
		const editButtonVisibility = (params: ProductAssetViewParamsVO) =>
			editable$.getValue() && !extractAlloc.readOnly;

		//Function which decides if we have any existing data
		const hasDataButtonVisibility = (params: ProductAssetViewParamsVO) => {
			return (
				(params.data as ProductAssetViewVO).assetId !== undefined &&
				(params.data as ProductAssetViewVO).assetId > 0
			);
		};

		//Add in the unlink button
		buttons.push({
			buttonName: "Unlink",
			buttonIcon: "link_off",
			buttonTooltip: "Unlink asset from product",
			buttonCheckVisible: (params) =>
				editButtonVisibility(params) && hasDataButtonVisibility(params),
			buttonClickFunc: (params) =>
				extractId
					? this.assetRendererUnlink(
							params,
							extractId,
							productId,
							extractAlloc,
							requestActionMonitor
					  )
					: undefined,
		});

		//Swap assets
		buttons.push({
			buttonName: "Swap",
			buttonIcon: "shuffle",
			buttonTooltip: "Swap asset to another asset slot",
			buttonCheckVisible: (params) =>
				editButtonVisibility(params) && hasDataButtonVisibility(params),
			buttonClickFunc: (params) => this.startAssetSwapping(params, assets),
		});

		const downloadButtons = this.buildDownloadButtonsByAssetType(
			assetData,
			assetTypeName,
			dataSetId,
			extractId,
			requestActionMonitor,
			hasDataButtonVisibility,
			downloadResizedImageAssetClickHandlerFunc
		);

		buttons = buttons.concat(downloadButtons);

		//We always have the file upload avalible
		buttons.push({
			buttonName: "Upload",
			buttonIcon: "cloud_upload",
			buttonTooltip: "Upload Asset",
			buttonCheckVisible: editButtonVisibility,
			buttonClickFunc: uploadSingleFileClickHandlerFunc,
		});

		//Do we have an upload a folder function
		if (uploadFolderClickHandlerFunc) {
			//We always have the folder upload avalible
			buttons.push({
				buttonName: "Upload Folder",
				buttonIcon: "cloud_upload",
				buttonTooltip: "Upload Asset Folder",
				buttonCheckVisible: editButtonVisibility,
				buttonClickFunc: (params) => uploadFolderClickHandlerFunc(params),
			});
		}

		//We will create an object which store the
		const assetRendererDataObject: ProductAssetViewVO = {
			productId,
			allocationId: extractAlloc.id,
			sectionId: extractAlloc.section.id,
			propertyId: extractAlloc.property.id,
			type: extractAlloc.property.typeReference,
			assetId: assetData ? assetData.assetId : -1,
			readOnly: extractAlloc.readOnly,
		};

		/**
		 * Icons for the asset types
		 */
		const propertyIconMap = StoreAccess.dataGet(viewPropertyIconMap);

		let rightIcon = extractAlloc.readOnly ? "lock" : undefined;
		let rightIconTooltip = extractAlloc.readOnly
			? "Asset is read only"
			: undefined;
		let rightIconClickFunc = undefined;

		if (
			extractAlloc.property.typeReference !== "BLOB" &&
			assetRendererDataObject.assetId > 0
		) {
			rightIcon = !rightIcon ? "fullscreen" : rightIcon;
			rightIconTooltip = !rightIconTooltip
				? "Click to go view fullscreen"
				: rightIconTooltip;
			rightIconClickFunc = (params) => fullScreenAssetClickHandlerFunc(params);
		}

		return {
			backgroundImageGetRelativePathFunc: (data) =>
				hasDataButtonVisibility(data)
					? this.ASSET_BACKGROUND
					: this.ASSET_BACKGROUND_DND,
			data: assetRendererDataObject,
			leftIcon: propertyIconMap[extractAlloc.property.typeReference],
			leftIconTooltip: assetTypeName,
			leftIconClickFunc: (params) => fullScreenAssetClickHandlerFunc(params),
			rightIcon,
			rightIconTooltip,
			rightIconClickFunc,
			assetImagePreviewURL: assetPreviewImgUrls,
			sectionName: extractAlloc.section.label,
			sectionColour: extractAlloc.section.colour,
			propertyName: extractAlloc.property.label,
			componentClickHandler: (params) =>
				fullScreenAssetClickHandlerFunc(params),
			dragdropHandler: dragAndDropHandlerFunc
				? dragAndDropHandlerFunc
				: (event, rendererConfig) => {
						//Only do anything if we are not a read only property
						if (!assetRendererDataObject.readOnly) {
							const uploadActionId = this.assetRendererDragDropHandler(
								productId,
								extractAlloc.id,
								event,
								notificationGenerator,
								objectStoreService
							);

							//Call the renderer to update itself ( weird but prevents scope issues ) based on the action id
							this.fileUploadRendererUpdate(
								rendererConfig,
								uploadActionId,
								requestActionMonitor
							);
						}
				  },
			busyIndicatorState: "none",
			menuButtonCheckVisible: (data) =>
				editButtonVisibility(data) || downloadButtons.length > 0,
			menuButtonDisplay:
				assetRendererDataObject.readOnly && assetRendererDataObject.assetId < 1
					? "none"
					: "menu",
			menuButtons: buttons,
			menuButtonClickButtonModeFunc: (params) =>
				this.assetRendererSwapAssets(
					params,
					requestActionMonitor,
					assets,
					assetsMap
				),
			hideAssetDetails,
			glbURL: glbURL,
		};
	}

	/**
	 * Lets create some 'buttons' for this asset that will allow for downloading whichever type the asset is
	 * @param assetData
	 * @param assetTypeName
	 * @param dataSetId
	 * @param extractId
	 * @param requestActionMonitor
	 * @param hasDataButtonVisibility
	 */
	static buildDownloadButtonsByAssetType(
		assetData: ProductAssetSectionPropertyValues,
		assetTypeName: string,
		dataSetId: any,
		extractId: any,
		requestActionMonitor: RequestActionMonitorService,
		hasDataButtonVisibility: (params: ProductAssetViewParamsVO) => boolean,
		downloadResizedImageAssetClickHandlerFunc?: (
			params: ProductAssetViewParamsVO
		) => void
	): ProductAssetViewParamsMenuButtonsVO[] {
		if (!assetData) {
			return [];
		}

		const downloadButtons: ProductAssetViewParamsMenuButtonsVO[] = [];

		// Lets make a convenient map to help with providing asset sizes for image assets
		const assetInfoMap: Map<string, string> =
			this.buildAssetInfoMapFromAssetData(assetData);

		switch (assetTypeName) {
			case "Image Asset":
				//Add in the download specified sized image function
				downloadButtons.push({
					buttonName: this.buildButtonNameToIncludeAssetDimensions(
						"Asset",
						assetInfoMap
					),
					buttonIcon: "file_download",
					buttonTooltip: "Download Image to specified dimensions",
					buttonCheckVisible: hasDataButtonVisibility,
					buttonClickFunc: (params) =>
						downloadResizedImageAssetClickHandlerFunc(params),
				});
				break;

			case "Flickbook Asset":
				{
					//Add in the download original asset function
					downloadButtons.push({
						buttonName: this.buildButtonNameToIncludeAssetDimensions(
							"Flickbook Original",
							assetInfoMap
						),
						buttonIcon: "file_download",
						buttonTooltip: "Download originally uploaded Asset",
						buttonCheckVisible: hasDataButtonVisibility,
						buttonClickFunc: (params) =>
							this.rendererFileDownloadHandler(
								params,
								dataSetId,
								extractId,
								assetData.sourceAssetUrls,
								"source",
								requestActionMonitor
							),
					});

					//Add in the download resized asset function (only applies to image type assets)
					downloadButtons.push({
						buttonName: this.buildButtonNameToIncludeAssetDimensions(
							"Flickbook Processed",
							assetInfoMap
						),
						buttonIcon: "file_download",
						buttonTooltip:
							"Download 'processed' Asset (includes: Original, Reszied and Thumbnail images)",
						buttonCheckVisible: hasDataButtonVisibility,
						buttonClickFunc: (params) =>
							this.rendererFileDownloadHandler(
								params,
								dataSetId,
								extractId,
								assetData.assetUrls,
								"processed",
								requestActionMonitor
							),
					});
				}

				break;
			case "Video Asset": {
				//Add in the download SD video asset function
				const sdVideoAssetUrl = assetData.assetUrls.find((url) =>
					url.includes("sd")
				);
				downloadButtons.push({
					buttonName: this.buildButtonNameToIncludeAssetDimensions(
						"SD",
						assetInfoMap
					),
					buttonIcon: "file_download",
					buttonTooltip: "Download SD Video",
					buttonCheckVisible: hasDataButtonVisibility,
					buttonClickFunc: (params) =>
						this.downloadAssetFromURL(sdVideoAssetUrl),
				});

				//Add in the download HD video asset function
				const hdVideoAssetUrl = assetData.assetUrls.find((url) =>
					url.includes("hd")
				);
				downloadButtons.push({
					buttonName: this.buildButtonNameToIncludeAssetDimensions(
						"HD",
						assetInfoMap
					),
					buttonIcon: "file_download",
					buttonTooltip: "Download HD Video",
					buttonCheckVisible: hasDataButtonVisibility,
					buttonClickFunc: (params) =>
						this.downloadAssetFromURL(hdVideoAssetUrl),
				});
				break;
			}

			case "GLB Asset": {
				//GLB formats which
				const glbFormats = [
					{ type: "p256", name: "Small GLB" },
					{ type: "p1024", name: "Medium GLB" },
					{ type: "p2048", name: "Large GLB" },
				];

				//Generate download buttons for each GLB format supplied
				const glbDownloadButtons = glbFormats
					.map((glbFormat) => {
						//Get the name of the file
						const glbAssetURL = assetData.assetUrls.find((fName) =>
							fName.includes(`${glbFormat.type}/`)
						);

						if (!glbAssetURL) {
							return undefined;
						}

						// Get the asset info for this type.
						const assetInfo = assetInfoMap.get(glbFormat.type.toUpperCase());

						let assetInfoObject;

						try {
							// Convert the asset info into an object.
							assetInfoObject = JSON.parse(assetInfo);
						} catch (error) {
							console.log("Error getting asset info for GLB asset: ", error);
						}

						// Now get the size of the asset.
						const assetSize: number = assetInfoObject
							? assetInfoObject.fileSizeBytes
							: -1;

						// Finally construct the button name.
						let buttonName = `Download ${glbFormat.name}`;

						// Then include the asset size if we have one.
						if (assetSize > -1) {
							buttonName += ` (${this.formatBytes(assetSize)})`;
						}

						//Return the newley created button
						return {
							buttonIcon: "file_download",
							buttonName,
							buttonTooltip: `Download ${glbFormat.name} asset`,
							buttonCheckVisible: hasDataButtonVisibility,
							buttonClickFunc: (params) =>
								this.downloadAssetFromURL(glbAssetURL),
						};
					})
					.filter((button) => button !== undefined);

				//Add all our download buttons
				downloadButtons.push(...glbDownloadButtons);

				// Set up the source button name.
				let sourceButtonName = "Download Original";

				// Get the source file size if we have one?
				const sourceFileSize =
					assetData && assetData.assetInfo
						? assetData.assetInfo.sourceSizeBytes
						: 0;

				// If we have a file size then add it to the button name,
				if (sourceFileSize > -1) {
					sourceButtonName += ` (${this.formatBytes(sourceFileSize)})`;
				}

				downloadButtons.push({
					buttonIcon: "file_download",
					buttonName: sourceButtonName,
					buttonTooltip: "Download the original GLB asset",
					buttonCheckVisible: hasDataButtonVisibility,
					buttonClickFunc: (params) =>
						this.downloadAssetFromURL(assetData.sourceAssetUrls[0]),
				});

				break;
			}

			default:
				//Add in the download original asset function
				downloadButtons.push({
					buttonName: `Download ${assetTypeName}`,
					buttonIcon: "file_download",
					buttonTooltip: `Download ${assetTypeName}`,
					buttonCheckVisible: hasDataButtonVisibility,
					buttonClickFunc: (params) =>
						this.rendererFileDownloadHandler(
							params,
							dataSetId,
							extractId,
							assetData.sourceAssetUrls,
							"source",
							requestActionMonitor
						),
				});

				break;
		}

		return downloadButtons;
	}

	/**
	 * Builds useful button names containing more information about what will be downloaded if it were clicked
	 * @param assetSizeType
	 * @param assetInfoMap
	 */
	static buildButtonNameToIncludeAssetDimensions(
		assetSizeType: string,
		assetInfoMap: Map<string, string>
	): string {
		let buttonName = `Download ${assetSizeType}`;

		switch (assetSizeType) {
			case "Original":
				{
					const assetWidth = assetInfoMap.get("standardWidthPx")
						? assetInfoMap.get("standardWidthPx")
						: assetInfoMap.get("sourceWidthPx");
					const assetHeight = assetInfoMap.get("standardHeightPx")
						? assetInfoMap.get("standardHeightPx")
						: assetInfoMap.get("sourceHeightPx");
					if (assetWidth && assetHeight) {
						buttonName += ` (${assetWidth} x ${assetHeight})`;
					}
				}
				break;
			case "Resized":
				{
					const assetWidth = assetInfoMap.get("processedWidthPx");
					const assetHeight = assetInfoMap.get("processedHeightPx");
					if (assetWidth && assetHeight) {
						buttonName += ` (${assetWidth} x ${assetHeight})`;
					}
				}
				break;
			case "SD":
				{
					const fileSize = assetInfoMap.get("SDFileSizeBytes");
					if (fileSize) {
						buttonName += ` (${this.formatBytes(fileSize)})`;
					}
				}
				break;
			case "HD":
				{
					const fileSize = assetInfoMap.get("HDFileSizeBytes");
					if (fileSize) {
						buttonName += ` (${this.formatBytes(fileSize)})`;
					}
				}

				break;

			default:
				break;
		}

		return buttonName;
	}

	/**
	 * Handel the unlinking of assets
	 */
	private static assetRendererUnlink(
		parameters: ProductAssetViewParamsVO,
		extractId: number,
		productId: number,
		extractAlloc: ExtractDefinitionPropertyAllocationObjectVO,
		requestActionMonitor: RequestActionMonitorService
	) {
		//Submit the action
		const actionId = this.unlinkAssetFromProduct(
			extractId,
			productId,
			extractAlloc.section.id,
			extractAlloc.property.id
		);

		//Is the action id greater than
		if (actionId > 0) {
			//Reset the error so we are not displaying one
			AssetUtils.rendererSetError(parameters, undefined);

			//Set the renderer icon and state
			parameters.menuButtonDisplay = "none";
			parameters.busyIndicatorIcon = "link_off";
			parameters.busyIndicatorState = "indeterminate";

			//Watch the action monitor status
			requestActionMonitor
				.worklogByActionId(actionId, true)
				.onlyComplete()
				.observable()
				.pipe
				//   takeUntil(componentDestroyStream(this)))
				()
				.subscribe(
					(worklog) => {
						//Reset the state!
						AssetUtils.rendererBaseStateReset(parameters);

						//Do we have a fault
						if (worklog.fault) {
							AssetUtils.rendererSetError(parameters, worklog.exception);
						}
					},
					(error: Error) => {
						//Reset the renderer
						AssetUtils.rendererBaseStateReset(parameters);

						//Set the error
						AssetUtils.rendererSetError(parameters, error.message);
					}
				);
		}
	}

	/**
	 * Generic function to unlink the given data from this product specified
	 */
	private static unlinkAssetFromProduct(
		extractId: number,
		productId: number,
		sectionId: number,
		propertyId: number
	): number {
		//Not enough info for the asset then bail out!
		if (productId < 1 || sectionId < 1 || propertyId < 1) {
			return -1;
		}

		//Call the function to unlink the asset from this product
		return StoreAccess.dispatch(
			AHubActions.extractProductSectionPropertyAssetDelete(
				extractId,
				productId,
				sectionId,
				propertyId
			)
		);
	}

	/**
	 * Set the error renderer state
	 */
	public static rendererSetError(
		parameters: ProductAssetViewParamsVO,
		error: string
	) {
		//Do we have an error message, then we will dispay it!
		if (error) {
			//Set the symbol and warning
			parameters.rightIcon = "warning";
			parameters.rightIconColour = "crimson";
			parameters.rightIconTooltip = error;
		} else {
			//No error loose all the data
			parameters.rightIcon = undefined;
			parameters.rightIconColour = undefined;
			parameters.rightIconTooltip = undefined;
		}
	}

	/**
	 * Reset the renderers base state
	 */
	public static rendererBaseStateReset(parameters: ProductAssetViewParamsVO) {
		//Get the asset data
		const assetData: ProductAssetViewVO = parameters.data as ProductAssetViewVO;

		//Set the base state back to not busy with a menu!
		parameters.busyIndicatorIcon = undefined;
		parameters.busyIndicatorState = "none";
		parameters.menuButtonDisplay =
			assetData.readOnly && assetData.assetId < 1 ? "none" : "menu";
	}

	/**
	 * Begin the swapping of assets
	 */
	private static startAssetSwapping(
		renderer: ProductAssetViewParamsVO,
		assets: BehaviorSubject<ProductAssetViewParamsVO[]>
	) {
		//Dispatch an action to start the asset swap
		StoreAccess.dispatch(
			ViewActions.libraryAssetsSwapSourceSet(
				renderer.data as ProductAssetViewVO
			)
		);

		//Call the renderer update this should keep all the swap views consistant
		this.rendererAssetSwapUpdate(assets);
	}

	/**
	 * Update the renderers to swap the
	 */
	public static rendererAssetSwapUpdate(
		assets: BehaviorSubject<ProductAssetViewParamsVO[]>
	) {
		const currentAssets: ProductAssetViewParamsVO[] = assets.getValue();

		//Are we currently swapping assets?
		const swapData = StoreAccess.dataGet(viewLibraryProductAssetSwapSource);

		//No swap data then bail out
		if (!swapData) {
			return;
		}

		//Loop throught all our assets
		currentAssets.forEach((existingRenderer) => {
			//Get the data from the existing renderer
			const existingRendererData = existingRenderer.data as ProductAssetViewVO;

			//If this renderer is the correct type and not the same one selected for swapping then we will
			//allow it for selection
			if (
				swapData.type === existingRendererData.type &&
				(swapData.productId !== existingRendererData.productId ||
					swapData.allocationId !== existingRendererData.allocationId) &&
				!existingRendererData.readOnly
			) {
				//Set the swap icon
				existingRenderer.menuButtonDisplay = "button";
				existingRenderer.menuButtonIcon = "shuffle";
			} else {
				existingRenderer.menuButtonDisplay = "none";
			}
		});
	}

	//File download handler, for the download buttons
	private static rendererFileDownloadHandler(
		renderer: ProductAssetViewParamsVO,
		dataSetId: number,
		extractId: number,
		assetUrls: string[],
		assetType: string,
		requestActionMonitor: RequestActionMonitorService
	) {
		//No URL's then bail out
		if (!assetUrls || assetUrls.length === 0) {
			return;
		}

		//Is this a single file?
		if (assetUrls.length === 1) {
			//Download the single asset
			this.downloadAssetFromURL(assetUrls[0]);
		} else {
			//Dispatch an action to generate a zip

			// Do we have a dataset id?
			let actionId;
			if (dataSetId) {
				actionId = StoreAccess.dispatch(
					AHubActions.dataSetProductAssetZipGenerate(
						dataSetId,
						(renderer.data as ProductAssetViewVO).assetId,
						assetType
					)
				);
			} else if (extractId) {
				actionId = StoreAccess.dispatch(
					AHubActions.extractProductAssetZipGenerate(
						extractId,
						(renderer.data as ProductAssetViewVO).assetId,
						assetType
					)
				);
			}

			//Hide the renderer menu button
			renderer.menuButtonDisplay = "none";
			renderer.busyIndicatorState = "indeterminate";
			renderer.busyIndicatorIcon = "file_download";

			//Watch the action monitor status
			requestActionMonitor
				.worklogByActionId(actionId, true)
				.onlyComplete()
				.observable()
				.pipe
				// takeUntil(componentDestroyStream(this))
				()
				.subscribe(
					(worklog) => {
						//Reset the state!
						this.rendererBaseStateReset(renderer);

						//Do we have a fault
						if (worklog.fault) {
							this.rendererSetError(renderer, worklog.exception);
						}
					},
					(error: Error) => {
						//Reset the renderer
						this.rendererBaseStateReset(renderer);

						//Set the error
						this.rendererSetError(renderer, error.message);
					}
				);
		}
	}

	/**
	 * Handel the drag and drop event for the item renderers this is only setup to recieve a single file
	 */
	public static assetRendererDragDropHandler(
		productId,
		allocationId,
		event,
		notificationGenerator: NotificationGeneratorService,
		objectStoreService: ObjectStoreService,
		trimThreshold?: number
	): number {
		//Files from the drag and drop
		const files: File[] = Array.from(event.files);

		//Too many files
		if (files.length > 1) {
			return;
		}

		//Handel the upload action
		return this.uploadSingleAsset(
			productId,
			allocationId,
			files[0],
			notificationGenerator,
			objectStoreService,
			trimThreshold
		);
	}

	/**
	 * Update the renderer based on the upload progress of the action id we supplied
	 */
	public static fileUploadRendererUpdate(
		rendererConfig: ProductAssetViewParamsVO,
		actionId: number,
		requestActionMonitor: RequestActionMonitorService
	) {
		//No action id we will do nothing!
		if (actionId < 1) {
			return;
		}

		//Reset the error message on the renderer
		this.rendererSetError(rendererConfig, undefined);

		//Setup the renderer with the state for the upload!
		rendererConfig.busyIndicatorState = "determinate";
		rendererConfig.busyIndicatorIcon = "cloud_upload";
		rendererConfig.menuButtonDisplay = "none";
		rendererConfig.busyIndicatorProgress = requestActionMonitor
			.requestActionStatusUploadObservableByAction(actionId)
			.pipe(
				map((upload) => {
					//Total progress made
					let progress = 0;

					//Do we have any upload data?
					if (upload && upload.uploadData && upload.uploadData.length > 0) {
						//Work out the current progress and the total progress values ... if we have multiple file this will be larger than 100
						let currentProgress = 0;
						const totalProgress = 100 * upload.uploadData.length;

						//For every file we have total up how much upload progress we are
						upload.uploadData.forEach((a) => {
							if (a.progress) {
								currentProgress += a.progress;
							}
						});

						//Work out the progress
						progress = (100 / totalProgress) * currentProgress;
					}

					return progress;
				})
			);

		//Setup a subscription ready for the complete
		requestActionMonitor
			.worklogByActionId(actionId, true)
			.observable()
			.pipe(
				filter((worklog) => worklog.startTime !== undefined)
				// takeUntil(componentDestroyStream(this))
			)
			.subscribe(
				(worklog) => {
					//Has the work log finished?
					if (worklog.complete) {
						//Reset the renderer
						this.rendererBaseStateReset(rendererConfig);

						//If there was a fault set the error
						if (worklog.fault) {
							this.rendererSetError(rendererConfig, worklog.exception);
						}
					} else {
						//Update the view to reflect the upload state
						rendererConfig.busyIndicatorState = "indeterminate";
						rendererConfig.busyIndicatorIcon = undefined;
						rendererConfig.busyIndicatorProgress = undefined;
					}
				},
				(error: Error) => {
					//Reset the renderer
					this.rendererBaseStateReset(rendererConfig);
					this.rendererSetError(rendererConfig, error.message);
				}
			);
	}

	/**
	 * Trigger the swap of two assets from the asset renderer
	 */
	private static assetRendererSwapAssets(
		parameters,
		requestActionMonitor: RequestActionMonitorService,
		assets: BehaviorSubject<ProductAssetViewParamsVO[]>,
		assetsMap: BehaviorSubject<Map<string, ProductAssetViewParamsVO>>
	) {
		//Get the current swap value
		const swapWith = StoreAccess.dataGet(viewLibraryProductAssetSwapSource);

		//Stop the asset swap now!
		this.cancelAssetSwap(assets);

		//No swap data then we will skip over
		if (!swapWith) {
			return;
		}

		const castAssetData = parameters.data as ProductAssetViewVO;

		const simpleSourceProduct: ProductAHubVO = {
			id: swapWith.productId,
			productClassId: -1,
			productSectionValues: [
				{
					sectionId: swapWith.sectionId,
					productSectionPropertyValues: [
						{
							propertyId: swapWith.propertyId,
							value:
								castAssetData.assetId && castAssetData.assetId > 0
									? castAssetData.assetId.toString()
									: undefined,
						},
					],
				},
			],
		};

		const simpleDestinationProduct: ProductAHubVO = {
			id: castAssetData.productId,
			productClassId: -1,
			productSectionValues: [
				{
					sectionId: castAssetData.sectionId,
					productSectionPropertyValues: [
						{
							propertyId: castAssetData.propertyId,
							value:
								swapWith.assetId && swapWith.assetId > 0
									? swapWith.assetId.toString()
									: undefined,
						},
					],
				},
			],
		};

		// Commit the product(s) with the new asset locations
		const swapActionId = StoreAccess.dispatch(
			AHubActions.extractProductsCommitObjectsOnly(
				StoreAccess.dataGet(viewLibrarySelectedExtractId),
				[simpleSourceProduct, simpleDestinationProduct]
			)
		);

		//Set the state for our current renderer
		parameters.busyIndicatorIcon = "shuffle";
		parameters.busyIndicatorState = "indeterminate";
		parameters.menuButtonDisplay = "none";
		this.rendererSetError(parameters, undefined);

		//Get a source asset renderer
		const sourceRenderer = assetsMap
			.getValue()
			.get(
				this.generateAssetRendererCacheId(
					swapWith.productId,
					swapWith.allocationId,
					swapWith.assetId
				)
			);

		//Do we have a current renderer
		if (sourceRenderer) {
			sourceRenderer.busyIndicatorIcon = "shuffle";
			sourceRenderer.busyIndicatorState = "indeterminate";
			sourceRenderer.menuButtonDisplay = "none";
			this.rendererSetError(sourceRenderer, undefined);
		}

		requestActionMonitor
			.worklogByActionId(swapActionId, true)
			.onlyComplete()
			.observable()
			.pipe(
				filter((worklog) => worklog.startTime !== undefined)
				// takeUntil(componentDestroyStream(this))
			)
			.subscribe(
				(worklog) => {
					//Reset our renderer
					this.rendererBaseStateReset(parameters);

					//If we have one for the source reset it
					if (sourceRenderer) {
						this.rendererBaseStateReset(sourceRenderer);
					}

					//Did we get a fault in the worklog then we should show an error
					if (worklog.fault) {
						this.rendererSetError(parameters, worklog.exception);
						if (sourceRenderer) {
							this.rendererSetError(sourceRenderer, worklog.exception);
						}
					}
				},
				(error: Error) => {
					//Reset the renderer and set the error
					this.rendererBaseStateReset(parameters);
					this.rendererSetError(parameters, error.message);

					if (sourceRenderer) {
						this.rendererBaseStateReset(sourceRenderer);
						this.rendererSetError(sourceRenderer, error.message);
					}
				}
			);
	}

	/**
	 * Generate a cache id for the asset renderer
	 */
	private static generateAssetRendererCacheId(
		productId: number,
		allocationId: number,
		assetId: number
	): string {
		return `${productId}-${allocationId}-${assetId}`;
	}

	/**
	 * Cancel the asset swap from happening!
	 */
	public static cancelAssetSwap(
		assets: BehaviorSubject<ProductAssetViewParamsVO[]>
	) {
		const currentAssets: ProductAssetViewParamsVO[] = assets.getValue();

		//Clear out the old swap data
		StoreAccess.dispatch(ViewActions.libraryAssetsSwapSourceSet(undefined));

		//We will then reset each of the asset renderers into menu mode
		currentAssets.forEach((asset) => {
			asset.menuButtonDisplay = "menu";
			asset.menuButtonIcon = undefined;
		});
	}

	/**
	 * Simple function to start an asset download
	 */
	private static downloadAssetFromURL(assetURL: string) {
		console.log("Download", assetURL);
		StoreAccess.dispatch(AppActions.fileDownloadConfigSet([{ url: assetURL }]));
	}

	/**
	 * Upload a single asset
	 */
	public static uploadSingleAsset(
		productId: number,
		allocationId: number,
		file: File,
		notificationGenerator: NotificationGeneratorService,
		objectStoreService: ObjectStoreService,
		imageAssetTrimThreshold = 3
	) {
		//If we have no files then ignore.
		if (productId < 1 || allocationId < 1 || !file) {
			return -1;
		}

		//Set of records which should be created
		const uploadFileRecords: RequestActionStatusUploadDataVO[] = [];

		//Id for the asset file
		let assetId = "";

		// We can't upload files from just the provision of a directory.
		// Ie We can't look up files if we are given a directory. We need files..
		// Theres no 'good' way to recognise whether a file is a file or a directory (until https://web.dev/file-system-access/ becomes a thing)
		// While we wait, lets assume files have extensions (shonky but generally true)
		if (file.name.match(/\.[0-9a-z]{1,5}$/i)) {
			//Set the asset name for the file
			assetId = file.name;

			//Add the item to the upload record
			uploadFileRecords.push({
				objectStoreId: objectStoreService.store(file),
				uploadPath: `assets/${assetId}`,
			});
		} else {
			//Send a notification to show we cannot use a folder
			notificationGenerator.notificationDispatch({
				notificationId: `GN# ${new Date().valueOf()}`,
				notificationType: "General",
				notificationTime: new Date(),
				notificationLabel: "Drag / Dropping of folders prohibited by browser.",
				notificationIcon: "not_interested",
				notificationObject: {
					detail:
						"Drop multiple files instead, or use the upload from folder menu option.",
				},
			});
		}

		// Check we have files to store.
		if (uploadFileRecords.length === 0) {
			return -1;
		}

		//Call an action to commit an asset
		return StoreAccess.dispatch(
			AHubActions.extractProductsCommitObjectsAndAssets(
				StoreAccess.dataGet(viewLibrarySelectedExtractId),
				[],
				[
					{
						assetId,
						productAllocations: [
							{
								productId,
								allocationIds: [allocationId],
							},
						],
						productAssetFiles: uploadFileRecords,
					},
				],
				imageAssetTrimThreshold
			)
		);
	}

	public static buildFlickBookChapters(
		assetData: ProductAssetSectionPropertyValues
	): ProductAssetViewDialogFlickbookComponentParamsChapter[] {
		// No data? Then return undefined.
		if (
			!assetData ||
			!assetData.assetInfo ||
			!assetData.assetInfo.typeSpecificInfo
		) {
			return undefined;
		}

		//Get the chapters element
		const chapters = assetData.assetInfo.typeSpecificInfo.entry.find(
			(entry) => entry["key"] === "chapters"
		);
		const chapersArray: any[] =
			chapters && chapters["value"] ? JSON.parse(chapters["value"]) : [];

		//Map the chapters into the dialogue parameters so that we are able to display a larger version of the asset
		return chapersArray.map((chapter) => {
			return {
				chapterId: chapter["chapterId"],
				chapterLabel: chapter["chapterLabel"],
				chapterUrls: assetData.assetUrls.filter((url) =>
					url.includes(`/${chapter["chapterId"]}/full/`)
				),
			} as ProductAssetViewDialogFlickbookComponentParamsChapter;
		});
	}

	static buildAssetInfoMapFromAssetData(
		assetData: ProductAssetSectionPropertyValues
	): Map<string, string> {
		return assetData &&
			assetData.assetInfo &&
			assetData.assetInfo.typeSpecificInfo
			? new Map(
					assetData.assetInfo.typeSpecificInfo.entry.map((entry) => [
						entry.key,
						entry.value,
					])
			  )
			: new Map<string, string>();
	}
}
