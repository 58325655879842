import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";
import { GridEditorParamsInterface } from "../grid-editor-params-interface";

@Component({
	selector: "app-grid-editor-date",
	templateUrl: "./grid-editor-date.component.html",
	styleUrls: ["./grid-editor-date.component.css"],
})
export class GridEditorDateComponent
	implements ICellEditorAngularComp, AfterViewInit
{
	/**
	 * Input for the date
	 */
	@ViewChild("dateInput", { static: true }) dateInput;

	/**
	 * Parameters object passed to the editor
	 */
	params: ICellEditorParams = undefined;
	paramsCustom: GridEditorDateComponentParams = undefined;

	/**
	 * The current date in Ms which has been selected
	 */
	currentDateInMs = -1;

	/**
	 * Init of the renderer
	 */
	agInit(params: any): void {
		//Set the parameters
		this.params = params;
		this.paramsCustom = params;

		//Get the current value for the renderer
		const currentValue = this.params.value;

		//Do we have a value
		if (currentValue) {
			//Set the current value
			this.currentDateInMs = currentValue;
		}

		//Update the date string
		this.updateDateString();
	}

	/**
	 * Once the view has been initalised
	 */
	ngAfterViewInit() {
		//Update the date string
		this.updateDateString();
	}

	/**
	 * Get the currently selected value
	 */
	getValue(): any {
		//As far as were concerened the time has to be greater than 0 or it's not set!
		return this.currentDateInMs > 0 ? this.currentDateInMs + "" : undefined;
	}

	/**
	 * This is a popup so thats that
	 */
	isPopup(): boolean {
		return false;
	}

	/**
	 * Is called when editing is about to start. Giving
	 * us an opertunity to stop the editing before it happens
	 */
	isCancelBeforeStart(): boolean {
		//Do we have a supplied cancel function in the parameters
		if (this.paramsCustom.canEditCellFunc) {
			return !this.paramsCustom.canEditCellFunc(this.params);
		}

		//No is cancel function so no cancel behaviour
		return false;
	}

	/**
	 * Triggered the date change
	 *
	 * @param event
	 */
	dateChanged(event) {
		//Get the valid flag for the date
		const validDate = event.target.validity.valid;

		//Not valid we will not do anything
		if (!validDate) {
			return;
		}

		//Get the currently selected date
		const dateSelected: Date = event.target.valueAsDate;

		//Remove all time elements from the stored date
		if (dateSelected) {
			dateSelected.setUTCHours(0);
			dateSelected.setUTCMinutes(0);
			dateSelected.setUTCSeconds(0);
			dateSelected.setUTCMilliseconds(0);
		}

		//Set the current date time
		this.currentDateInMs = dateSelected ? dateSelected.getTime() : 0;

		//No time then we will come the editor
		if (this.currentDateInMs === 0) {
			this.close();
		}
	}

	/**
	 * Close the editor
	 */
	close() {
		//Option has been selected so we are done
		if (this.params.api) {
			this.params.api.stopEditing();
		}
	}

	/**
	 * Update the date string
	 */
	updateDateString() {
		//Set the date input if we have one
		if (this.dateInput) {
			//Loose any time element from the date
			let date: Date = undefined;

			//Create the date so we can remove the time elements
			if (this.currentDateInMs > 0) {
				date = new Date();
				date.setTime(this.currentDateInMs);
				date.setUTCHours(0);
				date.setUTCMinutes(0);
				date.setUTCSeconds(0);
				date.setUTCMilliseconds(0);
			}

			//Set the date as the value
			this.dateInput.nativeElement.valueAsNumber =
				!date || date.getTime() <= 0 ? undefined : date.getTime();
		}
	}
}

/**
 * Get the parameters
 */
export interface GridEditorDateComponentParams
	extends GridEditorParamsInterface {}
