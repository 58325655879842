import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "truncateLongLabel",
})
export class TruncateLongLabelPipe implements PipeTransform {
	transform(value: string, maxLength: number): any {
		if (value.length > maxLength) {
			return value.substr(0, maxLength - 3) + "...";
		}

		return value;
	}
}
