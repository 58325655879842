import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";

@Component({
	selector: "app-fab-icon-busy-button",
	templateUrl: "./fab-icon-busy-button.component.html",
	styleUrls: ["./fab-icon-busy-button.component.css"],
})
export class FabIconBusyButtonComponent {
	@Input()
	/**
	 * Boolean Observable which lets up know weather the item is busy or not
	 */
	isBusy: boolean = false;

	@Input()
	/**
	 * Is the button disabled or not
	 */
	isDisabled: boolean = false;

	/**
	 * What is the button icon to display.
	 */
	@Input()
	buttonIcon: string = "bi-add";

	/**
	 * Position in nav - left center or right
	 */
	@Input()
	position = "";

	/**
	 * What is the button icon to display.
	 */
	@Input()
	svgIcon = false;

	/**
	 * Used to overide the default theme (colors).
	 */
	@Input()
	colour: string = "accent";

	/**
	 * Shall we make it a mini fab button?.
	 */
	@Input() mini: boolean = false;

	/**
	 * This is any tool tip we want to put on the button.
	 */
	@Input()
	buttonToolTip: string = null;

	/**
	 * This is any disabled tool tip we want to put on the button.
	 */
	@Input()
	disabledToolTip: string = null;

	/**
	 * If specified, this is the value to be applied as a badge (matBadge) to the button.
	 */
	@Input()
	badgeValue$: Observable<number>;

	@Output()
	/**
	 * Button click event emitter
	 */
	clicked: EventEmitter<void> = new EventEmitter<void>();

	constructor() {
		// This is intentional
	}

	/**
	 * Click handler for the button.
	 */
	buttonClickHandler() {
		//Emit an on click event
		this.clicked.emit();
	}
}
