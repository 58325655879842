import { HashedMapStorage } from "store/hashed-map-storage.vo";
import { HashedMapUtil } from "store/hashed-map-storage.util";

/**
 * This is the util class for accessing the data of the hashed map which is indexed by an id field.
 * We have this one specifically for the id as its a very common implementation and cleans up the implementation
 * outside the class!
 *
 * This version will also timestamp all the data which the regular version will not!
 */
export class IdHashedMapUtil {
	/**
	 * Define an id function which we will using for getting the objects id
	 */
	private static ID_FUNCTION = (item) => String(item.id);

	/**
	 * Create a new hashed map with the data specified
	 *
	 * @param data          The data which we want to start the map off with
	 * @param mapBuckets    Amounts of buckets which the hash map will be split into
	 */
	public static hashMapCreate<T>(
		data: T[],
		mapBuckets?: number
	): HashedMapStorage<T> {
		return HashedMapUtil.hashedMapCreate(data, IdHashedMapUtil.ID_FUNCTION);
	}

	/**
	 * Append the items into the list
	 *
	 * @param hashMap           Hash map which we want to add the data too
	 * @param item              Item which we want to add
	 * @param idFunction        The function which we will use to identify the unique key of the item
	 */
	public static hashMapAppendItem<T>(
		hashMap: HashedMapStorage<T>,
		item: T
	): void {
		return HashedMapUtil.hashMapAppendItem(
			hashMap,
			item,
			IdHashedMapUtil.ID_FUNCTION
		);
	}

	/**
	 * Append all the items in the array into the hash map
	 *
	 * @param hashMap       Hash map which we want to add the data too
	 * @param items         The items which we want to add to the map
	 */
	public static hashMapAppendItems<T>(
		hashMap: HashedMapStorage<T>,
		items: T[]
	): void {
		return HashedMapUtil.hashMapAppendItems(
			hashMap,
			items,
			IdHashedMapUtil.ID_FUNCTION
		);
	}
}
