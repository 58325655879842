import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";

@Component({
	selector: "app-distribution-rules-dialog",
	templateUrl: "./distribution-rules-dialog.component.html",
	styleUrls: ["./distribution-rules-dialog.component.css"],
})
export class DistributionRulesDialogComponent implements OnInit {
	//Link to the add filter term text input
	@ViewChild("newFilterTermInput", { static: true }) newFilterTermInput;

	/**
	 * This is the title and content to display in this dialog.
	 */
	public title: string;
	public listControlForm: UntypedFormControl;

	/**
	 * These are the labels to be used on the confirm and cancel buttons.
	 */
	public confirmButtonLabel: string;
	public cancelButtonLabel: string;

	/**
	 * Configuration which can be sent to the form
	 */
	public config: DistributionRulesDialogComponentConfig = {
		listFormControl: undefined,
		simpleDistribution: true,
	};

	/**
	 *  Hide AND, EMPTY and NOT buttons if idalog should be simple
	 */
	public simple: boolean = false;

	newFilter: string = "";
	newFilterTerm: string = "";

	constructor(
		public dialogRef: MatDialogRef<DistributionRulesDialogComponent>
	) {}

	ngOnInit() {
		//Do we have configuration? if so then we can apply the properties
		if (this.config) {
			//Set the object properties from the configuration
			this.listControlForm = this.config.listFormControl;
			this.simple = this.config.simpleDistribution;
		}

		//We want to set the focus
		this.focusInput();
	}

	/**
	 * Remove the item at the specified index from the list
	 */
	itemRemove(index: number) {
		//Get the item array
		let itemArray: any[] = this.itemListFromControl();

		//If the index is off either bounds then we cannot remove the item
		if (index < 0 || itemArray == undefined || index >= itemArray.length)
			return;

		//Remove the item from the item array
		itemArray.splice(index, 1);

		//Mark the form as dirty
		this.listControlForm.markAsDirty();

		//Set the altered list of values in the form control
		this.listControlForm.setValue(itemArray);
	}

	/**
	 * Return the item list from the form control
	 */
	itemListFromControl(): any[] {
		//If the list form control is undefined then we will return an empty list
		if (this.listControlForm == undefined) return [];

		//Return the item list
		return <any[]>this.listControlForm.value;
	}

	/**
	 * This handler is called when the user clicks on the and button.
	 */
	and() {
		// Stop here if there isn't anything in the current new filter term.
		if (this.newFilterTerm === "" || this.newFilterTerm === "!") return;

		// Add the new filter term to the new filter.
		this.newFilter += this.newFilterTerm.concat("&");
		this.newFilterTerm = "";
		this.focusInput();
	}

	/**
	 * This handler is called when the user clicks on the or button.
	 */
	or() {
		// Stop here if there isn't anything in the current new filter term.
		if (this.newFilterTerm === "" || this.newFilterTerm === "!") return;

		// Add the new filter term to the new filter.
		this.newFilter += this.newFilterTerm.concat("|");
		this.newFilterTerm = "";
		this.focusInput();
	}

	/**
	 * This handler is called when the user clicks on the any button.
	 */
	any() {
		// Lose the current new filter.
		this.newFilter = "";

		// Set the new filter term to *.
		this.newFilterTerm = "*";

		// Then add the value.
		this.addCurrentFilter();
	}

	/**
	 * This handler is called when the user clicks on the not empty button.
	 */
	notEmpty() {
		// Lose the current new filter.
		this.newFilter = "";

		// Set the new filter term to null.
		this.newFilterTerm = "!null";

		// Then add the value.
		this.addCurrentFilter();
	}

	/**
	 * This handler is called when the user clicks on the empty button.
	 */
	empty() {
		// Lose the current new filter.
		this.newFilter = "";

		// Set the new filter term to null.
		this.newFilterTerm = "null";

		// Then add the value.
		this.addCurrentFilter();
	}

	/**
	 * This handler is called when the user clicks on the not button.
	 */
	not() {
		// Get the filter length.
		let filterLastCharIndex: number = this.newFilter.length - 1;

		if (this.newFilter.charAt(filterLastCharIndex) === "!") return;

		this.newFilter = this.newFilter + "!" + this.newFilterTerm;
		this.newFilterTerm = "";
		this.focusInput();
	}

	/**
	 * This handler is called when the user clicks on the clear button.
	 */
	clear() {
		this.newFilter = "";
		this.newFilterTerm = "";
		this.focusInput();
	}

	/**
	 * Add the filter to the form group
	 */
	add() {
		// Add the current filter data.
		this.addCurrentFilter();

		// Set the focus on the input.
		this.focusInput();
	}

	/**
	 * This function will add the data in the current filter value.
	 */
	private addCurrentFilter() {
		this.newFilter += this.newFilterTerm;
		this.newFilter = this.trimTrailingAndMultipleAnds(this.newFilter);

		//Check that the input has a valid value, if it has then push the value onto the form
		if (this.newFilter.trim().length > 0) {
			//Get the current item list
			let inputItemList: any[] = this.itemListFromControl();

			//If the item list is undefined then we will set it to a new array ready for use
			if (inputItemList == undefined) inputItemList = [];

			//Add the item to the list
			inputItemList.unshift(this.newFilter);

			//Mark the form as dirty
			this.listControlForm.markAsDirty();

			//Set the altered list of values in the form control
			this.listControlForm.setValue(inputItemList);
		}

		// Clear the filters.
		this.newFilter = "";
		this.newFilterTerm = "";
	}

	/**
	 * Set the focus to the input
	 */
	focusInput() {
		setTimeout(() => {
			this.newFilterTermInput.nativeElement.focus();
		}, 1);
	}

	/**
	 * We want to prcess the input sream and return a cleaner input
	 *
	 * @param input   Input we want to clean
	 */
	trimTrailingAndMultipleAnds(input: string) {
		// Replace all repeat &'s and !'s.
		input = input.replace(new RegExp("/&{2,}/g"), "&");
		input = input.replace(new RegExp("/!{2,}/g"), "!");

		// Remove all trailing &'s and !'s.
		input = input.replace(new RegExp("&+$"), "");
		input = input.replace(new RegExp("!+$"), "");

		return input;
	}
}

/**
 *  Configuration for the distribution rules builder
 */
export class DistributionRulesDialogComponentConfig {
	public listFormControl: UntypedFormControl = undefined;
	public simpleDistribution: boolean = false;
}
