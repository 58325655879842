import {
	HttpClient,
	HttpEvent,
	HttpEventType,
	HttpHeaders,
	HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { last, map, tap } from "rxjs/operators";

@Injectable()
/**
 * Service for uploading objects
 */
export class UploadService {
	constructor(private http: HttpClient) {}

	/**
	 * Update a data object to the url specified
	 */
	upload(
		uploadUrl: string,
		dataObject: any,
		progressCallback: (percent: number) => void
	) {
		//No asset unable to upload
		if (!dataObject) return;

		// Create the request object that POSTs the file to an upload endpoint.
		const headers = new HttpHeaders({
			"Content-Type": "application/octet-stream",
		});

		// The `reportProgress` option tells HttpClient to listen and return
		// XHR progress events.
		const req = new HttpRequest("PUT", uploadUrl, dataObject, {
			headers: headers,
			reportProgress: true,
		});

		// The `HttpClient.request` API produces a raw event stream
		// which includes start (sent), progress, and response events.
		return this.http.request(req).pipe(
			map((event) => this.getEventMessage(event)),
			tap((percentageComplete) => progressCallback(percentageComplete)),
			last()
		);
	}

	/** Return distinct message for sent, upload progress, & response events */
	private getEventMessage(event: HttpEvent<any>): number {
		switch (event.type) {
			case HttpEventType.Sent:
				return 0;

			case HttpEventType.UploadProgress:
				return Math.round((100 * event.loaded) / event.total);

			case HttpEventType.Response:
				return 100;

			default:
				return -1;
		}
	}
}
