import { Component, OnInit } from "@angular/core";
import {
	FormGroup,
	FormControl,
	UntypedFormBuilder,
	Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import {
	SelectionACatalogVO,
	SelectionReactionACatalogVO,
} from "@harksolutions/ahub-web-services-types";
import { SelectionConfigACatalogVO } from "@harksolutions/ahub-web-services-types";
import { ACatalogActions } from "app/store/actions/acatalog.actions";
import { selectionDefaultConfigs } from "app/store/selector/acatalog.selector";
import { StoreAccess } from "app/store/store-access";
import { Observable, Subject } from "rxjs";
import { delay, takeUntil } from "rxjs/operators";
import { componentDestroyStream, Hark } from "../../hark.decorator";

@Component({
	selector: "app-selection-dialog",
	templateUrl: "./selection-dialog.component.html",
	styleUrls: ["./selection-dialog.component.css"],
})
@Hark()
export class SelectionDialogComponent implements OnInit {
	constructor(
		private readonly dialogRef: MatDialogRef<SelectionDialogComponent>,
		private readonly formBuilder: UntypedFormBuilder
	) {}

	selectionVO: SelectionACatalogVO = null;

	// selectionDialogForm = new FormGroup({
	//   selectionName: new FormControl(),
	//   config: new FormControl()
	// });
	selectionDialogForm = this.formBuilder.group({
		name: ["", Validators.required],
		config: ["", Validators.required],
	});

	/**
	 * Boolean stream should emit values to indicate whether dialog is valid.
	 */
	public dialogIsValid$: Subject<boolean> = new Subject<boolean>();

	selectionDefaultConfigs$: Observable<SelectionConfigACatalogVO[]> =
		StoreAccess.dataGetObvs(selectionDefaultConfigs);

	ngOnInit(): void {
		this.selectionDialogForm.patchValue(this.selectionVO);

		// Disable config changes for 'Edit' dialog
		if (
			this.dialogRef.componentInstance?.["initVO"] &&
			Object.keys(this.dialogRef.componentInstance["initVO"])?.length > 0
		) {
			this.selectionDialogForm.controls.config.disable();
		}

		// Listen on changes of form to update our selection dialog VO
		this.selectionDialogForm.valueChanges
			.pipe(takeUntil(componentDestroyStream(this)), delay(50))
			.subscribe(() => {
				const formInput = this.selectionDialogForm.getRawValue();

				//Update the form state as it has changed
				this.selectionVO.name = formInput.name;
				this.selectionVO.config = formInput.config;

				this.dialogIsValid$.next(this.selectionDialogForm.valid);
			});

		StoreAccess.dispatch(ACatalogActions.selectionDefaultConfigsFetch());
	}

	compareFunction(o1: SelectionACatalogVO, o2: SelectionACatalogVO) {
		return o1.id === o2.id;
	}

	ngOnDestroy() {
		// for hark decorator
	}

	closeDialog() {
		this.dialogRef.close(this.selectionVO);
	}
}
