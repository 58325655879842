import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "fontToLabel",
})
export class FontToLabelPipe implements PipeTransform {
	transform(value: string, args?: any): any {
		if (value) {
			switch (value) {
				case "Roboto":
					return "Default";
				case "NotoSansJP":
					return "Japanese";
				case "NotoSansKR":
					return "Korean";
				case "NotoSansSC":
					return "Simplified Chinese";

				default:
					return "fontToLabel PIPE FAILED TO FIND " + value;
			}
		}
	}
}
