import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable()
/**
 * Class which will redirect users to a new domain, if they have accessed this site from an old one.
 */
export class UrlRedirectGuard implements CanActivate {
	constructor(private readonly router: Router) {}

	/**
	 * Can we activate this route?
	 */
	canActivate() {
		// If we have got to the server via an old domain ( eg not ahub.cloud but perhaps portal.aworkbook.com , then redirect to the new ahub.cloud domain.)
		if (
			window.location.hostname.indexOf("ahub.cloud") === -1 &&
			window.location.hostname.indexOf("localhost") === -1
		) {
			this.redirectToPortalURL(window.location.href);
		}

		return true;
	}

	private redirectToPortalURL(href: string) {
		// redirect calls from: https://login.<clusterId>.ahub.harksolutions.net
		//                or: https://<clusterId>.portal.aworkbook.com
		//                to: https://portal.<environment>.ahub.cloud
		let redirectUrl;

		try {
			redirectUrl = new URL(href);

			// What environment  were we trying to get to?
			let environmentId = "."; // Default Live.. no environment subdomain required.
			if (redirectUrl.hostname.indexOf("alpha") !== -1) {
				environmentId = ".alpha.";
			}
			if (redirectUrl.hostname.indexOf("beta") !== -1) {
				environmentId = ".beta.";
			}
			if (redirectUrl.hostname.indexOf("gamma") !== -1) {
				environmentId = ".gamma.";
			}

			const portalHostname = `portal${environmentId}ahub.cloud`;

			redirectUrl.hostname = portalHostname;
		} catch (e) {
			console.log("redirectToPortalURL e: ", JSON.stringify(e));
		}
		window.location.href = redirectUrl;
	}
}
