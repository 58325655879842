import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	Input,
	OnInit,
} from "@angular/core";
import { Utils } from "app/modules/common/utils";
import { PropertyAViewVO } from "app/modules/routes/aview/valueObjects/property.aview.vo";
import {
	aHubStateTemporaryProductPropertyAViewVOs,
	aHubStateTemporaryProductPropertyList,
} from "app/store/selector/ahub/ahub-temporary.selector";
import { StoreAccess } from "app/store/store-access";
import { EntityPermissions } from "app/valueObjects/ahub/accounts/entity-permissions.ahub";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import { DistributionAviewPropertiesConfigIndexItemComponent } from "./distribution-aview-properties-config-index-item/distribution-aview-properties-config-index-item.component";

@Component({
	selector: "app-distribution-aview-property-filter",
	templateUrl: "./distribution-aview-property-filter.component.html",
	styleUrls: ["./distribution-aview-property-filter.component.css"],
})
export class DistributionAviewPropertyFilterComponent implements OnInit {
	/**
	 * These are the distribution editor permissions required to
	 * add/remove restrictions.
	 */
	distributionEditor = EntityPermissions.DISTRIBUTION_EDITOR;

	@Input() propertyExclusionIds$: BehaviorSubject<number[]>;

	@Input() propertyExclusionsInherited$: BehaviorSubject<boolean>;

	@Input() isGroupSettings = false;

	@Input() readOnly = false;

	@Input() userExportReadOnly = false;

	aViewProductProperties$: Observable<PropertyAViewVO[]> =
		StoreAccess.dataGetObvs(aHubStateTemporaryProductPropertyList).pipe(
			map((propertyList) => propertyList.data),
			filter((properties) => properties?.length > 0)
		);

	/**
	 * Component factory to generate components for the index.
	 */
	public componentFactory: ComponentFactory<DistributionAviewPropertiesConfigIndexItemComponent> =
		this.resolver.resolveComponentFactory(
			DistributionAviewPropertiesConfigIndexItemComponent
		);

	includedPropertyIndexItems$: BehaviorSubject<
		PropertiesConfigIndexItemAHubVO[]
	> = new BehaviorSubject(undefined);
	excludedPropertyIndexItems$: BehaviorSubject<
		PropertiesConfigIndexItemAHubVO[]
	> = new BehaviorSubject(undefined);

	constructor(private readonly resolver: ComponentFactoryResolver) {}

	ngOnInit() {
		combineLatest([
			this.aViewProductProperties$.pipe(Utils.isNotNullOrUndefined()),
			this.propertyExclusionIds$.pipe(Utils.isNotNullOrUndefined()),
		]).subscribe(([properties, exclusionIds]) => {
			let includedProperties = properties;

			if (exclusionIds) {
				includedProperties = includedProperties.filter(
					(property) => !exclusionIds.includes(property.id)
				);
			}

			const includedPropertiesIndexItems: PropertiesConfigIndexItemAHubVO[] =
				includedProperties.map((includedProperty) => {
					return { property: includedProperty, excluded: false };
				});

			this.includedPropertyIndexItems$.next(includedPropertiesIndexItems);

			// If there are no excludedAllocIds, we can Foxtrot Oscar
			if (!exclusionIds) {
				return;
			}

			const excludedAllocs = properties.filter((property) =>
				exclusionIds.includes(property.id)
			);

			const excludedPropertiesIndexItems: PropertiesConfigIndexItemAHubVO[] =
				excludedAllocs.map((includedProperty) => {
					return { property: includedProperty, excluded: true };
				});

			this.excludedPropertyIndexItems$.next(excludedPropertiesIndexItems);
		});

		// As properties are added to the 'excluded' list we should update our parent (through the excludedAllocIds BehaviorSubject),
		// such that the parent can save exporter setting as a whole
		this.excludedPropertyIndexItems$
			.pipe(
				Utils.isNotNullOrUndefined(),

				distinctUntilChanged((a, b) => {
					return JSON.stringify(a) === JSON.stringify(b);
				})
			)
			.subscribe((propertyIndexItemsToBeExcluded) => {
				const idsToBeExcluded: number[] = propertyIndexItemsToBeExcluded.map(
					(indexItem: PropertiesConfigIndexItemAHubVO) => {
						return indexItem.property.id;
					}
				);
				this.propertyExclusionIds$.next(idsToBeExcluded);
			});
	}

	propertiesConfigListIndexLabelFunction(index) {
		// Return the name from the index property.
		return index.property.label;
	}

	/**
	 * User search value passed back to index list to allow search against returned value
	 */
	propertiesConfigSearchValue = (
		propertyIndexItem: PropertiesConfigIndexItemAHubVO
	) => {
		return propertyIndexItem ? `${propertyIndexItem.property.label}` : ``;
	};

	includeProperty(propertyToBeIncluded) {
		const excludedPropertyIndexItems: PropertiesConfigIndexItemAHubVO[] =
			this.excludedPropertyIndexItems$.getValue();

		const excludedPropertyIndexItemsWithRemovedProperty: PropertiesConfigIndexItemAHubVO[] =
			excludedPropertyIndexItems.filter(
				(item) => item.property.id !== propertyToBeIncluded.property.id
			);

		this.excludedPropertyIndexItems$.next(
			excludedPropertyIndexItemsWithRemovedProperty
		);
	}

	excludeProperty(propertyToBeExcluded: PropertiesConfigIndexItemAHubVO) {
		const excludedPropertyIndexItems: PropertiesConfigIndexItemAHubVO[] =
			this.excludedPropertyIndexItems$.getValue();

		propertyToBeExcluded.excluded = true;

		excludedPropertyIndexItems.push(propertyToBeExcluded);

		this.excludedPropertyIndexItems$.next(excludedPropertyIndexItems);
	}

	inheritOnChange($event) {
		this.propertyExclusionsInherited$.next($event.checked);
	}
}

export interface PropertiesConfigIndexItemAHubVO {
	property: PropertyAViewVO;
	excluded: boolean;
}
