import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-product-asset-view-dialog-image",
	templateUrl: "./product-asset-view-dialog-image.component.html",
	styleUrls: ["./product-asset-view-dialog-image.component.css"],
})
export class ProductAssetViewDialogImageComponent implements OnInit {
	/**
	 * Parameters for the dialogue (must be null or property isn't found!)
	 */
	public dialogParams: ProductAssetViewDialogImageComponentParams = null;

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		// This is intentional
	}
}

/**
 * Parameters for setting up the image preview
 */
export interface ProductAssetViewDialogImageComponentParams {
	sectionName: string;
	sectionColour: string;
	propertyName: string;
	imageUrl: string;
}
