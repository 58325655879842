import { ComponentFactory, Injectable, ViewContainerRef } from "@angular/core";
import {
	MatDialog,
	MatDialogConfig,
	MatDialogRef,
} from "@angular/material/dialog";
import { Observable } from "rxjs";
import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";
import { ComponentDialogComponent } from "./component-dialog/component-dialog.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { FileSelectDialogComponent } from "./file-select-dialog/file-select-dialog.component";
import { InfoDialogComponent } from "./info-dialog/info-dialog.component";
import { KnowledgeBaseDialogComponent } from "./knowledge-base-dialog/knowledge-base-dialog.component";
import { SelectMultiListDialogComponent } from "./select-multi-list-dialog/select-multi-list-dialog.component";
import { SelectSingleListDialogComponent } from "./select-single-list-dialog/select-single-list-dialog.component";
import {
	MultipleAddDialogComponent,
	MultiAddDialogVO,
} from "./multiple-add-dialog/multiple-add-dialog.component";
import { ClientLogDialogComponent } from "./client-log-dialog/client-log-dialog.component";
import { ConfigureLibraryViewDialogComponent } from "./configure-library-view-dialog/configure-library-view-dialog.component";
import { VideoEmbedDialogComponent } from "./video-embed-dialog/video-embed-dialog.component";
import { EntityPermissionAHubVO } from "app/valueObjects/ahub/accounts/entity-permission.ahub.vo";

@Injectable()
export class DialogService {
	/**
	 * The view container ref for the top level application
	 */
	public appRootViewContainerRef: ViewContainerRef;

	constructor(private readonly dialog: MatDialog) {}

	/**
	 * This function will open a new instance of a multiple add dialog window.
	 */
	public configureLibraryViewDialogOpen(
		title: string,
		datasetID,
		dialogVO: any,
		enableResetButton: boolean,
		confirmButtonLabel?: string,
		cancelButtonLabel?: string
	): Observable<boolean> {
		// Create a new config for the dialog.
		const config = new MatDialogConfig();

		// Set the configs view container reference.
		config.viewContainerRef = this.appRootViewContainerRef;

		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<ConfigureLibraryViewDialogComponent> =
			this.dialog.open(ConfigureLibraryViewDialogComponent, config);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.datasetID = datasetID;

		dialogRef.componentInstance.dialogVO = dialogVO;

		dialogRef.componentInstance.enableResetButton = enableResetButton;

		dialogRef.componentInstance.confirmButtonLabel = confirmButtonLabel
			? confirmButtonLabel
			: "OK";
		dialogRef.componentInstance.cancelButtonLabel = cancelButtonLabel
			? cancelButtonLabel
			: "Cancel";

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}

	/**
	 * This function will open a new instance of a Video Embed add dialog window.
	 */
	public videoEmbedDialogOpen(
		title: string,
		videoUrl: string,
		cancelButtonLabel?: string
	): Observable<boolean> {
		// Create a new config for the dialog.
		const config = new MatDialogConfig();

		// Set the configs view container reference.
		config.viewContainerRef = this.appRootViewContainerRef;

		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<VideoEmbedDialogComponent> = this.dialog.open(
			VideoEmbedDialogComponent,
			config
		);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.videoUrl = videoUrl;
		dialogRef.componentInstance.cancelButtonLabel = cancelButtonLabel
			? cancelButtonLabel
			: "Cancel";

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}

	/**
	 * This function will open a new instance of a multiple add dialog window.
	 */
	public multipleAddDialogOpen(
		title: string,
		content: string,
		multiAddDialogVO: MultiAddDialogVO,
		confirmButtonLabel?: string,
		cancelButtonLabel?: string
	): Observable<boolean> {
		// Create a new config for the dialog.
		const config = new MatDialogConfig();

		// Set the configs view container reference.
		config.viewContainerRef = this.appRootViewContainerRef;

		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<MultipleAddDialogComponent> =
			this.dialog.open(MultipleAddDialogComponent, config);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.content = content;
		dialogRef.componentInstance.multiAddDialogVO = multiAddDialogVO;
		dialogRef.componentInstance.confirmButtonLabel = confirmButtonLabel
			? confirmButtonLabel
			: "OK";
		dialogRef.componentInstance.cancelButtonLabel = cancelButtonLabel
			? cancelButtonLabel
			: "Cancel";

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}

	/**
	 * This function will open a new instance of a confirm dialog window.
	 */
	public confirmDialogOpen(
		title: string,
		content: string,
		confirmButtonLabel?: string,
		cancelButtonLabel?: string
	): Observable<boolean> {
		// Create a new config for the dialog.
		const config = new MatDialogConfig();

		// Set the configs view container reference.
		config.viewContainerRef = this.appRootViewContainerRef;

		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(
			ConfirmDialogComponent,
			config
		);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.content = content;
		dialogRef.componentInstance.confirmButtonLabel = !confirmButtonLabel
			? "OK"
			: confirmButtonLabel;
		dialogRef.componentInstance.cancelButtonLabel = !cancelButtonLabel
			? "Cancel"
			: cancelButtonLabel;

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}

	/**
	 * This function will open a new instance of a info dialog window.
	 */
	public infoDialogOpen(
		title: string,
		contentAssetFileLocation: string
	): Observable<boolean> {
		// Create a new config for the dialog.
		const config = new MatDialogConfig();

		// Set the configs view container reference.
		config.viewContainerRef = this.appRootViewContainerRef;

		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<InfoDialogComponent> = this.dialog.open(
			InfoDialogComponent,
			config
		);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.contentAssetFileLocation =
			contentAssetFileLocation;

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}

	/**
	 * This function will open a new instance of a knowledge base dialog window.
	 */
	public knowledgeBaseDialogOpen(
		title: string,
		knowledgeBasePageLocation: string
	): Observable<boolean> {
		// Create a new config for the dialog.
		const config = new MatDialogConfig();

		// Set the configs view container reference.
		config.viewContainerRef = this.appRootViewContainerRef;

		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<KnowledgeBaseDialogComponent> =
			this.dialog.open(KnowledgeBaseDialogComponent, config);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.knowledgeBasePageLocation =
			knowledgeBasePageLocation;

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}

	/**
	 * This function will open a new instance of a alert dialog window.
	 */
	public alertDialogOpen(
		title: string,
		content: string,
		confirmButtonLabel?: string
	): Observable<boolean> {
		// Create a new config for the dialog.
		const config = new MatDialogConfig();
		config.maxWidth = "40vw";

		// Set the configs view container reference.
		config.viewContainerRef = this.appRootViewContainerRef;
		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<AlertDialogComponent> = this.dialog.open(
			AlertDialogComponent,
			config
		);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.content = content;
		dialogRef.componentInstance.confirmButtonLabel = !confirmButtonLabel
			? "OK"
			: confirmButtonLabel;

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}

	/**
	 * This function will open a new instance of a list dialog window.
	 */
	public selectSingleListModalDialogOpen(
		title: string,
		listTitle: string,
		listData$: Observable<any[]>,
		selectedValue?: any,
		itemSelectorProperty?: string,
		confirmButtonLabel?: string,
		cancelButtonLabel?: string,
		modal?: boolean
	): Observable<any> {
		// Create a new config for the dialog.
		const config = new MatDialogConfig();

		// Set the configs view container reference.
		config.viewContainerRef = this.appRootViewContainerRef;
		config.disableClose = modal;

		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<SelectSingleListDialogComponent> =
			this.dialog.open(SelectSingleListDialogComponent, config);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.listTitle = listTitle;
		dialogRef.componentInstance.listDataSet(listData$);
		dialogRef.componentInstance.confirmButtonLabel = !confirmButtonLabel
			? "OK"
			: confirmButtonLabel;
		dialogRef.componentInstance.cancelButtonLabel = !cancelButtonLabel
			? "Cancel"
			: cancelButtonLabel;

		// Set the selected value if we have one.
		if (selectedValue !== undefined) {
			dialogRef.componentInstance.selectedValue = selectedValue;
		}

		// Do we have a item selector property.
		if (itemSelectorProperty !== undefined) {
			dialogRef.componentInstance.itemSelectorProperty = itemSelectorProperty;
		}

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}

	/**
	 * This function will open a new instance of a list dialog window.
	 */
	public selectMultiListDialogOpen(
		title: string,
		textBlock: string,
		listTitle: string,
		listData$: Observable<any[]>,
		selectedIds?: number[],
		labelProperty?: string,
		selectAllOption?: boolean,
		confirmButtonLabel?: string,
		cancelButtonLabel?: string,
		componentFactory?: ComponentFactory<any>,
		componentVOParamName?: string,
		editableAs?: EntityPermissionAHubVO
	): Observable<any[]> {
		// Create a new config for the dialog.
		const config = new MatDialogConfig();

		// Set the configs view container reference.
		config.viewContainerRef = this.appRootViewContainerRef;

		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<SelectMultiListDialogComponent> =
			this.dialog.open(SelectMultiListDialogComponent, config);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.componentFactory = componentFactory;
		dialogRef.componentInstance.componentVOParamName = componentVOParamName;
		dialogRef.componentInstance.textBlock = textBlock;
		dialogRef.componentInstance.listTitle = listTitle;
		dialogRef.componentInstance.editableAs = editableAs;
		dialogRef.componentInstance.listDataSet(listData$);

		//If we have selected ids then we will set them
		if (selectedIds) {
			dialogRef.componentInstance.listDataSelectedSet(selectedIds);
		}

		dialogRef.componentInstance.labelProperty = !labelProperty
			? "name"
			: labelProperty;
		dialogRef.componentInstance.confirmButtonLabel = !confirmButtonLabel
			? "OK"
			: confirmButtonLabel;
		dialogRef.componentInstance.cancelButtonLabel = !cancelButtonLabel
			? "Cancel"
			: cancelButtonLabel;

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}

	/**
	 * This function will open a select file(s) dialog.
	 *
	 * @param title                 The title of the dialog.
	 * @param content               The description for the dialog.
	 * @param allowMultipleSelect   Does this dialog allow multiple files to be selected at once?
	 * @param acceptableFileTypes   This is the actual list of the acceptable file types.
	 * @param confirmButtonLabel    The label on the confirm button.
	 * @param cancelButtonLabel     The label on the cancel button.
	 */
	public fileSelectDialogOpen(
		title: string,
		content: string,
		allowMultipleSelect?: boolean,
		acceptableFileTypes?: string[],
		confirmButtonLabel?: string,
		cancelButtonLabel?: string
	): Observable<any[]> {
		// Create a new config for the dialog.
		const config = new MatDialogConfig();

		// Set the configs view container reference.
		config.viewContainerRef = this.appRootViewContainerRef;

		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<FileSelectDialogComponent> = this.dialog.open(
			FileSelectDialogComponent,
			config
		);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.content = content;
		dialogRef.componentInstance.allowMultipleSelect = allowMultipleSelect;
		dialogRef.componentInstance.setAcceptableFileTypes(acceptableFileTypes);
		dialogRef.componentInstance.confirmButtonLabel = !confirmButtonLabel
			? "OK"
			: confirmButtonLabel;
		dialogRef.componentInstance.cancelButtonLabel = !cancelButtonLabel
			? "Cancel"
			: cancelButtonLabel;

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}

	/**
	 * Open an instance of the dialogue to view a client log.
	 *
	 * @param clientLogId             The id of the client log to view.
	 * @param title                   The title to display on the dialogue.
	 * @param confirmButtonLabel      (Optional) The confirm button label.
	 */
	public clientLogDialogOpen(
		clientLogId: number,
		title: string,
		confirmButtonLabel?: string
	): Observable<boolean> {
		// Create a new config for the dialog.
		const config = new MatDialogConfig();
		config.maxWidth = "40vw";

		// Set the configs view container reference.
		config.viewContainerRef = this.appRootViewContainerRef;
		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<ClientLogDialogComponent> = this.dialog.open(
			ClientLogDialogComponent,
			config
		);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.confirmButtonLabel = !confirmButtonLabel
			? "OK"
			: confirmButtonLabel;
		dialogRef.componentInstance.clientLogId = clientLogId;

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}

	/**
	 * This function will open a component nested inside a dialog window
	 *
	 * @param title                                       The title of the window
	 * @param componentFactory                            A factory which can be used to generate the component which is to be nested in the dialog
	 * @param componentVOParamName                        Parameter name within the generated component we will supply the initVO object too
	 * @param initVO                                      InitVO is the inital state of the value object which is displayed on the screen,
	 *                                                    On close of the dialogue we will be supplied a result of this type back via the observable stream
	 * @param dialogValidatedBooleanStreamParamName       Parameter name within the componenet where a boolean stream which we can use to toggle the confirmation button state
	 * @param confirmButtonLabel                          Label for the confirmation button (optional)
	 * @param cancelButtonLabel                           Label for the cancel button (optional) if undefined it will not be displayed
	 * @param componentConfigParamName                             Parameter name within the generated componenet we will supply the configuration object too  (optional)
	 * @param componentConfigObject                                Configuration object which can be used to costomise the dialogue to different screens
	 * @param panelClassName                              CSS Class name to be applied to the dialog panel (optional)
	 */
	public componentDialogOpen<T>(
		title: string,
		componentFactory: ComponentFactory<any>,
		componentVOParamName: string,
		initVO: T,
		dialogValidatedBooleanStreamParamName?: string,
		confirmButtonLabel?: string,
		cancelButtonLabel?: string,
		componentConfigParamName?: string,
		componentConfigObject?: Object,
		panelClassName?: string,
		dialogConfig?: MatDialogConfig,
		matDialogContentClassName?: string,
		wrapperCSSClass?: string
	): Observable<T> {
		// Create a new config for the dialog.
		if (dialogConfig == undefined) {
			dialogConfig = new MatDialogConfig();
		}

		// Set the configs view container reference.

		dialogConfig.viewContainerRef = this.appRootViewContainerRef;
		dialogConfig.panelClass = panelClassName;

		// Now open the dialogue and record the reference to the dialog.
		const dialogRef: MatDialogRef<ComponentDialogComponent> = this.dialog.open(
			ComponentDialogComponent,
			dialogConfig
		);

		// Set up the dialog.
		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.wrapperCSSClass = wrapperCSSClass;
		dialogRef.componentInstance.componentFactory = componentFactory;
		dialogRef.componentInstance.paramNameVO = componentVOParamName;
		dialogRef.componentInstance.initVO = initVO;
		dialogRef.componentInstance.matDialogContentClassName =
			matDialogContentClassName;

		dialogRef.componentInstance.paramNameDialogValid =
			dialogValidatedBooleanStreamParamName;

		if (confirmButtonLabel) {
			dialogRef.componentInstance.confirmButtonLabel = confirmButtonLabel;
		}
		if (cancelButtonLabel) {
			dialogRef.componentInstance.cancelButtonLabel = cancelButtonLabel;
		}

		dialogRef.componentInstance.configurationParameter =
			componentConfigParamName;
		dialogRef.componentInstance.configurationObject = componentConfigObject;

		// Then return the obsverable after closed value. This can be subscribed to
		// get the users response in the dialog.
		return dialogRef.afterClosed();
	}
}
