/**
 * Create a reducer
 *
 * This function takes an object which is a mapping of action types to reducer functions e.g.
 * {
 *    'USERS_ACTION_SET': (state, action) => { set something },
 *    'USERS_ACTION_DELETE': (state, action) => { delete something }
 * }
 *
 */
export const createReducer = (initialState, handlers: Object) => {
	//We are creating a function within this function. This is the actual
	//function which will be called from the reducer this then will map the action
	//type to the function based on the handler that have been passed in
	return (state = initialState, action) => {
		//Does the have a property which matches the action passed in.
		//If so call the handler registered under the action type specified.
		//Passing the state and the action supplied
		if (handlers.hasOwnProperty(action.type))
			return handlers[action.type](state, action);
		else return state;
	};
};

/**
 * Generic array append utility to make it easy to append one array to another without having duplicate items
 */
export const arrayAppend = <T>(
	array: T[],
	newItems: T[],
	filter: (a: T, b: T) => boolean
): T[] => {
	// NOTE: I know I could use the .forEach() / .find() functions on an array ... however we did some tests
	//        and we found that the traditional loop appeared to be a little faster ( in our case ) either way
	//        will not make much difference on a short list.

	//Make a copy of the array, if its missign use an empty one
	if (array) array = array.concat();
	else array = [];

	//Copy of the new items array we will use for merging the data
	let newItemsCopy = [];

	//Make a copy of the new items, if its missign use an empty one
	if (newItems) newItemsCopy = newItems.concat();

	//Loop through the main array we are looking for the new items so we
	for (let x = 0; x < array.length; x++) {
		//If we are not looking for any more items then we will break out
		if (newItemsCopy.length <= 0) break;

		//Get the existing item out of the array
		let existingItem = array[x];

		//Loop through each of the new items to see if this item exists
		for (let y = 0; y < newItemsCopy.length; y++) {
			//Get the new item from the new item copy array
			let newItem = newItemsCopy[y];

			//Call the passed in filter function to check if the existing item is the new item
			if (filter(existingItem, newItem)) {
				//Slice the item off the list
				newItemsCopy.splice(y, 1);

				//Set the new item in the list
				array[x] = newItem;

				//Break out the list
				break;
			}
		}
	}

	//Return the result of
	return array.concat(newItemsCopy);
};
