import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// import { MaterialModule } from '@angular/material';
import { SharedModule } from "modules/shared/shared.module";

import { FooterComponent } from "./footer.component";

import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
@NgModule({
	imports: [
		MatIconModule,
		MatMenuModule,
		MatButtonModule,
		MatListModule,

		CommonModule,
		SharedModule,
		// MaterialModule
	],
	exports: [FooterComponent],
	declarations: [FooterComponent],
})
export class FooterModule {}
