import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from "@angular/core";
import { Utils } from "app/modules/common/utils";
import { PropertyAViewVO } from "app/modules/routes/aview/valueObjects/property.aview.vo";
import { ProductFilterTestAHubVO } from "app/valueObjects/ahub/library/product-filter-test.ahub.vo";
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
import { Observable, of } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { aHubStateTemporaryProductPropertyList } from "selector/ahub/ahub-temporary.selector";
import { viewPropertyIconMap } from "selector/view/view-library-classification-class.selector";
import { StoreAccess } from "store/store-access";

@Component({
	selector: "app-product-filter-test",
	templateUrl: "./product-filter-test.component.html",
	styleUrls: ["./product-filter-test.component.css"],
})
@Hark()
export class ProductFilterTestComponent implements OnInit, OnDestroy {
	@Input() propertyTest: ProductFilterTestAHubVO;
	@Input() allowPropertyTestsToBeAdded: boolean;

	@Output() propertyTestDelete: EventEmitter<void> = new EventEmitter<void>();
	@Output() editPropertyTest: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Get the product property indexes from the system
	 */
	productPropertyIndex$: Observable<PropertyAViewVO[]> =
		StoreAccess.dataGetObvs(aHubStateTemporaryProductPropertyList).pipe(
			map((propertyList) => propertyList.data)
		);

	property$: Observable<PropertyAViewVO> = of(undefined);

	//Stream for the property icon
	propertyIcon$: Observable<string> = of("");

	propertyIconMap = StoreAccess.dataGet(viewPropertyIconMap);

	isBusy: boolean = false;

	valueSurrounds: string = "";

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		// Put quotes around non number values
		let reg = new RegExp(/^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/);
		this.valueSurrounds = reg.test(this.propertyTest.value) ? "" : '"';

		this.property$ = this.productPropertyIndex$.pipe(
			Utils.isNotNullOrUndefined(),
			map((properties) => {
				const productFilterTestProperty = properties.find(
					(property) => property.id === this.propertyTest.propertyId
				);
				return productFilterTestProperty;
			}),
			takeUntil(componentDestroyStream(this))
		);

		this.propertyIcon$ = this.property$.pipe(
			map((property) =>
				property
					? this.propertyIconMap[property.typeReference]
					: "accessibility"
			)
		);
	}

	propertyTestClickHandler() {
		this.editPropertyTest.emit();
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	propertyTestDeleteClickHandler($event) {
		this.isBusy = true;
		this.propertyTestDelete.emit();
		$event.stopPropagation();
	}
}
