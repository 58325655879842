import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
	selector: "app-raised-busy-button",
	templateUrl: "./raised-busy-button.component.html",
	styleUrls: ["./raised-busy-button.component.css"],
})
export class RaisedBusyButtonComponent {
	@Input()
	/**
	 * Boolean which will change weather we see a spinner or a button
	 */
	isBusy: boolean = false;

	@Input()
	/**
	 * Is the button disabled or not
	 */
	isDisabled: boolean = false;

	@Input()
	/**
	 * Is the button disabled or not
	 */
	icon: string;

	@Input()
	/**
	 * Label for the button which we want to display
	 */
	buttonLabel: string = "Placeholder";

	@Output()
	/**
	 * Clicked event emitter
	 */
	clicked: EventEmitter<void> = new EventEmitter<void>();

	constructor() {
		// This is intentional
	}

	/**
	 * Click handler for the action button
	 */
	actionButtonClickHandler() {
		//Emit an on click event
		this.clicked.emit();
	}
}
