/**
 * Holds the state of the bulk transfers.
 * By having a seperate component state it can be re-used across views.
 * Whilst keeping the view state values seperate prevent cross view contamination.
 */
import { createReducer } from "reducers/reducer-util";
import { Reducer } from "redux";

/**
 * Actions
 */
import { ComponentActions } from "actions/component.actions";

/**
 * Action Types
 */
import { ActionComponentBulkTransferDataVOs } from "actions/types/component.action-types";

/**
 * Utils.
 */
import { ListUtil } from "store/list.util";
import { tassign } from "modules/common/type-assign.util";
import { List } from "store/list.vo";

/**
 * Value objects
 */
import { BulkTransferRecordAppVO } from "valueObjects/app/bulk-transfer-record.app.vo";
import { ActionWork } from "app/store/actions/types/work.action-types";

/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ComponentBulkTransferState {
	transferRequests: List<BulkTransferRecordAppVO>;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ComponentBulkTransferInitialState: ComponentBulkTransferState = {
	transferRequests: ListUtil.listCreateEmpty("actionId"),
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Append to the bulk transfer data
 */
export const bulkTransferRequestsDataAppend = (
	state: ComponentBulkTransferState,
	action: ActionComponentBulkTransferDataVOs
) => {
	return tassign(state, {
		transferRequests: ListUtil.listAppend(
			state.transferRequests,
			action.transferRequests,
			"actionId"
		),
	});
};

/**
 * Clear the bulk transfer data
 */
export const bulkTransferRequestsDataClear = (
	state: ComponentBulkTransferState,
	action: ActionWork
) => {
	return tassign(state, {
		transferRequests: ListUtil.listCreateEmpty("actionId"),
	});
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer
 */
reducerHandlers[ComponentActions.COMPONENT_BULK_TRANSFER_REQUESTS_DATA_APPEND] =
	bulkTransferRequestsDataAppend;
reducerHandlers[ComponentActions.COMPONENT_BULK_TRANSFER_REQUESTS_DATA_CLEAR] =
	bulkTransferRequestsDataClear;

/**
 * Create a reducers based on the reducers handlers
 */
export const ComponentBulkTransferStateReducer: Reducer<ComponentBulkTransferState> =
	createReducer(ComponentBulkTransferInitialState, reducerHandlers);

/**
 * Check if this reducer can handle the function specified
 */
export const ComponentBulkTransferStateHasHandler = (
	actionType: string
): boolean => reducerHandlers.hasOwnProperty(actionType);
