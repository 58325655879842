import { Component, Input, OnInit } from "@angular/core";
import { sessionUserId } from "app/store/selector/app.selector";
import { StoreAccess } from "app/store/store-access";
import {
	FeedbackView,
	GroupedFeedbackView,
} from "../feedback-message-widget.component";

@Component({
	selector: "app-feedback-container",
	templateUrl: "./feedback-container.component.html",
	styleUrls: ["./feedback-container.component.scss"],
})
export class FeedbackContainerComponent implements OnInit {
	@Input() groupedFeedback: GroupedFeedbackView;

	@Input() isNested = false;

	currentUserId: number = StoreAccess.dataGet(sessionUserId);

	constructor() {
		// This is intentional
	}

	trackById(index, indexItem: FeedbackView) {
		if (indexItem) {
			return `${indexItem.lastModified}`;
		}
		return undefined;
	}

	ngOnInit(): void {
		// This is intentional
	}
}
