/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import { ViewContentViewerState } from "app/store/reducers/view/view-content-viewer";
import { viewState } from "selector/view/view.selector";

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */

/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Get the content viewer state
 */
const viewContentViewer = (state): ViewContentViewerState =>
	viewState(state).contentViewer;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */

/**
 * Get the current number of items per row
 */
export const viewNumberOfItemsPerRow = (state): any =>
	viewContentViewer(state).numberOfItemsPerRow;

/**
 * Get the current number of items per row
 */
export const viewInfinityScrollerCurrentViewStart = (state): any =>
	viewContentViewer(state).infinityScrollerCurrentViewStart;
