import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginationIdRequestVO } from "app/valueObjects/ahub/system/pagination-id-request.ahub.vo";
import { environment } from "environments/environment";
import { Utils } from "modules/common/utils";
import { BehaviorSubject, Observable, of, Subject, timer } from "rxjs";
import {
	bufferCount,
	concatMap,
	filter,
	map,
	mergeMap,
	share,
	take,
	takeUntil,
	tap,
} from "rxjs/operators";
import { AHubServiceRequestPaginationControl } from "services/ahub/ahub-service-request-pagination-control";
import { PaginationControlAppVO } from "valueObjects/app/pagination-control.app.vo";

/**
 * When a request is made for an item by an Id, followed by similar requests for the same , but with different ids,
 * it makes sense to bundle them together so instead of many individual requests , we make a single request for all the items.
 * This is typical of say when we have a list of 100 items, rather than make 100 request typically milisecs appart, much better
 * to wait for say 50 ms, then send a full request, reduces load and probaby quicker.
 * This class represents such a bundled request. Used internally.
 */
export class RequestBufferBatch {
	requestType: string; // Identifier which is unique by endpoint, but not but not by object ids requested.
	responseObservable: Observable<Response>; // This is the observable object that will be returned for all requests batched together.
	ids: number[]; //  These are the combined id's of all the requests.
}

@Injectable()
export class AHubServiceUtil {
	/**
	 * A privtae cache used for bundling multiple individual requests to the same endpoint with different Ids.
	 */
	private requestBuffer: RequestBufferBatch[] = [];

	/**
	 * The domain for the API this should contain the full path including the protocol
	 */
	private aHubAPIDomain: string = "";

	/**
	 * This is a base path which can be applied to each call
	 */
	private aHubAPIBasePath: string = "";

	constructor(private http: HttpClient) {
		//Do we have aHub api data
		if (environment.aHubApi) {
			//Setup the aHub API data
			this.aHubAPIDomain = environment.aHubApi.domain;
			this.aHubAPIBasePath = environment.aHubApi.basePath;
		}
	}

	/**
	 * Takes a api request path ( without domain ), the HTTP method and options body string and parameters object
	 * where {userId:"bob"} becomes a url query string ?userId=bob etc..
	 * creates a (optionally signed using stored session) request to the aHub API which is then submitted and response returned.
	 * Also takes a cancel subject, when if emitted cancels any pending requests ( most useful for paginated requests )!
	 */
	requestGet<T>(
		requestPath: string,
		params?: Object,
		pagination?: PaginationControlAppVO | PaginationIdRequestVO,
		responseType?: string,
		cancelSignal?: Observable<any>
	): Observable<T> {
		return this.request<T>(
			requestPath,
			"GET",
			params,
			undefined,
			pagination,
			responseType,
			cancelSignal
		);
	}

	/**
	 * Request bundler.. multiple requests for singular IDs are bacthed togeather, whilst
	 * long list of requetss for multiple ids are split into multiple requests. This will prevent long id queries
	 * being truncated which typically results in the authentication throwing an error
	 * as the calculated signature no longer matches the one server side as the data has
	 * been changed.
	 *
	 * This method returns the OBJECTS in the response , NOT THE RESPONSE itself !
	 *
	 *
	 * NB: Do not include the Ids in the paramsBase object, they are added.
	 */
	requestIdBatch<T>(
		requestPath: string,
		ids: number[],
		httpMethod = "GET",
		batchSize: number = 100,
		idParam: string = "id",
		paramsBase?: Object
	): Observable<any> {
		// Contruct a request type containing all the information that needs to be the same in order for the
		// requests to be similir enough to merge.
		let requestType: string =
			requestPath + httpMethod + JSON.stringify(paramsBase) + idParam;

		// Do we have an existing request buffered ?
		let requestBufferBatch: RequestBufferBatch = this.requestBuffer.find(
			(requestBatch) => requestBatch.requestType == requestType
		);

		// Did we find one?
		if (requestBufferBatch != undefined) {
			// Yes, so add the ids in this request to the batch, and return the common observable.
			requestBufferBatch.ids = requestBufferBatch.ids.concat(ids);
			// Return the observable to the inital request, filtering on the ids originally requested.
			return requestBufferBatch.responseObservable.pipe(
				mergeMap((response: any) => {
					return this.responseFlatMapToArray(response);
				}),
				filter((object) => {
					return this.responseFilterObjectsById(object, ids, idParam);
				})
			);
		}

		// Create a new buffer batch item as we did'nt have one.
		requestBufferBatch = {
			requestType: requestType,
			responseObservable: null,
			ids: [],
		};

		// Create observable from timer which will trigger after a short period to make a request
		// based on all ids mergered from similair requets, store in our buffer.
		requestBufferBatch.responseObservable = timer(50).pipe(
			take(1),
			map((trigger) => requestBufferBatch.ids),
			mergeMap((id) => id), // flatten out teh array into individual observables.
			bufferCount(batchSize), // Batch into groups if there are lots!
			concatMap((idBatch: number[]): Observable<any> => {
				// Make the call for each batch before merging back togeather

				// With the call made, we need to clear the buffer item.
				this.requestBuffer = this.requestBuffer.filter(
					(requestBufferItem) => requestBufferItem.requestType != requestType
				);

				// Take a clone of the params object if supplied, or create a blank if not.
				let batchParams: Object = paramsBase
					? JSON.parse(JSON.stringify(paramsBase))
					: {};
				batchParams[idParam] = idBatch; // Add in the ids.

				switch (httpMethod) {
					case "GET":
						return this.requestGet(requestPath, batchParams);
					case "PUT":
						return this.requestPut(requestPath, batchParams);
					case "POST":
						return this.requestPost(requestPath, batchParams);
					case "DELETE":
						return this.requestDelete(requestPath, batchParams);

					default:
						console.error("requestIdBatch: httpMethod not found");
						break;
				}

				return null;
			}),
			share()
		); // Prevents the observable stream from being re-evaluate for each use.

		//  add in the first set of Ids/
		requestBufferBatch.ids = requestBufferBatch.ids.concat(ids);

		// and store for later.
		this.requestBuffer.push(requestBufferBatch);

		// Return the observable to the inital request, filtering on the ids originally requested.
		return requestBufferBatch.responseObservable.pipe(
			mergeMap((response: any) => {
				return this.responseFlatMapToArray(response);
			}),
			filter((object) => {
				return this.responseFilterObjectsById(object, ids, idParam);
			})
		);
	}

	/**
	 * Used in the flatMap observable function to convert the response body
	 * into a array of objects. ALWAYS returns an array even if single object.
	 */
	private responseFlatMapToArray(response: any): any[] {
		let jsonResponse = response;
		if (Array.isArray(jsonResponse)) return response;

		return [response];
	}

	/**
	 *  Takes a request object and ids and returns true if the object matches one of the ids supplied.
	 */
	private responseFilterObjectsById(
		object: any,
		filterIds: number[],
		isParamOverride?: string
	): boolean {
		// Ok this might be done better.. but its ok for now?

		// First we'll check the standard id property, to see if it exists and matches.
		if (object.hasOwnProperty("id"))
			return filterIds.findIndex((id) => id == object.id) > -1;

		// No standard property we will try using the same Id as used in the params to retrieve the object.
		if (isParamOverride)
			if (object.hasOwnProperty(isParamOverride))
				return filterIds.findIndex((id) => id == object[isParamOverride]) > -1;

		// We could'nt find a property to match against, so as a fall back position we will always return the object.
		// This may mean the object gets returned multiple times across multiple requests, but whilst inefficient
		// it should not break anything.
		// This is actually a good thing where the response contains a request ticket, say because we have had serveral delete requets.
		// In this case, the delete requests would have been combined into a single delete requests , but all teh actions that riggered need to know when that
		// single combined request has finished.
		return true;
	}

	/**
	 * Takes a api request path ( without domain ), the HTTP method and options body string and parameters object
	 * where {userId:"bob"} becomes a url query string ?userId=bob etc..
	 * creates a (optionally signed using stored session) request to th aHub API which is then submitted and response returned.
	 */
	requestPost(
		requestPath: string,
		params?: Object,
		body?: string,
		responseType?: string
	): Observable<any> {
		return this.request(
			requestPath,
			"POST",
			params,
			body,
			undefined,
			responseType
		);
	}

	/**
	 * Takes a api request path ( without domain ), the HTTP method and options body string and parameters object
	 * where {userId:"bob"} becomes a url query string ?userId=bob etc..
	 * creates a (optionally signed using stored session) request to th aHub API which is then submitted and response returned.
	 */
	requestPut(
		requestPath: string,
		params?: Object,
		body?: string,
		responseType?: string
	): Observable<any> {
		return this.request(
			requestPath,
			"PUT",
			params,
			body,
			undefined,
			responseType
		);
	}

	/**
	 * Takes a api request path ( without domain ), the HTTP method and options body string and parameters object
	 * where {userId:"bob"} becomes a url query string ?userId=bob etc..
	 * creates a (optionally signed using stored session) request to th aHub API which is then submitted and response returned.
	 */
	requestDelete(
		requestPath: string,
		params?: Object,
		body?: string
	): Observable<any> {
		return this.request(requestPath, "DELETE", params, body);
	}

	/**
	 * Takes a api request path ( without domain ), the HTTP method and options body string and parameters object
	 * where {userId:"bob"} becomes a url query string ?userId=bob etc..
	 * creates a (optionally signed using stored session) request to the aHub API which is then submitted and response returned.
	 * Extended to optionally take a pagination parameter to get a subset of the data.
	 */
	request<T>(
		requestPath: string,
		httpMethod: string,
		params?: Object,
		body?: string,
		pagination?: PaginationControlAppVO | PaginationIdRequestVO,
		responseType?: string,
		cancelSignal?: Observable<any>
	): Observable<T> {
		//Do we have pagination at all?
		if (pagination) {
			//Is this a pagination id request style pagination
			if (this.isPaginationIdRequest(pagination)) {
				//Call the for the request to be paginated via a paginated id request
				return this.requestPaginatedPaginationPage(
					requestPath,
					httpMethod,
					<PaginationIdRequestVO>pagination,
					cancelSignal,
					responseType,
					params,
					body
				);
			} else {
				//Get the pagination control object type
				let paginationControl: PaginationControlAppVO = <
					PaginationControlAppVO
				>pagination;

				// Because we are getting the data incrementally, we'll construct an observable which we can return immediately ( via the pagination request )
				// which we will use to emit the data as it comes in from the request.
				let resultSet: Subject<T> = new Subject();
				if (cancelSignal == undefined) {
					cancelSignal = new Observable<void>();
					console.log(
						"DEV NOTE: You have created an automated aginated request without a cancel - watch out !."
					);
				}
				return this.requestPaginatedAutoFetch<T>(
					requestPath,
					httpMethod,
					params,
					body,
					paginationControl,
					responseType,
					resultSet,
					new AHubServiceRequestPaginationControl(),
					cancelSignal
				).pipe(takeUntil(cancelSignal));
			}
		}

		// This is a simple request where we simply want to return the body of the response as an observable set
		// of typed data. So we grab the body only and return.

		// Single simple request, no automatic pagination re-request required.
		return this.requestFull<T>(
			requestPath,
			httpMethod,
			params,
			body,
			null,
			responseType
		).pipe(
			take(1),
			map((response) => response.body)
		);
	}

	/**
	 * This underlying request method returns both the headers and body of teh reponse, ie the full response object.
	 * For simple requests you might only need the body.. see request. For paginated requests where you want
	 * to automatically get the next page of results, you'll need the headers to work out what to get next.
	 * @param requestPath
	 * @param httpMethod
	 * @param params
	 * @param body
	 * @param paginationControl
	 * @param responseType
	 */
	private requestFull<T>(
		requestPath: string,
		httpMethod: string,
		params?: Object,
		body?: string,
		paginationHeaders?: HttpHeaders,
		responseType?: string
	): Observable<HttpResponse<T>> {
		//Make sure the request path is missing the
		requestPath = this.aHubAPIBasePath + requestPath;

		// Turn the params object into a key params string ( sorted ).
		let queryString: string = !params
			? ""
			: this.paramsObjectToQueryString(params);
		// construct full url to method call including params.
		let urlCall: string =
			this.aHubAPIDomain +
			requestPath +
			(queryString.length > 0 ? "?" + queryString : "");

		// Assemble headers and body into our request options.
		let options = {
			headers: paginationHeaders,
			body: body,
		};

		// This ensures that we get the full response including headers.. not just the body which is what we get by default.
		options["observe"] = "response";

		// If we have been provided a response type add that too , so we get back the data cast in the type we need.
		if (responseType) {
			options["responseType"] = responseType;
		}

		return this.http.request<HttpResponse<T>>(httpMethod, urlCall, options);
	}

	/**
	 * Paginate a series of requests using the paginated ids request objexct
	 *
	 * @param requestPath           Path to the request
	 * @param httpMethod            Method for the http method
	 * @param paginationIdRequest   Paginated id's request
	 * @param cancelSignal          Cancel the requests mid query
	 * @param responseType          What are the responce type for the request
	 * @param params                Parameters for the request
	 * @param body                  Body for the request
	 */
	private requestPaginatedPaginationPage<T>(
		requestPath: string,
		httpMethod: string,
		paginationIdRequest: PaginationIdRequestVO,
		cancelSignal: Observable<any>,
		responseType?: string,
		params?: Object,
		body?: string
	): Observable<T> {
		//Pairs of the values which we want to request
		let pageValuePairs: number[][] = [];

		//Do we have pagination data
		if (
			paginationIdRequest.paginationIdBreakdown &&
			paginationIdRequest.paginationIdBreakdown.pageStartIds
		) {
			//Pair the values up into pages e.g. page starting at  200  ending at 300
			pageValuePairs =
				paginationIdRequest.paginationIdBreakdown.pageStartIds.map(
					(val, index, src) => {
						//Get the id of the next page, if there is not another then we will use the last id as our bookend
						let nextPageId =
							index + 1 >= src.length
								? paginationIdRequest.paginationIdBreakdown.lastId
								: src[index + 1] - 1;

						//Create an array and return them as such
						return [val, nextPageId];
					}
				);
		}

		//We have no values so we will return an empty observable
		if (pageValuePairs.length == 0) return of();

		//Do we we want to sort this in descending order
		if (paginationIdRequest.paginationDirection == "desc")
			pageValuePairs.reverse();

		// We drive the data download by sending threadIds as a signal to download.
		// With each signal we take a pair of page values ( from and to ) to download.
		// Once Downlaoded, we re fire the singal with teh thread id as its ready to be reused.
		// we also fire additional signal to generate additional threads up to the max.
		// Net result , we download 1 page first ( to get the initl data on screen quickly )
		// then fire more threads up to the maximum to parallel download the rest of the data
		// more efficiently.
		let threadCount = 1; // Starts with 1 thread ( thread 0 );
		let maxDownloadThreads = 5; // Maximum is 5.
		let spawnThreadIdSignal: BehaviorSubject<{
			threadId: number;
			valuePair: number[];
		}> = new BehaviorSubject<{ threadId: number; valuePair: number[] }>({
			threadId: 0,
			valuePair: pageValuePairs.shift(),
		});

		return spawnThreadIdSignal.pipe(
			mergeMap((threadIdAndValuePair) => {
				if (threadIdAndValuePair.valuePair != undefined) {
					//Duplicate the parameters so we can edit them slightly
					let parameters = Utils.clone(params);

					//Set the thread index in the parameters, this will allow us to do simultanious requests
					parameters.requestThreadNumber = threadIdAndValuePair.threadId;

					//Create the headers
					var httpHeaders = new HttpHeaders()
						.append(
							"hark-options-pagination-first-id",
							threadIdAndValuePair.valuePair[0].toString()
						)
						.append(
							"hark-options-pagination-last-id",
							threadIdAndValuePair.valuePair[1].toString()
						);

					//Make the request for the data
					return this.requestFull<T>(
						requestPath,
						httpMethod,
						parameters,
						body,
						httpHeaders,
						responseType
					).pipe(
						map((response) => response.body),
						tap((data) => {
							//Get the next value pair that will run on this thread
							let nextValuePair =
								pageValuePairs && pageValuePairs.length > 0
									? pageValuePairs.shift()
									: undefined;

							//We will call to get the next set of values using the same thread number
							spawnThreadIdSignal.next({
								threadId: threadIdAndValuePair.threadId,
								valuePair: nextValuePair,
							});

							//Do we have any remaining threads which we can use
							while (threadCount < maxDownloadThreads) {
								//For each thread we will get a value and use it
								nextValuePair =
									pageValuePairs && pageValuePairs.length > 0
										? pageValuePairs.shift()
										: undefined;
								spawnThreadIdSignal.next({
									threadId: threadCount,
									valuePair: nextValuePair,
								});
								threadCount++;
							}
						}),
						takeUntil(cancelSignal)
					);
				}

				//If there is no data we will complete the stream!
				spawnThreadIdSignal.complete();
				return of<T>();
			}),
			takeUntil(cancelSignal)
		);
	}

	/**
	 * This extension of the request method automatically requests all paginated data ( from the pagination point onwards), one page at a time.
	 * If you simply want to make a request of a single page .. use the standard request method.
	 *
	 * DEV NOTE: See AHubServiceRequestPaginationControl for pagination details.
	 *
	 * @param requestPath
	 * @param httpMethod
	 * @param params
	 * @param body
	 * @param paginationControl
	 * @param responseType
	 * @param resultSet
	 */
	private requestPaginatedAutoFetch<T>(
		requestPath: string,
		httpMethod: string,
		params?: Object,
		body?: string,
		paginationControl?: PaginationControlAppVO,
		responseType?: string,
		resultSet?: Subject<T>,
		requestPaginationControl?: AHubServiceRequestPaginationControl,
		cancelSignal?: Observable<any>
	): Observable<T> {
		//Create the pagination headers
		let paginatedHeaders: HttpHeaders =
			this.paginationControlPaginationHeadersBuild(
				paginationControl.nextIndex,
				paginationControl.pageSize,
				paginationControl.sortDirection
			);

		this.requestFull<T>(
			requestPath,
			httpMethod,
			params,
			body,
			paginatedHeaders,
			responseType
		)
			.pipe(
				take(1), // Only expecting one response per request.
				takeUntil(cancelSignal)
			)
			.subscribe((response) => {
				// Publish the typed the body of the response to teh result set.
				resultSet.next(response.body);

				// Grab the next index representing the start of the next page of data.
				let nextIndex = response.headers.get("ahub-pagination-next-index");
				let currentIndex = response.headers.get(
					"ahub-pagination-current-index"
				);
				let nextPageSize: number;

				// Are we using exponential pagination. IE get me small quick, then larger chunks?
				if (paginationControl.exponential)
					nextPageSize = Math.min(
						(Number(nextIndex) - Number(currentIndex)) * 2,
						paginationControl.exponentialLimit
					);
				else nextPageSize = paginationControl.pageSize; // Keep the pagination equal size.

				// Remove from the requests threads the one requesting the index in the response.
				requestPaginationControl.requestThreadComplete(Number(currentIndex));

				// If we are going to get the next set of data
				// If there is a next page and we hav'nt retrieved teh last set of data yet.
				// ( Its possible because the parrallel )
				if (
					paginationControl.autoFetchNext &&
					nextIndex &&
					!requestPaginationControl.isLastDataSetRetrieved()
				) {
					// Container for the request parameters we generate, we need to generate all the requests first
					// before we make them to ensure we are correctly updating the pendingRequests.
					let newRequestParams: Object[] = [];
					while (requestPaginationControl.canMakeMoreRequests()) {
						// Check if the nextIndex has already been requested ? Increase by page size if it has to get the one after.
						while (
							requestPaginationControl.isIndexRequestedAlready(
								Number(nextIndex)
							)
						) {
							nextIndex = (Number(nextIndex) + nextPageSize).toString();

							// Increase pagination if exponential is on.
							if (paginationControl.exponential) {
								nextPageSize = Math.min(
									nextPageSize * 2,
									paginationControl.exponentialLimit
								);
							}
						}

						// We are going to make a request for this next index.. so add it to the pending request list.

						// Make another request, we'll use the requestPaginationControl to help us mange and find the next request thread number availible.
						let newRequestNumber: number =
							requestPaginationControl.requestThreadAdd(Number(nextIndex));

						// Make new pagination control based on the base one and update the next index to get.
						let nextPaginationControl: PaginationControlAppVO =
							Utils.clone(paginationControl);
						nextPaginationControl.nextIndex = Number(nextIndex);
						nextPaginationControl.pageSize = Number(nextPageSize);

						// Create a new set of request parameters, we'll clone teh parameters , so we don't share them across requests.
						let newRequestParam = {
							requestPath: requestPath,
							httpMethod: httpMethod,
							params: Utils.clone(params),
							body: body,
							paginationControl: nextPaginationControl,
							responseType: responseType,
							resultSet: resultSet,
						};

						// We'll add the request number to the params to allow the browser to run it concurrently to other otherwise identical calls.
						newRequestParam["params"]["requestThreadNumber"] = newRequestNumber;

						// Record the new request parameters.
						newRequestParams.push(newRequestParam);
					} // Repeat for all concurrent thread we need to generate to make up teh max concurrent threads.

					// Everything , ready.. make the requests.
					newRequestParams.forEach((requestParams) => {
						this.requestPaginatedAutoFetch(
							requestParams["requestPath"],
							requestParams["httpMethod"],
							requestParams["params"],
							requestParams["body"],
							requestParams["paginationControl"],
							requestParams["responseType"],
							requestParams["resultSet"],
							requestPaginationControl,
							cancelSignal
						);
					});
				} else {
					// We have not been given a next index to get, so must be last of the data.

					// Flag that teh last data set has been retrieved.
					requestPaginationControl.flagLastDatasetRetrieved();

					// Are we the last?
					if (requestPaginationControl.isRequestsAllComplete())
						resultSet.complete(); // complete the result set observable.
				}
			});

		// Return the observable we passed in ( for convenince )  which contains our steaming data results.
		return resultSet.asObservable();
	}

	/**
	 * Create an HTTP headers object which we can supply to the aHub as part of the pagination control system
	 *
	 * @param nextIndex                 Next index
	 * @param pageSize                  Size of the current page
	 * @param sortDirection             Sort direction of the data
	 *
	 * @returns                         Https Headers for the pagination control
	 */
	private paginationControlPaginationHeadersBuild(
		nextIndex: number,
		pageSize: number,
		sortDirection: string
	): HttpHeaders {
		// Header construction.
		let headers = new HttpHeaders();

		//Add in all the required http headers for the pagination
		headers = headers
			.append("hark-options-pagination-next-index", nextIndex.toString())
			.append("hark-options-pagination-page-size", pageSize.toString())
			.append("hark-options-pagination-sort-direction", sortDirection);

		//Return the headers object
		return headers;
	}

	/**
	 * Takes a params object and returns a query string params alphabetically sorted.
	 */
	private paramsObjectToQueryString(params?: Object): string {
		// Quick sanity check for null object.
		// Returns empty string to represent no params.
		if (!params) return "";

		// Constructing {key ,value} pairs array from object
		// Object properties have no order.. arrays do.
		// If the object property itself is an array of strings ( ie one property has multiple values ),
		// then we need to create an individual key value pair for each.
		let paramKeyPairArray: Object[] = [];

		// Loop through object properties.
		for (var paramkey in params) {
			// Does this property have multiple values ( ie an array )
			if (Array.isArray(params[paramkey])) {
				//Cast the values as an array
				let values: any[] = <any[]>params[paramkey];

				//For each value create the parameter pair and add them to the array
				values.forEach((value) =>
					paramKeyPairArray.push({ key: paramkey, value: value })
				);
			} else {
				//Create the parameter value pair and add it to the array
				paramKeyPairArray.push({ key: paramkey, value: params[paramkey] });
			}
		}
		// Now we sort our key value pairs, by key
		// and if we have mulitple identical then by value as well.
		this.paramSort(paramKeyPairArray);

		// Construct the URLSearchParams which will carry out any encoding.
		// polyfill included to allow use by safari < 11
		let urlParams: URLSearchParams = new URLSearchParams();
		for (let paramKeyPair of paramKeyPairArray) {
			urlParams.append(paramKeyPair["key"], paramKeyPair["value"]);
		}

		// Turn into string and return.
		return urlParams.toString();
	}

	private paramSort(paramKeyPairArray: Object[]) {
		// Safari < 11 no like sort for array less than 2 elements
		if (paramKeyPairArray.length < 2) {
			return;
		}

		// Safari < 11 no like arrow function
		paramKeyPairArray.sort(function (a, b) {
			// Get the keys and values as strings. This is to ensure
			// that all comparisons are done with string values.
			// This means 150 will actually equate to less and thefore
			// be before 95.
			let aKey: string = a["key"] === undefined ? "" : a["key"].toString();
			let bKey: string = b["key"] === undefined ? "" : b["key"].toString();
			let aValue: string =
				a["value"] === undefined ? "" : a["value"].toString();
			let bValue: string =
				b["value"] === undefined ? "" : b["value"].toString();

			if (aKey > bKey) {
				return 1;
			}
			if (aKey < bKey) {
				return -1;
			}
			if (aValue > bValue) {
				return 1;
			}
			if (aValue < bValue) {
				return -1;
			}
			return 0;
		});
	}

	/**
	 * We will add a test in to see if the object is a pagination
	 *
	 * @param page  Page we want to test for
	 */
	private isPaginationIdRequest(page: any): page is PaginationIdRequestVO {
		return (<PaginationIdRequestVO>page).paginationIdBreakdown !== undefined;
	}
}
