/**
 * ----------------------------------------------------------
 * Action Types
 * ----------------------------------------------------------
 */
import {
	SelectionIndexACatalogVO,
	SelectionReactionACatalogVO,
	UserIndexAHubVO,
} from "@harksolutions/ahub-web-services-types";
import { ACatalogDistributionAHubVO } from "@harksolutions/ahub-web-services-types";
import { AviewProductFullDataLegacy } from "app/modules/routes/aview/aview-legacy/aview-legacy-product/aview-legacy-product.component";
import { AviewProductFullDataMapped } from "app/modules/routes/aview/aview-mapped/aview-mapped-product/aview-mapped-product.component";
import { AViewVO } from "app/modules/routes/aview/valueObjects/aview.aview.vo";
import { ProductCardSummaryAViewVO } from "app/modules/routes/aview/valueObjects/product-card-summary.aview.vo";
import { ProductViewConfigAViewVO } from "app/modules/routes/aview/valueObjects/product-view-config.aview.vo";
import { ExportOutputAssetAHubVO } from "app/valueObjects/ahub/accounts/export-output-asset.ahub.vo";
import { PresignedUrlAHubVO } from "app/valueObjects/ahub/presigned-url.ahub.vo";
import { MapStorage } from "../map-storage.vo";
import {
	ActionAViewAssetFetch,
	ActionAViewProductFullDataVO,
	ActionAViewVO,
	ActionComponentACatalogDistribution,
	ActionComponentActiveGlbAssetPreview,
	ActionComponentProductCardSummary,
	ActionComponentPublicationEditionAssets,
	ActionComponentSelectionIndexes,
	ActionComponentSelectionReactionFilter,
	ActionComponentSelectionUserFilter,
	ActionLibraryViewConfigByEditionAHubVO as ActionProductViewConfigByEditionAHubVO,
	ActionLibraryViewConfigMapStorageByPublicationIdAHubVO as ActionProductViewConfigMapStorageByPublicationIdAHubVO,
	ActionLibraryViewConfigsAHubVO as ActionProductViewConfigsAHubVO,
} from "./types/aview.action-types";
import {
	ActionBoolean,
	ActionIdItem,
	ActionNumber,
	ActionNumberNumber,
	ActionNumberNumberString,
	ActionString,
	ActionStringNumber,
	ActionStringNumberArray,
	ActionStringString,
} from "./types/common.action-types";
import { ActionWork } from "./types/work.action-types";

/**
 * View Actions class
 */
export class AViewActions {
	static AVIEW_PUBLICATION_EDITION_PATH_URL_FETCH =
		"[AVIEW] AVIEW_PUBLICATION_EDITION_PATH_URL_FETCH";
	static AVIEW_PUBLICATION_EDITION_PATH_URL_SET =
		"[AVIEW] AVIEW_PUBLICATION_EDITION_PATH_URL_SET";
	static AVIEW_PUBLICATION_EDITION_ASSETS_FETCH =
		"[AVIEW] AVIEW_PUBLICATION_EDITION_ASSETS_FETCH";
	static AVIEW_PUBLICATION_EDITION_ASSETS_BY_IDS_FETCH =
		"[AVIEW] AVIEW_PUBLICATION_EDITION_ASSETS_BY_IDS_FETCH";
	static AVIEW_COMPONENT_SELECTION_INDEXES_FETCH =
		"[AVIEW] AVIEW_COMPONENT_SELECTION_INDEXES_FETCH";
	static AVIEW_SET = "[AVIEW] AVIEW_SET";
	static AVIEW_REMOVE = "[AVIEW] AVIEW_REMOVE";
	static AVIEW_CLEAR = "[AVIEW] AVIEW_CLEAR";
	static AVIEW_FETCH = "[AVIEW] AVIEW_FETCH";
	static AVIEW_COMPONENT_ID_SET = "[AVIEW] AVIEW_COMPONENT_ID_SET";
	static AVIEW_COMPONENT_DELETE = "[AVIEW] AVIEW_COMPONENT_DELETE";
	static AVIEW_COMPONENT_PRODUCT_COUNT_SET =
		"[AVIEW] AVIEW_COMPONENT_PRODUCT_COUNT_SET";
	static AVIEW_COMPONENT_SELECTED_PRODUCT_ID_SET =
		"[AVIEW] AVIEW_COMPONENT_SELECTED_PRODUCT_ID_SET";
	static AVIEW_COMPONENT_SELECTED_PRODUCT_VIEW_DATA_SET =
		"[AVIEW] AVIEW_COMPONENT_SELECTED_PRODUCT_VIEW_DATA_SET";
	static AVIEW_COMPONENT_SELECTED_CATEGORY_ID_SET =
		"[AVIEW] AVIEW_COMPONENT_SELECTED_CATEGORY_ID_SET";
	static AVIEW_COMPONENT_ASSETS_SET = "[AVIEW] AVIEW_COMPONENT_ASSETS_SET";
	static AVIEW_COMPONENT_ASSETS_APPEND =
		"[AVIEW] AVIEW_COMPONENT_ASSETS_APPEND";
	static AVIEW_COMPONENT_PUBLICATION_EDITION_SET =
		"[AHUB] AVIEW_COMPONENT_PUBLICATION_EDITION_SET";
	static AVIEW_COMPONENT_SELECTED_ACATALOG_DISTRIBUTION_SET =
		"[AVIEW] AVIEW_COMPONENT_SELECTED_ACATALOG_DISTRIBUTION_SET";
	static AVIEW_COMPONENT_ACTIVE_GLB_ASSET_PREVIEW_SET =
		"[AVIEW] AVIEW_COMPONENT_ACTIVE_GLB_ASSET_PREVIEW_SET";
	static AVIEW_COMPONENT_ACTIVE_GLB_ASSET_PREVIEW_REMOVE =
		"[AVIEW] AVIEW_COMPONENT_ACTIVE_GLB_ASSET_PREVIEW_REMOVE";
	static AVIEW_COMPONENT_SELECTION_INDEXES_SET =
		"[AVIEW] AVIEW_COMPONENT_SELECTION_INDEXES_SET";
	static AVIEW_COMPONENT_SELECTION_ID_SET =
		"[AVIEW] AVIEW_COMPONENT_SELECTION_ID_SET";
	static AVIEW_COMPONENT_PRODUCT_CARD_SUMMARY_SET =
		"[AVIEW] AVIEW_COMPONENT_PRODUCT_CARD_SUMMARY_SET";
	static AVIEW_COMPONENT_SELECTION_USER_FILTER_SET =
		"[AVIEW] AVIEW_COMPONENT_SELECTION_USER_FILTER_SET";
	static AVIEW_COMPONENT_SELECTION_USER_FILTER_REMOVE =
		"[AVIEW] AVIEW_COMPONENT_SELECTION_USER_FILTER_REMOVE";
	static AVIEW_COMPONENT_SELECTION_USER_FILTER_CLEAR =
		"[AVIEW] AVIEW_COMPONENT_SELECTION_USER_FILTER_CLEAR";
	static AVIEW_COMPONENT_SELECTION_REACTION_FILTER_SET =
		"[AVIEW] AVIEW_COMPONENT_SELECTION_REACTION_FILTER_SET";
	static AVIEW_COMPONENT_SELECTION_REACTION_FILTER_REMOVE =
		"[AVIEW] AVIEW_COMPONENT_SELECTION_REACTION_FILTER_REMOVE";
	static AVIEW_COMPONENT_SELECTION_REACTION_FILTER_CLEAR =
		"[AVIEW] AVIEW_COMPONENT_SELECTION_REACTION_FILTER_CLEAR";
	static AVIEW_COMPONENT_SELECTION_REACTION_CONSENSUS_FILTER_SET =
		"[AVIEW] AVIEW_COMPONENT_SELECTION_REACTION_CONSENSUS_FILTER_SET";
	static AVIEW_COMPONENT_SELECTION_REACTION_CONSENSUS_FILTER_REMOVE =
		"[AVIEW] AVIEW_COMPONENT_SELECTION_REACTION_CONSENSUS_FILTER_REMOVE";
	static AVIEW_PRODUCT_VIEW_CONFIG_SET =
		"[AVIEW] AVIEW_PRODUCT_VIEW_CONFIG_SET";
	static AVIEW_PRODUCT_VIEW_CONFIG_BY_EDITION_SET =
		"[AVIEW] AVIEW_PRODUCT_VIEW_CONFIG_BY_EDITION_SET";
	static AVIEW_PRODUCT_VIEW_CONFIGS_BY_PUBLICATION_ID_SET =
		"[AVIEW] AVIEW_PRODUCT_VIEW_CONFIGS_BY_PUBLICATION_ID_SET";
	static AVIEW_PRODUCT_VIEW_CONFIGS_CLEAR =
		"[AVIEW] AVIEW_PRODUCT_VIEW_CONFIGS_CLEAR";
	static AVIEW_FILTERED_PRODUCT_IDS_SET =
		"[AVIEW] AVIEW_FILTERED_PRODUCT_IDS_SET";
	static AVIEW_DISPLAY_SETTING_SET = "[AVIEW] AVIEW_DISPLAY_SETTING_SET";
	static AVIEW_FILTER_COUNT_SET = "[AVIEW] AVIEW_FILTER_COUNT_SET";
	static AVIEW_GLOBAL_SIDEBAR_STATE_ACTIVE_SET =
		"[AVIEW] AVIEW_GLOBAL_SIDEBAR_STATE_ACTIVE_SET";
	static AVIEW_EXPORT_CLIENT_LOGO_GET = "[AVIEW] AVIEW_EXPORT_CLIENT_LOGO_GET";
	static AVIEW_EXPORT_CLIENT_LOGO_SET = "[AVIEW] AVIEW_EXPORT_CLIENT_LOGO_SET";

	/**
	 * Fetches the path URL export specified version.
	 */
	static publicationEditionPathURLFetch(
		publicationId: number,
		edition: number
	): ActionNumberNumber {
		return {
			type: AViewActions.AVIEW_PUBLICATION_EDITION_PATH_URL_FETCH,
			number1: publicationId,
			number2: edition,
		};
	}

	/**
	 * Store the path URL export version.
	 */
	static publicationEditionPathURLSet(
		publicationId: number,
		publicationEditionPathUrl: PresignedUrlAHubVO
	): ActionIdItem<PresignedUrlAHubVO> {
		return {
			type: AViewActions.AVIEW_PUBLICATION_EDITION_PATH_URL_SET,
			idItem: { id: publicationId, item: publicationEditionPathUrl },
		};
	}

	static publicationEditionAssetsFetch(
		publicationId: number,
		editionNumber: number,
		componentId: string
	): ActionNumberNumberString {
		return {
			type: AViewActions.AVIEW_PUBLICATION_EDITION_ASSETS_FETCH,
			number1: publicationId,
			number2: editionNumber,
			string: componentId,
		};
	}

	/**
	 * Create the action type for the asset fetch by id's action
	 */
	static publicationEditionAssetsFetchByAssetIds(
		publicationId: number,
		editionNumber: number,
		componentId: string,
		assetIds: number[]
	): ActionAViewAssetFetch {
		return {
			type: AViewActions.AVIEW_PUBLICATION_EDITION_ASSETS_BY_IDS_FETCH,
			publicationId,
			editionNumber,
			componentId,
			assetIds,
		};
	}

	/**
	 * Store the AView.
	 */
	static aViewSet(aView: AViewVO): ActionAViewVO {
		return {
			type: AViewActions.AVIEW_SET,
			aView: aView,
		};
	}

	/**
	 * Clear AView by publicationId and edition
	 */
	static aViewRemove(
		publicationId: number,
		edition: number
	): ActionNumberNumber {
		return {
			type: AViewActions.AVIEW_REMOVE,
			number1: publicationId,
			number2: edition,
		};
	}

	/**
	 * Create a new action that will clear all of the aViews.
	 */
	static aViewClear(): ActionWork {
		return {
			type: AViewActions.AVIEW_CLEAR,
		};
	}

	/**
	 * Fetch the AView by publicationId and edition.
	 */
	static aViewFetch(
		publicationId: number,
		edition: number
	): ActionNumberNumber {
		return {
			type: AViewActions.AVIEW_FETCH,
			number1: publicationId,
			number2: edition,
		};
	}

	/**
	 * Set the component id for the component map
	 *
	 * @param componentId            Component id which this is in reference too
	 */
	static aViewComponentIdSet(componentId: string): ActionString {
		return {
			type: AViewActions.AVIEW_COMPONENT_ID_SET,
			string: componentId,
		};
	}

	/**
	 * Delete component map by id
	 *
	 * @param componentId            Component id which this is in reference to
	 */
	static aViewComponentDelete(componentId: string): ActionString {
		return {
			type: AViewActions.AVIEW_COMPONENT_DELETE,
			string: componentId,
		};
	}

	/**
	 * Set the selected product id for the given aview products component
	 *
	 * @param componentId            Component id which this is in reference too
	 * @param productCount           Number of products being displayed in this aview component
	 */
	static aViewComponentProductCountSet(
		componentId: string,
		productCount: number
	): ActionStringNumber {
		return {
			type: AViewActions.AVIEW_COMPONENT_PRODUCT_COUNT_SET,
			string: componentId,
			number: productCount,
		};
	}

	/**
	 * Set the selected product id for the given aview products component
	 *
	 * @param componentId            Component id which this is in reference too
	 * @param selectedProductId      Data set category selected product id which we want to set
	 */
	static aViewComponentSelectedProductIdSet(
		componentId: string,
		selectedProductId: number
	): ActionStringNumber {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTED_PRODUCT_ID_SET,
			string: componentId,
			number: selectedProductId,
		};
	}

	/**
	 * Set the selected product id for the given aview products component
	 *
	 * @param componentId            Component id which this is in reference too
	 * @param selectedProductId      Data set category selected product id which we want to set
	 */
	static aViewComponentSelectedProductViewDataSet(
		componentId: string,
		selectedProductViewData:
			| AviewProductFullDataLegacy
			| AviewProductFullDataMapped
	): ActionAViewProductFullDataVO {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTED_PRODUCT_VIEW_DATA_SET,
			componentId: componentId,
			aViewProductFullData: selectedProductViewData,
		};
	}

	/**
	 * Set the selected category id for the given aview products component
	 *
	 * @param componentId            Component id which this is in reference too
	 * @param selectedCategoryId     Selected category id which we want to set
	 */
	static aViewComponentSelectedCategoryIdSet(
		componentId: string,
		selectedCategoryId: number
	): ActionStringNumber {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTED_CATEGORY_ID_SET,
			string: componentId,
			number: selectedCategoryId,
		};
	}

	/**
	 * Set the content set id for the publication in this component
	 *
	 * @param componentId            Component id which this is in reference too
	 * @param assets           assets for (used to create) the publication
	 */
	static aViewComponentPublicationEditionAssetsSet(
		componentId: string,
		publicationId: number,
		edition: number,
		assets: ExportOutputAssetAHubVO[]
	): ActionComponentPublicationEditionAssets {
		return {
			type: AViewActions.AVIEW_COMPONENT_ASSETS_SET,
			componentId,
			publicationId,
			edition,
			assets: assets,
		};
	}

	/**
	 * Append the assets to the suppled component
	 *
	 * @param componentId      Component id which this is in reference too
	 * @param assets           assets for (used to create) the publication
	 */
	static aViewComponentPublicationEditionAssetsAppend(
		componentId: string,
		publicationId: number,
		edition: number,
		assets: ExportOutputAssetAHubVO[]
	): ActionComponentPublicationEditionAssets {
		return {
			type: AViewActions.AVIEW_COMPONENT_ASSETS_APPEND,
			componentId,
			publicationId,
			edition,
			assets: assets,
		};
	}

	/**
	 * Create an action to se the components publication and editions.
	 *
	 * @param componentId             The component who's publication and edition have changed.
	 * @param publicationId           The id of the publication now selected.
	 * @param edition                 The edition that has now been selected.
	 */
	static aViewComponentPublicationEditionSet(
		componentId: string,
		publicationId: number,
		edition: number
	): ActionNumberNumberString {
		return {
			type: AViewActions.AVIEW_COMPONENT_PUBLICATION_EDITION_SET,
			number1: publicationId,
			number2: edition,
			string: componentId,
		};
	}

	static aViewComponentActiveGlbAssetPreviewSet(
		componentId: string,
		activeGlbAssetPreview: number
	): ActionComponentActiveGlbAssetPreview {
		return {
			type: AViewActions.AVIEW_COMPONENT_ACTIVE_GLB_ASSET_PREVIEW_SET,
			componentId,
			activeGlbAssetPreview,
		};
	}

	static aViewComponentActiveGlbAssetPreviewRemove(
		componentId: string,
		activeGlbAssetPreview: number
	): ActionComponentActiveGlbAssetPreview {
		return {
			type: AViewActions.AVIEW_COMPONENT_ACTIVE_GLB_ASSET_PREVIEW_REMOVE,
			componentId,
			activeGlbAssetPreview,
		};
	}

	/**
	 * Set the library view config
	 */
	static productViewConfigSet(
		productViewConfigs: MapStorage<MapStorage<ProductViewConfigAViewVO>>
	): ActionProductViewConfigsAHubVO {
		//Constuct action with the specified interface
		return {
			type: AViewActions.AVIEW_PRODUCT_VIEW_CONFIG_SET,
			productViewConfigs: productViewConfigs,
		};
	}

	/**
	 * Set the library view config by dataset id set in the permanent part of the store
	 */
	static productViewConfigByEditionSet(
		productViewConfig: ProductViewConfigAViewVO,
		edition: number
	): ActionProductViewConfigByEditionAHubVO {
		//Constuct action with the specified interface
		return {
			type: AViewActions.AVIEW_PRODUCT_VIEW_CONFIG_BY_EDITION_SET,
			productViewConfig: productViewConfig,
			edition,
		};
	}

	/**
	 * Set the library view config by publication id set in the permanent part of the store
	 */
	static productViewConfigMapStorageByPublicationIdSet(
		productViewConfigMapStorage: MapStorage<ProductViewConfigAViewVO>,
		publicationId: number
	): ActionProductViewConfigMapStorageByPublicationIdAHubVO {
		//Constuct action with the specified interface
		return {
			type: AViewActions.AVIEW_PRODUCT_VIEW_CONFIGS_BY_PUBLICATION_ID_SET,
			productViewConfigMapStorage: productViewConfigMapStorage,
			publicationId: publicationId,
		};
	}

	/**
	 * Create an action to clear the library view config from the store.
	 *
	 */
	static productViewConfigClear(): ActionWork {
		// Construct the action.
		return {
			type: AViewActions.AVIEW_PRODUCT_VIEW_CONFIGS_CLEAR,
		};
	}

	/**
	 * Create an action to store the remaining product ids after filtering has been applied .
	 *
	 */
	static filteredProductIdsSet(
		componentId: string,
		filteredProductIds: number[]
	): ActionStringNumberArray {
		// Construct the action.
		return {
			type: AViewActions.AVIEW_FILTERED_PRODUCT_IDS_SET,
			string: componentId,
			numbers: filteredProductIds,
		};
	}

	/**
	 * Create an action to store view state of aView eg. Compare mode/ Detail View/ Standard.
	 *
	 */
	static displaySettingSet(
		displaySetting: "COMPARE" | "DETAIL" | "STANDARD"
	): ActionString {
		// Construct the action.
		return {
			type: AViewActions.AVIEW_DISPLAY_SETTING_SET,
			string: displaySetting,
		};
	}

	/**
	 * Create an action to store view state of aView eg. Compare mode/ Detail View/ Standard.
	 *
	 */
	static filterCountSet(
		componentId: string,
		filterCount: number
	): ActionStringNumber {
		// Construct the action.
		return {
			type: AViewActions.AVIEW_FILTER_COUNT_SET,
			string: componentId,
			number: filterCount,
		};
	}

	/**
	 * Create an action to store the remaining product ids after filtering has been applied .
	 *
	 */
	static globalSidebarStateActive(
		globalSidebarStateActive: boolean
	): ActionBoolean {
		// Construct the action.
		return {
			type: AViewActions.AVIEW_GLOBAL_SIDEBAR_STATE_ACTIVE_SET,
			boolean: globalSidebarStateActive,
		};
	}

	/**
	 * Create an action to set selected distribution for given component
	 */
	static componentSelectedACatalogDistributionSet(
		componentId: string,
		aCatalogDistribution: ACatalogDistributionAHubVO
	): ActionComponentACatalogDistribution {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTED_ACATALOG_DISTRIBUTION_SET,
			componentId,
			aCatalogDistribution,
		};
	}

	/**
	 * Action to get selection Indexes.
	 */
	static componentPublicationSelectionIndexesFetch(
		componentId: string,
		publicationId: number
	): ActionStringNumber {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTION_INDEXES_FETCH,
			string: componentId,
			number: publicationId,
		};
	}

	/**
	 * Action to set selection Indexes.
	 */
	static componentSelectionIndexesSet(
		componentId: string,
		selectionIndexes: SelectionIndexACatalogVO[]
	): ActionComponentSelectionIndexes {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTION_INDEXES_SET,
			componentId,
			selectionIndexes,
		};
	}

	static componentSelectionIdSet(
		componentId: string,
		selectionId: number
	): ActionStringNumber {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTION_ID_SET,
			string: componentId,
			number: selectionId,
		};
	}

	static componentProductCardSummarySet(
		componentId: string,
		productId: number,
		productCardSummary: ProductCardSummaryAViewVO
	): ActionComponentProductCardSummary {
		return {
			type: AViewActions.AVIEW_COMPONENT_PRODUCT_CARD_SUMMARY_SET,
			componentId,
			productId,
			productCardSummary,
		};
	}

	static componentSelectionUserFilterSet(
		componentId: string,
		userIndexes: UserIndexAHubVO[]
	): ActionComponentSelectionUserFilter {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTION_USER_FILTER_SET,
			componentId,
			userIndexes,
		};
	}

	static componentSelectionUserFilterRemove(
		componentId: string,
		userIndexesToRemove: UserIndexAHubVO[]
	): ActionComponentSelectionUserFilter {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTION_USER_FILTER_REMOVE,
			componentId,
			userIndexes: userIndexesToRemove,
		};
	}

	static componentSelectionUserFilterClear(componentId: string): ActionString {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTION_USER_FILTER_CLEAR,
			string: componentId,
		};
	}

	static componentSelectionReactionFilterSet(
		componentId: string,
		reactions: SelectionReactionACatalogVO[]
	): ActionComponentSelectionReactionFilter {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTION_REACTION_FILTER_SET,
			componentId,
			reactions,
		};
	}

	static componentSelectionReactionFilterRemove(
		componentId: string,
		reactions: SelectionReactionACatalogVO[]
	): ActionComponentSelectionReactionFilter {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTION_REACTION_FILTER_REMOVE,
			componentId,
			reactions,
		};
	}

	static componentSelectionReactionFilterClear(
		componentId: string
	): ActionString {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTION_REACTION_FILTER_CLEAR,
			string: componentId,
		};
	}

	static componentSelectionReactionConsensusFilterSet(
		componentId: string,
		filterValue: string
	): ActionStringString {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTION_REACTION_CONSENSUS_FILTER_SET,
			string1: componentId,
			string2: filterValue,
		};
	}

	static componentSelectionReactionConsensusFilterRemove(
		componentId: string,
		filterValue: string
	): ActionStringString {
		return {
			type: AViewActions.AVIEW_COMPONENT_SELECTION_REACTION_CONSENSUS_FILTER_REMOVE,
			string1: componentId,
			string2: filterValue,
		};
	}

	static exportClientLogoGet(exportId: number): ActionNumber {
		return {
			type: AViewActions.AVIEW_EXPORT_CLIENT_LOGO_GET,
			number: exportId,
		};
	}

	/**
	 * Create an action to set the client id.
	 *
	 * @param exportId 				The id of the export's logo we are setting.
	 * @param presignedUrl			The presigned URL to the client logo.
	 *
	 * @returns 					The action created.
	 */
	static exportClientLogoSet(
		exportId: number,
		presignedUrl: PresignedUrlAHubVO
	): ActionIdItem<PresignedUrlAHubVO> {
		return {
			type: AViewActions.AVIEW_EXPORT_CLIENT_LOGO_SET,
			idItem: { id: exportId, item: presignedUrl },
		};
	}
}
