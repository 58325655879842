import {
	Component,
	OnInit,
	Input,
	ViewChild,
	Output,
	EventEmitter,
} from "@angular/core";
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators,
} from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { BehaviorSubject } from "rxjs";

@Component({
	selector: "app-multiple-add-input",
	templateUrl: "./multiple-add-input.component.html",
	styleUrls: ["./multiple-add-input.component.css"],
})
export class MultipleAddInputComponent implements OnInit {
	@ViewChild("addInput") addInput;

	@Input() inputForm: UntypedFormGroup = this.formBuilder.group({
		formInput: ["", Validators.required],
	});

	@Input() inputLabel = "Add Input";
	@Input() list: string[];
	@Input() content: string = undefined;
	@Input() allowDragSort = false;
	@Input() listChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	@Output() listChagedData: EventEmitter<string[]> = new EventEmitter();

	/**
	 * Source value which we opened the dialogue with
	 */
	private sourceValue: string;

	constructor(private readonly formBuilder: UntypedFormBuilder) {}

	ngOnInit() {
		this.sourceValue = JSON.stringify(this.list);
	}

	// Add the input value to the data array
	multiAdd() {
		if (!this.list) {
			this.list = [];
		}

		// Empty input or Invalid, lets not add it
		if (
			!this.inputForm.controls.formInput.value ||
			!this.inputForm.controls.formInput.valid
		) {
			return;
		}

		this.list.push(this.inputForm.controls.formInput.value);
		this.inputForm.controls.formInput.setValue("");
		this.addInput.nativeElement.focus();
		this.inputForm.markAsPristine();

		//Re-evaluate if the data has changed
		this.valueChangedFromOpen();
	}

	/**
	 * Remove the input value to the data array
	 */
	removeFromList(item) {
		// Get the index of the item.
		const index = this.list.indexOf(item);

		//Remove the item from the list
		this.list.splice(index, 1);

		//Re-evaluate if the data has changed
		this.valueChangedFromOpen();
	}

	drop(event: CdkDragDrop<string[]>) {
		//Move the item within the list
		moveItemInArray(this.list, event.previousIndex, event.currentIndex);

		//Re-evaluate if the data has changed
		this.valueChangedFromOpen();
	}

	/**
	 * Evaluate if the data has changed
	 */
	valueChangedFromOpen() {
		//Is the data different to the source data supplied
		this.listChanged$.next(this.sourceValue !== JSON.stringify(this.list));

		//Fire that the list has changed
		this.listChagedData.emit(this.list);
	}
}
