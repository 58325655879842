import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from "@angular/core";
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { TreeStructure } from "../tree-structure.class";
import { TreeConfiguration, TreeSize, ViewboxPoint } from "../tree.component";

@Component({
	selector: "[app-subtree]",
	templateUrl: "./subtree.component.html",
	styleUrls: ["./subtree.component.css"],
})
@Hark()
export class SubtreeComponent implements OnInit, OnDestroy {
	/**
	 * The element representing the container for teh node of this subtree.
	 */
	@ViewChild("subTreeNode", { static: true }) subTreeNodeElement: ElementRef;

	/**
	 * The part of the tree this sub tree represents.
	 */
	@Input() tree: TreeStructure;

	/**
	 * Tree configuration setout by the host component
	 */
	@Input() treeConfig: TreeConfiguration;

	/**
	 * Can we edit?
	 */
	editable: boolean = true;

	/**
	 * Simple flag to indicate we have children.
	 */
	hasSubTrees: boolean = false;

	/**
	 * Was a simple boolean, changed to 0 to 1 to allow elegant fade ins.
	 * essentially still for visual showing the subtree or hiding leaing vertical ellipsis.
	 */
	subTreesDisplay: number = 0;

	/**
	 * Indicates if he subtrees were already being display before the current transition.
	 */
	subTreesPreviouslyDisplayed: boolean = false;

	/**
	 * This represents our sub trees which we will need to call to render our children.
	 */
	subTrees: TreeStructure[] = null;

	/**
	 * Set to true if any of the sub trees have a warning flag.
	 */
	subTreesHaveWarning: boolean = false;

	/**
	 * Cached sizes of sub trees.
	 */
	subTreesCachedDeltaSize: TreeSize[] = [];

	/**
	 * Used to indicate that a particular part of teh tree should not be animated.
	 * this is because as the tree resizes the collapsing or expanding part's parent
	 * do not move. But if we let the calclations take place, the fractional number cause a nasty shake.
	 */
	excludeTreeIdFromAnimation: string = "";

	/**
	 * Notification flag used to indicate when we are repositioning this subtree.
	 * True starting , false stopping.
	 */
	posTransitioning$: Subject<boolean> = new Subject<boolean>();

	/**
	 * Status indicator, records if transition animation is actually in progress.
	 */
	isPosTransitioning: boolean = false;

	/**
	 *  Trigger to stop the animation calculations.
	 */
	stopNotification$: Observable<boolean>;

	/**
	 * This is this subtrees absolute canvaus position.
	 */
	absoluteCanvasPosition: ViewboxPoint = null;

	/**
	 * Translation from current node to location of subtree drawing.
	 */
	nodeSubTreeTranslate = "translate(0 0)";

	/**
	 * Translation to top left most point of node rendering.
	 */
	nodeTopLeftTranslate = "translate(0 0)";

	/**
	 * Translation to top left most point of node rendering.
	 */
	nodeBottomLeftTranslate = "translate(0 0)";

	/**
	 * Translation to top right most point of node rendering.
	 */
	nodeTopRightTranslate = "translate(0 0)";

	/**
	 * Towards the top or left of the node according to orientation , plus half the level gap.
	 */
	nodeTopPlusHalfLevelGapTranslate = "translate(0 0)";

	/**
	 * Top right of the node , plus half the Vertical and Horizontag gaps.
	 */
	nodeLeftPlusHalfHGapTranslate = "translate(0 0)";

	/**
	 * 90 Rotate transform when in landscape.
	 */
	nodeRotateWhenLandscape = "";
	nodeRotateCCWhenLandscape = "";

	constructor(private viewContainerRef: ViewContainerRef) {}

	ngOnInit() {
		//Create the translation for the the expanidng children
		//The registration point for the node is now centered 0, 0.

		// Set the editable to whatever value is set in the static property.
		this.editable = this.treeConfig.editable;

		// Watch the editable property in the tree config.
		if (this.treeConfig.editable$)
			this.treeConfig.editable$
				.pipe(takeUntil(componentDestroyStream(this)))
				.subscribe((isEditable) => (this.editable = isEditable));

		// this.nodeSubTreeTranslate = this.treeConfig.treeLandscape ? "translate(" + ((this.treeConfig.nodeWidth / 2) + (this.treeConfig.nodeLevelGap / 2)) + " 0)" : "translate(0 " + this.treeConfig.nodeHeight / 2 + ")";
		this.nodeSubTreeTranslate = this.treeConfig.treeLandscape
			? "translate(" + this.treeConfig.nodeWidth / 2 + " 0)"
			: "translate(0 " + this.treeConfig.nodeHeight / 2 + ")";
		this.nodeTopLeftTranslate =
			"translate(-" +
			this.treeConfig.nodeWidth / 2 +
			" -" +
			this.treeConfig.nodeHeight / 2 +
			")";
		this.nodeBottomLeftTranslate =
			"translate(-" +
			this.treeConfig.nodeWidth / 2 +
			" " +
			this.treeConfig.nodeHeight / 2 +
			")";
		this.nodeTopRightTranslate =
			"translate(" +
			this.treeConfig.nodeWidth / 2 +
			" -" +
			this.treeConfig.nodeHeight / 2 +
			")";
		this.nodeTopPlusHalfLevelGapTranslate = this.treeConfig.treeLandscape
			? "translate(-" +
			  (this.treeConfig.nodeWidth / 2 + this.treeConfig.nodeLevelGap / 2) +
			  " 0)"
			: "translate(0 -" +
			  (this.treeConfig.nodeHeight / 2 + this.treeConfig.nodeLevelGap / 2) +
			  ")";
		this.nodeLeftPlusHalfHGapTranslate =
			"translate(-" +
			(this.treeConfig.nodeWidth / 2 + this.treeConfig.nodeSiblingGap / 2) +
			" 0)";
		this.nodeRotateWhenLandscape = this.treeConfig.treeLandscape
			? " rotate(90)"
			: "";
		this.nodeRotateCCWhenLandscape = this.treeConfig.treeLandscape
			? " rotate(270)"
			: "";

		// Record our part of the tree.
		this.subTrees = this.tree.subTrees;

		// Set the display of the children
		this.hasSubTrees = this.tree.subTrees.length > 0;

		// Always start with subtree hidden when adding to screen for nice effect.
		this.subTreesDisplay = 0;
		this.subTreesPreviouslyDisplayed = false;

		// Watch for requests for us to update our position.
		this.tree.posCalc$
			.asObservable()
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((excludeTree) => {
				this.excludeTreeIdFromAnimation = excludeTree;

				// Recheck for children.
				this.hasSubTrees = this.tree.subTrees.length > 0;
				this.posTransitioning$.next(true); // Trigger annimation.

				// Make sure our node area is updated, assuming we have a absolute postion to use.
				if (this.absoluteCanvasPosition != null) this.nodeViewboxAreaUpdate();
			});

		// Watch for when a position transformation is triggered.
		this.posTransitioning$
			.asObservable()
			.pipe(
				takeUntil(componentDestroyStream(this)),
				filter((isStarting) => {
					return isStarting == true;
				})
			) // We are starting.
			.subscribe((isStarting) => {
				this.positionTransitionStart();
			});

		this.stopNotification$ = this.posTransitioning$.asObservable().pipe(
			takeUntil(componentDestroyStream(this)),
			filter((isStarting) => {
				return isStarting == false;
			})
		);

		// Watch for changes in our parents absolute position.. assuming we have a parent, we wont if we are root !
		this.tree.absolutePosition$
			.asObservable()
			.pipe(
				takeUntil(componentDestroyStream(this)),
				filter((absoluteCanvasPosition) => absoluteCanvasPosition != null)
			)
			.subscribe((absoluteCanvasPosition) => {
				this.absoluteCanvasPositionUpdate(absoluteCanvasPosition);
				this.nodeViewboxAreaUpdate();
			});

		// As we have just been added, well have the tree recalculate sizes
		this.tree.treeComponent.debouncedTreeStructureSizeCalc();

		// We'll need to resposition ourself to any change in location.
		this.repositionSelf();

		// Observe the node for content changes which we want to reflect in our display.
		this.tree.nodeChanged$
			.asObservable()
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((signal) => {
				this.subTreesHaveWarning = this.subTreesHasWarningCheck();
			});

		// check our sub tress for warnings - imemdiately as well.
		this.subTreesHaveWarning = this.subTreesHasWarningCheck();
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * Returns true if any of the sub tress have a warning flag in their node info.
	 */
	subTreesHasWarningCheck(): boolean {
		// No subtree - no warning !
		if (!this.hasSubTrees) return false;

		let subTreeWarning: boolean = false;
		TreeStructure.applyFunctionSubTreesOnly(
			this.tree,
			(tree) => {
				subTreeWarning = subTreeWarning || tree.node.hasWarning;
			},
			true
		);
		return subTreeWarning;
	}

	/**
	 * Called when a mouse event indicates we are about to start dragging the subtree.
	 */
	prepareForClassMove(mouseEvent: MouseEvent) {
		// We won't proceed if the tree is not editable.
		if (this.editable) {
			this.tree.treeComponent.treeDragStart(this.tree, mouseEvent);
		}
	}

	/****************************************************************************
	 * TREE DISPLAY AND ANIMATION.
	 *****************************************************************************/

	private absoluteCanvasPositionUpdate(absoluteCanvasPosition: ViewboxPoint) {
		// Record our new canvas position which will be used for our node area calcs.
		this.absoluteCanvasPosition = absoluteCanvasPosition;

		// Tell our children their new absolute postions.
		for (
			let subTreeIndex: number = 0;
			subTreeIndex < this.tree.subTrees.length;
			subTreeIndex++
		) {
			// Whats our subTree new absolute position? ( based on ours)
			let subTreeAbsolutePosition: ViewboxPoint = this.treeConfig.treeLandscape
				? {
						x:
							absoluteCanvasPosition.x +
							(this.treeConfig.nodeWidth + this.treeConfig.nodeLevelGap),
						y:
							absoluteCanvasPosition.y +
							this.subTreeChildDeltaSize(subTreeIndex, true).height,
						scale: absoluteCanvasPosition.scale,
				  }
				: {
						x:
							absoluteCanvasPosition.x +
							this.subTreeChildDeltaSize(subTreeIndex, true).width,
						y:
							absoluteCanvasPosition.y +
							(this.treeConfig.nodeHeight + this.treeConfig.nodeLevelGap),
						scale: absoluteCanvasPosition.scale,
				  };

			// Tell the kid.
			this.tree.subTrees[subTreeIndex].absolutePosition$.next(
				subTreeAbsolutePosition
			);
		}
	}

	/**
	 * Update the recorded nodeViewbox Area based on our absolute canvas postion.
	 */
	private nodeViewboxAreaUpdate() {
		// Update the position covered by the node part of this subtree
		this.tree.nodeViewboxArea = {
			left: this.absoluteCanvasPosition.x - this.treeConfig.nodeWidth / 2,
			top: this.absoluteCanvasPosition.y,
			width: this.treeConfig.nodeWidth,
			height: this.treeConfig.nodeHeight,
		};
	}

	/**
	 * Triggers repositioning of ourselves.
	 */
	repositionSelf() {
		TreeStructure.reposition(this.tree);
	}

	/**
	 * Triggers the repositioning of this trees ancestors.
	 * Used when this tree changes its size, as all the ancestors will be effceted.
	 */
	repositionAncestors(tree: TreeStructure) {
		if (tree.parentTree) {
			TreeStructure.repositionExcluding(tree.parentTree, tree.id);
			this.repositionAncestors(tree.parentTree);
		}
	}

	/**
	 *  Has the visual component update its position calculations to carry out visual animation of position.
	 */
	positionTransitionStart() {
		// Set the new target and reset the time to get there.
		this.tree.sizeDisplayedStart = this.tree.sizeDisplayed;

		let nowDate = new Date();
		let nowMs = nowDate.getTime();
		this.tree.sizeDisplayedTransitionStart = nowMs;

		// If we are already transitioning, no need to subscribe again.
		if (this.isPosTransitioning) return;

		// Transition triggered, start incremental updates.
		this.isPosTransitioning = true;

		this.tree.treeComponent.animationHeartbeat$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				takeUntil(this.stopNotification$)
			)
			.subscribe((update) => {
				this.positionTransitingUpdate();
			});
	}

	// Carries out the positiong transitioning calculation to work out the
	// size of the tree at this point in the transition.
	positionTransitingUpdate() {
		// We dont want the added items to slide in ...
		// But because trying to stop the animation for some parts of the tree and not others
		// is a minefield.. (feel free to have a go) .. we'll split the dsiplay animation
		// and the move animation into two parts, so at teh point of display its already in position.

		// Whats the difference between where we started from and where we want to end up. ( Collapse or expansion of the size of the sub tree )
		let deltaVector: TreeSize = TreeSize.subtract(
			this.tree.size,
			this.tree.sizeDisplayedStart
		);

		// We want to have travelled a proportion of this distance, a fraction , based on the time.
		let nowDate = new Date();
		let nowMs = nowDate.getTime();
		let ellapsedProportion: number =
			(nowMs - this.tree.sizeDisplayedTransitionStart) / TreeStructure.TRANTIME;

		// We can not be beyond the end of the transition. ie 1.
		ellapsedProportion = Math.min(1, ellapsedProportion);

		// Set the displayChildren ( opacity ) to the linear progress, between 0.5 and 1 ( so it happens after translation.)
		// If we are changing the dsiplay state from the previous one.
		let subTreesTargetDisplay: boolean =
			this.tree.childrenShow && this.hasSubTrees;
		if (!this.subTreesPreviouslyDisplayed && subTreesTargetDisplay)
			// hidden , want to display , fade in.
			this.subTreesDisplay = Math.max(ellapsedProportion - 0.5, 0) * 2.0;
		else if (this.subTreesPreviouslyDisplayed && !subTreesTargetDisplay)
			// displayed, want to hide , fade out.
			this.subTreesDisplay = 1 - Math.max(ellapsedProportion - 0.5, 0) * 2.0;
		else if (this.subTreesPreviouslyDisplayed && subTreesTargetDisplay)
			// displayed, want to display ??? ( inturrupted animation )
			this.subTreesDisplay = Math.max(
				this.subTreesDisplay,
				Math.max(ellapsedProportion - 0.5, 0) * 2.0
			);
		// Fade in if not already.
		// Hidden , want to hide ??? ( inturrupted animation )
		else
			this.subTreesDisplay = Math.min(
				this.subTreesDisplay,
				1 - Math.max(ellapsedProportion - 0.5, 0) * 2.0
			); // Fade out in not already.

		// Because we are being a smart arse, rather than any old linear movement, we want it to ease in and out.
		// So we'll use a cos wave ( adjusted to go between 0 - 1) to move our linear proportion to a eased one.
		// We are transitioning over teh first part of the animation 0 to 0.5;
		let easedProportion: number =
			(Math.cos(
				Math.min(ellapsedProportion * 2.0, 1.0) * 180.0 * (Math.PI / 180.0) +
					Math.PI
			) +
				1.0) /
			2.0;

		// Apply the eased proportion to the delta vector and add to the orginal starting point to get
		// our current focused location.
		this.tree.sizeDisplayed = TreeSize.add(
			this.tree.sizeDisplayedStart,
			TreeSize.multiple(deltaVector, easedProportion)
		);

		// Check for end of transition.
		if (ellapsedProportion >= 1) {
			this.transitionEnd();
			return;
		}
	}

	// Ends ths current transition, move positions directly.
	transitionEnd() {
		this.posTransitioning$.next(false);
		this.isPosTransitioning = false;

		this.tree.sizeDisplayed = this.tree.size;
		this.tree.sizeDisplayedStart = this.tree.size;
		this.tree.sizeDisplayedTransitionStart = 0;

		// If we have reached the end of the transition, and we are going to show children,
		// then display them ( if we have them .)
		if (this.tree.childrenShow && this.hasSubTrees) {
			this.subTreesDisplay = 1;
			this.subTreesPreviouslyDisplayed = true;
		} else {
			this.subTreesDisplay = 0;
			this.subTreesPreviouslyDisplayed = false;
		}
	}

	// Returns the delta postion relative to this trees center
	// to the child subtree with the given index. ( For spacing out the child sub trees )
	// Depending on if the tree layout is portrait (X delta) or landscape (y delta) the delta position will change axis.
	subTreeChildDeltaSize(subTreeIndex: number, useFinal?: boolean): TreeSize {
		return this.subTreeDeltaSize(this.tree, subTreeIndex, useFinal);
	}

	/**
	 * Returns the translate instruction for svg for the positioning of a child of the subtree.
	 * NOTE: There is a temptation here to simply carry out calculations based on portrait,
	 * then simply swap dimensions, but because the nodes themselves are not necessary equal width
	 * and height the calculations for size an position change if the tree rotates as the nodes remain
	 * in thier original aspect.
	 **/
	subTreeTranslate(subTreeIndex: number): string {
		// Note that the gaps between level / siblings , when portrait the level gap is added to the vertical, when landscape the level gap is added to the horizontal.
		//return this.treeConfig.treeLandscape ? "translate(" + (this.treeConfig.nodeWidth / 2 + this.treeConfig.nodeLevelGap) + " " + this.subTreeChildDeltaSize(subTreeIndex) + ")" : "translate(" + this.subTreeChildDeltaSize(subTreeIndex) + " " + (this.treeConfig.nodeHeight / 2 + this.treeConfig.nodeLevelGap) + ")";
		return this.treeConfig.treeLandscape
			? "translate(" +
					(this.treeConfig.nodeWidth / 2 + this.treeConfig.nodeLevelGap) +
					" " +
					this.subTreeChildDeltaSize(subTreeIndex).height +
					")"
			: "translate(" +
					this.subTreeChildDeltaSize(subTreeIndex).width +
					" " +
					(this.treeConfig.nodeHeight / 2 + this.treeConfig.nodeLevelGap) +
					")";
	}

	// For a given tree structure and subtree index of a tree below this one,
	// calculates the X or Y center offset ( dependingon orientation of the tree) of the subtree to allow it to fit alongside
	// the other subtrees. Remembering that 0 is the center line.
	// If use final is set, we use the final node positions for our calculations. Not the
	// displayed ones which change during the animation.
	subTreeDeltaSize(
		tree: TreeStructure,
		subTreeIndex: number,
		useFinal?: boolean
	): TreeSize {
		// Default to using the displayed positions, not the final end of animation positions.
		if (useFinal == null) useFinal = false;

		// We are using the widths or heights as identified by the tree, so this function requires
		// these to be up to date. We could go down and calculate all the widths or heights at this point
		// ( but this seems wasteful and unnecessary looping ??)

		// Start from the left or top side of this tree. Which will be -ve half a width.
		// Add in our gap.
		let resultDeltaSize: TreeSize = this.treeConfig.treeLandscape
			? {
					width: 0,
					height: useFinal
						? this.treeConfig.nodeSiblingGap - tree.size.height / 2
						: this.treeConfig.nodeSiblingGap - tree.sizeDisplayed.height / 2,
			  }
			: {
					width: useFinal
						? this.treeConfig.nodeSiblingGap - tree.size.width / 2
						: this.treeConfig.nodeSiblingGap - tree.sizeDisplayed.width / 2,
					height: 0,
			  };

		// If we have no subtrees , or the the subtree Index is less than 0  , return left / top most position and report.
		// Should not happen.
		if (tree.subTrees.length < 1 || subTreeIndex < 0) {
			console.log("DEV NOTE: TREE: Asking for subtree position out of bounds.");
			return resultDeltaSize;
		}

		// If we are asking for a subtree beyond the number we have , return right / bottom  most edge of tree and report.
		// Should not happen.
		if (subTreeIndex >= tree.subTrees.length) {
			console.log("DEV NOTE: TREE: Asking for subtree position out of bounds.");
			return useFinal
				? TreeSize.add(resultDeltaSize, tree.size)
				: TreeSize.add(resultDeltaSize, tree.sizeDisplayed);
		}

		// We will use the cached value if it matches the currently excluded item
		// We exclude parents of teh the tree node being expanded or contracted as these
		// dont actually change position. ( whatch the animation , its true.)
		// This saves animating unmoving trees, reducing shaking.
		if (tree.subTrees[subTreeIndex].id == this.excludeTreeIdFromAnimation) {
			if (this.subTreesCachedDeltaSize.length > subTreeIndex) {
				if (this.subTreesCachedDeltaSize[subTreeIndex] != undefined) {
					return this.subTreesCachedDeltaSize[subTreeIndex];
				}
			}
		}

		// Now add all the widths or heights of the subtrees before the subtree we are interested in.
		let otherSubTreeIndex: number = 0;
		if (useFinal) {
			while (otherSubTreeIndex < subTreeIndex) {
				resultDeltaSize = TreeSize.add(
					resultDeltaSize,
					tree.subTrees[otherSubTreeIndex].size
				);
				otherSubTreeIndex++;
			}
		} else {
			while (otherSubTreeIndex < subTreeIndex) {
				resultDeltaSize = TreeSize.add(
					resultDeltaSize,
					tree.subTrees[otherSubTreeIndex].sizeDisplayed
				);
				otherSubTreeIndex++;
			}
		}

		// Finally add half the width or height of the subtree we are interested in.
		resultDeltaSize = useFinal
			? TreeSize.add(
					resultDeltaSize,
					TreeSize.multiple(tree.subTrees[subTreeIndex].size, 0.5)
			  )
			: TreeSize.add(
					resultDeltaSize,
					TreeSize.multiple(tree.subTrees[subTreeIndex].sizeDisplayed, 0.5)
			  );

		// Cache the value.
		this.subTreesCachedDeltaSize[subTreeIndex] = resultDeltaSize;

		return resultDeltaSize;
	}

	/***********************************************************************************
	 * USER INTERACTION / CONTROL HANDLING
	 ************************************************************************************/

	/**
	 * Called when the user has selected a part of the tree, by clicking on a node.
	 * This is for internal purposes, the control / selection of the contents
	 * is handled by the the node wrapping component, supplied to the tree.
	 * This is different to the add / delete, which have to use methods supplied to the tree
	 */
	selectSubtreeClick() {
		this.tree.treeComponent.treeSubTreeSelect(this.tree);
	}

	/**
	 * Called when the user has requeted a new sub tree (node) to be added to this one.
	 * We will call our parent Tree component to handle, they will use the configured add node function to complete.
	 */
	addNodeClick() {
		this.tree.treeComponent.treeAddToNode(this.tree.node);
	}

	/**
	 * Called when the user has requeted the node to be deleted.
	 * We will call our parent Tree component to handle, they will use the configured node function to complete.
	 */
	deleteNodeClick() {
		this.tree.treeComponent.treeDeleteNode(this.tree.node);
	}

	/**
	 * Called when the user has requeted the node to be edited.
	 * We will call our parent Tree component to handle, they will use the configured node function to complete.
	 */
	editNodeClick() {
		this.tree.treeComponent.treeEditNode(this.tree.node);
	}

	toggleChildren() {
		// Toggeling the hiding our our children.
		this.tree.childrenShow = !this.tree.childrenShow;

		// Recalculate tree sizes / layout.
		this.tree.treeComponent.debouncedTreeStructureSizeCalc();

		// Need to recalculate myself, but exclude animating my children.
		//TreeStructure.repositionExcluding(this.tree, this.tree.id);

		// We'll need to resposition ourself to any change in location.
		this.repositionSelf();

		// Trigger respositioning animations of ancestors, using special exclusion
		// list to prevent shaking of our parents.
		this.repositionAncestors(this.tree); // Have our ancestors reposition.
	}

	/**
	 * Allows the user to toggle the display of a whole section of the tree.
	 */
	toggleChildrenRecursive() {
		// Toggeling the hiding our our children.
		if (this.tree.childrenShow)
			TreeStructure.applyFunctionSubTrees(this.tree, (tree) => {
				tree.childrenShow = false;
			});
		else
			TreeStructure.applyFunctionSubTrees(this.tree, (tree) => {
				tree.childrenShow = true;
			});

		// Recalculate tree sizes / layout.
		this.tree.treeComponent.debouncedTreeStructureSizeCalc();

		// We'll need to resposition ourself to any change in location.
		this.repositionSelf();

		// Trigger respositioning animations of ancestors, using special exclusion
		// list to prevent shaking of our parents.
		this.repositionAncestors(this.tree); // Have our ancestors reposition.
	}

	/**
	 * Hides everything accept this tree and its parents.
	 */
	showLineage() {
		// Hide everything.
		TreeStructure.applyFunctionEverywhere(this.tree, (tree) => {
			tree.childrenShow = false;
		});

		// Show Parents
		TreeStructure.applyFunctionAncestors(this.tree, (tree) => {
			tree.childrenShow = true;
		});

		// But not my children.. ( switched on by above function.)
		this.tree.childrenShow = false;

		// Recalculate tree sizes / layout.
		this.tree.treeComponent.debouncedTreeStructureSizeCalc();

		// everyone needs to check and move if required.
		this.tree.treeComponent.treeStructureRespositionAll();
	}
}
