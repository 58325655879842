import { Component, OnInit, Input } from "@angular/core";
import { ClientLogEntryAHubVO } from "app/valueObjects/ahub/library/client-log-entry.ahub.vo";

@Component({
	selector: "app-client-log-entry",
	templateUrl: "./client-log-entry.component.html",
	styleUrls: ["./client-log-entry.component.scss"],
})
export class ClientLogEntryComponent implements OnInit {
	activeToggle = false;

	@Input()
	public entry: ClientLogEntryAHubVO = null;

	constructor() {
		// This is intentional
	}

	ngOnInit() {}

	clicked() {
		this.activeToggle = !this.activeToggle;
	}
}
