import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd, Router } from "@angular/router";
import { saveAs as importedSaveAs } from "file-saver";
import { DialogService } from "modules/common/dialogs/dialog.service";
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, combineLatest } from "rxjs";
import {
	delay,
	distinctUntilChanged,
	filter,
	flatMap,
	map,
	takeUntil,
} from "rxjs/operators";
import {
	actionsQueue,
	clipBoardCopyText,
	fileDownloadConfigs,
	requestActionStatuses,
	sessionToken,
	sessionUserId,
	sessionUserSessionCredentials,
} from "selector/app.selector";
import {
	viewComponentStateFormStatesDirty,
	viewFormStateFormStatesDirty,
} from "selector/view/view-page-state.selector";
import { ActionQueueService } from "services/actions/action-queue.service";
import { PermissionsService } from "services/permissions/permissions.service";
import { RequestActionMonitorService } from "services/request-action-monitor/request-action-monitor.service";
import { RouterService } from "services/router/router.service";
import { StoreAccess } from "store/store-access";
import { environment } from "../environments/environment";
import { NotificationGeneratorService } from "./services/notification-generator/notification-generator.service";
import { AHubActions } from "./store/actions/ahub.actions";
import { AppActions } from "./store/actions/app.actions";
import { ToolIDEnum } from "./valueObjects/view/tool-id.view.enum";

/**
 * We have created a new function called indexOf( value ) on the SVG animated string because
 * when we translate the portal and display SVG's we get many many errors. This is because
 * the class doesn't have the function required. So we give it the function.
 */
declare global {
	interface SVGAnimatedString {
		indexOf(value): number;
	}
}

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"],
})
@Hark()
export class AppComponent implements OnInit, OnDestroy {
	public static browserSupported = new BehaviorSubject<boolean>(true);

	@ViewChild("BrowserUnsupportedNotice") BrowserUnsupportedNotice;
	/**
	 * Get an observable stream for the download URL
	 */
	fileDownloadConfig$ = StoreAccess.dataGetObvs(fileDownloadConfigs);

	/**
	 * Clipboad copy text
	 */
	clipboardCopyText$ = StoreAccess.dataGetObvs(clipBoardCopyText);

	userIsLoggedIn$ = StoreAccess.dataGetObvs(sessionUserId).pipe(
		takeUntil(componentDestroyStream(this)),
		/**
		 * delay(0) Added in order to prevent ExpressionChangedAfterChecked errors!
		 * Followed advice from: https://blog.angular-university.io/angular-debugging/
		 */
		delay(0),
		map((userId) => userId !== undefined)
	);
	/**
	 * Queue service for the upload + actions
	 */
	actionQueueService: ActionQueueService = undefined;
	supportSiteUrlWithSessionToken: string;

	loadingRouteConfig: boolean;

	constructor(
		private readonly notificationGenertorService: NotificationGeneratorService,
		private readonly viewContainerRef: ViewContainerRef,
		private readonly dialogService: DialogService,
		private readonly permissionsService: PermissionsService,
		private readonly requestActionMonitor: RequestActionMonitorService,
		private readonly routerService: RouterService,
		public readonly snackBar: MatSnackBar,
		private readonly http: HttpClient,
		private readonly router: Router,
		private readonly cookieService: CookieService
	) {
		//Set the dialogue Service
		this.dialogServiceSetup();

		/**
     * Detect router events to display loading screen

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        // Delay added for smooth page transition
        //  setTimeout(r=>{
        //    document.querySelector('body').classList.add('loaded');
        //  }, 300)
      }
      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
      }
    });
    */

		// Lets look out for navigation to aview
		// If so we'll set the 'toolId' to AVIEW so that we can hide all portal sidenav
		// If not, lets set the toolId back to AHUB
		router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((navigationEndEvent: NavigationEnd) => {
				if (navigationEndEvent && navigationEndEvent.url.startsWith("/aview")) {
					// Make the call to switch to the aView tool.
					StoreAccess.dispatch(AppActions.currentToolIdSet(ToolIDEnum.AVIEW));
				} else {
					// Make the call to switch to the aView tool.
					StoreAccess.dispatch(AppActions.currentToolIdSet(ToolIDEnum.AHUB));
				}
			});
	}

	/**
	 * BROWSER SUPPORT DETECTION.
	 * Set supported browser versions below:
	 * (Browsers below these limits will not be supported and a notice will display. IE is set to IE10 or below).
	 */
	chromeVersion: any = 70;
	firefoxVersion: any = 70;
	safariVersion: any = 500;
	operaVersion: any = 64;

	browserNoticeMessage = "Your browser is not supported.";
	// Show browser support notice.
	browserNotice() {
		this.snackBar.open(this.browserNoticeMessage);
	}
	// Browser version support check from userAgent.
	isSupportedBrowser(browser, supportedVersion, userAgent) {
		let str = "(?:";
		str += browser;
		str += "/)([0-9]+)";
		const regEx = new RegExp(str);
		const currentVersion: any = userAgent
			.match(regEx)[0]
			.replace(/[^0-9]/g, "");
		if (currentVersion < supportedVersion) {
			this.browserNotice();
		}
	}
	// Run support checks for all browsers.
	supportedBrowserCheck() {
		const userAgent = navigator.userAgent;
		const msIE = userAgent.indexOf("MSIE ") > -1;
		const isOpera = userAgent.toLowerCase().indexOf("opr") > -1;
		const trident = userAgent.indexOf("Trident/");
		const edge = userAgent.indexOf("Edge/");

		if (isOpera) {
			AppComponent.browserSupported.next(false);
		}
		// IE
		if (msIE) {
			AppComponent.browserSupported.next(false);
		}
		if (trident > 0) {
			// IE 11
			AppComponent.browserSupported.next(false);
		}
		if (edge > 0) {
			// Edge
			AppComponent.browserSupported.next(false);
		}
	}

	ngOnInit() {
		// Run the browser support checks and display notice if unsupported.
		this.supportedBrowserCheck();

		// Set up the index of function on the SVG animated string class as stated above.
		SVGAnimatedString.prototype.indexOf = function (value) {
			return -1;
		};

		//Get the router service to start
		this.routerService.routeWatcherStart();

		//Setup the file download
		this.downloadFileURLSetup();
		this.clipboardCopySetup();

		//Create a new queue service, it will sort itself out!
		this.actionQueueService = new ActionQueueService(this.requestActionMonitor);

		StoreAccess.dataGetObvs(sessionUserSessionCredentials)
			.pipe(
				takeUntil(componentDestroyStream(this)),
				filter(
					(sessionCredentials) =>
						sessionCredentials !== undefined && sessionCredentials !== null
				)
			)
			.subscribe((sessionCredentials) => {
				// Lets go grab a sessionToken so we could share our (authenticated) session with other sites
				// (e.g. the support wordpress site)
				StoreAccess.dispatch(
					AHubActions.sessionTokenFetch(sessionCredentials.sessionId)
				);
			});

		StoreAccess.dataGetObvs(sessionToken)
			.pipe(
				takeUntil(componentDestroyStream(this)),
				filter(
					(sessionToken) => sessionToken !== undefined && sessionToken !== null
				)
			)
			.subscribe((sessionToken) => {
				this.cookieService.set(
					"sessionToken",
					sessionToken,
					30,
					"/",
					".ahub.cloud"
				);
			});

		//Setup the refresh loss prevention
		this.refreshLossPreventionSetup();

		// ************************ SHOW MESSAGE WHILE LAZY LOADING MODULES? ************************
		// this.router.events.subscribe(event => {
		//   if (event instanceof RouteConfigLoadStart) {
		//     this.loadingRouteConfig = true;
		//   } else if (event instanceof RouteConfigLoadEnd) {
		//     this.loadingRouteConfig = false;
		//   }
		// });
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * Setup the refresh loss prevention for the site
	 */
	private refreshLossPreventionSetup() {
		//Subscribe to the following things all of which if they are the case we want to prevent a page reload
		// requests - to check for active uploads
		// queued action - prevents refresh from un-executed actions
		// dirty components - prevents loss from componenets
		// dirty forms - prevents loss from forms
		combineLatest([
			StoreAccess.dataGetObvs(requestActionStatuses).pipe(
				map((all) =>
					all
						? all.filter((a) => a.upload !== undefined && a.upload !== null)
						: []
				)
			),
			StoreAccess.dataGetObvs(actionsQueue).pipe(
				map((queue) => (queue ? queue.length : 0))
			),
			StoreAccess.dataGetObvs(viewComponentStateFormStatesDirty).pipe(
				map((dirtyComps) => (dirtyComps ? dirtyComps.length > 0 : false))
			),
			StoreAccess.dataGetObvs(viewFormStateFormStatesDirty).pipe(
				map((dirtyForms) => (dirtyForms ? dirtyForms.length > 0 : false))
			),
		]).subscribe(
			([
				uploadRequestActionStatuses,
				actionQueueLength,
				dirtyComps,
				dirtyForms,
			]) => {
				//Are we preventing reloads for this data?
				let preventReload = actionQueueLength > 0 || dirtyComps || dirtyForms;

				//OK so if we are not preventing the reload at this point we will check our status for any currently active uploads
				if (!preventReload) {
					//Look for a request which has an incomplete upload as part of it, we will prevent refreshes at this point
					const incompleteUploadRequest = uploadRequestActionStatuses.find(
						(uploadRequest) =>
							uploadRequest.upload.uploadData &&
							uploadRequest.upload.uploadData.find((d) => d.progress < 100) !==
								undefined
					);

					//If we have an incomplete upload then we will prevent a reload
					if (incompleteUploadRequest) {
						preventReload = true;
					}
				}

				//If we are running it loacally we never want to prevent it
				if (environment.name === "LOCAL") {
					preventReload = false;
				}

				//Are we preventing the reload?
				if (preventReload) {
					//Add a function to indicate to the window what we will be doing on a reload. The message is actually redunant as newer browsers
					//do not allow message specification. This is purely incase we do fall through the gap
					window.onbeforeunload = function (e) {
						return "There are unsaved changes on this page reloading will loose all current unsaved changes or unfinished uploads";
					};
				} else {
					//We no longer have any unsaved changes we will loose the handler and user can refresh at will!
					window.onbeforeunload = undefined;
				}
			}
		);
	}

	/**
	 * Setup the dialogue service so it can be used globally
	 */
	private dialogServiceSetup() {
		//Setup the dialog service with the view container ref
		this.dialogService.appRootViewContainerRef = this.viewContainerRef;
	}

	/**
	 * Setup the file download procedure
	 */
	private downloadFileURLSetup() {
		//Subscribe to the download URL string to start the generation of file
		this.fileDownloadConfig$
			.pipe(
				filter(
					(fileDownloadConfig) =>
						fileDownloadConfig !== undefined &&
						fileDownloadConfig !== null &&
						fileDownloadConfig.length > 0
				),
				distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
				flatMap((fileDownloadConfig) => fileDownloadConfig),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((fileDownloadConfig) => {
				//No URL no download
				if (!fileDownloadConfig.url) {
					return;
				}

				//OK this may seem hacky but hear me out ...
				//When we want to download a file, we want a download and
				//not for it to open in a new tab. Due to the nature of browsers
				//they will try and render the files they understand ... txt, xml, pdf etc
				//One way to prevent this rendering it to have the source have the URL mime types set to
				//application/octet-stream this will then let the browser belive it cannot render this.
				//however this requires the source of the file to have this set and this feels like abit
				//of an issues to force the aHub to restrict the mimetype just so the portal can download file.

				//It is a simple workaround and at this point I think it will force consistency ... so im not worried
				//Basically create an anchor tag with our link (hidden), click it and remove it

				// Approach #1
				// If a download filename has not been specified we can use the filename of the file at the end of the url
				// const element = document.createElement('a');
				// element.setAttribute('href', url);
				// // element.setAttribute('download', fileName[0]);
				// // element.href = url;
				// // element.download = fileName[0];
				// // element.download = 'terence.png';
				// element.style.display = 'none';
				// document.body.appendChild(element);
				// element.click();
				// document.body.removeChild(element);

				// Approach #2
				// If a filename wsa not specified when the download was requested, lets use the filename at the end of the url
				if (!fileDownloadConfig.fileName) {
					const typedUrl: URL = new URL(fileDownloadConfig.url);
					// Lets try to grab the filename at the end of the path using regex
					fileDownloadConfig.fileName = typedUrl.pathname.match(/[^\/]+$/)[0];
				}

				// Adding '&download' to the end of the url (making it different to any previously displayed image url) such that chrome does not attempt to 'download' this image (say)
				// from the image cache. Attempts to download images from the image cache cause odd CORs issues (https://stackoverflow.com/questions/12648809/cors-policy-on-cached-image)
				// N.B. This is now only being done on paths that start with "https://materials." because this the area where we have the issues.
				// If we apply this fix to all downloads then it stops the downloading of content set data and other files.
				if (fileDownloadConfig.url.startsWith("https://materials.")) {
					fileDownloadConfig.url = fileDownloadConfig.url + "&download";
				}

				this.http
					.get(fileDownloadConfig.url, {
						headers: new HttpHeaders({
							Accept: "*/*",
						}),
						responseType: "blob",
						observe: "response",
					})
					.subscribe((response) => {
						// Not going to use this for the moment, but setting an alternate name as meta data for a file
						// then transfered as a response http header...
						// in order for this to work (CORS) the server needs to inform the client that the 'Content-Disposition'
						// header is allowable
						// e.g. <ExposeHeader>Content-Disposition</ExposeHeader> in the s3 CORS configuration
						//  OR
						// include in the response header Access-Control-Expose-Headers Content-Disposition

						importedSaveAs(response.body, fileDownloadConfig.fileName);
					});
			});
	}

	/**
	 * Method is use to download file.
	 * @param data - Array Buffer data
	 * @param type - type of the document.
	 */
	// private downLoadFile(data: any, type: string) {
	//   var blob = new Blob([data], { type: type });
	//   var url = window.URL.createObjectURL(blob);
	//   var pwa = window.open(url);
	//   if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
	//     alert('Please disable your Pop-up blocker and try again.');
	//   }
	// }

	/**
	 * Setup the copy clipboard procedure
	 */
	private clipboardCopySetup() {
		//Subscribe to the clipboard copy text
		this.clipboardCopyText$
			.pipe(
				filter((txt) => txt !== undefined && txt !== null),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((txt) => {
				const el = document.createElement("textarea");
				el.value = txt;
				document.body.appendChild(el);
				el.select();
				document.execCommand("copy");
				document.body.removeChild(el);
			});
	}

	/**
	 * Adds a class name formatted from the url. (A top level css style overide per page if ever needed).
	 */
	uniquePageClass() {
		const pageName = this.router.url.replace(/\//g, "-");
		return `page${pageName}`;
	}
}
