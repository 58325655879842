import { Component, OnInit, Input } from "@angular/core";
import { Observable } from "rxjs";
import { ProductPropertyCompletenessAHubVO } from "app/valueObjects/ahub/library/product-property-completeness.ahub.vo";

@Component({
	selector: "app-product-completeness",
	templateUrl: "./product-completeness.component.html",
	styleUrls: ["./product-completeness.component.css"],
})
export class ProductCompletenessComponent implements OnInit {
	/**
	 * This is the user id we are working with.
	 */
	@Input() productCompleteness$: Observable<ProductPropertyCompletenessAHubVO> =
		null;

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		// This is intentional
	}
}
