import {
	Component,
	AfterViewInit,
	ViewChild,
	ViewContainerRef,
	ComponentFactory,
	ComponentRef,
} from "@angular/core";
import { IFloatingFilter } from "ag-grid-community";
import { AgFrameworkComponent } from "ag-grid-angular";

@Component({
	selector: "app-grid-filter-floating-component",
	template: "<div #placeholder></div>",
	styleUrls: ["./grid-filter-floating-component.component.css"],
})
export class GridFilterFloatingComponentComponent
	implements GridFilterFloatingComponentInterface, AfterViewInit
{
	@ViewChild("placeholder", {
		read: ViewContainerRef,
		static: true,
	})
	placeholder: ViewContainerRef;

	/**
	 * Parameters for the component
	 */
	params = undefined;

	/**
	 * Parameters for the component
	 */
	paramsWithCustom: GridFilterFloatingComponentParams = undefined;

	/**
	 * The component factory passed in to create the components for object display.
	 */
	componentFactory: ComponentFactory<GridFilterFloatingComponentInterface>;

	/**
	 * The created component in the wrapper.
	 */
	createdComponent: ComponentRef<GridFilterFloatingComponentInterface>;

	constructor() {
		// This is intentional
	}

	agInit(params: any): void {
		//Set the different parameter types
		this.params = params;
		this.paramsWithCustom = this.params as GridFilterFloatingComponentParams;
	}

	ngAfterViewInit(): void {
		// Create a component using the factory supplied in our wrapper.
		if (this.paramsWithCustom.factory) {
			//Set the factory
			this.componentFactory = this.paramsWithCustom.factory;

			//Create the component
			this.createdComponent = this.placeholder.createComponent(
				this.componentFactory
			);
		}

		//Do we have a created component
		if (
			this.createdComponent &&
			this.createdComponent.instance &&
			this.createdComponent.instance.agInit
		) {
			//Call the agInit with its parameters
			this.createdComponent.instance.agInit(this.params);
		}
	}

	onParentModelChanged(parentModel: any): void {
		if (
			this.createdComponent &&
			this.createdComponent.instance &&
			this.createdComponent.instance.onParentModelChanged
		) {
			this.createdComponent.instance.onParentModelChanged(parentModel);
		}
	}
}

/**
 * Common interface for the floating grid componenent
 */
export interface GridFilterFloatingComponentInterface
	extends IFloatingFilter,
		AgFrameworkComponent<any>,
		AfterViewInit {}

/**
 * Custom interface for the grid floating filter factory
 */
export interface GridFilterFloatingComponentParams {
	factory: ComponentFactory<GridFilterFloatingComponentInterface>;
}
