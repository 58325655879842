/**
 * ----------------------------------------------------------
 * Action Types
 * ----------------------------------------------------------
 */
import {
	SelectionACatalogVO,
	SelectionIndexACatalogVO,
	SelectionProductUserReactionACatalogVO,
	SelectionUserFeedbackACatalogVO,
	UserIndexAHubVO,
} from "@harksolutions/ahub-web-services-types";
import { ACatalogDistributionAHubVO } from "@harksolutions/ahub-web-services-types";
import { SelectionConfigACatalogVO } from "@harksolutions/ahub-web-services-types";
import { ExportDistributionIndexAHubVO } from "@harksolutions/ahub-web-services-types";
import { FeedbackView } from "app/modules/common/components/feedback/feedback-message-widget/feedback-message-widget.component";
import { MapStorage } from "../map-storage.vo";
import {
	ActionACatalogDistribution,
	ActionExportDistributionIndex,
	ActionPublicationExportDistributionIndexes,
	ActionPublicationSelectionACatalog,
	ActionPublicationSelectionIndexesACatalog,
	ActionSelectionACatalogVO,
	ActionSelectionConfigsACatalog,
	ActionSelectionFeedbackAdd as ActionSelectionFeedbackAdd,
	ActionSelectionFeedbackCommit,
	ActionSelectionFeedbackDelete,
	ActionSelectionFeedbackViewsACatalog,
	ActionSelectionProductFeedbackViewsACatalog,
	ActionSelectionProductReaction,
	ActionSelectionProductReactionsACatalog,
	ActionSelectionsACatalog,
	ActionSelectionUserIdsACatalog,
	ActionSelectionUsersACatalog,
	ActionSelectionUsersByEmailACatalog,
	ActionSelectionWithComponentIdACatalogVO,
} from "./types/acatalog.action-types";
import { ActionNumber, ActionNumberBoolean } from "./types/common.action-types";
import { ActionWork } from "./types/work.action-types";

/**
 * View Actions class
 */
export class ACatalogActions {
	static ACATALOG_SELECTION_ADD = "[ACATALOG] ACATALOG_SELECTION_ADD";
	static ACATALOG_SELECTION_DELETE = "[ACATALOG] ACATALOG_SELECTION_DELETE";
	static ACATALOG_SELECTION_COMMIT = "[ACATALOG] ACATALOG_SELECTION_COMMIT";
	static ACATALOG_SELECTION_INDEXES_FETCH =
		"[ACATALOG] ACATALOG_SELECTION_INDEXES_FETCH";
	static ACATALOG_SELECTION_BY_ID_FETCH =
		"[ACATALOG] ACATALOG_SELECTION_BY_ID_FETCH";
	static ACATALOG_SELECTIONS_BY_USER_ID_FETCH =
		"[ACATALOG] ACATALOG_SELECTIONS_BY_USER_ID_FETCH";
	static ACATALOG_SELECTION_USERS_FETCH =
		"[ACATALOG] ACATALOG_SELECTION_USERS_FETCH";
	static ACATALOG_SELECTION_USERS_ADD =
		"[ACATALOG] ACATALOG_SELECTION_USERS_ADD";
	static ACATALOG_SELECTION_USERS_ADD_BY_EMAIL =
		"[ACATALOG] ACATALOG_SELECTION_USERS_ADD_BY_EMAIL";
	static ACATALOG_SELECTION_USERS_DELETE =
		"[ACATALOG] ACATALOG_SELECTION_USERS_DELETE";

	static ACATALOG_SELECTION_INDEXES_SET =
		"[ACATALOG] ACATALOG_SELECTION_INDEXES_SET";
	static ACATALOG_SELECTION_SET = "[ACATALOG] ACATALOG_SELECTION_SET";
	static ACATALOG_SELECTIONS_SET = "[ACATALOG] ACATALOG_SELECTIONS_SET";
	static ACATALOG_SELECTIONS_CLEAR = "[ACATALOG] ACATALOG_SELECTIONS_CLEAR";
	static ACATALOG_SELECTION_USERS_SET =
		"[ACATALOG] ACATALOG_SELECTION_USERS_SET";

	static ACATALOG_SELECTION_PRODUCT_REACTION_COMMIT =
		"[ACATALOG] ACATALOG_SELECTION_PRODUCT_REACTION_COMMIT";
	static ACATALOG_SELECTION_PRODUCT_REACTION_DELETE =
		"[ACATALOG] ACATALOG_SELECTION_PRODUCT_REACTION_DELETE";

	static ACATALOG_SELECTION_PRODUCT_REACTION_MAP_SET =
		"[ACATALOG] ACATALOG_SELECTION_PRODUCT_REACTION_MAP_APPEND";
	static ACATALOG_SELECTION_PRODUCT_REACTION_MAP_CLEAR =
		"[ACATALOG] ACATALOG_SELECTION_PRODUCT_REACTION_MAP_CLEAR";

	static ACATALOG_SELECTION_FEEDBACK_ADD =
		"[ACATALOG] ACATALOG_SELECTION_FEEDBACK_ADD";
	static ACATALOG_SELECTION_FEEDBACK_COMMIT =
		"[ACATALOG] ACATALOG_SELECTION_FEEDBACK_COMMIT";
	static ACATALOG_SELECTION_FEEDBACK_DELETE =
		"[ACATALOG] ACATALOG_SELECTION_FEEDBACK_DELETE";

	static ACATALOG_SELECTION_FEEDBACK_VIEWS_MAP_SET =
		"[ACATALOG] ACATALOG_SELECTION_FEEDBACK_MAP_SET";
	static ACATALOG_SELECTION_PRODUCT_FEEDBACK_VIEWS_MAP_SET =
		"[ACATALOG] ACATALOG_SELECTION_PRODUCT_FEEDBACK_VIEWS_MAP_SET";
	static ACATALOG_SELECTION_PRODUCT_FEEDBACK_VIEWS_MAP_CLEAR =
		"[ACATALOG] ACATALOG_SELECTION_PRODUCT_FEEDBACK_VIEWS_MAP_CLEAR";

	static ACATALOG_SELECTION_DEFAULT_CONFIGS_FETCH =
		"[ACATALOG] ACATALOG_SELECTION_DEFAULT_CONFIGS_FETCH";
	static ACATALOG_SELECTION_DEFAULT_CONFIGS_SET =
		"[ACATALOG] ACATALOG_SELECTION_DEFAULT_CONFIGS_SET";

	static ACATALOG_IS_PUBLICATION_AVIEW_PRO_FETCH =
		"[ACATALOG] ACATALOG_IS_PUBLICATION_AVIEW_PRO_FETCH";
	static ACATALOG_IS_PUBLICATION_AVIEW_PRO_SET =
		"[ACATALOG] ACATALOG_IS_PUBLICATION_AVIEW_PRO_SET";

	static ACATALOG_PUBLICATION_EXPORT_DISTRIBUTION_INDEXES_SET =
		"[ACATALOG] ACATALOG_PUBLICATION_EXPORT_DISTRIBUTION_INDEXES_SET";

	static ACATALOG_DISTRIBUTION_FETCH = "[ACATALOG] ACATALOG_DISTRIBUTION_FETCH";
	static ACATALOG_DISTRIBUTION_SET = "[ACATALOG] ACATALOG_DISTRIBUTION_SET";

	static ACATALOG_SHARE_SELECTION_TOKEN_GENERATE =
		"[ACATALOG] ACATALOG_SHARE_SELECTION_TOKEN_GENERATE";

	/**
	 * Action to get selection Indexes.
	 */
	static publicationSelectionIndexesFetch(publicationId: number): ActionNumber {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_INDEXES_FETCH,
			number: publicationId,
		};
	}

	/**
	 * Action to set selection Indexes.
	 */
	static publicationSelectionIndexesSet(
		publicationId: number,
		selectionIndexes: SelectionIndexACatalogVO[]
	): ActionPublicationSelectionIndexesACatalog {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_INDEXES_SET,
			publicationId,
			selectionIndexes,
		};
	}

	/**
	 * Action to commit a selection.
	 */
	static selectionByIdFetch(selectionId: number): ActionNumber {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_BY_ID_FETCH,
			number: selectionId,
		};
	}

	/**
	 * Action to fetch selections by user id
	 */
	static selectionsByUserIdFetch(userId: number): ActionNumber {
		return {
			type: ACatalogActions.ACATALOG_SELECTIONS_BY_USER_ID_FETCH,
			number: userId,
		};
	}

	/**
	 * Action to add a selection.
	 */
	static selectionAddAndSetSelectionId(
		selection: SelectionACatalogVO,
		componentId: string
	): ActionSelectionWithComponentIdACatalogVO {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_ADD,
			selection,
			componentId,
		};
	}

	/**
	 * Action to commit a selection.
	 */
	static selectionCommit(
		selection: SelectionACatalogVO
	): ActionSelectionACatalogVO {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_COMMIT,
			selection,
		};
	}

	/**
	 * Action to delete a selection.
	 */
	static selectionDelete(
		selection: SelectionACatalogVO
	): ActionSelectionACatalogVO {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_DELETE,
			selection,
		};
	}

	static selectionUsersFetch(selectionId: number): ActionNumber {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_USERS_FETCH,
			number: selectionId,
		};
	}

	static selectionUsersAdd(
		selectionId: number,
		userIds: number[]
	): ActionSelectionUserIdsACatalog {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_USERS_ADD,
			selectionId,
			userIds,
		};
	}

	static selectionUsersAddByEmail(
		selectionId: number,
		userEmails: string[]
	): ActionSelectionUsersByEmailACatalog {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_USERS_ADD_BY_EMAIL,
			selectionId,
			userEmails,
		};
	}

	static selectionUsersDelete(
		selectionId: number,
		userIds: number[]
	): ActionSelectionUserIdsACatalog {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_USERS_DELETE,
			selectionId,
			userIds,
		};
	}

	/**
	 * Action to commit a selection.
	 */
	static publicationSelectionSet(
		selection: SelectionACatalogVO
	): ActionPublicationSelectionACatalog {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_SET,
			publicationId: selection.publicationId,
			selection,
		};
	}

	/**
	 * Action to store selections.
	 */
	static selectionsSet(
		selections: SelectionACatalogVO[]
	): ActionSelectionsACatalog {
		return {
			type: ACatalogActions.ACATALOG_SELECTIONS_SET,
			selections,
		};
	}

	/**
	 * Action to store selections.
	 */
	static selectionsClear(): ActionWork {
		return {
			type: ACatalogActions.ACATALOG_SELECTIONS_CLEAR,
		};
	}

	static selectionUsersSet(
		selectionId: number,
		userIndexes: UserIndexAHubVO[]
	): ActionSelectionUsersACatalog {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_USERS_SET,
			selectionId,
			userIndexes: userIndexes,
		};
	}

	static selectionProductReactionCommit(
		selectionId: number,
		productId: number,
		reaction: string
	): ActionSelectionProductReaction {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_PRODUCT_REACTION_COMMIT,
			selectionId,
			productId,
			reaction,
		};
	}

	static selectionProductReactionDelete(
		selectionId: number,
		productId: number,
		reactionId: number
	): ActionSelectionProductReaction {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_PRODUCT_REACTION_DELETE,
			selectionId,
			productId,
			reactionId,
		};
	}

	static selectionProductReactionMapSet(
		selectionId: number,
		productUserReactionsMap: MapStorage<
			SelectionProductUserReactionACatalogVO[]
		>
	): ActionSelectionProductReactionsACatalog {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_PRODUCT_REACTION_MAP_SET,
			selectionId: selectionId,
			productUserReactionsMap: productUserReactionsMap,
		};
	}

	static selectionProductReactionMapClear(): ActionWork {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_PRODUCT_REACTION_MAP_CLEAR,
		};
	}

	static selectionFeedbackAdd(
		selectionId: number,
		feedback: SelectionUserFeedbackACatalogVO
	): ActionSelectionFeedbackAdd {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_FEEDBACK_ADD,
			selectionId,
			feedback,
		};
	}

	static selectionFeedbackCommit(
		selectionId: number,
		selectionFeedbackId: number,
		feedbackMessage: string
	): ActionSelectionFeedbackCommit {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_FEEDBACK_COMMIT,
			selectionId,
			selectionFeedbackId,
			feedbackMessage,
		};
	}

	static selectionFeedbackDelete(
		selectionId: number,
		selectionFeedbackId: number
	): ActionSelectionFeedbackDelete {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_FEEDBACK_DELETE,
			selectionId,
			selectionFeedbackId,
		};
	}

	static selectionFeedbackViewsMapSet(
		selectionId: number,
		feedbackViews: FeedbackView[]
	): ActionSelectionFeedbackViewsACatalog {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_FEEDBACK_VIEWS_MAP_SET,
			selectionId: selectionId,
			feedbackViews,
		};
	}

	static selectionProductFeedbackViewsMapSet(
		selectionId: number,
		productUserFeedbackViewsMap: MapStorage<FeedbackView[]>
	): ActionSelectionProductFeedbackViewsACatalog {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_PRODUCT_FEEDBACK_VIEWS_MAP_SET,
			selectionId: selectionId,
			productUserFeedbackViewsMap,
		};
	}

	static selectionProductFeedbackViewsMapClear(): ActionWork {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_PRODUCT_FEEDBACK_VIEWS_MAP_CLEAR,
		};
	}

	static selectionDefaultConfigsFetch(): ActionWork {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_DEFAULT_CONFIGS_FETCH,
		};
	}

	static selectionDefaultConfigsSet(
		selectionConfigs: SelectionConfigACatalogVO[]
	): ActionSelectionConfigsACatalog {
		return {
			type: ACatalogActions.ACATALOG_SELECTION_DEFAULT_CONFIGS_SET,
			selectionConfigs,
		};
	}

	static isPublicationAViewProFetch(publicationId: number): ActionNumber {
		return {
			type: ACatalogActions.ACATALOG_IS_PUBLICATION_AVIEW_PRO_FETCH,
			number: publicationId,
		};
	}

	static isPublicationAViewProSet(
		publicationId: number,
		isAViewPro: boolean
	): ActionNumberBoolean {
		return {
			type: ACatalogActions.ACATALOG_IS_PUBLICATION_AVIEW_PRO_SET,
			number: publicationId,
			boolean: isAViewPro,
		};
	}

	static publicationExportDistributionIndexesSet(
		publicationId: number,
		exportDistributionIndexes: ExportDistributionIndexAHubVO[]
	): ActionPublicationExportDistributionIndexes {
		return {
			type: ACatalogActions.ACATALOG_PUBLICATION_EXPORT_DISTRIBUTION_INDEXES_SET,
			publicationId,
			exportDistributionIndexes,
		};
	}

	static aCatalogDistributionFetch(
		exportDistributionIndex: ExportDistributionIndexAHubVO
	): ActionExportDistributionIndex {
		return {
			type: ACatalogActions.ACATALOG_DISTRIBUTION_FETCH,
			exportDistributionIndex,
		};
	}

	static aCatalogDistributionSet(
		distributionId: number,
		aCatalogDistribution: ACatalogDistributionAHubVO
	): ActionACatalogDistribution {
		return {
			type: ACatalogActions.ACATALOG_DISTRIBUTION_SET,
			distributionId,
			aCatalogDistribution,
		};
	}

	// static shareSelectionTokenGenerate(selectionUserAddByEmailTokenPayload: SelectionUserAddByEmailTokenPayloadACatalogVO): ActionSelectionUserAddByEmailTokenPayload {
	//   return {
	//     type: ACatalogActions.ACATALOG_SHARE_SELECTION_TOKEN_GENERATE,
	//     selectionUserAddByEmailTokenPayload
	//   }
	// }
}
