import { ParameterAHubVO } from "valueObjects/ahub/accounts/parameter.vo";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";
import { DistributionAViewProductFilterAHubVO } from "app/valueObjects/ahub/accounts/distribution-aview-product-filter.ahub.vo";
import { DistributionAViewPropertyRuleAHubVO } from "app/valueObjects/ahub/accounts/distribution-aview-property-rule.ahub.vo";
import { DistributionAViewPropertyTestAHubVO } from "app/valueObjects/ahub/accounts/distribution-aview-property-test.ahub.vo";
import { DistributionIndexAHubVO } from "app/valueObjects/ahub/accounts/distribution-index.ahub.vo";
import { ExportDistributionIndexAHubVO } from "@harksolutions/ahub-web-services-types";

/**
 * This is the class full of distribution utilities.
 */
export class DistributionUtils {
	/**
	 * The Regular expression used to validate a URL.
	 */
	private static urlPatternRegexp =
		"^(http(s)?(://))?(www.)?[a-zA-Z0-9-_.]+(.[a-zA-Z0-9]{2,})([-a-zA-Z0-9:%_+.~#?&//=]*)$";

	/**
	 * The all of the order submission types.
	 */
	public static orderDropReferenceTypes: any[] = [
		{ value: "", name: "None" },
		{ value: "custom", name: "Custom Only" },
		{ value: "unique", name: "Unique Only" },
		{ value: "combination", name: "Combination" },
	];

	/**
	 * This function will add a form control to the current distribution form.
	 *
	 * @param name            The name of the form. This will be what is set in the HTML.
	 * @param value           The default value to set the form too.
	 * @param disabled        (Optional) Is the form control disabled?
	 * @param validators      (Optional) Any validators that need to be added to the form control.
	 */
	public static formControlAdd(
		formGroup: UntypedFormGroup,
		name: string,
		value,
		disabled?: boolean,
		validators?
	) {
		// Creat the form with the default value and validators.
		const formControl = new UntypedFormControl(value, validators);

		// Then disable it if we need too.
		if (disabled) {
			formControl.disable();
		}

		// Finally, add the form control to the form.
		formGroup.addControl(name, formControl);
	}

	public static aViewExportTypeParametersAddToForm(
		formGroup: UntypedFormGroup
	) {
		DistributionUtils.formControlAdd(formGroup, "skuFilters", []);
		DistributionUtils.formControlAdd(formGroup, "arangerDisabled", false);
	}

	/**
	 * Add the standard aWorkbook export type parameters to a form.
	 *
	 * @param formGroup             The form group to add the controls to.
	 */
	public static awExportTypeParametersAddToForm(formGroup: UntypedFormGroup) {
		DistributionUtils.formControlAdd(formGroup, "skuFilters", []);
		DistributionUtils.formControlAdd(formGroup, "priceList", []);
		DistributionUtils.formControlAdd(formGroup, "resourceFilters", []);
		DistributionUtils.formControlAdd(formGroup, "assortmentFilters", []);
		DistributionUtils.formControlAdd(formGroup, "presentationFilters", []);
		DistributionUtils.formControlAdd(
			formGroup,
			"preSavedAssortmentFilters",
			[]
		);
		DistributionUtils.formControlAdd(formGroup, "tabOrderDisable", []);
		DistributionUtils.formControlAdd(formGroup, "tabPresentationDisable", []);
		DistributionUtils.formControlAdd(
			formGroup,
			"productRetailPriceDisable",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"productRetailPriceOutputDisable",
			[]
		);
		DistributionUtils.formControlAdd(formGroup, "dropsRequireDate", []);
		DistributionUtils.formControlAdd(
			formGroup,
			"productWholesalePriceDisable",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"productWholesalePriceOutputDisable",
			[]
		);
		DistributionUtils.formControlAdd(formGroup, "productFilterOutOfStock", []);
		DistributionUtils.formControlAdd(formGroup, "stockServerUrl", "", false, [
			Validators.pattern(DistributionUtils.urlPatternRegexp),
		]);
		DistributionUtils.formControlAdd(formGroup, "stockServerSessionKey", "");
		DistributionUtils.formControlAdd(
			formGroup,
			"customOrderDataProviderServerUrl",
			"",
			false,
			[Validators.pattern(DistributionUtils.urlPatternRegexp)]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"customOrderDataProviderServerSessionKey",
			""
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"orderSubmissionServerType",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"orderSubmissionServerUrl",
			"",
			false,
			[Validators.pattern(this.urlPatternRegexp)]
		);
		DistributionUtils.formControlAdd(formGroup, "orderSubmissionServerKey", []);
		DistributionUtils.formControlAdd(
			formGroup,
			"orderSubmissionAllProducts",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"orderSubmissionLockSentOrder",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"orderSubmissionReportFullError",
			[]
		);
		DistributionUtils.formControlAdd(formGroup, "alternativeCoverAssetId", []);
		DistributionUtils.formControlAdd(formGroup, "colourSchemeId", []);
		DistributionUtils.formControlAdd(formGroup, "orderDropTypeOptions", []);
		DistributionUtils.formControlAdd(formGroup, "orderDropReferenceType", []);
		DistributionUtils.formControlAdd(
			formGroup,
			"orderSummaryAssortmentGroupId",
			[]
		);
		DistributionUtils.formControlAdd(formGroup, "allowDiscountLists", []);
	}

	/**
	 * Add the standard aView export type inherit parameters to a form.
	 *
	 * @param formGroup             The form group to add the controls to.
	 */
	public static aViewExportTypeInheritParametersAddToForm(
		formGroup: UntypedFormGroup
	) {
		DistributionUtils.formControlAdd(formGroup, "skuFiltersInherit", []);
		DistributionUtils.formControlAdd(formGroup, "arangerDisabledInherit", []);
	}

	/**
	 * Add the standard aWorkbook export type inherit parameters to a form.
	 *
	 * @param formGroup             The form group to add the controls to.
	 */
	public static awExportTypeInheritParametersAddToForm(
		formGroup: UntypedFormGroup
	) {
		DistributionUtils.formControlAdd(formGroup, "skuFiltersInherit", []);
		DistributionUtils.formControlAdd(formGroup, "priceListInherit", []);
		DistributionUtils.formControlAdd(formGroup, "resourceFiltersInherit", []);
		DistributionUtils.formControlAdd(formGroup, "assortmentFiltersInherit", []);
		DistributionUtils.formControlAdd(
			formGroup,
			"presentationFiltersInherit",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"preSavedAssortmentFiltersInherit",
			[]
		);
		DistributionUtils.formControlAdd(formGroup, "tabOrderDisableInherit", []);
		DistributionUtils.formControlAdd(
			formGroup,
			"tabPresentationDisableInherit",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"productRetailPriceDisableInherit",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"productRetailPriceOutputDisableInherit",
			[]
		);
		DistributionUtils.formControlAdd(formGroup, "dropsRequireDateInherit", []);
		DistributionUtils.formControlAdd(
			formGroup,
			"productWholesalePriceDisableInherit",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"productWholesalePriceOutputDisableInherit",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"productFilterOutOfStockInherit",
			[]
		);
		DistributionUtils.formControlAdd(formGroup, "stockServerUrlInherit", []);
		DistributionUtils.formControlAdd(
			formGroup,
			"stockServerSessionKeyInherit",
			""
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"customOrderDataProviderServerUrlInherit",
			""
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"customOrderDataProviderServerSessionKeyInherit",
			""
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"orderSubmissionServerInherit",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"alternativeCoverAssetIdInherit",
			[]
		);
		DistributionUtils.formControlAdd(formGroup, "colourSchemeIdInherit", []);
		DistributionUtils.formControlAdd(
			formGroup,
			"orderDropTypeOptionsInherit",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"orderDropReferenceTypeInherit",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"orderSummaryAssortmentGroupIdInherit",
			[]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"allowDiscountListsInherit",
			[]
		);
	}

	/**
	 * Add the standard aView export type group parameters to a form.
	 *
	 * @param formGroup             The form group to add the controls to.
	 */
	public static aViewExportTypeGroupParametersAddToForm(
		formGroup: UntypedFormGroup
	) {
		DistributionUtils.formControlAdd(formGroup, "groupSkuFilters", "", true);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupArangerDisabled",
			"",
			true
		);
	}

	/**
	 * Add the standard aWorkbook export type group parameters to a form.
	 *
	 * @param formGroup             The form group to add the controls to.
	 */
	public static awExportTypeGroupParametersAddToForm(
		formGroup: UntypedFormGroup
	) {
		DistributionUtils.formControlAdd(formGroup, "groupSkuFilters", "", true);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupPriceListFilter",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupResourceFilters",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupAssortmentFilters",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupPresentationFilters",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupPreSavedAssortmentFilters",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupTabOrderDisable",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupTabPresentationDisable",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupProductRetailPriceDisable",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupProductRetailPriceOutputDisable",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupDropsRequireDate",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupProductWholesalePriceDisable",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupProductWholesalePriceOutputDisable",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupProductFilterOutOfStock",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupStockServerUrl",
			"",
			true,
			[Validators.pattern(this.urlPatternRegexp)]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupStockServerSessionKey",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupCustomOrderDataProviderServerUrl",
			"",
			true,
			[Validators.pattern(this.urlPatternRegexp)]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupCustomOrderDataProviderServerSessionKey",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupOrderSubmissionServerType",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupOrderSubmissionServerUrl",
			"",
			true,
			[Validators.pattern(this.urlPatternRegexp)]
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupOrderSubmissionServerKey",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupOrderSubmissionAllProducts",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupOrderSubmissionLockSentOrder",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupOrderSubmissionReportFullError",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupAlternativeCoverAssetId",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupColourSchemeId",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupOrderDropTypeOptions",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupOrderDropReferenceType",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupOrderSummaryAssortmentGroupId",
			"",
			true
		);
		DistributionUtils.formControlAdd(
			formGroup,
			"groupAllowDiscountLists",
			"",
			true
		);
	}

	/**
	 * Function to get the first value named with that parameter, if the parameter doesn't exist then its undefined
	 */
	public static distributionParamValueSingleBoolean(
		params: ParameterAHubVO[],
		parameterName: string,
		defaultValue: boolean
	): boolean {
		//Get the values array
		const values: string[] = this.distributionParamValuesArrayFind(
			params,
			parameterName
		);

		//Return the first value from the list
		return values && values.length > 0
			? values[0].toLowerCase() === "true"
			: defaultValue;
	}

	/**
	 * Function to get the first value named with that parameter, if the parameter doesn't exist then its undefined
	 */
	public static distributionParamValueSingleBooleanFalse(
		params: ParameterAHubVO[],
		parameterName: string,
		defaultValue: boolean
	): boolean {
		//Get the values array
		const values: string[] = this.distributionParamValuesArrayFind(
			params,
			parameterName
		);

		//Return the first value from the list
		return values && values.length > 0
			? values.some((value) => value.toLowerCase() === "false")
			: defaultValue;
	}

	/**
	 * Function to get the values from the distribution parameters based on name
	 */
	public static distributionParamValuesArrayFind(
		params: ParameterAHubVO[],
		parameterName: string
	): string[] {
		//If we don't have a parameters array we will return undefined
		if (!params) {
			return undefined;
		}

		//Find the parameter with the correct name
		const parameter: ParameterAHubVO = params.find(
			(param) => param.id.toLowerCase() === parameterName.toLowerCase()
		);

		//If we have a parameter then we will return the values if not then we will return undefined
		return parameter ? parameter.values : undefined;
	}

	/**
	 * Function to get the values from the distribution parameters based on name
	 */
	public static distributionParamValuesSingleFind(
		params: ParameterAHubVO[],
		parameterName: string
	): string {
		const values = this.distributionParamValuesArrayFind(params, parameterName);

		//If we have a parameter then we will return the values if not then we will return undefined
		return values ? values[0] : undefined;
	}

	/**
	 * Pass the aWorkbook distribution parameters into a form.
	 *
	 * @param distributionParams                The distribution parameters to pass into the form.
	 * @param form                              The form to set the values in.
	 */
	public static setAViewDistributionParamsToForm(
		distributionParams: ParameterAHubVO[],
		form: UntypedFormGroup
	) {
		// Do we have paramters associated with this distribution? If so we will
		// override some of the flags set by the distribution group
		if (distributionParams) {
			//We will also need to set parts of the form manually
			form.patchValue({
				skuFilters: this.distributionParamValuesArrayFind(
					distributionParams,
					"skuFilters"
				),
			});
		}
		form.patchValue({
			arangerDisabled: this.distributionParamValueSingleBoolean(
				distributionParams,
				"arangerDisabled",
				false
			),
		});
	}

	/**
	 * Pass the aWorkbook distribution parameters into a form.
	 *
	 * @param distributionParams                The distribution parameters to pass into the form.
	 * @param form                              The form to set the values in.
	 */
	public static setAWDistributionParamsToForm(
		distributionParams: ParameterAHubVO[],
		form: UntypedFormGroup
	) {
		// Do we have paramters associated with this distribution? If so we will
		// override some of the flags set by the distribution group
		if (distributionParams) {
			//We will also need to set parts of the form manually
			form.patchValue({
				skuFilters: this.distributionParamValuesArrayFind(
					distributionParams,
					"skuFilters"
				),
				priceList: this.distributionParamValuesArrayFind(
					distributionParams,
					"priceList"
				),
				resourceFilters: this.distributionParamValuesArrayFind(
					distributionParams,
					"resourceFilters"
				),
				assortmentFilters: this.distributionParamValuesArrayFind(
					distributionParams,
					"assortmentFilters"
				),
				presentationFilters: this.distributionParamValuesArrayFind(
					distributionParams,
					"presentationFilters"
				),
				preSavedAssortmentFilters: this.distributionParamValuesArrayFind(
					distributionParams,
					"preSavedAssortmentFilters"
				),
				stockServerUrl: this.distributionParamValuesSingleFind(
					distributionParams,
					"stockServerUrl"
				),
				stockServerSessionKey: this.distributionParamValuesSingleFind(
					distributionParams,
					"stockServerSessionKey"
				),
				customOrderDataProviderServerUrl:
					this.distributionParamValuesSingleFind(
						distributionParams,
						"customOrderDataProviderServerUrl"
					),
				customOrderDataProviderServerSessionKey:
					this.distributionParamValuesSingleFind(
						distributionParams,
						"customOrderDataProviderServerSessionKey"
					),
				orderSubmissionServerType: this.distributionParamValuesSingleFind(
					distributionParams,
					"orderSubmissionServerType"
				),
				orderSubmissionServerUrl: this.distributionParamValuesSingleFind(
					distributionParams,
					"orderSubmissionServerUrl"
				),
				orderSubmissionServerKey: this.distributionParamValuesSingleFind(
					distributionParams,
					"orderSubmissionServerKey"
				),
				alternativeCoverAssetId: this.distributionParamValuesSingleFind(
					distributionParams,
					"alternativeCoverAssetId"
				),
				colourSchemeId: this.distributionParamValuesSingleFind(
					distributionParams,
					"colourSchemeId"
				),
				orderDropTypeOptions: this.distributionParamValuesSingleFind(
					distributionParams,
					"orderDropTypeOptions"
				),
				orderDropReferenceType: this.distributionParamValuesSingleFind(
					distributionParams,
					"orderDropReferenceType"
				),
				orderSummaryAssortmentGroupId: this.distributionParamValuesSingleFind(
					distributionParams,
					"orderSummaryAssortmentGroupId"
				),
			});
		}
		form.patchValue({
			tabOrderDisable: this.distributionParamValueSingleBooleanFalse(
				distributionParams,
				"tabOrderDisable",
				true
			),
			tabPresentationDisable: this.distributionParamValueSingleBooleanFalse(
				distributionParams,
				"tabPresentationDisable",
				true
			),
			productRetailPriceDisable: this.distributionParamValueSingleBooleanFalse(
				distributionParams,
				"productRetailPriceDisable",
				false
			),
			productRetailPriceOutputDisable:
				this.distributionParamValueSingleBooleanFalse(
					distributionParams,
					"productRetailPriceOutputDisable",
					false
				),
			dropsRequireDate: this.distributionParamValueSingleBoolean(
				distributionParams,
				"dropsRequireDate",
				false
			),
			productWholesalePriceDisable:
				this.distributionParamValueSingleBooleanFalse(
					distributionParams,
					"productWholesalePriceDisable",
					false
				),
			productWholesalePriceOutputDisable:
				this.distributionParamValueSingleBooleanFalse(
					distributionParams,
					"productWholesalePriceOutputDisable",
					false
				),
			productFilterOutOfStock: this.distributionParamValueSingleBoolean(
				distributionParams,
				"productFilterOutOfStock",
				false
			),
			orderSubmissionAllProducts: this.distributionParamValueSingleBoolean(
				distributionParams,
				"orderSubmissionAllProducts",
				false
			),
			orderSubmissionLockSentOrder: this.distributionParamValueSingleBoolean(
				distributionParams,
				"orderSubmissionLockSentOrder",
				false
			),
			orderSubmissionReportFullError: this.distributionParamValueSingleBoolean(
				distributionParams,
				"orderSubmissionReportFullError",
				false
			),
			allowDiscountLists: this.distributionParamValueSingleBoolean(
				distributionParams,
				"allowDiscountLists",
				false
			),
		});
	}

	/**
	 * Pass aView inherited distribution group parameters into a form.
	 *
	 * @param distributionGroupParams               The distribution group parameters to pass in.
	 * @param form                                  The form to fill with values.
	 */
	public static setAViewDistributionParamsGroupToForm(
		distributionGroupParams: ParameterAHubVO[],
		form: UntypedFormGroup
	) {
		// Set any flags or filters from the distribution group level.
		if (distributionGroupParams) {
			form.patchValue({
				groupSkuFilters: DistributionUtils.distributionParamValuesArrayFind(
					distributionGroupParams,
					"skuFilters"
				),
			});
		}
		form.patchValue({
			groupArangerDisabled:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"arangerDisabled",
					false
				),
		});
	}

	/**
	 * Pass aWorkbook inherited distribution group parameters into a form.
	 *
	 * @param distributionGroupParams               The distribution group parameters to pass in.
	 * @param form                                  The form to fill with values.
	 */
	public static setAWDistributionParamsGroupToForm(
		distributionGroupParams: ParameterAHubVO[],
		form: UntypedFormGroup
	) {
		// Set any flags or filters from the distribution group level.
		if (distributionGroupParams) {
			form.patchValue({
				groupSkuFilters: DistributionUtils.distributionParamValuesArrayFind(
					distributionGroupParams,
					"skuFilters"
				),
				groupPriceListFilter:
					DistributionUtils.distributionParamValuesArrayFind(
						distributionGroupParams,
						"priceList"
					),
				groupResourceFilters:
					DistributionUtils.distributionParamValuesArrayFind(
						distributionGroupParams,
						"resourceFilters"
					),
				groupAssortmentFilters:
					DistributionUtils.distributionParamValuesArrayFind(
						distributionGroupParams,
						"assortmentFilters"
					),
				groupPresentationFilters:
					DistributionUtils.distributionParamValuesArrayFind(
						distributionGroupParams,
						"presentationFilters"
					),
				groupPreSavedAssortmentFilters:
					DistributionUtils.distributionParamValuesArrayFind(
						distributionGroupParams,
						"preSavedAssortmentFilters"
					),
				groupStockServerUrl: DistributionUtils.distributionParamValuesArrayFind(
					distributionGroupParams,
					"stockServerUrl"
				),
				groupStockServerSessionKey:
					DistributionUtils.distributionParamValuesArrayFind(
						distributionGroupParams,
						"stockServerSessionKey"
					),
				groupCustomOrderDataProviderServerUrl:
					DistributionUtils.distributionParamValuesArrayFind(
						distributionGroupParams,
						"customOrderDataProviderServerUrl"
					),
				groupCustomOrderDataProviderServerSessionKey:
					DistributionUtils.distributionParamValuesArrayFind(
						distributionGroupParams,
						"customOrderDataProviderServerSessionKey"
					),
				groupOrderSubmissionServerType:
					DistributionUtils.distributionParamValuesSingleFind(
						distributionGroupParams,
						"orderSubmissionServerType"
					),
				groupOrderSubmissionServerUrl:
					DistributionUtils.distributionParamValuesSingleFind(
						distributionGroupParams,
						"orderSubmissionServerUrl"
					),
				groupOrderSubmissionServerKey:
					DistributionUtils.distributionParamValuesSingleFind(
						distributionGroupParams,
						"orderSubmissionServerKey"
					),
				groupAlternativeCoverAssetId:
					DistributionUtils.distributionParamValuesSingleFind(
						distributionGroupParams,
						"alternativeCoverAssetId"
					),
				groupColourSchemeId:
					DistributionUtils.distributionParamValuesSingleFind(
						distributionGroupParams,
						"colourSchemeId"
					),
				groupOrderDropTypeOptions:
					DistributionUtils.distributionParamValuesSingleFind(
						distributionGroupParams,
						"orderDropTypeOptions"
					),
				groupOrderDropReferenceType:
					DistributionUtils.distributionParamValuesSingleFind(
						distributionGroupParams,
						"orderDropReferenceType"
					),
				groupOrderSummaryAssortmentGroupId:
					DistributionUtils.distributionParamValuesSingleFind(
						distributionGroupParams,
						"orderSummaryAssortmentGroupId"
					),
			});
		}

		form.patchValue({
			groupTabOrderDisable:
				!DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"tabOrderDisable",
					false
				),
			groupTabPresentationDisable:
				!DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"tabPresentationDisable",
					false
				),
			groupProductRetailPriceDisable:
				!DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"productRetailPriceDisable",
					true
				),
			groupProductRetailPriceOutputDisable:
				!DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"productRetailPriceOutputDisable",
					true
				),
			groupDropsRequireDate:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"dropsRequireDate",
					false
				),
			groupProductWholesalePriceDisable:
				!DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"productWholesalePriceDisable",
					true
				),
			groupProductWholesalePriceOutputDisable:
				!DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"productWholesalePriceOutputDisable",
					true
				),
			groupProductFilterOutOfStock:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"productFilterOutOfStock",
					false
				),
			groupOrderSubmissionAllProducts:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"orderSubmissionAllProducts",
					false
				),
			groupOrderSubmissionLockSentOrder:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"orderSubmissionLockSentOrder",
					false
				),
			groupOrderSubmissionReportFullError:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"orderSubmissionReportFullError",
					false
				),
			groupAllowDiscountLists:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionGroupParams,
					"allowDiscountLists",
					false
				),
		});
	}

	/**
	 * Pass aView inherited distribution parameters into a form.
	 *
	 * @param distributionParams                The distribution parameters to pass in.
	 * @param form                              The form to fill with values.
	 */
	public static setAViewDistributionParamsInheritToForm(
		distributionParams: ParameterAHubVO[],
		form: UntypedFormGroup
	) {
		form.patchValue({
			skuFiltersInherit: DistributionUtils.distributionParamValueSingleBoolean(
				distributionParams,
				"skuFiltersInherit",
				true
			),
			arangerDisabledInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"arangerDisabledInherit",
					true
				),
		});
	}

	/**
	 * Pass aWorkbook inherited distribution parameters into a form.
	 *
	 * @param distributionParams                The distribution parameters to pass in.
	 * @param form                              The form to fill with values.
	 */
	public static setAWDistributionParamsInheritToForm(
		distributionParams: ParameterAHubVO[],
		form: UntypedFormGroup
	) {
		form.patchValue({
			skuFiltersInherit: DistributionUtils.distributionParamValueSingleBoolean(
				distributionParams,
				"skuFiltersInherit",
				true
			),
			priceListInherit: DistributionUtils.distributionParamValueSingleBoolean(
				distributionParams,
				"priceListInherit",
				true
			),
			resourceFiltersInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"resourceFiltersInherit",
					true
				),
			assortmentFiltersInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"assortmentFiltersInherit",
					true
				),
			presentationFiltersInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"presentationFiltersInherit",
					true
				),
			preSavedAssortmentFiltersInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"preSavedAssortmentFiltersInherit",
					true
				),
			tabOrderDisableInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"tabOrderDisableInherit",
					true
				),
			tabPresentationDisableInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"tabPresentationDisableInherit",
					true
				),
			productRetailPriceDisableInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"productRetailPriceDisableInherit",
					true
				),
			productRetailPriceOutputDisableInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"productRetailPriceOutputDisableInherit",
					true
				),
			dropsRequireDateInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"dropsRequireDateInherit",
					true
				),
			productWholesalePriceDisableInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"productWholesalePriceDisableInherit",
					true
				),
			productWholesalePriceOutputDisableInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"productWholesalePriceOutputDisableInherit",
					true
				),
			productFilterOutOfStockInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"productFilterOutOfStockInherit",
					true
				),
			stockServerUrlInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"stockServerUrlInherit",
					true
				),
			stockServerSessionKeyInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"stockServerSessionKeyInherit",
					true
				),
			customOrderDataProviderServerUrlInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"customOrderDataProviderServerUrlInherit",
					true
				),
			customOrderDataProviderServerSessionKeyInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"customOrderDataProviderServerSessionKeyInherit",
					true
				),
			alternativeCoverAssetIdInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"alternativeCoverAssetIdInherit",
					true
				),
			colourSchemeIdInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"colourSchemeIdInherit",
					true
				),
			orderDropTypeOptionsInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"orderDropTypeOptionsInherit",
					true
				),
			orderDropReferenceTypeInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"orderDropReferenceTypeInherit",
					true
				),
			orderSummaryAssortmentGroupIdInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"orderSummaryAssortmentGroupIdInherit",
					true
				),
			allowDiscountListsInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"allowDiscountListsInherit",
					true
				),

			// OK this item is slightly special. We need a inherite property for
			// 'orderSubmissionServerTypeInherit', 'orderSubmissionServerUrlInherit', 'orderSubmissionServerKeyInherit'
			// they should also be the same. so we will use one of the values from the params to set the values and have it represented by a single form value
			// when we then read it all back out we will set both props based on this form
			orderSubmissionServerInherit:
				DistributionUtils.distributionParamValueSingleBoolean(
					distributionParams,
					"orderSubmissionServerTypeInherit",
					true
				),
		});
	}

	/**
	 * This function will build the distribution params from a form group.
	 *
	 * @param form      The form to get the parameters from.
	 */
	public static buildDistributionParamsFromForm(
		form: UntypedFormGroup,
		onlyDirtyControls?: boolean
	): ParameterAHubVO[] {
		// Create a new set of parameters to fill.
		const parameters: Set<ParameterAHubVO> = new Set();

		// Get the list of keys in the controls, then repeat through them.
		Object.keys(form.controls).forEach((controlName) => {
			// Get each control.
			const formControl = form.get(controlName);

			// Skip un-dirty controls when we want too.
			if (!formControl.dirty && onlyDirtyControls) {
				return;
			}

			// Is this our agregated order submission inherit? If so we will need to convert this back into our two proper
			// parameter types with the same values.
			if (controlName === "orderSubmissionServerInherit") {
				// Value we want to set,
				const orderInheritValToSet = this.buildParamValues(
					formControl as UntypedFormControl,
					controlName
				);

				// Add these items to the list.
				parameters.add({
					id: "orderSubmissionServerTypeInherit",
					values: orderInheritValToSet,
				});
				parameters.add({
					id: "orderSubmissionServerUrlInherit",
					values: orderInheritValToSet,
				});
				parameters.add({
					id: "orderSubmissionServerKeyInherit",
					values: orderInheritValToSet,
				});
				parameters.add({
					id: "orderSubmissionAllProductsInherit",
					values: orderInheritValToSet,
				});
				parameters.add({
					id: "orderSubmissionLockSentOrderInherit",
					values: orderInheritValToSet,
				});
				parameters.add({
					id: "orderSubmissionReportFullErrorInherit",
					values: orderInheritValToSet,
				});
			}

			//We only want to put away parameter values specific to this distribution
			else if (
				!controlName.includes("group") &&
				!controlName.includes("distributionNotes")
			) {
				// Add the value after we build the param values from the form control.
				parameters.add({
					id: controlName,
					values: DistributionUtils.buildParamValues(
						formControl as UntypedFormControl,
						controlName
					),
				});
			} else {
				return;
			}
		});

		// Convert the set of parameters into an array and return.
		return Array.from(parameters);
	}

	/**
	 * This function will take a form control and
	 *
	 * @param formControl           The form contol who's value we want to convert into param values.
	 * @param controlName           The name of the form control.
	 */
	private static buildParamValues(
		formControl: UntypedFormControl,
		controlName: string
	) {
		// Is the form controls value already an array? If so, just return it.
		if (Array.isArray(formControl.value)) {
			return formControl.value;
		}

		// Is the form control value true or false?
		if (formControl.value === true || formControl.value === false) {
			// If so, does it end with "Disable" if so we want to invert the value, convert into a string and put it in a new array.
			if (controlName.endsWith("Disable")) {
				return [(!formControl.value).toString()];
			}

			// Otherwise just convert the boolean value into a string, and put it into a new array.
			return [formControl.value.toString()];
		}

		// If we get here then just put the value into a new array.
		return [formControl.value];
	}

	// map string operator to a function that accepts two parameters and returns result of performed operation
	public static mapOperatorToFunction(
		operator: string
	): (a: string, b: string) => boolean {
		switch (operator) {
			case "EQUALS":
				return (a: string, b: string) => a === b;
			case "NOT_EQUALS":
				return (a: string, b: string) => a !== b;
			case "LESS_OR_EQUAL":
				// if parameters aren't parsable parseInt returns NaN
				// which results in false for every operator (even for '===')
				// so we don't need to check for NaNs
				return (a: string, b: string) => parseInt(a) <= parseInt(b);
			case "GREATER_OR_EQUAL":
				return (a: string, b: string) => parseInt(a) >= parseInt(b);
			case "LIKE":
			case "CONTAINS":
				return (a: string, b: string) => a.includes(b);
			case "NOT_CONTAINS":
				return (a: string, b: string) => !a.includes(b);
			case "BLANK":
				return (a: string, b: string) =>
					a === "" || a === undefined || a === null;
			case "NOT_BLANK":
				return (a: string, b: string) =>
					a !== "" && a !== undefined && a !== null;
			default:
				// if unknown operator ignore the test (always pass)
				return (a: string, b: string) => true;
		}
	}

	// Build an object suitable for the product filter component from the distribution params (productFilter)
	public static buildProductFilterFromParams(
		paramsProductFilters: ParameterAHubVO
	): DistributionAViewProductFilterAHubVO {
		if (!paramsProductFilters) {
			return undefined;
		}

		let productFilter: DistributionAViewProductFilterAHubVO = undefined;

		const paramValues: string[] = paramsProductFilters.values || [];

		let propertyFilterRules: DistributionAViewPropertyRuleAHubVO[] = [];

		propertyFilterRules = paramValues.map((valuesPropertyRule) => {
			let propertyFilterTests: DistributionAViewPropertyTestAHubVO[] = [];
			try {
				propertyFilterTests = JSON.parse(valuesPropertyRule);
			} catch (error) {
				console.error(
					"Unable to parse productFilter rule: ",
					valuesPropertyRule
				);
			}

			return propertyFilterTests
				? { propertyTests: propertyFilterTests }
				: undefined;
		});

		if (propertyFilterRules) {
			productFilter = {
				propertyRules: propertyFilterRules,
			};
		}

		return productFilter;
	}

	/**
	 * Function to sort distribution groups in place (modify array and return reference to the same array)
	 *
	 * @param distributionGroups
	 * @param order Order of result array (1: a -> z; -1: z -> a)
	 * @returns Alphabetically sorted array of distribution group by name in specified order
	 */
	public static sortDistributionIndexesByName(
		distributionIndexes: DistributionIndexAHubVO[],
		order: 1 | -1 = 1
	): DistributionIndexAHubVO[] {
		return distributionIndexes?.sort((a, b) => {
			const nameA: string = a.distributionGroupName?.[0]?.toUpperCase();
			const nameB: string = b.distributionGroupName?.[0]?.toUpperCase();

			if (nameA > nameB) {
				return 1 * order;
			}

			if (nameA < nameB) {
				return -1 * order;
			}

			return 0;
		});
	}

	/**
	 * Function to sort export distribution indexes in place (modify array and return reference to the same array)
	 *
	 * @param exportDistributionIndexes
	 * @param order Order of result array (1: a -> z; -1: z -> a)
	 * @returns Alphabetically sorted array of distribution group by name in specified order
	 */
	public static sortExportDistributionIndexesByName(
		exportDistributionIndexes: ExportDistributionIndexAHubVO[],
		order: 1 | -1 = 1
	): ExportDistributionIndexAHubVO[] {
		return exportDistributionIndexes?.sort((a, b) => {
			const nameA: string = a.distributionGroupNames?.[0]?.toUpperCase();
			const nameB: string = b.distributionGroupNames?.[0]?.toUpperCase();

			if (nameA > nameB) {
				return 1 * order;
			}

			if (nameA < nameB) {
				return -1 * order;
			}

			return 0;
		});
	}
}
