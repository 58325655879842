import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	Input,
	OnInit,
} from "@angular/core";
import { DialogService } from "app/modules/common/dialogs/dialog.service";
import {
	componentDestroyStream,
	Hark,
} from "app/modules/common/hark.decorator";
import { Utils } from "app/modules/common/utils";
import { PropertyAViewVO } from "app/modules/routes/aview/valueObjects/property.aview.vo";
import { aHubStateTemporaryProductPropertyList } from "app/store/selector/ahub/ahub-temporary.selector";
import { StoreAccess } from "app/store/store-access";
import { DistributionAViewProductFilterAHubVO } from "app/valueObjects/ahub/accounts/distribution-aview-product-filter.ahub.vo";
import { EntityPermissions } from "app/valueObjects/ahub/accounts/entity-permissions.ahub";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import {
	AviewDistributionProductFilterTestDialogComponent,
	ProductFilterTestDialogVO,
} from "./aview-distribution-product-filter-test-dialog/aview-distribution-product-filter-test-dialog.component";
import { PropertyTestByRuleIndexVO } from "./product-filter-rule/product-filter-rule.component";

@Component({
	selector: "app-distribution-aview-product-filter",
	templateUrl: "./distribution-aview-product-filter.component.html",
	styleUrls: ["./distribution-aview-product-filter.component.css"],
})
@Hark()
export class DistributionAviewProductFilterComponent implements OnInit {
	/**
	 * These are the distribution editor permissions required to
	 * add/remove restrictions.
	 */
	distributionEditor = EntityPermissions.DISTRIBUTION_EDITOR;

	@Input()
	productFilter$: BehaviorSubject<DistributionAViewProductFilterAHubVO>;

	@Input() productFiltersInherited$: BehaviorSubject<boolean>;

	@Input() isGroupSettings: boolean;

	@Input() readOnly = false;

	@Input() userExportReadOnly = false;

	aViewProductProperties$: Observable<PropertyAViewVO[]> =
		StoreAccess.dataGetObvs(aHubStateTemporaryProductPropertyList).pipe(
			map((propertyList) => propertyList.data)
		);
	properties: PropertyAViewVO[];

	/**
	 * Are we allowing the adding of property rules?
	 */
	allowPropertyRuleToBeAdded: boolean = true;

	/**
	 * Are we allowing property tests to be added?
	 */
	allowPropertyTestsToBeAdded: boolean = true;

	/**
	 * Stream which shows if the delete is busy.
	 */
	@Input()
	updateBusy$: Observable<boolean> = undefined;

	productFilter: DistributionAViewProductFilterAHubVO;

	constructor(
		private dialogueService: DialogService,
		private resolver: ComponentFactoryResolver
	) {}

	ngOnInit() {
		this.aViewProductProperties$
			.pipe(
				Utils.isNotNullOrUndefined(),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((properties) => (this.properties = properties));

		this.productFilter$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
			)
			.subscribe((productFilter) => (this.productFilter = productFilter));

		this.productFiltersInherited$
			.pipe(Utils.isNotNullOrUndefined())
			.subscribe((inherited) => (this.readOnly = inherited));
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * This function is called when the user clicks on the add property rule button.
	 * This is the first level of interaction, you then add tests to the rule.
	 */
	addPropertyRule() {
		// Make sure we have a set of product filters to display. If not, set some up.
		if (!this.productFilter || !this.productFilter.propertyRules) {
			this.productFilter = { propertyRules: [] };
		}

		// Add the new property rules test (to the last element).
		this.addPropertyTest(this.productFilter.propertyRules.length, true);
	}

	/**
	 * This function is called when we want to create a new property test.
	 */
	addPropertyTest(propertyRuleIndex, addingOr) {
		// Get the copmponent Factory we will use to create the component dialog
		// we will use to create a new export entry.
		const componentFactory: ComponentFactory<AviewDistributionProductFilterTestDialogComponent> =
			this.resolver.resolveComponentFactory(
				AviewDistributionProductFilterTestDialogComponent
			);

		// Create a new client.
		const dialogVO: ProductFilterTestDialogVO = {
			properties: this.properties,
		};

		// Open the custom component dialogue , using the component factory to create the component content, passing in a blank VO.
		const newDialogue = this.dialogueService.componentDialogOpen(
			"Add property filter rule.",
			componentFactory,
			"dialogVO",
			dialogVO,
			"dialogIsValid$",
			"Add",
			"Cancel"
		);

		//Subscribe to the new export dialogue, if we recive an export then we will add it
		newDialogue.subscribe((dialogVO) => {
			//If the new VO is undefined then we will bail out
			if (!dialogVO || dialogVO.propertyTest == undefined) {
				return;
			}

			// Are we adding an or test?
			if (addingOr) {
				// Yes, so add a new or level property test.
				this.productFilter.propertyRules.push({ propertyTests: [] });
			}

			// If the operator has been set to 'BLANK' or 'NOT_BLANK' we should clear the (now irrelevent) 'value'
			if (
				dialogVO.propertyTest.operator === "BLANK" ||
				dialogVO.propertyTest.operator === "NOT_BLANK"
			) {
				dialogVO.propertyTest.value = undefined;
			}

			// Now Put the property test into the correct spot.
			this.productFilter.propertyRules[propertyRuleIndex].propertyTests.push(
				dialogVO.propertyTest
			);

			// Call the property test added function.
			this.productFilter$.next(this.productFilter);
		});
	}

	editPropertyTest(propertyTestByRuleIndex: PropertyTestByRuleIndexVO) {
		// Get the copmponent Factory we will use to create the component dialog
		// we will use to create a new export entry.
		const componentFactory: ComponentFactory<AviewDistributionProductFilterTestDialogComponent> =
			this.resolver.resolveComponentFactory(
				AviewDistributionProductFilterTestDialogComponent
			);

		// Create a new client.
		const dialogVO: ProductFilterTestDialogVO = {
			properties: this.properties,
			propertyTest: propertyTestByRuleIndex.propertyTest,
		};

		// Open the custom component dialogue , using the component factory to create the component content, passing in a blank VO.
		const newDialogue = this.dialogueService.componentDialogOpen(
			"Edit Product Filter.",
			componentFactory,
			"dialogVO",
			dialogVO,
			"dialogIsValid$",
			"Save",
			"Cancel"
		);

		//Subscribe to the new export dialogue, if we recive an export then we will add it
		newDialogue.subscribe((dialogVO) => {
			//If the new VO is undefined then we will bail out
			if (!dialogVO || dialogVO.propertyTest == undefined) {
				return;
			}

			this.productFilter.propertyRules[
				propertyTestByRuleIndex.propertyRuleIndex
			].propertyTests.splice(
				propertyTestByRuleIndex.propertyTestIndex,
				1,
				dialogVO.propertyTest
			);

			this.productFilter$.next(this.productFilter);
		});
	}

	/**
	 * This handler is called when the user clicks on the delete property test function.
	 *
	 * @param propertyTestByRuleIndex         The test to delete by rule and test index.
	 */
	deletePropertyTest(propertyTestByRuleIndex: PropertyTestByRuleIndexVO) {
		// Open a dialogue to check they want to delete the property.
		const dialogRef: Observable<boolean> =
			this.dialogueService.confirmDialogOpen(
				"Delete filter?",
				"Are you sure you want to delete this filter?"
			);

		// Subscribe to the result of the dialogue box.
		dialogRef
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((result) => {
				// Was the delete confirmed?
				if (result === true) {
					this.productFilter.propertyRules[
						propertyTestByRuleIndex.propertyRuleIndex
					].propertyTests.splice(propertyTestByRuleIndex.propertyTestIndex, 1);

					// Did we remove the only remaining test in this rule?
					if (
						this.productFilter.propertyRules[
							propertyTestByRuleIndex.propertyRuleIndex
						].propertyTests.length === 0
					) {
						// Yes, so remove it from the display list.
						this.productFilter.propertyRules.splice(
							propertyTestByRuleIndex.propertyRuleIndex,
							1
						);
					}

					this.productFilter$.next(this.productFilter);
				}
			});
	}

	/**
	 * This function is called when the user clicks on the delete all button.
	 *
	 * @param propertyRuleIndex       The index of the property rule to delete.
	 */
	deletePropertyRule(propertyRuleIndex: number) {
		// Open a dialogue to check they want to delete the property.
		const dialogRef: Observable<boolean> =
			this.dialogueService.confirmDialogOpen(
				"Delete all filters?",
				"Are you sure you want to delete all filters?"
			);

		// Subscribe to the result of the dialogue box.
		dialogRef
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((result) => {
				// Was the delete confirmed?
				if (result === true) {
					this.productFilter.propertyRules.splice(propertyRuleIndex, 1);

					this.productFilter$.next(this.productFilter);
				}
			});
	}

	inheritOnChange($event) {
		this.productFiltersInherited$.next($event.checked);
	}
}
