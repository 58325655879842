import { Component, OnInit, Input, OnDestroy } from "@angular/core";

import * as treeUtils from "../tree-utils";
import { TreeStructure } from "../tree-structure.class";

import { Hark } from "modules/common/hark.decorator";

@Component({
	selector: "app-node-details",
	templateUrl: "./node-details.component.html",
	styleUrls: ["./node-details.component.css"],
})
@Hark()
export class NodeDetailsComponent implements OnInit, OnDestroy {
	@Input() tree;

	/**
	 * Observe the currently selected workGroup so we can display its data.
	 */
	nodesToRoot: TreeStructure[] = [];

	toggleExpandAll = false;

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		this.nodesToRoot = treeUtils.getPathOfTreeStructureNodesToRoot(this.tree);
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}
}
