import { HttpClient } from "@angular/common/http";
import { resourcePackFileUrlGet } from "app/modules/routes/aview/utils/aview-utils";
import { AViewVO } from "app/modules/routes/aview/valueObjects/aview.aview.vo";
import { ExporterExportSettingsVO } from "app/modules/routes/aview/valueObjects/exporter-export-settings.vo";
import { LeafletGroupAViewVO } from "app/modules/routes/aview/valueObjects/leaflet-group.aview.vo";
import { StructureAViewVO } from "app/modules/routes/aview/valueObjects/structure.aview.vo";
import { AHubService } from "app/services/ahub/ahub.service";
import { combineLatest, from, Observable } from "rxjs";
import { filter, map, mergeMap, reduce } from "rxjs/operators";
import { AViewActions } from "../actions/aview.actions";
import { ActionAViewAssetFetch } from "../actions/types/aview.action-types";
import {
	ActionNumber,
	ActionNumberNumber,
	ActionNumberNumberString,
} from "../actions/types/common.action-types";
import { Epic } from "./epic";
import { EpicsBase } from "./epics-base";

/**
 * Class for the View epic functions
 */
export class AViewEpics extends EpicsBase implements Epic {
	constructor(
		private readonly aHubService: AHubService,
		private readonly http: HttpClient
	) {
		super();
	}

	/**
	 * Returns a list of the epic function avalible from this class
	 * If a new epic is added to the class it must be added to this list
	 */
	epicMethods(): any[] {
		return [
			this.publicationEditionPathUrlFetch,
			this.aViewFetch,
			this.aViewPublicationEditionAssetsFetch,
			this.aViewPublicationEditionAssetsByIdsFetch,
			this.exportClientLogoGet,
		];
	}

	/**
	 * -----------------------------------------------------
	 * Epics
	 * -----------------------------------------------------
	 */

	exportClientLogoGet = (action$: Observable<ActionNumber>) => {
		return action$.pipe(
			filter(({ type }) => type === AViewActions.AVIEW_EXPORT_CLIENT_LOGO_GET),
			mergeMap((action) => {
				return this.requestIndexSingleDataToAction(
					this.aHubService.exportClientLogoGet(action.number),
					action,
					AViewActions.exportClientLogoSet
				);
			})
		);
	}

	publicationEditionPathUrlFetch = (
		action$: Observable<ActionNumberNumber>
	) => {
		return action$.pipe(
			filter(
				({ type }) =>
					type === AViewActions.AVIEW_PUBLICATION_EDITION_PATH_URL_FETCH
			),
			mergeMap((action) =>
				this.requestIndexSingleDataToAction(
					this.aHubService.exportVersionPathUrl(action.number1, action.number2),
					{
						actionId: action.actionId,
						number: action.number1,
						type: action.type,
					},
					AViewActions.publicationEditionPathURLSet
				)
			)
		);
	};

	aViewFetch = (action$: Observable<ActionNumberNumber>) => {
		return action$.pipe(
			filter(({ type }) => type === AViewActions.AVIEW_FETCH),
			mergeMap((action) => {
				const publicationId: number = action.number1;
				const editionNumber: number = action.number2;

				//Call out function download all the aView data and turn it into a aView object
				//then we will set the data in store via action
				return this.requestSingleDataToAction(
					this.aViewDownloadDataToAView(publicationId, editionNumber),
					action,
					AViewActions.aViewSet
				);
			})
		);
	};

	/**
	 * Download the aView data and convert it into an aView object for a specified publication and edition
	 *
	 * @param publicationId         Publication we want the data for
	 * @param editionNumber         The edition of the publication we want the data for
	 */
	private aViewDownloadDataToAView(
		publicationId: number,
		editionNumber: number
	): Observable<AViewVO> {
		//File which we want to be able to download
		const requiredDataFiles = [
			"productData",
			"structureData",
			"categoryData",
			"exportSettings",
			"leafletData",
		];

		//First we will need to go and get a presigned URL for our publication and edition
		return combineLatest([
			this.aHubService.exportVersionPathUrl(publicationId, editionNumber),
			this.aHubService.exportVersionAssets(publicationId, editionNumber),
		]).pipe(
			mergeMap(([presignedURL, assets]) => {
				//We will then use the URL to create a URL for the manifest file
				const manifestDownloadPath = presignedURL.signedUrl.replace(
					"*",
					"manifest.json"
				);

				//Go and access the manifest file! This will contail the full list of files we need to access
				//for the aView.
				return this.http
					.get(manifestDownloadPath, {
						responseType: "json",
					})
					.pipe(
						mergeMap((manifest) => {
							//We want to extract the file paths for the things in the manifest
							//we will just check that the files we are going to download are on our required data files lists
							//names aren't exact matches as some pieces of data are splt across multiple files
							const manifestItemPaths = (manifest["items"] as any[])
								.map((item) => item["itemPath"] as string)
								.filter(
									(path) =>
										requiredDataFiles.find((item) => path.startsWith(item)) !==
										undefined
								);

							//For each of the items in the manifiest we want to go and download the data
							return from(manifestItemPaths).pipe(
								// Data Request ====================================
								// Take the item path from the manifest and create a presigned URL for it
								// Once the data has been returned we will map this to a simple temp object
								// which will make our lives easier in the REDUCE later.
								//
								// IMPORTANT NOTE: At the end of the merge map we have limited the amount of files which will be
								// Downloaded simultaniously.
								mergeMap(
									(manifestItemPath) =>
										this.http
											.get(
												presignedURL.signedUrl.replace("*", manifestItemPath),
												{
													responseType: "json",
												}
											)
											.pipe(map((data) => ({ key: manifestItemPath, data }))),
									4
								),

								// Reduction ====================================
								// As out data starts filing back from our requests we want to colate it into
								// it's appropriate parts of the aView object.
								// We define an inital object as part of the definition which we will use to fill
								// when all requests are finished this object will be returned
								reduce(
									(existing, value) => {
										if (value.key.indexOf("productData") === 0) {
											existing.products.push(...(value.data as any[]));
										} else if (value.key.indexOf("structureData") === 0) {
											existing.structure = value.data as StructureAViewVO;
										} else if (value.key.indexOf("categoryData") === 0) {
											existing.categories.push(...(value.data as any[]));
										} else if (value.key.indexOf("exportSettings") === 0) {
											existing.exportSetings =
												value.data as ExporterExportSettingsVO;
										} else if (value.key.indexOf("leafletData") === 0) {
											// Get the new data.
											const newData = (value.data as any)
												.leafletGroups as LeafletGroupAViewVO[];

											// Give all of the leaflet assets a presigned URL.
											newData.forEach((leafletGroup) => {
												// Do we have any leaflets? Nope, skip on.
												if (!leafletGroup.leaflets) {
													return;
												}

												// If we get there then we need to loop through the leaflets.
												leafletGroup.leaflets.forEach((leaflet) => {
													// Do we have any assets? Nope, ski on.
													if (!leaflet.assets) {
														return;
													}

													// Now replace the path to the leaflet assets with pre-signed URL'.s
													leaflet.assets = leaflet.assets.map(
														(leafletAsset) => {
															leafletAsset.assetUrl = resourcePackFileUrlGet(
																presignedURL,
																leafletAsset.asset
															);
															leafletAsset.placeholderUrl =
																resourcePackFileUrlGet(
																	presignedURL,
																	leafletAsset.placeholder
																);
															return leafletAsset;
														}
													);
												});
											});

											// Add to the existing data.
											existing.leafletData.push(...newData);
										}

										//Return the existing data now its been modified
										return existing;
									},
									{
										categories: [],
										products: [],
										structure: undefined,
										exportSetings: undefined,
										publicationId: publicationId,
										edition: editionNumber,
										assets,
										leafletData: [],
									} as AViewVO
								)
							);
						})
					);
			})
		);
	}

	private aViewPublicationEditionAssetsFetch = (
		action$: Observable<ActionNumberNumberString>
	) => {
		return action$.pipe(
			filter(
				({ type }) =>
					type === AViewActions.AVIEW_PUBLICATION_EDITION_ASSETS_FETCH
			),
			mergeMap((action) => {
				return this.requestDataToAction(
					this.aHubService.exportVersionAssets(action.number1, action.number2),
					action,
					(res) =>
						AViewActions.aViewComponentPublicationEditionAssetsSet(
							action.string,
							action.number1,
							action.number2,
							res
						)
				);
			})
		);
	};

	private aViewPublicationEditionAssetsByIdsFetch = (
		action$: Observable<ActionAViewAssetFetch>
	) => {
		return action$.pipe(
			filter(
				({ type }) =>
					type === AViewActions.AVIEW_PUBLICATION_EDITION_ASSETS_BY_IDS_FETCH
			),
			mergeMap((action) => {
				return this.requestDataToAction(
					this.aHubService.exportVersionAssetsByIds(
						action.publicationId,
						action.editionNumber,
						action.assetIds
					),
					action,
					(res) =>
						AViewActions.aViewComponentPublicationEditionAssetsAppend(
							action.componentId,
							action.publicationId,
							action.editionNumber,
							res
						)
				);
			})
		);
	};
}
