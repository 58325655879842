/**
 *----------------------------------------
 * Core + Util Imports
 *----------------------------------------
 */

import { AHubActions } from "actions/ahub.actions";
/**
 *----------------------------------------
 * Action Types Imports
 *----------------------------------------
 */
import {
	ActionClientIndexAHubVOs,
	ActionClientStorageIndexAHubVOs,
	ActionExportGeneratorAHubVOs,
	ActionExportTypeAHubVOs,
	ActionUserArrayAHubVO,
	ActionWorkGroupAHubVOs,
} from "actions/types/ahub-accounts.action-types";
import { ActionWorklogSegmentAHubVO } from "actions/types/ahub-work.action-types";
import { ActionBoolean, ActionDate } from "actions/types/common.action-types";
import { UserExtendedAHubVO } from "app/valueObjects/ahub/accounts/user-extended.ahub.vo";
import { tassign } from "modules/common/type-assign.util";
import { createReducer } from "reducers/reducer-util";
import { Action, Reducer } from "redux";
import { IdListUtil } from "store/id-list.util";
import { List } from "store/list.vo";
/**
 *----------------------------------------
 * Value Objects Imports
 *----------------------------------------
 */
import { ClientIndexAHubVO } from "valueObjects/ahub/accounts/client-index.ahub.vo";
import { ClientStorageIndexAHubVo } from "valueObjects/ahub/accounts/client-storage-index.ahub.vo";
import { ExportGeneratorAHubVO } from "valueObjects/ahub/accounts/export-generator.ahub.vo";
import { ExportTypeAHubVO } from "valueObjects/ahub/accounts/export-type.ahub.vo";
import { WorkGroupAHubVO } from "valueObjects/ahub/accounts/work-group.ahub.vo";
import { WorklogSegmentAHubVO } from "valueObjects/ahub/work/worklog-segment.ahub.vo";
import { WorklogAHubVO } from "valueObjects/ahub/work/worklog.ahub.vo";

/**
 * ----------------------------------
 * aHub Permenant State interface
 * ----------------------------------
 */
export interface AHubPermanentState {
	users: List<UserExtendedAHubVO>;
	clientIndexs: ClientIndexAHubVO[];
	clientStorageIndexes: ClientStorageIndexAHubVo[];
	workGroups: List<WorkGroupAHubVO>;
	worklogs: List<WorklogAHubVO>;
	worklogRequestOutstanding: number;
	isConnected: boolean;
	worklogSegmentLast: WorklogSegmentAHubVO;
	exportTypes: ExportTypeAHubVO[];
	exportGenerators: ExportGeneratorAHubVO[];
	userHasAViews: boolean;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */

export const AHubPermanentStateInitial: AHubPermanentState = {
	users: IdListUtil.listCreateEmpty(),
	clientIndexs: [],
	clientStorageIndexes: [],
	workGroups: IdListUtil.listCreateEmpty(),
	worklogs: IdListUtil.listCreateEmpty(),
	worklogRequestOutstanding: 0,
	isConnected: true,
	worklogSegmentLast: {
		worklogLastestRecievedTime: undefined,
		workLogLastestUpdateTime: undefined,
		workLogs: [],
	},
	exportTypes: [],
	exportGenerators: [],
	userHasAViews: false,
};

/**
 * ----------------------------------
 * Reducers Handlers
 * ----------------------------------
 */

/**
 * Reset this permanent section of the store
 */
const permanentStoreClear = (state: AHubPermanentState, action: Action) => {
	//Return the initial state of the permanent store this will reset it to default
	return AHubPermanentStateInitial;
};

/**
 * A comms error has occured , store the fact !
 */
const commsError = (state: AHubPermanentState, action: Action) => {
	//Update the worklog pending flag to false to allow a fresh query.
	return tassign(state, { isConnected: false });
};

/**
 * Set the user
 */
const sessionUsersSet = (
	state: AHubPermanentState,
	action: ActionUserArrayAHubVO
) => {
	// Get existing store list, filter out any items that have a matching object id in the list supplied, then add the supplied list.
	return tassign(state, {
		users: IdListUtil.listAppend(state.users, action.users),
	});
};

/**
 * Set whether the user has aViews or not.
 *
 * @param state           The state to update.
 * @param action          The action to update with.
 */
const userHasAViewsSet = (state: AHubPermanentState, action: ActionBoolean) => {
	return tassign(state, { userHasAViews: action.boolean });
};

/**
 * Set the client index list to the one provided.
 */
const clientIndexsSet = (
	state: AHubPermanentState,
	action: ActionClientIndexAHubVOs
) => {
	// Make the call to add the new list of client indexes to the current list.
	return tassign(state, { clientIndexs: action.clientIndexs });
};

/**
 * Set the client index list to the one provided.
 */
const clientStorageIndexesSet = (
	state: AHubPermanentState,
	action: ActionClientStorageIndexAHubVOs
) => {
	// Make the call to add the new list of client indexes to the current list.
	return tassign(state, { clientStorageIndexes: action.clientStorageIndexes });
};

/**
 * Set the permanent work groups.
 */
const workGroupsSet = (
	state: AHubPermanentState,
	action: ActionWorkGroupAHubVOs
) => {
	//Set the dataSets
	return tassign(state, {
		workGroups: IdListUtil.listAppend(state.workGroups, action.workGroups),
	});
};

/**
 * Set the worklog segment
 */
const worklogSegmentSet = (
	state: AHubPermanentState,
	action: ActionWorklogSegmentAHubVO
) => {
	// Set the recieved time..
	action.worklogSegment.worklogLastestRecievedTime = new Date();

	//New worklogs
	const newWorklogSegment = action.worklogSegment;

	// If there are no worklogs, will create an empty array, so the later addition dosn't have an issue.
	if (!newWorklogSegment.workLogs) {
		newWorklogSegment.workLogs = [];
	}

	//Append the current worklog items to the existing worklog state
	const worklogs = IdListUtil.listAppend(
		state.worklogs,
		action.worklogSegment.workLogs
	);

	// Get existing store list, filter out any items that have a matching object id in the list supplied, then add the supplied list.
	return tassign(state, {
		worklogs,
		worklogRequestOutstanding: Math.max(0, state.worklogRequestOutstanding - 1),
		worklogSegmentLast: action.worklogSegment,
		isConnected: true, // If we have worklogs we must be connected.
	});
};

/**
 * Purge the worklogs
 */
const worklogPurge = (state: AHubPermanentState, action: ActionDate) => {
	// filter out the old worklogs.
	return tassign(state, {
		worklogs: IdListUtil.listFilter(
			state.worklogs,
			(worklog: WorklogAHubVO) =>
				!worklog.complete ||
				new Date(worklog.endTime).getTime() > action.date.getTime()
		),
	});
};

/**
 * Worklog segment fetch function
 */
const worklogSegmentFetch = (state: AHubPermanentState, action: Action) => {
	//Update the worklog requests outstanding count
	// NB, we don't set connection error here.. we'll be sending another action to do this which includes reporting.
	// which is attached to any 50x error being reported.
	return tassign(state, {
		worklogRequestOutstanding: state.worklogRequestOutstanding + 1,
	});
};

/**
 * Worklog fetch error, update worklog to recover.
 */
const worklogSegmentFetchError = (
	state: AHubPermanentState,
	action: Action
) => {
	console.log("worklogSegmentFetchError", state.worklogRequestOutstanding - 1);

	// Update the worklog outstaniding count to false to allow a fresh query.
	// If we have had an error , we can;t be connected.
	return tassign(state, {
		worklogRequestOutstanding: Math.max(0, state.worklogRequestOutstanding - 1),
		isConnected: false,
	});
};

/**
 * Set all of the export types.
 *
 * @param state             The state to set on.
 * @param action            The action to get the export types from.
 */
const exportTypesAllSet = (
	state: AHubPermanentState,
	action: ActionExportTypeAHubVOs
) => {
	// Update the export types.
	return tassign(state, { exportTypes: action.exportTypes });
};

/**
 * Set all of the export generators.
 *
 * @param state             The state to set on.
 * @param action            The action to get the export generators from.
 */
const exportGeneratorsAllSet = (
	state: AHubPermanentState,
	action: ActionExportGeneratorAHubVOs
) => {
	// Update the export generators.
	return tassign(state, { exportGenerators: action.exportGenerators });
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
const reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to
 */
reducerHandlers[AHubActions.STORE_PERMANENT_CLEAR] = permanentStoreClear;
reducerHandlers[AHubActions.COMMS_ERROR] = commsError;
reducerHandlers[AHubActions.SESSION_USERS_SET] = sessionUsersSet;
reducerHandlers[AHubActions.USER_HAS_AVIEWS_SET] = userHasAViewsSet;
reducerHandlers[AHubActions.WORK_GROUPS_PERMANENT_SET] = workGroupsSet;
reducerHandlers[AHubActions.USER_CLIENTS_INDEXS_SET] = clientIndexsSet;
reducerHandlers[AHubActions.CLIENT_STORAGE_INDEXES_SET] =
	clientStorageIndexesSet;
reducerHandlers[AHubActions.WORKLOG_SEGMENT_SET] = worklogSegmentSet;
reducerHandlers[AHubActions.WORKLOG_PURGE] = worklogPurge;
reducerHandlers[AHubActions.WORKLOG_SEGMENT_FETCH] = worklogSegmentFetch;
reducerHandlers[AHubActions.WORKLOG_SEGMENT_FETCH_ERROR] =
	worklogSegmentFetchError;
reducerHandlers[AHubActions.EXPORT_TYPES_ALL_SET] = exportTypesAllSet;
reducerHandlers[AHubActions.EXPORT_GENERATORS_ALL_SET] = exportGeneratorsAllSet;

//Create a reducers based on the reducers handlers
export const AHubPermanentReducer: Reducer<AHubPermanentState> = createReducer(
	AHubPermanentStateInitial,
	reducerHandlers
);

//Check if this reducers can handel the function specified
export const AHubPermanentReducerHasHandler = (actionType: string): boolean =>
	reducerHandlers.hasOwnProperty(actionType);
