import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from "@angular/core";
import { AHubActions } from "actions/ahub.actions";
import { ProductClassIndexAHubVO } from "app/valueObjects/ahub/library/product-class-index.ahub.vo";
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
import { Observable, of } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
/**
 * Selectors
 */
import {
	aHubStateTemporaryProductClassIndexes,
	aHubStateTemporaryProductPropertyAllocationIndexes,
	aHubStateTemporaryProductPropertyIndexes,
	aHubStateTemporaryProductPropertySectionIndexes,
} from "selector/ahub/ahub-temporary.selector";
import { viewPropertyIconMap } from "selector/view/view-library-classification-class.selector";
/**
 * Store Access
 */
import { StoreAccess } from "store/store-access";
import { ProductPropertyIndexAHubVO } from "valueObjects/ahub/library/product-property-index.ahub.vo";
import { ProductPropertySectionIndexAHubVO } from "valueObjects/ahub/library/product-property-section-index.ahub.vo";

@Component({
	selector: "app-property-test",
	templateUrl: "./property-test.component.html",
	styleUrls: ["./property-test.component.css"],
})
@Hark()
export class PropertyTestComponent implements OnInit, OnDestroy {
	@Input() propertyTest;
	@Input() allowPropertyTestsToBeAdded: boolean;

	@Output() propertyTestDelete: EventEmitter<void> = new EventEmitter<void>();
	@Output() editPropertyTest: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Get the product property indexes from the system
	 */
	productPropertyIndex$: Observable<ProductPropertyIndexAHubVO[]> =
		StoreAccess.dataGetObvs(aHubStateTemporaryProductPropertyIndexes);

	//Setup the streams which will be the core of our data
	property$: Observable<ProductPropertyIndexAHubVO> = of(null);
	section$: Observable<ProductPropertySectionIndexAHubVO> = of(null);

	class$: Observable<ProductClassIndexAHubVO> = of(null);

	//Stream for the property icon
	propertyIcon$: Observable<string> = of("");

	propertyIconMap = StoreAccess.dataGet(viewPropertyIconMap);

	isBusy: boolean = false;

	valueSurrounds: string = "";

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		// Put quotes around non number values
		let reg = new RegExp(/^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/);
		this.valueSurrounds = reg.test(this.propertyTest.value) ? "" : '"';

		StoreAccess.dispatch(AHubActions.productPropertyIndexsFetch());
		StoreAccess.dispatch(AHubActions.productPropertySectionIndexsFetch());
		StoreAccess.dispatch(AHubActions.productPropertyAllocationsFetch());

		//Subscribe to the allocation indexes
		StoreAccess.dataGetObvs(aHubStateTemporaryProductPropertyAllocationIndexes)
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((allocs) => {
				//If we have no allocations bail out
				if (!allocs) return;

				//Ok we need to find our allocation. This will allow us to know which property
				let alloc = allocs.find(
					(alloc) => alloc.id == this.propertyTest.productPropertyAllocId
				);

				//Did we find the allocation for this extract definition property
				if (alloc) {
					//Brill we got an allocation. Now we will use this to show the property and section information

					//Setup the property stream which will return the property which matches the one in the allocation
					this.property$ = StoreAccess.dataGetObvs(
						aHubStateTemporaryProductPropertyIndexes
					).pipe(
						takeUntil(componentDestroyStream(this)),
						map((indexs) => {
							if (!indexs) {
								return undefined;
							}
							return indexs.find(
								(index) => index.id == alloc.productPropertyId
							);
						})
					);

					//Setup the section stream which will return the property which matches the one in the allocation
					this.section$ = StoreAccess.dataGetObvs(
						aHubStateTemporaryProductPropertySectionIndexes
					).pipe(
						takeUntil(componentDestroyStream(this)),
						map((indexs) => {
							if (!indexs) {
								return undefined;
							}
							return indexs.find((index) => index.id == alloc.productSectionId);
						})
					);

					//Setup the section stream which will return the property which matches the one in the allocation
					this.class$ = StoreAccess.dataGetObvs(
						aHubStateTemporaryProductClassIndexes
					).pipe(
						takeUntil(componentDestroyStream(this)),
						map((indexs) => {
							if (!indexs) {
								return undefined;
							}
							return indexs.find((index) => index.id === alloc.productClassId);
						})
					);

					//Create a stream for the property icon
					this.propertyIcon$ = this.property$.pipe(
						map((property) =>
							property
								? this.propertyIconMap[property.typeReference]
								: "accessibility"
						)
					);
				}
			});
	}

	propertyTestClickHandler() {
		this.editPropertyTest.emit();
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	propertyTestDeleteClickHandler($event) {
		this.isBusy = true;
		this.propertyTestDelete.emit();
		$event.stopPropagation();
	}
}
