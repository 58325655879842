import { Pipe, PipeTransform } from "@angular/core";

/**
 * Pipe takes a number 37 and returns a padding zero string integer
 * eg "00037" .
 */
@Pipe({
	name: "integerFixedStringZeroPadding",
})
export class IntegerFixedStringZeroPadding implements PipeTransform {
	transform(value: number, args?: any): string {
		// The first and only optional parameter, the amount of zero padding to appy.
		const paddingSize: number =
			args != undefined ? (args[0] ? Number.parseInt(args[0]) : 5) : 5;

		// We should be dealiing with integers, so convert value to 0 decimal place string.
		let numberString = value.toFixed(0);

		// Now.. we pad until we get the right length of string.
		while (numberString.length < paddingSize) {
			numberString = "0" + numberString;
		}
		return numberString;
	}
}
