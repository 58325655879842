import {
	ServiceIntegration,
	ServiceOptions,
} from "@harksolutions/ahub-web-services";
import { AHubService } from "app/services/ahub/ahub.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { AHubActions } from "../actions/ahub.actions";
import { ActionNumber } from "../actions/types/common.action-types";
import { AHubBaseEpic } from "./ahub-base.epics";
import { Epic } from "./epic";

/**
 * Class for the View epic functions
 */
export class AHubIntegrationEpics extends AHubBaseEpic implements Epic {
	serviceIntegration: ServiceIntegration = undefined;

	constructor(public readonly aHubService: AHubService) {
		super(aHubService);

		const serviceOptions = new ServiceOptions(
			environment.integrationApi.domain,
			environment.integrationApi.basePath
		);

		serviceOptions.logRequest = true;

		//Create our service library with the base parameters for our requests
		this.serviceIntegration = new ServiceIntegration(serviceOptions);
	}

	epicMethods(): any[] {
		return [
			this.clientFileChangeSetsFetch,
			this.changeSetJobsFetch,
			this.jobLogsFetch,
			this.changeSetLogsFetch,
		];
	}

	clientFileChangeSetsFetch = (action$: Observable<ActionNumber>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.CLIENT_FILE_CHANGE_SETS_FETCH),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataArrayToAction(
					this.serviceIntegration.clientFileChangeSetsByClientId(
						this.reqOptSigned(),
						action.number
					),
					action,
					AHubActions.clientFileChangeSetsSet
				)
			)
		);

	changeSetJobsFetch = (action$: Observable<ActionNumber>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.CHANGE_SET_JOBS_FETCH),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataArrayToAction(
					this.serviceIntegration.changeSetJobsByChangeSetId(
						this.reqOptSigned(),
						action.number
					),
					action,
					(data) => AHubActions.changeSetJobsSet(action.number, data)
				)
			)
		);

	jobLogsFetch = (action$: Observable<ActionNumber>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.JOB_LOGS_FETCH),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataArrayToAction(
					this.serviceIntegration.logsByJobId(
						this.reqOptSigned(),
						action.number
					),
					action,
					(data) => AHubActions.jobLogsSet(action.number, data)
				)
			)
		);

	changeSetLogsFetch = (action$: Observable<ActionNumber>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.CHANGE_SET_LOGS_FETCH),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataArrayToAction(
					this.serviceIntegration.logsByChangeSetId(
						this.reqOptSigned(),
						action.number
					),
					action,
					(data) => AHubActions.changeSetLogsSet(action.number, data)
				)
			)
		);
}
