import { AfterViewInit, Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
	selector: "app-grid-renderer-boolean",
	templateUrl: "./grid-renderer-boolean.component.html",
	styleUrls: ["./grid-renderer-boolean.component.css"],
})
export class GridRendererBooleanComponent
	implements AgRendererComponent, AfterViewInit
{
	ngAfterViewInit() {}

	/**
	 * Parameters for the render
	 */
	params: ICellRendererParams;
	paramsCustom: GridRendererBooleanParams;

	/**
	 * State for show checkbox
	 */
	showCheckbox = true;

	/**
	 * State for the boolean renderer
	 */
	state = true;

	/**
	 * Call after the init function
	 */
	agInit(params: any): void {
		//Set the parameters for the render
		this.params = params as ICellRendererParams;
		this.paramsCustom = params as GridRendererBooleanParams;

		//IF we have parameter we will try and set the state
		if (this.params) {
			//Set the state to the correct state
			this.state = this.params.value === "1";
		}

		//Update the show checkbox value
		this.updateShowCheckbox();
	}

	/**
	 * Refresh the parameter the correct values
	 */
	refresh(params: any): boolean {
		//Update the show checkbox value
		this.updateShowCheckbox();

		return false;
	}

	/**
	 * Start the editing of this cell
	 */
	startEdit() {
		//If we have parameters (we should) and the request is to not start editing from here
		//then we will bail out
		if (this.paramsCustom && !this.paramsCustom.startEditFromCheckbox) {
			return;
		}

		//Request to start editing the cell this is for
		this.params.api.startEditingCell({
			colKey: this.params.column.getId(),
			rowIndex: this.params.rowIndex,
		});
	}

	updateShowCheckbox() {
		//Do we have a show checkbox function then we will call it
		if (this.paramsCustom.showCheckboxFunc) {
			this.showCheckbox = this.paramsCustom.showCheckboxFunc(this.params);
		}
	}
}

/**
 * Parameters class which shows what we can pass into the editor
 */
export interface GridRendererBooleanParams {
	startEditFromCheckbox: boolean;
	showCheckboxFunc: (render: ICellRendererParams) => boolean;
}
