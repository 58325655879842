import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "hideDisabledPrefix",
})
export class HideDisabledPrefixPipe implements PipeTransform {
	transform(value: string, args?: any): any {
		if (value) {
			return value.replace("*DISABLED*", "");
		}
	}
}
