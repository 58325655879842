import { MapStorage } from "store/map-storage.vo";
import { HashedMapStorage } from "./hashed-map-storage.vo";
import { IdHashedMapUtil } from "./id-hashed-map-storage.util";
import { HashedMapUtil } from "./hashed-map-storage.util";
import { Utils } from "app/modules/common/utils";

/**
 * Utility class to give access to the data within the MapStorage class. This will give
 * us the sort of functions which you would expect from a traditional map object, but having
 * seperated the funcationality into this class and the data into the other leaves the MapStorage
 * fully serialisable.
 */
export class MapStorageUtil {
	/**
	 * Create a new map storage object
	 *
	 * @returns     A new map storage object ready for use!
	 */
	public static mapStorageCreate<T>(): MapStorage<T> {
		//Create a new object map with data
		return {
			keys: [],
			objectDirectory: {},
		};
	}

	/**
	 * Do we have the key in our map?
	 *
	 * @param map       Map we want to check against
	 * @param key       The key we want to check
	 */
	public static mapStorageHasKey<T>(map: MapStorage<T>, key: string): boolean {
		//Seems mad forcing a string to a string but I have found is possible to trick the interface
		//and this is used by enough things for me to be scared I will break something if I don't do this
		key = key ? key.toString() : key;

		//If we are missing map or keys then we haven't got the key
		if (!map || !map.keys) return undefined;

		//Get the index of the key on the list, if its not -1 then its in our map
		//This seems silly but we just want to ensure the type is actually a string and not a
		//value which has seeped through
		return map.keys.indexOf(key.toString()) !== -1;
	}

	/**
	 * How many keys do we have in our map?
	 *
	 * @param map       Map we want to check against
	 */
	public static mapStorageKeyCount<T>(map: MapStorage<T>): number {
		//If we are missing map or keys then we haven't got any keys
		if (!map || !map.keys) return 0;

		// Return the length of the 'keys' array
		return map.keys.length;
	}

	/**
	 * Get the data for the key specified in the map
	 *
	 * @param map       The map that the data is stored in
	 * @param key       They key which the data is stored against
	 */
	public static mapStorageGet<T>(map: MapStorage<T>, key: string): T {
		//Seems mad forcing a string to a string but I have found is possible to trick the interface
		//and this is used by enough things for me to be scared I will break something if I don't do this
		key = key ? key.toString() : key;

		//No map, no data!
		if (!map || !map.objectDirectory || !map.keys) return undefined;

		//If the key isn't on the list we will bail out
		if (!MapStorageUtil.mapStorageHasKey(map, key)) return undefined;

		//Get the value from the map if we have one
		return map.objectDirectory.hasOwnProperty(key)
			? map.objectDirectory[key]
			: undefined;
	}

	/**
	 * Set the data against a specific key in the map
	 *
	 * @param map       The map we want to set the data into
	 * @param key       The key which we will set the data against
	 * @param data      Data to set against the map
	 *
	 * @returns         A new (shallow copied) map with the affected data set
	 */
	public static mapStorageSet<T>(
		map: MapStorage<T>,
		key: string,
		data: T
	): MapStorage<T> {
		//Seems mad forcing a string to a string but I have found is possible to trick the interface
		//and this is used by enough things for me to be scared I will break something if I don't do this
		key = key ? key.toString() : key;

		//If we are supplied with null data then we will just go and delete the entry
		if (!data) {
			return MapStorageUtil.mapStorageRemove(map, key);
		}

		//Create a shallow copy of the map so we are not affecting the current one
		const newMap = MapStorageUtil.mapStorageShallowCopy(map);

		//We now need to set the data supplied into the map

		//Is the key already on the map? if so we will replace the data
		if (!MapStorageUtil.mapStorageHasKey(map, key)) {
			//Add the new key into the map
			newMap.keys.push(key);
		}

		//Set the data into the index directory
		newMap.objectDirectory[key] = data;

		//Return the new map ready ready for use
		return newMap;
	}

	/**
	 * Remove the data by key from the map specified
	 *
	 * @param map       Map which we want to remove the key from
	 * @param key       The key which we want to remove from the store
	 *
	 * @returns         A  new (shallow copied) map missing the key specified
	 */
	public static mapStorageRemove<T>(
		map: MapStorage<T>,
		key: string
	): MapStorage<T> {
		return MapStorageUtil.mapStorageRemoveMulti(map, [key]);
	}

	/**
	 * Remove the data by key from the map specified
	 *
	 * @param map       Map which we want to remove the key from
	 * @param keys      The keys which we want to remove from the store
	 *
	 * @returns         A  new (shallow copied) map missing the key specified
	 */
	public static mapStorageRemoveMulti<T>(
		map: MapStorage<T>,
		keys: string[]
	): MapStorage<T> {
		//Create a shallow copy of the map so we are not affecting the current one.
		//I know that we are doing the copy before we are checking if the item is in the map
		//but after some internal conflict, I think its better to be consistent about the return type
		//and to exect a new shallow cloned objects should be fine (also very minor overhead)
		const newMap = MapStorageUtil.mapStorageShallowCopy(map);

		//For each key we will go through removing them from the system
		keys.forEach((key) => {
			//Seems mad forcing a string to a string but I have found is possible to trick the interface
			//and this is used by enough things for me to be scared I will break something if I don't do this
			const keyAsString = key.toString();

			//If the key isn't in the map we will return it straight away
			if (!MapStorageUtil.mapStorageHasKey(newMap, keyAsString)) return newMap;

			//Remove the key which was specified
			newMap.keys = newMap.keys.filter((mapKey) => mapKey !== keyAsString);

			//Delete the property from the object directory
			delete newMap.objectDirectory[keyAsString];
		});

		//Return the new map!
		return newMap;
	}

	/**
	 * Create a shallow copy of the map. This will duplicate new objects for
	 * the top level properties but will not duplicate stored object data.
	 * We don't want to do this we would expect our callers to do this if required
	 * as there are too many unknowns for a simple map to deal with
	 *
	 * @param map       Map which we want to do a shallow copy of
	 *
	 * @returns         Return the shallow copy of the map
	 */
	private static mapStorageShallowCopy<T>(map: MapStorage<T>): MapStorage<T> {
		//Create a new map with no data
		const shallowCopy: MapStorage<T> = MapStorageUtil.mapStorageCreate();

		//Do we have maps and keys
		if (map && map.keys) {
			//Clone to the keys object
			shallowCopy.keys = map.keys.slice();

			//Loop through the map keys backwards ... means if we do any clean-up
			//our arrays won't break
			for (let i = shallowCopy.keys.length - 1; i >= 0; i--) {
				//Get the key from the list
				const key = shallowCopy.keys[i];

				//Get the data for the map
				const data: T = MapStorageUtil.mapStorageGet(map, key);

				//Do we have data for this key?
				if (data) {
					//Yes!

					//Set the data into our new map. We will set it un-altered.
					//if the caller wants to edit it later they should do so
					//view this function and not directly
					shallowCopy.objectDirectory[key] = data;
				} else {
					//No ... we will remove the key from the set!
					shallowCopy.keys = shallowCopy.keys.splice(i, 1);
				}
			}
		}

		//Return the new map
		return shallowCopy;
	}

	/**
	 * Utility function for the item (e.g. item) map reducers as they will be stored in an identical way
	 *
	 * @param itemsByParentIdMap            Parent map which contains all our item for all our parents
	 * @param parentKey                     Key to the parent we actually wish to affect
	 * @param itemsToAppend                 Items which we wish to add the the item storage under the parent specified above
	 * @param itemsToRemoveIds              Items which we wish to remove from the item storage under the parent key specified above
	 *
	 * @returns                             A map the same as the one passed in with the changes applied. If no changes needed to be applied
	 *                                      due to the absense of data etc the map passed in will be returned
	 */
	public static itemsMapAppendAndRemove<T>(
		itemsByParentIdMap: MapStorage<HashedMapStorage<T>>,
		parentKey: number,
		itemsToAppend: T[],
		itemsToRemoveIds: number[],
		allowCreateEmpty: boolean = false
	): MapStorage<HashedMapStorage<T>> {
		//If we are missing the master map or its key just return the map back to the caller
		if (!itemsByParentIdMap || !parentKey) return itemsByParentIdMap;

		//Decide what we have to do
		const hasItemsToAppend = itemsToAppend && itemsToAppend.length > 0;
		const hasItemsToRemove = itemsToRemoveIds && itemsToRemoveIds.length > 0;

		//No changes to apply ... then return the map as it was!
		if (!hasItemsToAppend && !hasItemsToRemove && !allowCreateEmpty)
			return itemsByParentIdMap;

		//Get the key for the
		const parentKeyString = parentKey.toString();

		//Get the source hash map from the map
		const sourceItemHashMap = MapStorageUtil.mapStorageGet(
			itemsByParentIdMap,
			parentKeyString
		);

		//Is this item missing from the parent list? this would mean it never existed
		if (!sourceItemHashMap) {
			//We have no items to append (nothing to do) so return the map straight back
			if (!hasItemsToAppend && !allowCreateEmpty) return itemsByParentIdMap;

			//OK well we had no map before, so we will create a new one with our new items in and set it into our item map
			return MapStorageUtil.mapStorageSet(
				itemsByParentIdMap,
				parentKeyString,
				IdHashedMapUtil.hashMapCreate(itemsToAppend)
			);
		}

		//If we only have items to remove we will do a cheaky little step before to see if we need to do anything at all
		if (!hasItemsToAppend && hasItemsToRemove) {
			//I think we should do this what should be a quick check as it will save the copy of the map ... which i feel could be more labour intensive
			//and this is a quick check!

			//If we are only removing items we will only have to do this if there is something to remove for this id. So lets go through looking
			//for the first data item with this id, if we find something thats good enough
			const itemToRemove = itemsToRemoveIds.find(
				(id) =>
					HashedMapUtil.hashMapGetItem(sourceItemHashMap, id.toString()) !==
					undefined
			);

			//OK if we didn't find any items then there were no id's that affected us!
			if (!itemToRemove) return itemsByParentIdMap;
		}

		//Right so if we are here we have an existing set of values and we know we either want to append or remove some items
		//so first off we will make a shallow clone of the map which will allow us to change it without affecting the orginal object
		const clonedItemHashMap = HashedMapUtil.hashedMapClone(sourceItemHashMap);

		//Now we have our clone we can affect it however we want!

		//If we have items to append lets append them!
		if (hasItemsToAppend) {
			IdHashedMapUtil.hashMapAppendItems(clonedItemHashMap, itemsToAppend);
		}

		//If we have items to remove lets remove them!
		if (hasItemsToRemove) {
			HashedMapUtil.hashMapRemoveItems(
				clonedItemHashMap,
				itemsToRemoveIds.map((id) => id.toString())
			);
		}

		//Finally we will set the cloned hash map into our items map
		return MapStorageUtil.mapStorageSet(
			itemsByParentIdMap,
			parentKeyString,
			clonedItemHashMap
		);
	}
}
