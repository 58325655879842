import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AHubActions } from "actions/ahub.actions";
/**
 * Utilities.
 */
import { ListUtil } from "app/store/list.util";
import { MapStorage } from "app/store/map-storage.vo";
import { aHubStatePermanentExportTypes } from "app/store/selector/ahub/ahub-permanent.selector";
import { sessionUserId } from "app/store/selector/app.selector";
import { viewSelectedExportDistribution } from "app/store/selector/view/view-exports.selector";
import { DistributionGroupAHubVO } from "app/valueObjects/ahub/accounts/distribution-group.ahub.vo";
import { DistributionIndexAHubVO } from "app/valueObjects/ahub/accounts/distribution-index.ahub.vo";
import { DistributionAHubVO } from "app/valueObjects/ahub/accounts/distribution.ahub.vo";
import {
	ExportTypeCodeAHubEnum,
	exportTypeIconPathGet,
} from "app/valueObjects/ahub/accounts/export-type-code.ahub.enum";
import { PublicationDistributionAHubVO } from "app/valueObjects/ahub/accounts/publication-distribution.ahub.vo";
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import {
	distinctUntilChanged,
	filter,
	map,
	publishReplay,
	refCount,
	switchMap,
	takeUntil,
} from "rxjs/operators";
/**
 * Selectors.
 */
import {
	aHubStateTemporaryDistributionGroupIndexes,
	aHubStateTemporaryDistributionGroupList,
	aHubStateTemporaryDistributionsList,
	aHubStateTemporaryExportDistributionIndexList,
	aHubStateTemporaryExportList,
} from "selector/ahub/ahub-temporary.selector";

/**
 * Store access and actions.
 */
import { StoreAccess } from "store/store-access";
import { DistributionUtils } from "../../distribution-utils";
import { Utils } from "../../utils";
import { GroupedOptions } from "../select-with-search-grouped/select-with-search-grouped.component";

@Component({
	selector: "app-user-export-distribution",
	templateUrl: "./user-export-distribution.component.html",
	styleUrls: ["./user-export-distribution.component.css"],
})
@Hark()
export class UserExportDistributionComponent implements OnInit, OnDestroy {
	/**
	 * Get the selected export distributions from the store.
	 */
	exportDistribution$ = StoreAccess.dataGetObvs(viewSelectedExportDistribution);

	exportDistributionId$: BehaviorSubject<number> = new BehaviorSubject(
		undefined
	);

	/**
	 * Does the current export distribution have versions.
	 */
	exportDistributionHasVersions$: Observable<boolean>;

	/**
	 * The path to the export type icon.
	 */
	exportTypeIconPath$: Observable<string>;

	/**
	 * The distributions export type.
	 */
	distributionExportType$: Observable<ExportTypeCodeAHubEnum>;

	/**
	 * Is the current distribution export type code a legacy aWorkbook.
	 */
	distributionExportTypeIsAWLegacy$: Observable<boolean>;

	/**
	 * Is the current distribution export type code a JSON aWorkbook.
	 */
	distributionExportTypeIsAWJSON$: Observable<boolean>;

	aViewPublicationDistributionsMap$: BehaviorSubject<
		MapStorage<PublicationDistributionAHubVO[]>
	> = new BehaviorSubject(undefined);

	selectedPublicationDistributionIndexes$: BehaviorSubject<
		DistributionIndexAHubVO[]
	> = new BehaviorSubject(undefined);

	distributionIndexesOptionGroups$: Observable<GroupedOptions[]> =
		this.selectedPublicationDistributionIndexes$.pipe(
			takeUntil(componentDestroyStream(this)),
			Utils.isNotNullOrUndefined(),
			map((distributionIndexes) => {
				const reduceDistributionIndexesToGroupedOptions = (
					acc: GroupedOptions[],
					currentGroupIndex: DistributionIndexAHubVO
				): GroupedOptions[] => {
					if (currentGroupIndex.memberOf) {
						acc[0].options.push(currentGroupIndex);
					} else {
						acc[1].options.push(currentGroupIndex);
					}

					return acc;
				};

				const initialGroupedOptions: GroupedOptions[] = [
					{
						groupName: "Group",
						options: [],
					},
					{
						groupName: "Additional Groups",
						options: [],
					},
				];

				const distributionIndexesOptionGroups = distributionIndexes.reduce(
					reduceDistributionIndexesToGroupedOptions,
					initialGroupedOptions
				);

				// Filter groups with empty 'options' field
				return distributionIndexesOptionGroups.filter(
					(dgOptionGroup) => dgOptionGroup.options.length > 0
				);
			})
		);

	/**
	 * Used to return the value of each 'select-with-search' option we should use for searching
	 */
	propertyLabelValue = (option: DistributionAHubVO) => {
		return option?.distributionGroupName;
	};

	/**
	 * Used to determine which value of the option object should be applied if an option is selected
	 */
	propertyIdValue = (option: DistributionAHubVO) => {
		return option?.distributionGroupId;
	};

	/**
	 * Is the current distribution export type code AView.
	 */
	distributionExportTypeIsAView$: Observable<boolean>;

	publicationDistributionGroupMap: Map<number, string>;

	/**
	 * Form control which will control the selected publication and edition
	 */
	distributionGroupForm: UntypedFormGroup = this.formBuilder.group({
		distributionGroup: [""],
		search: [""],
	});

	/**
	 * Selected distribution group (used with AView)
	 */
	selectedAViewDistributionGroup$: Observable<DistributionGroupAHubVO> =
		this.distributionGroupForm.controls.distributionGroup.valueChanges.pipe(
			takeUntil(componentDestroyStream(this)),
			switchMap((selectDistribution) => {
				return StoreAccess.dataListItemGetObvs(
					aHubStateTemporaryDistributionGroupList,
					selectDistribution?.distributionGroupId
				);
			})
		);

	selectedDistributionIndex$: BehaviorSubject<DistributionIndexAHubVO> =
		new BehaviorSubject(undefined);

	selectedDistribution$: Observable<DistributionAHubVO> = combineLatest([
		StoreAccess.dataGetObvs(aHubStateTemporaryDistributionsList).pipe(
			Utils.isNotNullOrUndefined(),
			distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
		),
		this.selectedDistributionIndex$.pipe(
			Utils.isNotNullOrUndefined()
			//distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
		),
	]).pipe(
		takeUntil(componentDestroyStream(this)),
		map(([distributionsList, selectedDistributionIndex]) => {
			StoreAccess.dispatch(
				AHubActions.distributionGroupsFetch([
					selectedDistributionIndex.distributionGroupId,
				])
			);
			return ListUtil.listDataItemGet(
				distributionsList,
				selectedDistributionIndex.id
			);
		})
	);

	constructor(private readonly formBuilder: UntypedFormBuilder) {
		// This is intentional
	}

	ngOnInit() {
		// Fetch all user's possible distribution group indexes
		StoreAccess.dispatch(
			AHubActions.aHubDistributionGroupIndexesByUserIdFetch(
				StoreAccess.dataGet(sessionUserId)
			)
		);

		this.exportDistribution$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				filter((exportDistribution) => exportDistribution !== undefined)
			)
			.subscribe((exportDistribution) => {
				// If this is an AView distribution we will need to allow the user to specify a distribution group
				if (
					exportDistribution.exportTypeCode === ExportTypeCodeAHubEnum.AVIEW
				) {
					this.exportDistributionId$.next(exportDistribution.exportId);

					// Firstly lets go grab the distribution indexes list
					StoreAccess.dispatch(
						AHubActions.exportDistributionIndexsFetch(
							exportDistribution.exportId
						)
					);
				}

				// Make a request for the aHub export value object.
				StoreAccess.dispatch(
					AHubActions.exportsByIdFetch([exportDistribution.exportId])
				);
			});

		// Combine distribution group indexes and distribution indexes list to create member and non-member groups
		combineLatest([
			StoreAccess.dataGetObvs(aHubStateTemporaryDistributionGroupIndexes).pipe(
				Utils.isNotNullOrUndefined()
			),
			StoreAccess.dataGetObvs(
				aHubStateTemporaryExportDistributionIndexList
			).pipe(filter((relationshipList) => relationshipList?.size > 0)),
			this.exportDistributionId$.pipe(Utils.isNotNullOrUndefined()),
		])
			.pipe(
				takeUntil(componentDestroyStream(this)),
				map(
					([
						distributionGroupIndexes,
						distributionIndexesList,
						exportDistributionId,
					]) => {
						const isMemberOfDG = (
							distributionIndex: DistributionIndexAHubVO
						) => {
							return distributionGroupIndexes.some(
								(dgIndex) =>
									dgIndex.id === distributionIndex.distributionGroupId
							);
						};

						// Populate distribution indexes with the 'memberOf' field by distribution groups available for this user
						return ListUtil.listDataItemGet(
							distributionIndexesList,
							exportDistributionId
						)?.relationships?.map((distributionIndex) => ({
							...distributionIndex,
							memberOf: isMemberOfDG(distributionIndex),
						}));
					}
				)
			)
			.subscribe((publicationDistributionIndexes) => {
				// Initialise grouped select drop down with default DI
				const defaultDistributionIndex =
					publicationDistributionIndexes?.find(
						(publDistIndex) => publDistIndex.memberOf
					) || publicationDistributionIndexes?.[0];
				this.distributionGroupForm.controls.distributionGroup.setValue(
					defaultDistributionIndex
				);

				this.selectedPublicationDistributionIndexes$.next(
					DistributionUtils.sortDistributionIndexesByName(
						publicationDistributionIndexes
					)
				);
			});

		// We've got distribution group indexes for this publication and freshly selected distribution index
		combineLatest([
			this.selectedPublicationDistributionIndexes$.pipe(
				Utils.isNotNullOrUndefined(),
				distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
			),
			this.distributionGroupForm.controls.distributionGroup.valueChanges.pipe(
				distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
			),
			this.exportDistributionId$.pipe(Utils.isNotNullOrUndefined()),
		])
			.pipe(
				takeUntil(componentDestroyStream(this)),
				map(
					([
						selectedPublicationDistributionGroupIndexes,
						selectedDistributionGroupIndex,
					]) => {
						return (
							selectedPublicationDistributionGroupIndexes.find(
								(dgIndex) => dgIndex?.id === selectedDistributionGroupIndex?.id
							) || selectedPublicationDistributionGroupIndexes?.[0]
						);
					}
				)
			)
			.subscribe((selectedDistributionIndex) => {
				// save this selected distribution index which will be used to access distribution by id from list
				this.selectedDistributionIndex$.next(selectedDistributionIndex);

				// here we need to fetch the distribution by id
				selectedDistributionIndex &&
					StoreAccess.dispatch(
						AHubActions.distributionsByIdsFetch([selectedDistributionIndex.id])
					);
			});

		// Does the current export distribution have any versions available to this user?
		this.exportDistributionHasVersions$ = this.exportDistribution$.pipe(
			takeUntil(componentDestroyStream(this)),
			map((exportDistribution) =>
				exportDistribution !== undefined &&
				exportDistribution.distributionExportVersions &&
				exportDistribution.distributionExportVersions.length
					? exportDistribution.distributionExportVersions.length > 0
					: false
			)
		);

		// Make a call for the export types.
		StoreAccess.dispatch(AHubActions.exportTypesAllFetch());

		// Set up the distribution export type observable.
		this.distributionExportType$ = combineLatest(
			this.exportDistribution$.pipe(
				filter((distribution) => distribution != null),
				map((distribution) => distribution.exportId)
			),
			StoreAccess.dataGetObvs(aHubStatePermanentExportTypes),
			StoreAccess.dataGetObvs(aHubStateTemporaryExportList),
			(exportId, exportTypes, exportList) => {
				// Get the export base on the id.
				let exportAHubVO = ListUtil.listDataItemGet(exportList, exportId);

				// Get the export type.
				let exportType = exportAHubVO
					? exportTypes.find(
							(exportType) => exportType.id == exportAHubVO.exportTypeId
					  )
					: null;

				// Now return the export type.
				return exportType ? exportType.code : null;
			}
		).pipe(publishReplay(1), refCount());

		// Get the path to the export type based on this export.
		this.exportTypeIconPath$ = this.distributionExportType$.pipe(
			map((exportTypeCode) => exportTypeIconPathGet(exportTypeCode))
		);

		// Set up the is distribution export type a legacy aWorkbook observable.
		this.distributionExportTypeIsAWLegacy$ = this.distributionExportType$.pipe(
			map((typeCode) => typeCode == ExportTypeCodeAHubEnum.AWORKBOOK_CATALOGUE)
		);

		// Set up the is distribution export type a JSON aWorkbook observable.
		this.distributionExportTypeIsAWJSON$ = this.distributionExportType$.pipe(
			map(
				(typeCode) =>
					typeCode == ExportTypeCodeAHubEnum.AWORKBOOK_CATALOGUE_JSON
			)
		);

		this.distributionExportTypeIsAView$ = this.distributionExportType$.pipe(
			map((typeCode) => typeCode == ExportTypeCodeAHubEnum.AVIEW)
		);
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}
}
