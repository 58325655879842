import { AfterViewInit, Component } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { BehaviorSubject } from "rxjs";
import {
	GridEditorSelectComponentOption,
	GridEditorSelectComponentParams,
} from "../grid-editor-select/grid-editor-select.component";

@Component({
	selector: "app-grid-editor-multi-select",
	templateUrl: "./grid-editor-multi-select.component.html",
	styleUrls: ["./grid-editor-multi-select.component.css"],
})
export class GridEditorMultiSelectComponent
	implements ICellEditorAngularComp, AfterViewInit
{
	/**
	 * Paramteres for this renderer
	 */
	params;

	/**
	 * Params with our custom values, we seperate them as the the default value has a load of options which
	 * we don't really want to concerne our selves with
	 */
	paramsCustom: GridEditorSelectComponentParams;

	/**
	 * List of the value options for the form
	 */
	valueOptions: GridEditorSelectComponentOption[];

	selectedOptions$: BehaviorSubject<string[]> = new BehaviorSubject([]);
	/**
	 * After the view has initalised then we will focus the element
	 */
	ngAfterViewInit() {}

	/**
	 * Init of the renderer
	 */
	agInit(params: any): void {
		//Set the parameters
		this.params = params;
		this.paramsCustom = params as GridEditorSelectComponentParams;

		//Set the values list
		this.valueOptions = this.paramsCustom.values
			? this.paramsCustom.values
			: [];

		//Set the selected value
		const selectedOptions: string[] = this.params.value
			? JSON.parse(this.params.value)
			: [];
		this.selectedOptions$.next(selectedOptions);
	}

	/**
	 * Is called when editing is about to start. Giving
	 * us an opertunity to stop the editing before it happens
	 */
	isCancelBeforeStart(): boolean {
		//Do we have a supplied cancel function in the parameters
		if (this.paramsCustom.canEditCellFunc) {
			return !this.paramsCustom.canEditCellFunc(this.params);
		}

		//No is cancel function so no cancel behaviour
		return false;
	}

	/**
	 * Get the currently selected value
	 */
	getValue(): any {
		const trimmedListValues = this.selectedOptions$
			.getValue()
			.filter((value) => value !== undefined && value !== "");

		return JSON.stringify(
			trimmedListValues && trimmedListValues.length > 0
				? trimmedListValues
				: undefined
		);
	}

	/**
	 * This is a popup so thats that
	 */
	isPopup(): boolean {
		return true;
	}

	doneEditingHandler() {
		if (this.params.api) {
			this.params.api.stopEditing();
		}
	}
}
