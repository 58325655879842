import { filter, takeUntil } from "rxjs/operators";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Observable } from "rxjs";
import { Hark, componentDestroyStream } from "modules/common/hark.decorator";

/**
 * Store Access
 */
import { StoreAccess } from "store/store-access";
import { AHubActions } from "actions/ahub.actions";

/**
 * Selectors.
 */
import { aHubStateTemporaryProductClassIndexes } from "selector/ahub/ahub-temporary.selector";

/**
 * Value objects.
 */
import { ProductClassIndexAHubVO } from "valueObjects/ahub/library/product-class-index.ahub.vo";

@Component({
	selector: "app-product-class-id",
	templateUrl: "./product-class-id.component.html",
	styleUrls: ["./product-class-id.component.css"],
})
@Hark()
export class ProductClassIdComponent implements OnInit, OnDestroy {
	/**
	 * This is the id of the class we are displaying.
	 */
	@Input()
	productClassId: number;

	/**
	 * Is this product class id component displaying an exclusion id? Defaults to true.
	 */
	@Input()
	isExclusionId: Boolean = true;

	/**
	 * Get the product class indexes from the system.
	 */
	productClassIndexes$: Observable<ProductClassIndexAHubVO[]> =
		StoreAccess.dataGetObvs(aHubStateTemporaryProductClassIndexes);

	/**
	 * This is the product class index for the id specified.
	 */
	productClassIndex: ProductClassIndexAHubVO;

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		// Make a request to get all of the product class indexes.
		StoreAccess.dispatch(AHubActions.productClassIndexsByClientIdFetch());

		//Subscribe to the allocation indexes
		this.productClassIndexes$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				filter((productClasses) => productClasses != null)
			)
			.subscribe((productClasses) => {
				// Get the product class index.
				this.productClassIndex = productClasses.find(
					(productClass) => productClass.id == this.productClassId
				);
			});
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}
}
