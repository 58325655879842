/**
 * ----------------------------------------------------------
 * Action Types
 * ----------------------------------------------------------
 */
import {
	ActionComponentTreeDataVOs,
	ActionComponentBulkTransferDataVOs,
	ActionComponentDataSetProductSearchIdsFetch,
	ActionComponentDataSetProductSearchIdsSet,
} from "actions/types/component.action-types";

/**
 * Value Objects
 */
import { TreeNodeVO } from "modules/common/components/tree/tree.component";
import { BulkTransferRecordAppVO } from "valueObjects/app/bulk-transfer-record.app.vo";
import {
	ActionStringNumber,
	ActionString,
	ActionNumberStringString,
	ActionStringString,
} from "./types/common.action-types";
import { ActionWork } from "./types/work.action-types";

/**
 * Component Actions class
 */
export class ComponentActions {
	/**
	 * Event Type statics
	 */

	static COMPONENT_TREE_DATA_SET = "[COMPONENT] COMPONENT_TREE_DATA_SET";
	static COMPONENT_TREE_DATA_UPDATE = "[COMPONENT] COMPONENT_TREE_DATA_UPDATE";

	static COMPONENT_BULK_TRANSFER_REQUESTS_DATA_APPEND =
		"[COMPONENT] COMPONENT_BULK_TRANSFER_REQUESTS_DATA_APPEND";
	static COMPONENT_BULK_TRANSFER_REQUESTS_DATA_CLEAR =
		"[COMPONENT] COMPONENT_BULK_TRANSFER_REQUESTS_DATA_CLEAR";

	static COMPONENT_DATA_SET_PRODUCTS_REMOVE =
		"[COMPONENT] COMPONENT_DATA_SET_PRODUCTS_REMOVE";
	static COMPONENT_DATA_SET_PRODUCTS_DATA_SET_ID_SET =
		"[COMPONENT] COMPONENT_DATA_SET_PRODUCTS_DATA_SET_ID_SET";
	static COMPONENT_DATA_SET_PRODUCTS_DATA_SET_CATEGORY_ID_SET =
		"[COMPONENT] COMPONENT_DATA_SET_PRODUCTS_DATA_SET_CATEGORY_ID_SET";
	static COMPONENT_DATA_SET_PRODUCTS_DATA_SET_CATEGORY_SELECTED_PRODUCT_ID_SET =
		"[COMPONENT] COMPONENT_DATA_SET_PRODUCTS_DATA_SET_CATEGORY_SELECTED_PRODUCT_ID_SET";
	static COMPONENT_DATA_SET_PRODUCTS_SEARCH_FETCH =
		"[COMPONENT] COMPONENT_DATA_SET_PRODUCTS_SEARCH_FETCH";
	static COMPONENT_DATA_SET_PRODUCTS_SEARCH_SET =
		"[COMPONENT] COMPONENT_DATA_SET_PRODUCTS_SEARCH_SET";
	static COMPONENT_DATA_SET_PRODUCTS_SEARCH_REMOVE =
		"[COMPONENT] COMPONENT_DATA_SET_PRODUCTS_SEARCH_REMOVE";

	/**
	 * --------------------------------------------------------
	 *  Tree Component
	 * --------------------------------------------------------
	 */

	/**
	 * Sets the tree data for display.
	 */
	static componentTreeDataSet(
		treeData: TreeNodeVO[]
	): ActionComponentTreeDataVOs {
		//Constuct action with the specified interface
		return {
			type: ComponentActions.COMPONENT_TREE_DATA_SET,
			treeData: treeData,
		};
	}

	/**
	 * Updates the tree data for display.
	 */
	static componentTreeDataUpdate(
		treeData: TreeNodeVO[]
	): ActionComponentTreeDataVOs {
		//Constuct action with the specified interface
		return {
			type: ComponentActions.COMPONENT_TREE_DATA_UPDATE,
			treeData: treeData,
		};
	}

	/**
	 * --------------------------------------------------------
	 *  Bulk Transfer
	 * --------------------------------------------------------
	 */

	/**
	 * Appends to the bluk transfer data for display.
	 */
	static componentBulkTransferRequestsDataAppend(
		transferRequests: BulkTransferRecordAppVO[]
	): ActionComponentBulkTransferDataVOs {
		//Constuct action with the specified interface
		return {
			type: ComponentActions.COMPONENT_BULK_TRANSFER_REQUESTS_DATA_APPEND,
			transferRequests: transferRequests,
		};
	}

	/**
	 * Clear the bluk transfer data for display
	 */
	static componentBulkTransferRequestsDataClear(): ActionWork {
		//Constuct action with the specified interface
		return {
			type: ComponentActions.COMPONENT_BULK_TRANSFER_REQUESTS_DATA_CLEAR,
		};
	}

	/**
	 * --------------------------------------------------------
	 *  Data Set Products
	 * --------------------------------------------------------
	 */

	/**
	 * Remove all the data for this component
	 *
	 * @param componentId      Component id which we will remove
	 */
	static componentDataSetProductsRemove(componentId: string): ActionString {
		return {
			type: ComponentActions.COMPONENT_DATA_SET_PRODUCTS_REMOVE,
			string: componentId,
		};
	}

	/**
	 * Set the dataset id for the given data set products component
	 *
	 * @param componentId      Component id which this is in reference too
	 * @param dataSetId        Data set which we want to set
	 */
	static componentDataSetProductsDataSetIdSet(
		componentId: string,
		dataSetId: number
	): ActionStringNumber {
		return {
			type: ComponentActions.COMPONENT_DATA_SET_PRODUCTS_DATA_SET_ID_SET,
			string: componentId,
			number: dataSetId,
		};
	}

	/**
	 * Set the dataset category id for the given data set products component
	 *
	 * @param componentId            Component id which this is in reference too
	 * @param dataSetCategoryId      Data set category id which we want to set
	 */
	static componentDataSetProductsDataSetCategoryIdSet(
		componentId: string,
		dataSetCategoryId: number
	): ActionStringNumber {
		return {
			type: ComponentActions.COMPONENT_DATA_SET_PRODUCTS_DATA_SET_CATEGORY_ID_SET,
			string: componentId,
			number: dataSetCategoryId,
		};
	}

	/**
	 * Set the dataset category selected product id for the given data set products component
	 *
	 * @param componentId            Component id which this is in reference too
	 * @param dataSetCategorySelectedProductId      Data set category selected product id which we want to set
	 */
	static componentDataSetProductsDataSetCategorySelectProductIdSet(
		componentId: string,
		dataSetCategorySelectedProductId: number
	): ActionStringNumber {
		return {
			type: ComponentActions.COMPONENT_DATA_SET_PRODUCTS_DATA_SET_CATEGORY_SELECTED_PRODUCT_ID_SET,
			string: componentId,
			number: dataSetCategorySelectedProductId,
		};
	}

	/**
	 * Fetches the product id's for a given search terms
	 *
	 * @param componentId             Id for the component
	 * @param searchId                Unique id for the search that can be re-referenced later
	 * @param dataSetCategoryId       Id for the data set category which we want to search
	 * @param searchTerm              Search term which we want to run
	 */
	static componentDataSetProductsSearchProductIdsFetch(
		componentId: string,
		dataSetCategoryId: number,
		searchTerm: string
	): ActionComponentDataSetProductSearchIdsFetch {
		return {
			type: ComponentActions.COMPONENT_DATA_SET_PRODUCTS_SEARCH_FETCH,
			componentId,
			dataSetCategoryId,
			searchTerm,
		};
	}

	/**
	 * Sets the search results for a given component search
	 *
	 * @param componentId           Unique id for the component that can be re-referenced later
	 * @param dataSetCategoryId     Id of the data set category which the request was for
	 * @param productIds            Id's of the products which match the search term
	 * @param searchTerm            The search term which was used later on!
	 */
	static componentDataSetProductSearchSet(
		componentId: string,
		dataSetCategoryId: number,
		productIds: number[],
		searchTerm: string
	): ActionComponentDataSetProductSearchIdsSet {
		return {
			type: ComponentActions.COMPONENT_DATA_SET_PRODUCTS_SEARCH_SET,
			componentId,
			dataSetProductSearch: {
				dataSetCategoryId,
				productIds,
				searchTerm: searchTerm,
			},
		};
	}

	/**
	 * Delete the search for a given component
	 *
	 * @param componentId       Id of the component which we will remove the search of
	 */
	static componentDataSetProductSearchDelete(
		componentId: string
	): ActionString {
		return {
			type: ComponentActions.COMPONENT_DATA_SET_PRODUCTS_SEARCH_REMOVE,
			string: componentId,
		};
	}
}
