import { Pipe, PipeTransform } from "@angular/core";
import { DocumentProductFileTypeAHubEnum } from "app/valueObjects/ahub/library/document-product-file-type.ahub.enum";

@Pipe({ name: "documentProductFileTypeToLabel" })
export class DocumentProductFileTypeToLabelPipe implements PipeTransform {
	/**
	 * Transform a document file type into a lable.
	 *
	 * @param input             The string to convert.
	 */
	public transform(input: DocumentProductFileTypeAHubEnum): string {
		// Return the label from the type if we have one. Otherwise return an empty string.
		return input ? DocumentProductFileTypeAHubEnum.typeToLabel(input) : "";
	}
}
