import {
	takeUntil,
	startWith,
	filter,
	distinctUntilChanged,
	map,
	delay,
} from "rxjs/operators";
import {
	Component,
	OnInit,
	ComponentFactory,
	ComponentFactoryResolver,
	OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { Observable, interval, combineLatest } from "rxjs";

import { AppRoutingNavigation } from "app/app-routing-navigation";
import { Hark, componentDestroyStream } from "modules/common/hark.decorator";

import { StoreAccess } from "store/store-access";

import { aHubStatePermanentUsers } from "selector/ahub/ahub-permanent.selector";
import {
	sessionUserId,
	sessionUserSessionCredentials,
} from "selector/app.selector";

import { UserAHubVO } from "valueObjects/ahub/accounts/user.ahub.vo";
import { UserSessionCredentials } from "@harksolutions/ahub-web-services-types";
import { UserProfileComponent } from "modules/common/vo-render/user-profile/user-profile.component";

import { DialogService } from "modules/common/dialogs/dialog.service";
import { AppActions } from "actions/app.actions";

@Component({
	selector: "app-user-menu",
	templateUrl: "./user-menu.component.html",
	styleUrls: ["./user-menu.component.css"],
})
@Hark()
export class UserMenuComponent implements OnInit, OnDestroy {
	/**
	 * This is the current session user.
	 */
	sessionUser$: Observable<UserAHubVO> = combineLatest([
		StoreAccess.dataGetObvs(sessionUserId).pipe(
			filter((sessionUserId) => sessionUserId !== undefined)
		),
		StoreAccess.dataGetObvs(aHubStatePermanentUsers),
	]).pipe(
		delay(0),
		map(([sessionUserId, users]) =>
			users.find((user) => user.id === sessionUserId)
		)
	);

	/**
	 * This is the current session expiry.
	 */
	sessionUserSessionCredentials$: Observable<UserSessionCredentials> =
		StoreAccess.dataGetObvs(sessionUserSessionCredentials);

	/**
	 * Get the number of hours until the session expires, checking every hour.
	 */
	hoursToExpiry$: Observable<number> = combineLatest(
		interval(3600000).pipe(startWith(0)),
		this.sessionUserSessionCredentials$.pipe(
			filter((session) => session !== undefined),
			distinctUntilChanged((x, y) => {
				const previousExpiryDate = new Date(x.sessionExpiry).getTime();
				const currentExpiryDate = new Date(y.sessionExpiry).getTime();

				return currentExpiryDate === previousExpiryDate;
			})
		),

		(ping, session) => {
			// Get the number of milliseconds between now and the session expiry.
			const millisecondsDelta: number =
				new Date(session.sessionExpiry).getTime() - Date.now();

			// Return the number of hours.
			return millisecondsDelta / 1000 / 60 / 60;
		}
	);

	/**
	 * This is the session expiry message.
	 */
	sessionExpiryMessage$: Observable<string> = this.hoursToExpiry$.pipe(
		map((hoursToExpiry) => {
			// Do we have less than 24 hours? If so, the expiry message will be about hours.
			if (hoursToExpiry < 24) {
				return "Session will expire in " + hoursToExpiry.toFixed(0) + " hours!";
			}

			// If we get here then we need to get the number of days.
			const daysToExpiry: number = hoursToExpiry / 24;

			// Now return the days until expiry.
			return "Session will expire in " + daysToExpiry.toFixed(0) + " days!";
		})
	);

	/**
	 * Begin alerting if the session has less than 16 hours to go
	 */
	sessionAboutToExpire$: Observable<boolean> = this.hoursToExpiry$.pipe(
		map((hours) => {
			const sessionAboutToExpire: boolean = hours < 16;
			if (sessionAboutToExpire) {
				// The session expiry date may have been reset by the user logging into another device
				// Lets check that the expiry date we have is still true
				StoreAccess.dispatch(AppActions.sessionInfoFetch());
			}
			return sessionAboutToExpire;
		}),
		startWith(false)
	);

	constructor(
		private router: Router,
		private dialogueService: DialogService,
		private resolver: ComponentFactoryResolver
	) {}

	ngOnInit() {
		// This is intentional
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	logout() {
		// Go to the logout page.
		AppRoutingNavigation.navigateLogout(this.router);
	}

	logoutAllDevices() {
		// Invalidate the current users session, effectively logging them out of all ahub applications.
		StoreAccess.dispatch(AppActions.sessionInvalidate());
		AppRoutingNavigation.navigateLogout(this.router);
	}

	openUserProfileDialog() {
		// Get the copmponent Factory we will use to create the component dialog
		// we will use to create a new export entry.
		const componentFactory: ComponentFactory<UserProfileComponent> =
			this.resolver.resolveComponentFactory(UserProfileComponent);

		// Open the custom component dialogue , using the component factory to create the component content, passing in a blank VO.
		const newDialogue = this.dialogueService.componentDialogOpen(
			"",
			componentFactory,
			"user$",
			this.sessionUser$,
			undefined,
			"Edit Profile",
			"Cancel",
			undefined,
			undefined,
			"userProfileDialog"
		);

		//Subscribe to the new export dialogue, if we recive an export then we will add it
		newDialogue.subscribe((dialogVO) => {
			//If the new VO is undefined then we will bail out
			if (!dialogVO) {
				return;
			}

			AppRoutingNavigation.navigateMyAccount(this.router);
		});
	}
}
