import { Component, Input, OnInit } from "@angular/core";
import { PropertyAllocationObjectVO } from "app/valueObjects/stream/product-allocation-object-stream.vo";

@Component({
	selector: "app-distribution-aview-properties-config-index-item",
	templateUrl:
		"./distribution-aview-properties-config-index-item.component.html",
	styleUrls: [
		"./distribution-aview-properties-config-index-item.component.css",
	],
})
export class DistributionAviewPropertiesConfigIndexItemComponent
	implements OnInit
{
	constructor() {
		// This is intentional
	}

	@Input() propertiesConfigIndexItemAHubVO: PropertiesConfigIndexItemAHubVO =
		null;

	ngOnInit(): void {
		// This is intentional
	}

	preventClick(event) {
		event.preventDefault();
		event.stopPropagation();
	}
}

export interface PropertiesConfigIndexItemAHubVO {
	alloc: PropertyAllocationObjectVO;
	excluded: boolean;
	inUse?: string;
}
