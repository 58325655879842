import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

/**
 * Value Objects
 */
import { NotificationRecordVO } from "valueObjects/app/notification-record.vo";

@Component({
	selector: "app-notification-detail-dialog",
	templateUrl: "./notification-detail-dialog.component.html",
	styleUrls: ["./notification-detail-dialog.component.css"],
})
export class NotificationDetailDialogComponent implements OnInit {
	/**
	 * The VO that is passed in / out.
	 */
	public notificationRecordVO: NotificationRecordVO = null;

	/**
	 * Prettified version of the raw data.
	 */
	notificationJSON: string;

	constructor(
		public dialogRef: MatDialogRef<NotificationDetailDialogComponent>
	) {}

	ngOnInit() {
		this.notificationJSON = JSON.stringify(this.notificationRecordVO, null, 2);
	}
}
