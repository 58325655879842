import { Component, Input } from "@angular/core";

@Component({
	selector: "app-asset-loader",
	templateUrl: "./asset-loader.component.html",
	styleUrls: ["./asset-loader.component.css"],
})
export class AssetLoaderComponent {
	@Input() preview?: string;
}
