import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "tidyNodeId",
})
export class TidyNodeIdPipe implements PipeTransform {
	transform(value: string, args?: any): any {
		// Has this node got any depth?
		if (value && value.indexOf("/") !== -1) {
			// Lets split the selected tree node id into it parent and child node id (parentNode/childNode_0)
			let splitNode = value.split("/");
			let childNodeId: any = splitNode.pop();

			// Is this node an array item
			if (!isNaN(childNodeId)) {
				let parentNodeId = splitNode.pop();
				const indexOfTheWordListInParentNodeId = parentNodeId.indexOf("List");
				// Does the parent node end with 'List' if it does lets display the child names as abreviations of the parent node
				// (e.g. children of infoSectionList would be 'infoSection')
				if (indexOfTheWordListInParentNodeId !== -1) {
					return parentNodeId.substring(0, indexOfTheWordListInParentNodeId);
				}
				// Perhaps the parent has an 's' at the end, lets make the assumption that we can make the child nodes be the parent
				// node but with the 's' removed (e.g. children of alternativeAssets would be 'alternativeAsset')
				else if (parentNodeId.endsWith("s")) {
					return parentNodeId.substr(0, parentNodeId.length - 1);
				}
				return "item";
			}

			return childNodeId;
		}
		return value;
	}
}
