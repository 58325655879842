import { Component, Input, OnInit } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	FormGroupDirective,
	NgForm,
	Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { AHubActions } from "app/store/actions/ahub.actions";
import { aHubStateTemporaryProductPropertyTypes } from "app/store/selector/ahub/ahub-temporary.selector";
import { StoreAccess } from "app/store/store-access";
import { ProductPropertyTypeAHubVO } from "app/valueObjects/ahub/library/product-property-type.ahub.vo";
import { ProductPropertyAHubVO } from "app/valueObjects/ahub/library/product-property.ahub.vo";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { componentDestroyStream, Hark } from "../../hark.decorator";
import { buildGenderMap } from "../../product-utils";
import { Utils } from "../../utils";
import { ProductPropertyEnumListEditorOption } from "../product-property-option-list-editor/product-property-option-list-editor.component";

@Component({
	selector: "app-product-property-editor",
	templateUrl: "./product-property-editor.component.html",
	styleUrls: ["./product-property-editor.component.css"],
})
@Hark()
export class ProductPropertyEditorComponent implements OnInit {
	@Input() property$: Observable<ProductPropertyAHubVO>;

	@Input() value$: BehaviorSubject<string>;

	@Input() valueIsValid$: Subject<boolean> = new Subject<boolean>();

	/**
	 * The full list of the property types available.
	 */
	public propertyTypes$: Observable<ProductPropertyTypeAHubVO[]> =
		StoreAccess.dataGetObvs(aHubStateTemporaryProductPropertyTypes);

	/**
	 * value form control
	 */
	valueForm: UntypedFormGroup = this.formBuilder.group({
		propertyValue: [undefined, Validators.required],
	});

	property: ProductPropertyAHubVO;
	propertyType: ProductPropertyTypeAHubVO;

	/**
	 * Does the property have enum properties?
	 */
	hasEnumOptions = false;

	// Allows validation while the field is being edited
	matcher = new MyErrorStateMatcher();

	// Special variables for special data types
	genderMap: Map<string, string>;
	listValues$: BehaviorSubject<string[]> = new BehaviorSubject([]);

	selectedOptions$: BehaviorSubject<string[]> = new BehaviorSubject([]);
	valueOptions: ProductPropertyEnumListEditorOption[] = [];

	constructor(private readonly formBuilder: UntypedFormBuilder) {}

	ngOnInit() {
		//Do we have a existing value? then we will set it into the form
		if (this.value$ && this.value$.getValue()) {
			this.valueForm.controls.propertyValue.setValue(this.value$.getValue());
		}

		// Make the call to get the product property types.
		StoreAccess.dispatch(AHubActions.productPropertyTypesFetch());

		combineLatest([
			this.propertyTypes$.pipe(
				filter((types) => types !== undefined && types !== null)
			),
			this.property$.pipe(
				filter((property) => property !== undefined && property !== null)
			),
		])
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe(([types, property]) => {
				//Get the type of the property
				const typeReferenceForThisProperty = types.find(
					(type) => type.reference === property.typeReference
				);

				//No type then we need to bail out, we will struggle without the correct type
				if (!typeReferenceForThisProperty) {
					return;
				}

				//Set the property and the property type
				this.property = property;
				this.propertyType = typeReferenceForThisProperty;

				//Get the value for the property
				const value = this.value$.getValue();

				//Does this property have enum options?
				this.hasEnumOptions =
					property.enumOptions && property.enumOptions.length > 0;

				this.valueForm.controls.propertyValue.setValidators(
					Validators.pattern(typeReferenceForThisProperty.regExValid)
				);
				this.valueForm.controls.propertyValue.setValue(value, {
					emitEvent: false,
				});

				//Is this a boolean type?
				if (typeReferenceForThisProperty.reference === "BOOLEAN") {
					//Then we will use no validator's and we need to set the value
					this.valueForm.controls.propertyValue.setValidators([]);
					this.valueForm.controls.propertyValue.setValue(value === "1", {
						emitEvent: false,
					});
				}

				//Is this a list like property?
				if (this.propertyType.primitiveType === "LIST") {
					this.listValues$.next(value ? JSON.parse(value) : []);

					// The regex we store (in aHub) for TOKENLIST is for individual elements of a Token array
					// This doesnt work for validating a JSON.stringify'd array
					// So we will use an alternative
					// TOKENLIST regex: ^[\[[\"\w\"\,[\s]*\]]*$
					this.valueForm.controls.propertyValue.setValidators(
						Validators.pattern(/^[\[[\"\w\"\,[\s]*\]]*$/)
					);
					this.valueOptions = [];
					const selectedOptions: string[] = value ? JSON.parse(value) : [];
					this.selectedOptions$.next(selectedOptions);

					//Do we have enum options for the property?
					if (this.hasEnumOptions) {
						//Then we will create the possible value options
						property.enumOptions.forEach((option, index) => {
							const valueOption: ProductPropertyEnumListEditorOption = {
								value: option,
								label:
									property.enumDisplay && property.enumDisplay[index]
										? property.enumDisplay[index]
										: option,
							};
							this.valueOptions.push(valueOption);
						});
					}
				}

				// Lets do something a bit nicer for GENDER...this only works as we know the type 'description' from server
				// contains a nice 'list' of options
				if (typeReferenceForThisProperty.reference === "GENDER") {
					this.genderMap = buildGenderMap(typeReferenceForThisProperty);
				}
			});

		//Watch the forms value changes
		this.valueForm.valueChanges
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((formData) => {
				//Set the value valid boolean
				this.valueIsValid$.next(this.valueForm.valid);

				//Is the form valid?
				if (this.valueForm.valid) {
					//Set the value
					let value = formData.propertyValue;

					//Is this a boolean then we will default the value to the correct type
					if (this.propertyType.reference === "BOOLEAN") {
						value = value === true ? "1" : "0";
					}

					//Set the value into the values stream
					this.value$.next(value);
				}
			});

		// lets handle changes to selected options for token/text lists (when no Done editing is clicked)
		this.selectedOptions$
			.pipe(
				Utils.isNotNullOrUndefined(),
				filter((selectedOptions) => selectedOptions.length > 0),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((selectedOptions) => {
				// we'll also set the editor to 'valid'
				this.valueForm.controls.propertyValue.setErrors(null);

				// lets update the form, so the value can be passed up/out
				this.valueForm.controls.propertyValue.setValue(
					JSON.stringify(selectedOptions)
				);
			});

		this.listValues$
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((values) => {
				//No values bail out
				if (!values) {
					return;
				}

				// Strip out empty values
				const listValues: string[] = values.filter(
					(value) =>
						value !== undefined && value !== null && value.trim().length > 0
				);

				if (listValues && listValues.length > 0) {
					this.valueIsValid$.next(true);
					this.value$.next(JSON.stringify(listValues));
				}
			});
	}

	ngOnDestroy(): void {}

	/**
	 * Get the regular expression error for the list type properties
	 */
	getListRegexError() {
		//This is a terrible message but we don't have a better one else where
		return `Incorrect value for property type`;
	}
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: UntypedFormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const isSubmitted = form && form.submitted;
		return !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched || isSubmitted)
		);
	}
}
