import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-client-log-dialog",
	templateUrl: "./client-log-dialog.component.html",
	styleUrls: ["./client-log-dialog.component.css"],
})
export class ClientLogDialogComponent implements OnInit {
	/**
	 * This is the dialog title.
	 */
	public title: string;

	/**
	 * The id of the client log we want to display.
	 */
	public clientLogId: number;

	/**
	 * These are the labels to be used on the buttons.
	 */
	public confirmButtonLabel: string;

	constructor(public dialogRef: MatDialogRef<ClientLogDialogComponent>) {}

	ngOnInit() {
		// This is intentional
	}
}
