import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ProcessTokenRoutingModule } from "./process-token-routing.module";
import { ProcessTokenComponent } from "./process-token.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
	declarations: [ProcessTokenComponent],
	imports: [CommonModule, MatProgressSpinnerModule, ProcessTokenRoutingModule],
})
export class ProcessTokenModule {}
