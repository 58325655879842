/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

/**
 * Utilities
 */
import { ListUtil } from "store/list.util";
import { List } from "store/list.vo";

/**
 * Redcuer
 */
import { ViewLibraryExtractsState } from "reducers/view/view-library-extracts.reducer";

/**
 * Selectors
 */
import { viewState } from "selector/view/view.selector";

import {
	aHubStateTemporaryExtractDefinitionList,
	aHubStateTemporaryExtractList,
	aHubStateTemporaryExtractProducts,
	aHubStateTemporaryExtractProductCompletenessPaginationMap,
	aHubStateTemporaryExtractProductCompletenessMap,
} from "selector/ahub/ahub-temporary.selector";

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */
import { ExtractDefinitionAHubVO } from "valueObjects/ahub/library/extract-definition.ahub.vo";
import { ExtractDefinitionPropertyAHubVO } from "valueObjects/ahub/library/extract-definition-property.ahub.vo";
import { ExtractIndexAHubVO } from "valueObjects/ahub/library/extract-index.ahub.vo";
import { ExtractAHubVO } from "valueObjects/ahub/library/extract.ahub.vo";
import { ProductAHubVO } from "valueObjects/ahub/library/product.ahub.vo";
import { ProductAssetViewVO } from "valueObjects/view/product-asset-view.vo";
import { MapStorageUtil } from "store/map-storage.util";
import { HashedMapStorage } from "store/hashed-map-storage.vo";
import { MapStorage } from "app/store/map-storage.vo";
import { PaginationIdBreakdownVO } from "app/valueObjects/ahub/system/pagination-id-breakdown.ahub.vo";
import { ProductPropertyCompletenessAHubVO } from "app/valueObjects/ahub/library/product-property-completeness.ahub.vo";
import { ExtractDefinitionPropertyAllocationObjectVO } from "app/valueObjects/view/extract-definition-property-allocation.view.vo";

/**
 * Root selector to get the library extracts view state from the store state
 */
const viewLibraryExtractsState = (state): ViewLibraryExtractsState =>
	viewState(state).libraryExtracts;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */

/**
 * A selector to access the currently selected extract definition id in the extract definitions section.
 */
export const viewLibraryExtractsDefinitionId = (state): number =>
	viewLibraryExtractsState(state).viewLibraryExtractsDefinitionId;

/**
 * A selector to access the currently selected extract definition in the extract definitions section.
 */
export const viewLibraryExtractsDefinitionSelected = (
	state
): ExtractDefinitionAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryExtractDefinitionList(state),
		viewLibraryExtractsDefinitionId(state)
	);

/**
 * A selector to access the currently selected extract definition property id in the extract definitions section.
 */
export const viewLibraryExtractsDefinitionPropertyId = (state): number =>
	viewLibraryExtractsState(state).extractDefinitionPropertyId;

/**
 * A selector to access the extract definition properties
 */
export const viewLibraryExtractsDefinitionProperties = (
	state
): ExtractDefinitionPropertyAHubVO[] =>
	viewLibraryExtractsState(state).extractDefinitionProperties;

/**
 * A selector to access the extract definition properties
 */
export const viewLibraryExtractsDefinitionProductIdentifierProperty = (
	state
): ExtractDefinitionPropertyAHubVO =>
	viewLibraryExtractsState(state).extractDefinitionProductIdentifierProperty;

/**
 * A selector to access whether the current definition properties have been edited or not.
 */
export const viewLibraryExtractsDefinitionPropertiesEdited = (state): boolean =>
	viewLibraryExtractsState(state).extractDefinitionPropertiesEdited;

/**
 * A selector to get the latest selected extract id.
 */
export const viewLibrarySelectedExtractId = (state): number =>
	viewLibraryExtractsState(state).viewLibraryExtractsId;

/**
 * A selector to get the latest selected extract value object.
 */
export const viewLibrarySelectedExtract = (state): ExtractAHubVO =>
	ListUtil.listDataItemGet(
		aHubStateTemporaryExtractList(state),
		viewLibrarySelectedExtractId(state)
	);

/**
 * Extract indexes which relate to the extract definition
 */
export const viewLibrarySelectedExtractDefinitionExtractIndex = (
	state
): ExtractIndexAHubVO[] =>
	viewLibraryExtractsState(state).extractDefinitionExtractIndex;

/**
 * Get all the conflicts for the selected extracts
 */
export const viewLibrarySelectedExtractConflicts = (state): ExtractAHubVO[] =>
	viewLibraryExtractsState(state).extractConflicts;

/**
 * Get the current pagination data for the currently selected extract
 */
export const viewLibrarySelectedExtractProductCompletenessPagination = (
	state
): PaginationIdBreakdownVO =>
	MapStorageUtil.mapStorageGet(
		aHubStateTemporaryExtractProductCompletenessPaginationMap(state),
		viewLibrarySelectedExtractId(state).toString()
	);

/**
 * Get the current completeness data based on the selected extract
 */
export const viewLibrarySelectedExtractProductPropertyCompleteness = (
	state
): ProductPropertyCompletenessAHubVO[] =>
	MapStorageUtil.mapStorageGet(
		aHubStateTemporaryExtractProductCompletenessMap(state),
		viewLibrarySelectedExtractId(state).toString()
	);

/**
 * Get the extract products list for the currently selected extract
 */
export const viewLibrarySelectedExtractProductHashMap = (
	state
): HashedMapStorage<ProductAHubVO> =>
	MapStorageUtil.mapStorageGet(
		aHubStateTemporaryExtractProducts(state),
		viewLibrarySelectedExtractId(state).toString()
	);

/**
 * A selector to get the latest selected product id.
 */
export const viewLibrarySelectedProductIds = (state): number[] =>
	viewLibraryExtractsState(state).productSelectedIds;

/**
 * A selector to get the latest selected product id.
 */
export const viewLibraryDisplayedColumns = (
	state
): ExtractDefinitionPropertyAllocationObjectVO[] =>
	viewLibraryExtractsState(state).displayedColumns;

/**
 * A selector to get the extract show assets state.
 */
export const viewLibraryExtractShowAssetsState = (state): boolean =>
	viewLibraryExtractsState(state).showAssetsState;

/**
 * A selector to get the extract show assets state.
 */
export const viewLibraryExtractShowColumnsState = (state): boolean =>
	viewLibraryExtractsState(state).showColumnsState;

/**
 * A selector to get the extract show assets state.
 */
export const viewLibraryProductAssetSwapSource = (state): ProductAssetViewVO =>
	viewLibraryExtractsState(state).assetSwapSource;

/**
 * Selector to get all of extract products change set.
 */
export const viewLibraryExtractProductsChangeSet = (
	state
): List<ProductAHubVO> =>
	viewLibraryExtractsState(state).extractProductsChangeSet;

/**
 * Gets the currently selected extract allocation id from the store.
 */
export const viewLibrarySelectedExtractAllocationId = (state): number =>
	viewLibraryExtractsState(state).extractAllocationId;

/**
 * Gets the open allocation ids
 */
export const viewLibraryExtractDocumentSettingAllocationIdOpen = (
	state
): number[] =>
	viewLibraryExtractsState(state).extractDocumentSettingsAllocationIdOpen;
