import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "ampersandToAnd",
})
export class AmpersandToAndPipe implements PipeTransform {
	transform(value: string, args?: any): any {
		if (value) {
			return value.replace(/&/g, "<strong> AND </strong>");
		}
	}
}
