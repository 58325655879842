/**
 * Action Types
 */
import {
	ActionRouteViewVOs,
	ActionRouteViewVO,
} from "actions/types/view.action-types";
/**
 * Actions
 */
import { ViewActions } from "actions/view.actions";
import { tassign } from "modules/common/type-assign.util";
import { createReducer } from "reducers/reducer-util";
import { Reducer } from "redux";
import { EntityRefAHubEnum } from "valueObjects/ahub/system/entity-ref.ahub.enum";
/**
 * Value Objects
 */
import {
	RouteViewVO,
	ID_DASHBOARD,
	ID_USER_ACCOUNT,
	ID_CONTENT_VIEWER,
	ID_ACCESS_CONTROL,
	ID_LIBRARY_STRUCTURE,
	ID_LIBRARY_CONTENTS,
	ID_PUBLISHING,
	ID_DISTRIBUTION,
	ID_USAGE,
	ID_AVIEW,
	ID_AWORKBOOK,
} from "valueObjects/view/route.view.vo";
import { environment } from "environments/environment";
import { ToolIDEnum } from "app/valueObjects/view/tool-id.view.enum";

/**
 * ----------------------------------
 * App State interface
 * ----------------------------------
 */
export interface ViewRoutesState {
	allRoutes: RouteViewVO[];
	availableRoutes: RouteViewVO[];
	selectedRouteView: RouteViewVO;
}

const INFORMATION_ABOUT_HARK_CLIENTS = "Information about hark clients";
const ALL_AHUB_USERS = "All AHub Users";
const MY_ACCOUNT = "My Account";
/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */

const allRoutes: RouteViewVO[] = [
	{
		id: ID_DASHBOARD,
		name: "Dashboard",
		showKowledgeBaseIcon: true,
		knowledgeBasePagePath:
			"/getting-started-with-ahub/ahub-logging-in-and-the-dashboard/#the_dashboard",
		pageTitle: "Dashboard",
		description: "User's home dashboard",
		icon: "bi-house-door",
		iconActive: "bi-house-door-fill",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.ACCOUNTS,
				permissionType: "NONE",
			},
		],
		routerLink: "/welcome",
		routes: undefined,
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		id: ID_AVIEW,
		name: "aView",
		pageTitle: "aView",
		description: "aView",
		icon: "library_books",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.ACCOUNTS,
				permissionType: "NONE",
			},
		],
		routerLink: "/aview",
		routes: undefined,
		enabled: true,
		toolIds: [ToolIDEnum.AVIEW],
	},
	{
		id: ID_AWORKBOOK,
		name: "",
		pageTitle: "",
		description: "aWorkbook",
		icon: "library_books",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.ACCOUNTS,
				permissionType: "NONE",
			},
		],
		hideInMenu: true,
		routerLink: "/aworkbook",
		routes: undefined,
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		id: ID_USER_ACCOUNT,
		name: MY_ACCOUNT,
		pageTitle: MY_ACCOUNT,
		description: "Manage logged in user account",
		icon: "bi-person",
		iconActive: "bi-person-fill",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.ACCOUNTS,
				permissionType: "NONE",
			},
		],
		routerLink: "/my-account",
		routes: undefined,
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	// {
	//   name: 'Knowledge Base',
	//   pageTitle: 'Welcome',
	//   description: 'Users guides for aHub and aWorkbook',
	//   icon: 'help',
	//   permission: {
	//     entityRef: EntityRefAHubEnum.ACCOUNTS,
	//     permissionType: 'NONE'
	//   },
	//   routerLink: '/externalKnowledgeBaseRedirect',
	//   enabled: true
	// }
	{
		id: ID_ACCESS_CONTROL,
		name: "Access Control",
		pageTitle: "Access Control",
		description: "Control user access levels",
		icon: "bi-lock",
		iconActive: "bi-lock-fill",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.ACCOUNTS,
				permissionType: "USER",
			},
		],
		routerLink: "/access-control",
		routes: undefined,
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		id: ID_LIBRARY_STRUCTURE,
		name: "Library Structure",
		pageTitle: "Library Structure",
		description: "Product classifications and definitions.",
		icon: "bi-diagram-3",
		iconActive: "bi-diagram-3-fill",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.LIBRARY_DATA,
				permissionType: "ADMINISTRATOR",
			},
		],
		routerLink: "/library-config",
		routes: [
			{
				name: "Product Classes",
				pageTitle: "Library Structure>Product Classes",
				description: "Product Classes",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.LIBRARY_DATA,
						permissionType: "ADMINISTRATOR",
					},
				],
				routerLink: "/library-config/products/classification",
				routes: undefined,
				enabled: true,
			},
			{
				name: "Class Properties",
				pageTitle: "Library Structure>Class Properties",
				description: "Class Properties",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.LIBRARY_DATA,
						permissionType: "ADMINISTRATOR",
					},
				],
				routerLink: "/library-config/products/properties",
				routes: undefined,
				enabled: true,
			},
			{
				name: "Property Chaining",
				pageTitle: "Library Structure>Property Chaining",
				description: "Property Chaining",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.LIBRARY_DATA,
						permissionType: "ADMINISTRATOR",
					},
				],
				routerLink: "/library-config/products/chaining",
				routes: undefined,
				enabled: true,
			},
		],
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		id: ID_LIBRARY_CONTENTS,
		name: "Library Contents",
		pageTitle: "Library Contents",
		description: "Library content and content templates.",
		icon: "bi-file-earmark-richtext",
		iconActive: "bi-file-earmark-richtext-fill",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.LIBRARY_DATA,
				permissionType: "USER",
			},
		],
		routerLink: "/library-contents",
		routes: [
			{
				name: "Content Templates",
				pageTitle: "Library Contents>Content Templates",
				description:
					"Define section and class properties to be used for contents",
				icon: "arrow_right",
				showKowledgeBaseIcon: true,
				knowledgeBasePagePath: "/library-contents-an-overview/",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.LIBRARY_DATA,
						permissionType: "EDITOR",
					},
				],

				routerLink: "/library-contents/products/product-extract-definitions",
				routes: undefined,
				enabled: true,
			},
			{
				name: "Content Editing",
				pageTitle: "Library Contents>Content Editing",
				description: "Add or edit product data.",
				icon: "arrow_right",
				showKowledgeBaseIcon: true,
				knowledgeBasePagePath:
					"/library-contents/library-contents-how-to/how-do-i-set-up-my-content-editing-templates/",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.LIBRARY_DATA,
						permissionType: "USER",
					},
				],
				routerLink: "/library-contents/products/product-extract-data/details",
				routes: undefined,
				enabled: true,
			},
			{
				name: "Integration",
				pageTitle: "Library Contents>Integration",
				description: "File Change Sets",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.LIBRARY_DATA,
						permissionType: "ADMINISTRATOR",
					},
				],
				routerLink: "/library-contents/integration",
				routes: undefined,
				enabled: true,
			},
		],
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		id: ID_PUBLISHING,
		name: "Publishing",
		pageTitle: "Publishing",
		description: "Publish your content",
		icon: "bi-printer",
		iconActive: "bi-printer-fill",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.PUBLISHING,
				permissionType: "USER",
			},
		],
		routerLink: "/library-publishing",
		routes: [
			{
				name: "Content Sets",
				pageTitle: "Publishing>Content Sets",
				description: "Product Grouping for publishing",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.PUBLISHING,
						permissionType: "EDITOR",
					},
				],
				routerLink: "/library-publishing/dataSets/details",
				routes: undefined,
				enabled: true,
			},
			{
				id: ID_CONTENT_VIEWER,
				name: "Content Viewer",
				pageTitle: "Publishing>Content Viewer",
				description: "Review content sets",
				icon: "",
				svgIcon: "compare",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.PUBLISHING,
						permissionType: "USER",
					},
				],
				routerLink: "/library-publishing/content-viewer",
				routes: undefined,
				enabled: true,
			},
			{
				name: "Content Publishers",
				pageTitle: "Publishing>Content Publishers",
				description: "Define mapping and publishing structure",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.PUBLISHING,
						permissionType: "EDITOR",
					},
				],
				routerLink: "/library-publishing/exporters/details",
				routes: undefined,
				enabled: true,
			},
			{
				name: "Resource Packs",
				pageTitle: "Publishing>Resource Packs",
				description: "Add and Edit resource packs used in exports",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.PUBLISHING,
						permissionType: "EDITOR",
					},
				],
				routerLink: "/library-publishing/resourcePacks/content",
				routes: undefined,
				enabled: true,
			},
		],
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		id: ID_DISTRIBUTION,
		name: "Distribution",
		pageTitle: "Distribution",
		description: "Distribution of Publications to users.",
		icon: "bi-arrow-up-right-square",
		iconActive: "bi-arrow-up-right-square-fill",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.DISTRIBUTION,
				permissionType: "USER",
			},
		],
		routerLink: "/distribution",
		routes: [
			{
				name: "Publications",
				pageTitle: "Distribution>Publications",
				description: "Published exports",
				icon: "arrow_right",
				showKowledgeBaseIcon: true,
				knowledgeBasePagePath: "/distribution-an-overview/#publications",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.DISTRIBUTION,
						permissionType: "USER",
					},
				],
				routerLink: "/distribution/exports/details",
				routes: undefined,
				enabled: true,
			},
			{
				name: "Distribution Groups",
				pageTitle: "Distribution>Distribution Groups",
				description: "Define user access to exports (catalogues)",
				showKowledgeBaseIcon: true,
				knowledgeBasePagePath: "/distribution-an-overview/#distribution_groups",

				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.DISTRIBUTION,
						permissionType: "USER",
					},
				],
				routerLink: "/distribution/distribution-groups/details",
				routes: undefined,
				enabled: true,
			},
			{
				name: "Users",
				pageTitle: "Distribution>Users",
				description: "Users",
				icon: "arrow_right",
				showKowledgeBaseIcon: true,
				knowledgeBasePagePath: "/distribution-an-overview/#users",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.DISTRIBUTION,
						permissionType: "USER",
					},
				],
				routerLink: "/distribution/users/export-distributions",
				routes: undefined,
				enabled: true,
			},
		],
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		id: ID_USAGE,
		name: "Usage",
		pageTitle: "Usage",
		description: "Client usage metrics",
		icon: "bi-speedometer2",
		iconActive: "bi-speedometer2",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.USAGE,
				permissionType: "USER",
			},
		],
		routerLink: "/usage",
		routes: [],
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		name: "Configuration",
		pageTitle: "Configuration",
		description: "Client based settings.",
		icon: "bi-gear",
		iconActive: "bi-gear-fill",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.ACCOUNTS,
				permissionType: "EDITOR",
			},
		],
		routerLink: "/configuration",
		routes: undefined,
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},

	{
		name: "Account Management",
		pageTitle: "Account Management",
		description: "Management of clients and their interaction with users.",
		icon: "bi-people",
		iconActive: "bi-people-fill",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.SYSTEM,
				permissionType: "USER",
			},
		],
		routerLink: "/account-management",
		routes: [
			{
				name: "Clients",
				pageTitle: "Account Management>Clients",
				description: INFORMATION_ABOUT_HARK_CLIENTS,
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.SYSTEM,
						permissionType: "USER",
					},
				],
				routerLink: "/account-management/clients/details",
				routes: undefined,
				enabled: true,
			},
			{
				name: "Users",
				pageTitle: "Account Management>Users",
				description: ALL_AHUB_USERS,
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.SYSTEM,
						permissionType: "USER",
					},
				],
				routerLink: "/account-management/users/details",
				routes: undefined,
				enabled: true,
			},
		],
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		name: "Client Library Versions",
		pageTitle: "Client Library Versions",
		description: "Manage versions of the client library available",
		icon: "bi-file-earmark-person",
		iconActive: "bi-file-earmark-person-fill",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.SYSTEM,
				permissionType: "EDITOR",
			},
		],
		routerLink: "/client-library-version-management",
		routes: [],
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		name: "Advanced client tools",
		pageTitle: "Advanced client tools",
		description: "Tools for advanced users such as system integrators.",
		icon: "bi-tools",
		iconActive: "bi-tools",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.USAGE,
				permissionType: "ADMINISTRATOR",
			},
		],
		routerLink: "/client-tools",
		routes: [
			{
				name: "API Debug Tool",
				pageTitle: "Advanced client tools>API Debug Tool",
				description: "Tool to test api calls",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.USAGE,
						permissionType: "ADMINISTRATOR",
					},
				],
				routerLink: "/client-tools/api-debug",
				routes: undefined,
				enabled: true,
			},
			{
				name: "Swagger",
				pageTitle: "Advanced client tools>Swagger",
				description: "API Swagger definition",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.USAGE,
						permissionType: "ADMINISTRATOR",
					},
				],
				routerLink: "/client-tools/swagger",
				routes: undefined,
				enabled: true,
			},
		],
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		name: "Developer tools",
		pageTitle: "Developer tools",
		description: "Useful tools.",
		icon: "bi-wrench",
		iconActive: "bi-wrench",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.SYSTEM,
				permissionType: "ADMINISTRATOR",
			},
		],
		routerLink: "/dev-tools",
		routes: [
			{
				name: "aHub API Swagger",
				pageTitle: "Developer tools>aHub API Swagger",
				description: "AHub API Swagger definition",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.SYSTEM,
						permissionType: "ADMINISTRATOR",
					},
				],
				routerLink: "/dev-tools/swagger",
				routes: undefined,
				enabled: true,
			},
			{
				name: "aWorkbook API Swagger",
				pageTitle: "Developer tools>aWorkbook API Swagger",
				description: "aWorkbook API Swagger",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.SYSTEM,
						permissionType: "ADMINISTRATOR",
					},
				],
				routerLink: "/dev-tools/aworkbook-swagger",
				routes: undefined,
				enabled: true,
			},
			{
				name: "System Control",
				pageTitle: "Developer tools>System Control",
				description: "System Control",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.SYSTEM,
						permissionType: "ADMINISTRATOR",
					},
				],
				routerLink: "/dev-tools/systemControl",
				routes: undefined,
				enabled: true,
			},
			{
				name: "System Databases",
				pageTitle: "Developer tools>System Databases",
				description: "System Databases",
				icon: "arrow_right",
				permissions: [
					{
						entityRef: EntityRefAHubEnum.SYSTEM,
						permissionType: "ADMINISTRATOR",
					},
				],
				routerLink: "/dev-tools/systemDatabases",
				routes: undefined,
				enabled: true,
			},
		],
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
];

const availableRoutes: RouteViewVO[] = [
	{
		name: "Welcome to aHub",
		pageTitle: "Welcome to aHub",
		description: "Logged in user dashboard",
		icon: "home",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.ACCOUNTS,
				permissionType: "NONE",
			},
		],
		routerLink: "/welcome",
		routes: undefined,
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		name: MY_ACCOUNT,
		pageTitle: MY_ACCOUNT,
		description: "Manage logged in user account",
		icon: "face",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.ACCOUNTS,
				permissionType: "NONE",
			},
		],
		routerLink: "/my-account",
		routes: undefined,
		enabled: true,
		toolIds: [ToolIDEnum.AHUB],
	},
	{
		id: ID_AVIEW,
		name: "aView",
		pageTitle: "aView",
		description: "aView",
		icon: "library_books",
		permissions: [
			{
				entityRef: EntityRefAHubEnum.ACCOUNTS,
				permissionType: "NONE",
			},
		],
		routerLink: "/aview",
		routes: undefined,
		enabled: true,
		toolIds: [ToolIDEnum.AVIEW],
	},
];

export const ViewRoutesInitialState: ViewRoutesState = {
	allRoutes,
	availableRoutes,
	selectedRouteView: undefined,
};
/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the selected route index
 */
const availableRoutesSet = (
	state: ViewRoutesState,
	action: ActionRouteViewVOs
) => {
	//Set the router link passed in
	return tassign(state, { availableRoutes: action.routeViews });
};

/**
 * Set the selected route index
 */
const selectedRouteViewSet = (
	state: ViewRoutesState,
	action: ActionRouteViewVO
) => {
	//Set the router link passed in
	return tassign(state, { selectedRouteView: action.routeView });
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
const reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to
 */
reducerHandlers[ViewActions.AVAILABLE_ROUTES_SET] = availableRoutesSet;
reducerHandlers[ViewActions.SELECTED_ROUTE_VIEW_SET] = selectedRouteViewSet;

/**
 * Create a reducers based on the reducers handlers
 */
export const ViewRoutesReducer: Reducer<ViewRoutesState> = createReducer(
	ViewRoutesInitialState,
	reducerHandlers
);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewRoutesReducerHasHandler = (actionType: string): boolean =>
	reducerHandlers.hasOwnProperty(actionType);
