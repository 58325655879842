import {
	startWith,
	takeUntil,
	debounceTime,
	tap,
	delay,
	map,
} from "rxjs/operators";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { IHeaderGroupAngularComp } from "ag-grid-angular";
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
import { Observable, of, combineLatest } from "rxjs";

@Component({
	selector: "app-grid-group-header-count",
	templateUrl: "./grid-group-header-count.component.html",
	styleUrls: ["./grid-group-header-count.component.css"],
})
@Hark()
export class GridGroupHeaderCountComponent
	implements IHeaderGroupAngularComp, OnDestroy, OnInit
{
	/**
	 * Parameters for the renderer
	 */
	private params: any;

	/**
	 * Parameters with custom values
	 */
	private paramsWithCustom: GridGroupHeaderCountParameters;

	/**
	 * Stream to monitor filter change
	 */
	filterChange$: Observable<any> = of();

	/**
	 * Stream for the current count of the items
	 */
	itemCount$: Observable<number> = of(0);

	/**
	 * Stream for the expected total number of items
	 */
	expectedItemCount$: Observable<number> = of(0);

	/**
	 * The total amount of data items as reported by our external stream
	 */
	dataTotal = 0;

	/**
	 * The current count of items in the table
	 */
	tableCurrent = 0;

	/**
	 * Is the data being updated
	 */
	downloading = false;

	/**
	 * Warnings string
	 */
	warnings: string;

	ngOnInit() {
		// This is intentional
	}
	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	agInit(params): void {
		//Set the current set of parameters
		this.params = params;
		this.paramsWithCustom = params;

		//Set the item count streams from the parameters
		if (this.paramsWithCustom) {
			if (this.paramsWithCustom.totalItems$) {
				this.itemCount$ = this.paramsWithCustom.totalItems$;
			}

			if (this.paramsWithCustom.filterChange$) {
				this.filterChange$ = this.paramsWithCustom.filterChange$;
			}

			//Set the warnings observable
			if (this.paramsWithCustom.warnings$) {
				this.paramsWithCustom.warnings$
					.pipe(takeUntil(componentDestroyStream(this)))
					.subscribe((warning) => (this.warnings = warning));
			}
		}

		//We want to display a bouncing cloud whilst we are downloading data
		//so we have a dedicated stream for it
		this.itemCount$
			.pipe(
				tap(() => (this.downloading = true)),
				debounceTime(2000),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((data) => {
				this.downloading = false;
			});

		//We want to update the item counts of the data item in the gird
		combineLatest([this.itemCount$, this.filterChange$.pipe(startWith({}))])
			.pipe(
				map(([a, b]) => a),
				delay(100),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((count) => {
				this.dataTotal = count;
				this.tableCurrent = params.api.getDisplayedRowCount();
			});
	}
}

/**
 * Custom parameters for this componenet
 */
export interface GridGroupHeaderCountParameters {
	totalItems$: Observable<number>; // Total number of items we have.
	filterChange$?: Observable<any>;
	warnings$?: Observable<string>;
}
