import { NavigationExtras, Router } from "@angular/router";
import { AppActions } from "store/actions/app.actions";
import { StoreAccess } from "store/store-access";
import { returnToUrl } from "selector/app.selector";

/**
 * This class holds all of the names of the routes available to navigate too.
 */
export class AppRoutingNavigation {
	/**
	 * This is the path to the root page.
	 */
	private static readonly ROOT = "/";

	/**
	 * This is the path to the root page.
	 */
	private static readonly INDEX = "/index.html";

	/**
	 * The login page.
	 */
	private static readonly LOGIN = "/login";

	/**
	 * The login page.
	 */
	private static readonly LOGIN_SELECTION = "/login/login-selection";

	/**
	 * The page that will log the user out.
	 */
	private static readonly LOGOUT = "/logout";

	/**
	 * This is the page to call when we want to complete the login.
	 */
	private static readonly LOGIN_COMPLETE = "/login/login-complete";

	/**
	 * This is the page to call when we want to validate and verify an
	 */
	private static readonly AUTH_TOKEN = "/login/auth-token";

	/**
	 * The main welcome home screen.
	 */
	private static readonly WELCOME = "/welcome/home";

	/**
	 * The main my account screen.
	 */
	private static readonly MY_ACCOUNT = "/my-account";

	/**
	 * The verify screen.
	 */
	private static readonly VERIFY = "/login/verify";

	/**
	 * The email sent screen.
	 */
	private static readonly VERIFICATION_EMAIL_SENT =
		"/login/verification-email-sent";

	/**
	 * The link to the aView application which is part of the aHub
	 */
	private static readonly AVIEW_ROOT = "/aview/publication";

	/**
	 * The link to the aView application which is part of the aHub
	 */
	private static readonly PROCESS_TOKEN_LANDING_PAGE = "/process-token";

	/**
	 * The library publishing resource pack content screen.
	 */
	private static readonly RESOURCE_PACKS =
		"/library-publishing/resourcePacks/content";

	/**
	 * Navigate to the root
	 */
	static navigateRoot(router: Router) {
		//Navigate and respond with the result
		return router.navigate([AppRoutingNavigation.ROOT]);
	}

	/**
	 * Navigate to the root
	 */
	static navigateIndex(router: Router) {
		//Navigate and respond with the result
		return router.navigate([AppRoutingNavigation.INDEX]);
	}

	/**
	 * Navigate to the login
	 */
	static navigateLoginSelection(
		router: Router,
		email?: string,
		aHubToken?: string
	) {
		const params = {};

		//If we have the data then set it into the paramer object
		if (aHubToken) {
			params["ahtoken"] = aHubToken;
		}
		if (email) {
			params["email"] = email;
		}

		//Navigate and respond with the result
		return router.navigate([AppRoutingNavigation.LOGIN_SELECTION, params]);
	}

	/**
	 * Navigate to the login complete
	 */
	static navigateLoginComplete(router: Router) {
		//Navigate and respond with the result
		return router.navigate([AppRoutingNavigation.LOGIN_COMPLETE]);
	}

	/**
	 * Navigate to the logout
	 */
	static navigateLogout(router: Router) {
		//Navigate and respond with the result
		return router.navigate([AppRoutingNavigation.LOGOUT]);
	}

	/**
	 * Navigate to the return to app
	 */
	static navigateAuthToken(router: Router, identityProviderToken: string) {
		//Navigate and respond with the result
		return router.navigate([
			AppRoutingNavigation.AUTH_TOKEN,
			{ ipToken: identityProviderToken },
		]);
	}

	/**
	 * Navigate to the welcome,
	 *
	 * @param externalLogin
	 */
	static navigateWelcome(router: Router, externalLogin: boolean) {
		//Navigate and respond with the result
		return router.navigate([
			AppRoutingNavigation.WELCOME,
			{ externalLogin: externalLogin },
		]);
	}

	/**
	 * Navigate to the current return to URL.
	 *
	 * @param router      The router to do the navigating on.
	 */
	static navigateReturnToUrl(router: Router) {
		let navigationPromise: Promise<boolean>;

		// Get the return to URL from the store.
		let returnToUrlValue: string = StoreAccess.dataGet(returnToUrl);

		// Stop here if the return to URL is null or empty.
		if (
			returnToUrlValue !== null &&
			returnToUrlValue !== "" &&
			returnToUrlValue !== undefined
		) {
			// http://localhost:4200/process-token?token=eyJraWQiOiIxNjI0NDI4MDk1NzgyX0tKNGMxYm1rSE0ifQ.eyJhdWQiOiJhSHViIiwibmJmIjoxNjI0NDU3MjA4LCJyZWRpcmVjdFVybCI6IlwvYXZpZXdcL3B1YmxpY2F0aW9uXC85NTRcL2VkaXRpb25cLzEwXC9zZWxlY3Rpb25cLzUyIiwic2VsZWN0aW9uSWQiOjUyLCJpc3MiOiJhSHViIiwidXNlckVtYWlsIjoiY29naW5pNTA4NkBiZXlkZW50LmNvbSIsInByb2Nlc3NUeXBlIjoic2VsZWN0aW9uVXNlckFkZEJ5RW1haWwiLCJleHAiOjE2MjQ0NTkzMDgsInRva2VuVHlwZSI6IlBST0NFU1NfVE9LRU4iLCJpYXQiOjE2MjQ0NTc1MDgsImp0aSI6Ikcwb1V0VjM3TGhVM0g0VTg3NDhmdWhGMTI0VGpSSzNSIn0.lYcqSfEXyONcwDUtw3dA-fB2GLQxP_5H7XuvfWmFztqhfmeIykSWqHnYVlu8_FODrXHDlYQ3IO35AjM7rpTFrM40cGMCcnYmw-MQNlYvbkMet3u8OQjjMqEa4sDXwzMF332Y9UdnoHJtSTSS9AmYG2cEf9oknsYpAjwBgLqL8FC3mgOsXqzDOPWHV0dKV8WDjjxP5E4WW8NaARiM9R91wOiL73o0futR2za9fMAhPqnP66OqZ8sSMgUkkJ0KdaWzbka-ynVW-GPY65t_e70RT4yg5phL-DchCuVTBSFwpCG3MIZ7S0ULuNN0Kq1zCU2OnDtBal3pE2DCRrJJQvq7MQ

			// Clear in the store.
			StoreAccess.dispatch(AppActions.returnToUrlSet(null));

			// Lets handle query params
			let navigationExtras: NavigationExtras = {};
			if (returnToUrlValue.includes("?")) {
				const returnToUrlParts: string[] = returnToUrlValue.split("?");
				returnToUrlValue = returnToUrlParts[0];
				const queryParamsParts: string[] = returnToUrlParts[1]?.split("&");
				let queryParams = {};
				queryParamsParts.forEach((queryParam) => {
					const queryParamKeyValuePair: string[] = queryParam.split("=");
					queryParams[queryParamKeyValuePair[0]] = queryParamKeyValuePair[1];
				});
				navigationExtras.queryParams = queryParams;
			}

			// Now go to the page.
			navigationPromise = router.navigate([returnToUrlValue], navigationExtras);

			navigationPromise.then((navigated) => {}).catch((ohdear) => {});
		}

		return navigationPromise;
	}

	/**
	 * Navigate to the my account
	 */
	static navigateMyAccount(router: Router) {
		//Navigate and respond with the result
		return router.navigate([AppRoutingNavigation.MY_ACCOUNT]);
	}

	/**
	 * Navigate to the welcome,
	 *
	 * @param email
	 */
	static navigateVerify(router: Router, email: string) {
		//Navigate and respond with the result
		return router.navigate([AppRoutingNavigation.VERIFY, { email: email }]);
	}

	/**
	 * Navigate to the welcome,
	 *
	 * @param email
	 */
	static navigateVerificationEmailSent(router: Router, email: string) {
		//Navigate and respond with the result
		return router.navigate([
			AppRoutingNavigation.VERIFICATION_EMAIL_SENT,
			{ email: email },
		]);
	}

	/**
	 * Navigate to the aView and let the user select export when there.
	 */
	static navigateAView(router: Router) {
		//Navigate and respond with the result
		return router.navigate([`${AppRoutingNavigation.AVIEW_ROOT}/`]);
	}

	/**
	 * Navigate to the aView and a specific export.
	 *
	 * @param router          The router to do the navigating.
	 * @param publicationId   The id of the publication to view.
	 * @param edition         The edition of the publication to view.
	 */
	static navigateAViewExport(
		router: Router,
		publicationId: number,
		edition: number
	) {
		//Navigate and respond with the result
		return router.navigate([
			`${AppRoutingNavigation.AVIEW_ROOT}/${publicationId}/edition/${edition}`,
		]);
	}

	/**
	 * Navigate to the aView and a specific export and a specific selection.
	 *
	 * @param router          The router to do the navigating.
	 * @param publicationId   The id of the publication to view.
	 * @param edition         The edition of the publication to view.
	 * @param selection         The selection of the publication to view.
	 */
	static navigateAViewSelection(
		router: Router,
		publicationId: number,
		edition: number,
		selection: number
	) {
		//Navigate and respond with the result
		return router.navigate([
			`${AppRoutingNavigation.AVIEW_ROOT}/${publicationId}/edition/${edition}/discussion/${selection}`,
		]);
	}

	/**
	 * Navigate to library publishing resource pack content screen
	 */
	static navigateResourcePackContent(router: Router) {
		//Navigate and respond with the result
		return router.navigate([AppRoutingNavigation.RESOURCE_PACKS]);
	}

	/**
	 * Navigate to the process-token landing page,
	 *
	 * @param token
	 */
	static navigateProcessTokenLandingPage(router: Router, token: string) {
		//Navigate and respond with the result
		return router.navigate([AppRoutingNavigation.PROCESS_TOKEN_LANDING_PAGE], {
			queryParams: { token: token },
		});
	}
}
