import { Pipe, PipeTransform } from "@angular/core";

/**
 * Utilities
 */
import {
	sortAlphanumericAtoZ,
	sortAlphanumericZtoA,
} from "modules/common/sort.util";

@Pipe({
	name: "propertySort",
})
export class PropertySort implements PipeTransform {
	/**
	 * The transform function to be called to the pipe work.
	 *
	 * @param sourceList                The list to sort.
	 * @param sortProperty              The property to use on the sort.
	 * @param reverseList               (Optional) Are we inverting the list? Defaults to false.
	 */
	transform(sourceList: any[], sortProperty: string, reverseList?: boolean) {
		// Make sure we don't break when a null list is passed on.
		if (!sourceList) return;

		// Call the sort on the list.
		return sourceList.sort((a, b) => {
			// Get the a and b values to sort.
			let aValue = a ? String(a[sortProperty]) : "";
			let bValue = b ? String(b[sortProperty]) : "";

			// Call the sort the correct function with the a and b values.
			return reverseList
				? sortAlphanumericZtoA(aValue, bValue)
				: sortAlphanumericAtoZ(aValue, bValue);
		});
	}
}
